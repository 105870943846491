import { DefaultStageNodeModel } from '../../Default/DefaultStageNodeModel';
import { GreaterThanEqualsStage } from '../../../../../../../../Model/Coach/Stages/Logical/GreaterThanEqualsStage';

export class GreaterThanEqualsNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: GreaterThanEqualsStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'logical:gte' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
