/**
 * Created by katarinababic on 15.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Button, Space } from 'antd';
import { FolderContent } from '../../../Model/Media/FileStorage/FolderContent';
import { useNavigate } from 'react-router-dom';

export type FolderTableRowProps = {
  folder: FolderContent;
  onEditFolder?: (folder: FolderContent) => void;
  onDeleteFolder?: (folder: FolderContent) => void;
  onDownloadFolder?: (folder: FolderContent) => void;
};

export const FolderTableRow: React.FC<FolderTableRowProps> = observer(
  ({ folder, onDeleteFolder, onEditFolder, onDownloadFolder }) => {
    const history = useNavigate();

    const handleOpenFolder = React.useCallback(() => {
      history(`/customer-success/file-storage/folder/${folder.name}`);
    }, [folder, history]);

    const handleEditFolder = React.useCallback(() => {
      onEditFolder?.(folder);
    }, [folder, onEditFolder]);

    const handleDeleteFolder = React.useCallback(() => {
      onDeleteFolder?.(folder);
    }, [folder, onDeleteFolder]);

    const handleDownloadFolder = React.useCallback(() => {
      onDownloadFolder?.(folder);
    }, [folder, onDownloadFolder]);

    return (
      <tr>
        <td style={{ cursor: 'pointer' }} onClick={handleOpenFolder}>
          <FolderOpenIcon />
        </td>
        <td style={{ cursor: 'pointer' }} onClick={handleOpenFolder}>
          {folder.displayName}
        </td>
        <td style={{ textAlign: 'right' }}>
          <Space wrap={false}>
            <Button type="dashed" size="small" onClick={handleDownloadFolder}>
              Download
            </Button>
            <Button type="dashed" size="small" onClick={handleEditFolder}>
              Edit
            </Button>
            <Button type="dashed" size="small" danger={true} onClick={handleDeleteFolder}>
              Delete
            </Button>
          </Space>
        </td>
      </tr>
    );
  },
);
