/**
 *
 * Created by neo on 06.02.17.
 */

import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Gym } from '../../../Model/Gym/Gym';
import { TableRowMedia } from '../../../Components/TableRowMedia';
import { Badge } from 'reactstrap';
import { useEffect, useState } from 'react';
import { GymCoachSubscription } from '../../../Model/Subscription/GymCoachSubscription';

function subscriptionColor(subscription?: GymCoachSubscription) {
  if (subscription?.valid) {
    if (subscription.plan === 'standard') {
      return 'success';
    } else if (subscription.plan === 'engagement') {
      return 'warning';
    } else if (subscription.plan === 'move') {
      return 'secondary';
    } else if (subscription.plan === 'academy') {
      return 'info';
    }
    return 'primary';
  }
  return 'danger';
}

export type GymTableRowProps = {
  gym: Gym;
};

export const GymTableRow: React.FC<GymTableRowProps> = observer(({ gym }) => {
  const [subscription, setSubscription] = useState<GymCoachSubscription | undefined>();

  useEffect(() => {
    GymCoachSubscription.current({ gymId: gym.id }).then((result) => setSubscription(result));
  }, [gym]);

  return (
    <tr>
      <td>
        <TableRowMedia medias={gym.logo ? [gym.logo] : undefined} />
      </td>
      <td>
        <Link to={`/infrastructure/gym/${gym.id}`}>{gym.name}</Link>
      </td>
      <td>
        {subscription && <Badge color={subscriptionColor(subscription)}>{subscription?.plan}</Badge>}
        {/*<Badge color={gym.virtual ? 'success' : 'danger '}>{gym.virtual ? 'virtual' : 'physical'}</Badge>*/}
      </td>
      <td style={{ textAlign: 'center' }}>
        {/*<div>*/}
        {gym.totalCustomers}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <b>Spot Classes:</b> {gym.totalSpotClasses}*/}
        {/*</div>*/}
      </td>
    </tr>
  );
});
