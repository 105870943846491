/**
 * Created by neo on 01.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { Row, Col } from 'reactstrap';
import { PipelineParam } from '../../../../../Model/Coach/PipelineParam';
import { PipelineParamEntryDefaultValue } from '../PipelineParamEntryDefaultValue';
import { PipelineParamNameInput } from './PipelineParamNameInput';

export type PipelineParamEntryProps = {
  param: PipelineParam;
  onRemove?: (param: string) => any;
};

export const PipelineParamEntry: React.FC<PipelineParamEntryProps> = observer(
  ({ param, onRemove }: PipelineParamEntryProps) => {
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(param.name);
    }, [param, onRemove]);

    const handleChangeType = React.useCallback((e) => (param.type = e), [param]);

    return (
      <Row>
        <Col>
          <PipelineParamNameInput param={param} />
        </Col>
        <Col md="auto">
          <Form.Item label="Type">
            <Select value={param.type} onChange={handleChangeType}>
              <Select.Option value="string">String</Select.Option>
              <Select.Option value="number">Number</Select.Option>
              <Select.Option value="boolean">Boolean</Select.Option>
              <Select.Option value="stringArray">String Array</Select.Option>
              <Select.Option value="numberArray">Number Array</Select.Option>
              <Select.Option value="booleanArray">Boolean Array</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Default Value">
            <PipelineParamEntryDefaultValue param={param} />
          </Form.Item>
        </Col>
        <Col md="auto" style={{ alignItems: 'center' }}>
          <Button type="link" danger onClick={handleRemove}>
            Remove
          </Button>
        </Col>
      </Row>
    );
  },
);
