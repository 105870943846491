/**
 * Created by neo on 29.04.22.
 */
import { TimeOfDay } from '../TimeOfDay';
import { observable } from 'mobx';
import { TimesOfDayJson } from './TimesOfDay';
import { TimeRange, TimeRangeJson } from '../Gym/TimeRange';

export const DefaultTimeRangeAny: ScheduleTimeRangeJson = {
  timeOfDay: 'any',
  from: '18:00:00',
  to: '18:00:00',
};

export const DefaultTimeRangeAfterWakeup: ScheduleTimeRangeJson = {
  timeOfDay: 'afterWakeUp',
  from: '07:00:00',
  to: '07:00:00',
};

export const DefaultTimeRangeMorning: ScheduleTimeRangeJson = {
  timeOfDay: 'morning',
  from: '09:00:00',
  to: '09:00:00',
};

export const DefaultTimeRangeNoon: ScheduleTimeRangeJson = {
  timeOfDay: 'noon',
  from: '12:00:00',
  to: '12:00:00',
};

export const DefaultTimeRangeAfternoon: ScheduleTimeRangeJson = {
  timeOfDay: 'afternoon',
  from: '14:00:00',
  to: '14:00:00',
};

export const DefaultTimeRangeEvening: ScheduleTimeRangeJson = {
  timeOfDay: 'evening',
  from: '20:00:00',
  to: '20:00:00',
};

export const DefaultTimeRangeNight: ScheduleTimeRangeJson = {
  timeOfDay: 'night',
  from: '22:00:00',
  to: '22:00:00',
};

export const DefaultTimeRangeBeforeBedTime: ScheduleTimeRangeJson = {
  timeOfDay: 'beforeBedTime',
  from: '23:00:00',
  to: '23:00:00',
};

export const DefaultTimeRanges: TimesOfDayJson = {
  afterWakeUp: {
    timeOfDay: 'afterWakeUp',
    from: '07:00:00',
    to: '07:00:00',
  },
  morning: {
    timeOfDay: 'morning',
    from: '09:00:00',
    to: '09:00:00',
  },
  noon: {
    timeOfDay: 'noon',
    from: '12:00:00',
    to: '12:00:00',
  },
  afternoon: {
    timeOfDay: 'afternoon',
    from: '14:00:00',
    to: '14:00:00',
  },
  evening: {
    timeOfDay: 'evening',
    from: '20:00:00',
    to: '20:00:00',
  },
  night: {
    timeOfDay: 'night',
    from: '22:00:00',
    to: '22:00:00',
  },
  beforeBedTime: {
    timeOfDay: 'beforeBedTime',
    from: '23:00:00',
    to: '23:00:00',
  },
};

export type ScheduleTimeRangeJson = TimeRangeJson & {
  timeOfDay: TimeOfDay;
};

export class ScheduleTimeRange extends TimeRange {
  @observable
  timeOfDay: TimeOfDay = 'any';

  constructor(json?: Partial<ScheduleTimeRangeJson>) {
    super(json);
    if (json) {
      this.timeOfDay = json.timeOfDay ?? 'any';
    }
  }

  toJS(): ScheduleTimeRangeJson {
    return Object.assign(super.toJS(), {
      timeOfDay: this.timeOfDay,
    });
  }
}
