/**
 * Created by neo on 17.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { AthleteDeviceInfo } from '../../../../Model/Athlete/AthleteDeviceInfo';

export type DeviceInfoTableRowValueRendererProps = {
  deviceInfo: AthleteDeviceInfo;
  infoKey: string;
};

export const DeviceInfoTableRowValueRenderer: React.FC<DeviceInfoTableRowValueRendererProps> = observer(
  ({ deviceInfo, infoKey }) => {
    const value = deviceInfo.data.get(infoKey);

    if (typeof value !== 'object') {
      return value;
    }

    return null;
  },
);
