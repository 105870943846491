import * as React from 'react';
import { useLocalStore, observer } from 'mobx-react';
import { Row, Col, Container } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { WorkoutLogExerciseBlock } from './WorkoutLogExerciseBlock';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useEffect } from 'react';
import { AthleteLink } from '../../../../Components/AthleteLink';
import { WorkoutLog } from '../../../../Model/WorkoutLog';
import dayjs, { Dayjs } from 'dayjs';
import { Button } from 'antd';

export const WorkoutLogViewScreen: React.FC<unknown> = observer(() => {
  const { workoutLogId } = useParams<any>();

  const store = useLocalStore(() => ({
    workoutLog: new WorkoutLog(),
    async load(workoutLogId: string) {
      store.workoutLog = (await WorkoutLog.get(workoutLogId)) || new WorkoutLog();
    },
    get date(): string {
      return dayjs(this.workoutLog.startTimestamp).format('DD.MM.YYYY HH:mm');
    },
    get calories(): string {
      return `${(Math.round(this.workoutLog.calories * 100) / 100).toFixed(2)} kcal`;
    },
    get duration(): string {
      return `${(Math.round(this.workoutLog.duration * 100) / 100).toFixed(2)} kcal`;
    },
    get canStop(): boolean {
      return !this.workoutLog.ended && Date.now() - this.workoutLog.startTimestamp > 3600 * 1000;
    },
  }));

  useEffect(() => {
    workoutLogId && store.load(workoutLogId);
  }, [workoutLogId, store]);

  const handleStop = React.useCallback(() => {
    if (window.confirm('Stop workout?')) {
      store.workoutLog.end();
    }
  }, [store]);

  const handleCopy = React.useCallback(() => {
    store.workoutLog.save();
    // const json = store.workoutLog.toJS(true);
    // copy(JSON.stringify(json));
    // message.success('WorkoutLog JSON copied to clipboard');
  }, [store]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>
            <Link to={`/planning/workout/${store.workoutLog.workoutId ?? store.workoutLog.workout?.id}`}>
              {store.workoutLog.workout?.name ?? '<no workout name>'}
            </Link>
          </h1>
          <small className="text-muted">{store.date}</small> <small className="text-muted">{store.calories}</small>
          <h4>
            <AthleteLink athlete={store.workoutLog.athlete} />
          </h4>
        </Col>
        {store.canStop ? (
          <Col xs="auto">
            <Button type="primary" color="danger" onClick={handleStop}>
              End Workout
            </Button>
          </Col>
        ) : null}
        <Col xs="auto">
          <Button type="primary" onClick={handleCopy}>
            Save again
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        {store.workoutLog.exerciseBlocks.map((e, i) => (
          <WorkoutLogExerciseBlock key={`${i}`} exerciseBlock={e} />
        ))}
      </SingleColRow>
    </Container>
  );
});
