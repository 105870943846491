import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { AllNodeWidget } from './AllNodeWidget';
import { AllNodeModel } from './AllNodeModel';
import { AllStage } from '../../../../../../../../Model/Coach/Stages/Logical/AllStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class AllNodeModelFactory extends AbstractReactFactory<AllNodeModel, DiagramEngine> {
  constructor() {
    super('logical:all');
  }

  generateReactWidget(event): JSX.Element {
    return <AllNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new AllNodeModel(new AllStage(new Pipeline()));
  }
}
