/**
 * Created by neo on 10.01.21.
 */
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { Activity } from '../Activity/Activity';
import { Instructor, InstructorJson } from './Instructor';

export type ActivityWorkoutEntryJson = ExploreEntryJson & {
  activityIdentifier: string;
  data: Record<string, any>;
  instructor?: InstructorJson;
  recommendedMusicKey?: string;
  durationInMinutes?: number;
};

export class ActivityWorkoutEntry extends ExploreEntry {
  @observable
  activityIdentifier: string = '';
  @observable
  data: Record<string, any> = {};
  @observable.ref
  instructor?: Instructor;
  @observable
  recommendedMusicKey?: string;
  @observable
  durationInMinutes?: number;
  @observable
  activity?: Activity;
  fetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<ActivityWorkoutEntryJson>) {
    super(
      Object.assign(
        json ?? {},
        {
          type: 'activityWorkout',
        },
        { version: { minVersionIos: '1.30.0', minVersionAndroid: '1.30.0' } },
      ),
    );
    if (json) {
      this.activityIdentifier = json.activityIdentifier ?? '';
      this.data = json.data ?? {};
      this.instructor = json.instructor ? new Instructor(json.instructor) : undefined;
      this.recommendedMusicKey = json.recommendedMusicKey;
      this.durationInMinutes = json.durationInMinutes;
    }
    onBecomeObserved(this, 'activity', this.startFetchParent);
    onBecomeUnobserved(this, 'activity', this.stopFetchParent);
  }

  toJS(): ActivityWorkoutEntryJson {
    return Object.assign(super.toJS(), {
      activityIdentifier: this.activityIdentifier,
      instructor: this.instructor?.toJS(),
      recommendedMusicKey: this.recommendedMusicKey,
      durationInMinutes: this.durationInMinutes,
      data: toJS(this.data),
    });
  }

  startFetchParent = () => {
    this.fetchDisposer = reaction(
      () => this.activityIdentifier,
      (activityIdentifier) => {
        if (activityIdentifier) {
          Activity.get(activityIdentifier).then((result) => runInAction(() => (this.activity = result)));
        } else {
          runInAction(() => (this.activity = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchParent = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = undefined;
  };

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<ActivityWorkoutEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'activityWorkout' })).then((res) =>
      (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static async findOne(id: string): Promise<ActivityWorkoutEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new ActivityWorkoutEntry(res));
  }
}
