/**
 * Created by neo on 25.10.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Col, Row } from 'reactstrap';
import { Button, Form, Space } from 'antd';
import { GymChallenge } from '../../../Model/GymChallenge/GymChallenge';

export type GymChallengeLinksProps = {
  challenge: GymChallenge;
};

export const GymChallengeLinks: React.FC<GymChallengeLinksProps> = observer(({ challenge }) => {
  const [copiedDynamicLink, setCopiedDynamicLink] = useState<boolean>(false);
  const [copiedNormalLink, setCopiedNormalLink] = useState<boolean>(false);

  const normalLink = `https://coach.kinastic.com/challenge/${challenge.id}`;
  const dynamicLink = `https://open.kinastic.com?link=${encodeURIComponent(
    normalLink,
  )}&apn=app.flinq.athlete&isi=1465156373&ibi=app.flinq.athlete`;

  const handleCopyDynamicLink = React.useCallback(() => {
    copy(`${dynamicLink}`);
    setCopiedDynamicLink(true);
    setTimeout(() => {
      setCopiedDynamicLink(false);
    }, 3000);
  }, [dynamicLink]);

  const handleCopyNormalLink = React.useCallback(() => {
    copy(`${normalLink}`);
    setCopiedNormalLink(true);
    setTimeout(() => {
      setCopiedNormalLink(false);
    }, 3000);
  }, [normalLink]);

  return (
    <Row>
      <Col xs={6}>
        <Form.Item label="Notification Link" extra="Use this link in notifications">
          <Space>
            <span>{normalLink || 'No link available'}</span>
            <Button type="primary" shape="round" size="small" disabled={!normalLink} onClick={handleCopyNormalLink}>
              {copiedNormalLink ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </Space>
        </Form.Item>
      </Col>
      <Col xs={6}>
        <Form.Item label="Dynamic Link" extra="Use this links in any other communication like emails NOT notifications">
          <Space>
            <span>{dynamicLink || 'No link available'}</span>
            <Button type="primary" shape="round" size="small" disabled={!dynamicLink} onClick={handleCopyDynamicLink}>
              {copiedDynamicLink ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </Space>
        </Form.Item>
      </Col>
    </Row>
  );
});
