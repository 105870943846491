import { Stage } from '../Stage';
import { EmptySignal } from '../../Signal/EmptySignal';
import { dataSignal } from '../../Signal/DataSignal';

export class OrStage extends Stage<any> {
  process() {
    let signals = this.take();
    if (signals.length > 0) {
      while (signals.length > 0) {
        const dataSignals = signals.filter(dataSignal);
        if (dataSignals.length > 0) {
          // TODO this will replicate a possible signal
          dataSignals.forEach((signal) => this.processNext(signal));
        } else {
          this.processNext(new EmptySignal());
        }
        signals = this.take();
      }
    } else {
      this.processNext(new EmptySignal());
    }
  }

  toJS(): any {
    return super.toJS();
  }

  get type(): string {
    return 'logical:or';
  }
}
