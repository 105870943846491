/**
 * Created by neo on 07.06.2024
 */
import { Audited, AuditedJson } from '../Audited';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Pageable } from '../Pageable';
import { languagePriority } from '../LocalizedNamedEntity';
import { ExploreEntry } from './ExploreEntry';
import { ExploreEntryBuilder } from './ExploreEntryBuilder';

export type ExploreFaqQueryRequest = Pageable & {
  contentId?: string;
  query?: string;
  athleteId?: string;
  gymId?: string;
};

export type ExploreFaqJson = AuditedJson & {
  contentId: string;
  question: LocalizedValueJson[];
  answer: LocalizedValueJson[];
  athleteId?: string;
  gymId?: string;
  responderName?: string;
  responderImage?: string;
};

export class ExploreFaq extends Audited {
  @observable
  contentId: string = '';
  @observable
  question: LocalizedValue[] = [];
  @observable
  answer: LocalizedValue[] = [];
  @observable
  responderName?: string;
  @observable
  responderImage?: string;
  @observable
  athleteId?: string;
  @observable
  gymId?: string;

  @observable
  entry?: ExploreEntry;

  constructor(json?: Partial<ExploreFaqJson>) {
    super(json);

    if (json) {
      this.contentId = json.contentId ?? '';
      this.question = json.question?.map((q) => new LocalizedValue(q)) ?? [];
      this.answer = json.answer?.map((a) => new LocalizedValue(a)) ?? [];
      this.responderName = json.responderName;
      this.responderImage = json.responderImage;
      this.athleteId = json.athleteId;
      this.gymId = json.gymId;
    }

    onBecomeObserved(this, 'entry', () => {
      if (!this.entry) {
        ExploreEntryBuilder.findOne(this.contentId, this.gymId).then((entry) => runInAction(() => (this.entry = entry)));
      }
    });
  }

  toJS(): ExploreFaqJson {
    return Object.assign(super.toJS(), {
      contentId: this.contentId,
      question: this.question.map((q) => q.toJS()),
      answer: this.answer.map((a) => a.toJS()),
      responderName: this.responderName,
      responderImage: this.responderImage,
      athleteId: this.athleteId,
      gymId: this.gymId,
    });
  }

  save(): Promise<ExploreFaq> {
    return HttpBackend.post(`/coach/explore/faq/admin`, this.toJS()).then(() => this);
  }

  delete(): Promise<ExploreFaq> {
    return HttpBackend.delete(`/coach/explore/faq/admin/${this.id}`).then(() => this);
  }

  getQuestion(lang: string): string {
    return this.question.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultQuestion;
  }

  getAnswer(lang: string): string {
    return this.answer.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultAnswer;
  }

  @computed
  get defaultQuestion(): string {
    for (const lang of languagePriority) {
      const entry = this.question.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.question[0];
    return first?.value ?? '';
  }

  @computed
  get defaultAnswer(): string {
    for (const lang of languagePriority) {
      const entry = this.answer.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.answer[0];
    return first?.value ?? '';
  }

  static find(request?: Partial<ExploreFaqQueryRequest>): Promise<ExploreFaq[]> {
    return HttpBackend.get(`/coach/explore/faq/admin`, request).then((res) =>
      (res ?? []).map((r) => new ExploreFaq(r)),
    );
  }

  static count(request?: Partial<ExploreFaqQueryRequest>): Promise<number> {
    return HttpBackend.get(`/coach/explore/faq/admin/count`, request);
  }
}
