/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';
import { TagCondition } from '../../../../Model/Coach/SuperMacro/TagCondition';
import { Button } from 'antd';
import { notUndefined } from '../../../../Utils/notUndefined';
import { ProgramParams } from '../../../../Model/Coach/Program/ProgramParams';

const allowedTags = [
  'duration:short',
  'duration:medium',
  'duration:long',
  'age:junior',
  'age:adult',
  'age:senior',
  'age:elder',
  'gender:male',
  'gender:female',
  'activity:sitting',
  'activity:standing',
  'activity:physical',
  'equipment:yes',
  'equipment:no',
  'equipment:bottle',
  'level:beginner',
  'level:intermediate',
  'level:advanced',
  'goal:muscle_growth',
  'goal:stay_fit',
  'goal:weight_loss',
  'featured:yes',
].sort((a, b) => a.localeCompare(b));

const defaultOptions = allowedTags.map((value) => ({ value, label: value }));

export type ProgramParamsTagsInputProps = {
  params: ProgramParams;
  onChange?: (tagCondition: ProgramParams) => void;
};

export const ProgramParamsTagsInput: React.FC<ProgramParamsTagsInputProps> = observer(
  ({ params, onChange }) => {
    const includedTags = params.tags.map((value) => ({ label: value, value }));
    const includedTagCategories = params.tags.map((t) => t.split(':')[0]).filter(notUndefined);

    const allowedIncludedTags = defaultOptions.filter(
      ({ value }) => !includedTagCategories.some((cat) => value.startsWith(cat)),
    );

    const handleIncludedChange = React.useCallback(
      (tags?: any[]) => {
        runInAction(() => {
          params.tags.splice(0, params.tags.length);
          (tags ?? []).map((t) => t.value).forEach((t) => params.tags.push(t));
        });
        onChange && onChange(params);
      },
      [params, onChange],
    );

    return (
      <CreatableSelect
        isClearable
        isMulti
        options={allowedIncludedTags}
        onChange={handleIncludedChange as any}
        value={includedTags}
        placeholder="Tags"
      />
    );
  },
);
