/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { UserPropertiesData } from './UserPropertiesStatsChart';
import { Col, Row } from 'reactstrap';
import { ChartColors } from '../ChartColors';
import { allUserPropertiesStatsByAge, gymUserPropertiesStatsByAge } from './Queries/queryUserPropertiesStatsByAge';
import { UserPropertiesStatsAgeChart } from './UserPropertiesStatsAgeChart';
import { SingleColRow } from '../../../Components/SingleColRow';
import {
  allUserPropertiesStatsByAgeGender,
  gymUserPropertiesStatsByAgeGender,
} from './Queries/queryUserPropertiesStatsByAgeGender';
import { UserPropertiesStatsAgeGenderChart } from './UserPropertiesStatsAgeGenderChart';

const subCategories = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];
const dataKeys = ['MALE', 'FEMALE', 'UNKNOWN', 'TRANSGENDER'];

export type UserPropertiesStatsAgeGenderProps = {
  gym?: Gym;
};

export const UserPropertiesStatsAgeGender: React.FC<UserPropertiesStatsAgeGenderProps> = observer(({ gym }) => {
  const [data, setData] = useState<UserPropertiesData | undefined>();
  const [loading, setLoading] = useState(false);

  const userProperties = Object.keys(data ?? {});

  useEffect(() => {
    setData(undefined);
    setLoading(true);
    AnalyticsData.query({
      query: gym ? gymUserPropertiesStatsByAgeGender : allUserPropertiesStatsByAgeGender,
      parameters: {
        gym_id: {
          value: gym?.id ?? '',
        },
      },
    })
      .then((result) =>
        setData(
          result.reduce((agg, row) => {
            const key = row.key;
            const existing = agg[key];
            if (existing) {
              const category = existing.find((e) => e.name === row.value);
              if (category) {
                category[`${row.age}_${row.gender}`] = Number(row.user_count);
              } else {
                existing.push({ name: row.value, [`${row.age}_${row.gender}`]: Number(row.user_count) });
              }
              // existing.push({ name: row.value, user_count: Number(row.user_count) });
            } else {
              agg[key] = [{ name: row.value, [`${row.age}_${row.gender}`]: Number(row.user_count) }];
            }

            return agg;
          }, {} as UserPropertiesData),
        ),
      )
      .finally(() => setLoading(false));
  }, [gym]);

  console.log('UserPropertiesStatsAgeGender', data, userProperties);

  return (
    <SingleColRow>
      <h5>User Properties Stats by Age & Gender</h5>
      <Row>
        {Array.from(Object.entries(data ?? {})).map(([property, categories], index) => (
          <Col key={property} md={4} lg={3}>
            <UserPropertiesStatsAgeGenderChart
              property={property}
              categories={categories as any}
              subCategories={subCategories}
              dataKeys={dataKeys}
            />
          </Col>
        ))}
      </Row>
    </SingleColRow>
  );
});
