/**
 *
 * Created by andreaskarantzas on 01.08.18.
 */
import { observable, action, ObservableMap } from 'mobx';
import { TimeRange } from './TimeRange';

export class OpeningHours {
  @observable
  timeRanges: ObservableMap<any, TimeRange[]> = observable.map({});
  @observable
  exceptions: any;

  constructor(source?: any) {
    if (source) {
      this.timeRanges = observable.map(source.timeRanges || {});
      this.exceptions = source.exceptions;
    }
  }

  getTimeRange(key: string): string | undefined {
    const value = this.timeRanges.get(key);
    if (value && value.length > 0) {
      return `${value[0].from} - ${value[0].to}`;
    }
    return undefined;
  }

  @action
  setTimeRangeFrom(key: string, from: string, to: string) {
    this.timeRanges.set(key, [new TimeRange({ from, to })]);
  }
}
