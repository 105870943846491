/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';

export type SignupOnboardingChartProps = {
  data: AnalyticsRow[];
};

export const SignupOnboardingChart: React.FC<SignupOnboardingChartProps> = observer(({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart height={250} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="event_date" />
        <YAxis yAxisId="left" label={{ value: 'Per Day', position: 'insideTopLeft', dy: -25 }} />
        <YAxis
          yAxisId="right"
          label={{ value: 'Accumulated', position: 'insideTopRight', dy: -25 }}
          orientation="right"
          tickLine={false}
          axisLine={false}
        />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="sign_up_total"
          stroke="#913CA4"
          strokeWidth={2}
          dot={false}
          name="# Sign Ups"
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="onboarding_completed_total"
          stroke="#09D6B6"
          strokeWidth={2}
          dot={false}
          name="# Onboardings"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="sign_up_cumulative"
          stroke="#1E493F"
          connectNulls
          strokeWidth={2}
          dot={false}
          name="# Sign Ups (accumulated)"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="onboarding_completed_cumulative"
          stroke="#E32C6C"
          connectNulls
          strokeWidth={2}
          dot={false}
          name="# Onboardings (accumulated)"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
