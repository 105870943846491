/**
 * Created by neo on 07.05.21.
 */
import { observable } from 'mobx';

export type GoogleTtsInputJson = {
  text?: string;
  ssml?: string;
};

export class GoogleTtsInput {
  @observable
  text?: string;
  @observable
  ssml?: string;

  constructor(json?: Partial<GoogleTtsInputJson>) {
    if (json) {
      this.text = json.text;
      this.ssml = json.ssml;
    }
  }

  toJS(): GoogleTtsInputJson {
    return {
      text: this.text,
      ssml: this.ssml,
    };
  }
}
