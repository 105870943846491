/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { LogicalNodeWidget, LogicalNodeWidgetProps } from '../LogicalNodeWidget';

export type EqualsNodeWidgetProps = {} & LogicalNodeWidgetProps;

export const NotInNodeWidget: React.FC<EqualsNodeWidgetProps> = observer(
  ({ node, engine, size }: EqualsNodeWidgetProps) => {
    return <LogicalNodeWidget engine={engine} node={node} size={size} title="NIN" />;
  },
);
