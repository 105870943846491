/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { LogicalNodeWidget, LogicalNodeWidgetProps } from '../LogicalNodeWidget';

export type LessThanNodeWidgetProps = {} & LogicalNodeWidgetProps;

export const LessThanNodeWidget: React.FC<LessThanNodeWidgetProps> = observer(
  ({ node, engine, size }: LessThanNodeWidgetProps) => {
    return <LogicalNodeWidget engine={engine} node={node} size={size} title="LT" />;
  },
);
