/**
 * Created by neo on 25.05.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';
import { Table } from 'reactstrap';
import { TranslationTableRow } from './TranslationTableRow';

export type TranslationTableProps = {
  mergedTranslations: MergedTranslation[];
  namespaces: string[];
};

export const TranslationTable: React.FC<TranslationTableProps> = observer(({ mergedTranslations, namespaces }) => {
  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {mergedTranslations.map((entry, index) => (
          <TranslationTableRow key={entry.id} index={index} mergedTranslation={entry} namespaces={namespaces} />
        ))}
      </tbody>
    </Table>
  );
});
