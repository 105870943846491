/**
 * Created by neo on 14.11.2023
 */
import { RouteLocationPositionType } from './RouteLocationPositionType';
import { observable } from 'mobx';

export type RouteChallengeLocationPositionJson = {
  type: RouteLocationPositionType;
  xorLat: number;
  yorLng: number;
};

export class RouteChallengeLocationPosition {
  @observable
  type: RouteLocationPositionType = 'coordinates';
  @observable
  xorLat: number = 0;
  @observable
  yorLng: number = 0;

  constructor(json?: Partial<RouteChallengeLocationPositionJson>) {
    if (json) {
      this.type = json.type ?? 'coordinates';
      this.xorLat = json.xorLat ?? 0;
      this.yorLng = json.yorLng ?? 0;
    }
  }

  toJS(): RouteChallengeLocationPositionJson {
    return {
      type: this.type,
      xorLat: this.xorLat,
      yorLng: this.yorLng,
    };
  }
}
