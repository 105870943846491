/**
 * Created by katarinababic on 17.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type NumberPointElementProps = {
  component: NativeFeedComponent;
  lang?: string;
};

export const NumberPointElement: React.FC<NumberPointElementProps> = observer(({ component, lang }) => {
  const { classes } = useStyles();

  return (
    <ol className={classes.container}>
      {component.children.map((c) => (
        <li key={c.key} className={classes.text}>
          {c.getText(lang ?? 'de') ?? 'number point'}
        </li>
      ))}
    </ol>
  );
});

const useStyles = makeStyles()(() => ({
  container: {
    paddingLeft: 32,
    paddingRight: 16,
    paddingTop: 0,
  },
  text: {
    color: '#3c3c3c',
    fontSize: 16,
    fontFamily: 'Inter',
    textAlign: 'left',
  },
}));
