import { action, observable, toJS } from 'mobx';
import { EquipmentConfiguration, EquipmentConfigurationJson } from '../EquipmentConfiguration';

const mainCats = ['duration:', 'age:', 'level:', 'gender:', 'goal:', 'frequency:', 'activity:'];

export type ProgramParamsJson = {
  tags: string[];
  equipmentConfiguration: EquipmentConfigurationJson;
};

export class ProgramParams {
  @observable
  tags: string[] = [];
  @observable
  equipmentConfiguration = new EquipmentConfiguration();

  constructor(json?: Partial<ProgramParamsJson>) {
    if (json) {
      this.tags = json.tags ?? [];
      this.equipmentConfiguration = new EquipmentConfiguration(json.equipmentConfiguration);
    }
  }

  private combineTags(oldTags: string[], newTags: string[]) {
    return oldTags
      .filter((oldTag) => {
        const cat = oldTag.substring(0, oldTag.indexOf(':') + 1);
        if (mainCats.some((main) => oldTag.startsWith(main))) {
          return !newTags.some((n) => n.startsWith(cat));
        }
        return true;
      })
      .concat(newTags);
  }

  merge(other: ProgramParams) {
    return new ProgramParams({
      tags: this.combineTags(this.tags, other.tags),
      equipmentConfiguration: this.equipmentConfiguration.merge(other.equipmentConfiguration),
    });
  }

  @action
  setSingleTag(tag: string) {
    const index = tag.indexOf(':');
    if (index !== -1) {
      const group = tag.substr(0, index);
      this.tags = this.tags.filter((t) => !t.startsWith(`${group}:`));
      this.tags.push(tag);
    }
  }

  @action
  removeTagGroup(tagGroup: string) {
    this.tags = this.tags.filter((t) => !t.startsWith(`${tagGroup}:`));
  }

  toJS() {
    return {
      tags: toJS(this.tags),
      equipmentConfiguration: this.equipmentConfiguration.toJS(),
    };
  }
}
