/**
 * Created by katarinababic on 4.5.22.
 */

export const queryWorkoutFinished = `
SELECT
  e_id.value.string_value AS workout_template_id,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS finished_sessions,
  AVG(e_percent.value.double_value) AS avg_percentage_completed
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS e_id,
  UNNEST (event_params) AS e_percent
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'Workout_finished'
  AND e_id.key = 'workoutTemplateId'
  AND e_id.value.string_value = @workout_template_id
  AND e_percent.key = 'percentageCompleted'
GROUP BY
  workout_template_id
`;
