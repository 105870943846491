/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Form, Select } from 'antd';
import { ExerciseType } from '../../../../../Model/Exercise/ExerciseType';

export type ExerciseTypeSelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExerciseTypeSelect: React.FC<ExerciseTypeSelectProps> = observer(
  ({ exercise, disabled }: ExerciseTypeSelectProps) => {
    const handleChange = React.useCallback((value) => (exercise.type = value as ExerciseType), [exercise]);

    return (
      <Form.Item label="Type">
        <Select value={exercise.type} onChange={handleChange} disabled={disabled}>
          <Select.Option value="ENDURANCE">{'Endurance'}</Select.Option>
          <Select.Option value="STRENGTH">{'Strength'}</Select.Option>
          <Select.Option value="BALANCE">{'Balance'}</Select.Option>
          <Select.Option value="FLEXIBILITY">{'Flexibility'}</Select.Option>
          <Select.Option value="MOBILISATION">{'Mobilisation'}</Select.Option>
          <Select.Option value="MEDITATION">{'Meditation'}</Select.Option>
          <Select.Option value="RELAX">{'Relax'}</Select.Option>
          <Select.Option value="WORKPLACE">{'Workplace'}</Select.Option>
          <Select.Option value="YOGA">{'Yoga'}</Select.Option>
        </Select>
      </Form.Item>
    );
  },
);
