/**
 * Created by neo on 01.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { EquipmentTypeInput } from '../EquipmentTypeInput';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';

export type ExerciseVariationFilterRequiredEquipmentTypesProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterRequiredEquipmentTypes: React.FC<ExerciseVariationFilterRequiredEquipmentTypesProps> = observer(
  ({ filter }: ExerciseVariationFilterRequiredEquipmentTypesProps) => {
    return <EquipmentTypeInput label="Required Equipment Types" container={filter.requiredEquipmentTypes} />;
  },
);
