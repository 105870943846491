/**
 * Created by neo on 14.12.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { GymCoachSubscription } from '../../../../../Model/Subscription/GymCoachSubscription';
import { Table } from 'reactstrap';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Button, Popconfirm } from 'antd';
import { Link, Route, useNavigate, Routes } from 'react-router-dom';
import { GymCoachSubscriptionModal } from './GymCoachSubscriptionModal';
import dayjs, { Dayjs } from 'dayjs';

export type GymCoachSubscriptionTabProps = {
  gym: Gym;
};

export const GymCoachSubscriptionTab: React.FC<GymCoachSubscriptionTabProps> = observer(({ gym }) => {
  const [subscriptions, setSubscriptions] = useState<GymCoachSubscription[]>([]);
  const history = useNavigate();

  useEffect(() => {
    GymCoachSubscription.find({ gymId: gym.id, sort: 'validFrom,DESC' }).then((result) => setSubscriptions(result));
  }, [gym]);

  const handleCreate = React.useCallback(() => {
    history(`new`);
  }, [history]);

  const handleDeleteSubscription = React.useCallback((subscription: GymCoachSubscription) => {
    subscription.delete().then((res) =>
      setSubscriptions((prev) => {
        return prev.splice(
          prev.findIndex((s) => s.id === subscription.id),
          1,
        );
      }),
    );
  }, []);

  return (
    <React.Fragment>
      <SingleColRow>
        <div style={{ float: 'right' }}>
          <Button type="primary" onClick={handleCreate}>
            Create Subscription
          </Button>
        </div>
      </SingleColRow>
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Plan</th>
              <th>Valid From</th>
              <th>Valid Until</th>
              <th>Licenses</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {subscriptions
              .filter((sub) => sub.plan !== 'free')
              .map((subscription) => (
                <tr key={subscription.id}>
                  <td>
                    <Link to={`${subscription.id}`}>{subscription.id}</Link>{' '}
                  </td>
                  <td>{subscription.plan}</td>
                  <td>{dayjs(subscription.validFrom).format('DD.MM.YYYY HH:mm')}</td>
                  <td>
                    {subscription.validUntil ? dayjs(subscription.validUntil).format('DD.MM.YYYY HH:mm') : 'unlimited'}
                  </td>
                  <td>{subscription.licenseCount ?? 'unlimited'}</td>
                  <td>
                    <Popconfirm title={'Delete Subscription?'} onConfirm={() => handleDeleteSubscription(subscription)}>
                      <Button type="link" danger>
                        Delete
                      </Button>
                    </Popconfirm>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </SingleColRow>
      <Routes>
        <Route path=":subscriptionId" element={<GymCoachSubscriptionModal />} />
      </Routes>
    </React.Fragment>
  );
});
