import { Stage } from './Stage';
import { PipelineContext } from '../PipelineContext';
import { Exercise } from '../../Exercise/Exercise';
import { ExerciseBlock } from '../../ProgramPortfolio/ExerciseBlock';
import { ExerciseBlockSet } from '../../ProgramPortfolio/ExerciseBlockSet';
import { LocalizedValue } from '../../LocalizedValue';
import { toJS } from 'mobx';
import { DataSignal, dataSignal } from '../Signal/DataSignal';
import { notUndefined } from '../../../Utils/notUndefined';

export interface TrackingKeyConfig {
  key: string;
  minValue?: number | string;
}

export interface ExerciseBlockStageConfig {
  description?: LocalizedValue[];
  trackingKeys?: Array<TrackingKeyConfig>;
  startSets?: any; // default 1 or max if not set (can be higher than maxSets -> decrements)
}

export class ExerciseBlockStage extends Stage<ExerciseBlockStageConfig> {
  process() {
    console.log('noop');
  }

  get type(): string {
    return 'exerciseBlock';
  }
}
