/**
 * Created by katarinababic on 15.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Alert,
  Spinner,
  Col,
} from 'reactstrap';
import { FolderContent } from '../../../Model/Media/FileStorage/FolderContent';

export type CreateFolderDialogProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

export const CreateFolderDialog: React.FC<CreateFolderDialogProps> = observer(({ isOpen, onClose }) => {
  const [name, setName] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [processing, setProcessing] = React.useState(false);

  const handleNameChange = React.useCallback(({ target: { value } }) => {
    setName(value);
  }, []);

  const toggle = React.useCallback(() => {
    if (isOpen) {
      onClose?.();
    }
  }, [onClose]);

  const dismiss = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleCreateFolder = React.useCallback(() => {
    setProcessing(true);
    FolderContent.createFolder(name)
      .then((res) => {
        console.log('CreateFolderDialog::handleCreateFolder', res);
        onClose?.();
      })
      .catch((e) => setErrorMessage(e.message))
      .finally(() => setProcessing(false));
  }, [name, onClose]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Create Folder</ModalHeader>
      <ModalBody>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <FormGroup>
          <Label>{'Add a name that describes this folder. Example: "Winter Kits"'}</Label>
          <Input type="text" onChange={handleNameChange} value={name} autoFocus />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {processing ? (
          <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </Col>
        ) : (
          <React.Fragment>
            <Button color="primary" onClick={handleCreateFolder}>
              Create
            </Button>
            <Button color="secondary" onClick={dismiss}>
              Cancel
            </Button>
          </React.Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
});
