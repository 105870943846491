/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { EarnModelTemplate } from '../../../../Model/Engagement/EarnModelTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, DatePicker, Form, Input, message } from 'antd';
import { reaction, runInAction } from 'mobx';
import { EarnModelTemplateCategory } from '../../../../Model/Engagement/EarnModelTemplateCategory';
import { EarnModelTemplateCategoryEditor } from './EarnModelTemplateCategoryEditor';
import { Route, Routes } from 'react-router';
import { EarnModelTemplateCategoryEditModal } from './EarnModelTemplateCategoryEditModal';
import { EarnModelTemplateRuleEditModal } from './EarnModelTemplateRuleEditModal/EarnModelTemplateRuleEditModal';
import dayjs, { Dayjs } from 'dayjs';
import AsyncSelect from 'react-select/async';
import { Gym } from '../../../../Model/Gym/Gym';

export type EarnModelTemplateViewScreenProps = {};

export const EarnModelTemplateViewScreen: React.FC<EarnModelTemplateViewScreenProps> = observer((props) => {
  const [template, setTemplate] = useState(new EarnModelTemplate());
  const { earnModelTemplateId } = useParams();

  const history = useNavigate();

  const [gym, setGym] = useState<Gym | undefined>();

  useEffect(() => {
    if (earnModelTemplateId && earnModelTemplateId !== 'create') {
      EarnModelTemplate.findOne(earnModelTemplateId).then((res) => setTemplate(res));
    } else {
      setTemplate(new EarnModelTemplate());
    }
  }, [earnModelTemplateId]);

  useEffect(
    () =>
      reaction(
        () => template.gymId,
        (gymId) => {
          setGym(undefined);
          if (gymId) {
            Gym.get(gymId).then((res) => setGym(res));
          }
        },
        { fireImmediately: true },
      ),
    [template],
  );

  const handleSave = React.useCallback(() => {
    template
      .save()
      .then(() => history(`/engagement/earn-model-templates/${template.id}`, { replace: true }))
      .then(() => message.success('Template saved'))
      .catch(() => message.error('Failed to save template'));
  }, [history, template]);

  const handleChangeTemplateName = React.useCallback(
    ({ target: { value } }) => runInAction(() => (template.templateName = value)),
    [template],
  );

  const handleCreateCategory = React.useCallback(() => {
    const newCategory = new EarnModelTemplateCategory({
      templateName: `New Category ${template.categories.length + 1}`,
    });
    runInAction(() => template.categories.push(newCategory));
    history(`${newCategory.id}`);
  }, [template, history]);

  const handleChangePublishDate = React.useCallback(
    (date) => runInAction(() => (template.activeFrom = date?.startOf('day').toDate())),
    [template],
  );

  const handleChangeUnpublishDate = React.useCallback(
    (date) => runInAction(() => (template.activeUntil = date?.endOf('day').toDate())),
    [template],
  );

  const fetchGym = React.useCallback((query: string) => Gym.find({ query }), []);

  const optionLabel = React.useCallback((obj) => obj.name, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeObject = React.useCallback(
    (value?: Gym) => {
      runInAction(() => (template.gymId = value?.id));
    },
    [template],
  );

  return (
    <React.Fragment>
      <Container>
        <Form layout="vertical">
          <Row>
            <Col>
              <h1>{template.templateName}</h1>
            </Col>
            <Col xs="auto">
              <Button type="primary" onClick={handleSave}>
                Save Template
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Item label="Template Name">
                <Input onChange={handleChangeTemplateName} value={template.templateName} />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item label="Valid From">
                <DatePicker
                  value={template.activeFrom ? dayjs(template.activeFrom) : undefined}
                  onChange={handleChangePublishDate}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item label="Valid Until" extra="Leave blank when forever">
                <DatePicker
                  value={template.activeUntil ? dayjs(template.activeUntil) : undefined}
                  onChange={handleChangeUnpublishDate}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Gym / Company">
                <AsyncSelect
                  value={gym}
                  isClearable={true}
                  loadOptions={fetchGym as any}
                  getOptionLabel={optionLabel}
                  getOptionValue={optionValue as any}
                  onChange={handleChangeObject as any}
                />
              </Form.Item>
            </Col>
            <Col>
              <h3>Categories</h3>
            </Col>
            <Col xs="auto">
              <Button onClick={handleCreateCategory}>Add New Category</Button>
            </Col>
          </Row>
          <EarnModelTemplateCategoryEditor template={template} />
        </Form>
      </Container>
      <Routes>
        <Route path=":categoryId/:ruleId" element={<EarnModelTemplateRuleEditModal template={template} />} />
        <Route path=":categoryId" element={<EarnModelTemplateCategoryEditModal template={template} />} />
      </Routes>
    </React.Fragment>
  );
});
