import { computed, observable } from 'mobx';

export class ExerciseId {
  @observable
  variationId: string = '';
  @observable
  sourceType?: string = undefined;
  @observable
  sourceId?: string = undefined;

  constructor(id: string, sourceType?: string, sourceId?: string) {
    this.variationId = id;
    this.sourceType = sourceType;
    this.sourceId = sourceId;
  }

  toJS(): any {
    return {
      variationId: this.variationId,
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    };
  }

  @computed
  get uniqueId(): string {
    return this.variationId;
  }

  equals(other?: any) {
    if (other === this) {
      return true;
    }
    return other.variationId === this.variationId;
  }

  static fromJson(json: any): ExerciseId {
    return new ExerciseId(json.variationId, json.sourceType, json.sourceId);
  }
}
