/**
 * Created by neo on 18.12.20.
 */
import { observable } from 'mobx';

export type EstimatedCostJson = {
  value: number;
  unit: string;
};

export class EstimatedCost {
  @observable
  value = 0;
  @observable
  unit = '';

  constructor(json?: Partial<EstimatedCostJson>) {
    if (json) {
      this.value = json.value ?? 0;
      this.unit = json.unit ?? '';
    }
  }

  toJS(): EstimatedCostJson {
    return {
      value: this.value,
      unit: this.unit,
    };
  }
}
