/**
 * Created by neo on 01.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { DeeplService } from '../../../../Services/DeeplService';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';

export type TranslateIngredientsComponentProps = {};

export const TranslateIngredientsComponent: React.FC<TranslateIngredientsComponentProps> = observer(() => {
  const [translating, setTranslating] = useState(false);

  const translateCategories = React.useRef(async () => {
    let page = 0;
    let results: Ingredient[] | undefined;
    while (!results || results.length >= 10) {
      results = await Ingredient.search({ size: 10, page });

      await Promise.all(results.map((e) => DeeplService.translateName(e.name)));

      await Promise.all(results.map((e) => e.save()));
      page += 1;
    }
  }).current;

  const handleTranslate = React.useCallback(async () => {
    setTranslating(true);
    try {
      await translateCategories();
    } finally {
      setTranslating(false);
    }
  }, []);

  return (
    <Button onClick={handleTranslate} disabled={translating}>
      Translate All
    </Button>
  );
});
