/**
 * Created by neo on 18.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { Form, Input } from 'antd';
import { VersionInformation } from '../../../../../../Model/VersionInformation';

export type VersionEditorProps = {
  version: VersionInformation;
};

export const VersionEditor: React.FC<VersionEditorProps> = observer(({ version }) => {
  const handleChangeMinVersionIos = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (version.minVersionIos = value ? value : undefined));
    },
    [version],
  );

  const handleChangeMaxVersionIos = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (version.maxVersionIos = value ? value : undefined));
    },
    [version],
  );

  const handleChangeMinVersionAndroid = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (version.minVersionAndroid = value ? value : undefined));
    },
    [version],
  );

  const handleChangeMaxVersionAndroid = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (version.maxVersionAndroid = value ? value : undefined));
    },
    [version],
  );

  return (
    <Row>
      <Col>
        <Form.Item label="iOS min. Version" extra="Default is 0.0.0">
          <Input value={version.minVersionIos} onChange={handleChangeMinVersionIos} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="iOS max. Version" extra="In 99.99% of the cases... don't touch this field">
          <Input value={version.maxVersionIos} onChange={handleChangeMaxVersionIos} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Android min. Version" extra="Default is 0.0.0">
          <Input value={version.minVersionAndroid} onChange={handleChangeMinVersionAndroid} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Android max. Version" extra="In 99.99% of the cases... don't touch this field">
          <Input value={version.maxVersionAndroid} onChange={handleChangeMaxVersionAndroid} />
        </Form.Item>
      </Col>
    </Row>
  );
});
