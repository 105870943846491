/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { CoachWorkoutPhase } from '../../../../../Model/Coach/Program/Workout/CoachWorkoutPhase';
import { CoachExerciseBlockCard } from './CoachExerciseBlockCard';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { WorkoutResponsePhase } from '../../../../../Model/Coach/Script/WorkoutResponsePhase';

export type CoachWorkoutPhaseTableProps = {
  phase: CoachWorkoutPhase | WorkoutResponsePhase;
};

const Container = styled.div`
  flex: 1 1 0;
  min-width: 320px;
  border: 1px solid #ececec;
  margin: 4px 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const CardTitle = styled.div`
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #ececec;
  height: 62px;
`;

const CardBody = styled.div`
  display: flex;
  padding: 8px;
`;

export const CoachWorkoutPhaseTable: React.FC<CoachWorkoutPhaseTableProps> = observer(({ phase }) => {
  return (
    <Container>
      <CardTitle>
        {phase.scriptId ? (
          <Link to={`/coach/workout-script/${phase.scriptId}`}>{`${phase.type} (${phase.pipeline?.name ?? ''})`}</Link>
        ) : (
          <h6>{phase.type}</h6>
        )}
        <div>
          {/*<span>{dayjs.utc(phase.minDuration).format('HH:mm:ss')} - {dayjs.utc(phase.maxDuration).format('HH:mm:ss')}</span>*/}
        </div>
      </CardTitle>
      <CardBody>
        <ContentContainer>
          {phase instanceof CoachWorkoutPhase
            ? phase.exerciseBlocks.map((block, index) => (
                <CoachExerciseBlockCard key={block.exerciseBlockId} exerciseBlock={block} />
              ))
            : phase.exerciseBlocks.map((block, index) => (
                <CoachExerciseBlockCard key={block.exerciseBlockId} exerciseBlock={block} />
              ))}
        </ContentContainer>
      </CardBody>
    </Container>
  );
});
