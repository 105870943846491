/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Affix, Button, Form, Input, message, Modal, Popconfirm, Space } from 'antd';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { SuperMacro } from '../../../../Model/Coach/SuperMacro/SuperMacro';
import { useNavigate, useParams } from 'react-router-dom';
import { SuperMacroTagsInput } from './SuperMacroTagsInput';
import { SuperMacroExcludedTagsInput } from './SuperMacroExcludedTagsInput';
import { MacroCycleTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleTemplate';
import { MacroCycleTemplateList } from './MacroCycleTemplateList';
import { WorkoutSelectionContainer } from './WorkoutSelectionContainer/WorkoutSelectionContainer';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { SuperMacroMainAttributesEditor } from './SuperMacroMainAttributesEditor';

export type SuperMacroViewScreenProps = {};

export const SuperMacroViewScreen: React.FC<SuperMacroViewScreenProps> = observer(
  (props: SuperMacroViewScreenProps) => {
    const history = useNavigate();
    const { superMacroId } = useParams<any>();
    const [superMacro, setSuperMacro] = useState<SuperMacro>(new SuperMacro());

    useEffect(() => {
      if (superMacroId && superMacroId !== 'create' && superMacroId !== 'new') {
        SuperMacro.get(superMacroId).then((macro) => setSuperMacro(macro ?? new SuperMacro()));
      }
    }, [superMacroId]);

    const handleSave = React.useCallback(() => {
      superMacro
        .save()
        .then((superMacro) => {
          message.success('Super Macro saved');
          history(`/coach/super-macro/${superMacro.id}`, { replace: true });
        })
        .catch(() => message.error('Error saving Super Macro'));
    }, [history, superMacro]);

    const handleDelete = React.useCallback(() => {
      superMacro.delete().then(() => history(-1));
    }, [history, superMacro]);

    const handleAddMacroCycle = React.useCallback(() => {
      superMacro.macroCycles.push(new MacroCycleTemplate());
    }, [superMacro]);

    return (
      <Form layout="vertical">
        <Container style={{ paddingBottom: 0 }}>
          <Row>
            <Col>
              <h1>Super Macro</h1>
            </Col>
            <Col md="auto">
              <Space>
                <Popconfirm title={`Delete?`} onConfirm={handleDelete}>
                  <Button type="link" danger onClick={handleDelete}>
                    Delete
                  </Button>
                </Popconfirm>
                <Button type="primary" onClick={handleSave}>
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
          <SuperMacroMainAttributesEditor superMacro={superMacro} />
          <Row>
            <Col>
              <h3>Macro Cycle Levels</h3>
            </Col>
            <Col md="auto">
              <Button type="dashed" onClick={handleAddMacroCycle}>
                Add Macro Cycle
              </Button>
            </Col>
          </Row>
        </Container>
        <div style={{ display: 'flex' }}>
          <div style={{ minWidth: 250, width: '20%', maxWidth: 300 }}>
            <Affix offsetTop={36}>
              <WorkoutSelectionContainer />
            </Affix>
          </div>
          <div style={{ flex: 1 }}>
            <MacroCycleTemplateList superMacro={superMacro} />
          </div>
        </div>
      </Form>
    );
  },
);
