import { observable } from 'mobx';

export class AxonEventProcessorTracker {
  @observable
  clientId: string = '';
  @observable
  segmentId: number = 0;
  @observable
  caughtUp: boolean = false;
  @observable
  replaying: boolean = false;
  @observable
  tokenPosition: number = 0;
  @observable
  errorState: string = '';
  @observable
  onePartOf: number = 0;

  constructor(json?: any) {
    if (json) {
      this.clientId = json.clientId;
      this.segmentId = json.segmentId;
      this.caughtUp = json.caughtUp;
      this.replaying = json.replaying;
      this.tokenPosition = json.tokenPosition;
      this.errorState = json.errorState;
      this.onePartOf = json.onePartOf;
    }
  }
}
