/**
 * Created by neo on 05.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Col, Row, Spinner } from 'reactstrap';
import { DatePicker, Form } from 'antd';
import { ExploreIndexScreenChart } from './ExploreIndexScreenChart';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import dayjs, { Dayjs } from 'dayjs';
import styled from '@emotion/styled';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { ExploreContent } from '../../../Model/Explore/ExploreContent';
import { ExploreContentBuilder } from '../../../Model/Explore/ExploreContentBuilder';
import { PageResult } from '../../../Model/PageResult';

const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const getQuery = (category: ExploreCategory, categoryContents: ExploreContent[]) =>
  `
        SELECT
          SUM(select_count) AS total_select_count,
        FROM (
          SELECT
            COUNT(*) AS select_count,
            p_id.value.string_value AS id
          FROM
            \`flinq-6796d.analytics_201736462.events_*\`,
            UNNEST (event_params) AS p_id
          WHERE
            _TABLE_SUFFIX BETWEEN @start_date
            AND @end_date
            AND event_name = 'select_content'
            AND p_id.key = 'item_id'
            AND (p_id.value.string_value = '${category.identifier}' OR 
               ${categoryContents
                 .map((c) => `p_id.value.string_value = '${c instanceof ExploreCategory ? c.identifier : c.id}'`)
                 .join(' OR ')}
            )
          GROUP BY
            id
          ORDER BY
            id )
      `;

const getQueryForCardLink = (category: ExploreCategory) => `
    SELECT
        SUM(select_count) AS total_select_count,
    FROM (
        SELECT
            COUNT(*) AS select_count,
            params_2.value.string_value AS id,
        FROM
            \`flinq-6796d.analytics_201736462.events_*\`,
            UNNEST (event_params) AS params_1,
            UNNEST (event_params) AS params_2
        WHERE
            _TABLE_SUFFIX BETWEEN @start_date
            AND @end_date
            AND event_name = 'touchable_pressed'
            AND params_1.key = 'eventName'
            AND params_1.value.string_value = 'exploreCategoryCardLink'
            AND params_2.key = 'categoryId'
            AND params_2.value.string_value = '${category.id}'
        GROUP BY
            id
        ORDER BY
            id
        )
    `;

export type ExploreStatisticsProps = {
  categoryId?: string;
  contents: PageResult<ExploreContent>;
};

export const ExploreStatistics: React.FC<ExploreStatisticsProps> = observer(({ categoryId, contents }) => {
  const [categories, setCategories] = useState<ExploreCategory[]>([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contents.content.length > 0) {
      setCategories(contents.content.filter((c) => c.contentType === 'category') as ExploreCategory[]);
    }
  }, [contents]);

  const getData = React.useCallback(async () => {
    setData([]);
    setLoading(true);
    const fetchedData = await Promise.all(
      categories.map(async (category) => {
        const categoryContents = await ExploreContentBuilder.find({ categories: [category.identifier] });
        if (category.type !== 'card_link' && categoryContents.length > 0) {
          return AnalyticsData.query({
            query: getQuery(category, categoryContents),
            parameters: {
              start_date: {
                value: startDate.format('YYYYMMDD'),
              },
              end_date: {
                value: endDate.format('YYYYMMDD'),
              },
            },
          }).then((res) => {
            res[0].identifier = category.identifier;
            res[0].name = category.getName('en');
            return res[0];
          });
        } else if (category.type === 'card_link') {
          return AnalyticsData.query({
            query: getQueryForCardLink(category),
            parameters: {
              start_date: {
                value: startDate.format('YYYYMMDD'),
              },
              end_date: {
                value: endDate.format('YYYYMMDD'),
              },
            },
          }).then((res) => {
            res[0].identifier = category.identifier;
            res[0].name = category.getName('en');
            return res[0];
          });
        }
        return {};
      }),
    );
    setData(
      fetchedData
        .filter((row) => !!row.identifier && !!row.total_select_count)
        .map((d) =>
          Array.from(Object.entries(d))
            .map(([key, value]) => [key, key === 'identifier' || key === 'name' ? value : Number(value)])
            .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {} as any),
        ),
    );
    setLoading(false);
  }, [categories, startDate, endDate]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(2, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  useEffect(() => {
    console.log('**** categories are now', categories);
    getData();
  }, [categories, startDate, endDate, categoryId, getData]);

  if (categories.length > 0) {
    return (
      <React.Fragment>
        <SingleColRow>
          <TitleContainer>
            <h5>Total clicks %</h5>
          </TitleContainer>
        </SingleColRow>
        <SingleColRow>
          <Row className="justify-content-center">
            <Col>
              <Form.Item label="Start Date">
                <DatePicker value={startDate} onChange={handleChangeValidFrom} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="End Date">
                <DatePicker value={endDate} onChange={handleChangeValidUntil} />
              </Form.Item>
            </Col>
          </Row>
        </SingleColRow>
        <SingleColRow>
          <ChartContainer>
            {data.length > 0 ? (
              <ExploreIndexScreenChart data={data} />
            ) : loading ? (
              <Spinner />
            ) : (
              <h6>There is no data for selected period</h6>
            )}
          </ChartContainer>
        </SingleColRow>
      </React.Fragment>
    );
  }

  return null;
});
