/**
 * Created by neo on 01.02.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Input, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Form } from 'antd';
import { Pager } from '../../../Components/Pager';
import { CustomerTable } from './CustomerTable';

export type CustomersListScreenProps = {};

export const CustomersListScreen: React.FC<CustomersListScreenProps> = observer((props) => {
  return null;
  // return (
  //   <Container>
  //     <SingleColRow>
  //       <h1>{'Customers'}</h1>
  //     </SingleColRow>
  //     <Row>
  //       <Col>
  //         <Form.Item>
  //           <Input type="text" onChange={handleChangeQuery} value={query} />
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //     <SingleColRow>
  //       <Pager page={result} onPage={handlePage} />
  //     </SingleColRow>
  //     <SingleColRow>
  //       <CustomerTable gyms={result.content.slice()} />
  //     </SingleColRow>
  //     <SingleColRow>
  //       <Pager page={result} onPage={handlePage} />
  //     </SingleColRow>
  //   </Container>
  // );
});
