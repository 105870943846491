/**
 * Created by neo on 08.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import styled from '@emotion/styled';
import { Gym } from '../../../Model/Gym/Gym';

const Container = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 18px;
  padding: 16px 0;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CategoryContainer = styled.div<{ backgroundImage?: string }>`
  position: relative;
  padding: 16px;
  background-color: #e8e8e8;
  margin-bottom: 8px;
  flex: 0 1 31%;
  border-radius: 8px;
  margin: 4px;
  height: 120px;
  ${({ backgroundImage }) => (backgroundImage ? `background-image: url('${backgroundImage}');` : '')}
  ${({ backgroundImage }) => (backgroundImage ? `background-size: cover;` : '')}
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
`;

export type ExploreCategoryRendererToolsProps = {
  category: ExploreCategory;
  gym?: Gym;
};

export const ExploreCategoryRendererTools: React.FC<ExploreCategoryRendererToolsProps> = observer(
  ({ category, gym }) => {
    const history = useNavigate();
    const location = useLocation();
    const gymId = new URLSearchParams(location.search).get('gymId') || undefined;

    const handleClickEdit = React.useCallback(() => {
      history(`/coach/explore/${category.id}/edit?gymId=${gymId ?? ''}`);
    }, [history, category, gymId]);

    return (
      <Container>
        <Title>
          <Row>
            <Col>{category.defaultName}</Col>
            {gymId === category.gymId && (
              <Col xs="auto">
                <Button type="link" onClick={handleClickEdit}>
                  Edit
                </Button>
              </Col>
            )}
          </Row>
        </Title>
        <Description>{category.defaultDescription}</Description>
        <PreviewContainer>
          {category.links.map((link) => (
            <CategoryContainer key={link.link} backgroundImage={link.image?.smallest}>
              <Overlay />
              <a style={styles.link} href={link.link} target="_blank" rel="noreferrer">
                {link.defaultName}
              </a>
            </CategoryContainer>
          ))}
        </PreviewContainer>
      </Container>
    );
  },
);

const styles = {
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: 'white',
    fontSize: 18,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
} as any;
