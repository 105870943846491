export const withDay = (columnName: string = 'weekDay') => `
WITH root AS (
    SELECT 'Monday' as ${columnName} UNION ALL
    SELECT 'Tuesday' UNION ALL    
    SELECT 'Wednesday' UNION ALL    
    SELECT 'Thursday' UNION ALL    
    SELECT 'Friday' UNION ALL    
    SELECT 'Saturday' UNION ALL    
    SELECT 'Sunday'
) 
SELECT * FROM root 
`;
