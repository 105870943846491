/**
 * Created by neo on 03.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type CaptionElementProps = {
  component: NativeFeedComponent;
  lang?: string;
};

export const CaptionElement: React.FC<CaptionElementProps> = observer(({ component, lang }: CaptionElementProps) => {
  return (
    <div style={Object.assign({}, styles.content, component.props.style.toPOJO())}>
      {component.getText(lang || 'de') || component.type}
    </div>
  );
});

const styles = {
  content: {
    padding: '0 16px',
    fontSize: 14,
    color: '#3c3c3c',
    fontFamily: 'Inter',
  },
};
