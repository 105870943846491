import {
  EarnModelRuleActivationLogicValueCriteria,
  EarnModelRuleActivationLogicValueCriteriaJson,
} from './EarnModelRuleActivationLogicValueCriteria';
import { observable } from 'mobx';

/**
 * Created by neo on 05.08.22.
 */

export type EarnModelRuleActivationLogicEntryJson = {
  event: string;
  /**
   * duration string like PT15M
   */
  expires?: string;
  /**
   * How often needs the event to occur in order to be true
   */
  count: number;
  /**
   * depending on the event triggering it caries a value that needs to meet a specific criteria
   */
  valueCriteria?: EarnModelRuleActivationLogicValueCriteriaJson;
};

export class EarnModelRuleActivationLogicEntry {
  @observable
  event: string = '';
  /**
   * duration string like PT15M
   */
  @observable
  expires?: string;
  /**
   * How often needs the event to occur in order to be true
   */
  @observable
  count: number = 1;
  /**
   * depending on the event triggering it caries a value that needs to meet a specific criteria
   */
  @observable
  valueCriteria?: EarnModelRuleActivationLogicValueCriteria;

  constructor(json?: Partial<EarnModelRuleActivationLogicEntryJson>) {
    if (json) {
      this.event = json.event ?? '';
      this.expires = json.expires;
      this.valueCriteria = json.valueCriteria
        ? new EarnModelRuleActivationLogicValueCriteria(json.valueCriteria)
        : undefined;
    }
  }

  toJS(): EarnModelRuleActivationLogicEntryJson {
    return {
      event: this.event,
      expires: this.expires,
      count: this.count,
      valueCriteria: this.valueCriteria?.toJS(),
    };
  }
}
