/**
 * Created by neo on 01.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { DeeplService } from '../../../../Services/DeeplService';
import { ExploreContentBuilder } from '../../../../Model/Explore/ExploreContentBuilder';
import { ExploreContent } from '../../../../Model/Explore/ExploreContent';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ArticleEntry } from '../../../../Model/Explore/ArticleEntry';

export type TranslateExploreEntriesComponentProps = {};

export const TranslateExploreEntriesComponent: React.FC<TranslateExploreEntriesComponentProps> = observer(() => {
  const [translating, setTranslating] = useState(false);

  const translateCategories = React.useRef(async () => {
    let page = 0;
    let results: ExploreContent[] | undefined;
    while (!results || results.length >= 10) {
      results = await ExploreContentBuilder.find({ size: 10, page });

      await Promise.all(results.map((e) => DeeplService.translateName(e.name)));
      await Promise.all(results.map((e) => DeeplService.translateName(e.description)));
      await Promise.all(
        results
          .filter((e) => e instanceof ExploreEntry)
          .map((e) => (e instanceof ExploreEntry ? DeeplService.translateName(e.quotes) : Promise.resolve())),
      );
      await Promise.all(
        results
          .filter((e) => e instanceof ArticleEntry)
          .flatMap((e) =>
            e instanceof ArticleEntry
              ? Promise.all(e.content.map((c) => DeeplService.translateName(c.text)))
              : Promise.resolve(),
          ),
      );

      await Promise.all(results.map((e) => e.save()));
      page += 1;
    }
  }).current;

  const handleTranslate = React.useCallback(async () => {
    setTranslating(true);
    try {
      await translateCategories();
    } finally {
      setTranslating(false);
    }
  }, []);

  return (
    <Button onClick={handleTranslate} disabled={translating}>
      Translate All
    </Button>
  );
});
