/**
 * Created by katarinababic on 3.5.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import { ExploreEntryModalAnalyticsFinishedSessions } from './ExploreEntryModalAnalyticsFinishedSessions';
import { ExploreEntryModalAnalyticsVideo } from './ExploreEntryModalAnalyticsVideo';
import { ExploreEntryModalAnalyticsWorkoutFinished } from './ExploreEntryModalAnalyticsWorkoutFinished';
import { WorkplaceWorkoutEntry } from '../../../../../Model/Explore/WorkplaceWorkoutEntry';
import { WorkoutTemplateEntry } from '../../../../../Model/Explore/WorkoutTemplateEntry';

export type ExploreEntryModalAnalyticsContentSwitchProps = {
  entry: ExploreEntry;
};

export const ExploreEntryModalAnalyticsContentSwitch: React.FC<ExploreEntryModalAnalyticsContentSwitchProps> = observer(
  ({ entry }) => {
    switch (entry.type) {
      case 'article':
        return entry.videos.length > 0 ? <ExploreEntryModalAnalyticsVideo entry={entry} /> : null;
      case 'workplace':
        return <ExploreEntryModalAnalyticsWorkoutFinished entry={entry as WorkplaceWorkoutEntry} />;
      case 'workoutTemplate':
        return <ExploreEntryModalAnalyticsWorkoutFinished entry={entry as WorkoutTemplateEntry} />;
      case 'activityWorkout':
        return <ExploreEntryModalAnalyticsFinishedSessions entry={entry} eventName="videoWorkout_finished" />;
      case 'meditation':
        return <ExploreEntryModalAnalyticsFinishedSessions entry={entry} eventName="meditationSession_finished" />;
      case 'breathing':
        return <ExploreEntryModalAnalyticsFinishedSessions entry={entry} eventName="breathingSession_finished" />;
      case 'yoga':
        return <ExploreEntryModalAnalyticsFinishedSessions entry={entry} eventName="yogaSession_finished" />;
      case 'video':
        return <ExploreEntryModalAnalyticsVideo entry={entry} />;
      default:
        return null;
    }
  },
);
