export const queryAverageDailyEngagementTime = `
  SELECT event_date, AVG(user_daily_engagement_time/1000/60) as avg_daily_engagement_time, COUNT(DISTINCT user_pseudo_id) as unique_users_count
  FROM
   (
  SELECT user_pseudo_id, event_date, sum(engagement_time) AS user_daily_engagement_time
  FROM (
    SELECT user_pseudo_id, event_date,
      (SELECT value.int_value FROM UNNEST(event_params) WHERE key = "engagement_time_msec") AS engagement_time
    FROM \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p
    WHERE
     _TABLE_SUFFIX BETWEEN @startDate AND @endDate
     AND event_name = 'user_engagement'
     AND p.key = 'gymId'
     AND p.value.string_value = @gymId
  )
  WHERE engagement_time > 0
  GROUP BY user_pseudo_id, event_date)
  GROUP BY event_date
  ORDER BY event_date
`;

export const allAverageDailyEngagementTime = `
  SELECT event_date, AVG(user_daily_engagement_time/1000/60) as avg_daily_engagement_time, COUNT(DISTINCT user_pseudo_id) as unique_users_count
  FROM
   (
  SELECT user_pseudo_id, event_date, sum(engagement_time) AS user_daily_engagement_time
  FROM (
    SELECT user_pseudo_id, event_date,
      (SELECT value.int_value FROM UNNEST(event_params) WHERE key = "engagement_time_msec") AS engagement_time
    FROM \`flinq-6796d.analytics_201736462.events_*\`
    WHERE
     _TABLE_SUFFIX BETWEEN @startDate AND @endDate
     AND event_name = 'user_engagement'
  )
  WHERE engagement_time > 0
  GROUP BY user_pseudo_id, event_date)
  GROUP BY event_date
  ORDER BY event_date
`;
