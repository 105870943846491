/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../Model/Gym/Gym';
import { Col, Form, Row } from 'reactstrap';
import { ExploreWorkoutAnalytics } from './Workout/ExploreWorkoutAnalytics';
import { ExploreCategoryAnalyticsDaily } from './ExploreCategoryAnalytics/ExploreCategoryAnalyticsDaily';
import { ExploreEntryAnalyticsAggregated } from './ExploreEntryAnalytics/ExploreEntryAnalyticsAggregated';
import { ExploreCategoryAnalyticsWeekly } from './ExploreCategoryAnalytics/ExploreCategoryAnalyticsWeekly';
import { ExploreCategoryAnalyticsAggregated } from './ExploreCategoryAnalytics/ExploreCategoryAnalyticsAggregated';
import { ExploreEntryAnalyticsWeekly } from './ExploreEntryAnalytics/ExploreEntryAnalyticsWeekly';

export type ExploreAnalyticsTabProps = {
  gym?: Gym;
};

export const ExploreAnalyticsTab: React.FC<ExploreAnalyticsTabProps> = observer(({ gym }) => {
  return (
    <Form layout="vertical">
      <Row>
        <Col xs={12}>
          <ExploreWorkoutAnalytics gym={gym} />
        </Col>
        <Col xs={12}>
          <ExploreCategoryAnalyticsAggregated gym={gym} />
        </Col>
        <Col xs={12}>
          <ExploreCategoryAnalyticsDaily gym={gym} />
        </Col>
        <Col xs={12}>
          <ExploreCategoryAnalyticsWeekly gym={gym} />
        </Col>
        <Col xs={12}>
          <ExploreEntryAnalyticsAggregated gym={gym} />
        </Col>
        <Col xs={12}>
          <ExploreEntryAnalyticsWeekly gym={gym} />
        </Col>
      </Row>
    </Form>
  );
});
