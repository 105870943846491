/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { WorkoutEntry } from '../../../../../Model/Coach/SuperMacro/WorkoutEntry';
import { Link } from 'react-router-dom';

export type WorkoutEntryDropZoneProps = {
  onDragOver?: () => void;
  onDragLeave?: () => void;
  onDrop?: (workoutEntry: WorkoutEntry) => void;
  workoutEntry: WorkoutEntry;
};

const DropZone = styled.div<{ over?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 2px dashed lightgray;
  height: 50px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  border: ${({ over }) => (over ? '2px dashed black' : '2px dashed lightgray')};
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  text-align: center;
`;

export const WorkoutEntryDropZone: React.FC<WorkoutEntryDropZoneProps> = observer(
  ({ onDrop, onDragOver, onDragLeave, workoutEntry }: WorkoutEntryDropZoneProps) => {
    const [over, setOver] = useState(false);

    const handleDragOver = React.useCallback(
      (event) => {
        console.log('dragOver');
        event.preventDefault();
        setOver(true);
        onDragOver && onDragOver();
      },
      [onDragOver],
    );
    const handleDragLeave = React.useCallback((event) => {
      console.log('dragLEave');
      event.preventDefault();
      setOver(false);
    }, []);

    const handleDrop = React.useCallback(
      (event) => {
        console.log('onDrop', event);
        const workoutTemplateId = event.dataTransfer.getData('workout-template-data');
        const workoutTemplateMove = event.dataTransfer.getData('workout-template-move');
        if (workoutTemplateId) {
          // const workoutEntry = new WorkoutEntry({ workoutTemplateId });
          // onDrop && onDrop(workoutEntry);
          workoutEntry.workoutTemplateId = workoutTemplateId;
          onDrop && onDrop(workoutEntry);
        }
        setOver(false);
        onDragLeave && onDragLeave();
      },
      [onDragLeave, onDrop, workoutEntry],
    );

    const handleDragStart = React.useCallback(
      (event) => {
        event.dataTransfer.setData('workout-template-move', JSON.stringify({ workoutEntryId: workoutEntry.id }));
      },
      [workoutEntry],
    );

    return (
      <DropZone
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragLeave}
        onDrop={handleDrop}
        over={over}
      >
        {workoutEntry.workoutTemplate ? (
          <Body onDragStart={handleDragStart}>
            <Link
              style={{ color: 'white' }}
              to={`/coach/workout-template/${workoutEntry.workoutTemplateId}`}
              target="_blank"
            >
              {workoutEntry.workoutTemplate.templateName}
            </Link>
          </Body>
        ) : (
          'Drop here'
        )}
      </DropZone>
    );
  },
);
