/**
 *
 * Created by neo on 18.01.17.
 */

import { action, observable, computed, onBecomeObserved, runInAction } from 'mobx';
import { ExerciseBlockLog } from './ExerciseBlockLog';
import { Workout } from '../ProgramPortfolio/Workout';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExerciseSet } from './ExerciseSet';
import { Gym } from '../Gym/Gym';
import { Athlete } from '../Athlete/Athlete';

import { Audited } from '../Audited';
import { CoachWorkout } from '../Coach/Program/Workout/CoachWorkout';

export class WorkoutLog extends Audited {
  @observable
  type: string = 'gym_strength';
  @observable
  athleteId?: string = undefined;
  @observable
  exerciseBlocks: Array<ExerciseBlockLog> = [];
  @observable
  startTimestamp: number = 0;
  @observable
  endTimestamp: number = 0;
  @observable
  calories: number = 0;
  @observable
  workout?: Workout = undefined;
  @observable
  workoutId?: string = undefined;
  @observable
  athlete?: Athlete = undefined;
  @observable
  bmr = 1689.22;
  @observable
  coachProgramId?: string;
  @observable
  microCycleId?: string;
  @observable
  microCycleDayId?: string;
  @observable
  gym?: Gym = undefined;
  @observable embeddedWorkout?: CoachWorkout | Workout;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.type = json.type ?? 'gym_strength';
      this.workout = json.workout ? new Workout(json.workout) : undefined;
      this.workoutId = json.workoutId ?? json.workout?.id;
      this.athlete = new Athlete(json.athlete);
      this.startTimestamp = json.startTimestamp || 0;
      this.endTimestamp = json.endTimestamp || 0;
      this.calories = json.calories || 0;
      this.athleteId = json.athleteId;
      this.bmr = json.bmr || 1689.22;
      this.coachProgramId = json.coachProgramId;
      this.microCycleId = json.microCycleId;
      this.microCycleDayId = json.microCycleDayId;
      this.exerciseBlocks = (json.exerciseBlocks || []).map((v: any) => new ExerciseBlockLog(this, v));
    }

    onBecomeObserved(this, 'embeddedWorkout', this.fetchCoachWorkout);
  }

  fetchCoachWorkout = () => {
    const { workoutId } = this;
    if (!this.embeddedWorkout && workoutId) {
      CoachWorkout.get(workoutId)
        .then((result) => {
          runInAction(() => (this.embeddedWorkout = result));
        })
        .catch(() => Workout.get(workoutId).then((workout) => runInAction(() => (this.embeddedWorkout = workout))));
    }
  };

  toJS(newId: boolean = false): any {
    return Object.assign(super.toJS(newId), {
      type: this.type,
      athlete: this.athlete?.toJS(),
      workout: this.workout?.toJS(),
      startTimestamp: this.startTimestamp,
      endTimestamp: this.endTimestamp,
      exerciseBlocks: this.exerciseBlocks.map((b) => b.toJS(newId)),
    });
  }

  toDto() {
    return {
      id: this.id,
      type: this.workout?.type ?? this.type,
      athleteId: this.athlete?.id,
      workoutId: this.workout?.id,
      coachProgramId: this.coachProgramId,
      microCycleId: this.microCycleId,
      microCycleDayId: this.microCycleDayId,
      startTimestamp: this.startTimestamp,
      endTimestamp: this.endTimestamp,
      sets: this.exerciseBlocks
        .reduce((result, block) => result.concat(block.sets.slice()), new Array<ExerciseSet>())
        .map((s) => s.toDto()),
    };
  }

  copy() {
    return new WorkoutLog(this.toJS(true));
  }

  save() {
    return HttpBackend.post(`/workoutlog/save`, this.toDto()).then(() => this);
  }

  @action
  start(): void {
    if (!this.started) {
      this.startTimestamp = Date.now();
      HttpBackend.post('/workoutlog', {
        workoutLogId: this.id,
        workoutId: (this.workout || {}).id,
        startTimestamp: this.startTimestamp,
      });
    }
  }

  @action
  end(): void {
    if (this.active) {
      this.stopExercise();
      this.endTimestamp = Date.now();

      HttpBackend.post('/workoutlog/end', {
        workoutLogId: this.id,
        endTimestamp: this.endTimestamp,
      });
    }
  }

  @action
  stopExercise() {
    const { lastSet } = this;
    if (lastSet) {
      lastSet.stop();
    }
  }

  remove() {
    return HttpBackend.delete(`/workoutlog/admin/${this.id}`);
  }

  @computed({ name: 'WorkoutLog::elapsedBreakTime' })
  get elapsedBreakTime(): number {
    if (this.lastSet) {
      return this.lastSet.breakTime;
    }
    return 0;
  }

  @computed
  get sets(): Array<ExerciseSet> {
    return this.exerciseBlocks
      .reduce((arr: Array<ExerciseSet>, b: ExerciseBlockLog) => arr.concat(b.sets.slice()), [])
      .sort(
        (a, b) =>
          (a.startDateTime?.valueOf() ?? Number.MAX_SAFE_INTEGER) -
          (b.startDateTime?.valueOf() ?? Number.MAX_SAFE_INTEGER),
      );
  }

  @computed({ name: 'WorkoutLog::duration' })
  get duration(): number {
    if (this.startTimestamp > 0) {
      // const end = this.endTimestamp > 0 ? this.endTimestamp : Date.now();
      // return end - this.startTimestamp;
      const { sets } = this;
      const min = sets.reduce(
        (value, s) =>
          value === 0 ? s.startDateTime?.valueOf() ?? 0 : Math.min(value, s.startDateTime?.valueOf() ?? 0),
        0,
      );
      const max = sets.reduce((value: number, s: ExerciseSet) => Math.max(value, s.startDateTime?.valueOf() ?? 0), 0);
      return max - min;
    }
    return 0;
  }

  @computed({ name: 'WorkoutLog::durationInSeconds' })
  get durationInSeconds(): number {
    return this.duration / 1000;
  }

  @computed({ name: 'WorkoutLog::blockCount' })
  get blockCount(): number {
    return this.exerciseBlocks.length;
  }

  @computed({ name: 'WorkoutLog::lastBlock' })
  get lastBlock(): ExerciseBlockLog | undefined {
    const { length } = this.exerciseBlocks;
    if (length > 0) {
      return this.exerciseBlocks[length - 1];
    }
    return undefined;
  }

  @computed({ name: 'WorkoutLog::lastSet' })
  get lastSet(): ExerciseSet | undefined {
    // const { lastBlock } = this;
    // if (lastBlock) {
    //   return lastBlock.lastSet;
    // }
    const { sets } = this;
    if (sets.length > 0) {
      return sets[sets.length - 1];
    }
    return undefined;
  }

  @computed({ name: 'WorkoutLog::lastFinishedSet' })
  get lastFinishedSet(): ExerciseSet | undefined {
    const { lastBlock } = this;
    if (lastBlock) {
      return lastBlock.lastFinishedSet;
    }
    return undefined;
  }

  @computed({ name: 'WorkoutLog::lastEndTimestamp' })
  get lastEndTimestamp(): number {
    return this.lastSet ? this.lastSet.endDateTime?.valueOf() ?? 0 : 0;
  }

  @computed({ name: 'WorkoutLog::totalTimeExercising' })
  get totalTimeExercising(): number {
    return this.exerciseBlocks.reduce(
      (result: number, block: ExerciseBlockLog) => result + block.totalTimeExercising,
      0,
    );
  }

  @computed({ name: 'WorkoutLog::totalCaloriesBurnt' })
  get totalCaloriesBurnt(): number {
    const bmr24 = this.bmr / 24.0;
    return this.sets.reduce((result: number, set: ExerciseSet) => set.calculateCalories(bmr24) + result, 0.0);
  }

  @computed({ name: 'WorkoutLog::tons' })
  get tons(): number {
    return this.exerciseBlocks.reduce((result: number, block: ExerciseBlockLog) => result + block.tons, 0);
  }

  @computed({ name: 'WorkoutLog::totalSets' })
  get totalSets(): number {
    return this.exerciseBlocks.reduce((result: number, block: ExerciseBlockLog) => result + block.sets.length, 0);
  }

  @computed({ name: 'WorkoutLog::started' })
  get started(): boolean {
    return this.startTimestamp !== 0;
  }

  @computed({ name: 'WorkoutLog::ended' })
  get ended(): boolean {
    return this.endTimestamp !== 0;
  }

  @computed({ name: 'WorkoutLog::active' })
  get active(): boolean {
    return this.started && !this.ended;
  }

  @computed({ name: 'WorkoutLog::isRunning' })
  get isRunning(): boolean {
    if (this.active) {
      const { lastSet } = this;
      if (lastSet) {
        return lastSet.isRunning;
      }
    }
    return false;
  }

  @computed({ name: 'WorkoutLog::isBreak' })
  get isBreak(): boolean {
    if (this.active) {
      const { lastSet } = this;
      if (lastSet) {
        return lastSet.isFinished;
      }
    }
    return false;
  }

  static async get(workoutLogId: string): Promise<WorkoutLog | undefined> {
    const res = await HttpBackend.get(`/workoutlog/admin/${workoutLogId}`);
    if (res) {
      return new WorkoutLog(res);
    }
    return undefined;
  }

  static async find(params: any = { page: 0, sort: 'startTimestamp,ASC' }): Promise<WorkoutLog[]> {
    return HttpBackend.get(`/workoutlog/admin`, {
      resolveExercises: false,
      ...params,
    }).then((res) => res.map((w) => new WorkoutLog(w)));
  }

  static count(athleteId?: string): Promise<number> {
    return HttpBackend.get(`/workoutlog/admin/count`, {
      athleteId,
    });
  }
}
