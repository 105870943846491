/**
 * Created by katarinababic on 27.4.22.
 */

export const activitiesLogged = `
SELECT
  params.key AS key,
  params.value.string_value AS type,
  COUNT (*) AS num_of_logged,
  COUNT(DISTINCT user_id) AS user_count
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'activity_logged'
  AND (params.key = 'type'
    OR params.key = 'activityId')
GROUP BY
  key,
  type
ORDER BY
  num_of_logged DESC
`;

export const gymActivitiesLogged = `
SELECT
  params.key AS key,
  params.value.string_value AS type,
  COUNT (*) AS num_of_logged,
  COUNT(DISTINCT user_id) AS user_count
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params,
  UNNEST (user_properties) AS p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'activity_logged'
  AND (params.key = 'type'
    OR params.key = 'activityId')
  AND p.key = 'gymId'
  AND p.value.string_value = @gym_id
GROUP BY
  key,
  type
ORDER BY
  num_of_logged DESC
`;
