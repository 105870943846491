import { TagLibrary, TagMap } from '../TagDefinition';

const ExerciseTagValues: TagLibrary = {
  focus: {
    values: [
      'chest',
      'eye',
      'triceps',
      'back',
      'upper_back',
      'lowerBack',
      'biceps',
      'legs',
      'thigh',
      'butt',
      'shoulder',
      'abs',
      'abs_side',
      'fullBody',
      'trapezius',
      'spine',
      'forearm',
    ],
    min: 1,
    max: 2,
  },
  level: {
    values: ['beginner', 'intermediate', 'advanced'],
    min: 1,
  },
};

export const ExerciseTagsDefinition: TagMap = new Map(Object.entries(ExerciseTagValues));

export const ExerciseTags: Array<string> = Object.keys(ExerciseTagValues).reduce(
  (arr, key) => arr.concat(ExerciseTagValues[key].values.map((value) => `${key}:${value}`)),
  Array<string>(),
);
