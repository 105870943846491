/**
 * Created by neo on 16.07.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { RegisteredUserPerGymAggregationEntry } from './RegisteredUserPerGymAggregationEntry';
import { Table } from 'reactstrap';
import { Gym } from '../../../Model/Gym/Gym';
import { notUndefined } from '../../../Utils/notUndefined';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { RegisteredUserPerGymAggregation } from './RegisteredUserPerGymAggregation';

const tableContainerStyle = {
  position: 'relative',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
} as any;

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
} as any;

const fixedColumnStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 2,
  minWidth: 200,
} as any;

const fixedColumnHeaderStyle = {
  ...fixedColumnStyle,
  zIndex: 3,
};

export type CompanyDashboardScreenProps = {};

export const CompanyDashboardScreen: React.FC<CompanyDashboardScreenProps> = observer((props) => {
  const [data, setData] = useState<RegisteredUserPerGymAggregation[]>([]);
  console.log('data', data);

  const allDates = useMemo(() => data.flatMap((item) => item.entries.map((entry) => entry.date)), [data]);

  const dates = useMemo(
    () =>
      [...new Set(allDates.map((item) => item.toISOString()))]
        .map((d) => new Date(d))
        .sort((a, b) => a.getTime() - b.getTime())
        .reverse(),
    [data],
  );

  useEffect(() => {
    RegisteredUserPerGymAggregation.find({ window: 'week' }).then((res) =>
      setData(res.sort((a, b) => a.gym.name.localeCompare(b.gym.name))),
    );
  }, []);

  return (
    <div style={tableContainerStyle}>
      <Table style={tableStyle} size="sm" hover striped>
        <thead>
          <tr>
            <th style={fixedColumnHeaderStyle}>Company</th>
            {dates.map((date) => (
              <th key={date.toISOString()}>{dayjs(date).format('DD.MM.YYYY')}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((company) => (
            <tr key={company.id}>
              <td style={fixedColumnStyle}>
                <Link to={`/infrastructure/gym/${company.id}`}>{company.gym.name}</Link>
              </td>
              {company.entries
                .reverse()
                .map((entry) => {
                  return (
                    <td key={`${company.id}-${entry.date.toISOString()}`}>
                      <b>{entry?.cumulative}</b>
                      <br/>
                      <span
                        style={{
                          color: entry?.deltaUsers > 0 ? 'green' : entry?.deltaUsers === 0 ? 'black' : 'red',
                        }}
                      >
                        {entry?.deltaUsers > 0 ? '+' : ''}
                        {entry?.deltaUsers}
                      </span>
                      <br/>
                      <span
                        style={{
                          color: entry?.percentageChange > 0 ? 'green' : entry?.percentageChange === 0 ? 'black' : 'red',
                        }}
                      >
                        {entry?.percentageChange > 0 ? '+' : ''}
                        {entry?.percentageChange.toFixed(2)}%
                      </span>
                    </td>
                  );
                })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
});
