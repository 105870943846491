import { ActivityWorkoutEntry, ActivityWorkoutEntryJson } from './ActivityWorkoutEntry';
import { ArticleEntry, ArticleEntryJson } from './ArticleEntry';
import { WorkoutTemplateEntry, WorkoutTemplateEntryJson } from './WorkoutTemplateEntry';
import { DefaultExploreEntry, DefaultExploreEntryJson } from './DefaultExploreEntry';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { toJS } from 'mobx';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { MeditationEntry, MeditationEntryJson } from './MeditationEntry';
import { BreathingEntry, BreathingEntryJson } from './BreathingEntry';
import { YogaEntry, YogaEntryJson } from './YogaEntry';
import { WorkplaceWorkoutEntry, WorkplaceWorkoutEntryJson } from './WorkplaceWorkoutEntry';
import { ExploreEntry } from './ExploreEntry';
import { ZoomMeetingEntry, ZoomMeetingEntryJson } from './ZoomMeetingEntry';
import { VideoEntry } from './VideoEntry';
import { LinkEntry } from './LinkEntry';
import { OnlineMeetingEntry } from './OnlineMeetingEntry';
import { OfflineMeetingEntry } from './OfflineMeetingEntry';
import { WebinarEntry, WebinarEntryJson } from './WebinarEntry';

export type ExploreEntryJson =
  | WorkplaceWorkoutEntryJson
  | ActivityWorkoutEntryJson
  | ArticleEntryJson
  | WorkoutTemplateEntryJson
  | MeditationEntryJson
  | BreathingEntryJson
  | YogaEntryJson
  | WebinarEntryJson
  | DefaultExploreEntryJson;

export type ExploreEntryTypes =
  | WorkplaceWorkoutEntry
  | ActivityWorkoutEntry
  | ArticleEntry
  | WorkoutTemplateEntry
  | MeditationEntry
  | BreathingEntry
  | YogaEntry
  | WebinarEntry
  | DefaultExploreEntry;

export class ExploreEntryBuilder {
  static build(json: ExploreEntryJson): ExploreEntry {
    console.log('type', json.type);
    switch (json.type) {
      case 'article':
        return new ArticleEntry(json);
      case 'workplace':
        return new WorkplaceWorkoutEntry(json);
      case 'workoutTemplate':
        return new WorkoutTemplateEntry(json);
      case 'activityWorkout':
        return new ActivityWorkoutEntry(json);
      case 'meditation':
        return new MeditationEntry(json);
      case 'breathing':
        return new BreathingEntry(json);
      case 'yoga':
        return new YogaEntry(json);
      case 'zoomMeeting':
        return new ZoomMeetingEntry(json);
      case 'video':
        return new VideoEntry(json);
      case 'webinar':
        return new WebinarEntry(json);
      case 'link':
        return new LinkEntry(json);
      case 'onlineMeeting':
        return new OnlineMeetingEntry(json);
      case 'offlineMeeting':
        return new OfflineMeetingEntry(json);
      default:
        return new DefaultExploreEntry(json);
    }
  }

  static buildFromType(type: string, json: ExploreEntryJson): ExploreEntry {
    switch (type) {
      case 'article':
        return new ArticleEntry(json);
      case 'workplace':
        return new WorkplaceWorkoutEntry(json);
      case 'workoutTemplate':
        return new WorkoutTemplateEntry(json);
      case 'activityWorkout':
        return new ActivityWorkoutEntry(json);
      case 'meditation':
        return new MeditationEntry(json);
      case 'breathing':
        return new BreathingEntry(json);
      case 'yoga':
        return new YogaEntry(json);
      case 'zoomMeeting':
        return new ZoomMeetingEntry(json);
      case 'video':
        return new VideoEntry(json);
      case 'webinar':
        return new WebinarEntry(json);
      case 'link':
        return new LinkEntry(json);
      case 'onlineMeeting':
        return new OnlineMeetingEntry(json);
      case 'offlineMeeting':
        return new OfflineMeetingEntry(json);
      default:
        return new DefaultExploreEntry(json);
    }
  }

  static find(request: ExploreEntryQueryRequest): Promise<ExploreEntry[]> {
    return HttpBackend.get(
      `/coach/explore/admin`,
      Object.assign({}, toJS(request) ?? {}, { contentType: 'entry' }),
    ).then((res) => (res ?? []).map((r) => ExploreEntryBuilder.build(r)));
  }

  static count(request: ExploreEntryQueryRequest): Promise<number> {
    return HttpBackend.get(
      `/coach/explore/admin/count`,
      Object.assign({}, toJS(request) ?? {}, { contentType: 'entry' }),
    );
  }

  static findOne(id: string, gymId?: string): Promise<ExploreEntry> {
    return HttpBackend.get(`/coach/explore/admin/${id}?gymId=${gymId ?? ''}`).then((res) =>
      ExploreEntryBuilder.build(res),
    );
  }
}
