/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Button } from 'reactstrap';
import { observer } from 'mobx-react';
import { UserRole } from '../../../Model/UserRole';

export type Props = {
  role: UserRole;
  onSelect: (type: UserRole) => any;
  onDelete: (type: UserRole) => any;
};

@observer
export class UserRoleTableRow extends React.Component<Props> {
  handleClick = () => {
    this.props.onSelect(this.props.role);
  };

  handleRemove = () => {
    this.props.onDelete(this.props.role);
  };

  render() {
    const {
      props: { role },
    } = this;
    return (
      <tr>
        <td>
          <Button color="link" onClick={this.handleClick}>
            {role.id}
          </Button>
        </td>
        <td>{role.name}</td>
        <td>
          <Button color="danger" onClick={this.handleRemove}>
            {'Delete'}
          </Button>
        </td>
      </tr>
    );
  }
}
