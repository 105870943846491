import * as React from 'react';

export type Props = {
  onDelete: () => any;
  tag: any;
  classNames: any;
};

export class Tag extends React.Component<Props> {
  render() {
    const { props } = this;
    return (
      <button
        type="button"
        className={props.classNames.selectedTag}
        title="Click to remove tag"
        onClick={props.onDelete}
      >
        <span className={props.classNames.selectedTagName}>{props.tag.name}</span>
      </button>
    );
  }
}
