import * as React from 'react';
import { observer } from 'mobx-react';
import { Phase } from '../../../../../Model/ProgramPortfolio/Phase';
import { PhaseTable } from './PhaseTable';
import { AbstractWorkout } from '../../../../../Model/ProgramPortfolio/AbstractWorkout';
import { Modal } from 'antd';

export type PhasesTableProps = {
  workout: AbstractWorkout;
  onAddExercise?: (phase: Phase) => void;
};

export const PhasesTable: React.FC<PhasesTableProps> = observer(({ workout, onAddExercise }: PhasesTableProps) => {
  const handleRemove = React.useCallback((phase) => {
    Modal.confirm({
      title: `Remove ${phase.name}?`,
    });
  }, []);

  return (
    <React.Fragment>
      {workout.phases.map((phase) => (
        <PhaseTable key={phase.phaseId} phase={phase} onAddExercise={onAddExercise} onRemove={handleRemove} />
      ))}
    </React.Fragment>
  );
});
