/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseFilterBodyPartCard } from './ExerciseFilterBodyPartCard';
import { observer } from 'mobx-react';

export type ExerciseVariationFilterStabilizersProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterStabilizers: React.FC<ExerciseVariationFilterStabilizersProps> = observer(
  ({ filter }: ExerciseVariationFilterStabilizersProps) => {
    return <ExerciseFilterBodyPartCard header="Stabilizers" bodyPartIds={filter.stabilizerIds ?? []} />;
  },
);
