/**
 *
 * Created by andreaskarantzas on 01.08.18.
 */
import { observable, ObservableMap } from 'mobx';
import { OpeningHours } from './OpeningHours';
import { AdditionalData } from './AdditionalData';

export class GymBranchInfo {
  @observable
  defaultDescription: string = '';
  @observable
  description: ObservableMap<string, string> = observable.map({});
  @observable
  data: AdditionalData = new AdditionalData();
  @observable
  openingHours: OpeningHours = new OpeningHours();

  constructor(source?: any) {
    if (source) {
      this.defaultDescription = source.defaultDescription || '';
      this.description = observable.map(source.description || {});
      this.data = new AdditionalData(source.data || {});
      this.openingHours = new OpeningHours(source.openingHours || {});
    }
  }
}
