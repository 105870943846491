import { observable } from 'mobx';

/**
 * Created by neo on 20.01.21.
 */

export type MediaTextTrackJson = {
  title: string;
  language: string;
  url: string;
  index: number;
  mimeType: string;
};

export class MediaTextTrack {
  @observable
  title: string = '';
  @observable
  language: string = '';
  @observable
  url: string = '';
  @observable
  index: number = 0;
  @observable
  mimeType: string = '';

  constructor(json?: Partial<MediaTextTrackJson>) {
    if (json) {
      this.title = json.title ?? '';
      this.language = json.language ?? '';
      this.url = json.url ?? '';
      this.index = json.index ?? 0;
      this.mimeType = json.mimeType ?? '';
    }
  }

  toJS(): MediaTextTrackJson {
    return {
      title: this.title,
      language: this.language,
      url: this.url,
      index: this.index,
      mimeType: this.mimeType,
    };
  }
}
