/**
 *
 * Created by andreaskarantzas on 03.10.18.
 */
import { Colors } from './Colors';
import { Images } from './Images';

export const ThemeConfig = {
  Colors,
  Images,
};
