/**
 * Created by neo on 14.12.21.
 */
import { observable, onBecomeObserved, runInAction } from 'mobx';
import { Exercise } from '../Exercise';
import { Pageable } from '../../Pageable';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type ExerciseStatisticsUsageQueryRequest = Pageable & {
  gymId?: string;
  exerciseId?: string;
};

export type ExerciseStatisticsUsageJson = {
  id: string;
  count: number;
  duration: number;
  athletes: number;
  sourceType?: string;
  sourceId?: string;
};

export class ExerciseStatisticsUsage {
  @observable
  id: string = '';
  @observable
  count: number = 0;
  @observable
  duration: number = 0;
  @observable
  athletes: number = 0;
  @observable
  sourceType?: string;
  @observable
  sourceId?: string;
  @observable
  exercise?: Exercise;

  constructor(json?: Partial<ExerciseStatisticsUsageJson>) {
    if (json) {
      this.id = json.id ?? '';
      this.count = json.count ?? 0;
      this.duration = json.duration ?? 0;
      this.athletes = json.athletes ?? 0;
      this.sourceType = json.sourceType;
      this.sourceId = json.sourceId;
    }

    onBecomeObserved(this, 'exercise', () => this.fetchExercise());
  }

  fetchExercise(): Promise<Exercise | undefined> {
    if (!this.exercise) {
      return Exercise.get(this.id, this.sourceType, this.sourceId).then((res) => {
        runInAction(() => (this.exercise = res));
        return res;
      });
    }

    return Promise.resolve(this.exercise);
  }

  static find(request: ExerciseStatisticsUsageQueryRequest): Promise<ExerciseStatisticsUsage[]> {
    return HttpBackend.get(`/workoutlog/analytics/admin/exercise`, request).then((res) =>
      (res ?? []).map((r) => new ExerciseStatisticsUsage(r)),
    );
  }

  static findOne(exerciseId: string, request: ExerciseStatisticsUsageQueryRequest): Promise<ExerciseStatisticsUsage> {
    return HttpBackend.get(`/workoutlog/analytics/admin/exercise/${exerciseId}`, request).then(
      (res) => new ExerciseStatisticsUsage(res),
    );
  }

  static count(request: ExerciseStatisticsUsageQueryRequest): Promise<number> {
    return HttpBackend.get(`/workoutlog/analytics/admin/exercise/count`, request).then((res) => res ?? 0);
  }
}
