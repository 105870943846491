import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { RandomNodeModel } from './RandomNodeModel';
import { RandomNodeWidget } from './RandomNodeWidget';
import { RandomStage } from '../../../../../../../Model/Coach/Stages/RandomStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class RandomNodeModelFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('random');
  }

  generateReactWidget(event): JSX.Element {
    return <RandomNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new RandomNodeModel(new RandomStage(new Pipeline()));
  }
}
