/**
 * Created by neo on 17.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeLocation } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeLocation';
import { RouteChallengeMapEditorMapContentLocation } from './RouteChallengeMapEditorMapContentLocation';

export type RouteChallengeMapEditorMapContentProps = {
  challenge: RouteChallenge;
  selectedLocation?: RouteChallengeLocation;
  onSelect?: (location: RouteChallengeLocation) => void;
};

export const RouteChallengeMapEditorMapContent: React.FC<RouteChallengeMapEditorMapContentProps> = observer(
  ({ challenge, selectedLocation, onSelect }) => {
    return (
      <React.Fragment>
        {challenge.locations
          .filter(({ position: { xorLat, yorLng } }) => xorLat && yorLng)
          .map((location) => (
            <RouteChallengeMapEditorMapContentLocation
              key={location.id}
              challenge={challenge}
              location={location}
              selectedLocation={selectedLocation}
              onSelect={onSelect}
            />
          ))}
      </React.Fragment>
    );
  },
);
