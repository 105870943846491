/**
 * Created by katarinababic on 27.4.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { Gym } from '../../../Model/Gym/Gym';
import { ActivitiesLoggedAggregated } from './ActivitiesLoggedAggregated';
import { ActivitiesLoggedWeekly } from './ActivitiesLoggedWeekly';
import { ActivitiesLoggedDaily } from './ActivitiesLoggedDaily';
import { SingleColRow } from '../../../Components/SingleColRow';

export type ActivitiesLoggedTabProps = {
  gym?: Gym;
};

export const ActivitiesLoggedTab: React.FC<ActivitiesLoggedTabProps> = observer(({ gym }) => {
  return (
    <Form layout="vertical">
      <SingleColRow>
        <ActivitiesLoggedAggregated gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <ActivitiesLoggedWeekly gym={gym} />
      </SingleColRow>
      <SingleColRow>
        <ActivitiesLoggedDaily gym={gym} />
      </SingleColRow>
    </Form>
  );
});
