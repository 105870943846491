/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { MindfulnessProgramTemplateDayUnitVideo } from '../../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitVideo';
import { YogaInstructorEdit } from '../../../../../Explore/Content/Yoga/YogaInstructorEdit';
import { Form } from 'antd';
import AsyncSelect from 'react-select/async';
import { Activity } from '../../../../../../../Model/Activity/Activity';
import { useEffect, useState } from 'react';
import { Media } from '../../../../../../../Model/Media/Media';
import { MediaEditModal } from '../../../../../../MetaData/Media/MediaEditModal/MediaEditModal';
import { reaction, runInAction } from 'mobx';
import { ExploreEntryModalMediaField } from '../../../../../Explore/ExploreEntryModalMediaField';

export type VideoEntryContentProps = {
  entry: MindfulnessProgramTemplateDayUnitVideo;
};

export const VideoEntryContent: React.FC<VideoEntryContentProps> = observer(({ entry }) => {
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

  useEffect(
    () =>
      reaction(
        () => entry.videos.map((a) => a),
        (medias) => {
          const durationSeconds = medias.reduce(
            (dur, media) => Math.min(dur, media.mediaInfo.duration ?? Number.MAX_SAFE_INTEGER),
            Number.MAX_SAFE_INTEGER,
          );
          if (
            (!entry.durationInMinutes ||
              entry.durationInMinutes <= 0 ||
              Math.abs(entry.durationInMinutes * 60 - durationSeconds) > 120) &&
            durationSeconds > 0 &&
            durationSeconds !== Number.MAX_SAFE_INTEGER
          ) {
            runInAction(() => (entry.durationInMinutes = Math.max(1, Math.round(durationSeconds / 60))));
          }
        },
        { fireImmediately: true },
      ),
    [entry],
  );

  const handleClickMedia = React.useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

  const handleMediaSave = React.useCallback(
    (media: Media) => {
      runInAction(() => {
        const index = entry.videos.findIndex((v) => v.id === media.id);
        if (index !== -1) {
          entry.videos[index] = media;
        }
      });
      setSelectedMedia(undefined);
    },
    [entry],
  );

  const handleVideoUploaded = React.useCallback(
    (media: Media) => {
      runInAction(() => entry.videos.push(media));
    },
    [entry],
  );

  const handleRemoveVideo = React.useCallback(
    (media: Media) => {
      runInAction(() => (entry.videos = entry.videos.filter((v) => v.id !== media.id)));
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 16 }}>
        <Col>
          <YogaInstructorEdit entry={entry} />
        </Col>
        <Col xs={12}>
          <Form.Item label="Videos">
            <div style={{ display: 'flex' }}>
              {entry.videos.concat([undefined] as any).map((video) => (
                <div key={video?.id} style={{ marginLeft: 8 }}>
                  <ExploreEntryModalMediaField
                    onRemove={handleRemoveVideo}
                    onUploaded={handleVideoUploaded}
                    onClick={handleClickMedia}
                    media={video}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Col>
      </Row>
      {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
    </React.Fragment>
  );
});
