import { action, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Signal } from '../Signal/Signal';
import { EndOfStreamSignal } from '../Signal/EndOfStreamSignal';
import { StageOutPort } from '../Signal/StageOutPort';
import { StageInPort } from '../Signal/StageInPort';
import { InterruptSignal } from '../Signal/InterruptSignal';
import { DataSignal, dataSignal } from '../Signal/DataSignal';
import { Pipeline } from '../Pipeline';
import { notUndefined } from '../../../Utils/notUndefined';

export type StagePortMode = 'SYNC' | 'ASYNC';

export abstract class Stage<T> {
  @observable
  id: string = UUID();
  @observable
  pipeline: Pipeline;
  @observable
  config: T = {} as T;
  @observable
  inputs: StageInPort[] = [];
  @observable
  nextStages: StageOutPort[] = [];
  @observable
  x: number = 0;
  @observable
  y: number = 0;
  processedSignals: Signal[] = [];

  constructor(pipeline: Pipeline, json?: any, public readonly portMode: StagePortMode = 'ASYNC') {
    this.pipeline = pipeline;
    if (json) {
      this.id = json.id || UUID();
      this.x = json.x ?? 0;
      this.y = json.y ?? 0;
      this.config = json.config || ({} as T);
    }
  }

  signal(signal: Signal, port: StageInPort) {
    // check if all incoming ports have data and are not closed (due to end of stream)
    const openPorts = this.inputs.filter((input) => input.portState === 'OPEN');
    const closedPorts = this.inputs.filter((input) => input.portState === 'CLOSED');

    console.log(
      `Recv. ${this.type}`,
      toJS(this.inputs.flatMap((input) => input.signalQueue)),
      toJS(port),
      toJS(openPorts),
      toJS(this.connectedInPorts),
      toJS(closedPorts),
    );

    // if (this.portMode === 'ASYNC') {
    if (closedPorts.length === this.connectedInPorts.length) {
      console.log('all ports filled... processing', this.type);
      const result = this.process();
      if (result instanceof Promise) {
        result.then(() => this.endOfStream());
      } else {
        this.endOfStream();
      }
    }
    // } else {
    //   if (openPorts.length === 0) {
    //     console.log('All ports received data');
    //     if (closedPorts.length === this.connectedInPorts.length) {
    //       this.endOfStream();
    //     } else {
    //       this.process();
    //     }
    //   }
    // }
  }

  take(): Signal[] {
    return this.connectedInPorts.map((port) => port.take()).filter(notUndefined);
  }

  takeAll(): Signal[] {
    let signals = this.take();
    let result = new Array<Signal>();
    while (signals.length > 0) {
      result = result.concat(signals);
      signals = this.take();
    }
    return result;
  }

  abstract process();

  endOfStream() {
    this.processNext(new EndOfStreamSignal(), 'default', true);
  }

  closePorts() {
    this.inputs.forEach((p) => p.close());
  }

  @action
  connectTo(stage?: Stage<any>, fromPortType: string = 'default', toPortType: string = 'in') {
    if (stage) {
      const outPorts = this.nextStages.filter((port) => port.type === fromPortType);
      const matchingPort = outPorts.find((port) => port.otherPort?.stage.id === stage.id);
      if (!matchingPort) {
        console.log('connecting', this.type, stage.type, fromPortType, toPortType);
        const newPort = outPorts.find((port) => !port.otherPort) ?? new StageOutPort(this, fromPortType);
        newPort.otherPort = stage.getOrCreateInPort(newPort, toPortType);
        this.nextStages.push(newPort);
      }
    }
  }

  @action
  protected getOrCreateInPort(otherPort: StageInPort, portType: string = 'in') {
    const port = new StageInPort(this, portType, otherPort);
    this.inputs.push(port);
    return port;
  }

  // @action
  // protected addInput(connection: StageConnection) {
  //   const key = connection.inPortType || 'in';
  //   this.inputs[key] = this.inputs[key] ?? [];
  //   if (this.inputs[key].findIndex((conn) => conn.from.id === connection.from.id) === -1) {
  //     this.inputs[key].push(connection);
  //   }
  // }

  @action
  protected removeInput(stage: Stage<any>) {
    this.inputs = this.inputs.filter((port) => port.stage?.id !== stage.id);
  }

  @action
  disconnectFrom(stage?: Stage<any>, type: string = 'default') {
    if (stage) {
      this.nextStages = this.nextStages.filter((port) => port.stage?.id !== stage.id);
      stage.removeInput(this);
      console.log('disconnected', this.type, stage.type, type);
    }
  }

  get connectedInPorts() {
    return this.inputs.filter((p) => !!p.otherPort);
  }

  get previousStages() {
    return this.connectedInPorts.filter((p) => p.otherPort?.stage.type !== 'start').map((port) => port.stage);
  }

  get connectedOutPorts() {
    return this.nextStages.filter((p) => !!p.otherPort);
  }

  get availablePorts(): StageInPort[] {
    return this.connectedInPorts.filter((port) => port.portState !== 'CLOSED');
  }

  get dataSignals(): DataSignal[] {
    return this.takeAll().filter(dataSignal);
  }

  protected processNext(signal: Signal, outputType: string = 'default', allPorts: boolean = false) {
    if (!this.processedSignals.find((s) => s === signal)) {
      this.processedSignals.push(signal);
      const nextPorts = allPorts
        ? this.connectedOutPorts
        : this.connectedOutPorts.filter((port) => port.type === outputType);
      console.log('Stage::processNext', this.type, toJS(signal), outputType, toJS(nextPorts));
      if (this.pipeline.context?.executeUntilStageId === this.id && this.pipeline.context?.executeUntilStageId) {
        console.log('Stopping execution here');
        this.connectedOutPorts.forEach((port) => port.forward(new InterruptSignal()));
      } else if (nextPorts.length > 0) {
        // console.log(
        //   'processing next',
        //   this.toJS(),
        //   nextPorts.map((s) => toJS(s)),
        //   signal,
        // );

        nextPorts.forEach((port) => port.forward(signal));
      }
    } else {
      console.warn('signal already processed', toJS(signal));
    }
  }

  resolveParam(userValue?: any, defaultValue?: any, raw: boolean = false): any {
    if (userValue) {
      if (typeof userValue === 'string' && userValue.startsWith('$')) {
        const param = userValue.replace('$', '');
        return this.pipeline.context.getValue(this.pipeline, param, defaultValue, raw) ?? 0;
      }
      return userValue;
    }
    return defaultValue;
  }

  resolveParamNumber(userValue?: string | number, defaultValue?: number): number {
    if (userValue) {
      if (typeof userValue === 'string' && userValue.startsWith('$')) {
        const param = userValue.replace('$', '');
        return this.pipeline.context.getValue(this.pipeline, param, defaultValue) ?? 0;
      }
      return Number(userValue);
    }
    return defaultValue ?? 0;
  }

  toJS(newId?: boolean): any {
    return {
      id: newId ? UUID() : this.id,
      type: this.type,
      config: toJS(this.config),
      x: this.x,
      y: this.y,
    };
  }

  toSaveJS(): Promise<any> {
    return Promise.resolve(this.toJS());
  }

  get type(): string {
    return 'default';
  }
}
