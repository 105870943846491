/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Alert, Button, Modal } from 'antd';
import { CoachExerciseBlock } from '../../../../../../Model/Coach/Program/Workout/CoachExerciseBlock';
import { CoachExerciseBlockSuggestionRow } from './CoachExerciseBlockSuggestionRow';

export type CoachExerciseBlockSuggestionsModalProps = {
  suggestions: CoachExerciseBlock[];
  onSelect?: (newBlock: CoachExerciseBlock) => any;
  onCancel?: () => any;
};

export const CoachExerciseBlockSuggestionsModal: React.FC<CoachExerciseBlockSuggestionsModalProps> = observer(
  ({ suggestions, onSelect, onCancel }: CoachExerciseBlockSuggestionsModalProps) => {
    return (
      <Modal open={true} onCancel={onCancel}>
        {suggestions.map((exerciseBlock) => (
          <CoachExerciseBlockSuggestionRow
            key={exerciseBlock.exerciseBlockId}
            exerciseBlock={exerciseBlock}
            onClick={onSelect}
          />
        ))}
        {suggestions.length === 0 ? <Alert message="No suggestions available" type="warning" /> : null}
      </Modal>
    );
  },
);
