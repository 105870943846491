/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { SuperMacro } from '../../../../Model/Coach/SuperMacro/SuperMacro';
import { MacroCycleCard } from './MacroCycleCard';
import { Collapse } from 'antd';
import { MacroCycleCardExtra } from './MacroCycleCardExtra';

export type MacroCycleTemplateListProps = {
  superMacro: SuperMacro;
};

export const MacroCycleTemplateList: React.FC<MacroCycleTemplateListProps> = observer(
  ({ superMacro }: MacroCycleTemplateListProps) => {
    const firstMacroCycle = superMacro.macroCycles[0];

    const handleRemove = React.useCallback(
      (macro) => (superMacro.macroCycles = superMacro.macroCycles.filter((m) => m.id !== macro.id)),
      [superMacro],
    );

    return (
      <Collapse defaultActiveKey={firstMacroCycle?.id}>
        {superMacro.macroCycles.map((macroCycle) => (
          // <Collapse.Panel
          //   key={macroCycle.id}
          //   header={macroCycle.tagConditions.map((c) => c.includedTags.join(', ')).join(' | ') || '<default>'}
          //   extra={<MacroCycleCardExtra macroCycle={macroCycle} onRemove={handleRemove} />}
          // >
          <MacroCycleCard key={macroCycle.id} macroCycle={macroCycle} onRemove={handleRemove} />
          // </Collapse.Panel>
        ))}
      </Collapse>
    );
  },
);
