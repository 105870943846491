import { observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Media } from './Media';

export class EntityMedia {
  @observable
  id?: string = undefined;
  @observable
  entityId?: string = undefined;
  @observable
  entityType?: string = undefined;
  @observable
  medias: Media[] = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.entityId = json.entityId;
      this.entityType = json.entityType;
      this.medias = json.medias.map((m) => new Media(m));
    }
  }

  static async get(uid: string): Promise<EntityMedia | undefined> {
    const data = await HttpBackend.get(`/media/${uid}`);
    if (data) {
      return new EntityMedia(data);
    }
    return undefined;
  }

  static async assign(entityType: string, entityId: string, medias: Array<Media>) {
    return HttpBackend.post(`/media/${entityType}/${entityId}`, medias);
  }
}
