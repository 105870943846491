import { Gym } from '../../../../../Model/Gym/Gym';
import { computed, observable, runInAction } from 'mobx';
import { Customer } from '../../../../../Model/Customer/Customer';
import { HttpBackend } from '../../../../../Services/Http/HttpBackend';
import { notUndefined } from '../../../../../Utils/notUndefined';

const EMAIL_REGEX = /(?!.*\.\.)(^[^\\.][^@\s]+@[^@\s]+\.[^@\s\\.]{2,8}$)/;

export class EmployeeMailSender {
  @observable
  fromMail = 'noreply@kinastic.com';

  @observable
  fromName = 'KINASTIC';

  @observable
  subject?: string;

  @observable
  content?: string;

  @observable
  processing = false;

  @observable
  totalRecipients = 0;

  @observable
  sentRecipients = 0;

  constructor(readonly gym: Gym) {}

  private sendBatch(emails: string[]): Promise<any> {
    console.log('Sending email to', emails);
    return HttpBackend.post('/messaging/mail', {
      from: {
        email: this.fromMail,
        name: this.fromName,
      },
      subject: this.subject,
      content: [
        {
          type: 'text/plain',
          value: this.content,
        },
      ],
      personalizations: emails.map((email) => ({
        to: [{ email, name: '' }],
        subject: this.subject,
        headers: {},
      })),
    });
  }

  async send() {
    if (!this.processing) {
      this.processing = true;
      this.totalRecipients = await Customer.count({ gymId: this.gym.id });
      const size = 100;
      let page = 0;
      let results: Customer[] | undefined;

      try {
        while (!results || results.length >= size) {
          results = await Customer.find({ gymId: this.gym.id, page, size });
          const length = results.length;
          const validMails = results
            .map((c) => c.athlete.user.email)
            .filter((email) => email && !email.toLowerCase().endsWith('@fbid.kinastic.com') && EMAIL_REGEX.test(email))
            .filter(notUndefined);
          if (validMails.length > 0) {
            await this.sendBatch(validMails);
          }
          page++;

          runInAction(() => (this.sentRecipients += length));
        }
      } finally {
        runInAction(() => (this.processing = false));
      }
    }
  }

  @computed
  get invalid(): boolean {
    return (
      !this.fromMail.trim() || !EMAIL_REGEX.test(this.fromMail.trim()) || !this.subject?.trim() || !this.content?.trim()
    );
  }
}
