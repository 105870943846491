export const allUsersAgeHistory = `
  SELECT
    event_date,
    age,
    num_of_users,
    SUM(num_of_users) OVER (PARTITION BY age ORDER BY event_date) AS accumulated_num_of_users
  FROM (
    SELECT
      event_date,
      age,
      COUNT(user_id) AS num_of_users
    FROM (
      SELECT
        user_id,
        MIN(event_date) AS event_date,
        ANY_VALUE(p_age.value.string_value) AS age
      FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST (user_properties) AS p_age
      WHERE p_age.key = 'athlete_age'
      GROUP BY
        user_id )
    GROUP BY
      event_date,
      age )
  ORDER BY event_date, age`;

export const queryUsersAgeHistory = `
  SELECT
    event_date,
    age,
    num_of_users,
    SUM(num_of_users) OVER (PARTITION BY age ORDER BY event_date) AS accumulated_num_of_users
  FROM (
    SELECT
      event_date,
      age,
      COUNT(user_id) AS num_of_users
    FROM (
      SELECT
        user_id,
        MIN(event_date) AS event_date,
        ANY_VALUE(p_age.value.string_value) AS age
      FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST (user_properties) AS p,
        UNNEST (user_properties) AS p_age
      WHERE
        p.key = 'gymId'
        AND p.value.string_value = @gymId
        AND p_age.key = 'athlete_age'
      GROUP BY
        user_id )
    GROUP BY
      event_date,
      age )
  ORDER BY event_date, age`;
