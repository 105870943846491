/**
 * Created by neo on 29.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { PipelineContext } from '../../../../Model/Coach/PipelineContext';
import { Form, Input } from 'antd';
import { EquipmentTypeInput } from '../../../../Components/EquipmentTypeInput';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Row, Col } from 'reactstrap';

export type ScriptContextFormProps = {
  context: PipelineContext;
};

export const ScriptContextForm: React.FC<ScriptContextFormProps> = observer(({ context }: ScriptContextFormProps) => {
  const handleChangeTags = React.useCallback(
    ({ target: { value } }: any) => {
      context.tags = value?.split(',').map((t) => t.trim());
    },
    [context],
  );

  return (
    <Form layout="vertical">
      <Row>
        <Col md={6}>
          <Form.Item label="Tags">
            <Input value={context.tags.join(', ')} onChange={handleChangeTags} />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Equipment">
            <EquipmentTypeInput container={context.equipmentTypes} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
