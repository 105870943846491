/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PropsWithChildren } from 'react';

export type ModalPromiseProps = {
  title?: string;
  size?: string;
};

@observer
export class ModalPromise extends React.Component<PropsWithChildren<ModalPromiseProps>> {
  @observable
  visible: boolean = false;
  resolve: () => void;

  handleSubmit = (e: any) => {
    e.prevenDefault();
  };

  close = () => {
    this.visible = false;
    this.resolve && this.resolve();
  };

  toggle = () => {
    this.close();
  };

  show(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.resolve = resolve;
      this.visible = true;
    });
  }

  render() {
    const {
      props: { title, children, size },
    } = this;
    if (this.visible) {
      return (
        <Modal isOpen toggle={this.toggle} size={size}>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" onClick={this.close}>
                {'OK'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      );
    }
    return null;
  }
}
