/**
 * Created by neo on 03.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { MarkdownRenderer } from '../../../../Markdown/MarkdownRenderer';

export type ParagraphElementProps = {
  component: NativeFeedComponent;
  lang?: string;
};

export const ParagraphElement: React.FC<ParagraphElementProps> = observer(
  ({ component, lang }: ParagraphElementProps) => {
    const componentText = component.getText(lang ?? 'de');

    return (
      <div style={Object.assign({}, styles.content, component.props.style.toPOJO())}>
        <MarkdownRenderer text={componentText ?? component.type} />
      </div>
    );
  },
);

const styles = {
  content: {
    padding: '0 16px',
    fontSize: 16,
    color: '#3C3C3C',
    whiteSpace: 'pre-wrap' as any,
    fontFamily: 'Inter',
  },
};
