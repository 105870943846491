/**
 * Created by neo on 01.12.21.
 */
import { observable } from 'mobx';

export type ChallengeMilestoneJson = {
  limitInMinutes: number;
  points: number;
  extraPoints: number;
};

export class ChallengeMilestone {
  @observable
  limitInMinutes: number = 0;
  @observable
  points: number = 0;
  @observable
  extraPoints = 0;

  constructor(json?: Partial<ChallengeMilestoneJson>) {
    if (json) {
      this.limitInMinutes = json.limitInMinutes ?? 0;
      this.points = json.points ?? 0;
      this.extraPoints = json.extraPoints ?? 0;
    }
  }

  toJS(): ChallengeMilestoneJson {
    return {
      limitInMinutes: this.limitInMinutes,
      points: this.points,
      extraPoints: this.extraPoints,
    };
  }
}
