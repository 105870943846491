/**
 * Created by neo on 17.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallengeLocation } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeLocation';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Button, Card, Form, InputNumber, message, Upload, UploadProps } from 'antd';
import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { runInAction } from 'mobx';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { RcFile } from 'antd/es/upload';
import MediaService from '../../../../../Services/MediaService';
import { decode, encode } from '@googlemaps/polyline-codec';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export type RouteChallengeLocationEntryProps = {
  challenge: RouteChallenge;
  location: RouteChallengeLocation;
  onClick?: (location: RouteChallengeLocation) => void;
  onEdit?: (location: RouteChallengeLocation) => void;
  selected?: boolean;
};

export const RouteChallengeLocationEntry: React.FC<RouteChallengeLocationEntryProps> = observer(
  ({ challenge, location, selected, onClick, onEdit }) => {
    const index = challenge.locations.findIndex((l) => l.id === location.id);
    const number = index + 1;

    const isFirst = index === 0;
    const isLast = index === challenge.locations.length - 1;

    const [uploading, setUploading] = useState(false);

    const beforeUpload = (file: RcFile) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        message.error('You can only upload JPG/PNG file!');
      } else if (file.size / 1024 / 1024 > 2) {
        message.error('Image must smaller than 2MB!');
      } else {
        setUploading(true);
        MediaService.uploadMedia(file)
          .then((media) => {
            runInAction(() => (location.image = media));
          })
          .catch(() => message.error('Failed to upload image'))
          .finally(() => setUploading(false));
      }

      return false;
    };

    const handleReverseRoute = React.useCallback(
      (e) => {
        e.preventDefault();
        if (location.googleMapsRouteToNextLocation) {
          const decoded = decode(location.googleMapsRouteToNextLocation);
          const reversed = decoded.reverse();
          const encoded = encode(reversed);
          runInAction(() => (location.googleMapsRouteToNextLocation = encoded));
        }
      },
      [location],
    );

    const uploadButton = (
      <div>
        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <Card
        style={{ marginBottom: 8, border: selected ? '1px solid blue' : undefined }}
        cover={
          <Upload
            key="avatar"
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            style={{ width: '100%' }}
          >
            {location.image?.medium ? (
              <img
                src={location.image?.medium}
                alt="avatar"
                style={{ width: '100%', height: 100, objectFit: 'contain' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        }
        onClick={() => onClick && onClick(location)}
        actions={[
          <SettingOutlined key="setting" onClick={() => onEdit && onEdit(location)} />,
          <DeleteOutlined key="ellipsis" onClick={() => runInAction(() => challenge.locations.splice(index, 1))} />,
        ]}
      >
        <h6>{`${number}. ${location.defaultName}`}</h6>
        <p>
          <b>Position</b>
          <br />
          {location.position.xorLat},{location.position.yorLng}
        </p>
        {isFirst ? (
          <p>Start Location</p>
        ) : (
          <Form.Item label="Points Required" extra={`Total Points: ${location.totalPointsRequired}`}>
            <InputNumber
              value={location.pointsRequired}
              onChange={(newValue) => runInAction(() => (location.pointsRequired = Number(newValue)))}
            />
          </Form.Item>
        )}
        {!isLast && <p>Route: {location.googleMapsRouteToNextLocation ? 'OK' : 'MISSING'}</p>}
        {!isLast && location.googleMapsRouteToNextLocation && (
          <Button type="link" onClick={handleReverseRoute}>
            Reverse Route
          </Button>
        )}
        {isLast && <p>Final Location</p>}
      </Card>
    );
  },
);
