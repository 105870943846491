/**
 * Created by neo on 09.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { List } from 'mdast-util-from-markdown/dev/lib';
import { MarkdownNodeRenderer } from './MarkdownNodeRenderer';
import { makeStyles } from 'tss-react/mui';
import { ThemeConfig } from '../../Config/Theme';

export type MarkdownListRendererProps = {
  content: List;
  ordered?: boolean;
  textStyle?: React.CSSProperties;
};

export const MarkdownListRenderer: React.FC<MarkdownListRendererProps> = observer(({ content, ordered, textStyle }) => {
  const { classes } = useStyles();

  return ordered ? (
    <ol className={classes.orderedList}>
      {content.children.map((child, index) => (
        <MarkdownNodeRenderer key={index.toString()} child={child} textStyle={textStyle} />
      ))}
    </ol>
  ) : (
    <ul className={classes.unorderedList}>
      {content.children.map((child, index) => (
        <MarkdownNodeRenderer key={index.toString()} child={child} textStyle={textStyle} />
      ))}
    </ul>
  );
});

const useStyles = makeStyles()((theme) => ({
  unorderedList: {
    color: ThemeConfig.Colors.black,
    fontSize: 22,
    lineHeight: '0.5',
    flexShrink: 1,
  },
  orderedList: {},
}));
