/**
 * Created by neo on 01.06.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EventEntry } from '../../../Model/Coach/Schedule/EventEntry';
import { Link } from 'react-router-dom';
import { Recipe } from '../../../Model/Diet/Recipe/Recipe';

export type EventEntryTableRowProps = {
  entry: EventEntry;
};

export const EventEntryTableRow: React.FC<EventEntryTableRowProps> = observer(({ entry }) => {
  const objectLink =
    entry.object instanceof Recipe
      ? `/coach/diet/recipes/${entry.objectId}`
      : `/coach/explore/entry/${entry.object?.categories[0]}/${entry.objectId}`;

  return (
    <React.Fragment>
      <tr>
        <td>
          <Link to={`/athlete/${entry.athleteId}`}>{entry.athlete?.fullName ?? entry.athleteId}</Link>
        </td>
        <td>{entry.objectType}</td>
        <td>
          <Link to={objectLink}>{entry.object?.defaultName}</Link>
        </td>
        <td>{entry.aiPlanned ? 'AI Planned' : 'Manual'}</td>
        <td>{entry.parentId}</td>
        <td>{entry.startTime}</td>
        <td>{entry.endTime}</td>
      </tr>
    </React.Fragment>
  );
});
