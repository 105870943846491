import { computed, observable, toJS } from 'mobx';
import { Media } from '../Media/Media';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { LocalizedArrayEntity } from '../LocalizedArrayEntity';

export class Activity extends LocalizedArrayEntity {
  @observable identifier: string = '';
  @observable tags: Array<string> = [];
  @observable medias: Array<Media> = [];
  @observable met: number = 5;
  @observable trackingKeys: Array<string> = [];

  constructor(json?: any) {
    super(json);
    if (json) {
      this.identifier = json.identifier || '';
      this.tags = json.tags || [];
      this.met = json.met || 5;
      this.trackingKeys = json.trackingKeys || [];
      this.medias = (json.medias ?? []).map((m) => new Media(m));
    }
  }

  toJS() {
    return Object.assign(super.toJS(), {
      identifier: this.identifier,
      tags: toJS(this.tags),
      trackingKeys: toJS(this.trackingKeys),
      met: this.met,
      medias: this.medias.map((m) => m.toJS()),
    });
  }

  save() {
    return HttpBackend.post('/activity/activities/admin', this.toJS()).then(() => this);
  }

  delete() {
    return HttpBackend.delete(`/activity/activities/admin/${this.id}`);
  }

  @computed
  get firstSmallestMediaUrl(): string | undefined {
    const { medias } = this;
    if (medias && medias.length > 0) {
      return medias[0].smallest;
    }
    return undefined;
  }

  static find(params?: any): Promise<Array<Activity>> {
    return HttpBackend.get('/activity/activities', params).then((result) => result.map((e) => new Activity(e)));
  }

  static count(params?: any): Promise<number> {
    return HttpBackend.get('/activity/activities/count', params);
  }

  static async get(id: string): Promise<Activity | undefined> {
    const res = await HttpBackend.get(`/activity/activities/${id}`);
    return res ? new Activity(res) : undefined;
  }
}
