import { observable } from 'mobx';
import {
  MindfulnessProgramTemplateDayUnit,
  MindfulnessProgramTemplateDayUnitJson,
} from './MindfulnessProgramTemplateDayUnit';
import { BreathingTechniqueStep, BreathingTechniqueStepJson } from '../../../Explore/BreathingTechniqueStep';
import { ConditionalMedia, ConditionalMediaJson } from '../../SuperMacro/ConditionalMedia';

/**
 * Created by neo on 12.04.22.
 */

export type MindfulnessProgramTemplateDayUnitBreathingJson = MindfulnessProgramTemplateDayUnitJson & {
  technique: BreathingTechniqueStepJson[];
  backgroundImages: ConditionalMediaJson[];
  spotifyPlaylistLink?: string;
};

export class MindfulnessProgramTemplateDayUnitBreathing extends MindfulnessProgramTemplateDayUnit {
  @observable
  technique: BreathingTechniqueStep[] = [];
  @observable
  backgroundImages: ConditionalMedia[] = [];
  @observable
  spotifyPlaylistLink?: string;

  constructor(json?: Partial<MindfulnessProgramTemplateDayUnitBreathingJson>) {
    super(Object.assign({}, json ?? {}, { type: 'breathing' }));
    if (json) {
      this.technique = (json.technique ?? []).map((a) => new BreathingTechniqueStep(a));
      this.backgroundImages = (json.backgroundImages ?? []).map((m) => new ConditionalMedia(m));
      this.spotifyPlaylistLink = json.spotifyPlaylistLink;
    }
  }

  toJS(newId: boolean = false): MindfulnessProgramTemplateDayUnitBreathingJson {
    return Object.assign(super.toJS(newId), {
      technique: this.technique.map((a) => a.toJS()),
      backgroundImages: this.backgroundImages.map((m) => m.toJS()),
      spotifyPlaylistLink: this.spotifyPlaylistLink,
    });
  }
}
