/**
 * Created by neo on 20.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { observable, runInAction } from 'mobx';
import { MediaOverlay } from '../../../../../../Model/Media/MediaOverlay';
import { Media } from '../../../../../../Model/Media/Media';
import styled from '@emotion/styled';
import { MediaLoopEntry } from './MediaLoopEntry';
import { MediaLoop } from '../../../../../../Model/Media/MediaLoop';
import { MediaLoopEditModalVideo } from './MediaLoopEditModalVideo';

export type MediaLoopTabProps = {
  media: Media;
};

const ContentContainer = styled.div`
  overflow-y: scroll;
  max-height: 560px;
`;

export const MediaLoopTab: React.FC<MediaLoopTabProps> = observer(({ media }) => {
  const [store] = useState(() =>
    observable({
      currentSeek: 0,
      maxSeconds: 0,
    }),
  );

  const handleProgress = React.useCallback(
    ({ played, playedSeconds }) => {
      runInAction(() => (store.currentSeek = playedSeconds));
    },
    [store],
  );

  const handleAdd = React.useCallback(() => {
    const startTime = MediaOverlay.calculateTime(store.currentSeek);
    const endTime = startTime;
    runInAction(() => media.loops.push(new MediaLoop({ startTime, endTime })));
  }, [media, store]);

  return (
    <React.Fragment>
      <MediaLoopEditModalVideo media={media} onProgress={handleProgress} store={store} />
      <Button block={true} onClick={handleAdd}>
        Add Loop Section
      </Button>
      <ContentContainer>
        {media.loops.map((loop, index) => (
          <MediaLoopEntry key={`${media.id}_${index}`} media={media} loop={loop} index={index} store={store} />
        ))}
      </ContentContainer>
    </React.Fragment>
  );
});
