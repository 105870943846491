/**
 * Created by neo on 18.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RecurringPattern } from '../../../../../Model/Coach/Schedule/RecurringPattern';
import { DayOfWeek } from '../../../../../Model/DayOfWeek';
import { runInAction } from 'mobx';
import { Col, Row } from 'reactstrap';
import { Checkbox } from 'antd';
import { EarnModelRuleTimeConstraint } from '../../../../../Model/Engagement/EarnModelRuleTimeConstraint';

export type RecurringPatternDaysOfWeekProps = {
  recurringPattern: RecurringPattern | EarnModelRuleTimeConstraint;
};

export const RecurringPatternDaysOfWeek: React.FC<RecurringPatternDaysOfWeekProps> = observer(
  ({ recurringPattern }) => {
    const handleChangeWeekDay = React.useCallback(
      (weekDay: DayOfWeek) =>
        ({ target: { checked } }) => {
          runInAction(() => {
            if (recurringPattern) {
              if (checked) {
                if (!recurringPattern.daysOfWeek.includes(weekDay)) {
                  recurringPattern.daysOfWeek.push(weekDay);
                }
              } else {
                recurringPattern.daysOfWeek = recurringPattern.daysOfWeek.filter((w) => w !== weekDay);
              }
            }
          });
        },
      [recurringPattern],
    );
    return (
      <React.Fragment>
        {recurringPattern && (
          <Row>
            <Col>
              <Checkbox
                checked={recurringPattern.daysOfWeek.includes('MONDAY')}
                onChange={handleChangeWeekDay('MONDAY')}
              >
                Monday
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={recurringPattern.daysOfWeek.includes('TUESDAY')}
                onChange={handleChangeWeekDay('TUESDAY')}
              >
                Tuesday
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={recurringPattern.daysOfWeek.includes('WEDNESDAY')}
                onChange={handleChangeWeekDay('WEDNESDAY')}
              >
                Wednesday
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={recurringPattern.daysOfWeek.includes('THURSDAY')}
                onChange={handleChangeWeekDay('THURSDAY')}
              >
                Thursday
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={recurringPattern.daysOfWeek.includes('FRIDAY')}
                onChange={handleChangeWeekDay('FRIDAY')}
              >
                Friday
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={recurringPattern.daysOfWeek.includes('SATURDAY')}
                onChange={handleChangeWeekDay('SATURDAY')}
              >
                Saturday
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={recurringPattern.daysOfWeek.includes('SUNDAY')}
                onChange={handleChangeWeekDay('SUNDAY')}
              >
                Sunday
              </Checkbox>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  },
);
