import { toJS } from 'mobx';
import { Token } from './Token';
import { UserToken } from './UserToken';
import * as ls from 'local-storage';
import AuthenticationService from './AuthenticationService';

export class AppAuthTokenClass extends Token {
  static STORE_KEY = 'admin_user:auth_data';

  refreshPromise?: Promise<UserToken>;
  authData?: UserToken;

  storeAuthData(authData: UserToken) {
    this.authData = authData;
    return ls.set(AppAuthTokenClass.STORE_KEY, toJS(authData));
  }

  loadAuthData(): UserToken | undefined {
    const json = ls.get(AppAuthTokenClass.STORE_KEY);
    if (json) {
      this.authData = new UserToken(json);
    }
    return this.authData;
  }

  async getAuthData(): Promise<UserToken | undefined> {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }
    if (this.authData && this.authData.expired) {
      try {
        this.refreshPromise = this.authData.refresh();
        this.storeAuthData(await this.refreshPromise);
      } catch (err: any) {
        if (err.response?.status === 401 || err.response?.status === 403) {
          AuthenticationService.logout();
        }
      } finally {
        this.refreshPromise = undefined;
      }
    }
    return this.authData;
  }

  deleteAuthData() {
    this.authData = undefined;
    return ls.remove(AppAuthTokenClass.STORE_KEY);
  }
}

const AppAuthToken = new AppAuthTokenClass();
export default AppAuthToken;
