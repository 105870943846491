/**
 * Created by neo on 06.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplateRuleActivationFrequency } from '../../../../../Model/Engagement/EarnModelTemplateRuleActivationFrequency';
import { Button, Card, Form, InputNumber, Popconfirm, Radio, Select } from 'antd';
import { DurationInput } from '../../../../../Components/DurationInput';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { runInAction } from 'mobx';
import { Col, Row } from 'reactstrap';
import { EarnModelTemplateRuleAbstract } from '../../../../../Model/Engagement/EarnModelTemplateRuleAbstract';

export type ActivationFrequencyTabInputProps = {
  rule: EarnModelTemplateRuleAbstract;
  index: number;
  activationFrequency: EarnModelTemplateRuleActivationFrequency;
};

export const ActivationFrequencyTabInput: React.FC<ActivationFrequencyTabInputProps> = observer(
  ({ rule, index, activationFrequency }) => {
    const handleRemove = React.useCallback(() => {
      rule.activationFrequencies.splice(index, 1);
    }, [rule, index]);

    const handleChangeMaxActivations = React.useCallback(
      (newValue) => runInAction(() => (activationFrequency.maxActivations = Number(newValue) ?? 0)),
      [activationFrequency],
    );

    const handleChangeDuration = React.useCallback(
      (newDuration) => {
        runInAction(() => (activationFrequency.duration = newDuration));
      },
      [activationFrequency],
    );

    const handleChangeExact = React.useCallback(
      () =>
        runInAction(() => {
          activationFrequency.duration = 'P1D';
          activationFrequency.type = undefined;
        }),
      [activationFrequency],
    );

    const handleChangeSimple = React.useCallback(
      () =>
        runInAction(() => {
          activationFrequency.duration = undefined;
          activationFrequency.type = 'daily';
        }),
      [activationFrequency],
    );

    const handleChangeType = React.useCallback(
      (newValue) => runInAction(() => (activationFrequency.type = newValue)),
      [activationFrequency],
    );

    return (
      <Card
        size="small"
        title={`${index + 1}. Frequency`}
        extra={
          <Popconfirm title="Remove this frequency?" onConfirm={handleRemove}>
            <Button type="link">Remove</Button>
          </Popconfirm>
        }
      >
        <SingleColRow>
          <Form.Item
            label="Max Activations"
            extra="How many times can the rule trigger within the time window defined below (-1 = unlimited)"
          >
            <InputNumber
              min={-1}
              max={1000}
              value={activationFrequency.maxActivations}
              onChange={handleChangeMaxActivations}
            />
          </Form.Item>
        </SingleColRow>
        <Row>
          <Col xs="auto">
            <Radio checked={!!activationFrequency.type} onChange={handleChangeSimple}>
              Simple
            </Radio>
          </Col>
          <Col>
            <Select value={activationFrequency.type} disabled={!activationFrequency.type} onChange={handleChangeType}>
              <Select.Option value="houryly">per hour</Select.Option>
              <Select.Option value="daily">per day</Select.Option>
              <Select.Option value="weekly">per week</Select.Option>
              <Select.Option value="montly">per month</Select.Option>
              <Select.Option value="yearly">per year</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <Radio checked={!!activationFrequency.duration} onChange={handleChangeExact}>
              Exact
            </Radio>
          </Col>
          <Col>
            <DurationInput
              value={activationFrequency.duration}
              onChange={handleChangeDuration}
              disabled={!activationFrequency.duration}
            />
          </Col>
        </Row>
      </Card>
    );
  },
);
