/**
 * Created by katarinababic on 15.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Row, Col, Container, Table, Spinner, Alert } from 'reactstrap';
import { Button } from 'antd';
import { useParams } from 'react-router';
import { FileContent } from '../../../Model/Media/FileStorage/FileContent';
import { FileTableRow } from './FileTableRow';
import { useDropzone } from 'react-dropzone';
import { EditFileDialog } from './EditFileDialog';
import { DeleteFileDialog } from './DeleteFileDialog';
import { FileStorageResult } from '../../../Model/Media/FileStorage/FileStorageResult';
import { debounce } from 'lodash';
import { InfiniteScroll } from '../../../Components/InfiniteScroll';

export type FolderViewScreenProps = {};

export const FolderViewScreen: React.FC<FolderViewScreenProps> = observer((props) => {
  const { folderId } = useParams();

  const hasMoreResults = React.useRef(false);
  const page = React.useRef(0);
  const loadingMore = React.useRef(false);

  const [files, setFiles] = React.useState<FileContent[]>([]);
  const [fileToEdit, setFileToEdit] = React.useState<FileContent | null>(null);
  const [processing, setProcessing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [nextPageToken, setNextPageToken] = React.useState<string | undefined>(undefined);

  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const loadData = async () => {
    setProcessing(true);
    try {
      const res = await FileStorageResult.getFolderContent(folderId, { size: 20, pageToken: nextPageToken });
      const resFiles = res.content.filter((f) => !f.isDirectory).map((folder) => new FileContent(folder));
      setFiles((previous) => (page.current === 0 ? resFiles : previous.concat(resFiles)));
      setNextPageToken(res.nextPageToken);
      hasMoreResults.current = !!res.nextPageToken;
    } catch (e) {
      console.log('FileStorageResult.getFolderContent error', e);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadMore = debounce(() => {
    if (!processing && hasMoreResults.current && !loadingMore.current) {
      loadingMore.current = true;
      page.current += 1;
      loadData().then(() => {
        loadingMore.current = false;
      });
    }
  }, 300);

  const reloadData = debounce(() => {
    page.current = 0;
    setNextPageToken(undefined);
    loadData();
  }, 300);

  const handleUploadFile = React.useCallback(
    (files: Array<File>) => {
      setProcessing(true);
      FileContent.uploadFiles(folderId, files)
        .then(() => {
          reloadData();
        })
        .catch((err) => setErrorMessage(err.message))
        .finally(() => setProcessing(false));
    },
    [reloadData],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadFile,
    multiple: true,
  });

  const handleShowEditFileDialog = React.useCallback((file: FileContent) => {
    setShowEditDialog(true);
    setFileToEdit(file);
  }, []);

  const handleCancelEditFileDialog = React.useCallback(() => {
    setShowEditDialog(false);
    setFileToEdit(null);
    reloadData();
  }, [reloadData]);

  const handleShowDeleteFileDialog = React.useCallback((file: FileContent) => {
    setShowDeleteDialog(true);
    setFileToEdit(file);
  }, []);

  const handleDeleteFile = React.useCallback(() => {
    setShowDeleteDialog(false);
    setFileToEdit(null);
    reloadData();
  }, [reloadData]);

  const handleCancelDeleteDialog = React.useCallback(() => {
    setShowDeleteDialog(false);
    setFileToEdit(null);
    reloadData();
  }, [reloadData]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{folderId}</h1>
        </Col>
        <Col xs="auto" {...getRootProps()}>
          <Button type="primary">
            <input {...getInputProps()} />
            Upload New File
          </Button>
        </Col>
      </Row>
      {processing ? (
        <Row>
          <Col xs={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </Col>
        </Row>
      ) : (
        <Table striped hover>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <thead>
            <tr>
              <th>{'No.'}</th>
              <th />
              <th>{'Name'}</th>
              <th>{'Created'}</th>
              <th>{'Last modified'}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <FileTableRow
                index={index}
                key={`${file.name}-${index}`}
                file={file}
                onRenameFile={handleShowEditFileDialog}
                onDeleteFile={handleShowDeleteFileDialog}
              />
            ))}
          </tbody>
        </Table>
      )}
      {hasMoreResults.current ? <InfiniteScroll loading={processing} onLoad={loadMore} /> : null}
      {fileToEdit && <EditFileDialog isOpen={showEditDialog} file={fileToEdit} onClose={handleCancelEditFileDialog} />}
      {fileToEdit && (
        <DeleteFileDialog
          isOpen={showDeleteDialog}
          file={fileToEdit}
          onClose={handleCancelDeleteDialog}
          onDeleteFile={handleDeleteFile}
        />
      )}
    </Container>
  );
});
