/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { ExerciseVariationFilterEquipmentTypes } from './ExerciseVariationFilterEquipmentTypes';
import { ExerciseVariationFilterBodyParts } from './ExerciseVariationFilterBodyParts';
import { ExerciseVariationFilterType } from './ExerciseVariationFilterType';
import { ExerciseVariationFilterTrackingKeys } from './ExerciseVariationFilterTrackingKeys';
import { ExerciseVariationFilterEquipmentTypesExcluded } from './ExerciseVariationFilterEquipmentTypesExcluded';
import { ExerciseVariationFilterMechanics } from './ExerciseVariationFilterMechanics';
import { ExerciseVariationFilterUtility } from './ExerciseVariationFilterUtility';
import { ExerciseVariationFilterForce } from './ExerciseVariationFilterForce';
import { ExerciseVariationFilterTrackingKeysExcluded } from './ExerciseVariationFilterTrackingKeysExcluded';
import { ExerciseVariationFilterTags } from './ExerciseVariationFilterTags';
import { ExerciseVariationFilterTagsExcluded } from './ExerciseVariationFilterTagsExcluded';
import { ExerciseVariationFilterExecutionTypes } from './ExerciseVariationFilterExecutionTypes';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { Space, Button, Form } from 'antd';
import { ExerciseVariationFilterHipFlexing } from './ExerciseVariationFilterHipFlexing';
import { ExerciseVariationFilterSpineFlexing } from './ExerciseVariationFilterSpineFlexing';
import { ExerciseVariationFilterSpineForceType } from './ExerciseVariationFilterSpineForceType';
import { ExerciseVariationFilterPositionPrimary } from './ExerciseVariationFilterPositionPrimary';
import { ExerciseVariationFilterPositionSecondary } from './ExerciseVariationFilterPositionSecondary';
import { ExerciseVariationFilterSynergists } from './ExerciseVariationFilterSynergists';
import { ExerciseVariationFilterStabilizers } from './ExerciseVariationFilterStabilizers';
import { ExerciseVariationFilterExecutionTypesExcluded } from './ExerciseVariationFilterExecutionTypesExcluded';
import { ExerciseVariationFilterPositionPrimaryExcluded } from './ExerciseVariationFilterPositionPrimaryExcluded';
import { ExerciseVariationFilterPositionSecondaryExcluded } from './ExerciseVariationFilterPositionSecondaryExcluded';
import { ExerciseVariationFilterBodyPartsExcluded } from './ExerciseVariationFilterBodyPartsExcluded';
import { ExerciseVariationFilterJoints } from './ExerciseVariationFilterJoints';
import { ExerciseVariationFilterJointsExcluded } from './ExerciseVariationFilterJointsExcluded';
import { ExerciseVariationFilterRequiredEquipmentTypes } from './ExerciseVariationFilterRequiredEquipmentTypes';
import { ExerciseVariationFilterEquipmentTypeGroup } from './ExerciseVariationFilterEquipmentTypeGroup';
import { ExerciseVariationFilterSecondaryEquipmentTypes } from './ExerciseVariationFilterSecondaryEquipmentTypes';
import { ExerciseVariationFilterSecondaryEquipmentTypesExcluded } from './ExerciseVariationFilterSecondaryEquipmentTypesExcluded';

export type ExerciseVariationFilterProps = {
  filter: ExerciseFilter;
  onApply?: (filter: ExerciseFilter) => any;
};

export const ExerciseVariationFilter: React.FC<ExerciseVariationFilterProps> = observer(
  ({ filter, onApply }: ExerciseVariationFilterProps) => {
    const handleApply = React.useCallback(() => {
      onApply && onApply(filter);
    }, [onApply, filter]);

    const handleClear = React.useCallback(() => {
      onApply && onApply(new ExerciseFilter());
    }, [onApply]);

    return (
      <Card>
        <CardBody>
          <Form layout="vertical">
            <Row>
              <Col sm={6}>
                <ExerciseVariationFilterType filter={filter} />
              </Col>
              <Col md={6}>
                <Row>
                  <Col>
                    <ExerciseVariationFilterHipFlexing filter={filter} />
                  </Col>
                  <Col>
                    <ExerciseVariationFilterSpineFlexing filter={filter} />
                  </Col>
                  <Col>
                    <ExerciseVariationFilterSpineForceType filter={filter} />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterRequiredEquipmentTypes filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterEquipmentTypeGroup filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterEquipmentTypes filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterEquipmentTypesExcluded filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterSecondaryEquipmentTypes filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterSecondaryEquipmentTypesExcluded filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterTags filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterTagsExcluded filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterBodyParts filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterBodyPartsExcluded filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterSynergists filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterStabilizers filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterJoints filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterJointsExcluded filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterTrackingKeys filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterTrackingKeysExcluded filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterMechanics filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterUtility filter={filter} />
              </Col>
              <Col sm={6}>
                <ExerciseVariationFilterForce filter={filter} />
              </Col>
              <Col sm={6}>
                <Row>
                  <Col>
                    <ExerciseVariationFilterPositionPrimary filter={filter} />
                  </Col>
                  <Col>
                    <ExerciseVariationFilterPositionSecondary filter={filter} />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col>
                    <ExerciseVariationFilterPositionPrimaryExcluded filter={filter} />
                  </Col>
                  <Col>
                    <ExerciseVariationFilterPositionSecondaryExcluded filter={filter} />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col>
                    <ExerciseVariationFilterExecutionTypes filter={filter} />
                  </Col>
                  <Col>
                    <ExerciseVariationFilterExecutionTypesExcluded filter={filter} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col />
              <Col xs="auto">
                <Space>
                  {onApply ? (
                    <Button type="primary" onClick={handleApply}>
                      Apply Filter
                    </Button>
                  ) : null}
                  {onApply ? (
                    <Button type="dashed" onClick={handleClear}>
                      Clear
                    </Button>
                  ) : null}
                </Space>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  },
);
