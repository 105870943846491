import { computed } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import AppAuthToken from '../../Services/Security/AppAuthToken';
import { EventSourcePolyfill } from 'event-source-polyfill';

export class DomainEvent {
  token: number;
  aggregateIdentifier: string;
  aggregateType: string;
  aggregateSequenceNumber: number = 0;
  payloadData: any;
  payloadType: string;
  payloadRevision?: string;
  metaData: any;
  eventIdentifier: string;
  timestamp: string;

  constructor(json?: any) {
    if (json) {
      this.token = json.token;
      this.aggregateIdentifier = json.aggregateIdentifier;
      this.aggregateType = json.aggregateType;
      this.aggregateSequenceNumber = json.aggregateSequenceNumber;
      this.payloadData = JSON.parse(json.payloadData);
      this.payloadType = json.payloadType;
      this.payloadRevision = json.payloadRevision;
      this.metaData = json.metaData;
      this.eventIdentifier = json.eventIdentifier;
      this.timestamp = json.timestamp;
    }
  }

  @computed
  get payloadTypeShort(): string {
    return this.payloadType.substr(this.payloadType.lastIndexOf('.') + 1);
  }

  static async list(aggregateType: string, aggregateIdentifer: string): Promise<Array<DomainEvent>> {
    const results = await HttpBackend.get(`/backup/events/${aggregateType}/${aggregateIdentifer}`);
    return results
      .map((r: any) => {
        r.payload = JSON.parse(r.serializedPayload);
        r.metaData = JSON.parse(r.serializedMetaData);
        return r;
      })
      .map((r) => new DomainEvent(r));
  }

  static async findByAggregate(aggregateId: string): Promise<Array<DomainEvent>> {
    const token = await AppAuthToken.getAuthData();
    const results = new Array<DomainEvent>();
    if (token) {
      return new Promise((resolve, reject) => {
        const source = new EventSourcePolyfill(
          `https://api.kinastic.com/v1/infrastructure/gateway/v1/events?aggregateId=${encodeURIComponent(
            aggregateId,
          )}&context=default&allowSnapshots=false&initialSequence=0&timeout=3600&trackingToken=0`,
          {
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          },
        );

        console.log('source', source);

        source.onopen = () => {
          console.log('onopen');
        };

        source.onmessage = (event: any) => {
          const data = JSON.parse(event.data);

          console.log('row', data);

          try {
            if (data.deleted) {
              console.info('Deleted');
              return;
            } else {
              const event = new DomainEvent(data);
              results.push(event);
            }
          } catch (e) {
            console.error('error parsing event', e);
          }
        };

        source.addEventListener('done', (event) => {
          // target.working = false;
          console.log('done', event);
          resolve(results);
          // this.processBatch(this.batch);
          // this.results = this.results.sort((a: any, b: any) => b.token - a.token);
        });

        source.addEventListener(
          'error',
          (e) => {
            if (e.readyState === EventSource.CLOSED) {
              // Connection was closed.
            } else {
              if (e.data) {
                alert(e.data);
              }
            }
            source.close();
            reject(e);
          },
          false,
        );
      });
    }
    return Promise.reject(new Error('unable to fetch token'));
  }
}
