/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Customer } from '../../../../Model/Customer/Customer';

export type CustomersTableRowProps = {
  customer: Customer;
  onClick?: (customer: Customer) => any;
};

export const CustomersTableRow: React.FC<CustomersTableRowProps> = observer(({ customer, onClick }) => {
  return (
    <tr>
      <td>{customer.id}</td>
      <td>
        <Link to={`/infrastructure/gym/${customer.gymId}`} target="_blank">
          {customer.gym ? customer.gym.name : ''}
        </Link>
      </td>
    </tr>
  );
});
