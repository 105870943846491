/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { HealthDataPointQuantityEntry } from './HealthDataPointQuantityEntry';
import { Button, Card } from 'antd';
import { HealthDataPointQuantity } from '../../../../Model/HealthData/HealthDataPointQuantity';
import { HealthDataPointFactory } from '../../../../Model/HealthData/HealthDataPointFactory';
import { HealthDataPointEntrySwitch } from './HealthDataPointEntrySwitch';

export type Props = {
  athlete: Athlete;
};

export const BodyValueCard: React.FC<Props> = observer(({ athlete }: Props) => {
  const handleAdd = React.useCallback(() => {
    const first = athlete.missingBodyValueTypes[0];
    if (first) {
      athlete.bodyValues?.set(first, HealthDataPointFactory.create(first));
    }
  }, [athlete]);

  const { bodyValues } = athlete;

  return (
    <Card title="Body Values">
      {bodyValues && (
        <React.Fragment>
          {Array.from(athlete.bodyValues?.values() ?? []).map((dataPoint) => (
            <HealthDataPointEntrySwitch key={dataPoint.type} dataPoint={dataPoint} athlete={athlete} />
          ))}
        </React.Fragment>
      )}
      <Button type="primary" onClick={handleAdd}>
        Add Entry
      </Button>
    </Card>
  );
});
