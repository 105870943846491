/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { OAuthScopeTable } from './OAuthScopeTable';
import { OAuthScope } from '../../../Model/OAuthScope';
import { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';

export type OAuthScopeListScreenProps = {};

export const OAuthScopeListScreen: React.FC<OAuthScopeListScreenProps> = observer(() => {
  const [result, setResult] = useState<OAuthScope[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const debounce = React.useRef<ReturnType<typeof setTimeout>>();

  const history = useNavigate();

  useEffect(() => {
    OAuthScope.find({ query, page }).then((res) => setResult(res));
  }, [page, query]);

  const load = () => {
    OAuthScope.find({ query, page }).then((res) => setResult(res));
  };

  const handlePage = (newPage: number): void => {
    if (page !== newPage) {
      setPage(newPage);
    }
  };

  const handleDelete = async (oauthScope: OAuthScope) => {
    if (window.confirm(`CAREFUL: Delete ${oauthScope.fullName}?`)) {
      if (window.confirm(`Are you really sure? Delete ${oauthScope.fullName}?`)) {
        await oauthScope.remove();
        load();
      }
    }
  };

  const handleQueryChange = ({ target: { value } }: any) => {
    setQuery(value);
    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setPage(0);
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'OAuth Scopes'}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" href="/security/oauthScope/create">
            Create Scope
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item>
            <Input type="text" onChange={handleQueryChange} value={query} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <OAuthScopeTable scopes={result} onDelete={handleDelete} />
        </Col>
      </Row>
    </Container>
  );
});
