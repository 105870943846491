/**
 * Created by neo on 21.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Media } from '../../../../../Model/Media/Media';

export type MediaAudioContentProps = {
  media: Media;
};

export const MediaAudioContent: React.FC<MediaAudioContentProps> = observer(({ media }) => {
  return null;
});
