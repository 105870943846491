/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../Default/DefaultStageNodeWidget';

export type WorkoutNodeWidgetProps = {} & StageNodeWidgetProps;

export const WorkoutNodeWidget: React.FC<WorkoutNodeWidgetProps> = observer(
  ({ node, engine, size }: WorkoutNodeWidgetProps) => {
    return <DefaultStageNodeWidget engine={engine} node={node} size={size} disableEdit={true} />;
  },
);
