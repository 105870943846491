/**
 * Created by neo on 15.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export type UserDailyEngagementTimeChartProps = {
  data: any[];
};

export const UserDailyEngagementTimeChart: React.FC<UserDailyEngagementTimeChartProps> = observer(({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width={730} height={250} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" label={{ value: 'Eng. Time Minutes', position: 'insideTopLeft', dy: -25 }} />
        <YAxis yAxisId="right" label={{ value: 'Unique Users', position: 'insideTopRight', dy: -25 }} orientation="right" tickLine={false} axisLine={false} />
        <Tooltip />
        <Legend />
        <Line yAxisId="left" type="monotone" dataKey="value" stroke="#195a19" name="Engagement Time Minutes" />
        <Line yAxisId="right" type="monotone" dataKey="usersCount" stroke="#ff0000" name="# Unique Users" />
      </LineChart>
    </ResponsiveContainer>
  );
});
