/**
 * Created by neo on 31.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Button, Calendar } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { EventEntry } from '../../../Model/Coach/Schedule/EventEntry';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { EventEntryTable } from './EventEntryTable';
import { EventSchedulerModal } from './EventSchedulerModal';

export type EventsIndexScreenProps = {};

export const EventsIndexScreen: React.FC<EventsIndexScreenProps> = observer((props) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [modalOpen, setModalOpen] = useState(false);
  const [entries, setEntries] = useState<PageResult<EventEntry>>(new PageResult());
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      EventEntry.find({
        page,
        size: 20,
        sort: 'athleteId,ASC,startDateTime,ASC,objectId,ASC',
        startDateTime: selectedDate.startOf('day').toDate(),
        endDateTime: selectedDate.endOf('day').toDate(),
      }),
      EventEntry.count({
        startDateTime: selectedDate.startOf('day').toDate(),
        endDateTime: selectedDate.endOf('day').toDate(),
      }),
      page,
      20,
    ).then((result) => setEntries(result));
  }, [selectedDate, page]);

  const handleSelect = React.useCallback((value) => {
    setSelectedDate(value);
  }, []);

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleOpenModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h2>Events / Scheduler</h2>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleOpenModal}>
            Schedule Events
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Calendar value={selectedDate} onSelect={handleSelect} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={entries} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <EventEntryTable entries={entries.content.slice()} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={entries} onPage={handlePage} />
      </SingleColRow>
      {modalOpen && <EventSchedulerModal onCancel={handleCloseModal} />}
    </Container>
  );
});
