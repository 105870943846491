/**
 *
 * Created by neo on 14.06.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import { Input } from 'antd';
import { useState } from 'react';

export type MultiRowProps = {
  container: any;
};

export const MultiRow: React.FC<MultiRowProps> = observer(({ container }) => {
  const [newValue, setNewValue] = useState('');

  const addRow = (e) => {
    e.preventDefault();
    container.push(newValue);
    setNewValue('');
  };

  const removeRow = (index: number) => {
    container.splice(index, 1);
  };

  return (
    <div>
      {container.map((value, index) => (
        <Row key={index}>
          <Col>
            <Input key={index} type="text" value={value} onChange={(e) => (container[index] = e.target.value)} />
          </Col>
          <Col xs="auto">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                removeRow(index);
              }}
            >
              Remove
            </a>
          </Col>
        </Row>
      ))}
      <Row>
        <Col>
          <Input type="text" value={newValue || ''} onChange={(e) => setNewValue(e.target.value)} />
        </Col>
        <Col xs="auto">
          <a href="" onClick={addRow}>
            Add
          </a>
        </Col>
      </Row>
    </div>
  );
});
