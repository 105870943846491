import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { v4 as UUID } from 'uuid';
import AppAuthToken from '../../../../../Services/Security/AppAuthToken';
import { DomainEvent } from '../../../../../Model/Axon/DomainEvent';
import { AxonEventKotlinExportModal } from './AxonEventKotlinExportModal';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxonQueryTable } from './AxonQueryTable/AxonQueryTable';
import { ExportKotlinButton } from './ExportKotlinButton';
import { UserToken } from '../../../../../Services/Security/UserToken';

const EventSource = EventSourcePolyfill;

export const AxonQueryTab: React.FC<unknown> = observer(() => {
  const [exports, setExports] = useState<Array<string> | undefined>(undefined);
  const clientToken = React.useMemo(() => UUID(), []);
  const eventSource = React.useRef<EventSource | undefined>(undefined);
  const location = useLocation();
  const history = useNavigate();
  const query = new URLSearchParams(location.search).get('query');

  const store = useLocalStore(() => ({
    query: 'token > 0 | last 10 minutes',
    context: '',
    results: new Array<DomainEvent>(),
    headers: [],
    async submit() {
      const { query, context } = store;
      eventSource.current?.close();

      const token = await AppAuthToken.getAuthData();
      if (token) {
        store.process(token).catch(() => store.process(token));
      }
    },
    process(token: UserToken) {
      return new Promise<void>((resolve, reject) => {
        const source = new EventSource(
          `https://api.kinastic.com/v1/infrastructure/gateway/v1/search?query=${encodeURIComponent(
            store.query,
          )}&context=default&timewindow=custom&liveupdates=false&clientToken=${encodeURIComponent(clientToken)}`,
          {
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          },
        );
        eventSource.current = source;

        store.headers = [];
        store.results = [];

        source.addEventListener('metadata', (event) => {
          resolve();
          // target.columns = JSON.parse(event.data);
          console.log('metadata', JSON.parse(event.data));
          this.headers = JSON.parse(event.data);
        });

        source.addEventListener('done', (event) => {
          console.log('data', event);
          // target.working = false;
          console.log('done', event);
          // this.processBatch(this.batch);
          // this.results = this.results.sort((a: any, b: any) => b.token - a.token);
        });

        source.addEventListener('row', (event) => {
          console.log('data', event);
          const data = JSON.parse(event.data);

          // console.log('row', d);

          if (data.deleted) {
            console.info('Deleted');
            return;
          } else {
            store.processBatch([data]);
          }
        });

        source.addEventListener(
          'error',
          (e) => {
            console.log('failed fetching events', e);
            if (e.readyState === EventSource.CLOSED) {
              console.log('failed fetching events connection closed', e);
            } else {
              if (e.data) {
                alert(e.data);
              }
            }
            source.close();
            reject();
          },
          false,
        );
      });
    },
    processBatch(batch: any[]) {
      for (const entry of batch) {
        let rowIdx = 0;
        const event = new DomainEvent(entry.value);
        while (rowIdx < store.results.length && event.token < store.results[rowIdx].token) {
          rowIdx++;
        }

        store.results.splice(rowIdx, 0, event);
      }
    },
  }));

  useEffect(() => {
    store.query = query || 'token > 0 | last 10 minutes';
  }, [query]);

  const changeUrl = (query: string) => {
    const search = new URLSearchParams([['query', store.query]]);
    history(
      {
        pathname: location.pathname,
        search: search.toString(),
      },
      { replace: true },
    );
  };

  const handleQueryChange = React.useCallback(({ target: { value } }) => {
    store.query = value;
  }, []);

  const handleKeyDown = React.useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        changeUrl(store.query);
        store.submit();
      }
    },
    [history, location],
  );

  const changeQuery = React.useCallback(
    (query: string) => {
      store.query = query;
      changeUrl(query);
      store.submit();
    },
    [history, location, store],
  );

  const handleClearQuery = React.useCallback(() => {
    store.query = 'token > 0 | last 10 minutes';
    changeUrl(store.query);
    store.submit();
  }, []);

  const handleExport = React.useCallback((exports: any[]) => {
    setExports(exports);
  }, []);

  const handleCloseExportModal = React.useCallback(() => setExports(undefined), []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Input type="text" value={store.query} onChange={handleQueryChange} onKeyDown={handleKeyDown} />
        </Col>
        <Col xs="auto">
          <Button color="primary" onClick={handleClearQuery}>
            Clear
          </Button>
          <ExportKotlinButton events={store.results} onExport={handleExport} />
        </Col>
      </Row>
      <SingleColRow>
        <AxonQueryTable events={store.results} onFilter={changeQuery} />
      </SingleColRow>
      {exports ? <AxonEventKotlinExportModal export={exports} onClose={handleCloseExportModal} /> : null}
    </React.Fragment>
  );
});
