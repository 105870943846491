/**
 * Created by neo on 12.04.22.
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../../LocalizedArrayEntity';
import { computed, observable } from 'mobx';
import { MindfulnessProgramTemplateDay, MindfulnessProgramTemplateDayJson } from './MindfulnessProgramTemplateDay';

export type MindfulnessProgramTemplatePhaseJson = LocalizedArrayEntityJson & {
  type: string;
  days: MindfulnessProgramTemplateDayJson[];
  cycles: number;
};

export class MindfulnessProgramTemplatePhase extends LocalizedArrayEntity {
  @observable
  type = '';
  @observable
  days: MindfulnessProgramTemplateDay[] = [];
  @observable
  cycles = 1;

  constructor(json?: Partial<MindfulnessProgramTemplatePhaseJson>) {
    super(json);
    if (json) {
      this.type = json.type ?? '';
      this.days = (json.days ?? []).map((d) => new MindfulnessProgramTemplateDay(d));
      this.cycles = json.cycles ?? 1;
    }
  }

  toJS(newId: boolean = false): MindfulnessProgramTemplatePhaseJson {
    return Object.assign(super.toJS(newId), {
      type: this.type,
      days: this.days.map((d) => d.toJS(newId)),
      cycles: this.cycles,
    });
  }

  copy(): MindfulnessProgramTemplatePhase {
    return new MindfulnessProgramTemplatePhase(this.toJS(true));
  }

  @computed
  get minDurationInMinutes(): number | undefined {
    return this.days.reduce(
      (agg, cycle) =>
        cycle.minDurationInMinutes ? Math.min(cycle.minDurationInMinutes, agg ?? Number.MAX_SAFE_INTEGER) : agg,
      undefined as number | undefined,
    );
  }

  @computed
  get maxDurationInMinutes(): number | undefined {
    return this.days.reduce(
      (agg, cycle) =>
        cycle.maxDurationInMinutes ? Math.max(cycle.maxDurationInMinutes, agg ?? Number.MIN_SAFE_INTEGER) : agg,
      undefined as number | undefined,
    );
  }
}
