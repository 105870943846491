/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry } from './ActivityWorkoutEntry';
import { ZoomJoinMeetingClientParams } from './ZoomJoinMeetingClientParams';
import { Activity } from '../Activity/Activity';
import { Instructor, InstructorJson } from './Instructor';
import { SubscribableEntry, SubscribableEntryJson } from './SubscribableEntry';

export type ZoomMeetingEntryJson = SubscribableEntryJson & {
  hosts: InstructorJson[];
  meetingId: string;
  password?: string;
  /**
   * optional -> if set it means we will log an activity log
   */
  activityIdentifier?: string;
  data: Record<string, any>;
  clientParameters?: ZoomJoinMeetingClientParams;
};

export class ZoomMeetingEntry extends SubscribableEntry {
  @observable
  hosts: Instructor[] = [];
  @observable
  meetingId = '';
  @observable
  password?: string;
  @observable
  activityIdentifier?: string;
  @observable
  data: Record<string, any> = {};
  @observable
  clientParameters?: ZoomJoinMeetingClientParams;
  @observable
  activity?: Activity;
  fetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<ZoomMeetingEntryJson>) {
    super(
      Object.assign(
        json ?? {},
        {
          type: 'zoomMeeting',
        },
        { version: { minVersionIos: '1.37.0', minVersionAndroid: '1.37.0' } },
      ),
    );
    if (json) {
      this.hosts = (json.hosts ?? []).map((h) => new Instructor(h));
      this.type = 'zoomMeeting';
      this.meetingId = json.meetingId ?? '';
      this.password = json.password;
      this.activityIdentifier = json.activityIdentifier;
      this.data = json.data ?? {};
      this.clientParameters = json.clientParameters;
    }

    onBecomeObserved(this, 'activity', this.startFetchParent);
    onBecomeUnobserved(this, 'activity', this.stopFetchParent);
  }

  startFetchParent = () => {
    this.fetchDisposer = reaction(
      () => this.activityIdentifier,
      (activityIdentifier) => {
        if (activityIdentifier) {
          Activity.get(activityIdentifier).then((result) => runInAction(() => (this.activity = result)));
        } else {
          runInAction(() => (this.activity = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchParent = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = undefined;
  };

  toJS(newId: boolean = false): ZoomMeetingEntryJson {
    return Object.assign(super.toJS(newId), {
      hosts: this.hosts.map((h) => h.toJS()),
      meetingId: this.meetingId,
      password: this.password,
      activityIdentifier: this.activityIdentifier,
      data: toJS(this.data),
      clientParameters: this.clientParameters,
    });
  }

  static find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<ZoomMeetingEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'zoomMeeting' })).then((res) =>
      (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static findOne(id: string): Promise<ZoomMeetingEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new ZoomMeetingEntry(res));
  }
}
