import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { OrNodeWidget } from './OrNodeWidget';
import { OrNodeModel } from './OrNodeModel';
import { OrStage } from '../../../../../../../../Model/Coach/Stages/Logical/OrStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class OrNodeModelFactory extends AbstractReactFactory<OrNodeModel, DiagramEngine> {
  constructor() {
    super('logical:or');
  }

  generateReactWidget(event): JSX.Element {
    return <OrNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new OrNodeModel(new OrStage(new Pipeline()));
  }
}
