export const withHour = (columnName: string = 'hour') => `
WITH root AS (
    SELECT '00' as ${columnName} UNION ALL
    SELECT '01' as ${columnName} UNION ALL
    SELECT '02' as ${columnName} UNION ALL
    SELECT '03' as ${columnName} UNION ALL
    SELECT '04' as ${columnName} UNION ALL
    SELECT '05' as ${columnName} UNION ALL
    SELECT '06' as ${columnName} UNION ALL
    SELECT '07' as ${columnName} UNION ALL
    SELECT '08' as ${columnName} UNION ALL
    SELECT '09' as ${columnName} UNION ALL
    SELECT '10' as ${columnName} UNION ALL
    SELECT '11' as ${columnName} UNION ALL
    SELECT '12' as ${columnName} UNION ALL
    SELECT '13' as ${columnName} UNION ALL
    SELECT '14' as ${columnName} UNION ALL
    SELECT '15' as ${columnName} UNION ALL
    SELECT '16' as ${columnName} UNION ALL
    SELECT '17' as ${columnName} UNION ALL
    SELECT '18' as ${columnName} UNION ALL
    SELECT '19' as ${columnName} UNION ALL
    SELECT '20' as ${columnName} UNION ALL
    SELECT '21' as ${columnName} UNION ALL
    SELECT '22' as ${columnName} UNION ALL
    SELECT '23'
) 
SELECT * FROM root 
`;
