/**
 * Created by katarinababic on 28.4.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Form } from 'antd';
import { ChartColors } from '../ChartColors';

export type ActivitiesLoggedChartProps = {
  data: AnalyticsRow[];
};

export const ActivitiesLoggedAggregatedChart: React.FC<ActivitiesLoggedChartProps> = observer(({ data }) => {
  return (
    <Form.Item label="No of logged activities">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart height={400} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar type="monotone" dataKey="num_of_logged" fill={ChartColors[3]} />
          <Bar type="monotone" dataKey="user_count" fill={ChartColors[4]} />
        </BarChart>
      </ResponsiveContainer>
    </Form.Item>
  );
});
