import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { BodyPartClusterSortStage } from '../../../../../../../Model/Coach/Stages/BodyPartClusterSortStage';

export class BodyPartClusterSortModel extends DefaultStageNodeModel {
  constructor(public readonly stage: BodyPartClusterSortStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'bodyPartClusterSort' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
