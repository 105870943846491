/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ArticleEntry } from '../../../../Model/Explore/ArticleEntry';
import { ActivityWorkoutEntry } from '../../../../Model/Explore/ActivityWorkoutEntry';
import { WorkoutTemplateEntry } from '../../../../Model/Explore/WorkoutTemplateEntry';
import { ArticleEntryContent } from './ArticleEntryContent';
import { ActivityWorkoutEntryContent } from './ActivityWorkoutEntryContent';
import { WorkoutTemplateEntryContent } from './WorkoutTemplate/WorkoutTemplateEntryContent';
import { MeditationEntry } from '../../../../Model/Explore/MeditationEntry';
import { MeditationEntryContent } from './Meditation/MeditationEntryContent';
import { BreathingEntry } from '../../../../Model/Explore/BreathingEntry';
import { BreathingEntryContent } from './Breathing/BreathingEntryContent';
import { YogaEntry } from '../../../../Model/Explore/YogaEntry';
import { YogaEntryContent } from './Yoga/YogaEntryContent';
import { WorkplaceWorkoutEntry } from '../../../../Model/Explore/WorkplaceWorkoutEntry';
import { WorkplaceWorkoutEntryContent } from './WorkplaceWorkout/WorkplaceWorkoutEntryContent';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ZoomMeetingEntryContent } from './ZoomMeetingEntry/ZoomMeetingEntryContent';
import { ZoomMeetingEntry } from '../../../../Model/Explore/ZoomMeetingEntry';
import { VideoEntry } from '../../../../Model/Explore/VideoEntry';
import { VideoEntryContent } from './Video/VideoEntryContent';
import { LinkEntry } from '../../../../Model/Explore/LinkEntry';
import { LinkEntryContent } from './Link/LinkEntryContent';
import { OnlineMeetingEntry } from '../../../../Model/Explore/OnlineMeetingEntry';
import { OnlineMeetingEntryContent } from './OnlineMeetingEntry/OnlineMeetingEntryContent';
import { OfflineMeetingEntry } from '../../../../Model/Explore/OfflineMeetingEntry';
import { OfflineMeetingEntryContent } from './OfflineMeeting/OfflineMeetingEntryContent';
import { WebinarEntry } from '../../../../Model/Explore/WebinarEntry';
import { WebinarEntryContent } from './Webinar/WebinarEntryContent';

export type ExploreEntryContentSwitchProps = {
  entry: ExploreEntry;
};

export const ExploreEntryContentSwitch: React.FC<ExploreEntryContentSwitchProps> = observer(({ entry }) => {
  console.log('entry switch', entry);
  if (entry instanceof ArticleEntry) {
    return <ArticleEntryContent entry={entry} />;
  } else if (entry instanceof MeditationEntry) {
    return <MeditationEntryContent entry={entry} />;
  } else if (entry instanceof YogaEntry) {
    return <YogaEntryContent entry={entry} />;
  } else if (entry instanceof BreathingEntry) {
    return <BreathingEntryContent entry={entry} />;
  } else if (entry instanceof ActivityWorkoutEntry) {
    return <ActivityWorkoutEntryContent entry={entry} />;
  } else if (entry instanceof WorkplaceWorkoutEntry) {
    return <WorkplaceWorkoutEntryContent entry={entry} />;
  } else if (entry instanceof WorkoutTemplateEntry) {
    return <WorkoutTemplateEntryContent entry={entry} />;
  } else if (entry instanceof ZoomMeetingEntry) {
    return <ZoomMeetingEntryContent entry={entry} />;
  } else if (entry instanceof VideoEntry) {
    return <VideoEntryContent entry={entry} />;
  } else if (entry instanceof LinkEntry) {
    return <LinkEntryContent entry={entry} />;
  } else if (entry instanceof OnlineMeetingEntry) {
    return <OnlineMeetingEntryContent entry={entry} />;
  } else if (entry instanceof OfflineMeetingEntry) {
    return <OfflineMeetingEntryContent entry={entry} />;
  } else if (entry instanceof WebinarEntry) {
    return <WebinarEntryContent entry={entry} />;
  }

  return null;
});
