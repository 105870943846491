/**
 * Created by neo on 01.11.2023
 */
import { observable } from 'mobx';

export type DateRangeJson = {
  validFrom: string;
  validUntil?: string;
};

export class DateRange {
  @observable
  validFrom = new Date();
  @observable
  validUntil?: Date;

  constructor(json?: Partial<DateRangeJson>) {
    if (json) {
      this.validFrom = json.validFrom ? new Date(json.validFrom) : new Date();
      this.validUntil = json.validUntil ? new Date(json.validUntil) : undefined;
    }
  }

  toJS(): DateRangeJson {
    return {
      validFrom: this.validFrom.toISOString(),
      validUntil: this.validUntil?.toISOString(),
    };
  }
}
