/**
 * Created by neo on 04.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Pager } from '../../../Components/Pager';
import { PageResult } from '../../../Model/PageResult';
import { GymChallengeParticipant } from '../../../Model/GymChallenge/GymChallengeParticipant';
import { GymChallenge } from '../../../Model/GymChallenge/GymChallenge';
import dayjs from '../../../Utils/dayjs';

export type GymChallengeModalParticipantTabProps = {
  challenge: GymChallenge;
};

export const GymChallengeModalParticipantTab: React.FC<GymChallengeModalParticipantTabProps> = observer(
  ({ challenge }) => {
    const [result, setResult] = useState(new PageResult<GymChallengeParticipant>());
    const [page, setPage] = useState(0);

    useEffect(() => {
      PageResult.execute(challenge.fetchRankingAdmin(page, 50), challenge.countRankingAdmin(), page, 50).then((res) =>
        setResult(res),
      );
    }, [page, challenge]);

    const handlePage = React.useCallback((newPage: number) => {
      setPage(newPage);
    }, []);

    return (
      <React.Fragment>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
        <SingleColRow>
          <Table size="sm">
            <thead>
              <tr>
                <th>Rank</th>
                <th>User</th>
                <th>Points</th>
                <th>Join Date</th>
              </tr>
            </thead>
            <tbody>
              {result.content.map((participant, index) => (
                <tr key={participant.id}>
                  <td>{page * 50 + (index + 1)}</td>
                  <td>
                    <Link to={`/athlete/${participant.athleteId}`}>
                      {participant.athlete?.fullName || participant.athleteId}
                    </Link>
                  </td>
                  <td>{participant.totalPointsCollected}</td>
                  <td>{dayjs(participant.joinDate).format('LLLL')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </SingleColRow>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
      </React.Fragment>
    );
  },
);
