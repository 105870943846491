/**
 * Created by neo on 12.04.22.
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../../LocalizedArrayEntity';
import { computed, observable, toJS } from 'mobx';
import { Media, MediaJson } from '../../../Media/Media';
import { VersionInformation, VersionInformationJson } from '../../../VersionInformation';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { Pageable } from '../../../Pageable';
import {
  MindfulnessProgramTemplatePhase,
  MindfulnessProgramTemplatePhaseJson,
} from './MindfulnessProgramTemplatePhase';
import { ProgramTemplateResult, ProgramTemplateResultJson } from '../../ProgramTemplateResult';
import { Quote, QuoteJson } from '../../Quote';
import { ConditionalMedia, ConditionalMediaJson } from '../../SuperMacro/ConditionalMedia';
import { TimeOfDay } from '../../../TimeOfDay';
import { TimesOfDay, TimesOfDayJson } from '../../TimesOfDay';
import { TimeRange } from '../../../Gym/TimeRange';
import { ScheduleTimeRange } from '../../ScheduleTimeRange';

export type MindfulnessProgramTemplateQueryRequest = Pageable & {
  query: string;
  tags: string[];
};

export type MindfulnessProgramTemplateJson = LocalizedArrayEntityJson & {
  templateName: string;
  type: string;
  tags: string[];
  images: ConditionalMediaJson[];
  phases: MindfulnessProgramTemplatePhaseJson[];
  minDurationInMinutes?: number;
  maxDurationInMinutes?: number;
  supportedVersion: VersionInformationJson;
  expectedResults: ProgramTemplateResultJson[];
  quotes: QuoteJson[];
  gymId?: string;
  defaultTimesOfDay: TimesOfDayJson;
};

export class MindfulnessProgramTemplate extends LocalizedArrayEntity {
  @observable
  templateName = '';
  @observable
  type = '';
  @observable
  tags: string[] = [];
  @observable
  images: ConditionalMedia[] = [];
  @observable
  phases: MindfulnessProgramTemplatePhase[] = [];
  @observable
  supportedVersion: VersionInformation = new VersionInformation();
  @observable
  expectedResults: ProgramTemplateResult[] = [];
  @observable
  quotes: Quote[] = [];
  @observable
  gymId?: string;
  @observable
  defaultTimesOfDay: TimesOfDay = {};

  constructor(json?: Partial<MindfulnessProgramTemplateJson>) {
    super(json);
    if (json) {
      this.templateName = json.templateName ?? '';
      this.type = json.type ?? '';
      this.tags = json.tags ?? [];
      this.images = (json.images ?? []).map((m) => new ConditionalMedia(m));
      this.phases = (json.phases ?? []).map((p) => new MindfulnessProgramTemplatePhase(p));
      this.supportedVersion = new VersionInformation(json.supportedVersion);
      this.expectedResults = (json.expectedResults ?? []).map((r) => new ProgramTemplateResult(r));
      this.quotes = (json.quotes ?? []).map((r) => new Quote(r));
      this.gymId = json.gymId;
      this.defaultTimesOfDay = Object.entries(json.defaultTimesOfDay ?? ({} as TimesOfDay))
        .map(([key, value]) => [key, new ScheduleTimeRange(value)])
        .reduce((obj, [key, value]) => Object.assign(obj, { [key as TimeOfDay]: value }), {} as TimesOfDay);
    }
  }

  toJS(newId: boolean = false): MindfulnessProgramTemplateJson {
    return Object.assign(super.toJS(newId), {
      templateName: this.templateName,
      type: this.type,
      tags: toJS(this.tags),
      images: this.images.map((m) => m.toJS()),
      phases: this.phases.map((p) => p.toJS(newId)),
      minDurationInMinutes: this.minDurationInMinutes,
      maxDurationInMinutes: this.maxDurationInMinutes,
      supportedVersion: this.supportedVersion.toJS(),
      expectedResults: this.expectedResults.map((r) => r.toJS()),
      quotes: this.quotes.map((q) => q.toJS()),
      gymId: this.gymId,
      defaultTimesOfDay: Array.from(Object.entries(this.defaultTimesOfDay))
        .map(([key, value]) => [key, value.toJS()])
        .reduce((agg, [key, entries]) => Object.assign(agg, { [key as string]: entries }), {} as TimesOfDayJson),
    });
  }

  save(): Promise<MindfulnessProgramTemplate> {
    return HttpBackend.post(`/coach/mindfulness/template/admin`, this.toJS()).then(() => this);
  }

  delete(): Promise<MindfulnessProgramTemplate> {
    return HttpBackend.delete(`/coach/mindfulness/template/admin/${this.id}`).then(() => this);
  }

  copy(): MindfulnessProgramTemplate {
    return new MindfulnessProgramTemplate(this.toJS(true));
  }

  @computed
  get minDurationInMinutes(): number | undefined {
    return this.phases.reduce(
      (agg, cycle) =>
        cycle.minDurationInMinutes ? Math.min(cycle.minDurationInMinutes, agg ?? Number.MAX_SAFE_INTEGER) : agg,
      undefined as number | undefined,
    );
  }

  @computed
  get maxDurationInMinutes(): number | undefined {
    return this.phases.reduce(
      (agg, cycle) =>
        cycle.maxDurationInMinutes ? Math.max(cycle.maxDurationInMinutes, agg ?? Number.MIN_SAFE_INTEGER) : agg,
      undefined as number | undefined,
    );
  }

  @computed
  get timesOfDay(): TimeOfDay[] {
    return [
      ...new Set(
        this.phases.flatMap((phase) => phase.days.flatMap((day) => day.units.map((unit) => unit.timeOfDay))) ?? [],
      ),
    ];
  }

  static find(request?: Partial<MindfulnessProgramTemplateQueryRequest>): Promise<MindfulnessProgramTemplate[]> {
    return HttpBackend.get(`/coach/mindfulness/template/admin`, toJS(request)).then((res) =>
      res.map((r) => new MindfulnessProgramTemplate(r)),
    );
  }

  static findOne(id: string): Promise<MindfulnessProgramTemplate> {
    return HttpBackend.get(`/coach/mindfulness/template/${id}`).then((res) => new MindfulnessProgramTemplate(res));
  }

  static count(request?: Partial<MindfulnessProgramTemplateQueryRequest>): Promise<number> {
    return HttpBackend.get(`/coach/mindfulness/template/admin/count`, toJS(request));
  }
}
