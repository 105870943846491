import { action, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { WorkoutEntry, WorkoutEntryJson } from './WorkoutEntry';

export type WorkoutDayConfigurationJson = {
  id: string;
  workouts: WorkoutEntryJson[];
};

export class SuperMacroWorkoutDayConfiguration {
  @observable
  id: string = UUID();
  @observable
  workouts: WorkoutEntry[] = [];

  constructor(json?: Partial<WorkoutDayConfigurationJson>) {
    if (json) {
      this.id = json.id || UUID();
      this.workouts = (json.workouts ?? []).map((m) => new WorkoutEntry(m));
    }
  }

  toJS(replaceId: boolean = false): any {
    return {
      id: replaceId ? UUID() : this.id,
      workouts: this.workouts.map((m) => m.toJS()),
    };
  }
}
