/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ArticleEntry } from '../../../../Model/Explore/ArticleEntry';
import { NativeFeedEditor } from '../../../../Components/FeedViewScreen/NativeFeedEditor/NativeFeedEditor';
import { Col, Row } from 'reactstrap';
import { Checkbox, Form } from 'antd';
import { v4 as UUID } from 'uuid';
import { runInAction } from 'mobx';

export type ArticleEntryContentProps = {
  entry: ArticleEntry;
};

export const ArticleEntryContent: React.FC<ArticleEntryContentProps> = observer(({ entry }) => {
  const [checkboxChecked, setCheckboxChecked] = React.useState(entry.newsArticleReferenceId != null);

  const handleCheckbox = React.useCallback(
    ({ target: { checked } }) => {
      setCheckboxChecked(checked);

      runInAction(() => {
        if (checked && entry.newsArticleReferenceId == null) {
          entry.newsArticleReferenceId = UUID();
        } else if (!checked) {
          entry.newsArticleReferenceId = null;
        }
      });
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Item>
            <Checkbox onChange={handleCheckbox} checked={checkboxChecked}>
              Also post this article in the News tab in the App
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <NativeFeedEditor entry={entry} />
    </React.Fragment>
  );
});
