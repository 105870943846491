import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { ExerciseBlockNodeModel } from './ExerciseBlockNodeModel';
import { ExerciseBlockNodeWidget } from './ExerciseBlockNodeWidget';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { ExerciseBlockStage } from '../../../../../../../Model/Coach/Stages/ExerciseBlockStage';

export class ExerciseBlockNodeFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('exerciseBlock');
  }

  generateReactWidget(event): JSX.Element {
    return <ExerciseBlockNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new ExerciseBlockNodeModel(new ExerciseBlockStage(new Pipeline()));
  }
}
