import {
  action,
  observable,
  IReactionDisposer,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
  reaction,
  computed,
} from 'mobx';

import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { PageResult } from '../../PageResult';
import { Exercise } from '../Exercise';
import { AbstractExerciseAdmin } from './AbstractExerciseAdmin';
import { BaseTrackingKey } from '../../ProgramPortfolio/TrackingKeys';
import { notUndefined } from '../../../Utils/notUndefined';
import { BodyPartRegion } from '../../BodyPart/BodyPartRegion';
import { BodyPartJoint } from '../../BodyPart/BodyPartJoint';
import { EquipmentType } from '../../Equipment/EquipmentType';
import { BodyPartRegionAdmin } from '../../BodyPart/BodyPartRegionAdmin';

const bodyPartSizes = ['huge', 'large', 'medium', 'small', 'tiny'];

export class ExerciseVariationAdmin extends AbstractExerciseAdmin {
  @observable
  equipmentTypes: EquipmentType[] = [];
  @observable
  secondaryEquipmentTypes: EquipmentType[] = [];
  @observable
  synergists: BodyPartRegionAdmin[] = [];
  @observable
  bodyParts: BodyPartRegionAdmin[] = [];
  @observable
  stabilizers: BodyPartRegionAdmin[] = [];
  @observable
  joints: BodyPartJoint[] = [];
  @observable
  allBodyParts: BodyPartRegionAdmin[] = [];
  @observable
  allSynergists: BodyPartRegionAdmin[] = [];
  @observable
  allStabilizers: BodyPartRegionAdmin[] = [];
  @observable
  easierAlternatives?: Exercise[];
  @observable
  alternatives?: Exercise[];
  @observable
  harderAlternatives?: Exercise[];
  exerciseFetchDisposer?: IReactionDisposer;
  easierFetchDisposer?: IReactionDisposer;
  alternativeFetchDisposer?: IReactionDisposer;
  harderFetchDisposer?: IReactionDisposer;

  constructor(json?: any) {
    super(json);

    if (json) {
      this.synergists = (json.synergists || []).map((b) => new BodyPartRegionAdmin(b));
      this.bodyParts = (json.bodyParts || []).map((b) => new BodyPartRegionAdmin(b));
      this.stabilizers = (json.stabilizers || []).map((b) => new BodyPartRegionAdmin(b));
      this.equipmentTypes = (json.equipmentTypes || []).map((e) => new EquipmentType(e));
      this.secondaryEquipmentTypes = (json.secondaryEquipmentTypes || []).map((e) => new EquipmentType(e));
      this.allSynergists = (json.allSynergists || []).map((b) => new BodyPartRegionAdmin(b));
      this.allBodyParts = (json.allBodyParts || []).map((b) => new BodyPartRegionAdmin(b));
      this.allStabilizers = (json.allStabilizers || []).map((b) => new BodyPartRegionAdmin(b));
    }

    onBecomeObserved(this, 'easierAlternatives', this.startFetchEasier);
    onBecomeUnobserved(this, 'easierAlternatives', this.stopFetchEasier);
    onBecomeObserved(this, 'alternatives', this.startFetchAlternatives);
    onBecomeUnobserved(this, 'alternatives', this.stopFetchAlternatives);
    onBecomeObserved(this, 'harderAlternatives', this.startFetchHarder);
    onBecomeUnobserved(this, 'harderAlternatives', this.stopFetchHarder);
  }

  toJS(newId?: boolean): any {
    return Object.assign(super.toJS(newId), {
      equipmentTypes: this.equipmentTypes.map((e) => e.toJS()),
      secondaryEquipmentTypes: this.secondaryEquipmentTypes.map((e) => e.toJS()),
      synergists: this.synergists.map((s) => s.toJS()),
      bodyParts: this.bodyParts.map((s) => s.toJS()),
      stabilizers: this.stabilizers.map((s) => s.toJS()),
    });
  }

  startFetchEasier = () => {
    this.easierFetchDisposer && this.easierFetchDisposer();
    this.easierFetchDisposer = reaction(
      () => this.easierAlternativeIds.map((i) => i),
      (ids) => {
        if (ids.length > 0) {
          Promise.all(ids.map((id) => Exercise.get(id, this.sourceType, this.sourceId))).then((result) =>
            runInAction(() => (this.easierAlternatives = result.filter(notUndefined))),
          );
        } else {
          runInAction(() => (this.easierAlternatives = []));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchEasier = () => {
    this.easierFetchDisposer && this.easierFetchDisposer();
  };

  startFetchAlternatives = () => {
    this.alternativeFetchDisposer && this.alternativeFetchDisposer();
    this.alternativeFetchDisposer = reaction(
      () => this.alternativeIds.map((i) => i),
      (ids) => {
        if (ids.length > 0) {
          Promise.all(ids.map((id) => Exercise.get(id, this.sourceType, this.sourceId))).then((result) =>
            runInAction(() => (this.alternatives = result.filter(notUndefined))),
          );
        } else {
          runInAction(() => (this.alternatives = []));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchAlternatives = () => {
    this.alternativeFetchDisposer && this.alternativeFetchDisposer();
  };

  startFetchHarder = () => {
    this.harderFetchDisposer && this.harderFetchDisposer();
    this.harderFetchDisposer = reaction(
      () => this.harderAlternativeIds.map((i) => i),
      (ids) => {
        if (ids.length > 0) {
          Promise.all(ids.map((id) => Exercise.get(id, this.sourceType, this.sourceId))).then((result) =>
            runInAction(() => (this.harderAlternatives = result.filter(notUndefined))),
          );
        } else {
          runInAction(() => (this.harderAlternatives = []));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchHarder = () => {
    this.harderFetchDisposer && this.harderFetchDisposer();
  };

  copy(): ExerciseVariationAdmin {
    return new ExerciseVariationAdmin(this.toJS(true));
  }

  remove(hard: boolean = false) {
    if (hard) {
      return HttpBackend.delete(`/exercise/admin/${this.id}/force`, {
        sourceType: this.sourceType,
        sourceId: this.sourceId,
      });
    }
    return HttpBackend.delete(`/exercise/admin/${this.id}`, {
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    });
  }

  @action
  addTrackingKey(key: BaseTrackingKey) {
    const index = this.trackingKeys.findIndex((tk) => tk === key);
    if (index === -1) {
      this.trackingKeys.push(key);
    }
  }

  @action
  removeTrackingKey(key: BaseTrackingKey) {
    const index = this.trackingKeys.findIndex((tk) => tk === key);
    if (index !== -1) {
      this.trackingKeys.splice(index, 1);
    }
  }

  save(): Promise<ExerciseVariationAdmin> {
    return HttpBackend.post('/exercise/admin', this.toJS()).then(() => this);
  }

  @computed
  get biggestBodyPart(): BodyPartRegionAdmin | undefined {
    const firstIndex = this.bodyParts
      .map((b) => bodyPartSizes.indexOf(b.size ?? 'medium') ?? 2)
      .sort((a, b) => a - b)[0];
    if (firstIndex) {
      return this.bodyParts[firstIndex];
    }
    return undefined;
  }

  @computed
  get smallestBodyPart(): BodyPartRegionAdmin | undefined {
    const firstIndex = this.bodyParts
      .map((b) => bodyPartSizes.indexOf(b.size ?? 'medium') ?? 2)
      .sort((a, b) => b - a)[0];
    if (firstIndex) {
      return this.bodyParts[firstIndex];
    }
    return undefined;
  }

  static find(params?: any): Promise<ExerciseVariationAdmin[]> {
    return HttpBackend.get('/exercise/admin', params).then((result) =>
      result.map((d) => new ExerciseVariationAdmin(d)),
    );
  }

  static autocomplete(params?: any): Promise<ExerciseVariationAdmin[]> {
    if (params.query) {
      return HttpBackend.get('/exercise/admin/autocomplete', params).then((result) =>
        result.map((d) => new ExerciseVariationAdmin(d)),
      );
    }
    return ExerciseVariationAdmin.find(params);
  }

  static autocompleteCount(params?: any): Promise<number> {
    if (params.query) {
      return HttpBackend.get('/exercise/admin/autocomplete/count', params);
    }
    return ExerciseVariationAdmin.count();
  }

  static count(params?: any): Promise<number> {
    return HttpBackend.get('/exercise/admin/count', params);
  }

  static async get(id: string, sourceType?: string | null, sourceId?: string | null): Promise<ExerciseVariationAdmin> {
    const result = await HttpBackend.get(`/exercise/admin/${id}`, { sourceType, sourceId });
    return new ExerciseVariationAdmin(result);
  }

  static async list(
    exerciseId: string,
    sourceType?: string,
    sourceId?: string,
  ): Promise<Array<ExerciseVariationAdmin>> {
    const result = await HttpBackend.get(`/exercise/admin/list`, { sourceType, sourceId });
    return (result || []).map((v) => new ExerciseVariationAdmin(v));
  }
}
