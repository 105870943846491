/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PageResult } from '../../../../Model/PageResult';
import { Pager } from '../../../../Components/Pager';
import { OAuthScope } from '../../../../Model/OAuthScope';
import { OAuthClient } from '../../../../Model/OAuthClient';

export type Props = {
  scope: OAuthScope;
};

@observer
export class OAuthViewClientsTab extends React.Component<Props> {
  @observable
  result: PageResult<OAuthClient> = new PageResult();
  @observable
  page: number = 0;

  componentDidMount() {
    this.load(this.page);
  }

  async load(page: number) {
    this.result = await PageResult.execute(
      OAuthClient.find({
        page,
        scopes: this.props.scope.id,
      }),
      OAuthClient.count({
        scopes: this.props.scope.id,
      }),
      this.page,
      10,
    );
  }

  handlePage = (page: number) => {
    if (this.page !== page) {
      this.page = page;
      this.load(this.page);
    }
  };

  render() {
    const { result } = this;
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <Pager page={result} onPage={this.handlePage} />
            </Col>
          </Row>
          <Row>
            <Col>{/*<UserTable users={result.content} />*/}</Col>
          </Row>
          <Row>
            <Col>
              <Pager page={result} onPage={this.handlePage} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
