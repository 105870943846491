/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { LogicalNodeWidget, LogicalNodeWidgetProps } from '../LogicalNodeWidget';

export type AllNodeWidgetProps = {} & LogicalNodeWidgetProps;

export const OrNodeWidget: React.FC<AllNodeWidgetProps> = observer(({ node, engine, size }: AllNodeWidgetProps) => {
  return <LogicalNodeWidget engine={engine} node={node} size={size} title="OR" />;
});
