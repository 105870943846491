/**
 * Created by andreaskarantzas on 02.03.2020
 */
import { observable } from 'mobx';

export default class FileInfo {
  @observable
  file: File;
  @observable
  loaded: number = 0;

  constructor(file: File) {
    this.file = file;
    this.loaded = 0;
  }
}
