/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Container } from 'reactstrap';
import { ImportExportModal } from './ImportExportModal';
import { useState } from 'react';
import { ExerciseListScreenContent } from './ExerciseListScreenContent';
import { SingleColRow } from '../../../../Components/SingleColRow';

export type ExerciseListScreenProps = {};

export const ExerciseListScreen: React.FC<ExerciseListScreenProps> = observer((_: ExerciseListScreenProps) => {
  const [showExportModal, setShowExportModal] = useState(false);

  const handleCloseExportModal = React.useCallback(() => setShowExportModal(false), []);

  return (
    <Container>
      <SingleColRow>
        <h1>Exercises</h1>
      </SingleColRow>
      <ExerciseListScreenContent />
      {showExportModal ? <ImportExportModal onClose={handleCloseExportModal} /> : null}
    </Container>
  );
});
