/**
 * Created by katarinababic on 16.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { FileContent } from '../../../Model/Media/FileStorage/FileContent';

export type DeleteFileDialogProps = {
  isOpen?: boolean;
  file: FileContent;
  onDeleteFile?: () => void;
  onClose?: () => void;
};

export const DeleteFileDialog: React.FC<DeleteFileDialogProps> = observer(({ file, isOpen, onDeleteFile, onClose }) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [processing, setProcessing] = React.useState(false);

  const toggle = React.useCallback(() => {
    if (isOpen) {
      onClose?.();
    }
  }, [onClose]);

  const dismiss = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleDeleteFile = React.useCallback(() => {
    setProcessing(true);
    FileContent.deleteFile(file.name)
      .then((res) => {
        onDeleteFile?.();
      })
      .catch((e) => setErrorMessage(e.message))
      .finally(() => setProcessing(false));
  }, [onClose, file]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Delete File</ModalHeader>
      <ModalBody>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <Label>Are you sure you want to delete this file?</Label>
      </ModalBody>
      <ModalFooter>
        {processing ? (
          <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </Col>
        ) : (
          <React.Fragment>
            <Button color="primary" onClick={handleDeleteFile}>
              Delete
            </Button>
            <Button color="secondary" onClick={dismiss}>
              Cancel
            </Button>
          </React.Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
});
