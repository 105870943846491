import * as React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'reactstrap';

const KEYS_NAME = {
  MAX_REPETITIONS: 'Max. Reps',
  MIN_REPETITIONS: 'Min. Reps',
  MIN_WEIGHT: 'Min. Wt.',
  MAX_WEIGHT: 'Max. Wt.',
  MIN_DURATION: 'Min. Dur.',
  MAX_DURATION: 'Max. Dur.',
  MIN_BREAK: 'Min. Break',
  MAX_BREAK: 'Max. Break',
  REPETITIONS: 'Reps',
  WEIGHT: 'Wt',
};

export type Props = {
  values: any;
  setKey: string;
};

@observer
export class SetsEntry extends React.Component<Props> {
  handleChange = ({ target: { value } }: any) => {
    this.props.values.set(this.props.setKey, Number(value));
  };

  render() {
    const { setKey, values } = this.props;
    const value = values.get(setKey);
    return (
      <div>
        <div>{KEYS_NAME[setKey]}</div>
        <Input type="number" value={value} onChange={this.handleChange} />
      </div>
    );
  }
}
