/**
 *
 * Created by neo on 25.02.17.
 */
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Dropzone from 'react-dropzone';
import MediaService from '../../../Services/MediaService';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { Media } from '../../../Model/Media/Media';
import { RootStore } from '../../../Store/RootStore';
import { ViewMediaItem } from '../../../Components/ViewMedia/ViewMediaItem';

export type Props = {
  containerName: string;
  rootStore?: RootStore;
};

@inject('rootStore')
@observer
export class WorkoutImagesScreen extends React.Component<Props> {
  @observable
  defaultMedia: Media[] = [];

  dropzone: any;

  static defaultProps = {
    containerName: 'media',
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    const res = await HttpBackend.get('media/media/workout/default');
    this.defaultMedia = res.medias.map((m) => new Media(m));
  }

  handleDrop = async (files: File[]) => {
    for (const file of files) {
      const media = await MediaService.uploadMedia(file);
      this.defaultMedia.push(media);
    }
    this.save();
  };

  handleRemoveFile = (media: Media) => {
    const index = this.defaultMedia.findIndex((item) => item.url === media.url);
    this.defaultMedia.splice(index, 1);
    this.save();
  };

  save() {
    HttpBackend.post('media/media/workout/default', this.defaultMedia);
  }

  render() {
    const { defaultMedia } = this;
    return (
      <Container>
        <Row>
          <Col>
            <h1>Default Workout Images</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Dropzone ref={(d) => (this.dropzone = d)} onDrop={this.handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="dropzone"
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  {'Drop here the default workout images'}
                </div>
              )}
            </Dropzone>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }}>
          {defaultMedia
            .filter((m) => Boolean(m))
            .map((f: Media) => (
              <Col key={f.url} md="4">
                <ViewMediaItem
                  media={f}
                  onRemove={this.handleRemoveFile}
                  disableEdit={!this.props.rootStore?.authentication.isGodAdmin}
                />
              </Col>
            ))}
        </Row>
      </Container>
    );
  }
}
