/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Badge, Button, Card, Form, Popconfirm, Select, Space } from 'antd';
import { MicroCycleDay } from '../../../../Model/Coach/Program/MicroCycleDay';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { EquipmentConfigurationInput } from './EquipmentConfigurationInput';
import { CoachProgram } from '../../../../Model/Coach/Program/CoachProgram';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { Col, Row } from 'reactstrap';

export type MicroCycleDayViewProps = {
  athleteSuperMacro: CoachProgram;
  microCycleDay: MicroCycleDay;
  onClick?: (microCycleDay: MicroCycleDay) => any;
};

export const MicroCycleDayView: React.FC<MicroCycleDayViewProps> = observer(
  ({ athleteSuperMacro, microCycleDay, onClick }) => {
    const [refreshing, setRefreshing] = useState(false);
    const [changingParams, setChangingParams] = useState(false);
    const history = useNavigate();

    const durationTag = microCycleDay.params.tags.find((t) => t.startsWith('duration:'));

    const handleChangeDuration = React.useCallback(
      (value) => {
        microCycleDay.params.setSingleTag(value);
      },
      [microCycleDay],
    );

    const handleClick = React.useCallback(() => {
      onClick && onClick(microCycleDay);
    }, [onClick, microCycleDay]);

    const handleChangeParams = React.useCallback(() => {
      setChangingParams(true);
      microCycleDay.changeParams().finally(() => setChangingParams(false));
    }, [microCycleDay]);

    const handleRefresh = React.useCallback(() => {
      setRefreshing(true);
      microCycleDay.refresh().finally(() => setRefreshing(false));
    }, [microCycleDay]);

    const handleShowWorkoutLog = React.useCallback(() => {
      history(`/tracking/workoutlog/${microCycleDay.workoutLog?.id}`);
    }, [microCycleDay, history]);

    const handleSkip = React.useCallback(() => {
      microCycleDay.skip();
    }, [microCycleDay]);

    return (
      <Card
        hoverable
        cover={
          <img
            style={{ objectFit: 'cover' }}
            height={220}
            src={
              microCycleDay.workout?.image?.medium ??
              'https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
            }
          />
        }
      >
        <Card.Meta
          title={microCycleDay.workout?.defaultName}
          description={
            <div>
              <div>
                <Badge
                  count={`${dayjs.utc(microCycleDay.workout?.minDuration).format('HH:mm:ss')}`}
                  style={{ backgroundColor: '#fff', color: '#999', fontSize: 14, boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                />
              </div>
              <div>
                {microCycleDay.params.tags.map((tag) => (
                  <Badge
                    key={tag}
                    count={tag}
                    style={{
                      margin: 2,
                      backgroundColor: '#1890ff',
                      border: '1px solid #1890ff',
                      color: '#fff',
                      boxShadow: '0 0 0 1px #1890ff inset',
                    }}
                  />
                ))}
              </div>
            </div>
          }
        />
        {microCycleDay.schedule && (
          <SingleColRow>
            Scheduled: {microCycleDay.schedule.startDateTime?.format('DD.MM.YYYY HH:mm') ?? '-'}
          </SingleColRow>
        )}
        <SingleColRow style={{ paddingTop: 16 }}>
          <Form layout="vertical">
            {!microCycleDay.completed && (
              <SingleColRow>
                <Form.Item label="Duration">
                  <Select value={durationTag} onChange={handleChangeDuration}>
                    <Select.Option value="duration:short">short</Select.Option>
                    <Select.Option value="duration:medium">medium</Select.Option>
                    <Select.Option value="duration:long">long</Select.Option>
                  </Select>
                </Form.Item>
              </SingleColRow>
            )}
            {athleteSuperMacro.athlete && !microCycleDay.completed && (
              <SingleColRow>
                <Form.Item label="Equipment">
                  <EquipmentConfigurationInput params={microCycleDay.params} athlete={athleteSuperMacro.athlete} />
                </Form.Item>
              </SingleColRow>
            )}
          </Form>
        </SingleColRow>
        <Row>
          {!microCycleDay.completed && (
            <Col>
              <Button onClick={handleRefresh} loading={refreshing} disabled={refreshing}>
                Refresh
              </Button>
            </Col>
          )}
          {!microCycleDay.completed && (
            <Col>
              <Button type="primary" onClick={handleChangeParams} loading={changingParams} disabled={changingParams}>
                Change Params
              </Button>
            </Col>
          )}
          <Col>
            <Button type="dashed" onClick={handleClick}>
              Show
            </Button>
          </Col>
          {!microCycleDay.completed && (
            <Col>
              <Popconfirm
                title="I hope you know what you are doing... The user won't be able to do this workout afterwards"
                onConfirm={handleSkip}
              >
                <Button type="link" loading={changingParams} disabled={changingParams}>
                  Skip
                </Button>
              </Popconfirm>
            </Col>
          )}
          {microCycleDay.workoutLog && (
            <Col>
              <Button type="link" onClick={handleShowWorkoutLog}>
                Show WorkoutLog
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    );
  },
);
