/**
 * Created by neo on 19.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Select, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { MindfulnessProgramTemplate } from '../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { Media } from '../../../../../Model/Media/Media';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { ViewMedia } from '../../../../../Components/ViewMedia/ViewMedia';
import { ConditionalMedia } from '../../../../../Model/Coach/SuperMacro/ConditionalMedia';
import { ProgramTemplateResult } from '../../../../../Model/Coach/ProgramTemplateResult';
import { MindfulnessProgramTagsInput } from './MindfulnessProgramTagsInput';
import { Quote } from '../../../../../Model/Coach/Quote';
import { VersionEditor } from './UnitEntryModal/VersionEditor';
import { GymSelector } from '../../../../../Components/GymSelector';
import { Gym } from '../../../../../Model/Gym/Gym';
import dayjs, { Dayjs } from 'dayjs';
import { TimeRange } from '../../../../../Model/Gym/TimeRange';
import { ScheduleTimeRange } from '../../../../../Model/Coach/ScheduleTimeRange';

export type MindfulnessProgramMainAttributesEditorProps = {
  template: MindfulnessProgramTemplate;
};

export const MindfulnessProgramMainAttributesEditor: React.FC<MindfulnessProgramMainAttributesEditorProps> = observer(
  ({ template }) => {
    const handleTemplateNameChange = React.useCallback(
      ({ target: { value } }) => {
        template.templateName = value;
      },
      [template],
    );

    // const handleSetParams = React.useCallback(
    //   () => runInAction(() => (template.params = new ProgramTemplateProgramParams())),
    //   [template],
    // );
    //
    // const handleRemoveParams = React.useCallback(() => runInAction(() => (template.params = undefined)), [template]);

    // const handleImageUploaded = React.useCallback((media: Media) => runInAction(() => (template.images = media)), [
    //   template,
    // ]);
    //
    // const handleImageRemove = React.useCallback(() => runInAction(() => (template.images = undefined)), [template]);

    const handleSaveMedia = React.useCallback((media: Media) => new ConditionalMedia(media.toJS()), []);

    const handleAddResult = React.useCallback(
      () => runInAction(() => template.expectedResults.push(new ProgramTemplateResult())),
      [template],
    );

    const handleAddQuote = React.useCallback(() => runInAction(() => template.quotes.push(new Quote())), [template]);

    const handleChangeType = React.useCallback(
      (type) => {
        runInAction(() => (template.type = type));
      },
      [template],
    );

    const handleChangeGym = React.useCallback(
      (gym?: Gym) => {
        runInAction(() => (template.gymId = gym?.id));
      },
      [template],
    );

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Form.Item label="Template Name">
              <Input value={template.templateName} onChange={handleTemplateNameChange} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Program Type">
              <Select value={template.type} onChange={handleChangeType}>
                <Select.Option value="antiStress">Anti Stress</Select.Option>
                <Select.Option value="selfAwareness">Self Awareness</Select.Option>
                <Select.Option value="confidence">Confidence</Select.Option>
                <Select.Option value="sleepImprovement">Sleep Improvement</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Min. Duration (per unit)">
              <Input value={template.minDurationInMinutes} onChange={handleTemplateNameChange} disabled={true} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Max. Duration (per unit)">
              <Input value={template.maxDurationInMinutes} onChange={handleTemplateNameChange} disabled={true} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Tags" extra="Used for search / filtering">
              <MindfulnessProgramTagsInput template={template} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {/*<Col xs={6}>*/}
          {/*  <Form.Item label="Excluded Tags" extra="Used for search / filtering">*/}
          {/*    <CoachProgramExcludedTagsInput template={template} />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          {/*<Col xs={12} style={{ paddingBottom: 16 }}>*/}
          {/*  {template.params ? (*/}
          {/*    <React.Fragment>*/}
          {/*      <ProgramParamsEditor params={template.params} />*/}
          {/*      <Button block danger onClick={handleRemoveParams}>*/}
          {/*        Remove Params*/}
          {/*      </Button>*/}
          {/*    </React.Fragment>*/}
          {/*  ) : (*/}
          {/*    <Button block type="primary" onClick={handleSetParams}>*/}
          {/*      Set Params*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*</Col>*/}
          <Col xs={6}>
            <Form.Item label="Optional Gym">
              <GymSelector gymId={template.gymId} onChange={handleChangeGym} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Optional Default Times">
              {template.timesOfDay.map((timeOfDay) => (
                <Row key={timeOfDay}>
                  <Col>{timeOfDay === 'any' ? 'default' : timeOfDay}</Col>
                  <Col>
                    <TimePicker
                      onChange={(date, dateString) => {
                        if (dateString) {
                          runInAction(
                            () =>
                              (template.defaultTimesOfDay[timeOfDay] = new ScheduleTimeRange({
                                timeOfDay,
                                from: date.format('HH:mm:ss'),
                              })),
                          );
                        } else {
                          runInAction(() => delete template.defaultTimesOfDay[timeOfDay]);
                        }
                      }}
                      format="HH:mm"
                      minuteStep={15}
                      allowClear={true}
                      value={
                        template.defaultTimesOfDay[timeOfDay]?.fromFixed
                          ? dayjs(template.defaultTimesOfDay[timeOfDay]?.from, 'HH:mm:ss')
                          : undefined
                      }
                    />
                  </Col>
                </Row>
              ))}
            </Form.Item>
          </Col>
          <Col xs={6}>
            <TranslationInputArray type="textarea" entity={template} field="name" label="Name" />
          </Col>
          <Col xs={6}>
            <TranslationInputArray type="textarea" entity={template} field="description" label="Description" />
          </Col>
          <Col xs={6}>
            <Form.Item label="Expected Results">
              {template.expectedResults.map((result, index) => (
                <Row key={index.toString()}>
                  <Col xs="auto">{`${index + 1}.`}</Col>
                  <Col>
                    <TranslationInputArray key={index.toString()} entity={result} field="name" />
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="small"
                      danger
                      onClick={() => runInAction(() => template.expectedResults.splice(index, 1))}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button onClick={handleAddResult} block={true}>
                Add Expected Result
              </Button>
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Quotes">
              {template.quotes.map((quote, index) => (
                <Row key={index.toString()}>
                  <Col xs="auto">{`${index + 1}.`}</Col>
                  <Col>
                    <TranslationInputArray type="textarea" key={index.toString()} entity={quote} field="values" />
                  </Col>
                  <Col xs="auto">
                    <Button size="small" danger onClick={() => runInAction(() => template.quotes.splice(index, 1))}>
                      Remove
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button onClick={handleAddQuote} block={true}>
                Add Quote
              </Button>
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Images">
              <ViewMedia medias={template.images} disableCamera={true} onTransform={handleSaveMedia} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Supported device versions" extra="If supported on all versions do not change">
              <VersionEditor version={template.supportedVersion} />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  },
);
