/**
 *
 * Created by neo on 13.03.17.
 */
import * as React from 'react';
import { isObservableArray, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { BodyPartRegion } from '../../../../Model/BodyPart/BodyPartRegion';
import { ActionMeta } from 'react-select';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';

export type BodyPartCardProps = {
  bodyParts: BodyPartRegionAdmin[];
  allBodyParts?: BodyPartRegionAdmin[];
  header?: string;
  disabled?: boolean;
};

export const BodyPartCard: React.FC<BodyPartCardProps> = observer(
  ({ bodyParts, allBodyParts, header, disabled }: BodyPartCardProps) => {
    const fetch = React.useCallback(
      (query?: string) =>
        BodyPartRegionAdmin.find({
          query,
          page: 0,
          sort: 'defaultName,ASC',
          includeHidden: true,
        }).then((result) => result.filter((b) => !bodyParts.find((b1) => b1.id === b.id))),
      [bodyParts],
    );

    const handleChange = React.useCallback(
      (newBodyParts: BodyPartRegionAdmin[], action?: ActionMeta<any>) => {
        const allBodyParts = newBodyParts ?? [];
        if (isObservableArray(bodyParts)) {
          bodyParts.replace(allBodyParts || []);
        } else {
          bodyParts.splice(0, bodyParts.length);
          (allBodyParts || []).forEach((b) => bodyParts.push(b));
        }
      },
      [bodyParts],
    );

    const getOptionLabel = React.useCallback(
      (option: BodyPartRegionAdmin) =>
        `${option.defaultName}${option.latinName && option.latinName !== option.defaultName ? ` / ${option.latinName}` : ''} (${
          option.identifier
        })`,
      [],
    );

    const getOptionValue = React.useCallback((option: BodyPartRegion) => option, []);

    return (
      <FormGroup>
        {header ? <Label>{header ? header : 'Body Parts'}</Label> : null}
        <AsyncSelect
          value={bodyParts.slice()}
          cacheOptions
          defaultOptions
          isClearable={true}
          loadOptions={fetch}
          isMulti={true}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue as any}
          onChange={handleChange as any}
          isDisabled={disabled}
        />
        {allBodyParts ? (
          <small>
            {allBodyParts
              .map((b) => `${b.defaultName}${b.latinName && b.latinName !== b.defaultName ? ` (${b.latinName})` : ''}`)
              .join(', ')}
          </small>
        ) : null}
      </FormGroup>
    );
  },
);
