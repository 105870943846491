import { computed, observable, ObservableMap, onBecomeObserved, runInAction, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ActivityLogSource } from './ActivityLogSource';
import { WorkoutLog } from '../WorkoutLog';
import dayjs from '../../Utils/dayjs';
import { Dayjs } from 'dayjs';
import { Athlete } from '../Athlete/Athlete';

export class ActivityLog {
  @observable id: string = UUID();
  @observable athleteId: string = '';
  /**
   * Refers to the `identifier` of an Activity from our system
   */
  @observable type?: string = undefined;
  @observable activityId?: string = undefined;
  @observable source = new ActivityLogSource();
  @observable startDate?: Date = undefined;
  @observable endDate?: Date = undefined;
  @observable data: ObservableMap<string, number> = observable.map({});
  @observable
  linkedData: Record<string, any> = {};
  @observable
  workoutLog?: WorkoutLog = undefined;
  @observable
  athlete?: Athlete;

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.athleteId = json.athleteId ?? '';
      this.type = json.type;
      this.activityId = json.activityId;
      this.startDate = json.startDate ? new Date(json.startDate) : undefined;
      this.endDate = json.endDate ? new Date(json.endDate) : undefined;
      this.data = observable.map(json.data || {});
      this.source = new ActivityLogSource(json.source);
      this.linkedData = json.linkedData ?? {};
    }

    onBecomeObserved(this, 'workoutLog', this.fetchWorkoutLog);
    onBecomeObserved(this, 'athlete', this.fetchAthlete);
  }

  fetchWorkoutLog = () => {
    if (this.source.sourceType === 'workout' && this.source.sourceId) {
      WorkoutLog.get(this.source.sourceId).then((result) => {
        runInAction(() => (this.workoutLog = result));
      });
    }
  };

  fetchAthlete = () => {
    if (!this.athlete) {
      Athlete.get(this.athleteId).then((res) => runInAction(() => (this.athlete = res)));
    }
  };

  toJS() {
    return {
      id: this.id,
      userId: this.athleteId,
      type: this.type,
      activityId: this.activityId,
      source: this.source.toJS(),
      startDate: this.startDate?.toISOString(),
      endDate: this.endDate?.toISOString(),
      data: toJS(this.data),
    };
  }

  @computed
  get totalCaloriesBurnt(): number {
    return this.data.get('CALORIES') || 0;
  }

  @computed
  get durationMs(): number {
    return dayjs(this.endDate).diff(dayjs(this.startDate));
  }

  @computed
  get valid(): boolean {
    const start = dayjs(this.startDate);
    const end = dayjs(this.endDate);

    if (!end.isBefore(start)) {
      const diff = end.diff(start, 'minute');
      return diff <= 480;
    }

    return false;
  }

  @computed
  get startDayjs(): Dayjs {
    return dayjs(this.startDate);
  }

  @computed
  get endDayjs(): Dayjs {
    return dayjs(this.endDate);
  }

  @computed
  get durationInSeconds(): number {
    return this.data.get('DURATION') || this.durationMs / 1000;
  }

  @computed
  get isWorkoutLog(): boolean {
    return this.source.sourceType === 'workout' && !!this.source.sourceId;
  }

  save() {
    return HttpBackend.post('/activity/tracking', this.toJS());
  }

  static find(params?: any): Promise<Array<ActivityLog>> {
    return HttpBackend.get('/activity/tracking/admin', params).then((result) => result.map((e) => new ActivityLog(e)));
  }

  static count(params?: any): Promise<number> {
    return HttpBackend.get('/activity/tracking/admin/count', params);
  }

  static async get(id: string): Promise<ActivityLog | undefined> {
    const res = await HttpBackend.get(`/activity/tracking/${id}`);
    return res ? new ActivityLog(res) : undefined;
  }
}
