/**
 * Created by neo on 09.08.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { EmployeeMailSender } from './EmployeeMailSender';
import { Col, Row } from 'reactstrap';
import { Alert, Button, Form, Input, message } from 'antd';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { GymNotification } from '../../../../../Model/Messaging/Gym/GymNotification';

export type GymMessagingTabProps = {
  gym: Gym;
};

export const GymMessagingTab: React.FC<GymMessagingTabProps> = observer(({ gym }) => {
  const [completed, setCompleted] = useState(false);
  const [mailSender, setMailSender] = React.useState(new EmployeeMailSender(gym));
  const [notification, setNotification] = useState(new GymNotification({ gymId: gym.id }));

  const notificationButtonDisabled = mailSender.processing || notification.invalid;
  const mailButtonDisabled = mailSender.processing || mailSender.invalid;

  useEffect(() => {
    setNotification(new GymNotification({ gymId: gym.id }));
  }, [gym]);

  useEffect(() => {
    setMailSender(new EmployeeMailSender(gym));
  }, [gym]);

  const handleSendMail = React.useCallback(async () => {
    mailSender
      .send()
      .then(() => setCompleted(true))
      .catch(() => message.error('Error sending mails'));
  }, [mailSender]);

  const handleSendNotification = React.useCallback(async () => {
    notification
      .save()
      .then(() => setCompleted(true))
      .then(() => message.success('Notification sent'))
      .catch(() => message.error('Error sending mails'));
  }, [notification]);

  return (
    <Form layout="vertical">
      <Row>
        <Col>
          <h4>Send Notification</h4>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleSendNotification} disabled={notificationButtonDisabled}>
            Send Notification
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Title">
            <TranslationInputArray entity={notification} field="title" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Message">
            <TranslationInputArray type="textarea" entity={notification} field="message" maxLength={140} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Send Mail</h4>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleSendMail} disabled={mailButtonDisabled}>
            Send Mails
          </Button>
        </Col>
      </Row>
      {completed && (
        <SingleColRow>
          <Alert type="success" message={`Mails succesfully sent to ${mailSender.totalRecipients}`} />
        </SingleColRow>
      )}
      <Row>
        <Col md={6}>
          <Form.Item label="From (optional)">
            <Input
              disabled={mailSender.processing}
              value={mailSender.fromMail}
              onChange={(e) => runInAction(() => (mailSender.fromMail = e.target.value))}
            />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="From Name (optional)">
            <Input
              disabled={mailSender.processing}
              value={mailSender.fromName}
              onChange={(e) => runInAction(() => (mailSender.fromName = e.target.value))}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Subject">
            <Input
              disabled={mailSender.processing}
              value={mailSender.subject}
              onChange={(e) => runInAction(() => (mailSender.subject = e.target.value))}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Content">
            <Input.TextArea
              rows={24}
              disabled={mailSender.processing}
              value={mailSender.content}
              onChange={(e) => runInAction(() => (mailSender.content = e.target.value))}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
