/**
 * Created by neo on 14.12.20.
 */
import { SubscriptionTransactionDetails, SubscriptionTransactionDetailsJson } from './SubscriptionTransactionDetails';
import { observable, onBecomeObserved, runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { SubscriptionPlan } from './SubscriptionPlan';
import { Gym } from '../Gym/Gym';

export type AthleteCoachSubscriptionJson = {
  id: string;
  athleteId: string;
  gymId?: string;
  parentId?: string;
  plan: SubscriptionPlan;
  validFrom: string;
  validUntil?: string;
  cancelDate?: string;
  transactionDetails?: SubscriptionTransactionDetailsJson;
  trial: boolean;
};

export class AthleteCoachSubscription {
  @observable
  id = UUID();
  @observable
  athleteId: string = '';
  @observable
  gymId?: string;
  @observable
  parentId?: string;
  @observable
  plan: SubscriptionPlan = 'free';
  @observable
  validFrom = new Date();
  @observable
  validUntil?: Date;
  @observable
  cancelDate?: string;
  @observable
  transactionDetails?: SubscriptionTransactionDetails;
  @observable
  trial = false;
  @observable
  gym?: Gym;

  constructor(json?: Partial<AthleteCoachSubscriptionJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.athleteId = json.athleteId ?? '';
      this.gymId = json.gymId;
      this.parentId = json.parentId;
      this.plan = json.plan ?? 'free';
      this.validFrom = json.validFrom ? new Date(json.validFrom) : new Date();
      this.validUntil = json.validUntil ? new Date(json.validUntil) : undefined;
      this.transactionDetails = json.transactionDetails
        ? new SubscriptionTransactionDetails(json.transactionDetails)
        : undefined;
      this.trial = json.trial ?? false;
    }

    onBecomeObserved(this, 'gym', this.fetchGym);
  }

  fetchGym = () => {
    if (!this.gym && this.gymId) {
      Gym.get(this.gymId).then((res) => runInAction(() => (this.gym = res)));
    }
  };

  toJS(): AthleteCoachSubscriptionJson {
    return {
      id: this.id,
      athleteId: this.athleteId,
      gymId: this.gymId,
      parentId: this.parentId,
      plan: this.plan,
      validFrom: this.validFrom.toISOString(),
      validUntil: this.validUntil?.toISOString(),
      transactionDetails: this.transactionDetails?.toJS(),
      trial: this.trial,
    };
  }

  save(): Promise<AthleteCoachSubscription> {
    return HttpBackend.post(`/coach/subscription`, this.toJS()).then(() => this);
  }

  delete() {
    return HttpBackend.delete(`/coach/subscription/${this.id}`);
  }

  static current(): Promise<AthleteCoachSubscription> {
    return HttpBackend.get(`/coach/subscription/current`).then((res) => new AthleteCoachSubscription(res));
  }

  static get(subscriptionId: string): Promise<AthleteCoachSubscription> {
    return HttpBackend.get(`/coach/subscription/${subscriptionId}`).then((res) => new AthleteCoachSubscription(res));
  }

  static find(params?: any): Promise<AthleteCoachSubscription[]> {
    return HttpBackend.get(`/coach/subscription`, params).then((res) =>
      (res ?? []).map((r) => new AthleteCoachSubscription(r)),
    );
  }
}
