/**
 *
 * Created by andreaskarantzas on 09.08.18.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

const styles = {
  label: {
    marginRight: 16,
    fontWeight: 800,
  },
};

export type Props = {
  onClose?: () => any;
  export: string[];
};

@observer
export class AxonEventKotlinExportModal extends React.Component<Props> {
  render() {
    return (
      <Modal isOpen={true} size="lg" backdrop={true} toggle={this.props.onClose}>
        <ModalHeader>Select the opening hours</ModalHeader>
        <ModalBody style={{ height: 1024 }}>
          <Input type="textarea" value={this.props.export.join(',\n')} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
