/**
 * Created by neo on 07.06.2024
 */
import { Audited, AuditedJson } from '../Audited';
import { action, observable, onBecomeObserved, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Pageable } from '../Pageable';
import { Athlete } from '../Athlete/Athlete';
import { Gym } from '../Gym/Gym';

export type ExploreFaqRequestQueryRequest = Pageable & {
  contentId?: string;
  query?: string;
  athleteId?: string;
  gymId?: string;
  answered?: boolean;
};

export type ExploreFaqRequestJson = AuditedJson & {
  contentId: string;
  question: string;
  athleteId?: string;
  gymId?: string;
  answerId?: string;
  answeredAt?: string;
};

export class ExploreFaqRequest extends Audited {
  @observable
  contentId: string = '';
  @observable
  question: string = '';
  @observable
  athleteId?: string;
  @observable
  gymId?: string;
  @observable
  answerId?: string;
  @observable
  answeredAt?: Date;

  @observable
  athlete?: Athlete;
  @observable
  gym?: Gym;

  constructor(json?: Partial<ExploreFaqRequestJson>) {
    super(json);

    if (json) {
      this.contentId = json.contentId ?? '';
      this.question = json.question ?? '';
      this.athleteId = json.athleteId;
      this.gymId = json.gymId;
      this.answerId = json.answerId;
      this.answeredAt = json.answeredAt ? new Date(json.answeredAt) : undefined;
    }

    onBecomeObserved(this, 'athlete', () => {
      if (!this.athlete && this.athleteId) {
        Athlete.get(this.athleteId).then((a) => runInAction(() => (this.athlete = a)));
      }
    });

    onBecomeObserved(this, 'gym', () => {
      if (!this.gym && this.gymId) {
        Gym.get(this.gymId).then((g) => runInAction(() => (this.gym = g)));
      }
    });
  }

  toJS(): ExploreFaqRequestJson {
    return Object.assign(super.toJS(), {
      contentId: this.contentId,
      question: this.question ?? '',
      athleteId: this.athleteId,
      gymId: this.gymId,
      answerId: this.answerId,
      answeredAt: this.answeredAt?.toISOString(),
    });
  }

  @action
  answered(answerId: string): Promise<ExploreFaqRequest> {
    if (!this.answerId || !this.answeredAt) {
      this.answerId = answerId;
      this.answeredAt = new Date();
    }

    return this.save();
  }

  save(): Promise<ExploreFaqRequest> {
    return HttpBackend.post(`/coach/explore/faq/requests/admin`, this.toJS()).then(() => this);
  }

  delete(): Promise<ExploreFaqRequest> {
    return HttpBackend.delete(`/coach/explore/faq/requests/admin/${this.id}`).then(() => this);
  }

  static find(request?: Partial<ExploreFaqRequestQueryRequest>): Promise<ExploreFaqRequest[]> {
    return HttpBackend.get(`/coach/explore/faq/requests/admin`, request).then((res) =>
      (res ?? []).map((r) => new ExploreFaqRequest(r)),
    );
  }

  static count(request?: Partial<ExploreFaqRequestQueryRequest>): Promise<number> {
    return HttpBackend.get(`/coach/explore/faq/requests/admin/count`, request).then((res) => res ?? 0);
  }
}
