/**
 * Created by katarinababic on 26.5.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { PieChart, Pie, Cell } from 'recharts';
import { ChartColors } from '../../Analytics/ChartColors';
import { useState } from 'react';
import { renderActiveShape } from '../../../Utils/pieChartActiveShape';

export type ExploreIndexScreenChartProps = {
  data: AnalyticsRow[];
};

export const ExploreIndexScreenChart: React.FC<ExploreIndexScreenChartProps> = observer(({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePieEnter = React.useCallback((_, index) => {
    setActiveIndex(index);
  }, []);

  return (
    <PieChart width={600} height={400}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={80}
        outerRadius={140}
        fill={ChartColors[activeIndex]}
        dataKey="total_select_count"
        onMouseEnter={handlePieEnter}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={ChartColors[index % ChartColors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
});
