/**
 * Created by neo on 01.09.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Badge, Button, Form, Modal, Tag } from 'antd';
import { ProgramParamsEditor } from './ProgramParamsEditor';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { ProgramParams } from '../../../../Model/Coach/Program/ProgramParams';
import { WorkoutDayEntry } from '../../../../Model/Coach/Program/Template/WorkoutDayEntry';
import { useState } from 'react';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { CoachWorkout } from '../../../../Model/Coach/Program/Workout/CoachWorkout';
import { PipelineContext } from '../../../../Model/Coach/PipelineContext';
import { CoachWorkoutView } from '../../AthleteSuperMacro/View/CoachWorkoutView/CoachWorkoutView';
import dayjs, { Dayjs } from 'dayjs';
import { ProgramTemplateProgramParams } from '../../../../Model/Coach/Program/Template/ProgramTemplateProgramParams';

export type WorkoutEntryModalProps = {
  template: FitnessProgramTemplate;
  workoutEntry: WorkoutDayEntry;
  onClose?: () => void;
};

export const WorkoutEntryModal: React.FC<WorkoutEntryModalProps> = observer(({ template, workoutEntry, onClose }) => {
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState<CoachWorkout | undefined>();

  const handleSetParams = React.useCallback(
    () => runInAction(() => (workoutEntry.params = new ProgramTemplateProgramParams())),
    [workoutEntry],
  );

  const handleRemoveParams = React.useCallback(() => runInAction(() => (workoutEntry.params = undefined)), [
    workoutEntry,
  ]);

  const handleGenerate = React.useCallback(() => {
    if (workoutEntry.workoutTemplateId) {
      setProcessing(true);
      setResult(undefined);
      const params = (template.params ?? new ProgramParams()).merge(workoutEntry.params ?? new ProgramParams());
      CoachWorkout.generate(
        workoutEntry.workoutTemplateId,
        new PipelineContext({
          tags: params.tags,
          equipmentTypes: params.equipmentConfiguration.equipmentTypes,
        }),
      )
        .then((result) => setResult(result))
        .finally(() => setProcessing(false));
    }
  }, [template, workoutEntry]);

  return (
    <Modal
      open={true}
      width={1024}
      title={workoutEntry.workoutTemplate?.templateName}
      footer={[
        <Button key="1" onClick={handleGenerate} disabled={processing}>
          Generate
        </Button>,
        <Button key="3" type="primary" onClick={onClose} disabled={processing}>
          Save & Close
        </Button>,
      ]}
      onCancel={onClose}
    >
      <Form layout="vertical">
        <Row>
          <Col xs={12} style={{ paddingBottom: 16 }}>
            {workoutEntry.params ? (
              <React.Fragment>
                <ProgramParamsEditor params={workoutEntry.params} />
                <Button block danger onClick={handleRemoveParams}>
                  Remove Params
                </Button>
              </React.Fragment>
            ) : (
              <Button block type="primary" onClick={handleSetParams}>
                Set Params
              </Button>
            )}
          </Col>
          <Col xs={12}>
            {result && (
              <Row>
                <Col xs={12}>
                  <h6>
                    Duration: {dayjs.utc(result.minDuration).format('HH:mm:ss')} -{' '}
                    {dayjs.utc(result.maxDuration).format('HH:mm:ss')}
                  </h6>
                  {result.tags.map((tag) => (
                    <Tag key={tag} color="processing">
                      {tag}
                    </Tag>
                  ))}
                </Col>
                <Col xs={12}>
                  <CoachWorkoutView workout={result} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
