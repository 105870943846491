import { PipelineContext } from '../../PipelineContext';
import objectPath from 'object-path';
import { LogicalStage } from './LogicalStage';
import { toJS } from 'mobx';
import { Signal } from '../../Signal/Signal';
import { DataSignal } from '../../Signal/DataSignal';
import { StartSignal } from '../../Signal/StartSignal';

export class InStage extends LogicalStage {
  canProceed(signal: Signal): boolean {
    if (signal instanceof DataSignal || signal instanceof StartSignal) {
      const object =
        this.config.source === 'input' && signal instanceof DataSignal ? signal.data : this.pipeline.context;
      const value = objectPath.get(object, this.config.attribute);
      return this.compareValues(value, this.getValue());
    }
    return false;
  }

  toJS(): any {
    return super.toJS();
  }

  get type(): string {
    return 'logical:in';
  }

  protected compareValues(value0: any, value1: any): boolean {
    const array0 = Array.isArray(value0) ? value0 : undefined;
    const array1 = Array.isArray(value1) ? value1 : undefined;

    console.log('compareValues', toJS(array0), toJS(array1), toJS(value0), toJS(value1));

    if (array0) {
      if (array1) {
        if (array0.length === 0 && array1.length === 0) {
          return true;
        }
        return array0.findIndex((a) => !!array1.find((b) => a === b)) !== -1;
      }
      return array0.findIndex((e) => e === value1) !== -1;
    } else if (array1) {
      return array1.findIndex((e) => e === value0) !== -1;
    }
    return value0 !== value1;
  }
}
