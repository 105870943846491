import objectPath from 'object-path';
import { LogicalStage } from './LogicalStage';
import { Signal } from '../../Signal/Signal';
import { DataSignal } from '../../Signal/DataSignal';
import { StartSignal } from '../../Signal/StartSignal';

export class LessThanStage extends LogicalStage {
  canProceed(signal: Signal): boolean {
    if (signal instanceof DataSignal || signal instanceof StartSignal) {
      const object =
        this.config.source === 'input' && signal instanceof DataSignal ? signal.data : this.pipeline.context;
      const value = objectPath.get(object, this.config.attribute);
      return this.compareValues(value, this.getValue());
    }
    return false;
  }

  toJS(): any {
    return super.toJS();
  }

  get type(): string {
    return 'logical:lt';
  }

  protected compareValues(value0: any, value1: any): boolean {
    return value0 < value1;
  }
}
