/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Form, Select } from 'antd';
import { ExercisePosition, ExercisePositionPrimary } from '../../../../../Model/Exercise/ExercisePosition';

export type ExercisePositionPrimarySelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExercisePositionPrimarySelect: React.FC<ExercisePositionPrimarySelectProps> = observer(
  ({ exercise, disabled }: ExercisePositionPrimarySelectProps) => {
    const handleChange = React.useCallback(
      (value) => {
        if (value) {
          exercise.initialPosition = exercise.initialPosition ?? new ExercisePosition();
          exercise.initialPosition.primary = value as ExercisePositionPrimary;
          if (exercise.initialPosition.secondary) {
            if (value === 'lying' && exercise.initialPosition.secondary.startsWith('inclined')) {
              exercise.initialPosition.secondary = 'straight';
            } else if (value === 'hanging') {
              exercise.initialPosition.secondary = undefined;
            }
          } else if (value !== 'hanging') {
            exercise.initialPosition.secondary = 'straight';
          }
        } else {
          exercise.initialPosition = undefined;
        }
      },
      [exercise],
    );

    return (
      <Form.Item label="Exercise Position (primary)">
        <Select value={exercise.initialPosition?.primary} onChange={handleChange} disabled={disabled}>
          <Select.Option value="">{''}</Select.Option>
          <Select.Option value="standing">{'Standing / Stehend'}</Select.Option>
          <Select.Option value="sitting">{'Sitting / Sitzend'}</Select.Option>
          <Select.Option value="lying">{'Lying / Liegend'}</Select.Option>
          <Select.Option value="hanging">{'Hanging / Hängend'}</Select.Option>
          <Select.Option value="kneeling">{'Kneeling / Kniend'}</Select.Option>
          <Select.Option value="cross_legged">{'Cross Legged / Schneidersitz'}</Select.Option>
        </Select>
      </Form.Item>
    );
  },
);
