/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { Athlete } from '../../Model/Athlete/Athlete';
import { PersonCard } from './View/AthleteBasicTab/PersonCard';
import { BodyValueCard } from './View/AthleteBasicTab/BodyValueCard';
import { AthleteSignup } from '../../Model/Athlete/AthleteSignup';
import { SingleColRow } from '../../Components/SingleColRow';
import { Card, Form, Input, Modal } from 'antd';

export type CreateAthleteModalProps = {
  onClose?: (athlete?: Athlete) => any;
};

export const CreateAthleteModal: React.FC<CreateAthleteModalProps> = observer((props: CreateAthleteModalProps) => {
  const { onClose } = props;

  const store = useLocalStore(
    (source) => ({
      creating: false,
      athlete: new AthleteSignup({
        bodyValues: {
          weight: 60,
          height: 170,
        },
      }),
      async signup() {
        store.creating = true;
        try {
          const athlete = await store.athlete.signup();
          source.onClose && source.onClose(athlete);
        } finally {
          store.creating = false;
        }
      },
    }),
    props,
  );

  const handleClose = React.useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      store.signup();
    },
    [store],
  );

  const handleEmailChange = React.useCallback((e) => (store.athlete.email = e.target.value), [store]);

  const handlePasswordChange = React.useCallback((e) => (store.athlete.password = e.target.value), [store]);

  return (
    <Modal open={true} onCancel={handleClose} onOk={handleSubmit} title="Create Athlete" width={1024}>
      <Form onFinish={handleSubmit} layout="vertical">
        <Card>
          <Row>
            <Col md={6}>
              <Form.Item label="E-Mail">
                <Input type="email" value={store.athlete.email} onChange={handleEmailChange} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Password">
                <Input type="text" value={store.athlete.password} onChange={handlePasswordChange} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <SingleColRow>
          <PersonCard athlete={store.athlete} />
        </SingleColRow>
        <SingleColRow>
          <BodyValueCard athlete={store.athlete} />
        </SingleColRow>
      </Form>
    </Modal>
  );
});
