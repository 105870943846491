/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Workout } from '../../../../Model/ProgramPortfolio/Workout';
import ReactJson from 'react-json-view';
import { toJS } from 'mobx';
import { PhasesTable } from '../../../Planning/Workout/View/PhasesTable/PhasesTable';
import { Alert } from 'antd';
import { ExerciseVariationTableRow } from '../../../../Components/ExerciseVariationTable/ExerciseVariationTableRow';
import { Button, Col, Table, Row } from 'reactstrap';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { AbstractWorkout } from '../../../../Model/ProgramPortfolio/AbstractWorkout';
import { CoachWorkoutPhase } from '../../../../Model/Coach/Program/Workout/CoachWorkoutPhase';
import { CoachWorkoutPhaseTable } from '../../AthleteSuperMacro/View/CoachWorkoutView/CoachWorkoutPhaseTable';
import { CoachWorkout } from '../../../../Model/Coach/Program/Workout/CoachWorkout';
import { CoachExerciseBlock } from '../../../../Model/Coach/Program/Workout/CoachExerciseBlock';
import { CoachExerciseBlockCard } from '../../AthleteSuperMacro/View/CoachWorkoutView/CoachExerciseBlockCard';
import { Exercise } from '../../../../Model/Exercise/Exercise';
import { WorkoutResponsePhase } from '../../../../Model/Coach/Script/WorkoutResponsePhase';
import { WorkoutResponse } from '../../../../Model/Coach/Script/WorkoutResponse';
import dayjs, { Dayjs } from 'dayjs';

export type ResultViewerProps = {
  result?: any;
  debug?: boolean;
};

export const ResultViewer: React.FC<ResultViewerProps> = observer(({ result, debug }: ResultViewerProps) => {
  if (result) {
    if (!debug) {
      if (result instanceof AbstractWorkout) {
        return (
          <React.Fragment>
            <Row>
              <Col>
                <h6>
                  Duration: {dayjs.utc(result.minDuration).format('HH:mm:ss')} -{' '}
                  {dayjs.utc(result.maxDuration).format('HH:mm:ss')}
                </h6>
              </Col>
            </Row>
            <SingleColRow>
              <PhasesTable workout={result} />
            </SingleColRow>
          </React.Fragment>
        );
      } else if (result[0] instanceof AbstractWorkout) {
        return (
          <React.Fragment>
            {result.map((result) => (
              <React.Fragment key={result.id}>
                <Row>
                  <Col>
                    <h6>
                      Duration: {dayjs.utc(result.minDuration).format('HH:mm:ss')} -{' '}
                      {dayjs.utc(result.maxDuration).format('HH:mm:ss')}
                    </h6>
                  </Col>
                </Row>
                <SingleColRow>
                  <PhasesTable workout={result} />
                </SingleColRow>
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      } else if (Array.isArray(result) && result.length > 0) {
        if (result[0]?.phaseId) {
          return (
            <React.Fragment>
              {result
                .map((p) => new WorkoutResponsePhase(new WorkoutResponse(), p))
                .map((p) => (
                  <CoachWorkoutPhaseTable key={p.phaseId} phase={p} />
                ))}
            </React.Fragment>
          );
        } else if (result[0] instanceof Workout) {
          return (
            <React.Fragment>
              {result.map((workout) => (
                <PhasesTable key={workout.id} workout={workout} />
              ))}
            </React.Fragment>
          );
        } else if (result[0]?.bodyParts) {
          return (
            <Table size="sm">
              <tbody>
                {result
                  .map((e) => new Exercise(e))
                  .map((exercise) => (
                    <ExerciseVariationTableRow key={exercise.id} variation={exercise} />
                  ))}
              </tbody>
            </Table>
          );
        } else if (result[0]?.exerciseBlockId) {
          return (
            <Table size="sm">
              <tbody>
                {result
                  .map((b) => new CoachExerciseBlock(new CoachWorkoutPhase(new CoachWorkout()), b))
                  .map((block) => (
                    <CoachExerciseBlockCard key={block.exerciseBlockId} exerciseBlock={block} />
                  ))}
              </tbody>
            </Table>
          );
        }
      }
    }
    return <ReactJson src={toJS(result)} displayDataTypes={false} collapsed={2} name={false} sortKeys />;
  }
  return <Alert message="No result" type="warning" />;
});
