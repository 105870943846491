/**
 * Created by neo on 21.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Media } from '../../../../../Model/Media/Media';
import { Tabs } from 'antd';
import { MediaOverlayTab } from './MediaOverlayTab/MediaOverlayTab';
import { MediaAudioTrackTab } from './MediaAudioTrackTab/MediaAudioTrackTab';
import { MediaEditVideoSizesTab } from './MediaEditVideoSizesTab';
import { MediaLoopTab } from './MediaLoopTab/MediaLoopTab';
import { MediaVisibleAreaTab } from './MediaVisibleAreaTab/MediaVisibleAreaTab';
import { MediaTextTrackTab } from './MediaTextTrackTab/MediaTextTrackTab';

export type MediaEditVideoContentProps = {
  media: Media;
};

export const MediaEditVideoContent: React.FC<MediaEditVideoContentProps> = observer(({ media }) => {
  const [activeTab, setActiveTab] = useState('visibleArea');

  const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

  return (
    <Tabs onChange={handleChangeTab} activeKey={activeTab}>
      <Tabs.TabPane tab="Visible Area" key="visibleArea">
        <MediaVisibleAreaTab media={media} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Overlay" key="overlay">
        <MediaOverlayTab media={media} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Loops" key="loops">
        <MediaLoopTab media={media} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Sizes" key="sizes">
        <MediaEditVideoSizesTab media={media} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Audio Tracks" key="audioTracks">
        <MediaAudioTrackTab media={media} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Text Tracks" key="textTracks">
        <MediaTextTrackTab media={media} />
      </Tabs.TabPane>
    </Tabs>
  );
});
