/**
 * Created by neo on 30.03.2024
 */
import { Audited, AuditedJson } from '../../Audited';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { computed, observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type GymNotificationJson = AuditedJson & {
  gymId: string;
  title: LocalizedValueJson[];
  message: LocalizedValueJson[];
  payload: Record<string, string>;
  sendAt: string;
  imageUrl?: string;
  ttl: number;
  analyticsLabel?: string;
  totalRecipients: number;
  totalSent: number;
  failureCount: number;
  enqueuedAt?: string;
};

export class GymNotification extends Audited {
  @observable
  gymId: string = '';
  @observable
  title: LocalizedValue[] = [];
  @observable
  message: LocalizedValue[] = [];
  @observable
  payload: Record<string, string> = {};
  @observable
  sendAt = new Date();
  @observable
  imageUrl?: string;
  @observable
  ttl = 0;
  @observable
  analyticsLabel?: string;
  @observable
  totalRecipients = 0;
  @observable
  totalSent = 0;
  @observable
  failureCount = 0;
  @observable
  enqueuedAt?: Date;

  constructor(json?: Partial<GymNotificationJson>) {
    super(json);
    if (json) {
      this.gymId = json.gymId ?? '';
      this.title = json.title?.map((l) => new LocalizedValue(l)) ?? [];
      this.message = json.message?.map((l) => new LocalizedValue(l)) ?? [];
      this.payload = json.payload ?? {};
      this.sendAt = json.sendAt ? new Date(json.sendAt) : new Date();
      this.imageUrl = json.imageUrl;
      this.ttl = json.ttl ?? -1;
      this.analyticsLabel = json.analyticsLabel;
      this.totalRecipients = json.totalRecipients ?? 0;
      this.totalSent = json.totalSent ?? 0;
      this.failureCount = json.failureCount ?? 0;
      this.enqueuedAt = json.enqueuedAt ? new Date(json.enqueuedAt) : undefined;
    }
  }

  toJS(newId?: boolean): GymNotificationJson {
    return {
      ...super.toJS(newId),
      gymId: this.gymId,
      title: this.title.map((l) => l.toJS()),
      message: this.message.map((l) => l.toJS()),
      payload: this.payload,
      sendAt: this.sendAt.toISOString(),
      imageUrl: this.imageUrl,
      ttl: this.ttl,
      analyticsLabel: this.analyticsLabel,
      totalRecipients: this.totalRecipients,
      totalSent: this.totalSent,
      failureCount: this.failureCount,
      enqueuedAt: this.enqueuedAt?.toISOString(),
    };
  }


  /**
   * only admin-web needs gymId
   * @param gymId
   */
  save(): Promise<GymNotification> {
    return HttpBackend.post(`/messaging/notification/gym?gymId=${this.gymId}`, this.toJS()).then(() => this);
  }

  /**
   * only admin-web needs gymId
   * @param gymId
   */
  delete(): Promise<GymNotification> {
    return HttpBackend.delete(`/messaging/notification/gym/${this.id}?gymId=${this.gymId}`).then(() => this);
  }

  @computed
  get valid(): boolean {
    return (
      this.title.length > 0 &&
      this.message.length > 0 &&
      this.title.every((e) => e.value.trim().length > 0) &&
      this.message.every((e) => e.value.trim().length > 0)
    );
  }

  @computed
  get invalid(): boolean {
    return !this.valid;
  }

  /**
   * only admin-web needs gymId
   * @param gymId
   */
  static find(gymId: string): Promise<GymNotificationJson[]> {
    return HttpBackend.get(`/messaging/notification/gym?gymId=${gymId}`).then((res) => res.map((n) => new GymNotification(n)));
  }

  /**
   * only admin-web needs gymId
   * @param gymId
   */
  static count(gymId: string): Promise<number> {
    return HttpBackend.get(`/messaging/notification/gym/count?gymId=${gymId}`);
  }

  static findOne(id: string): Promise<GymNotification | undefined> {
    return HttpBackend.get(`/messaging/notification/gym/${id}`).then((res) =>
      res ? new GymNotification(res) : undefined,
    );
  }
}
