/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import styled from '@emotion/styled';
import { Pipeline } from '../../../../../Model/Coach/Pipeline';

export type PipelineTrayItemProps = {
  pipeline: Pipeline;
};

export const StyledTray = styled.div<{ color?: string; borderColor?: string }>`
  color: black;
  background-color: ${(p) => p.color ?? 'lightgrey'};
  font-family: Helvetica, Arial;
  padding: 4px 8px;
  border: solid 1px ${(p) => p.borderColor ?? 'grey'};
  border-radius: 5px;
  margin: 4px 0;
  cursor: pointer;
`;

export const PipelineTrayItem: React.FC<PipelineTrayItemProps> = observer(({ pipeline }: PipelineTrayItemProps) => {
  return (
    <StyledTray
      color="lightgrey"
      draggable={true}
      onDragStart={(event) => {
        event.dataTransfer.setData('phase-template-data', JSON.stringify(pipeline.toJS()));
      }}
    >
      {pipeline.name}
    </StyledTray>
  );
});
