/**
 * Created by neo on 05.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Media } from '../../Model/Media/Media';

export type VideoMediaItemTypeRendererProps = {
  media: Media;
  onClick?: (media: Media) => void;
};

export const VideoMediaItemTypeRenderer: React.FC<VideoMediaItemTypeRendererProps> = observer(({ media, onClick }) => {
  const handleClick = React.useCallback(() => {
    onClick && onClick(media);
  }, [onClick, media]);

  if (media.mediaType.startsWith('video')) {
    return (
      <div style={{ flex: 1, width: '100%', height: '100%' }}>
        <video
          playsInline={true}
          autoPlay
          muted
          loop
          onClick={handleClick}
          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        >
          <source src={media.medium} type={media.mediaType} />
        </video>
      </div>
    );
  } else if (media.mediaType.startsWith('image')) {
    return (
      <div
        onClick={handleClick}
        className="rounded img-fluid"
        style={{ ...styles.img, backgroundImage: `url('${media.smallest}')` }}
      />
    );
  } else if (media.mediaType.startsWith('audio')) {
    return (
      <audio controls>
        <source src={media.url} />
      </audio>
    );
  }

  return (
    <div>
      <a href={media.url}>{media.url}</a>
    </div>
  );
});

const styles = {
  img: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    height: '180px',
    width: '100%',
  },
};
