import { LinkModel, DefaultPortModel, DefaultPortModelOptions } from '@projectstorm/react-diagrams';
import { DefaultStageLinkModel } from './DefaultStageLinkModel';
import { Stage } from '../../../../../../../Model/Coach/Stages/Stage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class DefaultStagePortModel extends DefaultPortModel {
  constructor(public readonly pipeline: Pipeline, public readonly stage: Stage<any>, options: DefaultPortModelOptions) {
    super(options);
  }

  // constructor(public readonly stage: Stage<any>, isIn: boolean, name?: string, label?: string) {
  //   super(isIn, name, label);
  // }

  createLinkModel(): LinkModel {
    return new DefaultStageLinkModel();
  }

  addLink(link: LinkModel) {
    super.addLink(link);
    const source = link.getSourcePort() as DefaultStagePortModel;

    if (source) {
      // we are the input
      if (this.options.in) {
        console.log(`adding link`, source.stage.type, this.stage.type, source.options.label);
        this.pipeline.connect(source.stage.id, this.stage.id, source.options.label, this.options.label);
      } else {
        console.log(`adding link`, this.stage.type, source.stage.type, this.options.label);
        this.pipeline.connect(this.stage.id, source.stage.id, this.options.label);
      }
    }
  }

  removeLink(link: LinkModel) {
    super.removeLink(link);
    const source = link.getSourcePort() as DefaultStagePortModel;
    const target = link.getTargetPort() as DefaultStagePortModel;
    const otherPort = source?.stage.id === this.stage.id ? target : source;
    if (this.options.in && otherPort) {
      console.log('removing link', otherPort.stage.type, this.stage.type, otherPort.options.label);
      this.pipeline.disconnect(otherPort.stage.id, this.stage.id);
    }
  }
}
