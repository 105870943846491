/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Media } from '../../../../Model/Media/Media';
import { Button, Checkbox, Form, Modal, Select, Tabs } from 'antd';
import { MediaEditContentSwitch } from './MediaEditContentSwitch';
import dayjs, { Dayjs } from 'dayjs';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { runInAction } from 'mobx';
import { availableLanguages } from '../../../Coach/TextToSpeech/CoachTtsEditModal/CoachTtsEditModal';
import { Col, Row } from 'reactstrap';

export type MediaEditModalProps = {
  media: Media;
  onCancel?: () => void;
  onOk?: () => void;
  onSave?: (media: Media) => void;
  onMultipleVideosMixed?: (media: Media[], rootMedia: Media) => void;
};

export const MediaEditModal: React.FC<MediaEditModalProps> = observer(
  ({ media, onCancel, onOk, onSave, onMultipleVideosMixed }) => {
    const [processing, setProcessing] = useState(false);

    const handleSave = React.useCallback(() => {
      setProcessing(true);
      media
        .save()
        .then(() => {
          onOk && onOk();
          onSave && onSave(media);
        })
        .finally(() => setProcessing(false));
    }, [media, onOk, onSave]);

    const handleMixAudioPerTrack = React.useCallback(() => {
      setProcessing(true);
      media
        .mixAudioPerTrack()
        .then((medias) => onMultipleVideosMixed && onMultipleVideosMixed(medias, media))
        .finally(() => setProcessing(false));
    }, [media, onMultipleVideosMixed]);

    const handleDelete = React.useCallback(() => {
      setProcessing(true);
      media
        .delete()
        .then(() => onOk && onOk())
        .finally(() => setProcessing(false));
    }, [media, onOk]);

    const handleChangeLanguage = React.useCallback(
      (lang) => {
        runInAction(() => (media.language = lang));
      },
      [media],
    );

    return (
      <Modal
        open={true}
        confirmLoading={processing}
        width={window.innerWidth * 0.8}
        style={{ paddingBottom: 64 }}
        title={`${media.name} - ${dayjs(media.createdAt).format('DD.MM.YYYY HH:mm')}`}
        footer={[
          <Button key="1" onClick={onCancel} disabled={processing}>
            Close
          </Button>,
          media.mediaType.startsWith('video') ? (
            <Button key="1" danger onClick={handleMixAudioPerTrack} disabled={processing}>
              Create Video per Audio Track
            </Button>
          ) : null,
          media.usedCount === 0 ? (
            <Button key="2" danger type="primary" onClick={handleDelete} disabled={processing}>
              Delete
            </Button>
          ) : null,
          <Button key="3" type="primary" onClick={handleSave} disabled={processing}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Row>
            <Col xs={6}>
              <Form.Item label="Language">
                <Select value={media.language} onChange={handleChangeLanguage}>
                  {availableLanguages.map(({ label, value }) => (
                    <Select.Option key={value} value={value}>
                      {`${label} (${value})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <Checkbox
                  onChange={({ target: { checked } }) => runInAction(() => (media.aiContent = checked))}
                  checked={media.aiContent}
                >
                  AI Content / AI Translated
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <MediaEditContentSwitch media={media} />
        </Form>
      </Modal>
    );
  },
);
