/**
 *
 * Created by andreaskarantzas on 12.03.18.
 */

import * as React from 'react';
import { observable, computed, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Col, Alert } from 'reactstrap';
import Lightbox from 'lightbox-react';
import Dropzone from 'react-dropzone';
import MediaService from '../../../../Services/MediaService';
import { Gym } from '../../../../Model/Gym/Gym';
import { ViewMediaItem } from '../../../../Components/ViewMedia/ViewMediaItem';

export type GymLogoMediaProps = {
  containerName?: string;
  gym: Gym;
};

export const GymLogoMedia: React.FC<GymLogoMediaProps> = observer(({ gym, containerName = 'media' }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropzone = React.useRef<any>();

  const photoSource = gym.logo?.url;

  const handleDrop = async (files: File[]) => {
    runInAction(() => (gym.logo = undefined));
    for (const file of files) {
      const media = await MediaService.uploadMedia(file);
      gym.logo = media;
    }
  };

  const handleRemoveFile = () => {
    runInAction(() => (gym.logo = undefined));
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <h4>Gym Logo</h4>
          <Dropzone ref={dropzone} onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className="dropzone"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <input {...getInputProps()} />
                {'Drop Medias (Images or Videos) here'}
              </div>
            )}
          </Dropzone>
        </Col>
        <Col md={6}>
          <h4>{'Current Logo'}</h4>
          <Row>
            <Col sm={4}>
              {gym.logo ? (
                <React.Fragment>
                  <ViewMediaItem media={gym.logo} onRemove={handleRemoveFile} onClick={handleClick} />
                  <div style={{ textAlign: 'center' }}>
                    <a href={photoSource} target="_blank" rel="noreferrer">
                      Download
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <Alert color="warning">{'No logo associated yet'}</Alert>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpen && photoSource ? (
        <Lightbox mainSrc={photoSource} nextSrc={photoSource} prevSrc={photoSource} onCloseRequest={handleClose} />
      ) : null}
    </React.Fragment>
  );
});
