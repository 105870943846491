/**
 * Created by neo on 30.11.2023
 */
import { observable } from 'mobx';

export type MapRegionJson = {
  latitude: number;
  longitude: number;
  latitudeDelta: number;
  longitudeDelta: number;
};

export class MapRegion {
  @observable
  latitude = 0;
  @observable
  longitude = 0;
  @observable
  latitudeDelta = 0;
  @observable
  longitudeDelta = 0;

  constructor(json?: Partial<MapRegionJson>) {
    if (json) {
      this.latitude = json.latitude ?? 0;
      this.longitude = json.longitude ?? 0;
      this.latitudeDelta = json.latitudeDelta ?? 0;
      this.longitudeDelta = json.longitudeDelta ?? 0;
    }
  }

  toJS(): MapRegionJson {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      latitudeDelta: this.latitudeDelta,
      longitudeDelta: this.longitudeDelta,
    };
  }
}
