/**
 * Created by neo on 06.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form, InputNumber } from 'antd';
import { Duration, parse, serialize } from '../Utils/durationUtil';

function numberOrUndefined(value: number | string | null): number | undefined {
  const number = Number(value);
  if (!isNaN(number) && value !== null) {
    return number;
  }
  return undefined;
}

function _serialize(duration: Duration): string | undefined {
  const { years, months, weeks, days, hours, minutes } = duration;
  if (
    years !== undefined ||
    months !== undefined ||
    weeks !== undefined ||
    days !== undefined ||
    hours !== undefined ||
    minutes !== undefined
  ) {
    return serialize(duration);
  }
  return undefined;
}

export type DurationInputProps = {
  value?: string;
  onChange?: (newValue?: string) => void;
  disabled?: boolean;
};

export const DurationInput: React.FC<DurationInputProps> = observer(({ value, onChange, disabled }) => {
  const [durationValue, setDurationValue] = useState<Duration | undefined>();

  useEffect(() => {
    if (value) {
      setDurationValue(parse(value));
    } else {
      setDurationValue(undefined);
    }
  }, [value]);

  const handleChangeYears = React.useCallback(
    (value) => {
      const newDuration = Object.assign({}, durationValue ?? {}, { years: numberOrUndefined(value) });
      setDurationValue(newDuration);
      onChange && onChange(_serialize(newDuration));
    },
    [durationValue, onChange],
  );

  const handleChangeMonths = React.useCallback(
    (value) => {
      const newDuration = Object.assign({}, durationValue ?? {}, { months: numberOrUndefined(value) });
      setDurationValue(newDuration);
      onChange && onChange(_serialize(newDuration));
    },
    [durationValue, onChange],
  );

  const handleChangeWeeks = React.useCallback(
    (value) => {
      const newDuration = Object.assign({}, durationValue ?? {}, { weeks: numberOrUndefined(value) });
      setDurationValue(newDuration);
      onChange && onChange(_serialize(newDuration));
    },
    [durationValue, onChange],
  );

  const handleChangeDays = React.useCallback(
    (value) => {
      const newDuration = Object.assign({}, durationValue ?? {}, { days: numberOrUndefined(value) });
      setDurationValue(newDuration);
      onChange && onChange(_serialize(newDuration));
    },
    [durationValue, onChange],
  );

  const handleChangeHours = React.useCallback(
    (value) => {
      const newDuration = Object.assign({}, durationValue ?? {}, { hours: numberOrUndefined(value) });
      setDurationValue(newDuration);
      onChange && onChange(_serialize(newDuration));
    },
    [durationValue, onChange],
  );

  const handleChangeMinutes = React.useCallback(
    (value) => {
      const newDuration = Object.assign({}, durationValue ?? {}, { minutes: numberOrUndefined(value) });
      setDurationValue(newDuration);
      onChange && onChange(_serialize(newDuration));
    },
    [durationValue, onChange],
  );

  return (
    <Row>
      <Col>
        <Form.Item label="Years">
          <InputNumber
            min={0}
            max={100}
            value={durationValue?.years}
            onChange={handleChangeYears}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Months">
          <InputNumber
            min={0}
            max={100}
            value={durationValue?.months}
            onChange={handleChangeMonths}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Weeks">
          <InputNumber
            min={0}
            max={100}
            value={durationValue?.weeks}
            onChange={handleChangeWeeks}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Days">
          <InputNumber min={0} max={100} value={durationValue?.days} onChange={handleChangeDays} disabled={disabled} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Hours">
          <InputNumber
            min={0}
            max={100}
            value={durationValue?.hours}
            onChange={handleChangeHours}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Minutes">
          <InputNumber
            min={0}
            max={100}
            value={durationValue?.minutes}
            onChange={handleChangeMinutes}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </Row>
  );
});
