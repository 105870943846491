/**
 *
 * Created by andreaskarantzas on 11.02.19.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { useEffect, useState } from 'react';
import { AthleteConfiguration } from '../../../../Model/Athlete/AthleteConfiguration';
import ReactJson from 'react-json-view';
import { SingleColRow } from '../../../../Components/SingleColRow';

export type AthleteConfigTabProps = {
  athlete: Athlete;
};

export const AthleteConfigTab: React.FC<AthleteConfigTabProps> = observer(({ athlete }) => {
  const [configuration, setConfiguration] = useState(new AthleteConfiguration());

  useEffect(() => {
    AthleteConfiguration.get(athlete.id).then((res) => setConfiguration(res));
  }, [athlete]);

  return (
    <React.Fragment>
      <SingleColRow>
        <ReactJson src={configuration.data} displayDataTypes={false} collapsed={2} sortKeys />
      </SingleColRow>
    </React.Fragment>
  );
});
