/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Badge, Button } from 'reactstrap';
import { Employee } from '../../../../../Model/Gym/Employee';
import { useRootStore } from '../../../../../Store/useRootStore';

export type EmployeeTableRowProps = {
  employee: Employee;
  onRemove?: (employee: Employee) => any;
  onSelect?: (employee: Employee) => any;
  onFetchToken?: (employee: Employee) => any;
};

export const EmployeeTableRow: React.FC<EmployeeTableRowProps> = observer(
  ({ employee, onRemove, onSelect, onFetchToken }: EmployeeTableRowProps) => {
    const { authentication } = useRootStore();
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(employee);
    }, [onRemove, employee]);

    const handleSelect = React.useCallback((e) => {
      e.preventDefault();
      onSelect && onSelect(employee);
    }, [onSelect, employee]);

    const handleFetchToken = React.useCallback((e) => {
      e.preventDefault();
      onFetchToken && onFetchToken(employee);
    }, [onFetchToken, employee]);

    return (
      <tr>
        <td>
          <a href="" onClick={handleSelect}>
            {employee.athlete.fullName || '<no name>'}
          </a>
        </td>
        <td>{employee.athlete.user.email}</td>
        <td>
          {employee.roles.map((r: string) => (
            <Badge key={r} color="info">
              {r}
            </Badge>
          ))}
        </td>
        <td>
          {onRemove && authentication.isGodAdmin ? (
            <Button size="sm" color="danger" onClick={handleRemove}>
              {'Remove'}
            </Button>
          ) : null}
          {onFetchToken && authentication.isGodAdmin ? (
            <Button size="sm" color="secondary" href="" onClick={handleFetchToken}>
              {'Fetch Token'}
            </Button>
          ) : null}
        </td>
      </tr>
    );
  },
);
