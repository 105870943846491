/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { ExerciseBlock } from '../../../../../Model/ProgramPortfolio/ExerciseBlock';
import { CoachExerciseBlock } from '../../../../../Model/Coach/Program/Workout/CoachExerciseBlock';
import ReactPlayer from 'react-player';
import { Media } from '../../../../../Model/Media/Media';
import { Props } from '../../../../../Components/TableRowMedia';
import { WorkoutResponseExerciseBlock } from '../../../../../Model/Coach/Script/WorkoutResponseExerciseBlock';
import { Exercise } from '../../../../../Model/Exercise/Exercise';

export type ExerciseBlockMediaProps = {
  exercise: Exercise;
  preferImage?: boolean;
};

export const ExerciseBlockMedia: React.FC<ExerciseBlockMediaProps> = observer(
  ({  preferImage, exercise }) => {
    const medias = exercise.medias;
    const imageMedia = medias?.find(
      (m) => m.mediaType.startsWith('image') || m.mediaType.startsWith('application/octet-stream'),
    );
    const videoMedia = medias?.find((m) => (m.mediaType || '').startsWith('video'));
    const videoUrl = videoMedia?.url;
    const imageUrl = imageMedia?.smallest;


    return videoUrl && !preferImage ? (
      <ReactPlayer url={videoUrl} width="100%" height={180} controls={true} loop={true} progressInterval={16} />
    ) : (
      <img src={imageUrl} style={styles.imageContainer as any} />
    );
  },
);

const styles = {
  imageContainer: {
    height: 92,
    objectFit: 'cover',
  },
  image: {
    width: 120,
    height: 120,
    maxWidth: '100%',
    maxHeight: '100%',
  },
};
