export const Config = {
  backend: {
    url: 'https://api.kinastic.com/v1',
    authUrl: '/api',
    websocket: 'wss://api.kinastic.com/v1',
  },
  openai: {
    gptApiKey: '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',
    organizationId: 'org-LlcqSsGb4JD04F87hpuhYgg9'
  }
};
