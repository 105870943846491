/**
 * Created by neo on 10.06.21.
 */
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { computed, observable } from 'mobx';
import { languagePriority } from '../LocalizedNamedEntity';

export type GymChallengeNotificationMessageJson = {
  title: LocalizedValueJson[];
  message: LocalizedValueJson[];
};

export class GymChallengeNotificationMessage {
  @observable
  title: LocalizedValue[] = [];
  @observable
  message: LocalizedValue[] = [];

  constructor(json?: Partial<GymChallengeNotificationMessageJson>) {
    if (json) {
      this.title = (json.title ?? []).map((m) => new LocalizedValue(m));
      this.message = (json.message ?? []).map((m) => new LocalizedValue(m));
    }
  }

  toJS(): GymChallengeNotificationMessageJson {
    return {
      title: this.title.map((t) => t.toJS()),
      message: this.message.map((t) => t.toJS()),
    };
  }

  getTitle(lang: string) {
    return this.title.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  getMessage(lang: string) {
    return this.message.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  @computed
  get defaultTitle(): string {
    for (const lang of languagePriority) {
      const entry = this.title.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.title[0];
    return first?.value ?? '';
  }

  @computed
  get defaultMessage(): string {
    for (const lang of languagePriority) {
      const entry = this.message.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.message[0];
    return first?.value ?? '';
  }
}
