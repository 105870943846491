import * as React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { TagMap } from '../Model/TagDefinition';
import { Badge, Tag } from 'antd';

const styles = {
  tagBadge: {
    cursor: 'pointer',
    marginRight: 8,
    fontSize: 14,
  },
};

export interface TagCategoryProps {
  category: string;
  definition: TagMap;
  tags: string[];
  color?: string;
  onAddTag?: (tag: string) => any;
}

@observer
export class TagCategory extends React.Component<TagCategoryProps> {
  @computed
  get values(): string[] {
    const {
      props: { tags, category },
    } = this;
    return tags
      .map((t: string) => t.split(':'))
      .filter((t: string[]) => t[0] === category)
      .reduce((arr: string[], entry: string[]) => arr.concat([entry[1]]), []);
  }

  @computed
  get remainingValues(): string[] {
    const {
      props: { category, definition },
      values,
    } = this;
    const entry = definition.get(category);
    if (entry) {
      if ((entry.max && values.length < entry.max) || !entry.max) {
        return entry.values.filter((t) => -1 === values.findIndex((v) => v === t));
      }
    }
    return [];
  }

  @computed
  get tagArray(): string[] {
    const {
      props: { category },
    } = this;
    return this.remainingValues.map((v) => `${category}:${v}`);
  }

  handleAdd = (tag: string) => {
    this.props.onAddTag && this.props.onAddTag(tag);
  };

  render() {
    const {
      props: { color },
    } = this;
    return (
      <React.Fragment>
        {this.tagArray.map((tag: string) => (
          <Tag key={tag} color={color || 'primary'} style={styles.tagBadge} onClick={() => this.handleAdd(tag)}>
            {tag}
          </Tag>
        ))}
      </React.Fragment>
    );
  }
}
