/**
 * Created by neo on 15.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Form } from 'antd';
import { ChartColors } from '../ChartColors';

export type AgeDistributionHistoryChartProps = {
  data: any[];
};

export const AgeDistributionHistoryChart: React.FC<AgeDistributionHistoryChartProps> = observer(({ data }) => {
  const keys =
    data && data.length > 0
      ? ([
          ...new Set<string>(data.flatMap((d) => Array.from(Object.keys(d)).filter((k) => k !== 'name') as string[])),
        ] as string[]).sort((a, b) => a.localeCompare(b))
      : [];

  return (
    <Form.Item label="Users Age">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart width={730} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {keys.map((key, index) => (
            <Line key={key} type="monotone" dataKey={key} stroke={ChartColors[index % ChartColors.length]} connectNulls />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Form.Item>
  );
});
