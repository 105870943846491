/**
 * Created by neo on 12.04.22.
 */
import {
  MindfulnessProgramTemplateDayUnit,
  MindfulnessProgramTemplateDayUnitJson,
} from './MindfulnessProgramTemplateDayUnit';
import { Media, MediaJson } from '../../../Media/Media';
import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction } from 'mobx';
import { PipelineContext, PipelineContextJson } from '../../PipelineContext';
import { WorkoutTemplate } from '../../SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { CoachWorkout } from '../../Program/Workout/CoachWorkout';

export type MindfulnessProgramTemplateDayUnitWorkoutTemplateJson = MindfulnessProgramTemplateDayUnitJson & {
  workoutTemplateId: string;
  context: PipelineContextJson;
};

export class MindfulnessProgramTemplateDayUnitWorkoutTemplate extends MindfulnessProgramTemplateDayUnit {
  @observable
  workoutTemplateId = '';
  @observable
  context: PipelineContext = new PipelineContext();
  @observable
  workoutTemplate?: WorkoutTemplate;
  private fetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<MindfulnessProgramTemplateDayUnitWorkoutTemplateJson>) {
    super(Object.assign({}, json ?? {}, { type: 'workoutTemplate' }));
    if (json) {
      this.workoutTemplateId = json.workoutTemplateId ?? '';
      this.context = new PipelineContext(json.context);
    }

    onBecomeObserved(this, 'workoutTemplate', this.startFetchActivity);
    onBecomeUnobserved(this, 'workoutTemplate', this.stopFetchActivity);
  }

  startFetchActivity = () => {
    this.fetchDisposer = reaction(
      () => this.workoutTemplateId,
      (workoutTemplateId) => {
        if (workoutTemplateId) {
          WorkoutTemplate.get(workoutTemplateId).then((result) => runInAction(() => (this.workoutTemplate = result)));
        } else {
          runInAction(() => (this.workoutTemplate = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchActivity = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = undefined;
  };

  toJS(newId: boolean = false): MindfulnessProgramTemplateDayUnitWorkoutTemplateJson {
    return Object.assign(super.toJS(newId), {
      workoutTemplateId: this.workoutTemplateId,
      context: this.context.toJS(),
    });
  }

  generate(): Promise<CoachWorkout | undefined> {
    return this.workoutTemplateId
      ? CoachWorkout.generate(this.workoutTemplateId, this.context)
      : Promise.resolve(undefined);
  }
}
