/**
 * Created by neo on 14.12.20.
 */
import { SubscriptionTransactionDetails, SubscriptionTransactionDetailsJson } from './SubscriptionTransactionDetails';
import { computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { SubscriptionPlan } from './SubscriptionPlan';

export type GymCoachSubscriptionJson = {
  id: string;
  gymId: string;
  plan: SubscriptionPlan;
  licenseCount?: number;
  validFrom: string;
  validUntil?: string;
  cancelDate?: string;
  transactionDetails?: SubscriptionTransactionDetailsJson;
  trial: boolean;
  enabledModules: string[];
};

export class GymCoachSubscription {
  @observable
  id = UUID();
  @observable
  gymId: string = '';
  @observable
  plan: SubscriptionPlan = 'free';
  @observable
  licenseCount?: number;
  @observable
  validFrom = new Date();
  @observable
  validUntil?: Date;
  @observable
  cancelDate?: string;
  @observable
  transactionDetails?: SubscriptionTransactionDetails;
  @observable
  trial = false;
  @observable
  enabledModules = ['notifications', 'content_management', 'custom_challenges', 'physical_workshops'];

  constructor(json?: Partial<GymCoachSubscriptionJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.gymId = json.gymId ?? '';
      this.plan = json.plan ?? 'free';
      this.licenseCount = json.licenseCount;
      this.validFrom = json.validFrom ? new Date(json.validFrom) : new Date();
      this.validUntil = json.validUntil ? new Date(json.validUntil) : undefined;
      this.transactionDetails = json.transactionDetails
        ? new SubscriptionTransactionDetails(json.transactionDetails)
        : undefined;
      this.trial = json.trial ?? false;
      this.enabledModules = json.enabledModules ?? [];
    }
  }

  toJS(): GymCoachSubscriptionJson {
    return {
      id: this.id,
      gymId: this.gymId,
      plan: this.plan,
      licenseCount: this.licenseCount,
      validFrom: this.validFrom.toISOString(),
      validUntil: this.validUntil?.toISOString(),
      transactionDetails: this.transactionDetails?.toJS(),
      trial: this.trial,
      enabledModules: this.enabledModules,
    };
  }

  save(): Promise<GymCoachSubscription> {
    return HttpBackend.post(`/coach/subscription/gym`, this.toJS()).then(() => this);
  }

  delete(): Promise<GymCoachSubscription> {
    return HttpBackend.delete(`/coach/subscription/gym/${this.id}`).then(() => this);
  }

  @computed
  get valid(): boolean {
    return this.plan !== 'free' && (!this.validUntil || this.validUntil.getTime() > new Date().getTime()) && this.validFrom.getTime() <= new Date().getTime();
  }

  static current(query?: { gymId?: string }): Promise<GymCoachSubscription> {
    return HttpBackend.get(`/coach/subscription/gym/current`, query).then((res) => new GymCoachSubscription(res));
  }

  static get(subscriptionId: string): Promise<GymCoachSubscription> {
    return HttpBackend.get(`/coach/subscription/gym/${subscriptionId}`).then((res) => new GymCoachSubscription(res));
  }

  static find(params?: any): Promise<GymCoachSubscription[]> {
    return HttpBackend.get('/coach/subscription/gym', params).then((res) =>
      res.map((r) => new GymCoachSubscription(r)),
    );
  }
}
