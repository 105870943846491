/**
 * Created by neo on 14.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PageResult } from '../../../Model/PageResult';
import { Col, Container, Row, Table } from 'reactstrap';
import { Button } from 'antd';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Pager } from '../../../Components/Pager';
import { Route, Routes } from 'react-router-dom';
import { GymChallengeModal } from '../Challenge/GymChallengeModal';
import { RouteChallenge } from '../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeTableRow } from './RouteChallengeTableRow';

export type RouteChallengeListScreenProps = {};

export const RouteChallengeListScreen: React.FC<RouteChallengeListScreenProps> = observer((props) => {
  const history = useNavigate();

  const [challenges, setChallenges] = useState<PageResult<RouteChallenge>>(new PageResult());
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      RouteChallenge.find({ page, sort: 'startDateTime,DESC', size: 20 }),
      RouteChallenge.count(),
      page,
      20,
    ).then((result) => setChallenges(result));
  }, [page]);

  const handleCreateChallenge = React.useCallback(() => {
    history(`new`);
  }, [history]);

  const handleRefresh = () => {
    PageResult.execute(
      RouteChallenge.find({ page, sort: 'startDateTime,DESC', size: 20 }),
      RouteChallenge.count(),
      page,
      20,
    ).then((result) => setChallenges(result));
  };

  return (
    <Container>
      <Row>
        <Col>
          <h3>Route Challenges</h3>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreateChallenge}>
            Create new Challenge
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={challenges} onPage={(newPage) => setPage(newPage)} />
      </SingleColRow>
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              {/*<th>Status</th>*/}
              <th>Points</th>
              <th>Teams</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {challenges.map((challenge) => (
              <RouteChallengeTableRow key={challenge.id} challenge={challenge} onRefresh={handleRefresh} />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
      <SingleColRow>
        <Pager page={challenges} onPage={(newPage) => setPage(newPage)} />
      </SingleColRow>
      <Routes>
        <Route path=":challengeId" element={<GymChallengeModal />} />
      </Routes>
    </Container>
  );
});
