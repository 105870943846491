/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { CustomersTable } from './CustomersTable';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { Pager } from '../../../../Components/Pager';
import { PageResult } from '../../../../Model/PageResult';
import { Customer } from '../../../../Model/Customer/Customer';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useEffect, useState } from 'react';

export type AthleteGymsTabProps = {
  athlete: Athlete;
};

export const AthleteGymsTab: React.FC<AthleteGymsTabProps> = observer(({ athlete }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    Customer.find({
      athleteId: athlete.id,
      sort: 'createdAt,DESC',
      size: 50,
    }).then((res) => setCustomers(res));
  }, [athlete]);

  return (
    <SingleColRow>
      <CustomersTable customers={customers} />
    </SingleColRow>
  );
});
