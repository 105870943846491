/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { Branch } from '../../../../Model/Gym/Branch';
import { ViewMedia } from '../../../../Components/ViewMedia/ViewMedia';
import { OpeningHoursList } from './OpeningHours/OpeningHoursList';
import { action, observable } from 'mobx';
import { GeoJsonPoint } from '../../../../Model/GeoJsonPoint';

export type Props = {
  branch: Branch;
};

@observer
export class ViewBasic extends React.Component<Props> {
  @observable
  address: string = '';

  componentDidMount(): void {
    this.address = this.props.branch.address.friendlyString;
    // Geocode.setApiKey('AIzaSyARgahXZgJ2T2casGDuIKat3oZtqcyQmr4');
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.branch.address.friendlyString !== this.props.branch.address.friendlyString) {
      this.address = this.props.branch.address.friendlyString;
    }
  }

  handleSave = async (e: any) => {
    e.preventDefault();
    try {
      await this.props.branch.save();
    } catch (err) {}
  };

  handleChange = (address: string) => {
    this.address = address;
  };

  getAddressPart(address: any, type: string) {
    const part = address.address_components.find((a) => -1 !== a.types.indexOf(type));
    if (part) {
      return part.long_name;
    }
    return undefined;
  }

  @action
  setAddress(addr: any, reverseGeocoding?: boolean) {
    const {
      props: {
        branch: { address },
      },
    } = this;
    const street = this.getAddressPart(addr, 'route');
    const houseNr = this.getAddressPart(addr, 'street_number');
    address.addressLine1 = `${street}${houseNr ? ` ${houseNr}` : ''}`;
    address.city = `${
      this.getAddressPart(addr, 'sublocality') ||
      this.getAddressPart(addr, 'locality') ||
      this.getAddressPart(addr, 'administrative_area_level_2')
    }`;
    address.state = this.getAddressPart(addr, 'administrative_area_level_1');
    address.zipCode = this.getAddressPart(addr, 'postal_code');
    address.country = this.getAddressPart(addr, 'country');
    if (reverseGeocoding) {
      address.location = new GeoJsonPoint({
        coordinates: [addr.geometry.location.lng, addr.geometry.location.lat],
      });
      console.log('setAddress', addr, addr.geometry.location.lat, addr.geometry.location.lng);
    } else {
      address.location = new GeoJsonPoint({
        coordinates: [addr.geometry.location.lng(), addr.geometry.location.lat()],
      });
      console.log('setAddress', addr, addr.geometry.location.lat(), addr.geometry.location.lng());
    }
    this.address = address.friendlyString;
  }

  render() {
    const {
      branch,
      branch: { branchInfo, address },
    } = this.props;
    return (
      <Row>
        <Col className="pt-2">
          <Form onSubmit={this.handleSave}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>{'Name'}</Label>
                  <Input type="text" value={branch.name || ''} onChange={(e) => (branch.name = e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form onSubmit={this.handleSave}>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label>{'Default description'}</Label>
                        <Input
                          type="text"
                          value={branchInfo.defaultDescription || ''}
                          onChange={(e) => (branchInfo.defaultDescription = e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label>{'Email'}</Label>
                        <Input
                          type="text"
                          value={branchInfo.data.email || ''}
                          onChange={(e) => (branchInfo.data.email = e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>{'Website'}</Label>
                        <Input
                          type="text"
                          value={branchInfo.data.website || ''}
                          onChange={(e) => (branchInfo.data.website = e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>{'Courses link'}</Label>
                        <Input
                          type="text"
                          value={branchInfo.data.coursesLink || ''}
                          onChange={(e) => (branchInfo.data.coursesLink = e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>{'Telephone'}</Label>
                        <Input
                          type="number"
                          value={branchInfo.data.telephone || ''}
                          onChange={(e) => (branchInfo.data.telephone = e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <OpeningHoursList branch={branch} />
            </Row>
            <h3>Branch media</h3>
            <ViewMedia medias={branch.medias} message="Drop image here" />
            <Row>
              <Col>
                <div className="float-right">
                  <button type="submit" className="btn btn-primary">
                    {'Save'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}
