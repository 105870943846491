/**
 * Created by neo on 01.12.21.
 */
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Audited, AuditedJson } from '../Audited';
import { ChallengeMilestoneJson } from './ChallengeMilestone';
import { GymChallengeParticipantDailyStatsJson } from './GymChallengeParticipantDailyStats';
import { observable, onBecomeObserved, runInAction } from 'mobx';
import { Athlete } from '../Athlete/Athlete';

export type GymChallengeParticipantQueryRequest = {
  gymId?: string;
  challengeId?: string;
};

export type GymChallengeParticipantJson = AuditedJson & {
  id: string;
  challengeId: string;
  gymId: string;
  athleteId: string;
  joinDate: string;
  challengeStartDateTime: string;
  challengeEndDateTime: string;
  dailyPoints: Record<string, number>;
  anonymous: boolean;

  /**
   * to determine to start & end of a day
   */
  timezone: string;
  totalPointsCollected: number;
  dailyStats: Record<string, GymChallengeParticipantDailyStatsJson>;
};

export class GymChallengeParticipant extends Audited {
  @observable
  challengeId = '';
  @observable
  gymId = '';
  @observable
  athleteId = '';
  @observable
  joinDate = new Date();
  @observable
  challengeStartDateTime = new Date();
  @observable
  challengeEndDateTime = new Date();
  @observable
  dailyPoints: Record<string, number> = {};
  @observable
  totalPointsCollected = 0;
  @observable
  anonymous = true;

  /**
   * to determine to start & end of a day
   */
  @observable
  timezone: string;
  @observable
  dailyStats: Record<string, GymChallengeParticipantDailyStatsJson>;
  @observable
  athlete?: Athlete;

  constructor(json?: Partial<GymChallengeParticipantJson>) {
    super(json);
    if (json) {
      this.challengeId = json.challengeId ?? '';
      this.gymId = json.gymId ?? '';
      this.athleteId = json.athleteId ?? '';
      this.joinDate = json.joinDate ? new Date(json.joinDate) : new Date();
      this.challengeStartDateTime = json.challengeStartDateTime ? new Date(json.challengeStartDateTime) : new Date();
      this.challengeEndDateTime = json.challengeEndDateTime ? new Date(json.challengeEndDateTime) : new Date();
      this.dailyPoints = json.dailyPoints ?? {};
      this.totalPointsCollected = json.totalPointsCollected ?? 0;
      this.anonymous = json.anonymous ?? true;
    }

    onBecomeObserved(this, 'athlete', () => {
      if (this.athleteId) {
        Athlete.get(this.athleteId).then((res) => runInAction(() => (this.athlete = res)));
      } else {
        runInAction(() => (this.athlete = undefined));
      }
    });
  }

  static count(params: GymChallengeParticipantQueryRequest): Promise<number> {
    return HttpBackend.get(`/engagement/gym/challenges/admin/participant/count`, params).then((result) => result ?? 0);
  }
}
