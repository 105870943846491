import * as React from 'react';
import { observer } from 'mobx-react';
import { TrackingKeysList } from '../../../../../Model/ProgramPortfolio/TrackingKeys';
import { computed, observable } from 'mobx';
import { ExerciseSet } from '../../../../../Model/WorkoutLog/ExerciseSet';
import { SetsTableColumn } from './SetsTableColumn';

export type Props = {
  set: ExerciseSet;
};

@observer
export class SetsTableRow extends React.Component<Props> {
  @observable
  rowVisible = false;

  handleToggle = () => {
    this.rowVisible = !this.rowVisible;
  };

  @computed
  get trackingKeys(): string[] {
    return this.props.set.assignedTrackingKeys;
  }

  render() {
    const { set } = this.props;
    return (
      <tr>
        {TrackingKeysList.map((t) => (
          <SetsTableColumn key={t} set={set} trackingKey={t} />
        ))}
      </tr>
    );
  }
}
