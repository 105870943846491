/**
 * Created by neo on 29.04.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { BodyPartRegionAdmin } from '../../../Model/BodyPart/BodyPartRegionAdmin';
import { TableRowMedia } from '../../../Components/TableRowMedia';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

export type BodyPartTableRowProps = {
  bodyPart: BodyPartRegionAdmin;
  onRemove?: (bodyPart: BodyPartRegionAdmin) => any;
};

export const BodyPartTableRow: React.FC<BodyPartTableRowProps> = observer(
  ({ bodyPart, onRemove }: BodyPartTableRowProps) => {
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(bodyPart);
    }, [bodyPart, onRemove]);

    return (
      <tr>
        <td>
          <TableRowMedia medias={bodyPart.medias} />
        </td>
        <td>{bodyPart.identifier}</td>
        <td>{bodyPart.size}</td>
        <td>
          <Link to={`/metadata/bodypart/view/${bodyPart.id}`}>{bodyPart.defaultName}</Link>
        </td>
        <td>{bodyPart.parents?.map((p) => p.defaultName).join(', ')}</td>
        <td>{bodyPart.latinName}</td>
        <td>{bodyPart.type}</td>
        <td>{bodyPart.exerciseCount}</td>
        <td>
          <Button type="link" danger onClick={handleRemove}>
            {'Remove'}
          </Button>
        </td>
      </tr>
    );
  },
);
