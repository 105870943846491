import { action, computed, observable, remove, set, toJS } from 'mobx';
import { TagCondition } from '../TagCondition';
import { WorkoutPhaseTemplate } from './WorkoutPhaseTemplate';
import { v4 as UUID } from 'uuid';
import { PipelineParam } from '../../PipelineParam';
import { notUndefined } from '../../../../Utils/notUndefined';
import { paramSort } from '../paramSort';

export class PhaseConfiguration {
  @observable
  id = UUID();
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  phaseTemplates: WorkoutPhaseTemplate[] = [];
  @observable
  params: {
    [key: string]: any;
  } = {};

  constructor(json?: any) {
    if (json) {
      this.id = json.id ?? UUID();
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.phaseTemplates = (json.phaseTemplates ?? []).map((t) => new WorkoutPhaseTemplate(t));
      this.params = json.params ?? {};
    }
  }

  toJS(replaceId?: boolean) {
    return {
      id: replaceId ? UUID() : this.id,
      tagConditions: this.tagConditions.map((p) => p.toJS()),
      phaseTemplates: this.phaseTemplates.map((p) => p.toJS(replaceId)),
      params: toJS(this.params),
    };
  }

  copy() {
    return new PhaseConfiguration(this.toJS(true));
  }

  @action
  setParam(paramName: string, value?: any) {
    this.params = this.params ?? {};
    set(this.params, paramName, value);
    // this.params[paramName] = value;
  }

  @action
  removeParam(paramName: string) {
    this.params = this.params ?? {};
    remove(this.params, paramName);
    // delete this.params[paramName];
  }

  @action
  addPhaseTemplate(phaseTemplate: WorkoutPhaseTemplate, index?: number) {
    if (index !== undefined && index < this.phaseTemplates.length) {
      const insertIndex = Math.max(index, 0);
      if (insertIndex === 0) {
        this.phaseTemplates.unshift(phaseTemplate);
      } else {
        this.phaseTemplates.splice(insertIndex, 0, phaseTemplate);
      }
    } else {
      this.phaseTemplates.push(phaseTemplate);
    }
  }

  @action
  removePhaseTemplate(phaseId: string) {
    this.phaseTemplates = this.phaseTemplates.filter((p) => p.id !== phaseId);
  }

  @action
  changePhaseTemplateIndex(phaseTemplateId: string, index: number) {
    const currentIndex = this.phaseTemplates.findIndex((p) => p.id === phaseTemplateId);
    const current = this.phaseTemplates.splice(currentIndex, 1)[0];
    console.log('index', phaseTemplateId, current, currentIndex, index, this.phaseTemplates.length);
    if (current) {
      if (index < this.phaseTemplates.length) {
        const insertIndex = Math.max(index, 0);
        this.phaseTemplates.splice(insertIndex, 0, current);
      } else {
        this.phaseTemplates.push(current);
      }
    }
  }

  @computed
  get allParams(): PipelineParam[] {
    const processed = new Map<string, boolean>();
    return this.phaseTemplates
      .map((p) => p.pipeline)
      .filter(notUndefined)
      .map((p) => {
        const result = p.params.filter((param) => !processed.has(param.name));
        p.params.forEach((param) => processed.set(param.name, true));
        return result;
      })
      .flatMap((p) => p)
      .filter((p) => p.name !== 'weight')
      .sort((a, b) => {
        const aIndex = paramSort.indexOf(a.name) === -1 ? 1000 : paramSort.indexOf(a.name);
        const bIndex = paramSort.indexOf(b.name) === -1 ? 1000 : paramSort.indexOf(b.name);
        return aIndex - bIndex;
      });
  }

  @computed
  get friendlyTagString(): string {
    if (this.tagConditions.length > 0) {
      const hasEmpty = this.tagConditions.findIndex((c) => c.empty) !== -1;
      return this.tagConditions
        .filter((c) => !c.empty)
        .map((c) => c.friendlyString)
        .concat(hasEmpty ? ['<default>'] : [])
        .join(' & ');
    }
    return '<default>';
  }
}
