/**
 * Created by neo on 01.07.20.
 */
import * as React from 'react';
import { EquipmentTypeGroupInput } from '../EquipmentTypeGroupInput';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { EquipmentTypeGroup } from '../../Model/Equipment/EquipmentTypeGroup';
import { EquipmentType } from '../../Model/Equipment/EquipmentType';
import { observer } from 'mobx-react';

export type ExerciseVariationFilterEquipmentTypeGroupProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterEquipmentTypeGroup: React.FC<ExerciseVariationFilterEquipmentTypeGroupProps> = observer(
  ({ filter }: ExerciseVariationFilterEquipmentTypeGroupProps) => {
    const handleChange = async (groups: EquipmentTypeGroup[]) => {
      const requiredEquipmentTypes = groups.flatMap((g) => g.requiredEquipmentTypes);
      const equipmentTypes = groups.flatMap((g) => g.equipmentTypes);
      filter.equipmentTypes = await Promise.all(
        equipmentTypes.map((e) => EquipmentType.get(e).then((result) => result?.resolveAll() ?? [])),
      ).then((result) => result.flatMap((r) => r.map((e) => e.id)));
      filter.requiredEquipmentTypes = await Promise.all(
        requiredEquipmentTypes.map((e) => EquipmentType.get(e).then((result) => result?.resolveAll() ?? [])),
      ).then((result) => result.flatMap((r) => r.map((e) => e.id)));
    };

    return (
      <EquipmentTypeGroupInput
        label="Equipment Type Groups"
        container={filter.equipmentTypeGroups}
        onChange={handleChange}
      />
    );
  },
);
