/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { MacroCycleLaneTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleLaneTemplate';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { InitCyclesEditor } from './InitCyclesEditor';
import { CyclesEditor } from './CyclesEditor';
import { TagConditionsInput } from './TagConditionsInput';

export type MacroCycleLaneCardProps = {
  lane: MacroCycleLaneTemplate;
  onRemove?: (lane: MacroCycleLaneTemplate) => any;
};

export const MacroCycleLaneCard: React.FC<MacroCycleLaneCardProps> = observer(
  ({ lane, onRemove }: MacroCycleLaneCardProps) => {
    return (
      <React.Fragment>
        <SingleColRow>
          <TagConditionsInput tagConditions={lane.tagConditions} />
        </SingleColRow>
        <SingleColRow>
          <h5>Init Cycles</h5>
        </SingleColRow>
        <InitCyclesEditor macroCycleLane={lane} />
        <SingleColRow>
          <h5>Repetitive Cycles</h5>
        </SingleColRow>
        <CyclesEditor macroCycleLane={lane} />
      </React.Fragment>
    );
  },
);
