/**
 * Created by joser on 10.05.2016.
 */

import AppAuthToken from './AppAuthToken';
import AppStore from '../AppStore';
import { HttpBackend } from '../Http/HttpBackend';
import { UserToken } from './UserToken';
import { Athlete } from '../../Model/Athlete/Athlete';
import { Config } from '../../Config/Config';
import { Buffer } from 'buffer';
import Backend from '../Http/Backend';
import { Retry } from '../../Utils/Retry';
import AppBackend from '../Http/AppBackend';

export class AuthenticationService {
  otpLogin(authenticationCode: string): Promise<Athlete> {
    return AppBackend.post(`/uaa/auth/otp/authenticate`, { authenticationCode })
      .then((data) => new UserToken(data))
      .then((res) => {
        if (res.authorities.find((a) => a === 'ROLE_ADMIN')) {
          return res;
        }
        throw new Error('not admin');
      })
      .then((token) => AppAuthToken.storeAuthData(token))
      .then(() => this.getUserData());
  }

  async getUserData(): Promise<Athlete> {
    const res = await HttpBackend.get('/athlete/me');
    AppStore.store('athlete', res);
    return new Athlete(res);
  }

  async checkToken(): Promise<Athlete> {
    const token = await AppAuthToken.getAuthData();
    const headers = {};

    if (token && token.access_token) {
      const response = await new Backend(undefined, undefined, Config.backend.authUrl).postForm(
        '/uaa/oauth/check_token',
        { token: token.access_token },
        { headers },
      );
      if (!response) {
        AppAuthToken.deleteAuthData();
        AppStore.truncate();
        throw new Error('no auth data available');
      }
      return this.getUserData();
    }
    AppAuthToken.deleteAuthData();
    AppStore.truncate();
    throw new Error('no auth data available');
  }

  logout() {
    AppAuthToken.deleteAuthData();
    AppStore.truncate();
  }
}

const instance = new AuthenticationService();
export default instance;
