/**
 *
 * Created by neo on 13.03.17.
 */
import {
  toJS,
  action,
  computed,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  IReactionDisposer,
  reaction,
  runInAction,
} from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { PageResult } from '../PageResult';
import { LocalizedValue } from '../LocalizedValue';
import { v4 as UUID } from 'uuid';
import { BodyPartPosition } from './BodyPartPosition';
import { Media } from '../Media/Media';
import { ExerciseVariationAdmin } from '../Exercise/Admin/ExerciseVariationAdmin';
import { LocalizedArrayEntity } from '../LocalizedArrayEntity';

export type BodyPartType = 'MUSCLE' | 'SINEW' | 'BONE';
export type BodyPartSize = 'tiny' | 'small' | 'medium' | 'large' | 'huge';

const bodyPartSizes = ['tiny', 'small', 'medium', 'large', 'huge'];

export class BodyPartRegionAdmin extends LocalizedArrayEntity {
  @observable
  parentIds: Array<string> = [];
  @observable
  type: BodyPartType = 'MUSCLE';
  @observable
  size: BodyPartSize = 'medium';
  @observable
  childrenIds: Array<string> = [];
  @observable
  position?: BodyPartPosition = undefined;
  @observable
  identifier = '';
  @observable
  antagonistIds: Array<string> = [];
  @observable
  latinName: string = '';
  @observable
  medias: Array<any> = [];
  @observable
  hide = true;
  @observable
  parents?: BodyPartRegionAdmin[] = undefined;
  @observable
  children?: BodyPartRegionAdmin[] = undefined;
  @observable
  antagonists?: BodyPartRegionAdmin[] = undefined;
  @observable
  exerciseCount?: number = undefined;
  parentFetch?: IReactionDisposer;
  antagonistsFetch?: IReactionDisposer;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.identifier = json.identifier || '';
      this.antagonistIds = json.antagonistIds || [];
      this.childrenIds = json.childrenIds || [];
      this.position = json.position ? new BodyPartPosition(json.position) : undefined;
      this.parentIds = json.parentIds || [];
      this.childrenIds = json.childrenIds || [];
      this.position = json.position ? new BodyPartPosition(json.position) : undefined;
      this.latinName = json.latinName;
      this.hide = !!json.hide;
      this.type = json.type || 'MUSCLE';
      this.size = json.size || 'medium';
      this.medias = (json.medias || []).map((m) => new Media(m));
    }

    onBecomeObserved(this, 'children', this.fetchChildren);
    onBecomeObserved(this, 'parents', this.startFetchParents);
    onBecomeUnobserved(this, 'parents', this.stopFetchParents);
    onBecomeObserved(this, 'antagonists', this.startFetchAntagonists);
    onBecomeUnobserved(this, 'antagonists', this.stopFetchAntagonists);
    onBecomeObserved(this, 'exerciseCount', this.fetchExerciseCount);
  }

  fetchChildren = async () => {
    if (!this.children) {
      this.children = await BodyPartRegionAdmin.getAll(this.childrenIds);
    }
    return this.children;
  };

  startFetchParents = () => {
    this.parentFetch = reaction(
      () => this.parentIds.map((p) => p),
      (parentIds) => this.fetchParents(),
      { fireImmediately: true },
    );
  };

  stopFetchParents = () => {
    this.parentFetch && this.parentFetch();
  };

  startFetchAntagonists = () => {
    this.antagonistsFetch = reaction(
      () => this.antagonistIds.map((p) => p),
      (parentIds) => this.fetchAntagonists(),
      { fireImmediately: true },
    );
  };

  stopFetchAntagonists = () => {
    this.antagonistsFetch && this.antagonistsFetch();
  };

  @action
  fetchParents() {
    if (this.parentIds.length > 0) {
      BodyPartRegionAdmin.getAll(this.parentIds).then((result) => runInAction(() => (this.parents = result)));
    } else {
      this.parents = [];
    }
    return this.parents;
  }

  @action
  fetchAntagonists() {
    if (this.antagonistIds.length > 0) {
      BodyPartRegionAdmin.getAll(this.antagonistIds).then((result) => runInAction(() => (this.antagonists = result)));
    } else {
      this.antagonists = [];
    }
    return this.antagonists;
  }

  fetchExerciseCount = async () => {
    if (this.exerciseCount === undefined) {
      this.exerciseCount = await ExerciseVariationAdmin.count({ bodyPartIds: this.id });
    }
    return this.exerciseCount;
  };

  toJS(): any {
    return Object.assign(super.toJS(), {
      id: this.id,
      identifier: this.identifier,
      parentIds: toJS(this.parentIds),
      childrenIds: toJS(this.childrenIds),
      antagonistIds: toJS(this.antagonistIds),
      size: this.size,
      position: this.position?.toJS(),
      name: toJS(this.name),
      description: toJS(this.description),
      latinName: this.latinName,
      type: this.type,
      medias: toJS(this.medias),
      hide: this.hide,
    });
  }

  @computed
  get _isNew(): boolean {
    return !this.id;
  }

  @action
  remove() {
    return HttpBackend.delete(`/exercise/bodypart/admin/${this.id}`);
  }

  @action
  async save() {
    return HttpBackend.post('/exercise/bodypart/admin', this.toJS()).then(() => this);
  }

  @action
  setParent(parent?: BodyPartRegionAdmin) {
    // if (this.parents) {
    //   const prevIndex = this.parents.childrenIds.findIndex((b) => b === this.id);
    //   if (-1 !== prevIndex) {
    //     this.parents.childrenIds.splice(prevIndex, 1);
    //   }
    // }
    if (parent) {
      if (-1 === parent.childrenIds.findIndex((b) => b === this.id)) {
        this.childrenIds.push(parent.id);
      }
      this.parentIds.push(parent?.id);
    }
    this.fetchParents();
  }

  @computed
  get sortIndex(): number {
    return bodyPartSizes.indexOf(this.size ?? 'medium');
  }

  @computed
  get sortIndexDesc(): number {
    return bodyPartSizes.reverse().indexOf(this.size ?? 'medium');
  }

  static async get(id: string): Promise<BodyPartRegionAdmin | undefined> {
    const res = await HttpBackend.get(`/exercise/bodypart/admin/${id}`);
    if (res) {
      return new BodyPartRegionAdmin(res);
    }
    return undefined;
  }

  static async getAll(ids: Array<string>): Promise<BodyPartRegionAdmin[]> {
    if (ids.length > 0) {
      const data = await Promise.all(ids.map((id) => BodyPartRegionAdmin.get(id)));
      return data.filter((b) => !!b) as Array<BodyPartRegionAdmin>;
    }
    return [];
  }

  static async list(): Promise<BodyPartRegionAdmin[]> {
    return HttpBackend.get(`/exercise/bodypart/admin/list`).then((result) =>
      (result || []).map((b) => new BodyPartRegionAdmin(b)),
    );
  }

  static async find(params: any = { page: 0, sort: 'name,ASC' }): Promise<BodyPartRegionAdmin[]> {
    params.edit = true;
    return HttpBackend.get(`/exercise/bodypart/admin`, params).then((res) =>
      (res ?? []).map((r) => new BodyPartRegionAdmin(r)),
    );
  }
}
