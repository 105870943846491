/**
 * Created by neo on 01.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Exercise } from '../../../../../Model/Exercise/Exercise';
import { runInAction } from 'mobx';
import AsyncSelect from 'react-select/async';

export type ExerciseHarderAlternativeInputProps = {
  exercise: ExerciseVariationAdmin;
};

export const ExerciseHarderAlternativeInput: React.FC<ExerciseHarderAlternativeInputProps> = observer(
  ({ exercise }) => {
    const handleChange = React.useCallback(
      async (tags?: Array<Exercise>) => {
        console.log('handleChange', tags);
        runInAction(() => (exercise.harderAlternativeIds = (tags ?? []).map((e) => e.id)));
      },
      [exercise],
    );

    const fetch = React.useCallback(
      (query?: string) =>
        Exercise.find({
          query,
          size: 30,
        }),
      [],
    );

    const optionLabel = React.useCallback(
      (option: Exercise) => `${option.name} (${option.equipmentTypes.join(', ')})`,
      [],
    );

    const optionValue = React.useCallback((option: Exercise) => option.id, []);

    return (
      <AsyncSelect
        value={exercise.harderAlternatives ?? []}
        cacheOptions
        defaultOptions
        isClearable={true}
        loadOptions={fetch}
        isMulti={true}
        getOptionLabel={optionLabel}
        getOptionValue={optionValue}
        onChange={handleChange as any}
      />
    );
  },
);
