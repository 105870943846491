/**
 * Created by neo on 22.11.2023
 */
import { observable } from 'mobx';

export type RouteChallengeTeamReachedLocationJson = {
  locationId: string;
  dateTime: string;
  /**
   * How many points were required to reach the location
   */
  requiredPoints: number;
  /**
   * Points collected at time when location was reached
   */
  points: number;
  /**
   * Member that collected the points
   */
  memberId: string;
};

export class RouteChallengeTeamReachedLocation {
  @observable
  locationId: string = '';
  @observable
  dateTime = new Date();
  /**
   * How many points were required to reach the location
   */
  @observable
  requiredPoints: number = 0;
  /**
   * Points collected at time when location was reached
   */
  @observable
  points: number = 0;
  /**
   * Member that collected the points
   */
  @observable
  memberId: string = '';

  constructor(json?: Partial<RouteChallengeTeamReachedLocationJson>) {
    if (json) {
      this.locationId = json.locationId ?? '';
      this.dateTime = json.dateTime ? new Date(json.dateTime) : new Date();
      this.requiredPoints = json.requiredPoints ?? 0;
      this.points = json.points ?? 0;
      this.memberId = json.memberId ?? '';
    }
  }
}
