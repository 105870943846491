/**
 * Created by neo on 09.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { ExploreCategoryLink } from '../../../Model/Explore/ExploreCategoryLink';
import { Col, Row } from 'reactstrap';
import { Button, Input, InputNumber, Select } from 'antd';
import { runInAction } from 'mobx';

export type ExploreCategoryModalLinkListEditorEntryParamProps = {
  category: ExploreCategory;
  link: ExploreCategoryLink;
  paramKey: string;
};

export const ExploreCategoryModalLinkListEditorEntryParam: React.FC<ExploreCategoryModalLinkListEditorEntryParamProps> = observer(
  ({ category, link, paramKey }) => {
    const paramValue = link.params[paramKey];

    const handleChangeParamValueString = React.useCallback(
      ({ target: { value } }) => {
        runInAction(() => (link.params[paramKey] = value));
      },
      [paramKey, link],
    );

    const handleRemove = React.useCallback(() => {
      runInAction(() => {
        delete link.params[paramKey];
      });
    }, [link, paramKey]);

    return (
      <Row>
        <Col>
          <Input value={paramKey} disabled />
        </Col>
        <Col>
          <Input value={paramValue} onChange={handleChangeParamValueString} />
        </Col>
        <Col xs="auto">
          <Button danger onClick={handleRemove}>
            Remove
          </Button>
        </Col>
      </Row>
    );
  },
);
