import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { PipelineStage } from '../../../../../../../Model/Coach/Stages/PipelineStage';

export class PipelineNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: PipelineStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'pipeline' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
