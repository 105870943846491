/**
 * Created by andreaskarantzas on 06.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import { runInAction } from 'mobx';
import { ExploreEntryModalMediaField } from '../ExploreEntryModalMediaField';
import { Media } from '../../../../Model/Media/Media';
import { Form, Select } from 'antd';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ExploreEntryOrientation } from '../../../../Model/Explore/ExploreEntryOrientation';

export type ExploreEntryModalVideoImageFieldsProps = {
  entry: ExploreEntry;
  onClickMedia?: (media: Media) => void;
};

export const ExploreEntryModalVideoImageFields: React.FC<ExploreEntryModalVideoImageFieldsProps> = observer(
  ({ entry, onClickMedia }) => {
    const canHaveBackgroundImage = entry.type === 'meditation' || !!entry.backgroundImage;
    const canHaveBackgroundVideo = entry.type === 'meditation' || entry.backgroundVideos.length > 0;

    const handleImageUploaded = React.useCallback(
      (media: Media) => {
        runInAction(() => entry.images.push(media));
      },
      [entry],
    );

    const handleBackgroundImageUploaded = React.useCallback(
      (media: Media) => {
        runInAction(() => (entry.backgroundImage = media));
      },
      [entry],
    );

    const handleVideoUploaded = React.useCallback(
      (media: Media) => {
        runInAction(() => entry.videos.push(media));
      },
      [entry],
    );

    const handleBackgroundVideoUploaded = React.useCallback(
      (media: Media) => {
        runInAction(() => entry.backgroundVideos.push(media));
      },
      [entry],
    );

    const handleRemoveImage = React.useCallback(
      (media: Media) => {
        runInAction(() => (entry.images = entry.images.filter((v) => v.id !== media.id)));
      },
      [entry],
    );

    const handleRemoveBackgroundImage = React.useCallback(() => {
      runInAction(() => (entry.backgroundImage = undefined));
    }, [entry]);

    const handleRemoveVideo = React.useCallback(
      (media: Media) => {
        runInAction(() => (entry.videos = entry.videos.filter((v) => v.id !== media.id)));
      },
      [entry],
    );

    const handleRemoveBackgroundVideo = React.useCallback(
      (media: Media) => {
        runInAction(() => (entry.backgroundVideos = entry.backgroundVideos.filter((v) => v.id !== media.id)));
      },
      [entry],
    );

    const handleClickMedia = React.useCallback(
      (media: Media) => {
        onClickMedia?.(media);
      },
      [onClickMedia],
    );

    const handleChangeOrientation = React.useCallback(
      (orientation: string) => runInAction(() => (entry.orientation = orientation as ExploreEntryOrientation)),
      [entry],
    );

    return (
      <React.Fragment>
        <Row>
          <Col md={12}>
            <Form.Item
              label="Orientation"
              extra="Sets how the media are displayed in the header of the ExploreModal and when shown as full_width"
            >
              <Select value={entry.orientation} onChange={handleChangeOrientation}>
                <Select.Option value="landscape">Landscape</Select.Option>
                <Select.Option value="portrait">Portrait</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Images">
              <div style={{ display: 'flex' }}>
                {([undefined] as any).concat(entry.images).map((media) => (
                  <div key={media?.id} style={{ marginLeft: 8 }}>
                    <ExploreEntryModalMediaField
                      onRemove={handleRemoveImage}
                      onUploaded={handleImageUploaded}
                      onClick={handleClickMedia}
                      media={media}
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
          </Col>
          {canHaveBackgroundImage && (
            <Col md={12}>
              <ExploreEntryModalMediaField
                label="Background Image"
                onRemove={handleRemoveBackgroundImage}
                onUploaded={handleBackgroundImageUploaded}
                media={entry.backgroundImage}
              />
            </Col>
          )}
          <Col md={12}>
            <Form.Item label="Videos">
              <div style={{ display: 'flex', overflowX: 'scroll' }}>
                {([undefined] as any).concat(entry.videos).map((video) => (
                  <div key={video?.id} style={{ marginLeft: 8 }}>
                    <ExploreEntryModalMediaField
                      onRemove={handleRemoveVideo}
                      onUploaded={handleVideoUploaded}
                      onClick={handleClickMedia}
                      media={video}
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
          </Col>
          {canHaveBackgroundVideo && (
            <Col md={12}>
              <Form.Item label="Background Videos">
                <div style={{ display: 'flex', overflowX: 'scroll' }}>
                  {([undefined] as any).concat(entry.backgroundVideos).map((video) => (
                    <div key={video?.id} style={{ marginLeft: 8 }}>
                      <ExploreEntryModalMediaField
                        onRemove={handleRemoveBackgroundVideo}
                        onUploaded={handleBackgroundVideoUploaded}
                        onClick={handleClickMedia}
                        media={video}
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
            </Col>
          )}
        </Row>
      </React.Fragment>
    );
  },
);
