/**
 * Created by neo on 09.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Break } from 'mdast-util-from-markdown/lib';

export type MarkdownBreakRendererProps = {
  content: Break;
};

export const MarkdownBreakRenderer: React.FC<MarkdownBreakRendererProps> = observer((props) => {
  return null;
});
