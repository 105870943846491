import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { ConcatModel } from './ConcatModel';
import { ConcatWidget } from './ConcatWidget';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { ConcatStage } from '../../../../../../../Model/Coach/Stages/ConcatStage';

export class ConcatFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('concat');
  }

  generateReactWidget(event): JSX.Element {
    return <ConcatWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new ConcatModel(new ConcatStage(new Pipeline()));
  }
}
