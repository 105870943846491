/**
 * Created by neo on 18.01.17.
 */
import { observable, computed, action, ObservableMap, onBecomeObserved, runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedValue } from '../../../LocalizedValue';
import { CoachExerciseBlock } from './CoachExerciseBlock';
import { languagePriority } from '../../../LocalizedArrayEntity';
import { CoachWorkout } from './CoachWorkout';
import { Exercise } from '../../../Exercise/Exercise';
import { Pipeline } from '../../Pipeline';

export class CoachWorkoutPhase {
  @observable phaseId: string = UUID();
  @observable
  scriptId: string = '';
  @observable type: string = 'strength';
  @observable
  description: Array<LocalizedValue> = [];
  @observable.shallow exerciseBlocks: Array<CoachExerciseBlock> = [];
  @observable options: ObservableMap<string> = observable.map({});
  @observable workout: CoachWorkout;
  @observable
  pipeline?: Pipeline = undefined;

  constructor(workout: CoachWorkout, json?: any) {
    this.workout = workout;
    if (json) {
      this.setData(json);
    }

    onBecomeObserved(this, 'pipeline', this.fetchPipeline);
  }

  fetchPipeline = () => {
    if (this.scriptId && !this.pipeline) {
      Pipeline.get(this.scriptId).then((pipeline) => runInAction(() => (this.pipeline = pipeline)));
    }
  };

  @action
  setBasicData(json: any) {
    this.phaseId = json.phaseId || UUID();
    this.scriptId = json.scriptId;
    this.type = json.type || 'strength';
    this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
    this.options = observable.map(json.options || {});
  }

  @action
  setData(json: any) {
    this.setBasicData(json);
    this.exerciseBlocks = (json.exerciseBlocks || [])
      .filter((b: any) => b && b.exercise && b.exercise.id)
      .map((b: any) => new CoachExerciseBlock(this, b));
  }

  toJS(newId?: boolean): any {
    return {
      phaseId: newId ? UUID() : this.phaseId,
      type: this.type,
      description: this.description.map((l) => l.toJS()),
      exerciseBlocks: this.exerciseBlocks.map((b) => b.toJS(newId)),
    };
  }

  hasExercise(exercise: Exercise, exerciseBlockId?: string): boolean {
    if (exerciseBlockId) {
      return !!this.exerciseBlocks.find((b) => b.exerciseBlockId === exerciseBlockId);
    }
    const index = this.exerciseBlocks.findIndex((b) => b.exercise.id === exercise.id);
    return index !== -1;
  }

  @computed
  get totalExercises(): number {
    return this.exerciseBlocks.length;
  }

  @computed
  get duration(): number {
    return this.exerciseBlocks.reduce((total: number, block: CoachExerciseBlock) => total + block.duration, 0);
  }

  @computed
  get breakTime(): number {
    return this.exerciseBlocks.reduce((total: number, block: CoachExerciseBlock) => total + block.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.exerciseBlocks.reduce(
      (total: number, block: CoachExerciseBlock) => total + block.totalTimeExercising,
      0,
    );
  }

  @computed
  get totalSets(): number {
    return this.exerciseBlocks.reduce((total: number, b: CoachExerciseBlock) => total + b.sets.length, 0);
  }

  @computed
  get tons(): number {
    return this.exerciseBlocks.reduce((total: number, b: CoachExerciseBlock) => total + b.tons, 0);
  }

  @computed
  get lastBlock(): CoachExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[length - 1];
    }
    return undefined;
  }

  @computed
  get firstBlock(): CoachExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[0];
    }
    return undefined;
  }

  @computed
  get prevPhase(): CoachWorkoutPhase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      if (phaseIndex > 0) {
        return this.workout.phases[phaseIndex - 1];
      }
    }
    return undefined;
  }

  @computed
  get nextPhase(): CoachWorkoutPhase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      const nextIndex = phaseIndex + 1;
      return nextIndex < this.workout.phases.length ? this.workout.phases[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get phaseIndex(): number {
    return this.workout ? this.workout.phases.findIndex((p) => p.phaseId === this.phaseId) : -1;
  }

  @computed
  get prevBlock(): CoachExerciseBlock | undefined {
    return this.lastBlock || (this.prevPhase ? this.prevPhase.prevBlock : undefined);
  }

  @computed
  get nextBlock(): CoachExerciseBlock | undefined {
    return this.firstBlock || (this.nextPhase ? this.nextPhase.nextBlock : undefined);
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.exerciseBlocks.reduce(
      (total: number, block: CoachExerciseBlock) => total + block.estimatedTotalExerciseTime,
      0,
    );
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }

  @computed
  get minDuration(): number {
    let totalTime = 0;
    const blocks = (this.exerciseBlocks || []).slice();
    for (const block of blocks) {
      totalTime += block.minDuration;
    }
    return totalTime;
  }

  @computed
  get maxDuration(): number {
    let totalTime = 0;
    const blocks = (this.exerciseBlocks || []).slice();
    for (const block of blocks) {
      totalTime += block.maxDuration;
    }
    return totalTime;
  }

  calculateCalories(bmr24: number): number {
    return this.exerciseBlocks.reduce((total: number, b: CoachExerciseBlock) => total + b.calculateCalories(bmr24), 0);
  }
}
