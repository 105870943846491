/**
 * Created by neo on 28.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Stage } from '../../../../Model/Coach/Stages/Stage';
import { FetchStage } from '../../../../Model/Coach/Stages/FetchStage';
import { FetchStageEditor } from './Editors/FetchStageEditor';
import { PipelineContext } from '../../../../Model/Coach/PipelineContext';
import { PipelineStage } from '../../../../Model/Coach/Stages/PipelineStage';
import { PipelineStageEditor } from './Editors/PipelineStageEditor';

export type StageEditorContentProps = {
  stage: Stage<any>;
  context?: PipelineContext;
  onExecute?: (stageId: string) => any;
};

export const StageEditorContent: React.FC<StageEditorContentProps> = observer(
  ({ stage, context, onExecute }: StageEditorContentProps) => {
    if (stage instanceof FetchStage) {
      return <FetchStageEditor stage={stage} context={context} />;
    } else if (stage instanceof PipelineStage) {
      return <PipelineStageEditor stage={stage} context={context} />;
    }
    return null;
  },
);
