/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { DatePicker, Form } from 'antd';
import { Gym } from '../../../Model/Gym/Gym';
import { ActivityConversionChart } from './ActivityConversionChart';
import { allWorkoutConversion, gymWorkoutConversion } from './Queries/queryActivityConversion';

export type WorkoutConversionProps = {
  gym?: Gym;
  workoutType?: string;
  title?: string;
};

export const WorkoutConversion: React.FC<WorkoutConversionProps> = observer(
  ({ gym, workoutType, title = 'All Workouts Conversion Daily' }) => {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [data, setData] = useState<AnalyticsRow[] | undefined>();

    useEffect(() => {
      setData(undefined);
      AnalyticsData.query({
        query: gym ? gymWorkoutConversion(workoutType) : allWorkoutConversion(workoutType),
        parameters: Object.assign(
          {
            start_date: {
              value: startDate.format('YYYYMMDD'),
            },
            end_date: {
              value: endDate.format('YYYYMMDD'),
            },
          },
          gym
            ? {
                gym_id: {
                  value: gym?.id,
                },
              }
            : {},
        ) as any,
      }).then((data) =>
        setData(
          data.map((d) =>
            Array.from(Object.entries(d))
              .map(([key, value]) => [key, key === 'event_date' ? value : Number(value)])
              .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
          ),
        ),
      );
    }, [gym, startDate, endDate, workoutType]);

    const handleChangeValidFrom = React.useCallback(
      (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
      [],
    );

    const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

    return (
      <Container>
        <SingleColRow>
          <h5>{title}</h5>
        </SingleColRow>
        <Row>
          <Col xs={6}>
            <Form.Item label="Start Date">
              <DatePicker value={startDate} onChange={handleChangeValidFrom} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeValidUntil} />
            </Form.Item>
          </Col>
          <Col xs={12}>{data && <ActivityConversionChart data={data} />}</Col>
        </Row>
      </Container>
    );
  },
);
