/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Tag, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import { reaction, runInAction } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';
import { InstructorEdit } from './InstructorEdit';
import { Instructor } from '../../../../../Model/Explore/Instructor';
import { Timezones } from '../../../../../Utils/Timezones';
import { availableLanguages } from '../../../TextToSpeech/CoachTtsEditModal/CoachTtsEditModal';
import { OfflineMeetingEntry } from '../../../../../Model/Explore/OfflineMeetingEntry';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { FormField, FormFieldType } from '../../../../../Model/Explore/FormField';

export type OfflineMeetingEntryContentProps = {
  entry: OfflineMeetingEntry;
};

export const OfflineMeetingEntryContent: React.FC<OfflineMeetingEntryContentProps> = observer(({ entry }) => {
  const [place, setPlace] = useState<any>();
  const [map, setMap] = useState<any>();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC3dEyEoFwx8oPo57ZSL3fMhUel_fRCoYs',
  });

  const center = React.useMemo(() => (place?.value ? place.value.geometry.location : undefined), [place]);

  useEffect(
    () =>
      reaction(
        () => entry.location.address,
        (address) =>
          geocodeByAddress(address).then((res) => {
            console.log('places', res[0]);
            if (res[0]) {
              setPlace({
                label: res[0].formatted_address,
                value: res[0],
              });
            }
          }),
        { fireImmediately: true },
      ),
    [entry],
  );

  const handleChangeLanguage = React.useCallback(
    (lang) => {
      runInAction(() => (entry.language = lang));
    },
    [entry],
  );

  const handleChangeStartDate = React.useCallback(
    (date) => 
      runInAction(() => (entry.startDate = date?.format('YYYY-MM-DD') ?? dayjs().add(1, 'day').format('YYYY-MM-DD'))),
    [entry],
  );

  const handleChangeStartTime = React.useCallback(
    (date, dateString) => runInAction(() => (entry.startTime = dateString ?? entry.startTime)),
    [entry],
  );

  const handleChangeTimezone = React.useCallback(
    (timezone: string) => runInAction(() => (entry.timezone = timezone)),
    [entry],
  );

  const transformToUtc = (newDate: Dayjs) => dayjs(0).hour(newDate.hour()).minute(newDate.minute());

  const handleChangeDuration = React.useCallback(
    (date, dateString) => {
      if (date) {
        runInAction(() => (entry.duration = dayjs.duration(transformToUtc(date).diff(dayjs(0))).toISOString()));
      }
    },
    [entry],
  );

  const handleChangeRegisterUntil = React.useCallback(
    (date) => runInAction(() => (entry.registerUntilDate = date?.toDate() ?? new Date())),
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Row>
            <Col xs={12} lg={2}>
              <Form.Item label="Meeting Date">
                <DatePicker
                  value={dayjs(entry.startDateTime?.toDate())}
                  disabledDate={(date) => !date.isAfter(dayjs().startOf('day'))}
                  onChange={handleChangeStartDate}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={2}>
              <Form.Item label="Meeting Time" extra={entry.localStartDateTime?.format('DD.MM.YYYY HH:mm')}>
                <TimePicker
                  value={dayjs(entry.startTime, 'HH:mm')}
                  showSecond={false}
                  format="HH:mm"
                  onChange={handleChangeStartTime}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={2}>
              <Form.Item label="Meeting Duration">
                <TimePicker
                  value={dayjs.utc(entry.durationDayjs.toDate())}
                  showSecond={false}
                  format="HH:mm"
                  onChange={handleChangeDuration}
                  minuteStep={5}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={2}>
              <Form.Item label="Register Until">
                <DatePicker
                  disabledDate={(date) => date.isBefore(dayjs()) || date.isAfter(entry.startDateTime)}
                  value={dayjs.utc(entry.registerUntilDate)}
                  showTime={true}
                  showSecond={false}
                  format="DD.MM.YYYY HH:mm"
                  onChange={handleChangeRegisterUntil}
                  minuteStep={5}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={3} lg={2}>
              <Form.Item label="Timezone">
                <Select value={entry.timezone} onChange={handleChangeTimezone}>
                  {Timezones.map((v) => (
                    <Select.Option key={v} value={v}>
                      {v}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={3} lg={2}>
              <Form.Item label="Language">
                <Select value={entry.language} onChange={handleChangeLanguage}>
                  {availableLanguages.map(({ label, value }) => (
                    <Select.Option key={value} value={value}>
                      {`${label} (${value})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="Min. required participants">
                <InputNumber
                  value={entry.minParticipants}
                  onChange={(e) => runInAction(() => (entry.minParticipants = e ?? 1))}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="Max. possible participants">
                <InputNumber
                  value={entry.maxParticipants}
                  onChange={(e) => runInAction(() => (entry.maxParticipants = e ?? -1))}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="Location Name">
                <Input
                  value={entry.location.name}
                  onChange={(e) => runInAction(() => (entry.location.name = e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="Location Address">
                <GooglePlacesAutocomplete
                  selectProps={{
                    value: place,
                    onChange: (e) => {
                      console.log('selected', e);
                      runInAction(() => (entry.location.address = (e as any).label));
                    },
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              {isLoaded && center && (
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: 360,
                  }}
                  center={center}
                  zoom={10}
                  onLoad={(map) => {
                    // This is just an example of getting and using the map instance!!! don't just blindly copy!
                    const bounds = new window.google.maps.LatLngBounds(center);
                    map.fitBounds(bounds);

                    setMap(map);
                  }}
                  onUnmount={() => setMap(undefined)}
                />
              )}
            </Col>
            <Col md={12}>
              <Form.Item label="Hosts">
                <Row>
                  {entry.hosts.map((host, index) => (
                    <Col md={3} key={index.toString()}>
                      <InstructorEdit
                        key={index}
                        index={index}
                        instructor={host}
                        onRemove={() => runInAction(() => entry.hosts.splice(index, 1))}
                      />
                    </Col>
                  ))}
                </Row>
                <Button type="primary" onClick={() => runInAction(() => entry.hosts.push(new Instructor()))}>
                  Add Host
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <h3>Registration Fields</h3>
            </Col>
            <Col md="auto">
              <Button type="primary" onClick={() => runInAction(() => entry.registrationFields.push(new FormField()))}>
                Add Field
              </Button>
            </Col>
          </Row>
          {entry.registrationFields.map((field, index) => (
            <Row key={index.toString()}>
              <Col>
                <Form.Item label="Identifier">
                  <Input
                    value={field.identifier}
                    onChange={(e) => runInAction(() => (field.identifier = e.target.value.toLowerCase().trim()))}
                  />
                </Form.Item>
                <Form.Item label="Field Type">
                  <Select
                    value={field.valueType}
                    onChange={(e) => runInAction(() => (field.valueType = e.toLowerCase().trim() as FormFieldType))}
                  >
                    <Select.Option value="numeric">Number</Select.Option>
                    <Select.Option value="string">Text</Select.Option>
                    <Select.Option value="email">E-Mail</Select.Option>
                    <Select.Option value="date">Date</Select.Option>
                    <Select.Option value="datetime">Date & Time</Select.Option>
                    <Select.Option value="time">Time</Select.Option>
                    <Select.Option value="boolean">Boolean (Yes / No)</Select.Option>
                  </Select>
                </Form.Item>
                <Row>
                  <Col>
                    <Form.Item label="Size / Min Length / Min Value" extra="Optional">
                      <InputNumber
                        value={field.minSize}
                        onChange={(e) => runInAction(() => (field.minSize = e ? Number(e) : undefined))}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="Size / Max Length / Max Value" extra="Optional">
                      <InputNumber
                        value={field.minSize}
                        onChange={(e) => runInAction(() => (field.minSize = e ? Number(e) : undefined))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item label="Label">
                  <TranslationInputArray entity={field} field="label" />
                </Form.Item>
              </Col>
              <Col xs="auto">
                <Button
                  danger
                  size="small"
                  onClick={() => runInAction(() => entry.registrationFields.splice(index, 1))}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  );
});
