import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { GraphSortNodeModel } from './GraphSortNodeModel';
import { GraphSortNodeWidget } from './GraphSortNodeWidget';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { GraphSortingStage } from '../../../../../../../Model/Coach/Stages/GraphSortingStage';

export class GraphSortNodeModelFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('graphSort');
  }

  generateReactWidget(event): JSX.Element {
    return <GraphSortNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new GraphSortNodeModel(new GraphSortingStage(new Pipeline()));
  }
}
