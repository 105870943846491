/**
 * Created by neo on 21.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import { ChartColors } from '../ChartColors';

export type ScatterChartDataEntry = { [key: string]: number | string }[];

export type ScatterChartData = {
  [key: string]: ScatterChartDataEntry;
};

const parseDomain = (data: ScatterChartData, dataKey: string = 'value') => [
  0,
  Math.max.apply(
    null,
    Array.from(Object.entries(data)).map(([key, entry]) =>
      Math.max.apply(
        null,
        entry.map((entry) => {
          const value = entry[dataKey];
          return typeof value === 'number' ? value : 0;
        }),
      ),
    ),
  ),
];

const parseRange = (data: ScatterChartData, dataKey: string = 'value') => [
  Math.min.apply(
    null,
    Array.from(Object.entries(data)).map(([key, entry]) =>
      Math.min.apply(
        null,
        entry.map((entry) => {
          const value = entry[dataKey];
          return typeof value === 'number' ? value : 0;
        }),
      ),
    ),
  ),
  Math.max.apply(
    null,
    Array.from(Object.entries(data)).map(([key, entry]) =>
      Math.max.apply(
        null,
        entry.map((entry) => {
          const value = entry[dataKey];
          return typeof value === 'number' ? value : 0;
        }),
      ),
    ),
  ),
];

export type ActivityWeekDayHourStatsChartProps = {
  data: ScatterChartData;
  categoryKey?: string;
  dataKey?: string;
  color?: string;
};

export const ActivityWeekDayHourStatsChart: React.FC<ActivityWeekDayHourStatsChartProps> = observer(
  ({ data, categoryKey = 'hour', dataKey = 'value', color = ChartColors[0] }) => {
    const yAxis = Array.from(Object.keys(data));

    const domain = parseDomain(data, dataKey);
    const range = parseRange(data, dataKey);

    console.log('ActivityWeekDayHourStatsChart', data, categoryKey, dataKey, yAxis, domain, range);

    const renderTooltip = React.useCallback(
      (props) => {
        const { active, payload } = props;

        if (active && payload && payload.length) {
          const data = payload[0] && payload[0].payload;

          return (
            <div
              style={{
                backgroundColor: '#fff',
                border: '1px solid #999',
                margin: 0,
                padding: 10,
              }}
            >
              <p>{data[categoryKey]}</p>
              <p>
                <span>value: </span>
                {data[dataKey]}
              </p>
            </div>
          );
        }

        return null;
      },
      [categoryKey, dataKey],
    );

    return (
      <React.Fragment>
        {yAxis.map((yAxisKey, index) => (
          <ResponsiveContainer key={yAxisKey} width="100%" height={60}>
            <ScatterChart
              height={60}
              margin={{
                top: 10,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <XAxis
                type="category"
                dataKey={categoryKey}
                interval={0}
                tick={index < yAxis.length - 1 ? { fontSize: 0 } : undefined}
                tickLine={{ transform: 'translate(0, -6)' }}
              />
              <YAxis
                type="number"
                dataKey="index"
                name={yAxisKey}
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{ value: yAxisKey, position: 'insideRight' }}
              />
              <ZAxis type="number" dataKey={dataKey} domain={domain} range={range} />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={renderTooltip} />
              <Scatter data={data[yAxisKey]} fill={color} />
            </ScatterChart>
          </ResponsiveContainer>
        ))}
        {/*<ResponsiveContainer width="100%" height={60}>*/}
        {/*  <ScatterChart*/}
        {/*    width={800}*/}
        {/*    height={60}*/}
        {/*    margin={{*/}
        {/*      top: 10,*/}
        {/*      right: 0,*/}
        {/*      bottom: 0,*/}
        {/*      left: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <XAxis*/}
        {/*      type="category"*/}
        {/*      dataKey="hour"*/}
        {/*      interval={0}*/}
        {/*      tick={{ fontSize: 0 }}*/}
        {/*      tickLine={{ transform: 'translate(0, -6)' }}*/}
        {/*    />*/}
        {/*    <YAxis*/}
        {/*      type="number"*/}
        {/*      dataKey="index"*/}
        {/*      name="sunday"*/}
        {/*      height={10}*/}
        {/*      width={80}*/}
        {/*      tick={false}*/}
        {/*      tickLine={false}*/}
        {/*      axisLine={false}*/}
        {/*      label={{ value: 'Sunday', position: 'insideRight' }}*/}
        {/*    />*/}
        {/*    <ZAxis type="number" dataKey="value" domain={domain} range={range} />*/}
        {/*    <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />*/}
        {/*    <Scatter data={data01} fill="#8884d8" />*/}
        {/*  </ScatterChart>*/}
        {/*</ResponsiveContainer>*/}

        {/*<ResponsiveContainer width="100%" height={60}>*/}
        {/*  <ScatterChart*/}
        {/*    width={800}*/}
        {/*    height={60}*/}
        {/*    margin={{*/}
        {/*      top: 10,*/}
        {/*      right: 0,*/}
        {/*      bottom: 0,*/}
        {/*      left: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <XAxis*/}
        {/*      type="category"*/}
        {/*      dataKey="hour"*/}
        {/*      name="hour"*/}
        {/*      interval={0}*/}
        {/*      tick={{ fontSize: 0 }}*/}
        {/*      tickLine={{ transform: 'translate(0, -6)' }}*/}
        {/*    />*/}
        {/*    <YAxis*/}
        {/*      type="number"*/}
        {/*      dataKey="index"*/}
        {/*      height={10}*/}
        {/*      width={80}*/}
        {/*      tick={false}*/}
        {/*      tickLine={false}*/}
        {/*      axisLine={false}*/}
        {/*      label={{ value: 'Monday', position: 'insideRight' }}*/}
        {/*    />*/}
        {/*    <ZAxis type="number" dataKey="value" domain={domain} range={range} />*/}
        {/*    <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />*/}
        {/*    <Scatter data={data02} fill="#8884d8" />*/}
        {/*  </ScatterChart>*/}
        {/*</ResponsiveContainer>*/}

        {/*<ResponsiveContainer width="100%" height={60}>*/}
        {/*  <ScatterChart*/}
        {/*    width={800}*/}
        {/*    height={60}*/}
        {/*    margin={{*/}
        {/*      top: 10,*/}
        {/*      right: 0,*/}
        {/*      bottom: 0,*/}
        {/*      left: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <XAxis*/}
        {/*      type="category"*/}
        {/*      dataKey="hour"*/}
        {/*      name="hour"*/}
        {/*      interval={0}*/}
        {/*      tick={{ fontSize: 0 }}*/}
        {/*      tickLine={{ transform: 'translate(0, -6)' }}*/}
        {/*    />*/}
        {/*    <YAxis*/}
        {/*      type="number"*/}
        {/*      dataKey="index"*/}
        {/*      height={10}*/}
        {/*      width={80}*/}
        {/*      tick={false}*/}
        {/*      tickLine={false}*/}
        {/*      axisLine={false}*/}
        {/*      label={{ value: 'Tuesday', position: 'insideRight' }}*/}
        {/*    />*/}
        {/*    <ZAxis type="number" dataKey="value" domain={domain} range={range} />*/}
        {/*    <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />*/}
        {/*    <Scatter data={data01} fill="#8884d8" />*/}
        {/*  </ScatterChart>*/}
        {/*</ResponsiveContainer>*/}

        {/*<ResponsiveContainer width="100%" height={60}>*/}
        {/*  <ScatterChart*/}
        {/*    width={800}*/}
        {/*    height={60}*/}
        {/*    margin={{*/}
        {/*      top: 10,*/}
        {/*      right: 0,*/}
        {/*      bottom: 0,*/}
        {/*      left: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <XAxis*/}
        {/*      type="category"*/}
        {/*      dataKey="hour"*/}
        {/*      name="hour"*/}
        {/*      interval={0}*/}
        {/*      tick={{ fontSize: 0 }}*/}
        {/*      tickLine={{ transform: 'translate(0, -6)' }}*/}
        {/*    />*/}
        {/*    <YAxis*/}
        {/*      type="number"*/}
        {/*      dataKey="index"*/}
        {/*      height={10}*/}
        {/*      width={80}*/}
        {/*      tick={false}*/}
        {/*      tickLine={false}*/}
        {/*      axisLine={false}*/}
        {/*      label={{ value: 'Wednesday', position: 'insideRight' }}*/}
        {/*    />*/}
        {/*    <ZAxis type="number" dataKey="value" domain={domain} range={range} />*/}
        {/*    <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />*/}
        {/*    <Scatter data={data02} fill="#8884d8" />*/}
        {/*  </ScatterChart>*/}
        {/*</ResponsiveContainer>*/}

        {/*<ResponsiveContainer width="100%" height={60}>*/}
        {/*  <ScatterChart*/}
        {/*    width={800}*/}
        {/*    height={60}*/}
        {/*    margin={{*/}
        {/*      top: 10,*/}
        {/*      right: 0,*/}
        {/*      bottom: 0,*/}
        {/*      left: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <XAxis*/}
        {/*      type="category"*/}
        {/*      dataKey="hour"*/}
        {/*      name="hour"*/}
        {/*      interval={0}*/}
        {/*      tick={{ fontSize: 0 }}*/}
        {/*      tickLine={{ transform: 'translate(0, -6)' }}*/}
        {/*    />*/}
        {/*    <YAxis*/}
        {/*      type="number"*/}
        {/*      dataKey="index"*/}
        {/*      height={10}*/}
        {/*      width={80}*/}
        {/*      tick={false}*/}
        {/*      tickLine={false}*/}
        {/*      axisLine={false}*/}
        {/*      label={{ value: 'Thursday', position: 'insideRight' }}*/}
        {/*    />*/}
        {/*    <ZAxis type="number" dataKey="value" domain={domain} range={range} />*/}
        {/*    <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />*/}
        {/*    <Scatter data={data01} fill="#8884d8" />*/}
        {/*  </ScatterChart>*/}
        {/*</ResponsiveContainer>*/}

        {/*<ResponsiveContainer width="100%" height={60}>*/}
        {/*  <ScatterChart*/}
        {/*    width={800}*/}
        {/*    height={60}*/}
        {/*    margin={{*/}
        {/*      top: 10,*/}
        {/*      right: 0,*/}
        {/*      bottom: 0,*/}
        {/*      left: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <XAxis*/}
        {/*      type="category"*/}
        {/*      dataKey="hour"*/}
        {/*      name="hour"*/}
        {/*      interval={0}*/}
        {/*      tick={{ fontSize: 0 }}*/}
        {/*      tickLine={{ transform: 'translate(0, -6)' }}*/}
        {/*    />*/}
        {/*    <YAxis*/}
        {/*      type="number"*/}
        {/*      dataKey="index"*/}
        {/*      height={10}*/}
        {/*      width={80}*/}
        {/*      tick={false}*/}
        {/*      tickLine={false}*/}
        {/*      axisLine={false}*/}
        {/*      label={{ value: 'Friday', position: 'insideRight' }}*/}
        {/*    />*/}
        {/*    <ZAxis type="number" dataKey="value" domain={domain} range={range} />*/}
        {/*    <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />*/}
        {/*    <Scatter data={data02} fill="#8884d8" />*/}
        {/*  </ScatterChart>*/}
        {/*</ResponsiveContainer>*/}

        {/*<ResponsiveContainer width="100%" height={60}>*/}
        {/*  <ScatterChart*/}
        {/*    width={800}*/}
        {/*    height={60}*/}
        {/*    margin={{*/}
        {/*      top: 10,*/}
        {/*      right: 0,*/}
        {/*      bottom: 0,*/}
        {/*      left: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <XAxis type="category" dataKey="hour" name="hour" interval={0} tickLine={{ transform: 'translate(0, -6)' }} />*/}
        {/*    <YAxis*/}
        {/*      type="number"*/}
        {/*      dataKey="index"*/}
        {/*      height={10}*/}
        {/*      width={80}*/}
        {/*      tick={false}*/}
        {/*      tickLine={false}*/}
        {/*      axisLine={false}*/}
        {/*      label={{ value: 'Saturday', position: 'insideRight' }}*/}
        {/*    />*/}
        {/*    <ZAxis type="number" dataKey="value" domain={domain} range={range} />*/}
        {/*    <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />*/}
        {/*    <Scatter data={data01} fill="#8884d8" />*/}
        {/*  </ScatterChart>*/}
        {/*</ResponsiveContainer>*/}
      </React.Fragment>
    );
  },
);
