import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { SingleColRow } from '../../../Components/SingleColRow';
import { useEffect, useState } from 'react';
import { ActivityLog } from '../../../Model/Activity/ActivityLog';
import { ActivityLogTable } from './ActivityLogTable/ActivityLogTable';

export type ActivityLogListScreenProps = {};

export const ActivityLogListScreen: React.FC<ActivityLogListScreenProps> = observer(() => {
  const [page, setPage] = useState(0);
  const [result, setResult] = useState(new PageResult<ActivityLog>());

  useEffect(() => {
    PageResult.execute(
      ActivityLog.find({
        page,
        size: 50,
        sort: 'startDate,DESC',
      }),
      ActivityLog.count(),
      page,
      50,
    ).then((res) => setResult(res));
  }, [page]);

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'Activity Logs'}</h1>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <ActivityLogTable activityLogs={result.content.slice()} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
