import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Activity } from '../../../Model/Activity/Activity';
import { TableRowMedia } from '../../../Components/TableRowMedia';
import { Badge } from 'reactstrap';

export interface ActivityListTableRowProps {
  activity: Activity;
}

@observer
export class ActivityListTableRow extends React.Component<ActivityListTableRowProps> {
  render() {
    const {
      props: { activity },
    } = this;
    return (
      <tr>
        <td>
          <TableRowMedia medias={(activity.medias || []).slice()} />
        </td>
        <td>
          <Link to={`/metadata/activity/${activity.id}`}>{activity.defaultName || '<no name>'}</Link>
        </td>
        <td>{activity.identifier}</td>
        <td>
          {activity.trackingKeys.map((tag: string) => (
            <Badge key={tag} color="primary">
              {tag}
            </Badge>
          ))}
        </td>
        <td>
          {activity.tags.map((tag: string) => (
            <Badge key={tag} color="info">
              {tag}
            </Badge>
          ))}
        </td>
      </tr>
    );
  }
}
