/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';
import styled from '@emotion/styled';
import { Input, Button, Space, Popconfirm, Select, Modal, message } from 'antd';
import { MesoCycleConfigurationTemplateEditor } from './MesoCycleConfigurationTemplateEditor';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { MesoCycleTypeSelect } from './MesoCycleTypeSelect';
import { useState } from 'react';
import { MesoCycleEditModal } from './MesoCycleEditModal';

export type MesoCycleEditorProps = {
  mesoCycle: SuperMacroMesoCycleTemplate;
  index: number;
  headerColor?: string;
  onRemove?: (mesoCycle: SuperMacroMesoCycleTemplate) => any;
};

const Container = styled.div`
  min-width: 400px;
  width: 49%;
  margin: 4px;
`;

const Body = styled.div``;

const Row = styled.div`
  display: flex;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.color || '#8fd14f'};
  padding: 4px;
  padding-top: 16px;
`;

const numbers = new Array(16).fill(0);

export const MesoCycleEditor: React.FC<MesoCycleEditorProps> = observer(
  ({ mesoCycle, headerColor, onRemove, index }: MesoCycleEditorProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const saveDebounce = React.useRef<NodeJS.Timeout | undefined>();

    const handleChangeName = React.useCallback(
      ({ target: { value } }) => {
        mesoCycle.templateName = value;
      },
      [mesoCycle],
    );

    const handleSave = React.useCallback(() => {
      saveDebounce.current && clearTimeout(saveDebounce.current);
      mesoCycle.save();
    }, [mesoCycle]);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(mesoCycle);
      saveDebounce.current && clearTimeout(saveDebounce.current);
    }, [mesoCycle, onRemove]);

    const handleChangeType = React.useCallback(() => {
      saveDebounce.current && clearTimeout(saveDebounce.current);
      saveDebounce.current = setTimeout(() => {
        mesoCycle.save();
      }, 500);
    }, [mesoCycle]);

    const handleChangeCycles = React.useCallback(
      (value) => {
        mesoCycle.minCycles = Number(value);
        saveDebounce.current && clearTimeout(saveDebounce.current);
        saveDebounce.current = setTimeout(() => {
          mesoCycle.save();
        }, 500);
      },
      [mesoCycle],
    );

    const handleEdit = React.useCallback(() => {
      setModalOpen(true);
    }, []);

    const handleCloseModal = React.useCallback(() => {
      mesoCycle
        .save()
        .then(() => {
          message.success('Meso Cycle saved');
        })
        .catch(() => message.error('Unable to save meso cycle'));
      setModalOpen(false);
    }, []);

    return (
      <Container>
        <Header color={headerColor}>
          <SingleColRow>
            <h6 style={{ textAlign: 'center' }}>{`Cycle ${index + 1}`}</h6>
          </SingleColRow>
          <Row>
            <div style={{ display: 'flex', flex: 1, paddingRight: 4 }}>
              <MesoCycleTypeSelect mesoCycle={mesoCycle} onChange={handleChangeType} />
            </div>
            <div style={{ display: 'flex', flex: 1, paddingLeft: 4, paddingRight: 4 }}>
              <Select style={{ flex: 1 }} value={mesoCycle.minCycles.toString()} onChange={handleChangeCycles}>
                {numbers.map((n, index) => (
                  <Select.Option key={index.toString()} value={`${index + 1}`}>{`${index + 1} Weeks`}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ display: 'flex', flex: 0 }}>
              <Space>
                <Button type="link" onClick={handleEdit}>
                  Edit
                </Button>
                <Popconfirm title={`Remove Meso Cycle ${mesoCycle.templateName}?`} onConfirm={handleRemove}>
                  <Button type="link" danger>
                    Remove
                  </Button>
                </Popconfirm>
              </Space>
            </div>
          </Row>
          {/*<Row>*/}
          {/*  <Input value={mesoCycle.templateName} onChange={handleChangeName} onBlur={handleSave} />*/}
          {/*</Row>*/}
        </Header>
        <Body>
          {mesoCycle.configurations.map((config) => (
            <MesoCycleConfigurationTemplateEditor key={config.id} mesoCycle={mesoCycle} configuration={config} />
          ))}
        </Body>
        {modalOpen ? <MesoCycleEditModal onClose={handleCloseModal} mesoCycle={mesoCycle} index={index} /> : null}
      </Container>
    );
  },
);
