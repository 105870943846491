/**
 * Created by neo on 12.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DomainEvent } from '../../../../../../Model/Axon/DomainEvent';
import { AxonQueryTableRow } from './AxonQueryTableRow';
import { Table } from 'reactstrap';

export type AxonQueryTableProps = {
  events: DomainEvent[];
  onFilter?: (query: string) => any;
};

export const AxonQueryTable: React.FC<AxonQueryTableProps> = observer(({ events, onFilter }: AxonQueryTableProps) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Token</th>
          {/*<th>Event ID</th>*/}
          <th>Aggregate ID</th>
          <th>Sequence</th>
          <th>Type</th>
          <th>Payload Type</th>
          <th>Revision</th>
          <th>Payload</th>
          <th>Time</th>
          <th>Meta Data</th>
        </tr>
      </thead>
      <tbody>
        {events.map((result: DomainEvent) => (
          <AxonQueryTableRow key={result.eventIdentifier} event={result} onFilter={onFilter} />
        ))}
      </tbody>
    </Table>
  );
});
