/**
 * Created by neo on 07.05.21.
 */
import { observable } from 'mobx';

export type VersionInformationJson = {
  minVersionIos: string;
  maxVersionIos: string;
  minVersionAndroid: string;
  maxVersionAndroid: string;
};

export class VersionInformation {
  @observable
  minVersionIos = '0.0.0';
  @observable
  maxVersionIos = '2147483647.2147483647.2147483647';
  @observable
  minVersionAndroid = '0.0.0';
  @observable
  maxVersionAndroid = '2147483647.2147483647.2147483647';

  constructor(json?: Partial<VersionInformationJson>) {
    if (json) {
      this.minVersionIos = json.minVersionIos || '0.0.0';
      this.maxVersionIos = json.maxVersionIos || '2147483647.2147483647.2147483647';
      this.minVersionAndroid = json.minVersionAndroid || '0.0.0';
      this.maxVersionAndroid = json.maxVersionAndroid || '2147483647.2147483647.2147483647';
    }
  }

  toJS(): VersionInformationJson {
    return {
      minVersionIos: this.minVersionIos,
      maxVersionIos: this.maxVersionIos,
      minVersionAndroid: this.minVersionAndroid,
      maxVersionAndroid: this.maxVersionAndroid,
    };
  }
}
