/**
 * Created by andreaskarantzas on 2019-02-11.
 */
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export class GymConfiguration {
  @observable id?: string = undefined;
  @observable configuration: Map<string, any> = new Map();
  @observable branchConfiguration: Map<string, any> = new Map();
  @observable created: number = 0;
  @observable updated: number = 0;
  @observable createdBy?: string = undefined;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.configuration = new Map(Object.entries(json.configuration || {}));
      this.branchConfiguration = new Map(Object.entries(json.branchConfiguration || {}));
      this.created = json.created;
      this.updated = json.updated;
      this.createdBy = json.createdBy;
    }
  }

  async save() {
    await HttpBackend.post('/gym/configuration', toJS(this));
  }

  static async find(gymId: string): Promise<GymConfiguration | undefined> {
    const res = await HttpBackend.get('/gym/configuration', { gymId });
    if (res) {
      return new GymConfiguration(res);
    }
    return undefined;
  }
}
