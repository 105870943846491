import { SnapshotEventPayload } from './SnapshotEventPayload';
import { observable } from 'mobx';

export class SnapshotEvent {
  @observable
  messageIdentifier: string = '';
  @observable
  aggregateIdentifier: string = '';
  @observable
  aggregateSequenceNumber: number = 0;
  @observable
  aggregateType: string = '';
  @observable
  timestamp: number = 0;
  @observable
  metaData: any;
  @observable
  payload: SnapshotEventPayload = new SnapshotEventPayload();

  constructor(json?: any) {
    if (json) {
      this.messageIdentifier = json.messageIdentifier;
      this.aggregateIdentifier = json.aggregateIdentifier;
      this.aggregateSequenceNumber = json.aggregateSequenceNumber;
      this.aggregateType = json.aggregateType;
      this.payload = new SnapshotEventPayload(json.payload);
      this.timestamp = json.timestamp;
      this.metaData = json.metaData;
    }
  }
}
