import { observable } from 'mobx';
import {
  MindfulnessProgramTemplateDayUnit,
  MindfulnessProgramTemplateDayUnitJson,
} from './MindfulnessProgramTemplateDayUnit';
import {
  MindfulnessProgramTemplateDayUnitVideo,
  MindfulnessProgramTemplateDayUnitVideoJson,
} from './MindfulnessProgramTemplateDayUnitVideo';

/**
 * Created by neo on 12.04.22.
 */

export type MindfulnessProgramTemplateDayUnitVideoSeriesJson = MindfulnessProgramTemplateDayUnitJson & {
  videos: MindfulnessProgramTemplateDayUnitVideoJson[];
};

export class MindfulnessProgramTemplateDayUnitVideoSeries extends MindfulnessProgramTemplateDayUnit {
  @observable
  videos: MindfulnessProgramTemplateDayUnitVideo[] = [];

  constructor(json?: Partial<MindfulnessProgramTemplateDayUnitVideoSeriesJson>) {
    super(Object.assign({}, json ?? {}, { type: 'videoSeries' }));
    if (json) {
      this.videos = (json.videos ?? []).map((a) => new MindfulnessProgramTemplateDayUnitVideo(a));
    }
  }

  toJS(newId: boolean = false): MindfulnessProgramTemplateDayUnitVideoSeriesJson {
    return Object.assign(super.toJS(newId), {
      videos: this.videos.map((a) => a.toJS()),
    });
  }
}
