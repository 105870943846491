/**
 * Created by neo on 30.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { WorkoutResponse } from '../../../../../Model/Coach/Script/WorkoutResponse';
import { CoachWorkoutView } from '../../../AthleteSuperMacro/View/CoachWorkoutView/CoachWorkoutView';
import dayjs, { Dayjs } from 'dayjs';
import { TestTableRowMedia } from './TestTableRowMedia';
import { Button } from 'antd';

export type TestTableRowProps = {
  workout: WorkoutResponse;
  index: number;
  onRetest?: (response: WorkoutResponse) => any;
};

export const TestTableRow: React.FC<TestTableRowProps> = observer((props: TestTableRowProps) => {
  const [show, setShow] = useState(false);
  const { workout, index, onRetest } = props;

  const handleToggleShow = React.useCallback(() => setShow((s) => !s), []);
  const durationDelta = workout.expectedMaxDuration - workout.maxDuration;
  const durationDeltaMin = workout.expectedMinDuration - workout.maxDuration;
  const durationErrorColor =  (Math.abs(durationDelta) > 300_000 ? 'red' : (Math.abs(durationDeltaMin) < 300_000 ? 'red': 'orange'))

  const handleRetest = React.useCallback(
    (e) => {
      e.stopPropagation();
      onRetest && onRetest(workout);
    },
    [onRetest, workout],
  );

  return (
    <React.Fragment>
      <tr onClick={handleToggleShow}>
        <td>
          <b>{index + 1}</b>
        </td>
        <td style={{ display: 'flex' }}>
          {workout.medias.map((media) => (
            <TestTableRowMedia key={media.id} media={media} />
          ))}
        </td>
        <td>
          {workout.validExercises ? (
            <React.Fragment>
              {workout.hasEmptyPhases ? (
                <b style={{ color: 'orange' }}>{workout.sortedTags.join(', ')}</b>
              ) : (
                workout.sortedTags.join(', ')
              )}
            </React.Fragment>
          ) : (
            <b style={{ color: 'red' }}>{workout.sortedTags.join(', ')}</b>
          )}
        </td>
        <td>
          {workout.durationValid ? (
            dayjs.utc(workout.maxDuration).format('HH:mm:ss')
          ) : (
            <React.Fragment>
              <b style={{ color: durationErrorColor }}>{dayjs.utc(workout.maxDuration).format('HH:mm:ss')}</b>
              <div style={{ fontSize: 12 }}>{durationDelta < 0 ? '+' : '-'}{dayjs.utc(Math.abs(durationDelta)).format('HH:mm:ss')}</div>
            </React.Fragment>
          )}
        </td>
        <td>
          <Button type="dashed" onClick={handleRetest}>
            Re-Calculate
          </Button>
        </td>
      </tr>
      {show ? (
        <tr>
          <td colSpan={5}>
            <CoachWorkoutView workout={workout} />
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  );
});
