/**
 *
 * Created by neo on 15.01.17.
 */

import { v4 as UUID } from 'uuid';
import { Media } from '../Model/Media/Media';
import { HttpBackend } from './Http/HttpBackend';

class MediaServiceClass {
  async uploadMedia(file: File, onUploadProgress?: (event: any) => any): Promise<Media> {
    const suffix = file.name.split('.').pop();
    const name = UUID();
    const finalName = `${name}.${suffix}`;

    const data = new FormData();
    data.append('name', finalName);
    data.append('file', file);

    return HttpBackend.upload('/media/media', data, {
      onUploadProgress,
      timeout: 3600000,
    }).then((data) => new Media(data));
  }

  async uploadMediaWithName(fileName: string, file: File, onUploadProgress?: (event: any) => any): Promise<Media> {
    const suffix = file.name.split('.').pop();
    const name = UUID();
    const finalName = fileName || `${name}.${suffix}`;

    const data = new FormData();
    data.append('name', finalName);
    data.append('file', file);

    return HttpBackend.upload('/media/media', data, {
      onUploadProgress,
      timeout: 3600000,
    }).then((data) => new Media(data));
  }

  async uploadRaw(fileName: string, fileData: Blob, onUploadProgress?: (event: any) => any): Promise<Media> {
    const suffix = fileName;
    const name = UUID();
    const finalName = `${name}.${suffix}`;

    const data = new FormData();
    data.append('name', finalName);
    data.append('file', fileData);
    return HttpBackend.upload('/media/media', data, {
      onUploadProgress,
      timeout: 3600000,
    }).then((data) => new Media(data));
  }
}

const MediaService = new MediaServiceClass();
export default MediaService;
