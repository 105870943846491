import { Stage } from './Stage';
import { EndOfStreamSignal } from '../Signal/EndOfStreamSignal';
import { toJS } from 'mobx';
import { EmptySignal } from '../Signal/EmptySignal';

export interface DuplicateStageConfig {
  count: string;
}

export class DuplicateStage extends Stage<DuplicateStageConfig> {
  process() {
    const { dataSignals } = this;
    const count = Math.max(this.resolveParamNumber(this.config.count), 1);
    dataSignals.forEach((signal) => {
      for (let i = 0; i < count; i++) {
        this.processNext(signal);
      }
    });
  }

  get type(): string {
    return 'duplicate';
  }
}
