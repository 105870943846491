/**
 * Created by neo on 26.10.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Button, Checkbox, Form, InputNumber } from 'antd';
import { Col, Row } from 'reactstrap';
import { GymEarnModelConfiguration } from '../../../../../Model/Engagement/Earn/GymEarnModelConfiguration';
import { runInAction } from 'mobx';
import { GymCoachSubscription } from '../../../../../Model/Subscription/GymCoachSubscription';

export type GymRewardsConfigurationTabProps = {
  gym: Gym;
};

export const GymRewardsConfigurationTab: React.FC<GymRewardsConfigurationTabProps> = observer(({ gym }) => {
  const [configuration, setConfiguration] = useState<GymEarnModelConfiguration | undefined>();
  const [subscription, setSubscription] = useState<GymCoachSubscription | undefined>();

  const disableEdit = subscription?.plan !== 'engagement';

  useEffect(() => {
    GymEarnModelConfiguration.findByGymId(gym.id).then((res) => res && setConfiguration(res));
  }, [gym]);

  useEffect(() => {
    GymCoachSubscription.current({ gymId: gym.id }).then((res) => setSubscription(res));
  }, [gym]);

  const handleChangeRewardsEnabled = ({ target: { checked } }) => {
    if (configuration) {
      runInAction(() => (configuration.rewardPointsCollectionEnabled = checked));
    }
  };

  const handleChangeFactor = React.useCallback(
    (value) => {
      if (configuration && Number(value)) {
        runInAction(() => (configuration.rewardPointsConversionFactor = Number(value)));
      }
    },
    [configuration],
  );

  const handleSave = () => {
    configuration?.save();
  };

  if (configuration) {
    return (
      <Form layout="vertical">
        <Row>
          <Col>
            <h4>Company Rewards Configuration</h4>
          </Col>
          <Col xs="auto">
            <Button type="primary" onClick={handleSave}>
              Save Configuration
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Item label="Rewards Enabled" extra="Can only be enabled if package engagement is set">
              <Checkbox
                checked={configuration.rewardPointsCollectionEnabled}
                onChange={handleChangeRewardsEnabled}
                disabled={disableEdit}
              >
                Rewards Enabled?
              </Checkbox>
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Points Conversion factor" extra="How many coins do you get for each health point?">
              <InputNumber
                value={configuration.rewardPointsConversionFactor}
                onChange={handleChangeFactor}
                min={0}
                max={10}
                disabled={disableEdit}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  return null;
});
