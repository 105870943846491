/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import { UserRole } from '../Model/UserRole';
import { ReactTags } from './ReactTags';

export type Props = {
  container: string[];
  label?: string;
  allowIncludes?: boolean;
  onRemove?: (index: number) => any;
};

interface TagEntry {
  id: string;
  role: UserRole;
  name: string;
}

@observer
export class UserRoleInput extends React.Component<Props> {
  @observable
  userRoles: TagEntry[] = [];
  debounce: any;

  static defaultProps = {
    label: 'Roles',
  };

  async loadEquipmentTypes(query: string) {
    UserRole.find({ query, sort: 'name,ASC', size: 6 }).then((result) =>
      runInAction(() => {
        this.userRoles = result.map((e) => ({
          id: e.fullName,
          role: e,
          name: e.fullName,
        }));
      }),
    );
  }

  handleInputChange = (input: string) => {
    // this.equipmentTypes = [];
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.loadEquipmentTypes(input);
    }, 300);
  };

  handleDelete = (index: number) => {
    this.props.container.splice(index, 1);
  };

  handleAddition = async (tag: TagEntry) => {
    if (this.props.allowIncludes) {
      const roles = (await Promise.all(tag.role.includes.map((r) => this.resolveRole(r))))
        .flatMap((r) => r)
        .filter((r: string, pos: number, self) => self.indexOf(r) === pos);
      roles
        .filter((r) => !this.props.container.find((r1) => r1 === r))
        .forEach((e: string) => this.props.container.push(e));
    }
    this.props.container.push(tag.role.id);
  };

  async resolveRole(role: string): Promise<Array<string>> {
    const userRole = await UserRole.get(role);
    if (userRole) {
      const roles = [userRole.id];
      const includes =
        userRole.includes.length > 0 ? await Promise.all(userRole.includes.map((r) => this.resolveRole(r))) : [];
      return roles.concat(includes.flatMap((r) => r));
    }
    return [];
  }

  @computed
  get tags(): any[] {
    return this.props.container.map((e) => ({ id: e, name: e }));
  }

  @computed
  get suggestions(): any[] {
    const { tags } = this;
    return this.userRoles.filter((e) => tags.findIndex((t) => t.id === e.role.id) === -1);
  }

  render() {
    const {
      suggestions,
      tags,
      props: { label },
    } = this;
    return (
      <FormGroup>
        <Label>{label}</Label>
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          onRemove={this.handleDelete}
          onAddition={this.handleAddition}
          onInputChange={this.handleInputChange}
          allowNew={false}
          minQueryLength={0}
        />
      </FormGroup>
    );
  }
}
