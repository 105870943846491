/**
 *
 * Created by neo on 30.03.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { User } from '../../../Model/User';
import { UserTableRow } from './UserTableRow';

export type Props = {
  users: User[];
  onRemove?: (user: User) => any;
};

@observer
export class UserTable extends React.Component<Props> {
  render() {
    const {
      props: { users, onRemove },
    } = this;
    return (
      <Table className="table-striped table-hover table-sm">
        <thead className="thead-inverse">
          <tr>
            <th>{'E-Mail'}</th>
            <th>{'Roles'}</th>
            <th>{''}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((c: User) => (
            <UserTableRow key={c.id} user={c} onRemove={onRemove} />
          ))}
        </tbody>
      </Table>
    );
  }
}
