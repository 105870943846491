/**
 * Created by neo on 18.01.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RouteChallengeTeamMember } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeamMember';
import { Athlete } from '../../../../../Model/Athlete/Athlete';
import { RouteChallengePointsTransaction } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengePointsTransaction';
import { Table } from 'reactstrap';
import dayjs from 'dayjs';

export type AthleteRouteChallengeTableRowTransactionsProps = {
  athlete: Athlete;
  member: RouteChallengeTeamMember;
};

export const AthleteRouteChallengeTableRowTransactions: React.FC<AthleteRouteChallengeTableRowTransactionsProps> =
  observer(({ athlete, member }) => {
    const [transactions, setTransactions] = React.useState<RouteChallengePointsTransaction[]>([]);

    useEffect(() => {
      RouteChallengePointsTransaction.find(member.teamId, member.id).then((res) => setTransactions(res));
    }, [member]);

    return (
      <Table size="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th>Points</th>
            <th>Total Points</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td>{dayjs(transaction.transactionDateTime).format('LLLL')}</td>
              <td>{transaction.points}</td>
              <td>{transaction.totalCollectedPoints}</td>
              <td>{transaction.type}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  });
