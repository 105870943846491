/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Pipeline } from '../../../Model/Coach/Pipeline';
import { Button, Popconfirm, Space } from 'antd';
import { Link } from 'react-router-dom';
import { SuperMacro } from '../../../Model/Coach/SuperMacro/SuperMacro';

export type SuperMacroTableRowProps = {
  superMacro: SuperMacro;
  onRemove?: (superMacro: SuperMacro) => any;
  onCopy?: (superMacro: SuperMacro) => any;
};

export const SuperMacroTableRow: React.FC<SuperMacroTableRowProps> = observer(
  ({ superMacro, onRemove, onCopy }: SuperMacroTableRowProps) => {
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(superMacro);
    }, [superMacro, onRemove]);

    const handleCopy = React.useCallback(() => {
      onCopy && onCopy(superMacro);
    }, [onCopy, superMacro]);

    return (
      <tr>
        <td>
          <Link to={`/coach/super-macro/${superMacro.id}`}>{superMacro.templateName || '<no name>'}</Link>
        </td>
        <td>{superMacro.includedTags.sort((a, b) => a.localeCompare(b)).join(', ')}</td>
        <td>
          <Space>
            <Popconfirm title={`Delete Super Macro ${superMacro.templateName}?`} onConfirm={handleRemove}>
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
            <Button type="dashed" onClick={handleCopy}>
              Copy
            </Button>
          </Space>
        </td>
      </tr>
    );
  },
);
