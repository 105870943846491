/**
 * Created by neo on 06.08.22.
 */
import { observable } from 'mobx';
import { RecurringPatternType } from '../Coach/Schedule/RecurringPattern';

export type EarnModelTemplateRuleActivationFrequencyJson = {
  duration?: string;
  type?: RecurringPatternType;
  maxActivations: number;
};

export class EarnModelTemplateRuleActivationFrequency {
  @observable
  duration?: string;
  @observable
  type?: RecurringPatternType = 'daily';
  @observable
  maxActivations = -1;

  constructor(json?: Partial<EarnModelTemplateRuleActivationFrequencyJson>) {
    if (json) {
      this.duration = json.duration;
      this.type = json.type ?? (!json.duration ? 'daily' : undefined);
      this.maxActivations = json.maxActivations ?? -1;
    }
  }

  toJS(): EarnModelTemplateRuleActivationFrequencyJson {
    return {
      duration: this.duration,
      type: this.type,
      maxActivations: this.maxActivations,
    };
  }
}
