/**
 * Created by neo on 26.04.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { MealRecommendation } from '../../../../Model/Diet/Planning/MealRecommendation';
import { Row, Col } from 'reactstrap';
import { Button, Form, Select } from 'antd';
import { BugTwoTone } from '@ant-design/icons';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import AsyncSelect from 'react-select/async';
import { ActionMeta, ValueType } from 'react-select/src/types';
import { MealRequirementCalculation } from '../../../../Model/Diet/Planning/MealRequirementCalculation';

export type RecipeDietRecommendationProps = {
  onRecommendationChanged?: (recommendation: MealRequirementCalculation) => void;
};

export const RecipeDietRecommendation: React.FC<RecipeDietRecommendationProps> = observer(
  ({ onRecommendationChanged }) => {
    const [recommendation, setRecommendation] = useState<MealRecommendation | undefined>();
    const [requirement, setRequirment] = useState<MealRequirementCalculation | undefined>();
    const [athlete, setAthlete] = useState<Athlete | undefined>();

    const handleLoadRecommendations = React.useCallback(() => {
      MealRecommendation.get(athlete?.id).then((result) => setRecommendation(result));
    }, [athlete]);

    const fetch = React.useCallback(
      (query?: string) =>
        Athlete.find({
          query,
          page: 0,
        }),
      [],
    );

    const getOptionLabel = React.useCallback(
      (option: Athlete) => `${option.fullName} (${option.user.email})`,
      [],
    );

    const getOptionValue = React.useCallback((option: Athlete) => option, []);

    const handleChangeAthlete = React.useCallback(
      (athlete?: Athlete) => {
        setAthlete(athlete);
      },
      [setAthlete],
    );

    const handleChange = React.useCallback(
      (meal?: string) => {
        const requirement = recommendation?.mealRecommendations.find((m) => m.meal === meal);
        setRequirment(requirement);
        onRecommendationChanged && requirement && onRecommendationChanged(requirement);
      },
      [recommendation, onRecommendationChanged],
    );

    return (
      <Row>
        <Col>
          <AsyncSelect
            value={athlete}
            cacheOptions
            defaultOptions
            isClearable={true}
            loadOptions={fetch}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue as any}
            onChange={handleChangeAthlete as any}
          />
        </Col>
        <Col>
          <Form.Item>
            {recommendation && (
              <Select onChange={handleChange} value={requirement?.meal}>
                {recommendation.mealRecommendations.map((meal) => (
                  <Select.Option key={meal.meal} value={meal.meal}>{`${meal.meal} ${meal.kcal.toFixed(
                    0,
                  )}kcal / fat: ${meal.fat.toFixed(0)}g / carbs: ${meal.carbs.toFixed(
                    0,
                  )}g / protein: ${meal.protein.toFixed(0)}g`}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs="auto">
          <Button onClick={handleLoadRecommendations}>Load Recommendations</Button>
        </Col>
      </Row>
    );
  },
);
