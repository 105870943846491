import { Stage } from './Stage';
import { EndOfStreamSignal } from '../Signal/EndOfStreamSignal';
import { toJS } from 'mobx';
import { EmptySignal } from '../Signal/EmptySignal';

export interface LimitStageConfig {
  limit: string;
}

export class LimitStage extends Stage<LimitStageConfig> {
  process() {
    const { dataSignals } = this;
    const result = dataSignals.slice(0, Math.min(this.resolveParamNumber(this.config.limit), dataSignals.length));
    console.log('limit::next', toJS(result));
    result.forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'limit';
  }
}
