import * as React from 'react';
import { observer } from 'mobx-react';
import { Activity } from '../../../Model/Activity/Activity';
import { Table } from 'reactstrap';
import { ActivityListTableRow } from './ActivityListTableRow';

export interface ActivityListTableProps {
  activities: Activity[];
  onRemove?: (activity?: Activity) => any;
}

@observer
export class ActivityListTable extends React.Component<ActivityListTableProps> {
  render() {
    const {
      props: { activities },
    } = this;
    return (
      <Table size="sm">
        <thead>
          <tr>
            <th> </th>
            <th>Name</th>
            <th>Identifier</th>
            <th>Tracking Keys</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity: Activity) => (
            <ActivityListTableRow key={activity.identifier} activity={activity} />
          ))}
        </tbody>
      </Table>
    );
  }
}
