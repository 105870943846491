/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { NativeFeedEditorGroupedStylesInput } from '../../Components/NativeFeedEditorGroupedStylesInput';
import Typography from '@mui/material/Typography';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import Grid from '@mui/material/Grid';

interface ComponentStylesViewProps {
  component: NativeFeedComponent;
}

export const ComponentStylesView: React.FC<ComponentStylesViewProps> = observer(({ component }) => {
  const paddingStyleTypes = ['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'];
  const paddingStyleTitles = ['Top', 'Right', 'Bottom', 'Left'];
  const { classes } = useStyles();

  return (
    <Grid className={classes.container}>
      <Typography>Padding</Typography>
      <NativeFeedEditorGroupedStylesInput
        styles={component.props.style}
        styleTypes={paddingStyleTypes}
        styleTitles={paddingStyleTitles}
      />
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
}));
