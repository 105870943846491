/**
 * Created by neo on 13.07.21.
 */
import { HttpBackend } from '../../Services/Http/HttpBackend';

export type AnalyticsRow = Record<string, string>;

export type ParamType = 'string' | 'number' | 'integer' | 'date' | 'datetime';

export type ParamValue = {
  value: string;
  type?: ParamType;
};

export type GenericQueryRequest = {
  query: string;
  parameters?: {
    [key: string]: ParamValue;
  };
};

export type AnalyticsDataJson = {};

export class AnalyticsData {
  static query(request: GenericQueryRequest): Promise<AnalyticsRow[]> {
    return HttpBackend.post(`/analytics/query`, request);
  }

  static gymAgeDistribution(request: { gymId: string; endDate?: Date }): Promise<AnalyticsRow[]> {
    return HttpBackend.get(`/analytics/gym/ageDistribution`, request);
  }

  static gymAgeDistributionHistory(request: { gymId: string }): Promise<AnalyticsRow[]> {
    return HttpBackend.get(`/analytics/gym/ageDistributionHistory`, request);
  }

  static gymUserStats(request: { gymId: string; startDate?: Date; endDate: Date }): Promise<AnalyticsRow[]> {
    return HttpBackend.get(`/analytics/gym/userStats`, request);
  }

  static firstEventDate(request: { gymId: string }): Promise<AnalyticsRow[]> {
    return HttpBackend.get(`/analytics/gym/firstEventDate`, request);
  }
}
