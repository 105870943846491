/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { AbstractExerciseAdmin } from '../../../../Model/Exercise/Admin/AbstractExerciseAdmin';
import { AbstractExercise } from '../../../../Model/Exercise/AbstractExercise';
import { Badge } from 'reactstrap';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';

export type ExerciseEquipmentTypeBadgesProps = {
  exercise: ExerciseVariationAdmin;
};

export const ExerciseEquipmentTypeBadges: React.FC<ExerciseEquipmentTypeBadgesProps> = observer(
  ({ exercise }: ExerciseEquipmentTypeBadgesProps) => {
    return (
      <React.Fragment>
        {exercise.equipmentTypes.map((e) => (
          <Badge key={e.id} color="primary">
            {e.defaultName}
          </Badge>
        ))}
        {exercise.secondaryEquipmentTypes.map((e) => (
          <Badge key={e.id} color="info">
            {e.defaultName}
          </Badge>
        ))}
      </React.Fragment>
    );
  },
);
