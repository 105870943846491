/**
 * Created by neo on 02.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Media } from '../../../../../../Model/Media/Media';
import { Col, Row } from 'reactstrap';
import webvtt from 'node-webvtt';
import { MediaTextTrack } from '../../../../../../Model/Media/MediaTextTrack';
import { observable, runInAction } from 'mobx';
import { VttCueEntry } from './VttCueEntry';
import { Button, Input } from 'antd';
import { v4 as UUID } from 'uuid';
import MediaService from '../../../../../../Services/MediaService';

export type MediaTextTrackVttEditorProps = {
  media: Media;
  selectedTrack?: MediaTextTrack;
};

export const MediaTextTrackVttEditor: React.FC<MediaTextTrackVttEditorProps> = observer(({ media, selectedTrack }) => {
  const [vttBlobUrl, setVttBlobUrl] = useState<string | undefined>();
  const [parsedVtt, setParsedVtt] = useState<any>();
  const [globalStyle, setGlobalStyle] = useState('');

  const loadVtt = React.useRef((textTrack: MediaTextTrack) =>
    fetch(textTrack.url)
      .then((res) => res.text())
      .then((content) => {
        setParsedVtt(observable(webvtt.parse(content)));
        return URL.createObjectURL(new Blob([content], { type: 'text/vtt' }));
      })
      .then((blobUrl) => setVttBlobUrl(blobUrl)),
  ).current;

  useEffect(() => {
    if (selectedTrack) {
      loadVtt(selectedTrack);
    }
  }, [loadVtt, selectedTrack]);

  const handleSubtitleChanged = React.useCallback((vtt) => {
    const text = webvtt.compile(vtt);
    setVttBlobUrl(URL.createObjectURL(new Blob([text], { type: 'text/vtt' })));
  }, []);

  const handleSave = React.useCallback(() => {
    if (parsedVtt) {
      const compiled = webvtt.compile(parsedVtt);
      if (compiled) {
        const blob = new Blob([compiled], { type: 'text/vtt' });
        const newFile = new File([blob], `${UUID()}.vtt`, { type: 'text/vtt' });
        MediaService.uploadMedia(newFile)
          .then((newMedia) => {
            runInAction(() => {
              if (selectedTrack) {
                selectedTrack.url = newMedia.url;
              }
            });
          })
          .then(() => media.save());
      }
    }
  }, [media, parsedVtt, selectedTrack]);

  const handleGlobalStyle = React.useCallback(({ target: { value } }) => {
    setGlobalStyle(value);
  }, []);

  const handleApplyStyle = React.useCallback(() => {
    Promise.all(
      media.textTracks.map((textTrack) =>
        fetch(textTrack.url)
          .then((res) => res.text())
          .then((content) => webvtt.parse(content))
          .then((parsed) => {
            parsed.cues.forEach((cue) => (cue.styles = globalStyle));
            return webvtt.compile(parsed);
          })
          .then((compiled) => {
            const blob = new Blob([compiled], { type: 'text/vtt' });
            const newFile = new File([blob], `${UUID()}.vtt`, { type: 'text/vtt' });
            return MediaService.uploadMedia(newFile).then((newMedia) =>
              runInAction(() => (textTrack.url = newMedia.url)),
            );
          }),
      ),
    )
      .then((parsed) => selectedTrack && loadVtt(selectedTrack))
      .then(() => media.save())
      .then(() => setGlobalStyle(''));
  }, [globalStyle, loadVtt, media, selectedTrack]);

  return (
    <Row>
      <Col>
        <video
          style={{
            height: '100%',
            width: 'auto',
          }}
          loop={true}
          controls
        >
          <source src={media.smallest} type={media.mediaType} />
          {vttBlobUrl && selectedTrack && (
            <track
              kind="subtitles"
              label={selectedTrack.title}
              srcLang={selectedTrack.language}
              src={vttBlobUrl}
              default
            />
          )}
        </video>
      </Col>
      <Col>
        <Input
          type="text"
          value={globalStyle}
          placeholder="Insert global style here like align:center line:80%..."
          onChange={handleGlobalStyle}
          addonAfter={
            <Button type="link" onClick={handleApplyStyle}>
              Apply
            </Button>
          }
        />
        {selectedTrack && (
          <React.Fragment>
            <div style={{ maxHeight: 400, overflow: 'scroll' }}>
              {selectedTrack && (
                <React.Fragment>
                  {parsedVtt?.cues.map((cue) => (
                    <VttCueEntry
                      key={cue.identifier}
                      cue={cue}
                      parsedVtt={parsedVtt}
                      onChange={handleSubtitleChanged}
                    />
                  ))}
                </React.Fragment>
              )}
            </div>
            <Button type="primary" onClick={handleSave}>
              Save Subtitle ({selectedTrack?.title})
            </Button>
          </React.Fragment>
        )}
      </Col>
    </Row>
  );
});
