import { HealthDataPointObjectType } from './HealthDataPointObjectType';
import { HealthDataPointQuantity, HealthDataPointQuantityJson } from './HealthDataPointQuantity';
import { HealthDataPointCorrelationTypes } from './HealthDataPointCorrelationType';
import { HealthDataPointCorrelation, HealthDataPointCorrelationJson } from './HealthDataPointCorrelation';
import { HealthDataPointCategoryTypes } from './HealthDataPointCategoryType';
import { HealthDataPointCategory, HealthDataPointCategoryJson } from './HealthDataPointCategory';
import { HealthDataPointObject } from './HealthDataPointObject';

export class HealthDataPointFactory {
  static create(
    type: HealthDataPointObjectType,
    json?: HealthDataPointQuantityJson | HealthDataPointCorrelationJson | HealthDataPointCategoryJson,
  ): HealthDataPointObject {
    if (HealthDataPointCorrelationTypes.some((t) => t === type)) {
      return new HealthDataPointCorrelation(json as HealthDataPointCorrelationJson);
    } else if (HealthDataPointCategoryTypes.some((t) => t === type)) {
      return new HealthDataPointCategory(json as HealthDataPointCategoryJson);
    }
    return new HealthDataPointQuantity(json as HealthDataPointQuantityJson);
  }
}
