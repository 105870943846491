/**
 * Created by katarinababic on 28.4.22.
 */

export const activitiesLoggedWeekly = `
SELECT
  *,
  week AS event_date
FROM (
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d',
        event_date), WEEK) AS week,
    params.key AS key,
    params.value.string_value AS type,
    COUNT (*) AS num_of_logged,
    COUNT(DISTINCT user_id) AS user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST (event_params) AS params
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'activity_logged'
    AND (params.key = 'type'
      OR params.key = 'activityId')
  GROUP BY
    week,
    key,
    type
  ORDER BY
    week,
    num_of_logged DESC )
`;

export const gymActivitiesLoggedWeekly = `
SELECT
  *,
  week AS event_date
FROM (
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d',
        event_date), WEEK) AS week,
    params.key AS key,
    params.value.string_value AS type,
    COUNT (*) AS num_of_logged,
    COUNT(DISTINCT user_id) AS user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST (event_params) AS params,
    UNNEST (user_properties) AS p
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'activity_logged'
    AND (params.key = 'type'
      OR params.key = 'activityId')
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
  GROUP BY
    week,
    key,
    type
  ORDER BY
    week,
    num_of_logged DESC )
`;
