import {
  action,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { v4 as UUID } from 'uuid';
import { WorkoutTemplate } from './WorkoutTemplate/WorkoutTemplate';
import { ProgramParamsJson } from '../Program/ProgramParams';

export type WorkoutEntryJson = {
  id: string;
  includedTags: string[];
  excludedTags: string[];
  workoutTemplateId: string;
};

export class WorkoutEntry {
  @observable
  id: string = UUID();
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  workoutTemplateId: string = '';
  @observable
  workoutTemplate?: WorkoutTemplate = undefined;
  disposer?: IReactionDisposer;

  constructor(json?: Partial<WorkoutEntryJson>) {
    if (json) {
      this.id = json.id || UUID();
      this.includedTags = json.includedTags || [];
      this.excludedTags = json.excludedTags || [];
      this.workoutTemplateId = json.workoutTemplateId ?? '';
    }

    onBecomeObserved(this, 'workoutTemplate', this.startFetch);
    onBecomeUnobserved(this, 'workoutTemplate', this.stopFetch);
  }

  startFetch = () => {
    this.disposer && this.disposer();
    this.disposer = reaction(
      () => this.workoutTemplateId,
      (templateId?: string) => {
        if (templateId) {
          WorkoutTemplate.get(templateId).then((result) => runInAction(() => (this.workoutTemplate = result)));
        } else {
          this.workoutTemplate = undefined;
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetch = () => {
    this.disposer && this.disposer();
  };

  toJS(replaceId: boolean = false): any {
    return {
      id: replaceId ? UUID() : this.id,
      includedTags: toJS(this.includedTags),
      excludedTags: toJS(this.excludedTags),
      workoutTemplateId: this.workoutTemplateId,
    };
  }
}
