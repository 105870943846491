/**
 * Created by neo on 30.04.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { HealthDataPoint } from '../../../../Model/HealthData/HealthDataPoint';
import { HealthDataPointQuantity } from '../../../../Model/HealthData/HealthDataPointQuantity';
import { HealthDataPointQuantityEntry } from './HealthDataPointQuantityEntry';
import { HealthDataPointCorrelation } from '../../../../Model/HealthData/HealthDataPointCorrelation';
import { HealthDataPointCorrelationEntry } from './HealthDataPointCorrelationEntry';
import { Athlete } from '../../../../Model/Athlete/Athlete';

export type HealthDataPointEntrySwitchProps = {
  athlete: Athlete;
  dataPoint: HealthDataPoint;
};

export const HealthDataPointEntrySwitch: React.FC<HealthDataPointEntrySwitchProps> = observer(
  ({ dataPoint, athlete }) => {
    const handleSaveQuantity = React.useCallback(
      (dataPoint: HealthDataPointQuantity) => {
        athlete.setBodyValue(dataPoint);
      },
      [athlete],
    );

    if (dataPoint instanceof HealthDataPointQuantity) {
      return <HealthDataPointQuantityEntry dataPoint={dataPoint} onSave={handleSaveQuantity} />;
    } else if (dataPoint instanceof HealthDataPointCorrelation) {
      return <HealthDataPointCorrelationEntry dataPoint={dataPoint} />;
    }
    return null;
  },
);
