/**
 * @fow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { runInAction } from 'mobx';
import { Container, Col, Row, Button, ButtonGroup } from 'reactstrap';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { ExerciseEditCard } from './VariationsTab/ExerciseEditCard';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react';
import { useEffect } from 'react';
import { message } from 'antd';

export type ExerciseViewScreenProps = {};

export const ExerciseViewScreen: React.FC<ExerciseViewScreenProps> = observer((_: ExerciseViewScreenProps) => {
  const { exerciseId } = useParams<any>();
  const history = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sourceType = searchParams.get('sourceType');
  const sourceId = searchParams.get('sourceId');

  const store = useLocalStore(() => ({
    exercise: new ExerciseVariationAdmin(),
    processing: false,
    load(exerciseId?: string, sourceType?: string | null, sourceId?: string | null) {
      if (exerciseId && 'new' !== exerciseId) {
        ExerciseVariationAdmin.get(exerciseId, sourceType, sourceId).then((result) =>
          runInAction(() => (store.exercise = result)),
        );
      } else {
        store.exercise = new ExerciseVariationAdmin({ sourceType, sourceId });
      }
    },
  }));

  useEffect(() => {
    store.load(exerciseId, sourceType, sourceId);
  }, [exerciseId, sourceType, sourceId]);

  const handleSave = React.useCallback(async () => {
    store.processing = true;
    try {
      await store.exercise.save();
      history(`/metadata/exercise/${store.exercise.id}?sourceType=${sourceType}&sourceId=${sourceId}`, { replace: true });
      message.success('Exercise saved');
    } catch (e) {
      message.error('ERROR saving exercise');
    } finally {
      store.processing = false;
    }
  }, [store, sourceType, sourceId]);

  const createNew = React.useCallback(() => {
    history(`/metadata/exercise/new?sourceType=${sourceType}&sourceId=${sourceId}`);
  }, [history, sourceType, sourceId]);

  const isNew = React.useMemo(() => exerciseId === 'new', [exerciseId]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="float-right">
            <ButtonGroup>
              {!isNew ? (
                <Button onClick={createNew} disabled={store.processing}>
                  {'Create Another'}
                </Button>
              ) : null}
              <Button color="primary" onClick={handleSave} disabled={store.processing}>
                {'Save'}
              </Button>
            </ButtonGroup>
          </div>
          <h1>{isNew ? 'New Exercise' : `${store.exercise.nameGermanOrNext || '<no name>'}`}</h1>
        </Col>
      </Row>
      <ExerciseEditCard exercise={store.exercise} />
    </Container>
  );
});
