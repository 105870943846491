/**
 * Created by neo on 07.05.21.
 */
import { observable } from 'mobx';
import { GoogleTtsAudioConfig, GoogleTtsAudioConfigJson } from './GoogleTtsAudioConfig';
import { GoogleTtsInput, GoogleTtsInputJson } from './GoogleTtsInput';
import { GoogleTtsVoice, GoogleTtsVoiceJson } from './GoogleTtsVoice';

export type GoogleTtsRequestJson = {
  audioConfig: GoogleTtsAudioConfigJson;
  input: GoogleTtsInputJson;
  voice: Partial<GoogleTtsVoiceJson>;
};

export class GoogleTtsRequest {
  @observable
  audioConfig = new GoogleTtsAudioConfig();
  @observable
  input = new GoogleTtsInput();
  @observable
  voice = new GoogleTtsVoice();

  constructor(json?: Partial<GoogleTtsRequestJson>) {
    if (json) {
      this.audioConfig = new GoogleTtsAudioConfig(json.audioConfig);
      this.input = new GoogleTtsInput(json.input);
      this.voice = new GoogleTtsVoice(json.voice);
    }
  }

  toJS(): GoogleTtsRequestJson {
    return {
      audioConfig: this.audioConfig.toJS(),
      input: this.input.toJS(),
      voice: this.voice.toJS(),
    };
  }
}
