/**
 *
 * Created by neo on 04.03.17.
 */

export class Location {
  latitude: number;
  longitude: number;

  constructor(json?: any) {
    if (json) {
      this.latitude = json.latitude;
      this.longitude = json.longitude;
    }
  }
}
