/**
 * Created by neo on 23.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import dayjs, { Dayjs } from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Resolution, userRetention, usersActive } from './Queries/queryUsersActive';
import { Col, Row } from 'reactstrap';
import { DatePicker, Form, InputNumber, Space, Typography } from 'antd';
import { UserActivityPercentageChart } from './UserActivityPercentageChart';
import { UserRetentionChart } from './UserRetentionChart';

export type UserRetentionProps = {
  gym?: Gym;
  title: string;
  resolution: Resolution;
};

export const UserRetention: React.FC<UserRetentionProps> = observer(({ gym, title, resolution }) => {
  const [startDate, setStartDate] = useState(dayjs('2021-03-21', 'YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs());
  const [minActive, setMinActive] = useState(1);
  const [data, setData] = useState<AnalyticsRow[]>([]);

  const totalUsers = data?.[0]?.total_users ?? 0;
  const gymCreatedAt = gym?.createdAt ? dayjs(gym.createdAt).format('YYYYMMDD') : undefined;
  const start_date = Number(startDate.format('YYYYMMDD'));

  useEffect(() => {
    setData([]);
    Gym.find({ size: 200 })
      .then((result) => result.filter((g) => !!g.configuration.configuration.get('excludeInAnalytics')).map(g => g.id)).then((excludedGymIds) =>
    AnalyticsData.query({
      query: userRetention(gym, resolution, excludedGymIds),
      parameters: {
        gym_created_date: {
          value: gym?.createdAt ? dayjs(gym.createdAt).format('YYYYMMDD') : '',
        },
        start_date: {
          value: gymCreatedAt ? Math.max(start_date, Number(gymCreatedAt)).toString() : start_date.toString(),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
        gym_id: {
          value: gym?.id ?? '',
        },
        min_active: {
          value: minActive.toString(),
          type: 'number',
        },
      },
    }).then((data) => {
      const totalUsersActive = Number(data[0].total_active_users) ?? 0;
      const totalUsers = Number(data[0].total_users) ?? 0;
      setData(
        data.map((d) => {
          d.user_percentage_active = (Math.round((Number(d.total_active_users) / totalUsersActive) * 10000) / 100).toString();
          d.user_percentage = (Math.round((Number(d.total_active_users) / totalUsers) * 10000) / 100).toString();
          d.open_percentage = (Math.round((Number(d.open_once_per_user) / totalUsers) * 10000) / 100).toString();
          return Array.from(Object.entries(d))
            .map(([key, value]) => [key, key === 'minWeek' ? value : Number(value)])
            .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {});
        }),
      );
    }));
  }, [gym, startDate, endDate, resolution, minActive, gymCreatedAt, start_date]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  const handleChangeMinActive = React.useCallback((e) => setMinActive(Number(e) ?? 1), []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h5>{title}</h5>
        </Col>
        <Col xs="auto">
          <h6>{`Total Users: ${totalUsers}`}</h6>
        </Col>
        <Col xs={12}>
          <Space>
            <Form.Item label="Start Date">
              <DatePicker value={startDate} onChange={handleChangeValidFrom} />
            </Form.Item>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeValidUntil} />
            </Form.Item>
            <Form.Item label="Min. Activities">
              <InputNumber min={0} max={30} step={1} value={minActive} onChange={handleChangeMinActive} />
            </Form.Item>
          </Space>
        </Col>
        <Col xs={12} style={{ paddingBottom: 16 }}>
          <Typography.Text type="secondary">
            Compares how many users are still active from the given start date after n days/weeks/months relative to the start date of the user. (independent from when the user actually started.)
            <br />This chart is useful to determine how long does a user stay with us and is engaged. It answers the question &quot;How many users are daily/weekly/monthly doing something after n days/weeks/months&quot;
            <br />(All users are &quot;zeroed&quot; which means when the first day/week/month of user X which differs from the first day/week/month of user Y are both seen as first day/week/month)
            <br />The violet line shows how many users are still doing x activities after n weeks/months. It always starts at a 100% since it calculates (users doing x activities in current week/month) / (users doing x activities in first week/month).
            <br />The red line shows the same thing like the violet line with the exception that it divides by the total amount of registered users: (users doing x activities in current week/month) / (total registered users).
          </Typography.Text>
        </Col>
        <Col xs={12}><UserRetentionChart data={data} /></Col>
      </Row>
    </React.Fragment>
  );
});
