import { Stage } from './Stage';
import { Signal } from '../Signal/Signal';
import { Pipeline } from '../Pipeline';

export class StartStage extends Stage<any> {
  constructor(pipeline: Pipeline) {
    super(pipeline, {});
  }

  sendSignal(signal: Signal) {
    this.processNext(signal);
  }

  process() {
    console.log('do nothing');
  }

  get type(): string {
    return 'start';
  }
}
