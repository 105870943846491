/**
 * Created by neo on 24.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { MindfulnessProgramTemplate } from '../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { MindfulnessProgramTemplateTableRow } from './MindfulnessProgramTemplateTableRow';

export type MindfulnessProgramTemplateTableProps = {
  templates: MindfulnessProgramTemplate[];
  onCopy?: (superMacro: MindfulnessProgramTemplate) => void;
  onRemove?: (superMacro: MindfulnessProgramTemplate) => void;
};

export const MindfulnessProgramTemplateTable: React.FC<MindfulnessProgramTemplateTableProps> = observer(
  ({ templates, onRemove, onCopy }) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Template Name</th>
            <th>Display Names</th>
            <th>Tags</th>
            <th>Min Duration</th>
            <th>Max Duration</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => (
            <MindfulnessProgramTemplateTableRow
              key={template.id}
              template={template}
              onRemove={onRemove}
              onCopy={onCopy}
            />
          ))}
        </tbody>
      </Table>
    );
  },
);
