/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';
import { SuperMacro } from '../../../../Model/Coach/SuperMacro/SuperMacro';
import { SuperMacroMesoCycleTemplate } from '../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';
import { MacroCycleTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleTemplate';
import { MacroCycleLaneTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleLaneTemplate';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';

export type CoachProgramExcludedTagsInputProps = {
  template: FitnessProgramTemplate;
};

export const CoachProgramExcludedTagsInput: React.FC<CoachProgramExcludedTagsInputProps> = observer(({ template }) => {
  const tags = template.excludedTags.map((value) => ({ label: value, value }));

  const handleChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        template.excludedTags.splice(0, template.excludedTags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => template.excludedTags.push(t));
      });
    },
    [template],
  );

  return <CreatableSelect isClearable isMulti onChange={handleChange as any} value={tags} />;
});
