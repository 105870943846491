import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { LessThanEqualsNodeWidget } from './LessThanEqualsNodeWidget';
import { LessThanEqualsNodeModel } from './LessThanEqualsNodeModel';
import { LessThanEqualsStage } from '../../../../../../../../Model/Coach/Stages/Logical/LessThanEqualsStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class LessThanEqualsNodeModelFactory extends AbstractReactFactory<LessThanEqualsNodeModel, DiagramEngine> {
  constructor() {
    super('logical:lte');
  }

  generateReactWidget(event): JSX.Element {
    return <LessThanEqualsNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new LessThanEqualsNodeModel(new LessThanEqualsStage(new Pipeline()));
  }
}
