import { withHour } from '../../Queries/withHour';

export const allSelectContentHourDistribution = `
${withHour('time')} LEFT JOIN (
SELECT *, time as event_date FROM (
  SELECT FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) time,
         e.key AS key,
         e.value.string_value AS value,
         COUNT(DISTINCT user_id) AS user_count,
         COUNT(*) AS select_count
  FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      UNNEST (event_params) AS e
  WHERE
      _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
  GROUP BY
      time,
      key,
      value
  ORDER BY
      time,
      key,
      value                                  
)) first ON root.time = first.time
`;

export const gymSelectContentHourDistribution = `
${withHour('time')} LEFT JOIN (
SELECT *, time as event_date FROM (
  SELECT FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) time,
          e.key AS key,
          e.value.string_value AS value,
          COUNT(DISTINCT user_id) AS user_count,
          COUNT(*) AS select_count
  FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      UNNEST (event_params) AS e,
      UNNEST (user_properties) AS p
  WHERE
      _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
  GROUP BY
      time,
      key,
      value
  ORDER BY
      time,
      key,
      value
  )) first ON root.time = first.time
`;
