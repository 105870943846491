/**
 * Created by neo on 07.06.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import { useEffect, useState } from 'react';
import { PageResult } from '../../../../../Model/PageResult';
import { ExploreFaq } from '../../../../../Model/Explore/ExploreFaq';
import { Col, Row } from 'reactstrap';
import { Pager } from '../../../../../Components/Pager';
import { Alert, Button, Collapse, Form, message, Space } from 'antd';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { GptResponseService } from '../../../../../Services/GptResponseService';
import { runInAction } from 'mobx';
import { LocalizedValue } from '../../../../../Model/LocalizedValue';

export type FaqAnswersTabContentJson = {
  entry: ExploreEntry;
};

export const FaqAnswersTabContent: React.FC<FaqAnswersTabContentJson> = observer(({ entry }) => {
  const [results, setResults] = useState<PageResult<ExploreFaq> | undefined>();
  const [page, setPage] = useState(0);

  const hasNoContent = !results || results?.content.length === 0;

  useEffect(() => {
    PageResult.execute(
      ExploreFaq.find({ page, size: 20, contentId: entry.id }),
      ExploreFaq.count({ contentId: entry.id }),
      page,
      20,
    ).then((result) => setResults(result));
  }, [entry, page]);

  const handlePage = (newPage: number) => setPage(newPage);

  const handleCreate = () => {
    if (results?.content) {
      results.content.unshift(
        new ExploreFaq({ contentId: entry.id, question: [{ lang: 'en', value: 'New Question' }] }),
      );
    }
  };

  const handleGenerate = () => {
    if (results?.content) {
      new GptResponseService([
        {
          role: 'system',
          content: 'You have to answer with an unformatted text and only the question no appendix or prefix.',
        },
        {
          role: 'user',
          content: `It's a ${entry.type} named ${entry.getName('en')}. 
                          The description is: ${entry.getDescription('en')}. Come up with a smart question`,
        },
      ])
        .generate()
        .then((response) => {
          runInAction(() =>
            results.content.unshift(
              new ExploreFaq({ contentId: entry.id, question: [{ lang: 'en', value: response }] }),
            ),
          );
        });
    }
  };

  return (
    <Row>
      {results && (
        <React.Fragment>
          <Col>
            <Pager page={results} onPage={handlePage} />
          </Col>
          <Col xs="auto">
            <Space>
              <Button onClick={handleGenerate}>Generate New Question</Button>
              <Button type="primary" onClick={handleCreate}>
                Create New Question
              </Button>
            </Space>
          </Col>
        </React.Fragment>
      )}
      <Col xs={12}>
        {hasNoContent && <Alert message="No questions avilable yet" type="warning" />}
        <Collapse>
          {results?.content.map((question) => (
            <Collapse.Panel
              key={question.id}
              header={question.getQuestion('en')}
              extra={
                <Space>
                  <Button
                    onClick={() => {
                      question.save().then(() => message.success('Question saved'));
                    }}
                  >
                    Save Question
                  </Button>
                  <Button
                    onClick={() => {
                      new GptResponseService([
                        {
                          role: 'system',
                          content:
                            'You have to answer with an unformatted text and only the answer no appendix or prefix.',
                        },
                        {
                          role: 'user',
                          content: `It's a ${entry.type} named ${entry.getName('en')}. 
                          The description is: ${entry.getDescription('en')}. Now answer the following question: ${question.getQuestion('en')}?`,
                        },
                      ])
                        .generate()
                        .then((response) => {
                          runInAction(() => {
                            question.answer = [new LocalizedValue({ lang: 'en', value: response })];
                          });
                        });
                    }}
                  >
                    Generate Answer
                  </Button>
                  <Button
                    danger={true}
                    onClick={() => {
                      question.delete().then(() => message.success('Question deleted'));

                      PageResult.execute(
                        ExploreFaq.find({ page, size: 20, contentId: entry.id }),
                        ExploreFaq.count({ contentId: entry.id }),
                        page,
                        20,
                      ).then((result) => setResults(result));
                    }}
                  >
                    Delete Question
                  </Button>
                </Space>
              }
            >
              <Row>
                <Col>
                  <Form.Item label="Question">
                    <TranslationInputArray entity={question} field="question" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Answer">
                    <TranslationInputArray entity={question} field="answer" />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Col>
      {results && (
        <Col xs={12}>
          <Pager page={results} onPage={handlePage} />
        </Col>
      )}
    </Row>
  );
});
