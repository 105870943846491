/**
 *
 * Created by andreaskarantzas on 01.08.18.
 */
import { computed, observable } from 'mobx';
import { Dayjs } from 'dayjs';
import dayjs from '../../Utils/dayjs';

export type TimeRangeJson = {
  from?: string;
  to?: string;
};

export class TimeRange {
  @observable
  from?: string;
  @observable
  to?: string;

  constructor(json?: Partial<TimeRangeJson>) {
    if (json) {
      this.from = json.from || '08:00:00';
      this.to = json.to || '09:00:00';
    }
  }

  @computed
  get fromFixed(): string | undefined {
    if (this.from) {
      if (this.from?.length === 5) {
        return `${this.from}:00`;
      } else if ((this.from?.length ?? 0) >= 8) {
        return this.from.substr(0, 8);
      }
    }
    return undefined;
  }

  @computed
  get toFixed(): string | undefined {
    if (this.to) {
      if (this.to?.length === 5) {
        return `${this.to}:00`;
      } else if ((this.to?.length ?? 0) >= 8) {
        return this.to.substr(0, 8);
      }
    }
    return undefined;
  }

  @computed
  get fromDayjs(): Dayjs | undefined {
    if (this.fromFixed) {
      return dayjs(`2019-01-12T${this.fromFixed}`, 'YYYY-MM-DDTHH:mm:ss');
    }
    return undefined;
  }

  @computed
  get toDayjs(): Dayjs | undefined {
    if (this.toFixed) {
      return dayjs(`2019-01-12T${this.toFixed}`, 'YYYY-MM-DDTHH:mm:ss');
    }
    return undefined;
  }

  toJS(): TimeRangeJson {
    return {
      from: this.fromFixed,
      to: this.toFixed,
    };
  }
}
