/**
 * Created by neo on 14.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Col, Row, Table } from 'reactstrap';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Pager } from '../../../../../Components/Pager';
import { PageResult } from '../../../../../Model/PageResult';
import { ExerciseStatisticsUsage } from '../../../../../Model/Exercise/Statistics/ExerciseStatisticsUsage';
import { GymExerciseStatisticsTableRow } from './GymExerciseStatisticsTableRow';

export type GymExerciseStatisticsTabProps = {
  gym: Gym;
};

export const GymExerciseStatisticsTab: React.FC<GymExerciseStatisticsTabProps> = observer(({ gym }) => {
  const [result, setResult] = useState(new PageResult<ExerciseStatisticsUsage>());

  const currentPage = React.useRef(0);

  const load = React.useCallback((gymId: string, page: number) => {
    PageResult.execute(
      ExerciseStatisticsUsage.find({ gymId, page, size: 20 }),
      ExerciseStatisticsUsage.count({ gymId }),
      page,
      20,
    ).then((res) => setResult(res));
  }, []);

  const handlePage = React.useCallback(
    (page: number) => {
      if (currentPage.current !== page) {
        currentPage.current = page;
        load(gym.id, currentPage.current);
      }
    },
    [gym.id, load],
  );

  useEffect(() => {
    currentPage.current = 0;
    load(gym.id, currentPage.current);
  }, [gym, load]);

  return (
    <React.Fragment>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th />
              <th>{'Exercise'}</th>
              <th>Type</th>
              <th>Equipment</th>
              <th>{'Count'}</th>
              <th># Athletes</th>
              <th>{'Total Duration'}</th>
            </tr>
          </thead>
          <tbody>
            {result.content.map((entry) => (
              <GymExerciseStatisticsTableRow key={entry.id} entry={entry} />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
    </React.Fragment>
  );
});
