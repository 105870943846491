import * as React from 'react';
import { useLocalStore, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { Activity } from '../../../Model/Activity/Activity';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { ActivityListTable } from './ActivityListTable';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'antd';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';

export type ActivityListScreenProps = {};

export const ActivityListScreen: React.FC<ActivityListScreenProps> = observer((_: ActivityListScreenProps) => {
  const history = useNavigate();
  const debounce = React.useRef<NodeJS.Timeout | undefined>();

  const store = useLocalStore(() => ({
    result: new PageResult<Activity>(),
    query: '',
    page: 0,
    hasMore: false,
    loading: false,
    load() {
      store.loading = true;
      PageResult.execute(
        Activity.find({
          query: store.query,
          page: store.page,
          sort: 'identifier,ASC',
          size: 10,
        }),
        Activity.count({
          query: store.query,
        }),
        store.page,
        10,
      ).then((data) => {
        runInAction(() => (store.result = data));
      });
    },
    resetAndLoad() {
      store.page = 0;
      store.result = new PageResult();
      store.load();
    },
  }));

  useEffect(() => {
    store.load();
  }, [store]);

  useEffect(() => {
    return () => {
      debounce.current && clearTimeout(debounce.current);
    };
  }, []);

  const handleCreate = React.useCallback(() => {
    history(`/metadata/activity/new`);
  }, [history]);

  const handlePage = React.useCallback(
    (newPage: number) => {
      if (store.page !== newPage) {
        runInAction(() => (store.page = newPage));
        store.load();
      }
    },
    [store],
  );

  const handleQueryChange = React.useCallback(
    ({ target: { value } }) => {
      store.query = value;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => store.resetAndLoad(), 300);
    },
    [store],
  );

  return (
    <Container>
      <SingleColRow>
        <h3>Activities</h3>
      </SingleColRow>
      <Row>
        <Col>
          <Input type="text" onChange={handleQueryChange} value={store.query} />
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreate}>
            Create
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <ActivityListTable activities={store.result.content} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
