import {
  action,
  computed,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
} from 'mobx';
import { v4 as UUID } from 'uuid';
import { ProgramParams } from './ProgramParams';
import { MesoCycle } from './MesoCycle';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { MicroCycle } from './MicroCycle';
import { SuperMacro } from '../SuperMacro/SuperMacro';
import { Athlete } from '../../Athlete/Athlete';
import { SuperMacroMesoCycleTemplate } from '../SuperMacro/SuperMacroMesoCycleTemplate';
import { ProgramSchedule } from './ProgramSchedule';
import { CoachProgramType } from './CoachProgramType';
import { FitnessProgramTemplate } from './Template/FitnessProgramTemplate';
import { MesoCycleTemplate } from './Template/MesoCycleTemplate';

// export type CoachProgramJson = AuditedJson & {
//   id: string;
//   athleteId: string;
//   schedule: ScheduleJson;
//   superMacroTemplateId: string;
//   currentParams: ProgramParamsJson;
//   currentMacroCycleLaneId?: string;
//   currentMesoCycle?: MesoCycleJson;
//   startDateTime?: string;
//   endDateTime?: string;
//   type: CoachProgramType;
// };

export class CoachProgram {
  @observable
  id = UUID();
  @observable
  athleteId: string = '';
  @observable
  schedule: ProgramSchedule = new ProgramSchedule();
  @observable
  superMacroTemplateId: string = '';
  @observable
  currentParams: ProgramParams = new ProgramParams();
  @observable
  currentMacroCycleLaneId?: string = undefined;
  @observable
  currentMesoCycle?: MesoCycle = undefined;
  @observable
  startDateTime?: Date = undefined;
  @observable
  endDateTime?: Date = undefined;
  @observable
  createdAt: Date = new Date();
  @observable
  type: CoachProgramType = 'superMacro';
  @observable
  template?: FitnessProgramTemplate;
  @observable
  athlete?: Athlete = undefined;
  superMacroDisposer?: IReactionDisposer;

  constructor(json?: any) {
    if (json) {
      this.setData(json);
    }

    onBecomeObserved(this, 'athlete', this.fetchAthlete);
    onBecomeObserved(this, 'template', this.startFetchSuperMacro);
    onBecomeUnobserved(this, 'template', this.stopFetchSuperMacro);
  }

  startFetchSuperMacro = () => {
    this.superMacroDisposer && this.superMacroDisposer();
    this.superMacroDisposer = reaction(
      () => this.superMacroTemplateId,
      (templateId?: string) => {
        if (templateId) {
          FitnessProgramTemplate.findOne(templateId).then((result) => runInAction(() => (this.template = result)));
        } else {
          runInAction(() => (this.template = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchSuperMacro = () => {
    this.superMacroDisposer && this.superMacroDisposer();
    this.superMacroDisposer = undefined;
  };

  fetchAthlete = () => {
    if (this.athleteId) {
      Athlete.get(this.athleteId).then((result) => runInAction(() => (this.athlete = result)));
    }
  };

  @action
  setData(json: any): CoachProgram {
    this.id = json.id || UUID();
    this.athleteId = json.athleteId;
    this.superMacroTemplateId = json.superMacroTemplateId;
    this.currentParams = new ProgramParams(json.currentParams);
    this.currentMacroCycleLaneId = json.currentMacroCycleLaneId;
    this.currentMesoCycle = json.currentMesoCycle ? new MesoCycle(json.currentMesoCycle) : undefined;
    this.startDateTime = json.startDateTime ? new Date(json.startDateTime) : undefined;
    this.endDateTime = json.endDateTime ? new Date(json.endDateTime) : undefined;
    this.schedule = new ProgramSchedule(json.schedule);
    this.createdAt = new Date(json.createdAt);
    this.type = json.type ?? 'superMacro';
    return this;
  }

  @action
  changeParams(params?: ProgramParams) {
    this.currentParams = params ?? this.currentParams;
    if (this.type === 'program') {
      return HttpBackend.post(`/coach/${this.id}/changeParams`, this.currentParams.toJS()).then((result) =>
        this.setData(result),
      );
    }
    return HttpBackend.post(`/coach/program/${this.id}/changeParams`, this.currentParams.toJS()).then((result) =>
      this.setData(result),
    );
  }

  startCycle(params?: any): Promise<MicroCycle> {
    if (this.type === 'program') {
      return HttpBackend.post(`/coach/program/${this.id}/startCycle`)
        .then((result) => new MicroCycle(result))
        .then((microCycle) => {
          if (this.currentMesoCycle) {
            this.currentMesoCycle.microCycleIds.push(microCycle.id);
          }
          return microCycle;
        });
    }
    return HttpBackend.post(`/coach/${this.id}/startCycle`)
      .then((result) => new MicroCycle(result))
      .then((microCycle) => {
        if (this.currentMesoCycle) {
          this.currentMesoCycle.microCycleIds.push(microCycle.id);
        }
        return microCycle;
      });
  }

  delete() {
    return HttpBackend.delete(`/coach/admin/${this.id}`).then(() => runInAction(() => (this.endDateTime = new Date())));
  }

  @computed
  get mesoCycleTemplate(): SuperMacroMesoCycleTemplate | MesoCycleTemplate | undefined {
    if (this.currentMesoCycle?.configuration && this.template instanceof SuperMacro) {
      return this.template?.macroCycles
        .flatMap((c) => c.lanes.flatMap((l) => l.initCycles.concat(l.cycles)))
        .find((cycle) => cycle.id === this.currentMesoCycle?.configuration.mesoCycleTemplateId);
    } else if (this.template instanceof FitnessProgramTemplate && this.currentMesoCycle?.configuration) {
      return this.template?.mesoCycles.find((m) => m.id === this.currentMesoCycle?.configuration.mesoCycleTemplateId);
    }
    return undefined;
  }

  static create(type: CoachProgramType, params: ProgramParams) {
    if (type === 'program') {
      return HttpBackend.post(`/coach/program`, params.toJS()).then((result) => new CoachProgram(result));
    }
    return HttpBackend.post(`/coach`, params.toJS()).then((result) => new CoachProgram(result));
  }

  static createAdmin(type: CoachProgramType, athleteId: string, params: ProgramParams) {
    if (type === 'program') {
      return HttpBackend.post(`/coach/program?athleteId=${athleteId}`, params.toJS()).then(
        (result) => new CoachProgram(result),
      );
    }
    return HttpBackend.post(`/coach?athleteId=${athleteId}`, params.toJS()).then((result) => new CoachProgram(result));
  }

  static async find(params?: any): Promise<CoachProgram[]> {
    const result = await HttpBackend.get('/coach/program', params);
    return result.map((t) => new CoachProgram(t));
  }

  static findAdmin(params?: any): Promise<CoachProgram[]> {
    return HttpBackend.get('/coach/program/admin', params).then((result) => result.map((t) => new CoachProgram(t)));
  }

  static findLatest(params?: any): Promise<CoachProgram[]> {
    return HttpBackend.get('/coach/program/admin/latest', params).then((result) =>
      result.map((t) => new CoachProgram(t)),
    );
  }

  static countAdmin(params?: any): Promise<number> {
    return HttpBackend.get('/coach/program/admin/count', params);
  }

  static countLatest(params?: any): Promise<number> {
    return HttpBackend.get('/coach/program/admin/countLatest', params);
  }

  static async get(id: string): Promise<CoachProgram> {
    const result = await HttpBackend.get(`/coach/program/${id}`);
    return new CoachProgram(result);
  }
}
