import { EstimatedCost, EstimatedCostJson } from '../EstimatedCost';
import { observable, toJS } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { v4 as UUID } from 'uuid';
import { NutritionInformation, NutritionInformationJson } from './NutritionInformation';
import { IngredientSearchParams } from './IngredientSearchParams';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { Media, MediaJson } from '../../Media/Media';

/**
 * Created by neo on 18.12.20.
 */

export type IngredientJson = LocalizedArrayEntityJson & {
  id: string;
  parentIds: string[];
  name: LocalizedValueJson[];
  originalName: LocalizedValueJson[];
  extendedName: LocalizedValueJson[];
  amount: number;
  unit: string;
  possibleUnits: {
    [key: string]: number;
  };
  estimatedCost?: EstimatedCostJson;
  consistency?: string;
  shoppingListUnits: string[];
  aisle?: string;
  image?: MediaJson;
  meta: string[];
  metaInformation: string[];
  nutrition: NutritionInformationJson;
  categoryPath: string[];
};

export const defaultPossibleUnits = {
  g: 1,
  milliliter: 1,
  oz: 28.3495,
  teaspoon: 4,
  pinch: 0.2,
  sachet: 1,
  tablespoon: 12,
  envelope: 14,
  packet: 14,
  lb: 453.592,
  kg: 1000,
  piece: 1,
  l: 1,
};

export class Ingredient extends LocalizedArrayEntity {
  @observable
  id = UUID();
  @observable
  parentIds: string[] = [];
  @observable
  originalName: LocalizedValue[] = [];
  @observable
  extendedName: LocalizedValue[] = [];
  @observable
  amount: number = 100;
  @observable
  unit: string = 'g';
  @observable
  possibleUnits: {
    [key: string]: number;
  } = {};
  @observable
  estimatedCost?: EstimatedCost;
  @observable
  consistency?: string;
  @observable
  shoppingListUnits: string[] = [];
  @observable
  aisle?: string;
  @observable
  image?: Media;
  @observable
  meta: string[] = [];
  @observable
  metaInformation: string[] = [];
  @observable
  nutrition = new NutritionInformation();
  @observable
  categoryPath: string[] = [];

  constructor(json?: Partial<IngredientJson>) {
    super(json);
    if (json) {
      this.id = json.id ?? UUID();
      this.parentIds = json.parentIds ?? [];
      this.originalName = (json.originalName ?? []).map((n) => new LocalizedValue(n));
      this.extendedName = (json.extendedName ?? []).map((n) => new LocalizedValue(n));
      this.amount = json.amount ?? 0;
      this.unit = json.unit ?? '';
      this.possibleUnits = json.possibleUnits ?? defaultPossibleUnits;
      this.estimatedCost = json.estimatedCost ? new EstimatedCost(json.estimatedCost) : undefined;
      this.consistency = json.consistency;
      this.shoppingListUnits = json.shoppingListUnits ?? [];
      this.aisle = json.aisle;
      this.image = json.image ? new Media(json.image) : undefined;
      this.meta = json.meta ?? [];
      this.metaInformation = json.metaInformation ?? [];
      this.nutrition = new NutritionInformation(json.nutrition);
      this.categoryPath = json.categoryPath ?? [];
    }
  }

  toJS(): IngredientJson {
    return Object.assign(super.toJS(), {
      id: this.id,
      parentIds: this.parentIds,
      originalName: this.originalName.map((l) => l.toJS()),
      extendedName: this.extendedName.map((l) => l.toJS()),
      amount: this.amount,
      unit: this.unit,
      possibleUnits: toJS(this.possibleUnits),
      estimatedCost: this.estimatedCost?.toJS(),
      consistency: this.consistency,
      shoppingListUnits: toJS(this.shoppingListUnits),
      aisle: this.aisle,
      image: this.image?.toJS(),
      meta: toJS(this.meta),
      metaInformation: toJS(this.metaInformation),
      nutrition: this.nutrition.toJS(),
      categoryPath: toJS(this.categoryPath),
    });
  }

  save(): Promise<Ingredient> {
    return HttpBackend.post(`/diet/admin/ingredients`, this.toJS()).then(() => this);
  }

  static search(params?: IngredientSearchParams): Promise<Ingredient[]> {
    return HttpBackend.get(`/diet/ingredients`, params).then((result) => result.map((r) => new Ingredient(r)));
  }

  static count(params?: IngredientSearchParams): Promise<number> {
    return HttpBackend.get(`/diet/ingredients/count`, params);
  }

  static get(id: string): Promise<Ingredient> {
    return HttpBackend.get(`/diet/ingredients/${id}`).then((result) => new Ingredient(result));
  }

  static getAll(ids: string[]): Promise<Ingredient[]> {
    return HttpBackend.get(`/diet/ingredients/findAll/${ids.join(',')}`).then((result) =>
      (result ?? []).map((res) => new Ingredient(res)),
    );
  }
}
