/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Col, Container, Row } from 'reactstrap';
import { BodyPartTable } from './BodyPartTable';
import { BodyPartRegionAdmin } from '../../../Model/BodyPart/BodyPartRegionAdmin';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDebouncedEffect } from '../../../Utils/useDebouncedEffect';

export type BodyPartTableTabProps = {};

export const BodyPartTableTab: React.FC<BodyPartTableTabProps> = observer((props: BodyPartTableTabProps) => {
  const navigate = useNavigate();

  const [result, setResult] = useState<BodyPartRegionAdmin[]>([]);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');

  useDebouncedEffect(() => {
    BodyPartRegionAdmin.find({ query, page, sort: 'defaultName,ASC', size: 200 }).then((res) => setResult(res));
  }, [query, page]);

  const handleRemove = React.useCallback(async (bodyPart: BodyPartRegionAdmin) => {
    if (window.confirm('Are you sure?')) {
      await bodyPart.remove();
      navigate(0);
    }
  }, []);

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleQueryChange = React.useCallback(({ target: { value } }: any) => {
    setQuery(value);
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Input type="text" onChange={handleQueryChange} placeholder="Search..." />
        </Col>
        <Col xs="auto">
          <Button type="primary" href={`/metadata/bodypart/view/create`}>
            {'Create Body Part'}
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <BodyPartTable bodyParts={result} onRemove={handleRemove} />
      </SingleColRow>
    </React.Fragment>
  );
});
