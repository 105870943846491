/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Athlete } from '../../Model/Athlete/Athlete';
import { ProfilePicture } from '../../Components/ProfilePicture';
import { useRootStore } from '../../Store/useRootStore';
import { AthleteLink } from '../../Components/AthleteLink';
import { Button, Space, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

export type AthleteTableRowProps = {
  athlete: Athlete;
  onFetchToken: (athlete: Athlete) => any;
  onRemove: (athlete: Athlete) => any;
};

export const AthleteTableRow: React.FC<AthleteTableRowProps> = observer(
  ({ athlete, onFetchToken, onRemove }: AthleteTableRowProps) => {
    const { authentication } = useRootStore();
    const handleRemove = React.useCallback(() => {
      onRemove(athlete);
    }, [onRemove, athlete]);

    const handleFetchToken = React.useCallback(() => {
      onFetchToken(athlete);
    }, [onFetchToken, athlete]);

    return (
      <tr>
        <td>
          <ProfilePicture image={athlete.profilePicture} />
        </td>
        <td>
          <AthleteLink athlete={athlete} />
        </td>
        <td>
          <Link to={`/security/user/${athlete.user.id}`}>{athlete.user.email}</Link>
        </td>
        <td>
          {athlete.apps.map((app) => (
            <Tag key={app} color={app === 'coach' ? 'red' : 'cyan'}>
              {app}
            </Tag>
          ))}
        </td>
        <td>{athlete.lastActivity?.updatedAt ? dayjs(athlete.lastActivity.updatedAt).format('DD.MM.YYYY HH:mm') : ''}</td>
        <td>{dayjs(athlete.createdAt).format('DD.MM.YYYY HH:mm')}</td>
        {authentication.isGodAdmin ? (
          <td>
            <Space>
              <Button danger={true} size="small" onClick={handleRemove}>
                {'Remove'}
              </Button>
              <Button type="dashed" size="small" onClick={handleFetchToken}>
                {'Fetch Token'}
              </Button>
            </Space>
          </td>
        ) : null}
      </tr>
    );
  },
);
