/**
 * Created by neo on 18.11.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { CoachExerciseBlockSet } from '../../../../../Model/Coach/Program/Workout/CoachExerciseBlockSet';
import dayjs from '../../../../../Utils/dayjs';
import {
  BaseTrackingKey,
  TrackingKey,
  TrackingKeyEntry,
  TrackingKeys,
} from '../../../../../Model/ProgramPortfolio/TrackingKeys';
import { CoachExerciseBlock } from '../../../../../Model/Coach/Program/Workout/CoachExerciseBlock';
import { Exercise } from '../../../../../Model/Exercise/Exercise';
import { WorkoutResponseSet } from '../../../../../Model/Coach/Script/WorkoutResponseSet';
import styled from '@emotion/styled';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 1px 0;
  border-bottom: 1px solid lightgrey;
  &:last-child {
    border-bottom: none;
  }
`;
const SetIndex = styled.div`
  display: flex;
  font-size: 12px;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  width: 28px;
`;

const LeftRight = styled.div`
  font-size: 12px;
  margin-left: 4px;
`;

const Value = styled.div`
  flex: 1;
  font-size: 12px;
  text-align: right;
  padding-left: 4px;
  justify-content: space-between;
  display: flex;
`;

const TrackingKeyContainer = styled.span`
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
`;

const TrackingKeyColors = {
  BREAK: 'darkorange',
  REPETITIONS: 'blue',
  DURATION: 'blue',
  WEIGHT: 'green',
};

export type CoachExerciseSetProps = {
  exercise: Exercise;
  set: CoachExerciseBlockSet | WorkoutResponseSet;
  index: number;
};

export const CoachExerciseSet: React.FC<CoachExerciseSetProps> = observer(({ set, exercise, index }) => {
  const definition = (tk: BaseTrackingKey): TrackingKeyEntry | undefined => TrackingKeys[tk];

  const tracksTime = (tk: BaseTrackingKey) => tk === 'DURATION' || tk === 'BREAK';

  const trackingKeyUnit = (tk: BaseTrackingKey): string | undefined =>
    (tracksTime(tk) && maxValue(tk) < 60) || tk === 'BREAK' ? 's' : definition(tk)?.defaultUnit;

  const previousValue = (tk: BaseTrackingKey) =>
    set instanceof CoachExerciseBlockSet ? set.previousValues.get(tk) : undefined;

  const deltaValue = (tk: BaseTrackingKey, current: number): number | undefined => {
    const previous = previousValue(tk);
    return previous ? current - previous : undefined;
  };

  const maxValue = (tk: BaseTrackingKey) =>
    set.values.get(`MAX_${tk}` as TrackingKey) ?? set.values.get(`MIN_${tk}` as TrackingKey) ?? 0;

  const minValue = (tk: BaseTrackingKey) =>
    set.values.get(`MIN_${tk}` as TrackingKey) ?? set.values.get(`MAX_${tk}` as TrackingKey) ?? 0;

  const valueString = (tk: BaseTrackingKey, value: number, infinite: boolean = true): string => {
    if (value === 0) {
      return infinite ? '∞' : '0';
    } else if (tracksTime(tk)) {
      const ms = value * 1000;
      if (value > 3599 && tk === 'DURATION') {
        return dayjs.utc(ms).format('HH:mm:ss');
      } else if (value > 59 && tk === 'DURATION') {
        return dayjs.utc(ms).format('mm:ss');
      }
    }
    return `${value}`;
  };

  const data = set.assignedTrackingKeysSorted;

  const trackingKeysText = data.reduce((str, tk, index) => {
    const delta = tk === 'WEIGHT' ? deltaValue(tk, maxValue(tk)) : undefined;
    const minString = valueString(tk, minValue(tk), false);
    const maxString = valueString(tk, maxValue(tk));
    const range = minString === maxString ? minString : `${minString}-${maxString}`;
    return str
      .concat(`${range} ${trackingKeyUnit(tk) || ''}`)
      .concat(delta ? ` (${delta})` : '')
      .concat(tk === 'REPETITIONS' ? ' x ' : index < data.length - 1 ? ', ' : '');
  }, '');

  return (
    <Container>
      {exercise.executionType === 'ONE_SIDED' ? (
        <SetIndex>
          {index % 2 === 0 ? `${Math.floor(index / 2) + 1}.` : ''}{' '}
          <LeftRight>{`(${index % 2 === 0 ? 'L' : 'R'})`}</LeftRight>
        </SetIndex>
      ) : (
        <SetIndex>{`${index + 1}.`}</SetIndex>
      )}
      <Value>
        {set.assignedTrackingKeysSorted.map((tk) => {
          const delta = tk === 'WEIGHT' ? deltaValue(tk, maxValue(tk)) : undefined;
          const minString = valueString(tk, minValue(tk), false);
          const maxString = valueString(tk, maxValue(tk));
          const range = minString === maxString ? minString : `${minString}-${maxString}`;
          return (
            <TrackingKeyContainer key={tk} style={{ color: TrackingKeyColors[tk] ?? 'black' }}>
              {`${range} ${trackingKeyUnit(tk) ?? (tk === 'REPETITIONS' ? ' x' : '')}`.concat(
                delta ? ` (${delta})` : '',
              )}
            </TrackingKeyContainer>
          );
        })}
      </Value>
    </Container>
  );
});
