/**
 * Created by neo on 05.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { SingleColRow } from '../../Components/SingleColRow';
import { Alert, Form, Input } from 'antd';

export type QrCodeBuilderScreenProps = {};

export const QrCodeBuilderScreen: React.FC<QrCodeBuilderScreenProps> = observer((props) => {
  const [value, setValue] = useState('');

  const cleanValue = value.trim();

  return (
    <Container>
      <Form layout="vertical">
        <SingleColRow>
          <Form.Item label="Value" extra="Enter any value here... Link or whatever to generate a QR-Code out of it">
            <Input size="large" type="text" value={value} onChange={(e) => setValue(e.target.value)} />
          </Form.Item>
        </SingleColRow>
        {cleanValue ? (
          <Row>
            <Col xs={12}>
              <Form.Item label="QR Code" extra="If you want to download it just right-click and save it">
                <div style={{ textAlign: 'center' }}>
                  <QRCodeCanvas
                    value={cleanValue}
                    includeMargin
                    size={1280}
                    style={{ width: '100%', height: 'auto' }}
                    level="M"
                    // imageSettings={{
                    //   src: require('../../Assets/Images/logo.png'),
                    //   width: 180,
                    //   height: 180,
                    //   excavate: true,
                    // }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Alert message="Please enter a value to generate a QR-Code" type="info" showIcon />
        )}
      </Form>
    </Container>
  );
});
