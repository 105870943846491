/**
 * Created by neo on 14.12.20.
 */
import { observable } from 'mobx';

export type SubscriptionTransactionDetailsJson = {
  transactionId: string;
  paymentProvider: string;
  transactionDate: string;
};

export class SubscriptionTransactionDetails {
  @observable
  transactionId: string = '';
  @observable
  paymentProvider: string = '';
  @observable
  transactionDate: Date = new Date();

  constructor(json?: Partial<SubscriptionTransactionDetailsJson>) {
    if (json) {
      this.transactionId = json.transactionId ?? '';
      this.paymentProvider = json.paymentProvider ?? '';
      this.transactionDate = json.transactionDate ? new Date(json.transactionDate) : new Date();
    }
  }

  toJS(): SubscriptionTransactionDetailsJson {
    return {
      transactionId: this.transactionId,
      paymentProvider: this.paymentProvider,
      transactionDate: this.transactionDate.toISOString(),
    };
  }
}
