/**
 * Created by neo on 12.04.22.
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../../LocalizedArrayEntity';
import { Media, MediaJson } from '../../../Media/Media';
import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, toJS } from 'mobx';
import { TimeOfDay } from '../../../TimeOfDay';
import { Activity } from '../../../Activity/Activity';
import { TimesOfDay } from '../../TimesOfDay';
import { TimeRange, TimeRangeJson } from '../../../Gym/TimeRange';

export type MindfulnessProgramTemplateDayUnitJson = LocalizedArrayEntityJson & {
  type: string;
  activityIdentifier: string;
  includedTags: string[];
  excludedTags: string[];
  image?: MediaJson;
  durationInMinutes?: number;
  timeOfDay: TimeOfDay;
  defaultTime?: TimeRangeJson;
};

export class MindfulnessProgramTemplateDayUnit extends LocalizedArrayEntity {
  @observable
  type = 'default';
  @observable
  activityIdentifier = '';
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  image?: Media;
  @observable
  durationInMinutes?: number;
  @observable
  timeOfDay: TimeOfDay = 'any';
  @observable
  defaultTime?: TimeRange;
  @observable
  activity?: Activity;
  private fetchActivityDisposer?: IReactionDisposer;

  constructor(json?: Partial<MindfulnessProgramTemplateDayUnitJson>) {
    super(json);
    if (json) {
      this.type = json.type ?? 'default';
      this.activityIdentifier = json.activityIdentifier ?? '';
      this.includedTags = json.includedTags ?? [];
      this.excludedTags = json.excludedTags ?? [];
      this.image = json.image ? new Media(json.image) : undefined;
      this.durationInMinutes = json.durationInMinutes;
      this.timeOfDay = json.timeOfDay ?? 'any';
      this.defaultTime = json.defaultTime ? new TimeRange(json.defaultTime) : undefined;
    }

    onBecomeObserved(this, 'activity', this.startFetchActivity);
    onBecomeUnobserved(this, 'activity', this.stopFetchActivity);
  }

  startFetchActivity = () => {
    this.fetchActivityDisposer = reaction(
      () => this.activityIdentifier,
      (activityIdentifier) => {
        if (activityIdentifier) {
          Activity.get(activityIdentifier).then((result) => runInAction(() => (this.activity = result)));
        } else {
          runInAction(() => (this.activity = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchActivity = () => {
    this.fetchActivityDisposer && this.fetchActivityDisposer();
    this.fetchActivityDisposer = undefined;
  };

  toJS(newId: boolean = false): MindfulnessProgramTemplateDayUnitJson {
    return Object.assign(super.toJS(newId), {
      type: this.type,
      activityIdentifier: this.activityIdentifier,
      includedTags: toJS(this.includedTags),
      excludedTags: toJS(this.excludedTags),
      image: this.image?.toJS(),
      durationInMinutes: this.durationInMinutes,
      timeOfDay: this.timeOfDay,
      defaultTime: this.defaultTime?.toJS(),
    });
  }
}
