import { withDayAndHour } from '../../Queries/withDayAndHour';

export const weekDayHourDistribution = (gym: boolean = false) =>
  events.reduce(
    (query, event, index) =>
      query +
      `LEFT JOIN (${hourEventTotal(
        event,
        gym,
      )}) q${index}\n ON root.weekDay = q${index}.weekDay AND root.hour = q${index}.hour\n`,
    `${withDayAndHour()} \n
     LEFT JOIN (${hourTotal(gym)}) first ON root.weekDay = first.weekDay AND root.hour = first.hour\n`,
  ) +
  `LEFT JOIN (${hourRecipe(gym)}) recipe ON root.weekDay = recipe.weekDay AND root.hour = recipe.hour\n
  LEFT JOIN (${hourWorkout(gym, 'gym_strength')}) gs ON root.weekDay = gs.weekDay AND root.hour = gs.hour\n
  LEFT JOIN (${hourWorkout(gym, 'workplace')}) gw ON root.weekDay = gw.weekDay AND root.hour = gw.hour\n
  LEFT JOIN (${hourUsers(gym)}) users ON root.weekDay = users.weekDay AND root.hour = users.hour\n
  `;

export const hourTotal = (gym: boolean = false) => `
  SELECT
      FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) weekDay,
      FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) hour,
    IFNULL(COUNT(*), 0) as total
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    ${gym ? `UNNEST(user_properties) p,` : ''}
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND 
        (
            (event_name = 'yogaSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'breathingSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'meditationSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'Workout_finished' AND e.key = 'athleteId')
            OR (event_name = 'activity_logged' AND e.key = 'athleteId')
            OR (event_name = 'FeedEntry_read' AND e.key = 'athleteId')
            OR (e.key = 'content_type' AND e.value.string_value = 'recipe')
        )
  GROUP BY weekDay, hour
`;

const hourEventTotal = (eventName: string, gym: boolean = false) => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) weekDay,
           FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) hour,
           COUNT(*) as ${eventName}_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`
        ${gym ? ',UNNEST(user_properties) p' : ''}
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND event_name = '${eventName}'
    GROUP BY weekDay, hour
`;

const hourRecipe = (gym: boolean = false) => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) weekDay,
           FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) hour,
    COUNT(*) as recipe_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        ${gym ? `UNNEST(user_properties) p,` : ''}
        UNNEST(event_params) e
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
        ${
          gym
            ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
            : ''
        }
      AND e.key = 'content_type'
      AND e.value.string_value = 'recipe'
    GROUP BY weekDay, hour
`;

const hourWorkout = (gym: boolean = false, workoutType: string = 'gym_strength') => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) weekDay,
           FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) hour,
    COUNT(*) as ${workoutType}_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        ${gym ? `UNNEST(user_properties) p,` : ''}
        UNNEST(event_params) e
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND e.key = 'workoutType'
      AND e.value.string_value = '${workoutType}'
    GROUP BY weekDay, hour
`;

const hourUsers = (gym: boolean = false) => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) weekDay,
           FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) hour,
    COUNT(DISTINCT user_id) as user_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        ${gym ? `UNNEST(user_properties) p,` : ''}
        UNNEST(event_params) e
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND e.key = 'athleteId'
    GROUP BY weekDay, hour
`;

const events = [
  'yogaSession_finished',
  'breathingSession_finished',
  'meditationSession_finished',
  'Workout_finished',
  'activity_logged',
  'FeedEntry_read',
];
