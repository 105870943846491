/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { UserRole } from '../../../../Model/UserRole';
import { User } from '../../../../Model/User';
import { UserTable } from '../../User/UserTable';

export type Props = {
  role: UserRole;
};

@observer
export class UserRoleViewUsersTab extends React.Component<Props> {
  @observable
  result: User[] = [];
  @observable
  page: number = 0;

  componentDidMount() {
    this.load(this.page);
  }

  async load(page: number) {
    this.result = await User.find({
      page,
      roles: this.props.role.id,
    });
  }

  handlePage = (page: number) => {
    if (this.page !== page) {
      this.page = page;
      this.load(this.page);
    }
  };

  render() {
    const { result } = this;
    return (
      <Row>
        <Col>
          <UserTable users={result} />
        </Col>
      </Row>
    );
  }
}
