/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { SuperMacroWorkoutDayConfiguration } from '../../../../../Model/Coach/SuperMacro/SuperMacroWorkoutDayConfiguration';
import styled from '@emotion/styled';
import { WorkoutEntryEditor } from './WorkoutEntryEditor';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';
import { MesoCycleTemplate } from '../../../../../Model/Coach/Program/Template/MesoCycleTemplate';
import { WorkoutDayConfiguration } from '../../../../../Model/Coach/Program/Template/WorkoutDayConfiguration';
import { WorkoutDayEntry } from '../../../../../Model/Coach/Program/Template/WorkoutDayEntry';
import { runInAction } from 'mobx';
import { Button, Popconfirm } from 'antd';
import { FitnessProgramTemplate } from '../../../../../Model/Coach/Program/Template/FitnessProgramTemplate';

export type WorkoutDayEditorProps = {
  template: FitnessProgramTemplate;
  workoutDay: WorkoutDayConfiguration;
  index: number;
  onRemove?: (workoutDay: WorkoutDayConfiguration, index: number) => void;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
`;

const Header = styled.div<{ dragOver?: boolean }>`
  padding: 8px;
  ${({ dragOver }) => (dragOver ? 'background-color: green;' : '')}
`;

const Title = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DropZone = styled.div`
  flex: 1;
`;

export const WorkoutDayEditor: React.FC<WorkoutDayEditorProps> = observer(({ template, workoutDay, index, onRemove }) => {
  const [dragOver, setDragOver] = useState(false);
  const timeout = React.useRef<any>(undefined);

  const handleDragOver = React.useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
    timeout.current && clearTimeout(timeout.current);
  }, []);

  const handleDragLeave = React.useCallback((event) => {
    event.preventDefault();
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setDragOver(false), 100);
  }, []);

  const handleDrop = React.useCallback(
    (event) => {
      const workoutTemplateId = event.dataTransfer.getData('workout-template-data');
      if (workoutTemplateId) {
        workoutDay.workouts.push(new WorkoutDayEntry({ workoutTemplateId }));
        // const workoutEntry = new WorkoutEntry({ workoutTemplateId });
        // onDrop && onDrop(workoutEntry);
      }
      setDragOver(false);
      // workoutDay.workouts.push(workoutEntry);
    },
    [workoutDay],
  );

  const handleRemoveEntry = React.useCallback(
    (entry) => {
      runInAction(() => (workoutDay.workouts = workoutDay.workouts.filter((w) => w.id !== entry.id)));
    },
    [workoutDay],
  );

  const handleRemove = React.useCallback(() => {
    onRemove && onRemove(workoutDay, index);
  }, [onRemove, workoutDay, index]);

  return (
    <Container>
      <Header
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragLeave}
        onDrop={handleDrop}
        dragOver={dragOver}
      >
        <Title>{`Day ${index + 1}`}</Title>
      </Header>
      <Body>
        {workoutDay.workouts.map((workoutEntry) => (
          <WorkoutEntryEditor
            key={workoutEntry.id}
            template={template}
            workoutEntry={workoutEntry}
            onRemove={handleRemoveEntry}
          />
        ))}
        <DropZone
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragLeave}
          onDrop={handleDrop}
          // dragOver={dragOver}
        />
        <Popconfirm title={`Remove Day ${index + 1}?`} onConfirm={handleRemove}>
          <Button danger type="link" block={true}>
            Remove Day
          </Button>
        </Popconfirm>
      </Body>
    </Container>
  );
});
