/**
 * Created by neo on 03.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, message, Modal, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Pipeline } from '../../../Model/Coach/Pipeline';
import { SuperMacro } from '../../../Model/Coach/SuperMacro/SuperMacro';
import { SingleColRow } from '../../../Components/SingleColRow';
import { SuperMacroTable } from './SuperMacroTable';

export type SuperMacroListScreenProps = {};

export const SuperMacroListScreen: React.FC<SuperMacroListScreenProps> = observer(
  (props: SuperMacroListScreenProps) => {
    const history = useNavigate();
    const [superMacros, setSuperMacros] = useState<SuperMacro[]>([]);
    const [checking, setChecking] = useState(false);

    const handleCreate = React.useCallback(() => {
      history('/coach/super-macro/create');
    }, [history]);

    const handleRemove = React.useCallback((superMacro: SuperMacro) => {
      superMacro.delete().then(() => {
        setSuperMacros((p) => p.filter((p0) => p0.id !== superMacro.id));
        message.success('Super Macro deleted');
      });
    }, []);

    const handleCopy = React.useCallback((pipeline: SuperMacro) => {
      pipeline
        .copy()
        .save()
        .then((result) => setSuperMacros((p) => [result].concat(p)));
    }, []);

    const handleCompleteCheck = React.useCallback(() => {
      setChecking(true);
      SuperMacro.completeCheck()
        .then(() =>
          Modal.success({
            title: 'All good!',
            content: 'Configuration matches all tags',
          }),
        )
        .catch((err) => {
          console.log(err);
          Modal.error({
            title: 'Missing paths!',
            content: err.data,
          });
        })
        .finally(() => setChecking(false));
    }, []);

    useEffect(() => {
      SuperMacro.find().then((result) => setSuperMacros(result));
    }, [setSuperMacros]);

    return (
      <Container>
        <Row>
          <Col>
            <h1>Super Macros</h1>
          </Col>
          <Col xs="auto">
            <Space>
              <Button type="primary" onClick={handleCreate} disabled={checking}>
                Create Super Macro
              </Button>
              <Button type="dashed" onClick={handleCompleteCheck} disabled={checking}>
                Check If Complete
              </Button>
            </Space>
          </Col>
        </Row>
        <SingleColRow>
          <SuperMacroTable superMacros={superMacros} onRemove={handleRemove} onCopy={handleCopy} />
        </SingleColRow>
      </Container>
    );
  },
);
