/**
 * Created by andreaskarantzas on 06.05.21.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Gym } from '../../../../../Model/Gym/Gym';
import { useEffect, useState } from 'react';
import { Button, Form, message } from 'antd';
import copy from 'copy-to-clipboard';

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export type GymInfoToCopyProps = {
  gym: Gym;
  isNew?: boolean;
};

export const GymInfoToCopy: React.FC<GymInfoToCopyProps> = observer(({ isNew, gym }) => {
  const [onboardingUrl, setOnboardingUrl] = useState<string | undefined>();
  const [copied, setCopied] = useState<boolean>(false);
  const [copiedId, setCopiedId] = useState<boolean>(false);

  const qrCodeUrl = onboardingUrl
    ? `https://api.kinastic.com/v1/media-qrcode?data=${encodeURI(onboardingUrl)}`
    : undefined;

  useEffect(() => {
    if (gym.id && !isNew) {
      sha256(gym.id).then((hash) => setOnboardingUrl(`https://onboarding.kinastic.com/intro?client=${hash}`));
    }
  }, [gym, isNew]);

  const handleCopy = React.useCallback(() => {
    copy(`${onboardingUrl}`);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [onboardingUrl]);

  const handleCopyId = React.useCallback(() => {
    copy(`${gym.id}`);
    setCopiedId(true);
    setTimeout(() => {
      setCopiedId(false);
    }, 3000);
  }, [gym]);

  return (
    <Form.Item label="Gym ID">
      <Row>
        <Col>{gym.id}</Col>
        <Col xs="auto">
          <Button type="primary" shape="round" size="small" onClick={handleCopyId}>
            {copiedId ? 'Copied!' : 'Copy to clipboard'}
          </Button>
        </Col>
      </Row>
    </Form.Item>
  );
});

const styles = {
  container: {
    paddingBottom: 16,
    paddingLeft: 0,
  },
};
