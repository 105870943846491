import { DefaultStageNodeModel } from '../../Default/DefaultStageNodeModel';
import { EqualsStage } from '../../../../../../../../Model/Coach/Stages/Logical/EqualsStage';

export class EqualsNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: EqualsStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'logical:eq' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
