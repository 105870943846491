/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { EarnModelTemplate } from '../../../Model/Engagement/EarnModelTemplate';
import dayjs from '../../../Utils/dayjs';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';

export type EarnModelTemplateTableRowProps = {
  template: EarnModelTemplate;
};

export const EarnModelTemplateTableRow: React.FC<EarnModelTemplateTableRowProps> = observer(({ template }) => {
  const handleCopy = React.useCallback(() => {
    template
      .copy()
      .save()
      .then(() => window.location.reload())
      .then(() => message.success('Template copied'))
      .catch(() => message.error('Failed to copy template'));
  }, [template]);

  return (
    <tr>
      <td>
        <Link to={`/engagement/earn-model-templates/${template.id}`}>{template.id}</Link>
      </td>
      <td>{template.gym?.name ?? ''}</td>
      <td>{template.templateName}</td>
      <td>{template.activeFrom ? dayjs(template.activeFrom).format('DD.MM.YYYY HH:mm') : ''}</td>
      <td>{template.activeUntil ? dayjs(template.activeUntil).format('DD.MM.YYYY HH:mm') : ''}</td>
      <td>
        <Button type="primary" size="small" onClick={handleCopy}>
          Copy
        </Button>
      </td>
    </tr>
  );
});
