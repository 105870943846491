/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { CoachExerciseBlock } from '../../../../../../Model/Coach/Program/Workout/CoachExerciseBlock';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import { ExerciseBlockExerciseVariationLink } from '../../../../../Planning/Workout/View/PhasesTable/ExerciseBlockExerciseVariationLink';
import { CardBody, Col, Row } from 'reactstrap';
import { ExerciseBlockMedia } from '../../../../../Planning/Workout/View/PhasesTable/ExerciseBlockMedia';
import { CoachSetsTable } from '../CoachSetsTable';

export type CoachExerciseBlockSuggestionRowProps = {
  exerciseBlock: CoachExerciseBlock;
  onClick?: (exerciseBlock: CoachExerciseBlock) => any;
};

export const CoachExerciseBlockSuggestionRow: React.FC<CoachExerciseBlockSuggestionRowProps> = observer(
  ({ exerciseBlock, onClick }: CoachExerciseBlockSuggestionRowProps) => {
    const handleClick = React.useCallback(() => {
      onClick && onClick(exerciseBlock);
    }, [exerciseBlock]);

    return (
      <CardBody style={{ padding: 8 }} onClick={handleClick}>
        <SingleColRow style={{ textAlign: 'center' }}>
          <ExerciseBlockExerciseVariationLink exercise={exerciseBlock.exercise} />
        </SingleColRow>
        <Row>
          <Col>
            <ExerciseBlockMedia exercise={exerciseBlock.exercise} />
          </Col>
          <Col>
            <CoachSetsTable exerciseBlock={exerciseBlock} />
          </Col>
        </Row>
      </CardBody>
    );
  },
);
