/**
 * Created by katarinababic on 15.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { FolderContent } from '../../../Model/Media/FileStorage/FolderContent';
import { Alert, Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

export type DeleteFolderDialogProps = {
  isOpen?: boolean;
  folder: FolderContent;
  onDeleteFolder?: () => void;
  onClose?: () => void;
};

export const DeleteFolderDialog: React.FC<DeleteFolderDialogProps> = observer(
  ({ isOpen, folder, onClose, onDeleteFolder }) => {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [processing, setProcessing] = React.useState(false);

    const toggle = React.useCallback(() => {
      if (isOpen) {
        onClose?.();
      }
    }, [onClose]);

    const dismiss = React.useCallback(() => {
      onClose?.();
    }, [onClose]);

    const handleDeleteFolder = React.useCallback(() => {
      setProcessing(true);
      FolderContent.deleteFolder([folder.name])
        .then((res) => {
          onDeleteFolder?.();
        })
        .catch((e) => setErrorMessage(e.message))
        .finally(() => setProcessing(false));
    }, [onClose, folder]);

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Delete Folder</ModalHeader>
        <ModalBody>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <Label>Are you sure you want to delete this folder?</Label>
        </ModalBody>
        <ModalFooter>
          {processing ? (
            <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </Col>
          ) : (
            <React.Fragment>
              <Button color="primary" onClick={handleDeleteFolder}>
                Delete
              </Button>
              <Button color="secondary" onClick={dismiss}>
                Cancel
              </Button>
            </React.Fragment>
          )}
        </ModalFooter>
      </Modal>
    );
  },
);
