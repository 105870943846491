import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { MapStage } from '../../../../../../../Model/Coach/Stages/MapStage';

export class MapNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: MapStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'map' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
