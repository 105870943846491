/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplate } from '../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategory } from '../../../../Model/Engagement/EarnModelTemplateCategory';
import { Button, Popconfirm, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EarnModelTemplateRuleAbstract } from '../../../../Model/Engagement/EarnModelTemplateRuleAbstract';
import { runInAction } from 'mobx';

export type EarnModelTemplateRuleTableRowProps = {
  template: EarnModelTemplate;
  category: EarnModelTemplateCategory;
  rule: EarnModelTemplateRuleAbstract;
};

export const EarnModelTemplateRuleTableRow: React.FC<EarnModelTemplateRuleTableRowProps> = observer(
  ({ template, category, rule }) => {
    const history = useNavigate();

    const handleEdit = React.useCallback(() => {
      history(`${category.id}/${rule.id}`);
    }, [category, history, rule]);

    const handleRemove = React.useCallback(() => {
      const index = category.rules.findIndex((r) => r.id === rule.id);
      if (index !== -1) {
        runInAction(() => category.rules.splice(index, 1));
      }
    }, [category, rule]);

    const handleCopy = React.useCallback(() => {
      const index = category.rules.findIndex((r) => r.id === rule.id);
      if (index !== -1) {
        const copy = rule.copy();
        copy.templateName = `Copy ${copy.templateName}`;
        if (index < category.rules.length - 1) {
          runInAction(() => category.rules.splice(index + 1, 0, copy));
        } else {
          runInAction(() => category.rules.push(copy));
        }
      }
    }, [category, rule]);

    return (
      <tr>
        <td>{rule.ruleIdentifier}</td>
        <td>{rule.type}</td>
        <td>{rule.maxActivations}</td>
        <td>{rule.maxPointsPerActivation}</td>
        <td>{rule.templateName}</td>
        <td>{rule.dependsOnRuleIds}</td>
        <td>
          <Space>
            <Button type="link" size="small" onClick={handleEdit}>
              Edit
            </Button>
            <Button type="link" size="small" onClick={handleCopy}>
              Copy
            </Button>
            <Popconfirm title={`Delete rule ${rule.templateName}?`} onConfirm={handleRemove}>
              <Button type="link" size="small">
                Remove
              </Button>
            </Popconfirm>
          </Space>
        </td>
      </tr>
    );
  },
);
