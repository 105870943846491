import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

// const styles = {
//   header: {
//     display: 'flex',
//     alignItems: 'center',
//     backgroundColor: '#1f1f1f'
//   },
//   title: {
//     flex: 1,
//     fontSize: '1.5rem',
//     margin: '1rem'
//   },
//   date: {
//     flex: 1,
//     textAlign: 'right',
//     margin: '1rem',
//     color: 'white'
//   }
// };

export type Props = {
  dropdown?: string;
  activeKey?: string;
  label: string;
  children?: any;
  onToggle?: (key?: string) => any;
};

@observer
export class DropdownHeader extends React.Component<Props> {
  toggleDropdown = () => {
    this.props.onToggle && this.props.onToggle(this.props.dropdown);
  };

  @computed
  get isOpen() {
    return this.props.dropdown === this.props.activeKey;
  }

  render() {
    const {
      isOpen,
      props: { label, children },
    } = this;
    return (
      <Dropdown nav isOpen={isOpen} toggle={this.toggleDropdown}>
        <DropdownToggle nav caret>
          {label}
        </DropdownToggle>
        <DropdownMenu>{children}</DropdownMenu>
      </Dropdown>
    );
  }
}
