/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { TagCondition } from '../../../../Model/Coach/SuperMacro/TagCondition';
import { TagConditionInput } from './TagConditionInput';
import styled from '@emotion/styled';
import { Button } from 'antd';

export type TagConditionsInputProps = {
  tagConditions: TagCondition[];
  onChange?: (tagConditions: TagCondition[]) => void;
  disabled?: boolean;
};

const Container = styled.div`
  display: flex;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.div`
  margin: 4px 0;
`;

const Extra = styled.div`
  margin-left: 8px;
`;

export const TagConditionsInput: React.FC<TagConditionsInputProps> = observer(
  ({ tagConditions, onChange, disabled }) => {
    const handleAddCondition = React.useCallback(() => {
      tagConditions.push(new TagCondition());
      onChange && onChange(tagConditions);
    }, [tagConditions, onChange]);

    const handleRemove = React.useCallback(
      (index) => {
        tagConditions.splice(index, 1);
        onChange && onChange(tagConditions);
      },
      [tagConditions, onChange],
    );

    const handleChange = React.useCallback(() => {
      onChange && onChange(tagConditions);
    }, [onChange, tagConditions]);

    return (
      <Container>
        <Body>
          {tagConditions.map((tagCondition, index) => (
            <Row key={`${index}`}>
              <TagConditionInput
                disabled={disabled}
                tagCondition={tagCondition}
                index={index}
                onRemove={handleRemove}
                onChange={handleChange}
              />
            </Row>
          ))}
        </Body>
        <Extra>
          <Button type="primary" onClick={handleAddCondition} disabled={disabled}>
            Add Condition
          </Button>
        </Extra>
      </Container>
    );
  },
);
