import { HealthDataPoint, HealthDataPointJson } from './HealthDataPoint';
import { HealthDataPointQuantityType } from './HealthDataPointQuantityType';
import { computed, observable } from 'mobx';
import { HealthDataPointType } from './HealthDataPointType';
import { BodyValueKeys } from '../Athlete/BodyValueKeys';
import Qty from 'js-quantities';

export type HealthDataPointQuantityJson = {
  type: HealthDataPointQuantityType;
  value: number;
} & HealthDataPointJson;

export class HealthDataPointQuantity extends HealthDataPoint {
  @observable
  type: HealthDataPointQuantityType = HealthDataPointQuantityType.activeEnergyBurned;
  @observable
  value: number = 0;

  constructor(json?: Partial<HealthDataPointQuantityJson>) {
    super(Object.assign(json ?? {}, { pointType: HealthDataPointType.quantity }));
    if (json) {
      this.type = json.type ?? HealthDataPointQuantityType.activeEnergyBurned;
      this.value = json.value ?? 0;
    }
  }

  toJS(newId?: boolean): HealthDataPointQuantityJson {
    return Object.assign(super.toJS(newId), {
      type: this.type,
      value: this.value,
    });
  }

  clone(data?: Partial<HealthDataPointQuantityJson>): HealthDataPointQuantity {
    return new HealthDataPointQuantity(Object.assign(this.toJS(true), data ?? {}));
  }

  save(): Promise<HealthDataPointQuantity> {
    return super.save().then(() => this);
  }

  @computed
  get qty(): Qty {
    const definition = BodyValueKeys.get(this.type);
    if (definition?.unit) {
      return new Qty(!isNaN(this.value) ? this.value : 0, definition.unit);
    }
    return new Qty(!isNaN(this.value) ? this.value : 0);
  }

  @computed
  get valueImperial() {
    const definition = BodyValueKeys.get(this.type);
    if (definition?.imperial?.unit) {
      return this.qty.to(definition.imperial.unit);
    }
    return this.qty;
  }
}
