/**
 * Created by neo on 02.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Checkbox, Form } from 'antd';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';

export type ExerciseSpineFlexingSelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExerciseSpineFlexingSelect: React.FC<ExerciseSpineFlexingSelectProps> = observer(
  ({ exercise, disabled }: ExerciseSpineFlexingSelectProps) => {
    const handleChange = React.useCallback(
      (e) => {
        exercise.spineFlexing = e.target.checked;
      },
      [exercise],
    );

    return (
      <Form.Item>
        <Checkbox checked={exercise.spineFlexing} onChange={handleChange} disabled={disabled}>
          Spine Flexing?
        </Checkbox>
      </Form.Item>
    );
  },
);
