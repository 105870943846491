/**
 * Created by neo on 20.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeWalkthroughEntry } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeWalkthroughEntry';
import { Button, Card, Form, message, Popconfirm, Upload } from 'antd';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Col, Row } from 'reactstrap';
import { RcFile } from 'antd/es/upload';
import MediaService from '../../../../../Services/MediaService';
import { runInAction } from 'mobx';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';

export type RouteChallengeWalkthroughEntryCardProps = {
  challenge: RouteChallenge;
  entry: RouteChallengeWalkthroughEntry;
};

export const RouteChallengeBeforeWalkthroughEntryCard: React.FC<RouteChallengeWalkthroughEntryCardProps> = observer(
  ({ challenge, entry }) => {
    const [uploading, setUploading] = React.useState(false);

    const beforeUpload = (file: RcFile) => {
      if (!file.type.toLowerCase().startsWith('image/')) {
        message.error('You can only upload JPG/PNG file!');
      } else if (file.size / 1024 / 1024 > 2) {
        message.error('Image must smaller than 2MB!');
      } else {
        setUploading(true);
        MediaService.uploadMedia(file)
          .then((media) => {
            runInAction(() => (entry.image = media));
          })
          .catch(() => message.error('Failed to upload image'))
          .finally(() => setUploading(false));
      }

      return false;
    };

    const uploadButton = (
      <div>
        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    const handleRemove = () => {
      const index = challenge.beforeStartWalkthroughEntries.findIndex((e) => e.id === entry.id);
      if (index !== -1) {
        runInAction(() => challenge.beforeStartWalkthroughEntries.splice(index, 1));
      }
    };

    return (
      <Card
        title={entry.defaultName.trim() || '<no title set yet>'}
        extra={
          <Popconfirm title={'Delete this entry?'} onConfirm={handleRemove}>
            <Button size="small" danger>
              Delete
            </Button>
          </Popconfirm>
        }
      >
        <Row>
          <Col md={6}>
            <Form.Item label="Title">
              <TranslationInputArray entity={entry} field="name" />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Description">
              <TranslationInputArray type="textarea" entity={entry} field="description" />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Image">
              <Upload
                key="avatar"
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                style={{ width: '100%' }}
              >
                {entry.image?.medium ? (
                  <img
                    src={entry.image?.medium}
                    alt="avatar"
                    style={{ width: '100%', height: 100, objectFit: 'contain' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  },
);
