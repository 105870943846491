/**
 * Created by katarinababic on 29.7.22.
 */

export const queryVideoFullscreenDismissed = `
SELECT
  t1.video_id,
  t1.total_count,
  t1.user_count,
  t2.answer_index,
  t2.answer_count,
  ROUND((t2.answer_count / t1.total_count) * 100, 0) AS answer_percentage
FROM (
  SELECT
    e1.value.string_value AS video_id,
    COUNT (*) AS total_count,
    COUNT(DISTINCT user_id) AS user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST (event_params) AS e1
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'videoFullscreenDismissed'
    AND e1.key = 'videoId'
    AND e1.value.string_value = @video_id
  GROUP BY
    video_id ) t1
LEFT JOIN (
  SELECT
    e1.value.string_value AS video_id,
    COUNT (*) AS answer_count,
    COUNT(DISTINCT user_id) AS user_count,
    e2.value.double_value AS answer_index
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST (event_params) AS e1,
    UNNEST (event_params) AS e2
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'videoFullscreenDismissed'
    AND e1.key = 'videoId'
    AND e1.value.string_value = @video_id
    AND e2.key = 'answerIndex'
    AND (e2.value.double_value = 0
      OR e2.value.double_value = 1
      OR e2.value.double_value = 2
      OR e2.value.double_value = 3
      OR e2.value.double_value = 4)
  GROUP BY
    video_id,
    answer_index ) t2
ON
  t1.video_id = t2.video_id`;
