/**
 * Created by katarinababic on 17.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type InnerLinkTextElementProps = {
  component: NativeFeedComponent;
  lang?: string;
};

export const InnerLinkTextElement: React.FC<InnerLinkTextElementProps> = observer(({ component, lang }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.text}>
        {component.getText(lang ?? 'de') ?? 'text before link '}
        <span className={classes.linkText}>{component.children[0].getText(lang ?? 'de') ?? 'link '}</span>
        {component.children[1].getText(lang ?? 'de') ?? 'text after link'}
      </span>
    </div>
  );
});

const useStyles = makeStyles()(() => ({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  text: {
    color: '#3c3c3c',
    fontSize: 16,
    fontFamily: 'Inter',
    textAlign: 'left',
  },
  linkText: {
    color: 'blue',
  },
}));
