/**
 * Created by neo on 29.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { ConditionalAttributeSource } from '../../../../../../../Model/Coach/Stages/Logical/LogicalStageConfig';
import { LogicalStage } from '../../../../../../../Model/Coach/Stages/Logical/LogicalStage';
import styled from '@emotion/styled';

export type LogicalSourceSelectProps = {
  stage: LogicalStage;
};

const StyledInput = styled.select`
  flex: 1;
  color: black;
`;

export const LogicalSourceSelect: React.FC<LogicalSourceSelectProps> = observer(
  ({ stage }: LogicalSourceSelectProps) => {
    const handleChange = React.useCallback(
      ({ target: { value } }: any) => {
        stage.config.source = value as ConditionalAttributeSource;
      },
      [stage],
    );

    return (
      <StyledInput onChange={handleChange} value={stage.config.source}>
        <option value="context">Context</option>
        <option value="input">Input</option>
      </StyledInput>
    );
  },
);
