import { Stage } from '../Stage';
import { LogicalStageConfig } from './LogicalStageConfig';
import { Signal } from '../../Signal/Signal';
import { EmptySignal } from '../../Signal/EmptySignal';
import { toJS } from 'mobx';
import { TruthySignal } from '../../Signal/TruthySignal';

export abstract class LogicalStage extends Stage<LogicalStageConfig> {
  abstract canProceed(signal: Signal): boolean;

  process() {
    let signals = this.take();
    while (signals.length > 0) {
      const result = signals.map((s) => this.canProceed(s)).filter((r) => r);
      console.log('logical::process', toJS(signals), toJS(result));
      if (result.length === signals.length && result.length > 0) {
        signals.forEach((signal, index) => {
          console.log(
            'logical::forward',
            signal instanceof EmptySignal ? new TruthySignal(index) : signal,
            toJS(signal),
          );
          this.processNext(signal instanceof EmptySignal ? new TruthySignal(index) : signal);
        });
      } else {
        this.processNext(new EmptySignal());
      }
      signals = this.take();
    }
  }

  getValue(): any {
    switch (this.config.valueType) {
      case 'number':
        return Number(this.config.value);
      case 'boolean':
        return !!this.config.value;
      case 'stringArray':
        return this.config.value.split(',').map((s) => s.trim());
      case 'numberArray':
        return this.config.value
          .split(',')
          .map((s) => s.trim())
          .map((s) => Number(s));
      case 'booleanArray':
        return this.config.value
          .split(',')
          .map((s) => s.trim())
          .map((s) => !!s);
      case 'string':
      default:
        return this.config.value;
    }
  }
}
