import * as React from 'react';
import { useLocalStore, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { ReactTags } from '../../../../Components/ReactTags';
import { ViewMedia } from '../../../../Components/ViewMedia/ViewMedia';
import { TrackingKeysSelectionView } from '../../Exercise/View/TrackingKeysSelectionView';
import { Activity } from '../../../../Model/Activity/Activity';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, Form, InputNumber, message, Space } from 'antd';

export type ActivityViewScreenProps = {};

export const ActivityViewScreen: React.FC<ActivityViewScreenProps> = observer((_: ActivityViewScreenProps) => {
  const { activityId } = useParams<any>();
  const history = useNavigate();

  const store = useLocalStore(() => ({
    activity: new Activity(),
    processing: false,
    load(activityId?: string) {
      if (activityId) {
        store.processing = true;
        Activity.get(activityId)
          .then((activity) => {
            runInAction(() => (store.activity = activity ?? new Activity()));
          })
          .finally(() => (store.processing = false));
      }
    },
    get assignedTags(): any[] {
      return store.activity.tags.map((t) => ({ id: t, name: t }));
    },
  }));

  useEffect(() => {
    store.load(activityId);
  }, [store, activityId]);

  const handleCreateNew = React.useCallback(() => {
    history('/metadata/activity/new', { replace: true });
    store.activity = new Activity();
  }, [store, history]);

  const handleSave = React.useCallback(async () => {
    store.processing = true;
    store.activity
      .save()
      .then(() => {
        if (activityId === 'new') {
          history(`/metadata/activity/${store.activity.id}`, { replace: true });
        }
      })
      .then(() => message.info('Activity successfully saved'))
      .catch(() => message.error('Failed to save activity'))
      .finally(() => (store.processing = false));
  }, [store, history, activityId]);

  const handleChangeIdentifier = React.useCallback(
    ({ target: { value } }: any) => {
      store.activity.identifier = value;
    },
    [store],
  );

  const handleAddTag = React.useCallback(
    (tag: string) => {
      store.activity.tags.push(tag);
    },
    [store],
  );

  const handleAddition = React.useCallback(
    (entry: { id: string; name: string }) => {
      if (-1 === store.activity.tags.findIndex((t) => t === entry.id)) {
        store.activity.tags.push(entry.id);
      }
    },
    [store],
  );

  const handleDelete = React.useCallback(
    (index: number) => {
      store.activity.tags.splice(index, 1);
    },
    [store],
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1>{store.activity.defaultName || '<no name>'}</h1>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="dashed" onClick={handleCreateNew} disabled={store.processing}>
              {'Create Another'}
            </Button>
            <Button type="primary" onClick={handleSave} disabled={store.processing}>
              {'Save'}
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormGroup>
            <Label>Identifier</Label>
            <Input type="text" onChange={handleChangeIdentifier} value={store.activity.identifier} />
          </FormGroup>
        </Col>
        <Col md="6">
          <TranslationInputArray entity={store.activity} field="name" label="Name" />
        </Col>
        <Col md="6">
          <TranslationInputArray entity={store.activity} field="description" label="Description" type="textarea" />
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>Tags</Label>
            <ReactTags
              tags={store.assignedTags}
              onAddition={handleAddition}
              onRemove={handleDelete}
              minQueryLength={1}
              allowNew={true}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>MET</Label>
            <Row>
              <InputNumber
                min={1}
                max={30}
                step={0.1}
                value={store.activity.met}
                onChange={(e) => (store.activity.met = Number(e) ?? 0.0)}
              />
            </Row>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label>Tracking Keys</Label>
            <TrackingKeysSelectionView container={store.activity.trackingKeys} />
          </FormGroup>
        </Col>
        <Col md="6">
          <ViewMedia medias={store.activity.medias} />
        </Col>
      </Row>
    </Container>
  );
});
