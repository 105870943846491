import { computed, observable } from 'mobx';
import { AxonSagaAssociation } from './AxonSagaAssociation';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export class AxonSaga {
  @observable
  id: string = '';
  @observable
  sagaType: string = '';
  @observable
  sagaIdentifier: string = '';
  @observable
  serializedSaga: string = '';
  @observable
  associations: Array<AxonSagaAssociation> = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.sagaType = json.sagaType;
      this.sagaIdentifier = json.sagaIdentifier;
      this.serializedSaga = json.serializedSaga;
      this.associations = (json.associations || []).map((a) => new AxonSagaAssociation(a));
    }
  }

  @computed
  get sagaTypeShort(): string {
    return this.sagaType.substr(this.sagaType.lastIndexOf('.') + 1);
  }

  static async find(params?: any): Promise<Array<AxonSaga>> {
    const res = await HttpBackend.get(`/infrastructure/saga`, params);
    return (res || []).map((s) => new AxonSaga(s));
  }
}
