/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Input, Label, FormGroup } from 'reactstrap';
import { EquipmentTypeInput } from '../EquipmentTypeInput';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { SingleColRow } from '../SingleColRow';

export type Props = {
  filter: ExerciseFilter;
};

@observer
export class ExerciseVariationFilterEquipmentTypesExcluded extends React.Component<Props> {
  @computed
  get value(): string[] {
    return this.props.filter.excludedEquipmentTypes || [];
  }

  render() {
    return <EquipmentTypeInput label="Excluded Equipment Types" container={this.value} />;
  }
}

// const ExerciseVariationsTab = withRouter(ExerciseVariationsTabComponent);
// export default ExerciseVariationsTab;
