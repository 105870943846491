import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { action, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Media, MediaJson } from '../../Media/Media';
import { MacroCycleLaneTemplate, MacroCycleLaneTemplateJson } from './MacroCycleLaneTemplate';
import { LocalizedValue } from '../../LocalizedValue';
import { TagCondition, TagConditionJson } from './TagCondition';

export type MacroCycleTemplateJson = LocalizedArrayEntityJson & {
  templateName?: string;
  image?: MediaJson;
  tagConditions: TagConditionJson[];
  tags: string[];
  lanes: MacroCycleLaneTemplateJson[];
};

export class MacroCycleTemplate extends LocalizedArrayEntity {
  @observable
  templateName?: string = undefined;
  @observable
  image?: Media = undefined;
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  tags: string[] = [];
  @observable
  lanes: MacroCycleLaneTemplate[] = [];

  constructor(json?: Partial<MacroCycleTemplateJson>) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.setData(json);
    }
  }

  toJS(replaceId: boolean = false): any {
    return Object.assign(super.toJS(), {
      id: replaceId ? UUID() : this.id,
      templateName: this.templateName,
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      lanes: this.lanes.map((m) => m.toJS(replaceId)),
      tags: toJS(this.tags),
    });
  }

  @action
  setData(json?: any) {
    this.templateName = json.templateName;
    this.image = json.image ? new Media(json.image) : undefined;
    this.name = (json.name || []).map((v) => new LocalizedValue(v));
    this.tags = json.tags || [];
    this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
    this.description = (json.description || []).map((v) => new LocalizedValue(v));
    this.lanes = (json.lanes ?? []).map((m) => new MacroCycleLaneTemplate(m));
  }
}
