import { toJS, observable, ObservableMap, computed } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { PageResult } from '../PageResult';
import { v4 as UUID } from 'uuid';
import { FeedObjectCollectionItem } from './FeedObjectCollectionItem';

export class FeedActivity {
  @observable
  id: string = UUID();
  @observable
  actor?: string = undefined;
  @observable
  verb: string = 'article';
  @observable
  object: FeedObjectCollectionItem = new FeedObjectCollectionItem();
  @observable
  feedType: 'flat' | 'category' | 'aggregated' = 'flat';
  @observable
  feedSlug?: string = undefined;
  @observable
  feedId?: string = undefined;
  @observable
  tags: Array<string> = [];
  @observable.shallow
  extraFields: ObservableMap<string, any> = observable.map({});
  @observable
  shareable: boolean = false;
  @observable
  priority: number = 0;
  @observable
  publishTime?: Date = new Date();
  @observable
  unpublishTime?: Date = undefined;
  @observable
  createdAt: Date = new Date();
  @observable
  updatedAt: Date = new Date();

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.actor = json.actor;
      this.verb = json.verb || 'article';
      this.object = new FeedObjectCollectionItem(json.object);
      this.tags = json.tags || [];
      this.extraFields = observable.map(json.extraFields || {});
      this.feedType = json.feedType || 'flat';
      this.feedSlug = json.feedSlug;
      this.feedId = json.feedId;
      this.shareable = json.shareable || false;
      this.priority = json.priority || 0;
      this.publishTime = json.publishTime ? new Date(json.publishTime) : undefined;
      this.unpublishTime = json.unpublishTime ? new Date(json.unpublishTime) : undefined;
      this.createdAt = json.createdAt ? new Date(json.createdAt) : new Date();
      this.updatedAt = json.updatedAt ? new Date(json.updatedAt) : new Date();
    }
  }

  delete() {
    return HttpBackend.delete(`/feed/v3/activities/${this.id}`);
  }

  publish() {
    return HttpBackend.post('/feed/v3', toJS(this));
  }

  @computed
  get valid(): boolean {
    return this.object.valid;
  }

  static async get(feedId: string): Promise<FeedActivity | undefined> {
    const data = await HttpBackend.get(`/feed/v3/activities/${feedId}`);
    if (data) {
      return new FeedActivity(data);
    }
    return undefined;
  }

  static async load(params?: any): Promise<PageResult<FeedActivity>> {
    const data = await HttpBackend.get('/feed/v3/activities', params);
    if (data && data.content) {
      data.content = data.content.map((f) => new FeedActivity(f));
      return new PageResult(data);
    }
    return new PageResult();
  }
}
