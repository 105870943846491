import { DefaultNodeModel, DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import { Stage } from '../../../../../../../Model/Coach/Stages/Stage';
import { DefaultStagePortModel } from './DefaultStagePortModel';
import { PortModelAlignment } from '@projectstorm/react-diagrams';

export class DefaultStageNodeModel extends DefaultNodeModel {
  constructor(public readonly stage: Stage<any>, options: any = {}) {
    super(
      Object.assign(
        {
          type: 'stage',
        },
        options || {},
      ),
    );
  }

  addInPort(label: string, after?: boolean): DefaultPortModel {
    const p = new DefaultStagePortModel(this.stage.pipeline, this.stage, {
      in: true,
      name: label,
      label: label,
      alignment: PortModelAlignment.LEFT,
    });
    if (!after) {
      this.portsIn.splice(0, 0, p);
    }
    return this.addPort(p);
  }

  addOutPort(label: string, after?: boolean): DefaultPortModel {
    const p = new DefaultStagePortModel(this.stage.pipeline, this.stage, {
      in: false,
      name: label,
      label: label,
      alignment: PortModelAlignment.RIGHT,
    });
    if (!after) {
      this.portsOut.splice(0, 0, p);
    }
    return this.addPort(p);
  }
}
