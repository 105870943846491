/**
 * Created by katarinababic on 30/12/2020.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ParagraphElement } from './ParagraphElement';
import { HeadingElement } from './HeadingElement';
import { CaptionElement } from './CaptionElement';
import { ImageElement } from './ImageElement';
import { VideoElement } from './VideoElement';
import { ButtonElement } from './ButtonElement';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { SummaryElement } from './SummaryElement';
import { InnerBoldTextElement } from './InnerBoldTextElement';
import { InnerLinkTextElement } from './InnerLinkTextElement';
import { BulletPointElement } from './BulletPointElement';
import { NumberPointElement } from './NumberPointElement';
import { SecondaryButtonElement } from './SecondaryButtonElement';
import { ItalicParagraphElement } from './ItalicParagraphElement';
import { ItalicHeadingElement } from './ItalicHeadingElement';

export type ElementContentProps = {
  component: NativeFeedComponent;
  isHover?: boolean;
  lang?: string;
};

export const ElementContent: React.FC<ElementContentProps> = observer(({ component, isHover, lang }) => {
  switch (component.type) {
    case 'Image':
      return <ImageElement component={component} />;
    case 'Video':
      return <VideoElement component={component} />;
    case 'Paragraph':
      return <ParagraphElement component={component} lang={lang} />;
    case 'Button':
      return <ButtonElement component={component} isHover={isHover} lang={lang} />;
    case 'SecondaryButton':
      return <SecondaryButtonElement component={component} isHover={isHover} lang={lang} />;

    // the following are the old components, before markdown was implemented
    case 'Heading':
      return <HeadingElement component={component} lang={lang} />;
    case 'ItalicHeading':
      return <ItalicHeadingElement component={component} lang={lang} />;
    case 'ItalicParagraph':
      return <ItalicParagraphElement component={component} lang={lang} />;
    case 'Caption':
      return <CaptionElement component={component} lang={lang} />;
    case 'Summary':
      return <SummaryElement component={component} lang={lang} />;
    case 'InnerBoldText':
      return <InnerBoldTextElement component={component} lang={lang} />;
    case 'InnerLinkText':
      return <InnerLinkTextElement component={component} lang={lang} />;
    case 'BulletPoint':
      return <BulletPointElement component={component} lang={lang} />;
    case 'NumberPoint':
      return <NumberPointElement component={component} lang={lang} />;
    default:
      return null;
  }
});
