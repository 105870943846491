/**
 * Created by neo on 01.11.2023
 */
import { Audited, AuditedJson } from '../../Audited';
import { DateRange, DateRangeJson } from '../../DateRange';
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type GymEarnModelConfigurationJson = AuditedJson & {
  gymId: string;
  defaultEarnModelTemplateId: string;
  spendableTimeFrames: DateRangeJson[];
  rewardPointsConversionFactor: number;
  rewardPointsCollectionEnabled: boolean;
};

export class GymEarnModelConfiguration extends Audited {
  @observable
  gymId = '';
  @observable
  defaultEarnModelTemplateId = '';
  @observable
  spendableTimeFrames: DateRange[] = [];
  @observable
  rewardPointsConversionFactor = 1.0;
  @observable
  rewardPointsCollectionEnabled = false;

  constructor(json?: Partial<GymEarnModelConfigurationJson>) {
    super(json);
    if (json) {
      this.gymId = json.gymId ?? '';
      this.defaultEarnModelTemplateId = json.defaultEarnModelTemplateId ?? '';
      this.spendableTimeFrames = json.spendableTimeFrames?.map((d) => new DateRange(d)) ?? [];
      this.rewardPointsConversionFactor = json.rewardPointsConversionFactor ?? 1.0;
      this.rewardPointsCollectionEnabled = json.rewardPointsCollectionEnabled ?? false;
    }
  }

  save(): Promise<GymEarnModelConfiguration> {
    return HttpBackend.post(`/engagement/earn/gym/configuration`, {
      gymEarnModelConfigurationId: this.id,
      gymId: this.gymId,
      defaultEarnModelTemplateId: this.defaultEarnModelTemplateId,
      spendableTimeFrames: this.spendableTimeFrames.map((d) => d.toJS()),
      rewardPointsConversionFactor: this.rewardPointsConversionFactor,
      rewardPointsCollectionEnabled: this.rewardPointsCollectionEnabled,
    }).then((res) => this);
  }

  toJS(newId?: boolean): AuditedJson {
    return Object.assign(super.toJS(), {
      gymId: this.gymId,
      defaultEarnModelTemplateId: this.defaultEarnModelTemplateId,
      spendableTimeFrames: this.spendableTimeFrames.map((d) => d.toJS()),
      rewardPointsConversionFactor: this.rewardPointsConversionFactor,
      rewardPointsCollectionEnabled: this.rewardPointsCollectionEnabled,
    });
  }

  static findByGymId(gymId: string): Promise<GymEarnModelConfiguration> {
    return HttpBackend.get(`/engagement/earn/gym/configuration/${gymId}`).then(
      (res) => new GymEarnModelConfiguration(res),
    );
  }
}
