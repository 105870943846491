import { action, computed, observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { languagePriority } from '../LocalizedArrayEntity';
import dayjs, { Dayjs } from 'dayjs';

export type MediaOverlayJson = {
  startTime: string;
  endTime?: string;
  text: LocalizedValueJson[];
};

export class MediaOverlay {
  @observable
  startTime: string = '00:00:00.000';
  @observable
  endTime?: string = undefined;
  @observable
  text: LocalizedValue[] = [];

  constructor(json?: Partial<MediaOverlayJson>) {
    if (json) {
      this.startTime = json.startTime ?? '00:00:00.000';
      this.endTime = json.endTime;
      this.text = (json.text ?? []).map((t) => new LocalizedValue(t));
    }
  }

  @action
  setStartSeconds(seconds: number) {
    this.startTime = MediaOverlay.calculateTime(seconds);
  }

  @action
  setEndSeconds(seconds?: number) {
    this.endTime = seconds ? MediaOverlay.calculateTime(seconds) : undefined;
  }

  getText(language: string) {
    return this.text.find((l) => l.lang.toLowerCase() === language.toLowerCase())?.value ?? this.defaultText;
  }

  toJS() {
    return {
      startTime: this.startTime,
      endTime: this.endTime,
      text: this.text.map((t) => t.toJS()),
    };
  }

  @computed
  get startSeconds(): number {
    const split = this.startTime.split(':').reverse();
    const seconds = split.length > 0 ? Number(split[0]) : 0;
    const minutes = split.length > 1 ? Number(split[1]) : 0;
    const hours = split.length > 2 ? Number(split[2]) : 0;
    return hours * 3600 + minutes * 60 + seconds;
  }

  @computed
  get endSeconds(): number | undefined {
    if (this.endTime) {
      const split = this.endTime.split(':').reverse();
      const seconds = split.length > 0 ? Number(split[0]) : 0;
      const minutes = split.length > 1 ? Number(split[1]) : 0;
      const hours = split.length > 2 ? Number(split[2]) : 0;
      return hours * 3600 + minutes * 60 + seconds;
    }
    return undefined;
  }

  @computed
  get defaultText(): string {
    for (const lang of languagePriority) {
      const entry = this.text.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.text[0];
    return first?.value ?? '';
  }

  static calculateTime(videoSeconds: number) {
    const duration = dayjs.duration(videoSeconds, 'seconds');
    const hours = duration.hours(); // Math.trunc(videoSeconds / 3600);
    const minutes = duration.minutes(); // Math.trunc((videoSeconds - hours * 3600) / 60);
    const seconds = duration.seconds(); // Math.trunc(videoSeconds - hours * 3600 - minutes * 60);
    const milliseconds = duration.milliseconds().toFixed(0); // ((videoSeconds - hours * 3600 - minutes * 60 - seconds) * 1000).toFixed(0);
    return `${hours.toString(10).padStart(2, '0')}:${minutes.toString(10).padStart(2, '0')}:${seconds
      .toString(10)
      .padStart(2, '0')}.${milliseconds.padStart(3, '0')}`;
  }
}
