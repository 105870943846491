/**
 * Created by neo on 07.06.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { WebinarEntry } from '../../../../../Model/Explore/WebinarEntry';
import { Tabs } from 'antd';
import { TabBasicInfo } from '../../ExploreEntryModal/TabBasicInfo';
import { ExploreEntryModalVideoImageFields } from '../../ExploreEntryModal/ExploreEntryModalVideoImageFields';
import { TabNotification } from '../../ExploreEntryModal/TabNotification';
import { ExploreEntryContentSwitch } from '../ExploreEntryContentSwitch';
import { ExploreEntryModalAnalytics } from '../../ExploreEntryModal/ExploreEntryModalAnalytics/ExploreEntryModalAnalytics';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import { FaqRequestsTabContent } from './FaqRequestsTabContent';
import { FaqAnswersTabContent } from './FaqAnswersTabContent';
import { ExploreFaqRequest } from '../../../../../Model/Explore/ExploreFaqRequest';
import { FaqRequestsAnsweredTabContent } from './FaqRequestsAnsweredTabContent';

export type FaqTabContentJson = {
  entry: ExploreEntry;
};

export const FaqTabContent: React.FC<FaqTabContentJson> = observer(({ entry }) => {
  const [activeTab, setActiveTab] = useState('answers');
  const [requestsCount, setRequestsCount] = useState(0);

  useEffect(() => {
    ExploreFaqRequest.count({ contentId: entry.id, answered: false }).then((count) => setRequestsCount(count));
  }, [entry]);

  const handleChangeTab = (newTab: string) => setActiveTab(newTab);

  return (
    <Tabs activeKey={activeTab} onChange={handleChangeTab} destroyInactiveTabPane={true}>
      <Tabs.TabPane tab="Answers" key="answers">
        <FaqAnswersTabContent entry={entry} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={`Requests (${requestsCount})`} key="requests">
        <FaqRequestsTabContent entry={entry} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={`Answered Requests`} key="requests-answered">
        <FaqRequestsAnsweredTabContent entry={entry} />
      </Tabs.TabPane>
    </Tabs>
  );
});
