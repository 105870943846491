/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DiagramEngine, PortModelAlignment, PortWidget, DefaultPortLabel } from '@projectstorm/react-diagrams';
import styled from '@emotion/styled';
import { NodeColors } from '../../NodeColors';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { NodeWidgetHeaderTitle } from '../Default/NodeWidgetHeaderTitle';
import { NodeWidgetHeaderLink } from '../Default/NodeWidgetHeaderLink';
import { LogicalSourceSelect } from './LogicalSourceSelect';
import { LogicalStage } from '../../../../../../../Model/Coach/Stages/Logical/LogicalStage';
import { ConfigAttributeSelect } from '../ConfigAttributeSelect';
import { StyledInput } from '../StyledInput';

export type LogicalNodeWidgetProps = {
  node: DefaultStageNodeModel;
  engine: DiagramEngine;
  size?: number;
  title?: string;
};

const StyledNode = styled.div<{ background: string; selected: boolean }>`
  background-color: ${(p) => p.background};
  border-radius: 5px;
  font-family: sans-serif;
  color: white;
  border: solid 2px black;
  overflow: visible;
  font-size: 11px;
  border: solid 2px ${(p) => (p.selected ? 'rgb(0,192,255)' : 'black')};
`;

const StyledTitle = styled.div`
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  white-space: nowrap;
  justify-items: center;
`;

const StyledPorts = styled.div`
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
`;

const StyledPortsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: 10px;
  }
  &:only-child {
    margin-right: 0px;
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledRow = styled.div`
  display: flex;
  flex: 1;
  padding: 2px;
`;

const StyledSelect = styled.select`
  flex: 1;
  color: black;
  margin-right: 4px;
`;

export const LogicalNodeWidget: React.FC<LogicalNodeWidgetProps> = observer(
  ({ title, node, engine }: LogicalNodeWidgetProps) => {
    const generatePort = (port) => {
      return <DefaultPortLabel engine={engine} port={port} key={port.getID()} />;
    };

    const handleChangeValue = React.useCallback(
      ({ target: { value } }) => {
        node.stage.config.value = value;
      },
      [node],
    );

    const handleExecute = React.useCallback(
      (e) => {
        e.preventDefault();
        node.fireEvent({}, 'executeClicked');
      },
      [node],
    );

    const handleChangeValueType = React.useCallback(
      ({ target: { value } }: any) => {
        node.stage.config.valueType = value;
      },
      [node],
    );

    return (
      <StyledNode
        data-default-node-name={node.stage.type}
        selected={node.isSelected()}
        background={NodeColors[node.stage.type] ?? NodeColors.default}
      >
        <StyledTitle>
          <NodeWidgetHeaderTitle title={title ?? node.stage.type} />
          <NodeWidgetHeaderLink onClick={handleExecute} label="Execute" />
        </StyledTitle>
        <StyledPorts>
          <StyledPortsContainer>{node.getInPorts().map((p) => generatePort(p))}</StyledPortsContainer>
          <StyledContentContainer>
            <StyledRow>
              <LogicalSourceSelect stage={node.stage as LogicalStage} />
            </StyledRow>
            <StyledRow>
              <ConfigAttributeSelect node={node} />
            </StyledRow>
            <StyledRow>
              <StyledSelect value={node.stage.config.valueType} onChange={handleChangeValueType}>
                <option value="string">string</option>
                <option value="number">number</option>
                <option value="boolean">boolean</option>
                <option value="stringArray">String Array</option>
                <option value="numberArray">Number Array</option>
                <option value="booleanArray">Boolean Array</option>
              </StyledSelect>
              <StyledInput node={node} value={node.stage.config.value} onChange={handleChangeValue} />
            </StyledRow>
          </StyledContentContainer>
          <StyledPortsContainer>{node.getOutPorts().map((p) => generatePort(p))}</StyledPortsContainer>
        </StyledPorts>
      </StyledNode>
    );
  },
);
