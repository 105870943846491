/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { MeditationEntry } from '../../../../../Model/Explore/MeditationEntry';
import { MeditationNarrator } from '../../../../../Model/Explore/MeditationNarrator';
import { MediaNarratorAudioTrack } from '../../../../../Model/Explore/MediaNarratorAudioTrack';
import { Col, Row } from 'reactstrap';
import { Button, Input, Select } from 'antd';
import { runInAction } from 'mobx';

const defaultLanguages = ['en', 'de', 'fr', 'it', 'es', 'cn', 'fi'];

export type MediaNarratorAudioTrackEntryProps = {
  entry: MeditationEntry;
  narrator: MeditationNarrator;
  audioTrack: MediaNarratorAudioTrack;
};

export const MediaNarratorAudioTrackEntry: React.FC<MediaNarratorAudioTrackEntryProps> = observer(
  ({ entry, narrator, audioTrack }) => {
    const handleChangeLanguage = React.useCallback((lang: string) => runInAction(() => (audioTrack.language = lang)), [
      audioTrack,
    ]);

    const handleRemove = React.useCallback(() => {
      runInAction(
        () => (narrator.audioTracks = narrator.audioTracks.filter((a) => a.media.url !== audioTrack.media.url)),
      );
    }, [narrator, audioTrack]);

    return (
      <Row>
        <Col>
          <Select className="select-before" value={audioTrack.language} onChange={handleChangeLanguage}>
            {defaultLanguages.map((lang) => (
              <Select.Option key={lang} value={lang}>
                {lang.toLowerCase()}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <audio controls>
            <source src={audioTrack.media.url} />
          </audio>
        </Col>
        <Col xs="auto">
          <Button danger size="small" onClick={handleRemove}>Remove</Button>
        </Col>
      </Row>
    );
  },
);
