/**
 * Created by neo on 13.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../Model/Gym/Gym';
import { UserStatisticsTables } from './UserStatisticsTables';

export type UserStatisticsTabProps = {
  gym?: Gym;
};

export const UserStatisticsTab: React.FC<UserStatisticsTabProps> = observer(({ gym }) => {
  return (
    <React.Fragment>
      <UserStatisticsTables gym={gym} />
    </React.Fragment>
  );
});
