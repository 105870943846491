/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps, StyledRow } from '../Default/DefaultStageNodeWidget';
import { ConfigAttributeSelect } from '../ConfigAttributeSelect';
import { SortingDirection } from './SortingDirection';
import { Button, Form, Input } from 'antd';
import { SingleColRow } from '../../../../../../../Components/SingleColRow';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { StyledInput } from '../StyledInput';
import { SortNodeModel } from './SortNodeModel';

export type SortNodeWidgetProps = {
  node: SortNodeModel;
} & StageNodeWidgetProps;

export const SortNodeWidget: React.FC<SortNodeWidgetProps> = observer(
  ({ node, node: { stage }, engine, size }: SortNodeWidgetProps) => {
    const handleChangeSort = React.useCallback(
      (index: number, value: string) => {
        if (stage.config.customSort) {
          stage.config.customSort[index] = value;
        }
      },
      [stage],
    );

    const handleRemoveSort = React.useCallback(
      (index: number) => {
        stage.config.customSort?.splice(index, 1);
      },
      [stage],
    );

    const handleAddCustomSort = React.useCallback(() => {
      stage.config.customSort = stage.config.customSort || [];
      stage.config.customSort?.push('');
    }, [stage]);

    return (
      <DefaultStageNodeWidget
        engine={engine}
        node={node}
        size={size}
        disableEdit={true}
        content={
          <React.Fragment>
            <StyledRow>
              <ConfigAttributeSelect node={node} />
            </StyledRow>
            <StyledRow>
              <SortingDirection node={node} />
            </StyledRow>
            <StyledRow>
              <SingleColRow>
                {stage.config.customSort?.map((field, index) => (
                  <Form.Item required={false} key={`${index}`}>
                    <Form.Item noStyle>
                      <StyledInput
                        value={field}
                        onChange={({ target: { value } }) => {
                          handleChangeSort(index, value);
                        }}
                        node={node}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{ margin: '0 8px' }}
                      onClick={() => {
                        handleRemoveSort(index);
                      }}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={handleAddCustomSort}>
                    <PlusOutlined /> Add field
                  </Button>
                </Form.Item>
              </SingleColRow>
            </StyledRow>
          </React.Fragment>
        }
      />
    );
  },
);
