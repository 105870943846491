/**
 * Created by neo on 10.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { DefaultStageNodeModel } from './Default/DefaultStageNodeModel';

export type StyledInputProps = {
  value: any;
  node: DefaultStageNodeModel;
  onChange?: (event: any) => any;
  onFocus?: () => any;
  onBlur?: () => any;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
};

const StyledInputStyle = styled.input`
  flex: 1;
  color: black;
  border: 1px solid grey;
  border-radius: 4px;
  &[disabled] {
    background-color: lightgrey;
  }
`;

export const StyledInput: React.FC<StyledInputProps> = observer(
  ({ node, value, type, onChange, onFocus, onBlur, disabled, placeholder }: StyledInputProps) => {
    const handleFocus = React.useCallback(() => {
      node.setLocked(true);
    }, [node]);

    const handleBlur = React.useCallback(() => {
      node.setLocked(false);
    }, [node]);

    return (
      <StyledInputStyle
        type={type ?? 'text'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  },
);
