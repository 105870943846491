/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  DefaultStageNodeWidget,
  StageNodeWidgetProps,
  StyledColumn,
  StyledRow,
} from '../Default/DefaultStageNodeWidget';
import { ExerciseBlockStage } from '../../../../../../../Model/Coach/Stages/ExerciseBlockStage';
import { Button, Form, InputNumber } from 'antd';
import { StyledInput } from '../StyledInput';
import styled from '@emotion/styled';
import { SingleColRow } from '../../../../../../../Components/SingleColRow';
import { MinusCircleOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons/lib';
import { TrackingKeySelection } from './TrackingKeySelection';
import { availableTrackingKeys } from '../../../../../../../Model/ProgramPortfolio/TrackingKeys';

export type ExerciseBlockNodeWidgetProps = {} & StageNodeWidgetProps;

const StyledLabel = styled.div`
  color: black;
  font-size: 10px;
`;

export const ExerciseBlockNodeWidget: React.FC<ExerciseBlockNodeWidgetProps> = observer(
  ({ node, engine, size }) => {
    const stage = node.stage as ExerciseBlockStage;

    const usedTrackingKeys = stage.config.trackingKeys?.map((t) => t.key) ?? [];

    const selectableTrackingKeys = availableTrackingKeys.filter(
      (t) => usedTrackingKeys.findIndex((t1) => t1 === t) === -1,
    );

    const handleChangeStartSets = React.useCallback(
      ({ target: { value } }) => (stage.config.startSets = value?.startsWith('$') ? value : Number(value) ?? 0),
      [stage],
    );

    const handleAddTrackingKey = React.useCallback(() => {
      stage.config.trackingKeys = stage.config.trackingKeys || [];
      if (selectableTrackingKeys.length > 0) {
        stage.config.trackingKeys.push({
          key: selectableTrackingKeys[0],
          minValue: 0,
        });
      }
    }, [selectableTrackingKeys, stage]);

    const handleRemoveTrackingKey = React.useCallback(
      (index: number) => {
        stage.config.trackingKeys?.splice(index, 1);
      },
      [stage],
    );

    const handleChangeMinValue = React.useCallback(
      (trackingKey, value) => (trackingKey.minValue = value?.startsWith('$') ? value : Number(value) ?? 0),
      [],
    );

    return (
      <DefaultStageNodeWidget
        engine={engine}
        node={node}
        size={size}
        disableEdit={true}
        content={
          <React.Fragment>
            <StyledRow>
              <StyledColumn>
                <StyledRow>
                  <StyledLabel>Sets</StyledLabel>
                </StyledRow>
                <StyledInput node={node} onChange={handleChangeStartSets} value={stage.config.startSets} />
              </StyledColumn>
            </StyledRow>
            <StyledRow>
              <SingleColRow>
                {stage.config.trackingKeys?.map((trackingKey, index) => (
                  <Form.Item required={false} key={`${index}`}>
                    <Form.Item noStyle>
                      <TrackingKeySelection stage={stage} trackingKey={trackingKey} />
                    </Form.Item>
                    <Form.Item noStyle>
                      <StyledInput
                        value={trackingKey.minValue}
                        onChange={({ target: { value } }) => {
                          handleChangeMinValue(trackingKey, value);
                        }}
                        node={node}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{ margin: '0 8px' }}
                      onClick={() => {
                        handleRemoveTrackingKey(index);
                      }}
                    />
                  </Form.Item>
                ))}
                {selectableTrackingKeys.length > 0 ? (
                  <Form.Item>
                    <Button type="dashed" onClick={handleAddTrackingKey}>
                      <PlusOutlined /> Add Tracking Key
                    </Button>
                  </Form.Item>
                ) : null}
              </SingleColRow>
            </StyledRow>
          </React.Fragment>
        }
      />
    );
  },
);
