/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Pipeline } from '../../../../Model/Coach/Pipeline';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';

export type PipelineTagsInputProps = {
  pipeline: Pipeline;
};

export const PipelineTagsInput: React.FC<PipelineTagsInputProps> = observer((props: PipelineTagsInputProps) => {
  const { pipeline } = props;
  const store = useLocalStore(
    (source: PipelineTagsInputProps) => ({
      get tags(): any[] {
        return source.pipeline.tags.map((value) => ({ label: value, value }));
      },
    }),
    props,
  );

  const handleChange = React.useCallback(
    (tags?: any[]) => {
      console.log('tags', tags);
      runInAction(() => {
        pipeline.tags.splice(0, pipeline.tags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => pipeline.tags.push(t));
      });
    },
    [pipeline],
  );

  return <CreatableSelect isClearable isMulti onChange={handleChange as any} value={store.tags} />;
});
