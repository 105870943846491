/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { WorkoutEntry } from '../../../../../Model/Coach/SuperMacro/WorkoutEntry';
import styled from '@emotion/styled';
import { SuperMacroWorkoutDayConfiguration } from '../../../../../Model/Coach/SuperMacro/SuperMacroWorkoutDayConfiguration';
import { WorkoutEntryDropZone } from './WorkoutEntryDropZone';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';

export type WorkoutEntryEditorProps = {
  mesoCycle: SuperMacroMesoCycleTemplate;
  workoutDay: SuperMacroWorkoutDayConfiguration;
  workoutEntry: WorkoutEntry;
  onRemove?: (workoutEntry: WorkoutEntry) => any;
};

const Container = styled.div`
  color: black;
  background-color: #414bb2;
  font-family: Helvetica, Arial;
  font-size: 12px;
  padding: 5px;
  color: white;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
`;

const Title = styled.div`
  flex: 1;
  font-size: 10px;
`;

const Extra = styled.div`
  font-size: 10px;

  a,
  a:visited {
    font-size: 12px;
    color: #ff4d4f;

    &:hover,
    &:active {
      color: #ff7875;
    }
  }
`;

const Body = styled.div``;

export const WorkoutEntryEditor: React.FC<WorkoutEntryEditorProps> = observer(
  ({ workoutEntry, mesoCycle, workoutDay, onRemove }: WorkoutEntryEditorProps) => {
    const [over, setOver] = useState(false);

    const handleRemove = React.useCallback(
      (e) => {
        e.preventDefault();
        onRemove && onRemove(workoutEntry);
      },
      [onRemove, workoutEntry],
    );

    const handleDragOver = React.useCallback((event) => {
      console.log('dragOver');
      event.preventDefault();
      setOver(true);
    }, []);

    const handleDragLeave = React.useCallback((event) => {
      console.log('dragLEave');
      event.preventDefault();
      setOver(false);
    }, []);

    const handleDrop = React.useCallback(
      (workoutEntry) => {
        mesoCycle.save();
        setOver(false);
      },
      [mesoCycle],
    );

    return (
      <Container onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
        <Header>
          <Title>{workoutEntry.includedTags.join(', ')}</Title>
          {/*<Extra>*/}
          {/*  <a href="" onClick={handleRemove}>*/}
          {/*    Remove*/}
          {/*  </a>*/}
          {/*</Extra>*/}
        </Header>
        <Body>
          <WorkoutEntryDropZone workoutEntry={workoutEntry} onDrop={handleDrop} />
        </Body>
      </Container>
    );
  },
);
