/**
 * Created by neo on 17.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Modal, Space, Switch, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeEditModalBasicInfo } from './RecipeEditModalBasicInfo';
import { RecipeEditModalIngredients } from './RecipeEditModalIngredients';
import { RecipeEditModalInstructionsTab } from './RecipeEditModalInstructionsTab';
import { RecipeEditModalChatGptJsonTab } from './RecipeEditModalChatGptJsonTab';
import { RecipeEditModalNutrientsTab } from './RecipeEditModalNutrientsTab';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { runInAction } from 'mobx';
import { RecipeInstruction } from '../../../../Model/Diet/Recipe/RecipeInstruction';

export type RecipeEditModalProps = {
  onSaved?: () => void;
  onArchived?: () => void;
};

export const RecipeEditModal: React.FC<RecipeEditModalProps> = observer(({ onSaved, onArchived }) => {
  const history = useNavigate();
  const { recipeId } = useParams<{ recipeId: string }>();
  const [processing, setProcessing] = useState(false);
  const [checkingGpt, setCheckingGpt] = useState(false);
  const [gptDebug, setGptDebug] = useState('');
  const [recipe, setRecipe] = useState(new Recipe());
  const [activeTab, setActiveTab] = useState('basic');
  const isNew = recipeId === 'new';

  useEffect(() => {
    if (recipeId && recipeId !== 'new') {
      setProcessing(true);
      Recipe.get(recipeId)
        .then((result) => setRecipe(result))
        .finally(() => setProcessing(false));
    } else {
      setRecipe(new Recipe());
    }
  }, [recipeId]);

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    recipe
      .save()
      .then(() => setProcessing(false))
      .then(() => onSaved && onSaved());
  }, [recipe, onSaved]);

  const handleCancel = React.useCallback(() => {
    history(-1);
  }, [history]);

  const handleArchive = React.useCallback(() => {
    setProcessing(true);
    recipe
      .archive()
      .then(() => onArchived && onArchived())
      .then(() => history(-1))
      .finally(() => setProcessing(false));
  }, [history, recipe, onArchived]);

  const handleRestore = React.useCallback(() => {
    setProcessing(true);
    recipe.archived = false;
    recipe
      .save()
      .then(() => onSaved && onSaved())
      .then(() => history(-1))
      .finally(() => setProcessing(false));
  }, [history, onSaved, recipe]);

  const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

  const handleSetProcessing = React.useCallback(() => setProcessing(true), []);

  const handleUnsetProcessing = React.useCallback(() => setProcessing(false), []);

  const handleCheckRecipeGpt = React.useCallback(() => {
    setProcessing(true);
    setCheckingGpt(true);
    new GptResponseService([
      {
        role: 'system',
        content: `You are a recipe database and respond ONLY in JSON and exactly in the format as given. Your job is to check the recipe and find out what is missing or incorrectly described.
        You furthermore should simplify the instructions whenever possible so one thing after another is done.
      The recipes should not be translated from American recipes and just transferred to metric scale but they need to be authentic so that Swiss people feel spoken for.
      Use informal language.
      Rinderstreifen should be Rindsstreifen
      Rinderfilet should be Rindsfilet
      Lasagneplatten should be Lasagneplätter
      When you refer to the vegetable paprika, please use the word peperoni for Swiss audience
      Instead of kirschtomaten use cherry-tomaten
      Randen instead of Rote Beeten
      Paniermehl instead of semmelbröt
      Hacktäschen instead of Frikadellen
      Never use "ẞ" , replace it always with "ss"
      
      The information under nutrition.nutrients are always per serving.
      
      You will get a recipe in a JSON format and you have to respond with the fixed recipe in the exactly same JSON format without further explanation prefix (like "\`\`\`json") or suffix.
      The resulting JSON format should only include the changes you made to the recipe and not the whole recipe. If an ingredient is modified respond with the whole JSON for that ingredient as in the JSON given (measures, name, description, id, etc.).
      If an instruction is modified respond with the whole instructions array modified.
      After you have checked the given JSON of the recipe and find that no modifications are needed. 
      Respond only with "NO_MODIFICATION" and no further JSON response. Otherwise with the fixed JSON in the exact same format as given by the user.
      `,
      },
      {
        role: 'user',
        content: JSON.stringify(recipe.toJS()),
      },
    ])
      .generate((message) => {
        setGptDebug(message);
      })
      .then((res) => JSON.parse(res))
      .then((response) => {
        console.log('response', response);
      })
      .finally(() => {
        setProcessing(false);
        setCheckingGpt(false);
      });
  }, [recipe]);

  return (
    <Modal
      open={true}
      title={isNew ? 'New Recipe' : recipe.defaultName}
      confirmLoading={processing}
      onCancel={handleCancel}
      destroyOnClose={false}
      footer={[
        <Button key="1" onClick={handleCancel} disabled={processing}>
          Close
        </Button>,
        <Button key="plauscheck" onClick={handleCheckRecipeGpt} disabled={processing}>
          GPT Check
        </Button>,
        <Button key="2" danger onClick={recipe.archived ? handleRestore : handleArchive} disabled={processing}>
          {recipe.archived ? 'Restore' : 'Archive'}
        </Button>,
        <Button key="3" type="primary" onClick={handleSave} disabled={processing}>
          Save
        </Button>,
      ]}
      width={1280}
    >
      {checkingGpt ? (
        <Input.TextArea value={gptDebug} rows={20} />
      ) : (
        <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
          <Tabs.TabPane tab="Basic" key="basic">
            <RecipeEditModalBasicInfo recipe={recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Ingredients" key="ingredients">
            <RecipeEditModalIngredients recipe={recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Instructions" key="instructions">
            <RecipeEditModalInstructionsTab recipe={recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Nutrients" key="nutrients">
            <RecipeEditModalNutrientsTab recipe={recipe} />
          </Tabs.TabPane>
          {isNew && (
            <Tabs.TabPane tab="ChatGPT JSON" key="gpt-json">
              <RecipeEditModalChatGptJsonTab
                recipe={recipe}
                onBeforeProcessing={handleSetProcessing}
                onAfterProcessing={handleUnsetProcessing}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    </Modal>
  );
});
