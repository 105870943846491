import { HealthDataPointRequest } from './HealthDataPointRequest';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { HealthDataPointObjectType } from './HealthDataPointObjectType';
import { HealthDataPoint } from './HealthDataPoint';
import { HealthDataPointType } from './HealthDataPointType';
import { HealthDataPointCategory } from './HealthDataPointCategory';
import { HealthDataPointQuantity } from './HealthDataPointQuantity';
import { HealthDataPointCorrelation } from './HealthDataPointCorrelation';

export class HealthDataPointQuery {
  static find(request?: HealthDataPointRequest): Promise<Array<HealthDataPoint>> {
    return HttpBackend.get(`/athlete-health-data`, request).then((res) =>
      res.map((d: any) => HealthDataPointQuery.build(d)),
    );
  }

  static findQuantity(request?: HealthDataPointRequest): Promise<HealthDataPointQuantity[]> {
    return HttpBackend.get(
      `/athlete-health-data`,
      Object.assign(request ?? {}, { pointType: HealthDataPointType.quantity }),
    ).then((res) => res.map((d: any) => new HealthDataPointQuantity(d)));
  }

  static last(types: Array<HealthDataPointObjectType>): Promise<Array<HealthDataPoint>> {
    return HttpBackend.get(`/athlete-health-data/last/${types.join(',')}`).then((res) =>
      res.map((d: any) => HealthDataPointQuery.build(d)),
    );
  }

  static recent(athleteId?: string): Promise<Array<HealthDataPoint>> {
    return HttpBackend.get(`/athlete-health-data/recent?athleteId=${athleteId}`).then((res) =>
      res.map((d: any) => HealthDataPointQuery.build(d)),
    );
  }

  static async lastQuantity(types: Array<HealthDataPointObjectType>): Promise<Array<HealthDataPointQuantity>> {
    const res = await HttpBackend.get(`/athlete-health-data/last/${types.join(',')}`, {
      pointType: HealthDataPointType.quantity,
    });
    return (res || []).map((d: any) => new HealthDataPointQuantity(d));
  }

  static async lastCorrelation(types: Array<HealthDataPointObjectType>): Promise<Array<HealthDataPointCorrelation>> {
    const res = await HttpBackend.get(`/athlete-health-data/last/${types.join(',')}`, {
      pointType: HealthDataPointType.correlation,
    });
    return (res || []).map((d: any) => new HealthDataPointCorrelation(d));
  }

  static async lastCategory(types: Array<HealthDataPointObjectType>): Promise<Array<HealthDataPointCategory>> {
    const res = await HttpBackend.get(`/athlete-health-data/last/${types.join(',')}`, {
      pointType: HealthDataPointType.category,
    });
    return (res || []).map((d: any) => new HealthDataPointCategory(d));
  }

  static build(json: any): HealthDataPoint {
    switch (json.pointType) {
      case HealthDataPointType.category:
        return new HealthDataPointCategory(json);
      case HealthDataPointType.quantity:
        return new HealthDataPointQuantity(json);
      case HealthDataPointType.correlation:
        return new HealthDataPointCorrelation(json);
      default:
        return new HealthDataPoint(json);
    }
  }
}
