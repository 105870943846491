/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { Pagination } from 'antd';
import { PageResult } from '../Model/PageResult';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';

export type PagerProps<T> = {
  page: PageResult<T>;
  onPage?: (page: number) => void;
};

type PagerFunction<T = unknown> = React.FC<PagerProps<T>>;

export const Pager: PagerFunction = observer(({ page, onPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePage = React.useCallback(
    (newPage: number) => {
      const pageIndex = newPage - 1;
      if (pageIndex !== page.number) {
        setSearchParams((prev) => new URLSearchParams({ ...Object.fromEntries(prev), page: `${pageIndex}` }));
        onPage && onPage(pageIndex);
      }
    },
    [setSearchParams, onPage, page.number],
  );

  return (
    <Pagination
      style={styles.container}
      showSizeChanger={false}
      onChange={handlePage}
      showTotal={(total, range) => `${range[0]}-${range[1] ?? 1} of ${total ?? 0} items`}
      pageSize={page.size}
      hideOnSinglePage={false}
      current={page.number + 1}
      total={page.totalElements}
    />
  );
});

const styles = {
  container: { paddingBottom: 8 },
};
