/**
 * Created by neo on 14.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { MicroCycleDay } from '../../../../Model/Coach/Program/MicroCycleDay';
import { Modal } from 'antd';
import { ProgramParamsEditor } from './ProgramParamsEditor';
import { CoachProgram } from '../../../../Model/Coach/Program/CoachProgram';
import { Col, Row } from 'reactstrap';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { CoachWorkoutView } from './CoachWorkoutView/CoachWorkoutView';
import { Link } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

export type MicroCycleDayModalProps = {
  athleteSuperMacro: CoachProgram;
  microCycleDay: MicroCycleDay;
  onClose?: () => any;
};

export const MicroCycleDayModal: React.FC<MicroCycleDayModalProps> = observer(
  ({ athleteSuperMacro, microCycleDay, onClose }) => {
    const handleChange = React.useCallback(() => {
      microCycleDay.changeParams();
    }, [microCycleDay]);

    return (
      <Modal
        width={1920}
        style={{ top: 20 }}
        open={true}
        title={
          <Link to={`/coach/workout-template/${microCycleDay.workout.scriptId}`}>
            {microCycleDay.workout.defaultName}
          </Link>
        }
        onOk={onClose}
        onCancel={onClose}
      >
        {athleteSuperMacro.athlete && (
          <ProgramParamsEditor
            athlete={athleteSuperMacro.athlete}
            params={microCycleDay.params}
            onChange={handleChange}
          />
        )}
        <Row>
          <Col>
            <h6>
              Duration: {dayjs.utc(microCycleDay.workout.minDuration).format('HH:mm:ss')} -{' '}
              {dayjs.utc(microCycleDay.workout.maxDuration).format('HH:mm:ss')}
            </h6>
          </Col>
        </Row>
        <SingleColRow>
          <CoachWorkoutView workout={microCycleDay.workout} />
        </SingleColRow>
      </Modal>
    );
  },
);
