/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { Container, Col, Row, Badge } from 'reactstrap';
import { Gym } from '../../../../Model/Gym/Gym';
import { observer, useLocalStore, Observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import { GymViewScreenTitle } from './GymViewScreenTitle';
import { message, Button, Space, Popconfirm } from 'antd';
import { GymViewScreenTabs } from './GymViewScreenTabs';
import { useRootStore } from '../../../../Store/useRootStore';
import { GymCoachSubscription } from '../../../../Model/Subscription/GymCoachSubscription';

function subscriptionColor(subscription?: GymCoachSubscription) {
  if (subscription?.valid) {
    if (subscription.plan === 'standard') {
      return 'success';
    } else if (subscription.plan === 'engagement') {
      return 'warning';
    } else if (subscription.plan === 'move') {
      return 'secondary';
    } else if (subscription.plan === 'academy') {
      return 'info';
    }
    return 'primary';
  }
  return 'danger';
}

export type GymViewScreenProps = {};

export const GymViewScreen: React.FC<GymViewScreenProps> = observer(() => {
  const { gymId } = useParams<any>();
  const { authentication } = useRootStore();
  const [loaded, setLoaded] = useState(false);
  const history = useNavigate();

  const [subscription, setSubscription] = useState<GymCoachSubscription | undefined>();
  const [gym, setGym] = useState(new Gym());
  const isNew = !gymId || gymId === 'new';

  useEffect(() => {
    setSubscription(undefined);
    if (gymId && gymId !== 'new') {
      GymCoachSubscription.current({ gymId: gymId }).then((result) => setSubscription(result));
    }
  }, [gymId]);

  useEffect(() => {
    setLoaded(false);
    if ('new' !== gymId && gymId) {
      Gym.get(gymId)
        .then((result) => setGym(result ?? new Gym()))
        .then(() => setLoaded(true));
    } else {
      setGym(new Gym());
      setLoaded(true);
    }
  }, [gymId]);

  const handleSave = React.useCallback(() => {
    gym
      .save()
      .then(() => {
        history(`/infrastructure/gym/${gym.id}`, { replace: true });
      })
      .then(() => message.success('Gym saved'))
      .catch(() => message.error('ERROR saving gym'));
  }, [gym, history]);

  const handleChangeTab = React.useCallback(
    (key) => {
      history(`${key}`, { replace: true });
    },
    [history],
  );

  const handleDelete = React.useCallback(() => {
    gym.delete().then(() => {
      history('/infrastructure/gym', { replace: true });
    });
  }, [gym, history]);

  return (
    <Container>
      <Row>
        <Col>
          <GymViewScreenTitle gym={gym} isNew={isNew} />
          <Badge color={subscriptionColor(subscription)}>{subscription?.plan}</Badge>
        </Col>
        <Col md="auto">
          <Space>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
            {!isNew && loaded && authentication.isGodAdmin ? (
              <Popconfirm title={`Delete ${gym.name}?`} okText={'Delete'} cancelText="NO" onConfirm={handleDelete}>
                <Button danger>{'Delete'}</Button>
              </Popconfirm>
            ) : null}
          </Space>
        </Col>
      </Row>
      {loaded && (
        <Routes>
          <Route path=":tab?/*" element={<GymViewScreenTabs gym={gym} onTabChange={handleChangeTab} />} />
          <Route path="*" element={<GymViewScreenTabs gym={gym} onTabChange={handleChangeTab} />} />
        </Routes>
      )}
    </Container>
  );
});
