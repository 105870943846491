import React, { PropsWithChildren } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { Root } from './Root';
import { rootStore } from './Store/useRootStore';
import { isElectron } from './Utils/isElectron';
import { APIProvider } from '@vis.gl/react-google-maps';

const RouterContainer: React.FC<PropsWithChildren<any>> = ({ children }) =>
  isElectron() ? <HashRouter>{children}</HashRouter> : <BrowserRouter>{children}</BrowserRouter>;

export type AppProps = {};

export const App: React.FC<AppProps> = observer(() => {
  return (
    <APIProvider apiKey="AIzaSyCptdMtGLKz0F8FHU-uAWMqk0ydVnz96uw">
      <Provider rootStore={rootStore}>
        <RouterContainer>
          <Root />
        </RouterContainer>
      </Provider>
    </APIProvider>
  );
});
