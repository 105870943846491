import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { GroupStage } from '../../../../../../../Model/Coach/Stages/GroupStage';

export class GroupNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: GroupStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'group' }));
    this.addInPort('in');
    this.addOutPort('default');
    this.addOutPort('inner', true);
  }
}
