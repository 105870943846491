/**
 * Created by katarinababic on 17.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type SecondaryButtonElementProps = {
  component: NativeFeedComponent;
  lang?: string;
  isHover?: boolean;
};

export const SecondaryButtonElement: React.FC<SecondaryButtonElementProps> = observer(
  ({ component, lang, isHover }) => {
    const { classes } = useStyles();
    const url = component.props.extraProps.get('link');

    const handleClick = React.useCallback(() => {
      if (typeof url === 'string') {
        window.open(url);
      }
    }, [url]);

    return (
      <div className={isHover ? classes.hover : classes.button} onClick={handleClick}>
        <span className={classes.text}>
          {component.getText(lang ?? 'de').toUpperCase() || component.type.toUpperCase()}
        </span>
        <ArrowForward />
      </div>
    );
  },
);

const useStyles = makeStyles()(() => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
    fontFamily: 'Inter',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hover: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
    fontFamily: 'Inter',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
  text: {
    paddingRight: 16,
  },
}));
