import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import { UserDailyEngagementTime } from '../Analytics/UsersOverviewTab/UserDailyEngagementTime';
import { Form } from 'antd';

export type DashboardScreenProps = {};

export const DashboardScreen: React.FC<DashboardScreenProps> = observer(() => {
  return (
    <Container>
      <Form layout="vertical">
        <Row>
          <Col xs={12}>
            <UserDailyEngagementTime />
          </Col>
        </Row>
      </Form>
    </Container>
  );
});
