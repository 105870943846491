export const NodeColors = {
  bodyPartClusterSort: 'rgb(0,192,255)',
  collect: 'rgb(232,207,58)',
  concat: 'rgb(0,192,255)',
  distinct: 'rgb(0,192,255)',
  duplicate: 'rgb(0,192,255)',
  exerciseBlock: 'rgb(0,192,255)',
  fetch: 'rgb(169,63,248)',
  flatten: 'rgb(0,192,255)',
  filter: 'rgb(0,192,255)',
  graphSorting: 'rgb(0,192,255)',
  group: 'rgb(232,207,58)',
  inputSort: 'rgb(232,207,58)',
  limit: 'rgb(0,192,255)',
  map: 'rgb(0,192,255)',
  noop: 'rgb(0,192,255)',
  phase: 'rgb(232,207,58)',
  pipeline: 'rgb(0,192,255)',
  program: 'rgb(232,207,58)',
  random: 'rgb(232,207,58)',
  reverse: 'rgb(232,207,58)',
  sink: 'rgb(7,203,198)',
  sort: 'rgb(232,207,58)',
  switch: 'red',
  phaseSubstitute: 'red',
  workout: 'rgb(232,207,58)',
  conditional: 'red',
  'logical:all': 'rgb(192,255,0)',
  'logical:and': 'rgb(192,255,0)',
  'logical:eq': 'rgb(192,255,0)',
  'logical:neq': 'rgb(192,255,0)',
  'logical:gte': 'rgb(192,255,0)',
  'logical:gt': 'rgb(192,255,0)',
  'logical:in': 'rgb(192,255,0)',
  'logical:lte': 'rgb(192,255,0)',
  'logical:lt': 'rgb(192,255,0)',
  'logical:ne': 'rgb(192,255,0)',
  'logical:nin': 'rgb(192,255,0)',
  'logical:not': 'rgb(192,255,0)',
  'logical:or': 'rgb(192,255,0)',
  default: 'rgb(0,192,255)',
};
