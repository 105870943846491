/**
 * Created by neo on 11.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { Alert, Button, DatePicker, Form, Modal, Space } from 'antd';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { Routes, Route, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { ExploreCategoryCard } from './ExploreCategoryCard';
import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import { Gym } from '../../../Model/Gym/Gym';
import AsyncSelect from 'react-select/async';
import { ExploreContentBuilder } from '../../../Model/Explore/ExploreContentBuilder';
import { ExploreContent } from '../../../Model/Explore/ExploreContent';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreContentEditModal } from './ExploreContentEditModal';
import { ExploreIndexScreenCategoryPath } from './ExploreIndexScreenCategoryPath';
import { SingleColRow } from '../../../Components/SingleColRow';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { ExploreStatistics } from './ExploreStatistics';
import CreatableSelect from 'react-select/creatable';
import { allowedTags } from '../SuperMacro/View/TagConditionInput';

export type ExploreIndexScreenProps = {};

const defaultTagOptions = allowedTags.map((value) => ({ value, label: value }));

const CategoryContainer = styled.div`
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: space-between;
`;

const CategorySuperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExploreIndexScreen: React.FC<ExploreIndexScreenProps> = observer((props) => {
  const history = useNavigate();
  const { categoryId } = useParams<{ categoryId?: string }>();

  const [searchParams, setSearchParams] = useSearchParams();

  const gymId = searchParams.get('gymId') || undefined;
  const page = Number(searchParams.get('page') || '0');

  console.log('categoryId', categoryId);

  const [contents, setContents] = useState<PageResult<ExploreContent>>(new PageResult());
  const [parentCategory, setParentCategory] = useState<ExploreCategory | undefined>();
  const [loadingParent, setLoadingParent] = useState(false);
  const [dragIndex, setDragIndex] = useState<number | undefined>();
  const [dragEnabled, setDragEnabled] = useState(false);
  const [selectedGym, setSelectedGym] = useState<Gym | undefined>();
  const [testTags, setTestTags] = useState<string[]>([]);

  const testTagsTags = testTags.map((t) => ({ value: t, label: t }));

  const loadContents = React.useRef(
    (parentCategory?: ExploreCategory, gymId?: string, page?: number, testTags?: string[]) =>
      PageResult.execute(
        ExploreContentBuilder.find(
          parentCategory
            ? {
                categories: parentCategory.identifier ? [parentCategory.identifier] : [],
                tags: testTags,
                gymId,
                page,
                size: 20,
              }
            : { root: true, tags: testTags, gymId, page },
        ),
        ExploreContentBuilder.count(
          parentCategory
            ? { categories: parentCategory.identifier ? [parentCategory.identifier] : [], tags: testTags, gymId }
            : { root: true, tags: testTags, gymId },
        ),
        page,
        20,
      ).then((result) => setContents(result)),
  ).current;

  useEffect(() => {
    if (gymId) {
      Gym.get(gymId).then((result) => setSelectedGym(result));
    } else {
      setSelectedGym(undefined);
    }
  }, [gymId]);

  useEffect(() => {
    setParentCategory(undefined);
    setLoadingParent(true);
    if (categoryId) {
      ExploreCategory.findOne(categoryId, { gymId })
        .then((result) => setParentCategory(result))
        .finally(() => setLoadingParent(false));
    } else {
      setLoadingParent(false);
    }
  }, [categoryId, gymId]);

  useEffect(() => {
    if (!loadingParent) {
      setContents(new PageResult());
      loadContents(parentCategory, gymId, page, testTags);
    } else {
      setContents(new PageResult());
    }
  }, [parentCategory, loadingParent, gymId, page, testTags, loadContents]);

  const handleCreateCategory = React.useCallback(() => {
    if (parentCategory) {
      history(`new/edit?contentType=category&parentId=${parentCategory.identifier}&gymId=${gymId ?? ''}`);
    } else {
      history(`root/new/edit?contentType=category&gymId=${gymId ?? ''}`);
    }
  }, [history, parentCategory, gymId]);

  const handleDrop = React.useCallback(
    (categoryId: string, newIndex: number) => {
      const index = contents.content.findIndex((c) => c.id === categoryId);
      if (index !== -1) {
        const category = contents[index];
        runInAction(() => (category.order = newIndex));
        contents.content.splice(newIndex, 0, contents.content.splice(index, 1)[0]);
        runInAction(() => contents.content.forEach((c, i) => (c.order = i)));
        Promise.all(
          contents.map((content) =>
            content instanceof ExploreCategory
              ? content.save()
              : content instanceof ExploreEntry
              ? content.save()
              : (Promise.resolve() as any),
          ),
        );
      }
    },
    [contents],
  );

  const handleDragStart = React.useCallback((category, index) => {
    setDragIndex(index);
  }, []);

  const handleDragEnd = React.useCallback(() => {
    setDragIndex(undefined);
  }, []);

  const handleToggleDrag = React.useCallback((checked) => {
    setDragEnabled(checked);
  }, []);

  const handleEditCategory = React.useCallback(() => {
    if (parentCategory) {
      history(`${parentCategory.id}/edit?gymId=${gymId ?? ''}`);
    }
  }, [history, parentCategory, gymId]);

  const handleCreateEntry = React.useCallback(() => {
    if (parentCategory) {
      history(`new/edit?contentType=entry&parentId=${parentCategory.identifier}&gymId=${gymId ?? ''}`);
    } else {
      history(`new/edit?contentType=entry&gymId=${gymId ?? ''}`);
    }
  }, [history, parentCategory, gymId]);

  const handleDeleteCategory = React.useCallback(() => {
    if (parentCategory) {
      Modal.confirm({
        title: `Delete ${parentCategory.defaultName}??`,
        onOk() {
          parentCategory.delete().then(() => history(`/coach/explore?gymId=${gymId ?? ''}`, { replace: true }));
        },
      });
    }
  }, [parentCategory, history, gymId]);

  const fetchGym = React.useCallback(
    (query: string) =>
      Gym.find({
        query,
        size: 100,
      }),
    [],
  );

  const handleChange = React.useCallback(
    (gym?: Gym | null) => {
      setSelectedGym(gym ?? undefined);
      setSearchParams((prev) => new URLSearchParams({ ...prev, gymId: gym?.id ?? '' }));
    },
    [setSearchParams],
  );

  const getOptionLabel = React.useCallback((option: Gym) => option.name, []);

  const getOptionValue = React.useCallback((option: Gym) => option, []);

  const handleContentSaved = React.useCallback(
    (entry: ExploreContent) => {
      loadContents(parentCategory, gymId, page, testTags);

      // if (!contents.content.some((c) => c.id === entry.id)) {
      //   runInAction(() => contents.content.concat([entry]));
      // }
    },
    [gymId, loadContents, page, parentCategory, testTags],
  );

  const handleContentDeleted = React.useCallback(
    (entry: ExploreContent) => {
      const index = contents.content.findIndex((c) => c.id === entry.id);
      if (index !== -1) {
        runInAction(() => contents.content.splice(index, 1));
      }
    },
    [contents],
  );

  const handleExcludedChange = React.useCallback((tags?: any[]) => {
    setTestTags(tags?.map((t) => t.value) ?? []);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1>{parentCategory ? parentCategory.defaultName : 'Explore Tab'}</h1>
          </Col>
          <Col xs="auto">
            <Space>
              {parentCategory && (
                <Button type="primary" onClick={handleCreateEntry}>
                  Create Entry
                </Button>
              )}
              <Button type="primary" onClick={handleCreateCategory}>
                {parentCategory ? 'Create Sub Category' : 'Create Root Category'}
              </Button>
              {parentCategory && parentCategory.gymId === gymId && (
                <Button onClick={handleEditCategory}>Edit {parentCategory.defaultName}</Button>
              )}
              {parentCategory && parentCategory.gymId === gymId && (
                <Button type="primary" danger onClick={handleDeleteCategory}>
                  Delete {parentCategory.defaultName}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <SingleColRow>
          <h5>{parentCategory && <ExploreIndexScreenCategoryPath category={parentCategory} gymId={gymId} />}</h5>
        </SingleColRow>
        <SingleColRow>
          {selectedGym && (
            <SingleColRow>
              <Alert type="warning" message={`You are in '${selectedGym?.name}'`} />
            </SingleColRow>
          )}
        </SingleColRow>
        <Form layout="vertical">
          <Row>
            <Col>
              <Form.Item label="Select a gym to view gym specific explore tabs">
                <AsyncSelect
                  value={selectedGym}
                  cacheOptions
                  defaultOptions
                  isClearable={true}
                  loadOptions={fetchGym}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue as any}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="View with tags" extra="Enter the tags to test how a user would see the explore">
                <CreatableSelect
                  isClearable
                  isMulti
                  onChange={handleExcludedChange as any}
                  options={defaultTagOptions}
                  value={testTagsTags}
                  placeholder="Test Tags"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col xs={4} md={5}>
            <Pager page={contents} />
            <CategorySuperContainer>
              <CategoryContainer>
                {contents.content
                  .sort((a, b) => a.order - b.order)
                  .map((content, index) => (
                    <ExploreCategoryCard
                      key={content.id}
                      content={content}
                      index={index}
                      draggable={dragEnabled}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      dragIndex={dragIndex}
                      onDrop={handleDrop}
                      gym={selectedGym}
                    />
                  ))}
              </CategoryContainer>
            </CategorySuperContainer>
            <Pager page={contents} />
          </Col>
          <Col xs={8} md={7}>
            <ExploreStatistics contents={contents} categoryId={categoryId} />
          </Col>
        </Row>
      </Container>
      <Routes>
        <Route
          path={`:contentId/edit`}
          element={<ExploreContentEditModal onSaved={handleContentSaved} onDeleted={handleContentDeleted} />}
        />
      </Routes>
    </React.Fragment>
  );
});
