/**
 * Created by katarinababic on 4.5.22.
 */

export const queryVideoStopped = `
SELECT
  e_id.value.string_value AS video_id,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS no_of_plays,
  AVG(e_percent.value.double_value) AS avg_percentage_completed
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS e_id,
  UNNEST (event_params) AS e_percent
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'video_stopped'
  AND e_id.key = 'mediaId'
  AND e_id.value.string_value = @video_id
  AND e_percent.key = 'percentageCompleted'
GROUP BY
  video_id
`;
