/**
 * Created by neo on 23.04.21.
 */
import { RecipeSearchParams } from '../Recipe/RecipeSearchParams';
import { computed, observable } from 'mobx';

export type MealType =
  | 'breakfast'
  | 'midMorning'
  | 'lunch'
  | 'snack'
  | 'preWorkout'
  | 'postWorkout'
  | 'dinner'
  | 'lateNight';

export type MealRequirementCalculationJson = {
  meal: MealType;
  kcal: number;
  carbs: number;
  fat: number;
  protein: number;
  searchParams: RecipeSearchParams;
};

export class MealRequirementCalculation {
  @observable
  meal: MealType = 'breakfast';
  @observable
  kcal = 0.0;
  @observable
  carbs = 0.0;
  @observable
  fat = 0.0;
  @observable
  protein = 0.0;
  @observable
  searchParams: RecipeSearchParams = {};

  constructor(json?: Partial<MealRequirementCalculationJson>) {
    if (json) {
      this.meal = json.meal ?? 'breakfast';
      this.kcal = json.kcal ?? 0.0;
      this.carbs = json.carbs ?? 0.0;
      this.fat = json.fat ?? 0.0;
      this.protein = json.protein ?? 0.0;
      this.searchParams = json.searchParams ?? {};
    }
  }

  /**
   * seems that the requirements are pretty strict and the
   * request returns to recipes. For testing the view only
   * purposes some params are deleted
   */
  @computed
  get testSearchParams(): RecipeSearchParams {
    return { diet: this.searchParams.diet, dishTypes: this.searchParams.dishTypes, size: 6 };
  }
}
