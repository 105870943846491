/**
 * Created by neo on 18.12.20.
 */
import { Pageable } from '../../Pageable';

export type RecipeSearchParams = Pageable & {
  dishTypes?: DishType[];
  cuisine?: CuisinesType[];
  diet?: DietsType[];
  seasons?: SeasonType[];
  archived?: boolean;
  empty?: boolean;
  minProteinPercent?: number;
  maxProteinPercent?: number;
  minFatPercent?: number;
  maxFatPercent?: number;
  minCarbsPercent?: number;
  maxCarbsPercent?: number;
  minReadyTime?: number;
  maxReadyTime?: number;
  query?: string;
  excludeCuisine?: string[];
  intolerances?: string[];
  equipment?: string[];
  includeIngredients?: string[];
  excludeIngredients?: string[];
  type?: string[];
  minCarbs?: number;
  maxCarbs?: number;
  minProtein?: number;
  maxProtein?: number;
  minCalories?: number;
  maxCalories?: number;
  minFat?: number;
  maxFat?: number;
  minAlcohol?: number;
  maxAlcohol?: number;
  minCaffeine?: number;
  maxCaffeine?: number;
  minCopper?: number;
  maxCopper?: number;
  minCalcium?: number;
  maxCalcium?: number;
  minCholine?: number;
  maxCholine?: number;
  minCholesterol?: number;
  maxCholesterol?: number;
  minFluoride?: number;
  maxFluoride?: number;
  minSaturatedFat?: number;
  maxSaturatedFat?: number;
  minVitaminA?: number;
  maxVitaminA?: number;
  minVitaminC?: number;
  maxVitaminC?: number;
  minVitaminD?: number;
  maxVitaminD?: number;
  minVitaminE?: number;
  maxVitaminE?: number;
  minVitaminK?: number;
  maxVitaminK?: number;
  minVitaminB1?: number;
  maxVitaminB1?: number;
  minVitaminB2?: number;
  maxVitaminB2?: number;
  minVitaminB5?: number;
  maxVitaminB5?: number;
  minVitaminB3?: number;
  maxVitaminB3?: number;
  minVitaminB6?: number;
  maxVitaminB6?: number;
  minVitaminB12?: number;
  maxVitaminB12?: number;
  minFiber?: number;
  maxFiber?: number;
  minFolate?: number;
  maxFolate?: number;
  minFolicAcid?: number;
  maxFolicAcid?: number;
  minIodine?: number;
  maxIodine?: number;
  minIron?: number;
  maxIron?: number;
  minMagnesium?: number;
  maxMagnesium?: number;
  minManganese?: number;
  maxManganese?: number;
  minPhosphorus?: number;
  maxPhosphorus?: number;
  minPotassium?: number;
  maxPotassium?: number;
  minSelenium?: number;
  maxSelenium?: number;
  minSodium?: number;
  maxSodium?: number;
  minSugar?: number;
  maxSugar?: number;
  minZinc?: number;
  maxZinc?: number;
  limitLicense?: boolean;
};

/** https://spoonacular.com/food-api/docs#Meal-Types **/
export const DishList = [
  'breakfast',
  'midMorning',
  'preWorkout',
  'postWorkout',
  'lunch',
  'snack',
  'dinner',
  'lateNight',
  'main_course',
  'side_dish',
  'dessert',
  'appetizer',
  'salad',
  'bread',
  'soup',
  'sauce',
  'marinade',
  'fingerfood',
] as const;

/** https://spoonacular.com/food-api/docs#Cuisines **/
export const CuisinesList = [
  'african',
  'american',
  'british',
  'cajun',
  'caribbean',
  'chinese',
  'eastern_european',
  'european',
  'french',
  'german',
  'greek',
  'indian',
  'irish',
  'italian',
  'japanese',
  'jewish',
  'korean',
  'latin_american',
  'mediterranean',
  'mexican',
  'middle_eastern',
  'nordic',
  'southern',
  'spanish',
  'thai',
  'vietnamese',
] as const;

export const SeasonList = ['spring', 'summer', 'autumn', 'winter'] as const;

/** https://spoonacular.com/food-api/docs#Diets **/
export const DietsList = [
  'vegan',
  'vegetarian',
  'pescetarian',
  'gluten_free',
  'dairy_free',
  'lacto_ovo_vegetarian',
  'paleolithic',
  'primal',
  'ketogenic',
  'whole_30',
  'gaps',
  'gaps_full',
  'gaps_3',
  'gaps_4',
  'gaps_5',
  'gaps_6',
] as const;

export type DishType = typeof DishList[number];
export type CuisinesType = typeof CuisinesList[number];
export type DietsType = typeof DietsList[number];
export type SeasonType = typeof SeasonList[number];
