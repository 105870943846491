/**
 * Created by katarinababic on 26.1.22..
 */

export const allWorkplaceFinishedAggregated = `
SELECT
  ANY_VALUE(params_type.value.string_value) AS entry_type,
  params_name.value.string_value AS entry_name,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS finished_count,
  AVG(params_percentage.value.double_value) AS avg_percentage_completed
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params_percentage,
  UNNEST (event_params) AS params_type,
  UNNEST (event_params) AS params_name
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'Workout_finished'
  AND params_percentage.key = 'percentageCompleted'
  AND params_type.key = 'workoutType'
  AND params_type.value.string_value = 'workplace'
  AND params_name.key = 'name'
GROUP BY
  entry_name
ORDER BY
  entry_name
`;

export const gymWorkplaceFinishedAggregated = `
SELECT
  ANY_VALUE(params_type.value.string_value) AS entry_type,
  params_name.value.string_value AS entry_name,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS finished_count,
  AVG(params_percentage.value.double_value) AS avg_percentage_completed
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params_percentage,
  UNNEST (event_params) AS params_type,
  UNNEST (event_params) AS params_name,
  UNNEST (user_properties) AS p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'Workout_finished'
  AND params_percentage.key = 'percentageCompleted'
  AND params_type.key = 'workoutType'
  AND params_type.value.string_value = 'workplace'
  AND params_name.key = 'name'
  AND p.key = 'gymId'
  AND p.value.string_value = @gym_id
GROUP BY
  entry_name
ORDER BY
  entry_name
`;
