/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { GymChallenge, GymChallengeStatus } from '../../../Model/GymChallenge/GymChallenge';
import { GymChallengeParticipant } from '../../../Model/GymChallenge/GymChallengeParticipant';
import { Badge, Tag } from 'antd';

export type GymChallengeTableRowProps = {
  challenge: GymChallenge;
};

export const GymChallengeTableRow: React.FC<GymChallengeTableRowProps> = observer(({ challenge }) => {
  const [participantCount, setParticipantCount] = useState(0);

  const tagColor = (status: GymChallengeStatus) => {
    switch (status) {
      case 'in_progress':
        return 'cyan';
      case 'not_started':
        return 'yellow';
      case 'finished':
        return 'red';
      case 'achieved':
        return 'green';
    }
  };

  useEffect(() => {
    const interval = setInterval(
      () => GymChallengeParticipant.count({ challengeId: challenge.id }).then((result) => setParticipantCount(result)),
      5000,
    );
    return () => clearInterval(interval);
  }, [challenge]);

  return (
    <tr>
      <td>
        <Link to={`${challenge.id}`}>{challenge.id}</Link>
      </td>
      <td>{challenge.gym?.name}</td>
      <td>{challenge.defaultName}</td>
      <td>{dayjs(challenge.startDateTime).format('DD.MM.YYYY HH:mm')}</td>
      <td>{dayjs(challenge.endDateTime).format('DD.MM.YYYY HH:mm')}</td>
      <td>
        <Tag color={tagColor(challenge.status)}>{challenge.status}</Tag>
      </td>
      <td>
        {challenge.totalPoints} / {challenge.pointsGoal}
      </td>
      <td>{participantCount}</td>
    </tr>
  );
});
