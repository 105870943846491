/**
 * Created by neo on 12.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../Model/Explore/ExploreEntryBuilder';
import { Button, Col, Row } from 'antd';
import { Gym } from '../../../Model/Gym/Gym';

const Container = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 18px;
  padding: 16px 0;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex: 1;
`;

const EntryContainer = styled.div<{ backgroundImage?: string }>`
  flex: 1;
  width: 100%px;
  height: 240px;
  ${({ backgroundImage }) => (backgroundImage ? `background-image: url('${backgroundImage}');` : '')}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
`;

export type ExploreCategoryRendererBigPreviewProps = {
  category: ExploreCategory;
  gym?: Gym;
};

export const ExploreCategoryRendererBigPreview: React.FC<ExploreCategoryRendererBigPreviewProps> = observer(
  ({ category, gym }) => {
    const history = useNavigate();
    const location = useLocation();
    const gymId = new URLSearchParams(location.search).get('gymId') || undefined;

    const [entries, setEntries] = useState<ExploreEntry[]>([]);

    useEffect(() => {
      ExploreEntryBuilder.find({ categories: [category.identifier], size: 1 }).then((result) => setEntries(result));
    }, [category]);

    const handleClickEdit = React.useCallback(() => {
      history(`${category.id}/edit?gymId=${gymId ?? ''}`);
    }, [history, category, gymId]);

    console.log('gymids', gymId, category.gymId);

    return (
      <Container>
        <Title>
          <Row>
            <Col>
              <Link to={`/coach/explore/${category.id}?gymId=${gymId ?? ''}`}>{category.defaultName}</Link>
            </Col>
            {gymId === category.gymId && (
              <Col xs="auto">
                <Button type="link" onClick={handleClickEdit}>
                  Edit
                </Button>
              </Col>
            )}
          </Row>
        </Title>
        <Description>{category.defaultDescription}</Description>
        <PreviewContainer>
          {entries.map((entry) => (
            <EntryContainer key={entry.id} backgroundImage={entry.image?.smallest} />
          ))}
        </PreviewContainer>
      </Container>
    );
  },
);
