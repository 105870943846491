/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps, StyledRow } from '../Default/DefaultStageNodeWidget';
import { StyledInput } from '../StyledInput';
import { LimitFieldNodeModel } from './LimitFieldNodeModel';
import { ConfigAttributeSelect } from '../ConfigAttributeSelect';

export type LimitNodeWidgetProps = { node: LimitFieldNodeModel } & StageNodeWidgetProps;

export const LimitFieldNodeWidget: React.FC<LimitNodeWidgetProps> = observer(
  ({ node, engine, size }: LimitNodeWidgetProps) => {
    const handleChange = React.useCallback(
      ({ target: { value } }: any) => (node.stage.config.limit = Number(value) ?? 0),
      [node],
    );

    return (
      <DefaultStageNodeWidget
        engine={engine}
        node={node}
        size={size}
        disableEdit={true}
        content={
          <React.Fragment>
            <StyledRow>
              <ConfigAttributeSelect node={node} />
            </StyledRow>
            <StyledRow>
              <StyledInput node={node} value={node.stage.config.limit} onChange={handleChange} />
            </StyledRow>
          </React.Fragment>
        }
      />
    );
  },
);
