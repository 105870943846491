import {
  MindfulnessProgramTemplateDayUnit,
  MindfulnessProgramTemplateDayUnitJson,
} from './MindfulnessProgramTemplateDayUnit';
import { MindfulnessProgramTemplateDayUnitAudio } from './MindfulnessProgramTemplateDayUnitAudio';
import { MindfulnessProgramTemplateDayUnitVideo } from './MindfulnessProgramTemplateDayUnitVideo';
import { MindfulnessProgramTemplateDayUnitBreathing } from './MindfulnessProgramTemplateDayUnitBreathing';
import { MindfulnessProgramTemplateDayUnitVideoSeries } from './MindfulnessProgramTemplateDayUnitVideoSeries';
import { MindfulnessProgramTemplateDayUnitWorkoutTemplate } from './MindfulnessProgramTemplateDayUnitWorkoutTemplate';

export class MindfulnessProgramTemplateDayUnitBuilder {
  static build(json: Partial<MindfulnessProgramTemplateDayUnitJson>): MindfulnessProgramTemplateDayUnit {
    switch (json.type) {
      case 'audio':
        return new MindfulnessProgramTemplateDayUnitAudio(json);
      case 'video':
        return new MindfulnessProgramTemplateDayUnitVideo(json);
      case 'breathing':
        return new MindfulnessProgramTemplateDayUnitBreathing(json);
      case 'videoSeries':
        return new MindfulnessProgramTemplateDayUnitVideoSeries(json);
      case 'workoutTemplate':
        return new MindfulnessProgramTemplateDayUnitWorkoutTemplate(json);
      default:
        return new MindfulnessProgramTemplateDayUnit(json);
    }
  }
}
