/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Popconfirm } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MindfulnessProgramTemplate } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { MindfulnessProgramTemplateDayUnit } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnit';
import { UnitEntryModal } from '../UnitEntryModal/UnitEntryModal';
import { MindfulnessProgramTemplateDay } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDay';
import { runInAction } from 'mobx';
import { DeleteOutlined } from '@mui/icons-material';

const Container = styled.div`
  color: black;
  background-color: #414bb2;
  font-family: Helvetica, Arial;
  font-size: 12px;
  padding: 5px;
  color: white;
  cursor: pointer;
  margin: 4px 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const Title = styled.div`
  flex: 1;
  font-size: 16px;
`;

const Extra = styled.div`
  font-size: 10px;

  a,
  a:visited {
    font-size: 12px;
    color: #ff4d4f;

    &:hover,
    &:active {
      color: #ff7875;
    }
  }
`;

const Body = styled.div``;

export type UnitEntryEditorProps = {
  template: MindfulnessProgramTemplate;
  workoutDay: MindfulnessProgramTemplateDay;
  index: number;
  unit: MindfulnessProgramTemplateDayUnit;
  onRemove?: (workoutEntry: MindfulnessProgramTemplateDayUnit) => any;
  onEdit?: (workoutEntry: MindfulnessProgramTemplateDayUnit) => void;
};

export const UnitEntryEditor: React.FC<UnitEntryEditorProps> = observer(
  ({ template, workoutDay, index, unit, onRemove }) => {
    const [modalVisible, setModalVisible] = useState(unit.type === 'default');

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(unit);
    }, [onRemove, unit]);

    const handleEdit = React.useCallback(() => {
      setModalVisible(true);
    }, []);

    const handleCloseModal = React.useCallback(() => {
      setModalVisible(false);
    }, []);

    const handleEntryChanged = React.useCallback(
      (entry: MindfulnessProgramTemplateDayUnit) => runInAction(() => (workoutDay.units[index] = entry)),
      [workoutDay, index],
    );

    const handleSave = React.useCallback(() => {
      template.save();
    }, [template]);

    return (
      <React.Fragment>
        <Container>
          <Header>
            <Title>{`${unit.defaultName} (${unit.type}) - ${unit.timeOfDay}`}</Title>
            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={handleEdit} />
            <Popconfirm title={`Remove ${unit.defaultName}?`} onConfirm={handleRemove}>
              <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Header>
          <Body>{/*<WorkoutEntryDropZone workoutEntry={unit} onDrop={handleDrop} />*/}</Body>
        </Container>
        {modalVisible && (
          <UnitEntryModal
            template={template}
            entry={unit}
            onClose={handleCloseModal}
            onEntryChanged={handleEntryChanged}
            onSaved={handleSave}
          />
        )}
      </React.Fragment>
    );
  },
);
