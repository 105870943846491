/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { ActivityWorkoutEntry } from '../../../../Model/Explore/ActivityWorkoutEntry';
import { Col, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { Form, InputNumber, Select } from 'antd';
import { Activity } from '../../../../Model/Activity/Activity';
import { MultiRowMap } from '../../../Security/OAuth/View/MultiRowMap';
import { YogaInstructorEdit } from './Yoga/YogaInstructorEdit';
import { YogaMusicSelection } from './Yoga/YogaMusicSelection';
import ReactAudioPlayer from 'react-audio-player';
import { reaction, runInAction } from 'mobx';
import { useEffect } from 'react';

export type ActivityWorkoutEntryContentProps = {
  entry: ActivityWorkoutEntry;
};

export const ActivityWorkoutEntryContent: React.FC<ActivityWorkoutEntryContentProps> = observer(({ entry }) => {
  const music = YogaMusicSelection.find((m) => m.key === entry.recommendedMusicKey);

  useEffect(
    () =>
      reaction(
        () =>
          entry.videos
            .map((v) => v.mediaInfo?.duration)
            .filter((v) => !!v)
            .reduce((agg, v) => Math.max(v ?? 0, agg ?? 0), 0),
        (durationOfVideo) => {
          console.log('duration', durationOfVideo);
          if (durationOfVideo) {
            runInAction(() => (entry.durationInMinutes = Math.max(1, Math.floor(durationOfVideo / 60))));
          }
        },
        { fireImmediately: true },
      ),
    [entry],
  );

  const handleChange = React.useCallback(
    (activity: Activity | undefined) => {
      entry.activityIdentifier = activity?.identifier ?? '';
    },
    [entry],
  );

  const fetch = React.useCallback((query: string) => Activity.find({ query }), []);

  const getOptionLabel = React.useCallback((option: Activity) => `${option.defaultName} (${option.identifier})`, []);

  const getOptionValue = React.useCallback((option: Activity) => option, []);

  const handleChangeMusic = React.useCallback(
    (value?: string) => {
      entry.recommendedMusicKey = value;
    },
    [entry],
  );

  const handleChangeDuration = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        runInAction(() => (entry.durationInMinutes = Number(value)));
      }
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Item label="Activity">
            <AsyncSelect
              defaultOptions
              isClearable={true}
              loadOptions={fetch}
              value={entry.activity}
              hideSelectedOptions={false}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue as any}
              onChange={handleChange as any}
            />
          </Form.Item>
        </Col>
        <Col>
          <MultiRowMap label="Data" container={entry.data} />
        </Col>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Col>
          <YogaInstructorEdit entry={entry} />
        </Col>
        <Col>
          <Row>
            <Col>
              <Form.Item label="Recommended Music">
                <Select value={entry.recommendedMusicKey} onSelect={handleChangeMusic}>
                  {YogaMusicSelection.map((music) => (
                    <Select.Option key={music.key} value={music.key}>
                      {music.title}
                    </Select.Option>
                  ))}
                </Select>
                {music && <ReactAudioPlayer src={music.source} controls={true} />}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Duration in Minutes">
                <InputNumber value={entry.durationInMinutes} onChange={handleChangeDuration} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
});
