import { computed, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedEntity } from '../LocalizedEntity';
import { Media } from '../Media/Media';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export class EquipmentTypeGroup extends LocalizedEntity {
  @observable
  medias: Array<Media> = [];
  @observable
  requiredEquipmentTypes: Array<string> = [];
  @observable
  equipmentTypes: Array<string> = [];

  constructor(json?: any) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.medias = (json.medias || []).map((m) => new Media(m));
      this.equipmentTypes = json.equipmentTypes || [];
      this.requiredEquipmentTypes = json.requiredEquipmentTypes || [];
    }
  }

  @computed
  get allEquipmentTypes(): Array<string> {
    return this.requiredEquipmentTypes.concat(this.equipmentTypes.slice());
  }

  delete() {
    return HttpBackend.delete(`/equipment/typeGroup/${this.id}`);
  }

  save() {
    return HttpBackend.post('/equipment/typeGroup', toJS(this)).then(() => this);
  }

  static list(params: any = {}): Promise<EquipmentTypeGroup[]> {
    return HttpBackend.get('/equipment/typeGroup', params).then((res) => res.map((e) => new EquipmentTypeGroup(e)));
  }

  static count(params: any = {}): Promise<number> {
    return HttpBackend.get('/equipment/typeGroup/count', params);
  }

  static async get(id: string): Promise<EquipmentTypeGroup | undefined> {
    const res = await HttpBackend.get(`/equipment/typeGroup/${id}`);
    if (res) {
      return new EquipmentTypeGroup(res);
    }
    return undefined;
  }

  static async getAll(ids: Array<string>): Promise<Array<EquipmentTypeGroup>> {
    if (ids.length > 0) {
      const data = await Promise.all(ids.map((id) => EquipmentTypeGroup.get(id)));
      return data.filter((b) => !!b) as Array<EquipmentTypeGroup>;
    }
    return [];
  }
}
