/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps } from '../../Default/DefaultStageNodeWidget';

export type LimitNodeWidgetProps = {} & StageNodeWidgetProps;

export const AndNodeWidget: React.FC<LimitNodeWidgetProps> = observer(
  ({ node, engine, size }: LimitNodeWidgetProps) => {
    return <DefaultStageNodeWidget engine={engine} node={node} size={size} disableEdit={true} title="AND" />;
  },
);
