/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Checkbox, Input } from 'antd';
import { PipelineParam } from '../../../../../Model/Coach/PipelineParam';
import CreatableSelect from 'react-select/creatable';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { ParamCondition } from '../../../../../Model/Coach/SuperMacro/ParamCondition';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';
import { ActionMeta } from 'react-select';
import { v4 as UUID } from 'uuid';

export type PhaseParamEditorInputProps = {
  phaseTemplate: ParamCondition | WorkoutTemplate | PhaseConfiguration;
  param: PipelineParam;
  phaseConfiguration?: PhaseConfiguration;
  workoutTemplate?: WorkoutTemplate;
  disabled?: boolean;
  suffix?: React.ReactNode;
};

export const PhaseParamEditorInput: React.FC<PhaseParamEditorInputProps> = observer((props) => {
  const { param, phaseTemplate, disabled, suffix } = props;

  const hasParam =
    (phaseTemplate.params ?? {})[param.name] !== undefined && (phaseTemplate.params ?? {})[param.name] !== null;

  const store = useLocalStore(
    (source) => ({
      get value(): any {
        return (source.phaseTemplate.params ?? {})[source.param.name];
      },
      get derivedValue(): string | string[] {
        if (source.phaseTemplate instanceof WorkoutTemplate) {
          if (Array.isArray(param.defaultValue)) {
            return param.defaultValue;
          }
          return `${param.defaultValue}`;
        } else if (source.phaseTemplate instanceof PhaseConfiguration) {
          const workoutValue = source.workoutTemplate?.params[source.param.name];
          const finalValue = workoutValue ?? param.defaultValue;
          if (Array.isArray(finalValue)) {
            return finalValue;
          }
          return `${finalValue}`;
        }
        const workoutValue = source.workoutTemplate?.params[source.param.name];
        const phaseValue = source.phaseConfiguration?.params[source.param.name];
        const finalValue = phaseValue ?? workoutValue ?? param.defaultValue;
        if (Array.isArray(finalValue)) {
          return finalValue;
        }
        return `${finalValue}`;
      },
      get derivedValueString(): string {
        if (typeof store.derivedValue === 'string') {
          return store.derivedValue;
        }
        return store.derivedValue.join(' | ');
      },
    }),
    props,
  );

  const handleChangeNumber = React.useCallback(
    (value?: number | string) => {
      phaseTemplate.setParam(param.name, value ?? 0);
    },
    [phaseTemplate, param],
  );

  const handleChangeString = React.useCallback(
    ({ target: { value } }) => {
      phaseTemplate.setParam(param.name, value ?? '');
    },
    [phaseTemplate, param],
  );

  const handleChangeBoolean = React.useCallback(
    ({ target: { checked } }) => {
      phaseTemplate.setParam(param.name, checked ?? false);
    },
    [param, phaseTemplate],
  );

  const handleAddStringArray = React.useCallback(() => {
    if (!phaseTemplate.params[param.name] || !Array.isArray(phaseTemplate.params[param.name])) {
      if (Array.isArray(store.derivedValue)) {
        phaseTemplate.setParam(param.name, store.derivedValue);
      } else {
        phaseTemplate.setParam(param.name, [store.derivedValue]);
      }
    }
  }, [param, phaseTemplate, store]);

  const handleChangeStringArray = React.useCallback(
    (tags: { value: string; label: string }[], { action }: ActionMeta<any>) => {
      const newValues = tags || [];
      if (action === 'clear') {
        phaseTemplate.removeParam(param.name);
      } else {
        if (newValues.length > 0) {
          phaseTemplate.setParam(
            param.name,
            newValues.map((t) => t.label),
          );
        } else {
          // phaseTemplate.removeParam(param.name);
          phaseTemplate.setParam(param.name, []);
        }
      }
    },
    [param, phaseTemplate],
  );

  const handleChangeBooleanArray = React.useCallback(
    (tags?: any[]) => {
      phaseTemplate.setParam(
        param.name,
        (tags ?? []).map((t) => (t.value === 'false' || !t.value ? false : true)),
      );
    },
    [param, phaseTemplate],
  );

  const handleChangeNumberArray = React.useCallback(
    (tags?: any[]) => {
      phaseTemplate.setParam(
        param.name,
        (tags ?? []).map((t) => Number(t.value)),
      );
    },
    [param, phaseTemplate],
  );

  switch (param.type) {
    case 'boolean':
      return <Checkbox onChange={handleChangeBoolean} checked={store.value} disabled={disabled} />;
    case 'stringArray':
      return (
        <div onClick={!hasParam ? handleAddStringArray : undefined}>
          <CreatableSelect
            isClearable
            isMulti
            isDisabled={disabled}
            onChange={handleChangeStringArray as any}
            placeholder={store.derivedValueString}
            isValidNewOption={() => true}
            value={(typeof store.value === 'string'
              ? [store.value]
              : typeof store.value === 'number'
              ? [`${store.value}`]
              : store.value ?? []
            ).map((label) => ({
              label,
              value: UUID(),
            }))}
          />
        </div>
      );
    case 'numberArray':
      return (
        <CreatableSelect
          isClearable
          isMulti
          isDisabled={disabled}
          onChange={handleChangeNumberArray as any}
          value={(store.value ?? []).map((value) => ({
            label: `${value}`,
            value,
          }))}
        />
      );
    case 'booleanArray':
      return (
        <CreatableSelect
          isClearable
          isMulti
          isDisabled={disabled}
          onChange={handleChangeBooleanArray as any}
          value={(store.value ?? []).map((value) => ({
            label: value ? 'true' : 'false',
            value: !!value,
          }))}
        />
      );
    case 'number':
    case 'string': // return <InputNumber value={store.value ?? 0} onChange={handleChangeNumber} disabled={disabled} />;
    default:
      return (
        <Input
          value={`${store.value ?? ''}`}
          onChange={handleChangeString}
          disabled={disabled}
          placeholder={store.derivedValueString}
          suffix={suffix}
        />
      );
  }
});
