/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { EarnModelTemplate } from '../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategory } from '../../../../Model/Engagement/EarnModelTemplateCategory';
import { Table } from 'reactstrap';
import { EarnModelTemplateRuleTableRow } from './EarnModelTemplateRuleTableRow';

export type EarnModelTemplateRuleTableProps = {
  template: EarnModelTemplate;
  category: EarnModelTemplateCategory;
};

export const EarnModelTemplateRuleTable: React.FC<EarnModelTemplateRuleTableProps> = observer(
  ({ template, category }) => {
    return (
      <Table size="sm">
        <thead>
          <tr>
            <th>Identifier</th>
            <th>Type</th>
            <th>Max. Activations</th>
            <th>Points</th>
            <th>Name</th>
            <th>Depends On</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {category.rules.map((rule) => (
            <EarnModelTemplateRuleTableRow key={rule.id} template={template} category={category} rule={rule} />
          ))}
        </tbody>
      </Table>
    );
  },
);
