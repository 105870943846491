/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import { ExercisePositionPrimarySelect } from './ExercisePositionPrimarySelect';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { ExercisePositionSecondarySelect } from './ExercisePositionSecondarySelect';

export type ExercisePositionSelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExercisePositionSelect: React.FC<ExercisePositionSelectProps> = observer(
  ({ exercise, disabled }: ExercisePositionSelectProps) => {
    return (
      <Row>
        <Col>
          <ExercisePositionPrimarySelect exercise={exercise} disabled={disabled} />
        </Col>
        <Col>
          <ExercisePositionSecondarySelect exercise={exercise} disabled={disabled} />
        </Col>
      </Row>
    );
  },
);
