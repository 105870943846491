/**
 * Created by katarinababic on 17.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { TranslationArrayInput } from '../../../../Translation/TranslationArrayInput';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid';

export type ComponentStylesInnerBoldTextProps = {
  component: NativeFeedComponent;
};

export const ComponentStylesInnerBoldText: React.FC<ComponentStylesInnerBoldTextProps> = observer(({ component }) => {
  const { classes } = useStyles();

  return (
    <Grid className={classes.container} id="componentStylesInnerBoldText">
      <Grid item xs={12}>
        <Typography>Text before bold text</Typography>
        {component.text ? <TranslationArrayInput multiline={true} translations={component.text} /> : null}
        <Typography>Bold text</Typography>
        {component.children[0] ? (
          <TranslationArrayInput multiline={true} translations={component.children[0].text} />
        ) : null}
        <Typography className={classes.label}>Text after bold text</Typography>
        {component.children[1] ? (
          <TranslationArrayInput multiline={true} translations={component.children[1].text} />
        ) : null}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
  label: {
    paddingTop: 16,
  },
}));
