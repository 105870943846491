/**
 * Created by neo on 22.06.21.
 */
import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export type NotificationRequestJson = {
  requestId: string;
  athleteIds: string[];
  /**
   * It's either "club" or "coach"
   */
  app: 'coach' | 'club';
  title?: string;
  message?: string;
  payload: Record<string, string>;
  topic?: string;
  condition?: string;
  sendAt?: string;
  sticky: boolean;
  /**
   * An ID that any external system can set. Like when you have multiple reminders for an event and you change the config
   * then you can just delete all requests of the same groupId at once
   */
  groupId?: string;
  imageUrl?: string;
  /**
   * time to live (expiration in seconds)
   */
  ttl: number;
  /**
   * If the message is collapsible (discards other notifications with same key)
   */
  collapseKey?: string;
  analyticsLabel?: string;
};

export class NotificationRequest {
  @observable
  requestId = UUID();
  @observable
  athleteIds: string[] = [];
  /**
   * It's either "club" or "coach"
   */
  @observable
  app: 'coach' | 'club' = 'coach';
  @observable
  title?: string;
  @observable
  message?: string;
  @observable
  payload: Record<string, string> = {};
  @observable
  topic?: string;
  @observable
  condition?: string;
  @observable
  sendAt?: Date;
  @observable
  sticky: boolean = true;
  /**
   * An ID that any external system can set. Like when you have multiple reminders for an event and you change the config
   * then you can just delete all requests of the same groupId at once
   */
  @observable
  groupId?: string;
  @observable
  imageUrl?: string;
  /**
   * time to live (expiration in seconds)
   */
  @observable
  ttl: number = -1;
  /**
   * If the message is collapsible (discards other notifications with same key)
   */
  @observable
  collapseKey?: string;
  @observable
  analyticsLabel?: string;

  constructor(json?: Partial<NotificationRequestJson>) {
    if (json) {
      this.requestId = json.requestId ?? UUID();
      this.athleteIds = json.athleteIds ?? [];
      this.app = json.app ?? 'coach';
      this.title = json.title;
      this.message = json.message;
      this.payload = json.payload ?? {};
      this.topic = json.topic;
      this.condition = json.condition;
      this.sendAt = json.sendAt ? new Date(json.sendAt) : undefined;
      this.sticky = json.sticky ?? true;
      this.groupId = json.groupId;
      this.imageUrl = json.imageUrl;
      this.ttl = json.ttl ?? -1;
      this.collapseKey = json.collapseKey;
      this.analyticsLabel = json.analyticsLabel;
    }
  }

  toJS(): NotificationRequestJson {
    return {
      requestId: this.requestId,
      athleteIds: this.athleteIds,
      app: this.app,
      title: this.title,
      message: this.message,
      payload: this.payload,
      topic: this.condition,
      sendAt: this.sendAt?.toISOString(),
      sticky: this.sticky,
      groupId: this.groupId,
      imageUrl: this.imageUrl,
      ttl: this.ttl,
      collapseKey: this.collapseKey,
      analyticsLabel: this.analyticsLabel,
    };
  }

  send(): Promise<NotificationRequest> {
    return HttpBackend.post(`/messaging/notification/enqueue`, this.toJS()).then(() => this);
  }
}
