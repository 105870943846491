import * as ls from 'local-storage';

export class AppStoreClass {
  store(key: string, data: any) {
    return ls.set(key, data);
  }

  get(key: string) {
    return ls.get(key);
  }

  delete(key: string) {
    return ls.remove(key);
  }

  truncate() {
    ls.clear();
  }
}

const AppStore = new AppStoreClass();
export default AppStore;
