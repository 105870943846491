/**
 *
 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';

export type SingleColRowProps = {
  style?: any;
  rowStyle?: any;
};

export const SingleColRow: React.FC<PropsWithChildren<SingleColRowProps>> = observer(
  ({ children, style, rowStyle }) => {
    return (
      <Row style={rowStyle}>
        <Col style={style}>{children}</Col>
      </Row>
    );
  },
);
