/**
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseFilterBodyPartCard } from './ExerciseFilterBodyPartCard';
import { observer } from 'mobx-react';

export type ExerciseVariationFilterBodyPartsProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterBodyParts: React.FC<ExerciseVariationFilterBodyPartsProps> = observer(
  ({ filter }: ExerciseVariationFilterBodyPartsProps) => {
    return <ExerciseFilterBodyPartCard header="Body Parts" bodyPartIds={filter.bodyPartIds ?? []} />;
  },
);
