/**
 * Created by andreaskarantzas on 2018-12-03
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { ThemeConfig } from '../../../../Config/Theme';
import Grid from '@mui/material/Grid';

export type NativeFeedPhoneBezelProps = {};

export const NativeFeedPhoneBezel: React.FC<NativeFeedPhoneBezelProps> = observer(() => {
  const { classes } = useStyles();
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.upperBezel}>
      <Grid item className={classes.camera} />
      <Grid item className={classes.earpiece} />
    </Grid>
  );
});

const useStyles = makeStyles()(() => ({
  upperBezel: {
    height: 32,
    backgroundColor: ThemeConfig.Colors.charcoalGrey,
  },
  earpiece: {
    height: 8,
    width: 32,
    borderRadius: 4,
    backgroundColor: ThemeConfig.Colors.white,
  },
  camera: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: ThemeConfig.Colors.white,
    marginRight: 8,
  },
}));
