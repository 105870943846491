import objectPath from 'object-path';
import { LogicalStage } from './LogicalStage';
import { DataSignal } from '../../Signal/DataSignal';

export class AllStage extends LogicalStage {
  canProceed(signal: DataSignal): boolean {
    const object = this.config.source === 'input' ? signal.data : this.pipeline.context;
    const value = objectPath.get(object, this.config.attribute);
    return this.compareValues(value, this.config.value);
  }

  toJS(): any {
    return super.toJS();
  }

  get type(): string {
    return 'logical:all';
  }

  protected compareValues(value0: any, value1: any): boolean {
    const array0 = Array.isArray(value0) ? value0 : undefined;
    const array1 = Array.isArray(value1) ? value1 : undefined;

    if (array0 && array1) {
      if (array0.length === 0 && array1.length === 0) {
        return true;
      }
      return array1.findIndex((a) => !array0.find((b) => a === b)) === -1;
    }
    throw new Error('both sides need to be arrays');
  }
}
