import * as React from 'react';
import { observer } from 'mobx-react';
import { DomainEvent } from '../../../../../../Model/Axon/DomainEvent';
import ReactJson from 'react-json-view';
import { AxonQueryTableRowColumn } from './AxonQueryTableRowColumn';

export type AxonQueryTableRowProps = {
  event: DomainEvent;
  onFilter?: (filter: string) => any;
};

export const AxonQueryTableRow: React.FC<AxonQueryTableRowProps> = observer(
  ({ event, onFilter }: AxonQueryTableRowProps) => {
    return (
      <tr>
        <td>
          <AxonQueryTableRowColumn event={event} property="token" comparator=">=" onFilter={onFilter} />
        </td>
        {/*<td>{event.eventIdentifier}</td>*/}
        <td>
          <AxonQueryTableRowColumn event={event} property="aggregateIdentifier" onFilter={onFilter} quoteValue />
        </td>
        <td>
          <AxonQueryTableRowColumn
            event={event}
            property="aggregateSequenceNumber"
            comparator=">="
            onFilter={onFilter}
          />
        </td>
        <td>
          <AxonQueryTableRowColumn event={event} property="aggregateType" onFilter={onFilter} quoteValue />
        </td>
        <td>
          <AxonQueryTableRowColumn
            event={event}
            property="payloadTypeShort"
            domainProperty="payloadType"
            onFilter={onFilter}
            quoteValue
          />
        </td>
        <td>{event.payloadRevision}</td>
        <td>
          <ReactJson src={event.payloadData} displayDataTypes={false} collapsed={2} sortKeys />
        </td>
        <td>{event.timestamp}</td>
        <td>{event.metaData}</td>
      </tr>
    );
  },
);
