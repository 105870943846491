/**
 * Created by neo on 17.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallengeLocation } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeLocation';
import { Form, InputNumber, message, Modal, Upload } from 'antd';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { runInAction } from 'mobx';
import { Col, Row } from 'reactstrap';
import { RcFile } from 'antd/es/upload';
import MediaService from '../../../../../Services/MediaService';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Media } from '../../../../../Model/Media/Media';
import { RouteChallengeLocationModalMedia } from './RouteChallengeLocationModalMedia';

export type RouteChallengeLocationModalProps = {
  challenge: RouteChallenge;
  location: RouteChallengeLocation;
  onClose?: () => void;
};

export const RouteChallengeLocationModal: React.FC<RouteChallengeLocationModalProps> = observer(
  ({ challenge, location, onClose }) => {
    const [uploading, setUploading] = useState(false);

    const beforeUpload = (file: RcFile) => {
      if (!file.type.toLowerCase().startsWith('image/')) {
        message.error('You can only upload JPG/PNG file!');
      } else if (file.size / 1024 / 1024 > 2) {
        message.error('Image must smaller than 2MB!');
      } else {
        setUploading(true);
        MediaService.uploadMedia(file)
          .then((media) => {
            runInAction(() => (location.image = media));
          })
          .catch(() => message.error('Failed to upload image'))
          .finally(() => setUploading(false));
      }

      return false;
    };

    const handleImageUploaded = React.useCallback(
      (media: Media) => {
        runInAction(() => location.medias.push(media));
      },
      [location],
    );

    const handleRemoveImage = React.useCallback(
      (media: Media) => {
        runInAction(() => (location.medias = location.medias.filter((v) => v.id !== media.id)));
      },
      [location],
    );

    const uploadButton = (
      <div>
        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <Modal open={true} width={1200} onCancel={onClose} onOk={onClose} title="Edit Location">
        <Form layout="vertical">
          <Row>
            <Col md={12}>
              <Form.Item label="Name">
                <TranslationInputArray entity={location} field="name" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Description">
                <TranslationInputArray markdown={true} type="textarea" entity={location} field="description" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Points Required">
                <InputNumber
                  value={location.pointsRequired}
                  min={1}
                  step={1}
                  onChange={(value) => runInAction(() => (location.pointsRequired = Number(value)))}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Image">
                <Upload
                  key="avatar"
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  style={{ width: '100%' }}
                >
                  {location.image?.medium ? (
                    <img
                      src={location.image?.medium}
                      alt="avatar"
                      style={{ width: '100%', height: 100, objectFit: 'contain' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Images">
                <div style={{ display: 'flex' }}>
                  {location.medias.concat([undefined] as any).map((media) => (
                    <div key={media?.id} style={{ marginLeft: 8 }}>
                      <RouteChallengeLocationModalMedia
                        onRemove={handleRemoveImage}
                        onUploaded={handleImageUploaded}
                        // onClick={handleClickMedia}
                        media={media}
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  },
);
