/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { SuperMacroWorkoutDayConfiguration } from '../../../../../Model/Coach/SuperMacro/SuperMacroWorkoutDayConfiguration';
import styled from '@emotion/styled';
import { WorkoutEntryEditor } from './WorkoutEntryEditor';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';

export type WorkoutDayEditorProps = {
  mesoCycle: SuperMacroMesoCycleTemplate;
  workoutDay: SuperMacroWorkoutDayConfiguration;
  index: number;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
`;

const Header = styled.div`
  padding: 4px;
`;

const Title = styled.div`
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const WorkoutDayEditor: React.FC<WorkoutDayEditorProps> = observer(
  ({ workoutDay, mesoCycle, index }: WorkoutDayEditorProps) => {
    const [dragOver, setDragOver] = useState(false);
    const timeout = React.useRef<any>(undefined);

    const handleDragOver = React.useCallback(() => {
      setDragOver(true);
    }, []);

    const handleDragLeave = React.useCallback(() => {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setDragOver(false), 100);
    }, []);

    const handleDrop = React.useCallback(
      (workoutEntry) => {
        workoutDay.workouts.push(workoutEntry);
        mesoCycle.save();
      },
      [mesoCycle, workoutDay],
    );

    const handleRemoveEntry = React.useCallback(
      (entry) => {
        entry.workoutTemplateId = '';
        // workoutDay.workouts = workoutDay.workouts.filter((w) => w.id !== entry.id);
        mesoCycle.save();
      },
      [mesoCycle],
    );

    return (
      <Container onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
        <Header>
          <Title>{`Day ${index + 1}`}</Title>
        </Header>
        <Body>
          {workoutDay.workouts.map((workoutEntry) => (
            <WorkoutEntryEditor
              key={workoutEntry.id}
              mesoCycle={mesoCycle}
              workoutDay={workoutDay}
              workoutEntry={workoutEntry}
              onRemove={handleRemoveEntry}
            />
          ))}
        </Body>
      </Container>
    );
  },
);
