/**
 * Created by neo on 20.05.23
 */
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../../../../Model/LocalizedValue';
import { NutrientMapJson, NutritionInformation } from '../../../../Model/Diet/Ingredient/NutritionInformation';
import { defaultPossibleUnits, Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { RecipeIngredient, RecipeIngredientJson } from '../../../../Model/Diet/Recipe/RecipeIngredient';
import { RecipeInstructionStepJson } from '../../../../Model/Diet/Recipe/RecipeInstructionStep';
import { CaloricBreakdownJson } from '../../../../Model/Diet/CaloricBreakdown';
import { RecipeInstruction } from '../../../../Model/Diet/Recipe/RecipeInstruction';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { DeeplService } from '../../../../Services/DeeplService';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { Sleep } from '../../../../Utils/Sleep';

function parseTranslatedArray(array: any): LocalizedValueJson[] {
  console.log('array', Array.isArray(array), array);
  if (Array.isArray(array)) {
    if (array.length > 1) {
      return array;
      // return array.flatMap((item) =>
      //   Array.from(Object.entries(item)).map(([lang, value]) => ({ lang, value: value as string })),
      // );
    }
    return Array.from(Object.entries(array[0] ?? {}))?.map(([lang, value]) => ({ lang, value: value as string })) ?? [];
  } else if (typeof array === 'string') {
    return [{ lang: 'de', value: array }];
  }
  return Array.from(Object.entries(array ?? {}))?.map(([lang, value]) => ({ lang, value: value as string })) ?? [];
}

function getTranslation(object: any[], language: string): string | undefined {
  return object.find((item) => item.lang === language)?.value;
  // if (object.length > 1) {
  //   return object.find((item) => item[language])?.[language];
  // }
  // return object[0]?.[language];
}

function fixUnit(unit?: string): string {
  if (unit?.toLowerCase() === 'grams') {
    return 'g';
  } else if (unit?.toLowerCase() === 'tablespoons') {
    return 'tablespoon';
  } else if (unit?.toLowerCase() === 'milliliters') {
    return 'milliliter';
  }
  return unit?.toLowerCase() ?? '';
}

function fixNutrientJson(nutrientJson: any): NutrientMapJson {
  return Array.from(Object.entries(nutrientJson)).reduce(
    (acc, [key, value]) => {
      if (!acc[key.toLowerCase()]) {
        return {
          ...acc,
          [key.toLowerCase()]: {
            amount: Number(value ?? 0),
          },
        };
      }
      return acc;
    },
    {
      calories: {
        amount: Number(nutrientJson?.calories ?? nutrientJson?.kcal ?? 0),
        unit: 'kcal',
      },
      carbohydrates: {
        amount: Number(nutrientJson?.carbs ?? nutrientJson?.carbohydrates ?? nutrientJson?.total_carbohydrates ?? 0),
        unit: 'g',
      },
      sugar: {
        amount: Number(nutrientJson?.sugar ?? nutrientJson?.sugars ?? 0),
        unit: 'g',
      },
      fat: {
        amount: Number(nutrientJson?.fat ?? nutrientJson?.total_fats ?? 0),
        unit: 'g',
      },
      saturated_fat: {
        amount: Number(nutrientJson?.saturated_fats ?? nutrientJson?.saturated_fat ?? nutrientJson?.saturatedfat ?? 0),
        unit: 'g',
      },
      cholesterol: {
        amount: Number(nutrientJson?.cholesterol ?? 0),
        unit: 'g',
      },
      protein: {
        amount: Number(nutrientJson?.protein ?? 0),
        unit: 'g',
      },
      fiber: {
        amount: Number(nutrientJson?.fiber ?? nutrientJson?.dietary_fiber ?? 0),
        unit: 'g',
      },
      iron: {
        amount: Number(nutrientJson?.iron ?? 0),
        unit: 'mg',
      },
      potassium: {
        amount: Number(nutrientJson?.potassium ?? 0),
        unit: 'mg',
      },
      salt: {
        amount: Number(nutrientJson?.salt ?? 0),
        unit: 'g',
      },
      sodium: {
        amount: Number(nutrientJson?.sodium ?? 0),
        unit: 'g',
      },
      calcium: {
        amount: Number(nutrientJson?.calcium ?? 0),
        unit: 'mg',
      },
      vitamin_c: {
        amount: Number(nutrientJson?.vitamin_c ?? 0),
        unit: 'mg',
      },
      vitamin_e: {
        amount: Number(nutrientJson?.vitamin_e ?? 0),
        unit: 'mg',
      },
      vitamin_a: {
        amount: Number(nutrientJson?.vitamin_a ?? 0),
        unit: 'IU',
      },
    },
  );
}

async function createIngredient(ingredient: any): Promise<Ingredient> {
  const germanText = getTranslation(ingredient.name, 'de');
  const englishText = getTranslation(ingredient.name, 'de');
  const query = englishText || germanText;
  const prompt = `Give me the nutritional values in an JSON object for ${query} per 100grams. 
  Minimally include: calories, carbs, sugar, fat, protein, fiber, saturated fats, salt, sodium. Add any more where it makes sense. If applicable also add any vitamins.
  Respond only with the JSON object where the keys are the nutrients in lowercase english. 
`;

  return new GptResponseService([
    {
      role: 'system',
      content: `You act as a nutrient database and respond me only in JSON`,
    },
    { role: 'user', content: prompt },
  ])
    .generate()
    .then((res) => {
      try {
        return JSON.parse(res);
      } catch (e) {
        console.log('error', e);
      }
      return {};
    })
    .then(
      (nutrientJson) =>
        new Ingredient({
          name: parseTranslatedArray(ingredient.name ?? []),
          nutrition: {
            nutrients: fixNutrientJson(nutrientJson),
            properties: {},
            flavanoids: {},
            caloricBreakdown: {
              percentProtein: 0,
              percentFat: 0,
              percentCarbs: 0,
            },
            weightPerServing: {
              amount: 0,
              unit: 'g',
            },
          },
        }),
    )
    .then((ingredient) => ingredient.save());
}

function getUnit(amount: number, unit: string): string | undefined {
  const unitSmall = unit.toLowerCase();
  if (unitSmall === 'tbsp' || unitSmall === 'tablespoon' || unitSmall === 'tablespoons') {
    if (amount > 1) {
      return `${amount} tablespoons`;
    } else {
      return `${amount} tablespoon`;
    }
  } else if (unitSmall === 'tsp' || unitSmall === 'teaspoon' || unitSmall === 'teaspoons') {
    if (amount > 1) {
      return `${amount} teaspoons`;
    } else {
      return `${amount} teaspoon`;
    }
  } else if (unitSmall === 'g') {
    return `${amount} grams`;
  } else if (unitSmall === 'ml') {
    if (amount > 1) {
      return `${amount} milliliters`;
    } else {
      return `${amount} milliliter`;
    }
  } else if (unitSmall === 'l') {
    if (amount > 1) {
      return `${amount} liters`;
    } else {
      return `${amount} liter`;
    }
  } else if (unitSmall === 'kg') {
    if (amount > 1) {
      return `${amount} kilograms`;
    } else {
      return `${amount} kilogram`;
    }
  } else if (unitSmall === 'piece') {
    if (amount > 1) {
      return `${amount} pieces`;
    } else {
      return `${amount} piece`;
    }
  } else if (unitSmall) {
    return `${amount} ${unitSmall}`;
  }

  return undefined;
}

function getRecipeIngredientDescription(ingredient: any): Promise<LocalizedValueJson[]> {
  if (ingredient.description) {
    return Promise.resolve(parseTranslatedArray(ingredient.description ?? []));
  }

  const amount = ingredient.amount ?? 0;
  const unit = fixUnit(ingredient.unit);
  if (ingredient.description.length === 0 && amount > 0 && unit) {
    const english = getUnit(amount, unit);
    if (english) {
      return Promise.all([
        DeeplService.translate({ text: english, sourceLanguage: 'en', targetLanguage: 'de' }),
        DeeplService.translate({ text: english, sourceLanguage: 'en', targetLanguage: 'fr' }),
        DeeplService.translate({ text: english, sourceLanguage: 'en', targetLanguage: 'it' }),
      ]).then(([de, fr, it]) => {
        return [
          { lang: 'en', value: english },
          { lang: 'de', value: de ?? '' },
          { lang: 'fr', value: fr ?? '' },
          { lang: 'it', value: it ?? '' },
        ];
      });
    }
  }

  return Promise.resolve([]);
}

async function resolveIngredient(ingredient: any): Promise<RecipeIngredient> {
  const germanText = getTranslation(ingredient.name, 'de');
  const englishText = getTranslation(ingredient.name, 'de');
  const query = germanText || englishText;
  const existing = germanText
    ? (await Ingredient.search({ query })).find(
        (i) =>
          i.getName('de').toLowerCase() === germanText?.toLowerCase() || i.getName('en') === englishText?.toLowerCase(),
      ) ?? (await createIngredient(ingredient))
    : await createIngredient(ingredient);

  const description = await getRecipeIngredientDescription(ingredient);

  return new RecipeIngredient(
    Object.assign(existing.toJS(), {
      possibleUnits: defaultPossibleUnits,
      description,
      measures: {
        metric: {
          amount: ingredient.amount,
          unitShort: fixUnit(ingredient.unit),
        },
      },
    }) as any,
  );
}

function resolveIngredients(ingredients: any[]): Promise<RecipeIngredient[]> {
  return Promise.all(ingredients.map((ingredient) => resolveIngredient(ingredient)));
}

function parseInstructionSteps(steps: any[]): RecipeInstructionStepJson[] {
  return steps.map((step, number) => ({
    number: number + 1,
    step: parseTranslatedArray(
      (step.description?.length ?? 0) > 0
        ? step.description
        : step.text ?? step.content ?? step.details ?? step.instructions ?? step.name ?? step,
    ),
    ingredients: [],
    equipment: [],
  }));
}

function calculateCaloricBreakdown(nutrientJson: NutrientMapJson): CaloricBreakdownJson {
  const carbsCalories = (nutrientJson.carbohydrates?.amount ?? 0) * 4.1;
  const fatCalories = (nutrientJson.fat?.amount ?? 0) * 9.3;
  const proteinCalories = (nutrientJson.protein?.amount ?? 0) * 4.1;
  const totalCalories = carbsCalories + fatCalories + proteinCalories;
  return {
    percentCarbs: Math.round((carbsCalories / totalCalories) * 1000) / 10,
    percentFat: Math.round((fatCalories / totalCalories) * 1000) / 10,
    percentProtein: Math.round((proteinCalories / totalCalories) * 1000) / 10,
  };
}

function createStepFromJson(step: any, number: number): RecipeInstructionStepJson {
  return {
    number,
    step: parseTranslatedArray(step.description),
    ingredients: [],
    equipment: [],
  };
}

function generateInstructions(recipeName: string, ingredients: RecipeIngredient[]): Promise<RecipeInstruction[]> {
  return new GptResponseService([
    {
      role: 'system',
      content: `Take the role of a recipe database.
      The recipes should not be translated from American recipes and just transferred to metric scale but they need to be authentic so that Swiss people feel spoken for.
      Use informal language.
      Rinderstreifen should be Rindsstreifen
      Rinderfilet should be Rindsfilet
      Lasagneplatten should be Lasagneplätter
      When you refer to the vegetable paprika, please use the word peperoni for Swiss audience
      Instead of kirschtomaten use cherry-tomaten
      Randen instead of Rote Beeten
      Paniermehl instead of semmelbröt
      Hacktäschen instead of Frikadellen
      Never use "ẞ" , replace it always with "ss"
      
      The ingredients used in the recipes are: ${ingredients
        .map(
          (i) =>
            `${i.getName('en') ?? i.getName('de')} ${
              (i.measures.metric?.amount ?? 0) > 0
                ? `(${i.measures.metric?.amount} ${i.measures.metric?.unitShort})`
                : ''
            }`,
        )
        .join(', ')}

      The response has to be a JSON array containing only the required steps/tasks describing how someone can prepare and cook the recipe. 
      Make each step easy to understand and detailed. Each step should be only about one single task like "preheat the oven" or "cut the vegetables".
      Use 2 to 3 sentences per step whenever it makes sense. For each object in the array, the following properties are required:
      - description: an array of objects containing the description of the step in different languages
      - description.lang: two letter lowercase language code de | en | fr | it
      - description.value: the current step required to prepare or cook the recipe which are easy to understand and and should be around 2 to 3 sentences long.  Omit wordings like "step 1" or "1., 2." etc.`,
    },
    {
      role: 'user',
      content: recipeName,
    },
  ])
    .generate()
    .then((res) => JSON.parse(res))
    .then((steps) => {
      return [
        new RecipeInstruction({
          steps: steps
            .map((s, index) => createStepFromJson(s, index + 1))
            .filter((s) => s.step.find((l) => l.lang === 'de')?.value !== recipeName),
        }),
      ];
    });
}

const RECIPE_SYSTEM_MESSAGE = `You are a recipe database for a Swiss health prevention startup.
The recipes should be simple and easy to make from a few ingredients that one can find from a normal store like Coop, Denner, or Migros, and always be for 4 people.
Swiss people love the recipes from Tiptopf, Fooby.ch, and Betty Bossi but the app creators would like the recipes to be trendy like the ones in Gymondo.
So you should inspire and learn about the nutritional preferences of those Swiss cooking platforms but create the recipes in a style of Gymondo so they look fresh and take into account the current food trends.
The recipes need to be in high German but avoid using words that do not exist in Switzerland like chalotten. Swiss people know only Zwiebeln, and use metric scale.
The recipes should not be translated from American recipes and just transferred to metric scale but they need to be authentic so that Swiss people feel spoken for.
In any language used in the recipe, use informal language.
Please also keep an eye for the following instructions in German:
Use always the "du" form e.g. informal form.
Rinderstreifen should be Rindsstreifen
Rinderfilet should be Rindsfilet
Lasagneplatten should be Lasagneplätter
When you refer to the vegetable paprika, please use the word peperoni for Swiss audience
Instead of kirschtomaten use cherry-tomaten
Randen instead of Rote Beeten
Paniermehl instead of Semmelbröt
Hacktäschen instead of Frikadellen
Never use "ẞ" , replace it always with "ss"

The response has to be a JSON object containing following properties:
- name: array of objects
- name.lang: two letter lowercase language code de | en | fr | it
- name.value: the name of the recipe
- description: array of objects
- description.lang: two letter lowercase language code de | en | fr | it
- description.value: the description of the recipe
- servings: number of servings
- difficulty: easy | medium | hard
- preparation_time: number of minutes
- cooking_time: number of minutes
- weight_per_serving: number of grams
- diets: array of lowercase English words e.g. vegan, vegetarian, glutenfree, lactose-free, low-carb, low-fat, low-sugar, paleo, pescatarian, keto, whole30
- cuisines: array of lowercase English words e.g. swiss, german, french, african, carribbean etc.
- dish_types: array of lowercase English words e.g. breakfast, lunch, dinner, dessert, snack, appetizer, side dish, drink, sauce, soup, salad, bread, smoothie, shake, cake, pie, casserole, stew, curry, burger, pizza, pasta, sandwich, wrap, fingerfood, finger food, finger-food, fingerfood
- seasons: array of lowercase English words spring | summer | autumn | winter (if all apply use all)
- nutritional_information: object containing the nutritional values per servings. The nutritional_information needs to minimally include: calories, carbs, sugar, fat, protein, fiber, saturated fats, salt, sodium. Add any more where it makes sense. If applicable also add any vitamins.
- ingredients: array of objects whereas each entry is an object with the following properties:
- ingredients.name: array translated into German, English Italian, and French
- ingredients.name.lang: two letter lowercase language code de | en | fr | it
- ingredients.name.value: the name of the ingredient
- ingredients.amount: the amount required without unit
- ingredients.unit: the unit of the amount in lowercase English using metric scale wherever possible e.g. g, ml, piece, tablespoon, teaspoon, pinch, piece etc.
- ingredients.description: array per ingredient whereas each entry is an object with the following properties:
- ingredients.description.lang: two letter lowercase language code de | en | fr | it
- ingredients.description.value: the description of the amount required like "1 piece" or "2 Stück"
- steps: array of objects containing the required steps/tasks describing how someone can prepare and cook the recipe. Make each step easy to understand and detailed. Make each step easy to understand and detailed. Each step should be only about one single task like "preheat the oven" or "cut the vegetables". Use 2 to 3 sentences per step whenever it makes sense. For each object in the array, the following properties are required: Each entry is an object with the following properties:
- steps.description: an array of objects containing the description of the step in different languages
- steps.description.lang: two letter lowercase language code de | en | fr | it
- steps.description.value: the current step required to prepare or cook the recipe which are easy to understand and and should be around 2 to 3 sentences long. Omit wordings like "step 1" or "1., 2." etc.
`;

export class RecipeGenerator {
  @observable
  queued = false;
  // @observable
  // generating = false;
  @observable
  creating = false;
  @observable
  done = false;
  @observable
  saved = false;
  @observable
  requiresAttention = false;
  @observable
  exists = false;
  @observable
  jsonText = '';
  @observable
  error: any;
  @observable
  recipe = new Recipe();
  @observable
  messages: string[] = [];
  @observable
  tmpMessage: string = '';
  @observable
  private responseService?: GptResponseService;

  constructor(public recipeName: string, recipe?: Recipe) {
    this.recipe = recipe ?? new Recipe();
    this.exists = !!recipe;
    reaction(
      () => this.jsonText,
      async (jsonText) => {
        if (jsonText.trim()) {
          runInAction(() => (this.creating = true));
          try {
            const json = JSON.parse(jsonText);
            const ingredients = await resolveIngredients(json.ingredients ?? []);
            const instructions =
              (json.steps?.length ?? 0) > 0
                ? [
                    new RecipeInstruction({
                      steps: parseInstructionSteps(json.steps ?? []),
                    }),
                  ]
                : await generateInstructions(recipeName, ingredients);

            runInAction(() => {
              this.recipe.name = parseTranslatedArray(json.name ?? []).map((l) => new LocalizedValue(l));
              this.recipe.description = parseTranslatedArray(json.description ?? []).map((l) => new LocalizedValue(l));
              this.recipe.servings = Number(json.servings ?? 0);
              this.recipe.readyInMinutes = (json.preparation_time ?? 0) + Number(json.cooking_time ?? 0);
              this.recipe.diets = json.diets ?? [];
              this.recipe.cuisines = json.cuisines ?? [];
              this.recipe.dishTypes = json.dish_types ?? [];
              this.recipe.seasons = json.seasons ?? [];
              this.recipe.ingredients = ingredients;
              this.recipe.instructions = instructions;

              this.recipe.nutrition = new NutritionInformation({
                weightPerServing: {
                  amount: Number(json.weight_per_serving ?? 0),
                  unit: 'g',
                },
                nutrients: fixNutrientJson(json.nutritional_information ?? {}),
                caloricBreakdown: calculateCaloricBreakdown(fixNutrientJson(json.nutritional_information ?? {})),
              });
            });

            console.log('recipe', this.recipe.nutrition.nutrients);
            runInAction(() => (this.done = true));

            if (this.recipeIsOk) {
              this.recipe
                .save()
                .then(() => runInAction(() => (this.saved = true)))
                .catch(() => runInAction(() => (this.error = true)));
            } else {
              runInAction(() => (this.requiresAttention = true));
            }
          } catch (e) {
            console.error(e);
            runInAction(() => (this.error = e));
            if (e instanceof SyntaxError) {
              this.fixJson(jsonText.trim());
            }
          } finally {
            runInAction(() => (this.creating = false));
          }
        }
      },
    );
  }

  @action
  private fixJson(json: string) {
    this.responseService = new GptResponseService([
      {
        role: 'system',
        content: `You act as a software engineer and you have to fix the given JSON. Respond with the fixed JSON only. The expected JSON format is as follows:
- name: array of objects
- name.lang: two letter lowercase language code de | en | fr | it
- name.value: the name of the recipe
- description: array of objects
- description.lang: two letter lowercase language code de | en | fr | it
- description.value: the description of the recipe
- servings: number of servings
- difficulty: easy | medium | hard
- preparation_time: number of minutes
- cooking_time: number of minutes
- weight_per_serving: number of grams
- diets: array of lowercase English words e.g. vegan, vegetarian, glutenfree, lactose-free, low-carb, low-fat, low-sugar, paleo, pescatarian, keto, whole30
- cuisines: array of lowercase English words e.g. swiss, german, french, african, carribbean etc.
- dish_types: array of lowercase English words e.g. breakfast, lunch, dinner, dessert, snack, appetizer, side dish, drink, sauce, soup, salad, bread, smoothie, shake, cake, pie, casserole, stew, curry, burger, pizza, pasta, sandwich, wrap, fingerfood, finger food, finger-food, fingerfood
- seasons: array of lowercase English words spring | summer | autumn | winter (if all apply use all)
- nutritional_information: object containing the nutritional values per servings. The nutritional_information needs to minimally include: calories, carbs, sugar, fat, protein, fiber, saturated fats, salt, sodium. Add any more where it makes sense. If applicable also add any vitamins.
- ingredients: array of objects whereas each entry is an object with the following properties:
- ingredients.name: array translated into German, English Italian, and French
- ingredients.name.lang: two letter lowercase language code de | en | fr | it
- ingredients.name.value: the name of the ingredient
- ingredients.amount: the amount required without unit
- ingredients.unit: the unit of the amount in lowercase English using metric scale wherever possible e.g. g, ml, piece, tablespoon, teaspoon, pinch, piece etc.
- ingredients.description: array per ingredient whereas each entry is an object with the following properties:
- ingredients.description.lang: two letter lowercase language code de | en | fr | it
- ingredients.description.value: the description of the amount required like "1 piece" or "2 Stück"
- steps: array of objects whereas each entry is an object with the following properties:
- steps.name: array translated into German, English Italian and French
- steps.name.lang: two letter lowercase language code de | en | fr | it
- steps.name.value: the current step required to prepare or cook the recipe which are easy to understand and and should be around 2 to 3 sentences long
- steps.description: array translated into German, English Italian and French`,
      },
      {
        role: 'user',
        content: json,
      },
    ]);
    return this.responseService
      .generate((message) => runInAction(() => (this.tmpMessage = message)))
      .then((res) => runInAction(() => (this.jsonText = res)))
      .catch((err) => {
        console.error(err);
        runInAction(() => (this.error = err));
        return err;
      });
  }

  save() {
    this.recipe.save();
    this.saved = true;
  }

  @action
  reset() {
    this.saved = false;
    this.responseService = undefined;
    // this.generating = false;
    this.creating = false;
    this.done = false;
    this.saved = false;
    this.requiresAttention = false;
    this.messages = [];
    this.jsonText = '';
    this.error = undefined;
    this.queued = false;
    this.recipe = new Recipe({ id: this.recipe.id, image: this.recipe.image?.toJS() });
  }

  @action
  generate(): Promise<string> {
    if (!this.responseService?.generating && this.recipeName.trim()) {
      this.responseService = new GptResponseService([
        {
          role: 'system',
          content: RECIPE_SYSTEM_MESSAGE,
        },
        {
          role: 'user',
          content: this.recipeName.trim(),
        },
      ]);
      this.error = undefined;
      this.queued = false;
      return this.responseService
        .generate((message) => runInAction(() => (this.tmpMessage = message)))
        .then((res) => runInAction(() => (this.jsonText = res)))
        .catch((err) => {
          console.error(err);
          runInAction(() => (this.error = err));
          return err;
        });
    }

    return Promise.reject('Already generating');
  }

  @computed
  get recipeIsOk(): boolean {
    return this.recipe.isValid;
  }

  @computed
  get status(): string {
    if (this.generating) {
      return 'generating';
    }
    if (this.queued) {
      return 'queued';
    }
    if (this.creating) {
      return 'creating';
    }
    if (this.error) {
      return 'error';
    }
    if (this.requiresAttention) {
      return 'requiresAttention';
    }
    if (this.saved) {
      return 'saved';
    }
    if (this.done) {
      return 'done';
    }

    return 'idle';
  }

  @computed
  get generating(): boolean {
    return this.responseService?.generating ?? false;
  }

  static create(recipeName: string, recipe?: Recipe): Promise<RecipeGenerator> {
    if (recipe) {
      return Promise.resolve(new RecipeGenerator(recipeName, recipe));
    }

    return Sleep.sleepRandom(10, 250).then(() => new RecipeGenerator(recipeName));
  }
}
