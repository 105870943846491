/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from 'tss-react/mui';
import { NativeFeedSideDrawer } from './NativeFeedSideDrawer/NativeFeedSideDrawer';
import { NativeFeedStructureView } from './NativeFeedStructureView/NativeFeedStructureView';
import { NativeFeedPhoneBezel } from './NativeFeedPreview/NativeFeedPhoneBezel';
import { NativeFeedHeader } from './NativeFeedHeader';
import {
  NativeFeedSideDrawerComponentItemType,
  nativeFeedSideDrawerComponentItems,
} from './NativeFeedSideDrawer/NativeFeedSideDrawerComponents/NativeFeedSideDrawerComponents';
import { ThemeConfig } from '../../../Config/Theme';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import FeedEntryViewStore from '../FeedEntryViewStore';
import { Col, Row } from 'reactstrap';
import { Form, Select } from 'antd';
import { availableLanguages } from '../../../Utils/availableLanguages';

export type NativeFeedEditorProps = {
  entry: ArticleEntry;
};

export const NativeFeedEditor: React.FC<NativeFeedEditorProps> = observer(({ entry }) => {
  const { classes } = useStyles();
  const [store] = useState(() => new FeedEntryViewStore());
  const [lang, setLang] = useState<string>('de');

  /**
   * Reorders items in the same list.
   */
  const reorder = React.useCallback(
    (startIndex, endIndex) => {
      const [removed] = entry.content.splice(startIndex, 1);
      entry.content.splice(endIndex, 0, removed);
    },
    [entry],
  );

  /**
   * Creates a component based on the dragged item.
   */
  const createComponent = React.useCallback(
    (source: Array<NativeFeedSideDrawerComponentItemType>, droppableSource, droppableDestination) => {
      const item: NativeFeedSideDrawerComponentItemType = source[droppableSource.index];
      store.setSelectedComponentToAdd(undefined, droppableDestination.index);
      store.addComponent(entry, item.type);
    },
    [store, entry],
  );

  const handleDragEnd = React.useCallback(
    (result) => {
      const { source, destination } = result;
      /** dropped outside the list **/
      if (!destination) {
        return;
      }
      switch (source.droppableId) {
        /** the source and the destination are the same - an item
         * is being dragged through the same list
         */
        case destination.droppableId:
          reorder(source.index, destination.index);
          break;
        /** an item is dragged from the "nativeFeedSideDrawerComponents"
         * to the "nativeFeedStructureView"
         */
        case 'nativeFeedSideDrawerComponents':
          createComponent(nativeFeedSideDrawerComponentItems, source, destination);
          break;
      }
    },
    [createComponent, reorder],
  );

  const handleChangeLanguage = React.useCallback((e) => {
    setLang(e);
  }, []);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Row>
        <Col>
          <Form.Item label="Language">
            <Select value={lang} onChange={handleChangeLanguage}>
              {availableLanguages.map((lang) => (
                <Select.Option key={lang.value} value={lang.value}>
                  {lang.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className={classes.container}>
            <NativeFeedPhoneBezel />
            <div className={classes.innerContainer}>
              <NativeFeedHeader entry={entry} lang={lang} />
              <NativeFeedStructureView entry={entry} store={store} lang={lang} />
            </div>
          </div>
        </Col>
        <Col>
          <NativeFeedSideDrawer entry={entry} store={store} />
        </Col>
      </Row>
    </DragDropContext>
  );
});

const useStyles = makeStyles()((theme) => ({
  gridContainer: {
    paddingTop: 32,
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    width: window.innerHeight * 0.4,
    height: window.innerHeight * 0.8,
    backgroundColor: ThemeConfig.Colors.white,
    borderRadius: 8,
    border: `4px solid ${ThemeConfig.Colors.charcoalGrey}`,
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  innerContainer: {
    height: window.innerHeight * 0.8,
    overflow: 'auto',
    backgroundColor: ThemeConfig.Colors.white,
  },
}));
