/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, DatePicker, Form, Input, Select, Tag, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';
import { InstructorEdit } from './InstructorEdit';
import { Instructor } from '../../../../../Model/Explore/Instructor';
import { Timezones } from '../../../../../Utils/Timezones';
import { availableLanguages } from '../../../TextToSpeech/CoachTtsEditModal/CoachTtsEditModal';
import { WebinarEntry } from '../../../../../Model/Explore/WebinarEntry';
import { ExploreEntryModalMediaField } from '../../ExploreEntryModalMediaField';
import { Media } from '../../../../../Model/Media/Media';
import { useState } from 'react';
import { MediaEditModal } from '../../../../MetaData/Media/MediaEditModal/MediaEditModal';

export type WebinarEntryContentProps = {
  entry: WebinarEntry;
};

export const WebinarEntryContent: React.FC<WebinarEntryContentProps> = observer(({ entry }) => {
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

  const handleChangeLanguage = React.useCallback(
    (lang) => {
      runInAction(() => (entry.language = lang));
    },
    [entry],
  );

  const handleChangeStartDate = React.useCallback(
    (date) =>
      runInAction(() => (entry.startDate = date?.format('YYYY-MM-DD') ?? dayjs().add(1, 'day').format('YYYY-MM-DD'))),
    [entry],
  );

  const handleChangeStartTime = React.useCallback(
    (date, dateString) => runInAction(() => (entry.startTime = date.format('HH:mm') ?? entry.startTime)),
    [entry],
  );

  const handleChangeTimezone = React.useCallback(
    (timezone: string) => runInAction(() => (entry.timezone = timezone)),
    [entry],
  );

  const handleChangeDuration = React.useCallback(
    (date, dateString) => {
      if (date) {
        runInAction(() => (entry.duration = dayjs.duration(dayjs(date.toDate()).diff(dayjs(0))).toISOString()));
      }
    },
    [entry],
  );

  const handleVideoUploaded = React.useCallback(
    (media: Media) => {
      runInAction(() => entry.previewVideos.push(media));
    },
    [entry],
  );

  const handleRemoveVideo = React.useCallback(
    (media: Media) => {
      runInAction(() => (entry.previewVideos = entry.previewVideos.filter((v) => v.id !== media.id)));
    },
    [entry],
  );

  const handleClickMedia = React.useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

  const handleMediaSave = React.useCallback(
    (media: Media) => {
      runInAction(() => {
        if (entry.previewVideos.some((v) => v.id === media.id)) {
          const index = entry.previewVideos.findIndex((v) => v.id === media.id);
          if (index !== -1) {
            entry.previewVideos[index] = media;
          }
        }
      });

      entry.save();
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col xs={3}>
          <Form.Item label="Webinar Date" extra="From when on can people access it">
            <DatePicker value={dayjs(entry.startDateTime?.toDate())} onChange={handleChangeStartDate} size="large" />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Webinar Time" extra={entry.localStartDateTime?.format('DD.MM.YYYY HH:mm')}>
            <TimePicker
              value={dayjs(entry.startTime, 'HH:mm')}
              showSecond={false}
              format="HH:mm"
              onChange={handleChangeStartTime}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Webinar Duration">
            <TimePicker
              value={dayjs.utc(entry.durationDayjs.toDate())}
              showSecond={false}
              format="HH:mm"
              onChange={handleChangeDuration}
              minuteStep={5}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item label="Timezone">
            <Select value={entry.timezone} onChange={handleChangeTimezone}>
              {Timezones.map((v) => (
                <Select.Option key={v} value={v}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Preview Videos"
            extra="An optional video that is shown before the Webinar goes live e.g. is available"
          >
            <div style={{ display: 'flex' }}>
              {entry.previewVideos.concat([undefined] as any).map((video) => (
                <div key={video?.id} style={{ marginLeft: 8 }}>
                  <ExploreEntryModalMediaField
                    onRemove={handleRemoveVideo}
                    onUploaded={handleVideoUploaded}
                    onClick={handleClickMedia}
                    media={video}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Hosts">
            <Row>
              {entry.hosts.map((host, index) => (
                <Col md={12} key={index.toString()}>
                  <InstructorEdit
                    key={index}
                    index={index}
                    instructor={host}
                    onRemove={() => runInAction(() => entry.hosts.splice(index, 1))}
                  />
                </Col>
              ))}
            </Row>
            <Button type="primary" onClick={() => runInAction(() => entry.hosts.push(new Instructor()))}>
              Add Host
            </Button>
          </Form.Item>
        </Col>

      </Row>
      {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
    </React.Fragment>
  );
});
