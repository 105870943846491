/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Media } from '../../../../../../Model/Media/Media';
import { MediaOverlayContentItem } from './MediaOverlayContentItem';

export type MediaOverlayContentProps = {
  media: Media;
  store: {
    currentSeek: number;
  };
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 64px;
  right: 0;
`;

const LeftContainer = styled.div`
  width: 320px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-right: 3px solid white;
  padding: 16px;
  overflow-y: scroll;
`;

export const MediaOverlayContent: React.FC<MediaOverlayContentProps> = observer(({ media, store }) => {
  return (
    <Container>
      <LeftContainer>
        {media.overlay.map((overlay, index) => (
          <MediaOverlayContentItem key={`${media.id}_${index}`} overlay={overlay} store={store} />
        ))}
      </LeftContainer>
    </Container>
  );
});
