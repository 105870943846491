import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { GraphSortingStage } from '../../../../../../../Model/Coach/Stages/GraphSortingStage';

export class GraphSortNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: GraphSortingStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'graphSort' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
