/**
 * Created by neo on 02.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ValueEntry } from './ExerciseVariationFilterSingleSelection';
import { Form, Select } from 'antd';

const Values = [
  { value: 'true', label: 'yes' },
  { value: 'false', label: 'no' },
];

export type ExerciseVariationFilterSpineFlexingProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterSpineFlexing: React.FC<ExerciseVariationFilterSpineFlexingProps> = observer(
  ({ filter }: ExerciseVariationFilterSpineFlexingProps) => {
    const handleChange = React.useCallback(
      (value) => {
        if (value === 'true') {
          filter.spineFlexing = true;
        } else if (value === 'false') {
          filter.spineFlexing = false;
        } else {
          filter.spineFlexing = undefined;
        }
      },
      [filter],
    );

    const value = React.useMemo(() => {
      if (filter.spineFlexing !== undefined) {
        return filter.spineFlexing ? 'true' : 'false';
      }
      return undefined;
    }, [filter.spineFlexing]);

    return (
      <Form.Item label="Spine Flexing">
        <Select value={value} onChange={handleChange}>
          <Select.Option value="">{''}</Select.Option>
          {Values.map((v: ValueEntry) => (
            <Select.Option key={v.value} value={v.value}>
              {v.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  },
);
