/**
 * Created by neo on 07.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import styled from '@emotion/styled';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../Model/Explore/ExploreEntryBuilder';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router';
import { Gym } from '../../../Model/Gym/Gym';

const nonClickableCategories = ['recipe_favorites', 'recipe_recommendations', 'recipe_all', 'single_exercises'];

const Container = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 18px;
  padding: 16px 0;
`;

const PreviewContainer = styled.div`
  display: flex;
`;

const EntryContainer = styled.div<{ backgroundImage?: string }>`
  width: 120px;
  height: 120px;
  ${({ backgroundImage }) => (backgroundImage ? `background-image: url('${backgroundImage}');` : '')}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
`;

export type ExploreCategoryRendererPreviewProps = {
  category: ExploreCategory;
  gym?: Gym;
};

export const ExploreCategoryRendererPreview: React.FC<ExploreCategoryRendererPreviewProps> = observer(
  ({ category, gym }) => {
    const history = useNavigate();
    const location = useLocation();
    const gymId = new URLSearchParams(location.search).get('gymId') || undefined;

    const [entries, setEntries] = useState<ExploreEntry[]>([]);

    const showLink = !nonClickableCategories.some((c) => category.type === c);

    useEffect(() => {
      ExploreEntryBuilder.find({ categories: [category.identifier], size: 4 }).then((result) => setEntries(result));
    }, [category]);

    const handleClickEdit = React.useCallback(() => {
      history(`${category.id}/edit?gymId=${gymId ?? ''}`);
    }, [history, category, gymId]);

    return (
      <Container>
        <Title>
          <Row>
            <Col>
              {showLink ? (
                <Link to={`/coach/explore/${category.id}?gymId=${gymId ?? ''}`}>{category.defaultName}</Link>
              ) : (
                category.defaultName
              )}
            </Col>
            {gymId === category.gymId && (
              <Col xs="auto">
                <Button type="link" onClick={handleClickEdit}>
                  Edit
                </Button>
              </Col>
            )}
          </Row>
        </Title>
        <Description>{category.defaultDescription}</Description>
        <PreviewContainer>
          {entries.map((entry) => (
            <EntryContainer key={entry.id} backgroundImage={entry.image?.smallest}></EntryContainer>
          ))}
        </PreviewContainer>
      </Container>
    );
  },
);
