import { action, observable, computed } from 'mobx';
import * as React from 'react';
import NativeFeedComponent, { NativeFeedComponentType } from '../../Model/Feed/NativeFeedComponent';
import { ArticleEntry } from '../../Model/Explore/ArticleEntry';

export default class FeedEntryViewStore {
  @observable selectedComponentToEdit?: NativeFeedComponent;
  @observable selectedComponentToAdd?: NativeFeedComponent;
  @observable selectedComponentToAddIndex?: number;
  @observable showDefaultSideDrawer: boolean = true;
  @observable preview = false;

  @action
  setSelectedComponentToEdit(component: NativeFeedComponent) {
    this.selectedComponentToEdit = component;
    this.showDefaultSideDrawer = false;
  }

  @action
  setSelectedComponentToAdd(component?: NativeFeedComponent, index?: number) {
    this.selectedComponentToAdd = component;
    this.selectedComponentToAddIndex = index;
  }

  @action
  setDefaultSideDrawerMode() {
    this.showDefaultSideDrawer = true;
  }

  @action
  addComponent(entry: ArticleEntry, type: NativeFeedComponentType) {
    const newComponent = this.createComponent(type);
    console.log('add', this.selectedComponentToAdd, this.selectedComponentToAddIndex);
    if (this.selectedComponentToAdd) {
      if (
        this.selectedComponentToAddIndex &&
        this.selectedComponentToAddIndex > 0 &&
        this.selectedComponentToAddIndex < this.selectedComponentToAdd.children.length - 1
      ) {
        this.selectedComponentToAdd.children.splice(this.selectedComponentToAddIndex, 0, newComponent);
      } else if (this.selectedComponentToAddIndex === 0) {
        this.selectedComponentToAdd.children.unshift(newComponent);
      } else {
        this.selectedComponentToAdd.children.push(newComponent);
      }
    } else {
      if (
        this.selectedComponentToAddIndex &&
        this.selectedComponentToAddIndex > 0 &&
        this.selectedComponentToAddIndex < entry.content.length - 1
      ) {
        entry.content.splice(this.selectedComponentToAddIndex, 0, newComponent);
      } else if (this.selectedComponentToAddIndex === 0) {
        entry.content.unshift(newComponent);
      } else {
        entry.content.push(newComponent);
      }
    }
    this.selectedComponentToAddIndex = undefined;
    this.selectedComponentToAdd = undefined;
    this.setSelectedComponentToEdit(newComponent);
  }

  private createComponent(type: NativeFeedComponentType): NativeFeedComponent {
    switch (type) {
      case 'Heading':
      case 'ItalicHeading':
      case 'Paragraph':
      case 'ItalicParagraph':
      case 'Caption':
      case 'Summary':
      case 'Button':
      case 'SecondaryButton':
        return new NativeFeedComponent({
          type,
          text: [{ lang: 'de', value: '' }],
        });
      case 'InnerBoldText':
        return new NativeFeedComponent({
          type,
          text: [{ lang: 'de', value: 'Placeholder before ' }],
          children: [
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'bold text ' }],
            },
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'and placeholder after' }],
            },
          ],
        });
      case 'InnerLinkText':
        return new NativeFeedComponent({
          type,
          text: [{ lang: 'de', value: 'Placeholder before ' }],
          children: [
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'link text ' }],
            },
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'and placeholder after' }],
            },
          ],
        });
      case 'BulletPoint':
        return new NativeFeedComponent({
          type,
          children: [
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'bullet point 1' }],
            },
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'bullet point 2' }],
            },
          ],
        });
      case 'NumberPoint':
        return new NativeFeedComponent({
          type,
          children: [
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'number point 1' }],
            },
            {
              type: 'Paragraph',
              text: [{ lang: 'de', value: 'number point 2' }],
            },
          ],
        });
      default:
        return new NativeFeedComponent({ type });
    }
  }

  /**
   * if there is a parent component (reactElement is deeply nested)
   * delete it from its children
   * @param componentKey
   * @param entry
   */
  @action
  deleteComponent(entry: ArticleEntry, componentKey: string) {
    const index = entry.content.findIndex((c) => c.key === componentKey);
    if (index !== -1) {
      entry.content.splice(index, 1);
    }
  }

  @computed
  get availableStyles(): Array<any> {
    return [];
  }
}
