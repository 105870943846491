/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { Media } from '../../../../../../Model/Media/Media';

export type MediaLoopEditModalVideoProps = {
  media: Media;
  store: {
    currentSeek;
  };
} & Partial<ReactPlayerProps>;

export const MediaLoopEditModalVideo: React.FC<MediaLoopEditModalVideoProps> = observer(
  ({ media, store, ...props }: MediaLoopEditModalVideoProps) => {
    return (
      <div style={{ position: 'relative' }}>
        <ReactPlayer
          url={media.url}
          width="100%"
          height={380}
          controls={true}
          loop={true}
          progressInterval={16}
          {...props}
        />
      </div>
    );
  },
);
