import { DefaultStageNodeModel } from '../../Default/DefaultStageNodeModel';
import { OrStage } from '../../../../../../../../Model/Coach/Stages/Logical/OrStage';

export class OrNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: OrStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'logical:or' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
