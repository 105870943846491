/**
 * Created by neo on 18.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { Form, Input } from 'antd';

export type ExploreEntryModalVersionEditorProps = {
  entry: ExploreEntry;
};

export const ExploreEntryModalVersionEditor: React.FC<ExploreEntryModalVersionEditorProps> = observer(({ entry }) => {
  const handleChangeMinVersionIos = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (entry.version.minVersionIos = value ? value : undefined));
    },
    [entry],
  );

  const handleChangeMaxVersionIos = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (entry.version.maxVersionIos = value ? value : undefined));
    },
    [entry],
  );

  const handleChangeMinVersionAndroid = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (entry.version.minVersionAndroid = value ? value : undefined));
    },
    [entry],
  );

  const handleChangeMaxVersionAndroid = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (entry.version.maxVersionAndroid = value ? value : undefined));
    },
    [entry],
  );

  return (
    <Row>
      <Col>
        <Form.Item label="iOS min. Version" extra="The min. version required of the KINASTIC app to see this content">
          <Input value={entry.version.minVersionIos} onChange={handleChangeMinVersionIos} disabled={true} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Android min. Version" extra="The min. version required of the KINASTIC app to see this content">
          <Input value={entry.version.minVersionAndroid} onChange={handleChangeMinVersionAndroid} disabled={true} />
        </Form.Item>
      </Col>
    </Row>
  );
});
