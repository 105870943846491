/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { BodyValueCard } from './BodyValueCard';
import { PersonCard } from './PersonCard';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../../Store/useRootStore';

export type AthleteBasicTabProps = {
  athlete: Athlete;
};

export const AthleteBasicTab: React.FC<AthleteBasicTabProps> = observer(({ athlete }) => {
  const { authentication } = useRootStore();

  return (
    <React.Fragment>
      <SingleColRow>
        <PersonCard athlete={athlete} />
      </SingleColRow>
      {/*{authentication.isGodAdmin && (*/}
      {/*  <SingleColRow>*/}
      {/*    <BodyValueCard athlete={athlete} />*/}
      {/*  </SingleColRow>*/}
      {/*)}*/}
    </React.Fragment>
  );
});
