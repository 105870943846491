/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { AbstractExercise } from '../../../../Model/Exercise/AbstractExercise';
import { Badge } from 'reactstrap';
import { ComponentColors } from '../../../../Components/ComponentColors';

export type ExerciseTagBadgesProps = {
  exercise: AbstractExercise;
};

export const ExerciseTagBadges: React.FC<ExerciseTagBadgesProps> = observer(({ exercise }: ExerciseTagBadgesProps) => {
  return (
    <React.Fragment>
      {exercise.tags
        .slice()
        .sort()
        .map((tag, index) => (
          <React.Fragment key={tag}>
            <Badge color={ComponentColors[index % ComponentColors.length]} pill={true}>
              {tag}
            </Badge>
            <br />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
});
