import { Media, MediaJson } from '../Media/Media';
import { observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';

/**
 * Created by neo on 19.04.21.
 */

export type InstructorJson = {
  name: string;
  description: LocalizedValueJson[];
  image?: MediaJson;
};

export class Instructor {
  @observable
  name: string;
  @observable
  description: LocalizedValue[] = [];
  @observable
  image?: Media;

  constructor(json?: Partial<InstructorJson>) {
    if (json) {
      this.name = json.name ?? '';
      this.description = json.description?.map((l) => new LocalizedValue(l)) ?? [];
      this.image = json.image ? new Media(json.image) : undefined;
    }
  }

  toJS(): InstructorJson {
    return {
      name: this.name,
      description: this.description.map((l) => l.toJS()),
      image: this.image?.toJS(),
    };
  }
}
