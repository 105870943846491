/**
 *
 * Created by neo on 03.01.17.
 */

import { action, computed, observable, ObservableMap, onBecomeObserved, runInAction, toJS } from 'mobx';
import { User } from '../User';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Media } from '../Media/Media';
import { PageResult } from '../PageResult';
import { Token } from '../../Services/Security/Token';
import { v4 as UUID } from 'uuid';
import { HealthDataPointObject } from '../HealthData/HealthDataPointObject';
import { HealthDataPointObjectType, HealthDataPointObjectTypes } from '../HealthData/HealthDataPointObjectType';
import { HealthDataPointQuery } from '../HealthData/HealthDataPointQuery';
import { HealthDataPointQuantity } from '../HealthData/HealthDataPointQuantity';
import { HealthDataPointCategory } from '../HealthData/HealthDataPointCategory';
import { HealthDataPointCorrelation } from '../HealthData/HealthDataPointCorrelation';
import { HealthDataPointQuantityType } from '../HealthData/HealthDataPointQuantityType';
import { Gender } from '../Person/Gender';
import { EMPTY_ARRAY } from '../../Utils/Constants';
import { Audited } from '../Audited';
import { AthleteDeviceInfo } from './AthleteDeviceInfo';

export class Athlete extends Audited {
  @observable
  nickname: string = '';
  @observable
  firstname?: string;
  @observable
  lastname?: string;
  @observable
  birthYear?: number;
  @observable
  gender: Gender = 'UNKNOWN';
  @observable
  profilePicture: any = {};
  @observable
  bodyValues?: ObservableMap<HealthDataPointObjectType, HealthDataPointObject>;
  @observable
  user: User = new User();
  @observable
  tags: string[] = [];
  @observable
  apps: string[] = [];
  @observable
  lastActivity?: AthleteDeviceInfo = undefined;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.nickname = json.nickname || '';
      this.firstname = json.firstname;
      this.lastname = json.lastname;
      this.birthYear = json.birthYear;
      this.gender = json.gender || 'UNKNOWN';
      this.profilePicture = json.profilePicture ? new Media(json.profilePicture) : undefined;
      this.bodyValues = observable.map(json.bodyValues || {});
      this.user = new User(json.user);
      this.tags = json.tags ?? [];
      this.apps = json.apps ?? [];
    }

    onBecomeObserved(this, 'bodyValues', this.fetchHealthData);
    onBecomeObserved(this, 'lastActivity', this.fetchLastDeviceLog);
  }

  fetchHealthData = () => {
    this.bodyValues = this.bodyValues ?? observable.map({});
    HealthDataPointQuery.recent(this.id).then((result) => {
      runInAction(() => {
        result.forEach((point) => {
          if (
            point instanceof HealthDataPointQuantity ||
            point instanceof HealthDataPointCategory ||
            point instanceof HealthDataPointCorrelation
          ) {
            this.bodyValues?.set(point.type, point);
          }
        });
      });
    });
  };

  toJS(): any {
    return Object.assign(super.toJS(), {
      id: this.id,
      nickname: this.nickname,
      firstname: this.firstname,
      lastname: this.lastname,
      birthYear: this.birthYear,
      gender: this.gender,
      profilePicture: this.profilePicture?.toJS(),
      user: this.user.toJS(),
      tags: this.tags,
    });
  }

  fetchLastDeviceLog = async () => {
    if (!this.lastActivity) {
      AthleteDeviceInfo.last({ athleteId: this.id }).then((res) => runInAction(() => (this.lastActivity = res)));
    }
  };

  @action
  setBodyValue(dataPoint: HealthDataPointObject) {
    if (this.bodyValues) {
      if (dataPoint instanceof HealthDataPointQuantity || dataPoint instanceof HealthDataPointCorrelation) {
        this.bodyValues.set(dataPoint.type, dataPoint);
      }
    }
  }

  remove() {
    return HttpBackend.delete(`/athlete/admin/${this.id}`);
  }

  save(): Promise<Athlete> {
    return HttpBackend.post('/athlete/admin', this.toJS()).then(() => this);
  }

  async fetchOAuthToken(): Promise<Token> {
    if (this.user.id) {
      const res = await HttpBackend.post(`/uaa/auth`, {
        clientId: 'mfoeraer143k07j113e41tgd5n0t7p5jlrcddh8ciohg093hnfu',
        userId: this.user.id,
      });
      if (res) {
        return new Token(res);
      }
    }
    throw new Error('cannot fetch token');
  }

  @computed
  get height(): HealthDataPointQuantity | undefined {
    return this.bodyValues?.get(HealthDataPointQuantityType.height) as HealthDataPointQuantity;
  }

  @computed
  get weight(): HealthDataPointQuantity | undefined {
    return this.bodyValues?.get(HealthDataPointQuantityType.bodyMass) as HealthDataPointQuantity;
  }

  @computed
  get BMI(): number {
    return (this.bodyValues?.get(HealthDataPointQuantityType.bodyMassIndex) as HealthDataPointQuantity)?.value ?? 0;
  }

  @computed
  get bmr(): number {
    return (this.bodyValues?.get(HealthDataPointQuantityType.bmr) as HealthDataPointQuantity)?.value ?? 1689.22;
  }

  @computed
  get existingBodyValueTypes(): HealthDataPointObjectType[] {
    return this.bodyValues ? Array.from(this.bodyValues.keys()) : [];
  }

  @computed
  get missingBodyValueTypes(): HealthDataPointObjectType[] {
    return HealthDataPointObjectTypes.filter((type) => !this.existingBodyValueTypes.some((t0) => t0 === type));
  }

  @computed
  get initials(): string {
    return (this.firstname?.trim().split(' ') ?? EMPTY_ARRAY)
      .map((str) => str.charAt(0))
      .join('')
      .toUpperCase();
  }

  @computed
  get fullName(): string {
    return this.firstname?.trim() ?? '';
  }

  static async get(athleteId: string): Promise<Athlete | undefined> {
    const data = await HttpBackend.get(`/athlete/admin/${athleteId}`);
    if (data) {
      return new Athlete(data);
    }
    return undefined;
  }

  static find(params: any = { page: 0, sort: 'firstname,ASC' }): Promise<Athlete[]> {
    return HttpBackend.get(`/athlete/admin`, params).then((data) => data.map((a) => new Athlete(a)));
  }

  static count(params: any = { page: 0, sort: 'firstname,ASC' }): Promise<number> {
    return HttpBackend.get(`/athlete/admin/count`, params);
  }

  static async findByName(
    name?: string,
    pageable: any = { page: 0, sort: 'firstname,ASC' },
  ): Promise<PageResult<Athlete>> {
    const data = await HttpBackend.get(`/athlete/admin`, { name, ...pageable });
    if (data) {
      data.content = data.content.map((a) => new Athlete(a));
      return new PageResult(data);
    }
    return new PageResult();
  }

  static async findByUser(userId: string): Promise<Athlete | undefined> {
    const data = await HttpBackend.get(`/athlete/admin/findByUserId`, { userId });
    if (data) {
      return new Athlete(data);
    }
    return undefined;
  }

  static async findByNickname(nickname: string): Promise<Athlete | undefined> {
    const data = await HttpBackend.get(`/athlete/admin/findByNickname`, { nickname });
    if (data) {
      return new Athlete(data);
    }
    return undefined;
  }
}
