/**
 * Created by neo on 14.04.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Media } from '../../../../../Model/Media/Media';
import ReactPlayer from 'react-player';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Button } from 'antd';

export type MediaEditVideoSizesTabProps = {
  media: Media;
};

export const MediaEditVideoSizesTab: React.FC<MediaEditVideoSizesTabProps> = observer(({ media }) => {
  const handleEnqueue = React.useCallback(() => {
    media.enqueueConvert();
  }, [media]);

  return (
    <React.Fragment>
      {media.sizes.map((size) => (
        <SingleColRow key={size.size}>
          <h6>{size.size}</h6>
          <ReactPlayer url={size.url} width="100%" height={240} controls={true} loop={true} progressInterval={16} />
        </SingleColRow>
      ))}
      <Button block={true} size="large" onClick={handleEnqueue}>
        Enqueue-Conversion
      </Button>
    </React.Fragment>
  );
});
