/**
 * Created by katarinababic on 28.4.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartColors } from '../ChartColors';
import { Form } from 'antd';

export type ActivitiesLoggedChartProps = {
  data: AnalyticsRow[];
  rows: string[];
};

export const ActivitiesLoggedChart: React.FC<ActivitiesLoggedChartProps> = observer(({ data, rows }) => {
  const [disabledFields, setDisabledFields] = useState<{ [key: string]: boolean }>({});

  const handleLegendClick = React.useCallback((data) => {
    const dataKey = typeof data.dataKey === 'string' ? data.dataKey : 'avg_activities_per_user';
    setDisabledFields((existing) => Object.assign({}, existing, { [dataKey]: !existing[dataKey] }));
  }, []);

  return (
    <Form.Item label="No of logged activities">
      <ResponsiveContainer width="100%" height={600}>
        <BarChart height={600} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="event_date" />
          <YAxis />
          <Tooltip />
          <Legend onClick={handleLegendClick} />
          {rows.map((row, index) => (
            <Bar
              key={row}
              type="monotone"
              dataKey={row}
              fill={ChartColors[index % ChartColors.length]}
              stackId="a"
              name={`# Unique users ${row}`}
              hide={disabledFields[row]}
            />
          ))}
          {rows.map((row, index) => (
            <Bar
              key={`${row}_select`}
              type="monotone"
              dataKey={`${row}_select`}
              fill={ChartColors[index % ChartColors.length]}
              stackId="b"
              name={`# logged ${row} `}
              hide={disabledFields[`${row}_select`]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Form.Item>
  );
});
