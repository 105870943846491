/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps, StyledRow } from '../Default/DefaultStageNodeWidget';
import { StyledInput } from '../StyledInput';

export type DuplicateNodeWidgetProps = {} & StageNodeWidgetProps;

export const DuplicateNodeWidget: React.FC<DuplicateNodeWidgetProps> = observer(
  ({ node, engine, size }: DuplicateNodeWidgetProps) => {
    const handleChange = React.useCallback(({ target: { value } }: any) => (node.stage.config.count = value), [node]);

    return (
      <DefaultStageNodeWidget
        engine={engine}
        node={node}
        size={size}
        disableEdit={true}
        content={
          <StyledRow>
            <StyledInput node={node} value={node.stage.config.count} onChange={handleChange} />
          </StyledRow>
        }
      />
    );
  },
);
