/**
 * Created by neo on 16.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Col, Row } from 'reactstrap';
import { Map, MapProps, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Button, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { RouteChallengeLocation } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeLocation';
import { RouteChallengeLocationModal } from './RouteChallengeLocationModal';
import { RouteChallengeLocationEntry } from './RouteChallengeLocationEntry';
import { runInAction } from 'mobx';
import { RouteChallengeMapEditorMapContent } from './RouteChallengeMapEditorMapContent';
import { LocalizedValue } from '../../../../../Model/LocalizedValue';
import { RouteChallengeTeamPositions } from './RouteChallengeTeamPositions';
import { SingleColRow } from '../../../../../Components/SingleColRow';

export type RouteChallengeMapEditorProps = Pick<MapProps, 'onBoundsChanged'> & {
  challenge: RouteChallenge;
};

const KINASTIC_HQ_POSITION = { lat: 47.3732061, lng: 8.5283241 };

export const RouteChallengeMapEditor: React.FC<RouteChallengeMapEditorProps> = observer(
  ({ challenge, onBoundsChanged }) => {
    const mapId = `challenge-map-${challenge.id}`;

    const map = useMap();

    const [locationToEdit, setLocationToEdit] = useState<RouteChallengeLocation | undefined>();
    const [selectedLocation, setSelectedLocation] = useState<RouteChallengeLocation | undefined>();

    const [directionsService, setDirectionServices] = useState<google.maps.DirectionsService | undefined>();
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | undefined>();
    const [geocoderService, setGeocoderService] = useState<google.maps.Geocoder | undefined>();
    const [query, setQuery] = useState('');

    const directionsLibrary = useMapsLibrary('routes');
    const geocoderLibrary = useMapsLibrary('geocoding');
    const placesLibrary = useMapsLibrary('places');

    useEffect(() => {
      console.log('directionsLibrary', directionsLibrary);
      if (directionsLibrary) {
        setDirectionServices(new google.maps.DirectionsService());
      }
    }, [directionsLibrary]);

    useEffect(() => {
      if (placesLibrary) {
        setPlacesService(new placesLibrary.PlacesService(document.createElement('div')));
      }
    }, [placesLibrary]);

    useEffect(() => {
      if (geocoderLibrary) {
        setGeocoderService(new geocoderLibrary.Geocoder());
      }
    }, [geocoderLibrary]);

    const mapCenter = useMemo(() => {
      if (challenge) {
        return challenge.mapCenter
          ? { lat: challenge.mapCenter.latitude, lng: challenge.mapCenter.longitude }
          : KINASTIC_HQ_POSITION;
      }
      return undefined;
    }, [challenge]);

    console.log('directionsService', directionsService);
    console.log('geocoderService', geocoderService);

    const handleAddLocation = React.useCallback(() => {
      const selectedLocationIndex = challenge.locations.findIndex((l) => l.id === selectedLocation?.id);
      const newLocationIndex = selectedLocationIndex !== -1 ? selectedLocationIndex + 1 : undefined;
      const previousLocation = newLocationIndex
        ? challenge.locations[newLocationIndex - 1]
        : challenge.locations[challenge.locations.length - 1];
      const pointsRequired = Math.ceil((previousLocation?.pointsRequired ?? 0) * 1.1);
      const location = challenge.createLocation(newLocationIndex, { pointsRequired });

      console.log('previousLocation', previousLocation, newLocationIndex, selectedLocationIndex);

      setSelectedLocation(location);
    }, [challenge, selectedLocation]);

    const updateLocationName = React.useCallback(
      (location: RouteChallengeLocation, latLng: google.maps.LatLng) => {
        geocoderService
          ?.geocode({
            location: latLng,
          })
          .then((results) => {
            const locality = results.results.find((r) => r.types.includes('locality') || r.types.includes('political'));
            const newName = locality?.address_components.find((a) => a.types.includes('locality'))?.long_name;
            console.log('geocode', locality);
            if (locality && location.name.length <= 1 && newName) {
              runInAction(() => (location.name = [new LocalizedValue({ lang: 'en', value: newName })]));
            }
          });
      },
      [geocoderService],
    );

    const changeLocationPosition = React.useCallback(
      (location: RouteChallengeLocation, latLng: google.maps.LatLng) => {
        const locationIndex = challenge.locations.findIndex((l) => l.id === location.id);

        updateLocationName(location, latLng);

        if (locationIndex > 0) {
          const previousLocation = challenge.locations[locationIndex - 1];
          if (previousLocation.position.xorLat && previousLocation.position.yorLng) {
            console.log('calculate route to previous location', previousLocation);
            directionsService
              ?.route({
                origin: new google.maps.LatLng({
                  lat: previousLocation.position.xorLat,
                  lng: previousLocation.position.yorLng,
                }),
                destination: latLng,
                travelMode: google.maps.TravelMode.WALKING,
              })
              .then((res) => {
                console.log('route to previous', res);
              })
              .catch((err) => console.error('route error', err));
          }
        }

        if (locationIndex < challenge.locations.length - 1) {
          const nextLocation = challenge.locations[locationIndex + 1];
          if (nextLocation.position.xorLat && nextLocation.position.yorLng) {
            console.log('calculate route to next location', nextLocation);
            directionsService
              ?.route({
                origin: latLng,
                destination: new google.maps.LatLng({
                  lat: nextLocation.position.xorLat,
                  lng: nextLocation.position.yorLng,
                }),
                travelMode: google.maps.TravelMode.WALKING,
              })
              .then((res) => {
                console.log('route to next', res);
              })
              .catch((err) => console.error('route error', err));
          }
        }

        runInAction(() => {
          location.position.xorLat = latLng.lat();
          location.position.yorLng = latLng.lng();
        });
      },
      [challenge, directionsService, updateLocationName],
    );

    const handleMapClick = React.useCallback(
      ({ detail, map }) => {
        console.log('map click', detail, map);
        if (selectedLocation && !selectedLocation.position.xorLat && !selectedLocation.position.yorLng) {
          changeLocationPosition(selectedLocation, new google.maps.LatLng(detail.latLng));
        }
      },
      [changeLocationPosition, selectedLocation],
    );

    const handleSelectLocation = React.useCallback(
      (location) => {
        if (location.position.xorLat && location.position.yorLng && selectedLocation?.id !== location.id) {
          map?.panTo(new google.maps.LatLng(location.position.xorLat, location.position.yorLng));
        }

        setSelectedLocation((prev) => (prev?.id === location.id ? undefined : location));
      },
      [map, selectedLocation],
    );

    const handleSearch = React.useCallback(() => {
      console.log('search', query);
      if (query) {
        placesService?.textSearch(
          {
            query,
          },
          (results, status) => {
            if (results) {
              const result = results[0];
              console.log('search results', results, status);
              if (result) {
                const selectedLocationIndex = challenge.locations.findIndex((l) => l.id === selectedLocation?.id);
                const newLocationIndex = selectedLocationIndex !== -1 ? selectedLocationIndex + 1 : undefined;
                const previousLocation = newLocationIndex
                  ? challenge.locations[newLocationIndex - 1]
                  : challenge.locations[challenge.locations.length - 1];
                const pointsRequired = Math.ceil((previousLocation?.pointsRequired ?? 0) * 1.1);

                const location = challenge.createLocation(newLocationIndex, {
                  pointsRequired,
                  name: [{ lang: 'en', value: query }],
                  position: {
                    type: 'coordinates',
                    xorLat: result.geometry?.location?.lat() ?? 0,
                    yorLng: result.geometry?.location?.lng() ?? 0,
                  },
                });

                if (previousLocation.position.xorLat && previousLocation.position.yorLng && result.geometry?.location) {
                  console.log('calculate route to previous location', previousLocation);
                  directionsService
                    ?.route({
                      origin: new google.maps.LatLng({
                        lat: previousLocation.position.xorLat,
                        lng: previousLocation.position.yorLng,
                      }),
                      destination: result.geometry?.location,
                      travelMode: google.maps.TravelMode.WALKING,
                    })
                    .then((res) => {
                      const route = res.routes[0];
                      console.log('result destination', res);
                      if (route) {
                        console.log('route to previous', res);
                        runInAction(() => (previousLocation.googleMapsRouteToNextLocation = route.overview_polyline));
                      }
                      console.log('route to previous', res);
                    })
                    .catch((err) => console.error('route error', err));
                }

                setQuery('');
              }
            }
          },
        );
      }
    }, [challenge, placesService, query, selectedLocation]);

    return (
      <Row>
        <Col xs={12}>
          <h3>Location Editor</h3>
        </Col>
        <Col xs={12} md={8} lg={9}>
          <SingleColRow>
            <h4>Map</h4>
          </SingleColRow>
          <Row>
            <Col>
              <Input value={query} onChange={(e) => setQuery(e.target.value)} onSubmit={handleSearch} />
            </Col>
            <Col xs="auto">
              <Button type="primary" block onClick={handleSearch}>
                Search & Add
              </Button>
            </Col>
          </Row>

          {mapCenter && (
            <Map
              mapId={mapId}
              defaultCenter={mapCenter}
              defaultZoom={7}
              style={{ height: 600 }}
              onClick={handleMapClick}
              onBoundsChanged={onBoundsChanged}
            >
              {!selectedLocation && <RouteChallengeTeamPositions challenge={challenge} />}
              <RouteChallengeMapEditorMapContent
                challenge={challenge}
                selectedLocation={selectedLocation}
                onSelect={(location) =>
                  setSelectedLocation((prev) => (prev?.id === location.id ? undefined : location))
                }
              />
            </Map>
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          <h4>Locations</h4>
          <Button type="primary" block style={{ marginBottom: 16 }} onClick={handleAddLocation}>
            Add Location
          </Button>
          <div style={{ height: 600, overflowY: 'scroll' }}>
            {challenge.locations.map((location) => (
              <RouteChallengeLocationEntry
                key={location.id}
                challenge={challenge}
                location={location}
                selected={selectedLocation?.id === location.id}
                onClick={handleSelectLocation}
                onEdit={(location) => setLocationToEdit(location)}
              />
            ))}
          </div>
        </Col>
        {locationToEdit && (
          <RouteChallengeLocationModal
            challenge={challenge}
            location={locationToEdit}
            onClose={() => setLocationToEdit(undefined)}
          />
        )}
      </Row>
    );
  },
);

const mapStyle = {
  width: '100%',
  height: '400px',
};
