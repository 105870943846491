/**
 * Created by neo on 29.04.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { BodyPartRegionAdmin } from '../../../Model/BodyPart/BodyPartRegionAdmin';
import { Table } from 'reactstrap';
import { BodyPartTableRow } from './BodyPartTableRow';
import { SortableTableHead, SortableTableHeadColumnEntry } from '../../../Components/Table/SortableTableHead';
import { SortingDirection } from '../../../Components/Table/SortableHeadColumn';
import { runInAction } from 'mobx';

export type BodyPartTableProps = {
  bodyParts: BodyPartRegionAdmin[];
  onRemove?: (bodyPart: BodyPartRegionAdmin) => any;
};

const columns: SortableTableHeadColumnEntry[] = [
  { columnKey: 'image', label: 'Image' },
  { columnKey: 'identifier', label: 'Identifier', sortable: true },
  { columnKey: 'size', label: 'Size', sortable: true },
  { columnKey: 'nameMap.de', label: 'Name', sortable: true },
  { columnKey: 'parents', label: 'Parent' },
  { columnKey: 'latinName', label: 'Latin Name' },
  { columnKey: 'type', label: 'Type' },
  { columnKey: 'exercises', label: 'Exercises' },
  { columnKey: 'action', label: '' },
];

export const BodyPartTable: React.FC<BodyPartTableProps> = observer((props: BodyPartTableProps) => {
  const { bodyParts, onRemove } = props;
  const store = useLocalStore(
    (source: BodyPartTableProps) => ({
      columnKey: 'name',
      direction: 'ASC',
      get data(): BodyPartRegionAdmin[] {
        if (store.columnKey === 'size') {
          console.log('sort', store.columnKey, store.direction);
          return source.bodyParts
            .slice()
            .sort((a, b) => (store.direction === 'DESC' ? b.sortIndex - a.sortIndex : a.sortIndex - b.sortIndex));
        }
        return source.bodyParts;
      },
    }),
    props,
  );

  const handleSort = React.useCallback(
    (columnKey?: string, direction?: SortingDirection) => {
      runInAction(() => {
        store.columnKey = columnKey ?? '';
        store.direction = direction ?? 'ASC';
      });
    },
    [store],
  );

  return (
    <Table size="sm" striped>
      <SortableTableHead className="thead-inverse" columns={columns} onSort={handleSort} />
      <tbody>
        {store.data.map((b: BodyPartRegionAdmin) => (
          <BodyPartTableRow key={b.id} bodyPart={b} onRemove={onRemove} />
        ))}
      </tbody>
    </Table>
  );
});
