/**
 * Created by neo on 21.11.18
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { NavLink as RRLink } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { DropdownHeader } from '../DropdownHeader';
import { useRootStore } from '../../../Store/useRootStore';

export type MetaDataMenuProps = {
  activeKey?: string;
  onToggle: (key?: string) => any;
};

export const MetaDataMenu: React.FC<MetaDataMenuProps> = observer(({ activeKey, onToggle }) => {
  const { authentication } = useRootStore();

  return (
    <DropdownHeader dropdown={'metadata'} activeKey={activeKey} label={'Meta Data'} onToggle={onToggle}>
      <DropdownItem tag={RRLink} to="/metadata/activity" activeClassName="active">
        Activities
      </DropdownItem>
      {authentication.isGodAdmin && (
        <DropdownItem tag={RRLink} to="/metadata/exercise" activeClassName="active">
          Exercises
        </DropdownItem>
      )}
      <DropdownItem tag={RRLink} to="/metadata/bodypart" activeClassName="active">
        Body Parts
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag={RRLink} to="/metadata/equipment/type" activeClassName="active">
        Equipment Types
      </DropdownItem>
      <DropdownItem tag={RRLink} to="/metadata/equipment/typeGroup" activeClassName="active">
        Equipment Type Groups
      </DropdownItem>
      <DropdownItem divider />
      {authentication.isGodAdmin && (
        <React.Fragment>
          <DropdownItem tag={RRLink} to="/metadata/entity-media" activeClassName="active">
            Entity Medias
          </DropdownItem>
          <DropdownItem tag={RRLink} to="/metadata/media" activeClassName="active">
            Medias
          </DropdownItem>
        </React.Fragment>
      )}
      <DropdownItem divider />
      <DropdownItem tag={RRLink} to="/metadata/translations" activeClassName="active">
        Translations
      </DropdownItem>
        <DropdownItem tag={RRLink} to="/metadata/dailyTips" activeClassName="active">
            DailyTips
        </DropdownItem>
    </DropdownHeader>
  );
});
