/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { OAuthScope } from '../../../Model/OAuthScope';
import { Button } from 'antd';

export type OAuthScopeTableRowProps = {
  scope: OAuthScope;
  onSelect?: (scope: OAuthScope) => void;
  onDelete?: (scope: OAuthScope) => void;
};

export const OAuthScopeTableRow: React.FC<OAuthScopeTableRowProps> = observer(({ scope, onDelete, onSelect }) => {
  const handleRemove = () => {
    onDelete && onDelete(scope);
  };

  return (
    <tr>
      <td>
        <Button type="link" href={`/security/oauthScope/${scope.id}`}>
          {scope.id}
        </Button>
      </td>
      <td>{scope.name}</td>
      <td>
        <Button type="primary" danger onClick={handleRemove}>
          {'Delete'}
        </Button>
      </td>
    </tr>
  );
});
