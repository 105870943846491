/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Media } from '../../Model/Media/Media';
import { TagConditionsInput } from '../../View/Coach/SuperMacro/View/TagConditionsInput';
import { ConditionalMedia } from '../../Model/Coach/SuperMacro/ConditionalMedia';

const styles = {
  img: {
    objectFit: 'cover' as const,
    width: '100%',
  },
  container: {},
};

export type ModalMediaViewProps = {
  media: Media;
};

export const ModalMediaView: React.FC<ModalMediaViewProps> = observer(({ media }: ModalMediaViewProps) => {
  return (
    <div style={styles.container}>
      <img style={styles.img} src={media.medium} />
      {media instanceof ConditionalMedia ? <TagConditionsInput tagConditions={media.tagConditions} /> : null}
    </div>
  );
});
