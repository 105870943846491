import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { EqualsNodeWidget } from './EqualsNodeWidget';
import { EqualsNodeModel } from './EqualsNodeModel';
import { EqualsStage } from '../../../../../../../../Model/Coach/Stages/Logical/EqualsStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class EqualsNodeModelFactory extends AbstractReactFactory<EqualsNodeModel, DiagramEngine> {
  constructor() {
    super('logical:eq');
  }

  generateReactWidget(event): JSX.Element {
    return <EqualsNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new EqualsNodeModel(new EqualsStage(new Pipeline()));
  }
}
