/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { useLocalStore, observer } from 'mobx-react';
import { Row, Col, Table } from 'reactstrap';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Pager } from '../../../../../Components/Pager';
import { PageResult } from '../../../../../Model/PageResult';
import { Employee } from '../../../../../Model/Gym/Employee';
import { EmployeeTableRow } from './EmployeeTableRow';
import { EmployeeCreateModal } from './EmployeeCreateModal/EmployeeCreateModal';
import { EmployeeEditModal } from './EmployeeEditModal';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { ModalPromise } from '../../../../../Components/ModalPromise';
import { useEffect, useState } from 'react';
import { Token } from '../../../../../Services/Security/Token';
import { Button, Input } from 'antd';

export type EmployeeTabProps = {
  gym: Gym;
};

export const EmployeeTab: React.FC<EmployeeTabProps> = observer(({ gym }: EmployeeTabProps) => {
  const [showEmployeeCreate, setShowEmployeeCreate] = useState(false);
  const [oauthToken, setOauthToken] = useState<Token | undefined>(undefined);
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [result, setResult] = useState(new PageResult<Employee>());
  const [page, setPage] = useState(0);

  const modalRef = React.useRef<ModalPromise | null>(null);

  useEffect(() => {
    PageResult.execute(
      Employee.find(gym.id, { page, size: 20, sort: 'athlete.firstname,ASC' }),
      Employee.count(gym.id),
      page,
      20,
    ).then((res) => setResult(res));
  }, [gym, page]);

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleRemove = React.useCallback(
    async (employee: Employee) => {
      console.log(employee);
      if (window.confirm(`Do you really want to delete ${employee.athlete.fullName}?`)) {
        employee
          .remove()
          .then(() =>
            PageResult.execute(
              Employee.find(gym.id, { page, size: 20, sort: 'athlete.firstname,ASC' }),
              Employee.count(gym.id),
              page,
              20,
            ),
          )
          .then((res) => setResult(res));
      }
    },
    [gym, page],
  );

  const handleCreateEmployee = React.useCallback(() => {
    setShowEmployeeCreate(true);
  }, []);

  const handleEmployeeCreateClose = React.useCallback(() => {
    setShowEmployeeCreate(false);
    PageResult.execute(
      Employee.find(gym.id, { page, size: 20, sort: 'athlete.firstname,ASC' }),
      Employee.count(gym.id),
      page,
      20,
    ).then((res) => setResult(res));
  }, [gym, page]);

  const handleSelect = React.useCallback((employee: Employee) => {
    setEmployee(employee);
  }, []);

  const handleCloseEdit = React.useCallback(() => {
    setEmployee(undefined);
  }, []);

  const handleFetchToken = React.useCallback(async (employee: Employee) => {
    employee.fetchOAuthToken().then((result) => setOauthToken(result));
    modalRef.current && (await modalRef.current.show());
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreateEmployee} disabled={gym._isNew}>
            Create New Employee
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>{'Name'}</th>
              <th>{'User'}</th>
              <th>{'Roles'}</th>
              <th>{'Action'}</th>
            </tr>
          </thead>
          <tbody>
            {result.content.map((c: Employee) => (
              <EmployeeTableRow
                key={c.id}
                employee={c}
                onRemove={handleRemove}
                onSelect={handleSelect}
                onFetchToken={handleFetchToken}
              />
            ))}
          </tbody>
        </Table>
        {showEmployeeCreate ? (
          <EmployeeCreateModal onClose={handleEmployeeCreateClose} isOpen={showEmployeeCreate} gym={gym} />
        ) : null}
        {employee ? <EmployeeEditModal employee={employee} isOpen={true} onClose={handleCloseEdit} /> : null}
        <ModalPromise title={'OAuth Token'} ref={modalRef} size="lg">
          <Input.TextArea rows={30} disabled value={JSON.stringify(oauthToken, null, 4)} />
        </ModalPromise>
      </SingleColRow>
    </React.Fragment>
  );
});
