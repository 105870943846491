/**
 *
 * Created by neo on 08.03.17.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Media } from '../Model/Media/Media';

const styles = {
  imageContainer: {
    width: 120,
    height: 120,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  image: {
    width: 120,
    height: 120,
    maxWidth: '100%',
    maxHeight: '100%',
  },
};

export type Props = {
  medias?: Media[];
  showVideo?: boolean;
  width?: number;
  height?: number;
};

export const TableRowMedia: React.FC<Props> = observer((props: Props) => {
  const { width, height, showVideo } = props;
  const store = useLocalStore(
    (source: Props) => ({
      get imageMedia(): Media | undefined {
        return source.medias?.find(
          (m) => m.mediaType.startsWith('image') || m.mediaType.startsWith('application/octet-stream'),
        );
      },
      get videoMedia(): Media | undefined {
        return source.medias?.find((m) => (m.mediaType || '').startsWith('video'));
      },
      get imageUrl(): string | undefined {
        return store.imageMedia?.smallest;
      },
    }),
    props,
  );

  const size = {
    width: width ?? 120,
    height: height ?? 120,
  };
  if (store.videoMedia && showVideo) {
    return (
      <video width={size.width} height={size.height} playsInline={true} autoPlay={true} loop={true} muted={true}>
        <source src={store.videoMedia.smallest} type={store.videoMedia.mediaType} />
      </video>
    );
  } else if (store.imageUrl) {
    return <div style={{ ...styles.imageContainer, ...size, backgroundImage: `url('${store.imageUrl}')` }} />;
  }
  return <div style={{ ...styles.imageContainer, ...size }} />;
});
