/**
 * Created by neo on 23.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Input } from 'antd';
import { PageResult } from '../../../Model/PageResult';
import { WorkoutModule } from '../../../Model/Coach/WorkoutTemplate/WorkoutModule';
import { useNavigate } from 'react-router';

export type WorkoutModulesIndexScreenProps = {};

export const WorkoutModulesIndexScreen: React.FC<WorkoutModulesIndexScreenProps> = observer((props) => {
  const history = useNavigate();

  const [query, setQuery] = useState<string | undefined>();
  const [modules, setModules] = useState<PageResult<WorkoutModule>>();

  const handleChangeQuery = React.useCallback(({ target: { value } }) => setQuery(value), []);

  const handleCreateNew = React.useCallback(() => history(`/coach/workout-modules/new`), [history]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h1>Workout Modules</h1>
        </Col>
        <Col xs={12}>
          <Row>
            <Col>
              <Input placeholder="Search..." value={query} onChange={handleChangeQuery} />
            </Col>
            <Col md="auto">
              <Button type="primary" onClick={handleCreateNew}>
                Create New Module
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});
