import { MusicFile } from './MusicFile';

const YogaMusicSelection: MusicFile[] = [
  {
    title: 'Relax',
    key: 'relax',
    source: 'https://media.kinastic.com/b19d1346-75b9-4aae-bfe8-5907fb3ede07.mp3',
  },
  {
    title: 'Hot Chill',
    key: 'hot_chill',
    source: 'https://media.kinastic.com/57dcf818-543c-4dc0-b1fd-874dd4d140b0.mp3',
  },
  {
    title: 'Miles of Fantasies',
    key: 'miles_of_fantasies',
    source: 'https://media.kinastic.com/bae90e74-0edd-4daa-b0a3-a6767d667bd9.mp3',
  },
  {
    title: 'Again and Again',
    key: 'again_and_again',
    source: 'https://media.kinastic.com/c72e5f47-a6d8-4a0f-8854-689aa4fbd865.mp3',
  },
  {
    title: 'Aurora',
    key: 'aurora',
    source: 'https://media.kinastic.com/e8af657e-e410-49e0-bc59-76770650eefe.mp3',
  },
  {
    title: 'Really',
    key: 'really',
    source: 'https://media.kinastic.com/1cb396e6-780b-454c-913b-c95baab9af44.mp3',
  },
  {
    title: 'Long Run',
    key: 'long_run',
    source: 'https://media.kinastic.com/dce1f6de-6a5e-4ec0-9a57-0969a9b4b032.mp3',
  },
  {
    title: 'Inside Me',
    key: 'inside_me',
    source: 'https://media.kinastic.com/d83fbc93-a054-4e32-b25e-8decdf6047cb.mp3',
  },
  {
    title: 'Attracted to You',
    key: 'attracted_to_you',
    source: 'https://media.kinastic.com/abc0cfc1-e7a0-4147-9d0a-7cb4aa947d58.mp3',
  },
];

export { YogaMusicSelection };
