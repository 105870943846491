import * as React from 'react';
import { observer } from 'mobx-react';
import { SortingDirection } from './SortableHeadColumn';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export interface SortableHeadColumnProps {
  sortDirection?: SortingDirection;
}

@observer
export class SortableHeadColumnArrow extends React.Component<SortableHeadColumnProps> {
  render() {
    const {
      props: { sortDirection },
    } = this;

    if (sortDirection === 'ASC') {
      return <ArrowUpOutlined />;
    } else if (sortDirection === 'DESC') {
      return <ArrowDownOutlined />;
    }
    return null;
  }
}
