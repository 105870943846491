/**
 * Created by neo on 20.05.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RecipeGenerator } from './RecipeGenerator';
import { Badge, Button, Collapse, Input, Popconfirm, Space, Tabs, Tag } from 'antd';
import { RecipeEditModalBasicInfo } from '../Recipe/RecipeEditModalBasicInfo';
import { RecipeEditModalIngredients } from '../Recipe/RecipeEditModalIngredients';
import { RecipeEditModalInstructionsTab } from '../Recipe/RecipeEditModalInstructionsTab';
import { RecipeEditModalNutrientsTab } from '../Recipe/RecipeEditModalNutrientsTab';

function badgeColor(status: string): string {
  if (status === 'generating' || status === 'creating') return 'yellow';
  if (status === 'requiresAttention') return 'orange';
  if (status === 'error') return 'red';
  if (status === 'done' || status === 'saved') return 'green';
  return 'cyan';
}

export type RecipeGeneratorEntryProps = {
  generator: RecipeGenerator;
  onRemove?: (generator: RecipeGenerator) => void;
};

export const RecipeGeneratorEntry: React.FC<RecipeGeneratorEntryProps> = observer(({ generator, onRemove }) => {
  const [activeTab, setActiveTab] = useState('basic');

  const handleSave = React.useCallback(() => {
    generator.save();
  }, [generator]);

  const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

  const handleRetry = React.useCallback(() => {
    generator.reset();
    generator.generate();
  }, [generator]);

  const handleDelete = React.useCallback(() => {
    generator.recipe.archive();
    onRemove && onRemove(generator);
  }, [generator, onRemove]);

  return (
    <Collapse collapsible="header">
      <Collapse.Panel
        header={generator.recipeName}
        key={generator.recipeName}
        extra={
          <Space>
            {generator.exists && <Tag color="green">EXISTS</Tag>}
            {!generator.recipe.image && <Tag color="red">MISSING IMAGE</Tag>}
            <Tag color={badgeColor(generator.status)}>{generator.status}</Tag>
            {(generator.status === 'error' || generator.status === 'requiresAttention') && (
              <Button type="link" onClick={handleRetry}>
                Reset & Try again
              </Button>
            )}
            {(generator.status === 'done' ||
              generator.status === 'saved' ||
              generator.status === 'error' ||
              generator.status === 'requiresAttention') && (
              <React.Fragment>
                <Button type="link" onClick={handleSave}>
                  Save
                </Button>
                <Button type="link" onClick={() => onRemove && onRemove(generator)}>
                  Remove from list
                </Button>
                {generator.saved && (
                  <Popconfirm title={'Are you sure?'} onConfirm={handleDelete}>
                    <Button type="link">Delete</Button>
                  </Popconfirm>
                )}
              </React.Fragment>
            )}
          </Space>
        }
      >
        <Tabs defaultActiveKey="1" onChange={handleChangeTab} activeKey={activeTab}>
          <Tabs.TabPane tab="Basic" key="basic">
            <RecipeEditModalBasicInfo recipe={generator.recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Ingredients" key="ingredients">
            <RecipeEditModalIngredients recipe={generator.recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Instructions" key="instructions">
            <RecipeEditModalInstructionsTab recipe={generator.recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Nutrients" key="nutrients">
            <RecipeEditModalNutrientsTab recipe={generator.recipe} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="ChatGPT JSON" key="gpt-json">
            <Input.TextArea rows={32} value={generator.tmpMessage} disabled={true} />
          </Tabs.TabPane>
        </Tabs>
      </Collapse.Panel>
    </Collapse>
  );
});
