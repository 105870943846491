import { Stage, StagePortMode } from './Stage';
import { notUndefined } from '../../../Utils/notUndefined';
import { toJS } from 'mobx';
import { Pipeline } from '../Pipeline';
import { DataSignal, dataSignal } from '../Signal/DataSignal';
import { EmptySignal } from '../Signal/EmptySignal';
import { EndOfStreamSignal } from '../Signal/EndOfStreamSignal';
import objectPath from 'object-path';
import { SinkStage } from './SinkStage';
import { StageOutPort } from '../Signal/StageOutPort';

export type GroupEntry = {
  key: string;
  result: any;
};

export type GroupResult = Array<GroupEntry>;

export interface GroupStageConfig {
  /**
   * Supports nested objects like `position.primary`
   */
  attribute: string;
  /**
   * grouping (and filtering) of bodyPartGroups
   * if `attribute` is one of the values = bodyParts | synergists | stabilizers
   * e.g. [['lower_body', 'upper_thigh'], ['upper_body']]
   * note: since most exercises do not include 'lower_body' it's a 2d array where in the first array we define
   * a bunch of muscles that belong to one group (the grouping).
   * recommended use: Think about the "parent" muscles groups you want to group and then "flatten" their identifiers to get the array of children identifiers
   * it can also be just [['upper_arm', ...], ['lower_arm', ...]]
   */
  bodyPartGroups?: Array<Array<string>>;
}

export class GroupStage extends Stage<GroupStageConfig> {
  sink: SinkStage;

  constructor(pipeline: Pipeline, json?: any, portMode: StagePortMode = 'ASYNC') {
    super(pipeline, json, portMode);
    this.sink = new SinkStage(pipeline);
  }

  process() {
    const { dataSignals } = this;
    console.log('group::process', toJS(dataSignals));
    return Promise.all(
      this.group(dataSignals).map((group, index) => {
        const { innerPipeline } = this;
        console.log('innerPipeline', toJS(innerPipeline));
        if (innerPipeline.firstStages.length > 0) {
          const signals = group.result
            .map((result, index) => new DataSignal(result, index))
            .concat([new EndOfStreamSignal()] as any);
          return innerPipeline.compile().then((pipeline) =>
            pipeline.executePromise(this.pipeline.context, signals).then((result) => {
              console.log('group inner result', toJS(result));
              group.result = result.map((s) => s.data);
              return new DataSignal(group, index);
            }),
          );
        }
        return Promise.resolve(new DataSignal(group, index));
      }),
    ).then((signals) => {
      signals.forEach((signal) => this.processNext(signal));
    });
  }

  private group(input: DataSignal[] = []) {
    switch (this.config.attribute) {
      case 'bodyParts':
      case 'synergists':
      case 'stabilizers':
        return this.bodyPartGrouping(input);
      default:
        return this.paramGrouping(input);
    }
  }

  private bodyPartGrouping(input: DataSignal[] = []): GroupResult {
    if (this.config.bodyPartGroups) {
      return Object.entries(
        input
          .map((signal) => signal.data)
          .reduce((acc: Array<Array<any>>, exercise) => {
            const index = this.findIndex(exercise);
            if (index !== -1) {
              const group = acc[index] ?? [];
              group.push(exercise);
              acc[index] = group;
            }
            return acc;
          }, new Array<Array<any>>(this.config.bodyPartGroups.length))
          .filter(notUndefined),
      ).map(([key, result]) => ({ key, result }));
    }
    return [];
  }

  private paramGrouping(input: DataSignal[] = []): GroupResult {
    return Object.entries(
      input
        .map((signal) => signal.data)
        .reduce((acc: { [key: string]: Array<any> }, current: any) => {
          const value = objectPath.get(current, this.config.attribute) ?? 'undefined';
          console.log('grouping', this.config.attribute, value, toJS(current));
          const values = acc[value] ?? [];
          values.push(current);
          acc[value] = values;
          return acc;
        }, {} as { [key: string]: Array<any> }),
    ).map(([key, result]) => ({ key, result }));
  }

  /**
   * Find the index of the bucket inside `config.bodyPartGroups`
   */
  private findIndex(exercise: any) {
    const values = this.getIndexValues(exercise);
    return this.config.bodyPartGroups?.findIndex((group) => !!group.find((entry) => values.includes(entry))) ?? -1;
  }

  private getIndexValues(exercise: any): Array<string> {
    switch (this.config.attribute) {
      case 'synergists':
        return exercise.allSynergistIds;
      case 'stabilizers':
        return exercise.allStabilizerIds;
      case 'bodyParts':
      default:
        return exercise.allBodyPartIds;
    }
  }

  get type(): string {
    return 'group';
  }

  get innerPipeline(): Pipeline {
    console.log(
      'innerPipeline::create',
      this.nextStages.filter((port) => port.type === 'inner'),
    );
    return this.getNextStage(
      new Pipeline(),
      this.nextStages.filter((port) => port.type === 'inner'),
    );
  }

  getNextStage(pipeline: Pipeline, outPorts: StageOutPort[]) {
    const nextStages = outPorts.map((port) => port.otherPort?.stage).filter(notUndefined);
    const nextPorts = nextStages.flatMap((stage) => stage.nextStages.map((port) => port)).filter(notUndefined);
    console.log('innerPipeline::getNextStage', toJS(nextStages), toJS(nextPorts));
    nextStages.forEach((stage) => pipeline.addStage(stage));
    nextPorts
      .map((port) => ({
        from: port.stage.id,
        to: port.otherPort?.stage.id ?? '',
        type: port.type,
        inType: port.otherPort?.type,
      }))
      .forEach((edge) => pipeline.addEdge(edge));

    if (nextPorts.length > 0) {
      return this.getNextStage(pipeline, nextPorts);
    }
    return pipeline;
  }
}
