import { action, computed, observable } from 'mobx';
import { AbstractExercise } from '../AbstractExercise';
import { LocalizedValue } from '../../LocalizedValue';

const languagePriority = ['de', 'en', 'fr', 'it'];

export class AbstractExerciseAdmin extends AbstractExercise {
  @observable
  name: Array<LocalizedValue> = [];
  @observable
  description: Array<LocalizedValue> = [];

  constructor(json?: any) {
    super(json);
    if (json) {
      this.name = (
        json.name || [
          {
            lang: 'de',
            value: '',
          },
        ]
      ).map((v) => new LocalizedValue(v));
      this.description = (json.description || []).map((v) => new LocalizedValue(v));
    }
  }

  @action
  changeName(lang: string, value: string) {
    const existing = this.name.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.name.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  @action
  changeDescription(lang: string, value: string) {
    const existing = this.description.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.description.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  getName(lang: string) {
    return this.name.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  getDescription(lang: string) {
    return this.description.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  @computed
  get nameGermanOrNext(): string {
    for (const lang of languagePriority) {
      const entry = this.name.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.name[0];
    return first?.value ?? '';
  }

  toJS(newId?: boolean): any {
    return Object.assign(super.toJS(newId), {
      name: this.name.map((v) => v.toJS()),
      description: this.description.map((v) => v.toJS()),
    });
  }
}
