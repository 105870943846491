/**
 * Created by neo on 20.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { MediaOverlayEditModalVideo } from './MediaOverlayEditModalVideo';
import { Button, Modal } from 'antd';
import { MediaOverlayEntry } from './MediaOverlayEntry';
import { observable, runInAction } from 'mobx';
import { MediaOverlay } from '../../../../../../Model/Media/MediaOverlay';
import { Media } from '../../../../../../Model/Media/Media';
import styled from '@emotion/styled';

export type MediaOverlayTabProps = {
  media: Media;
};

const ContentContainer = styled.div`
  overflow-y: scroll;
  max-height: 560px;
`;

export const MediaOverlayTab: React.FC<MediaOverlayTabProps> = observer(({ media }) => {
  const [store] = useState(() =>
    observable({
      currentSeek: 0,
      maxSeconds: 0,
    }),
  );

  const handleProgress = React.useCallback(
    ({ played, playedSeconds }) => {
      runInAction(() => (store.currentSeek = playedSeconds));
    },
    [store],
  );

  const handleAddOverlay = React.useCallback(() => {
    const startTime = MediaOverlay.calculateTime(store.currentSeek);
    media.overlay.push(new MediaOverlay({ startTime }));
  }, [media, store]);

  return (
    <React.Fragment>
      <MediaOverlayEditModalVideo media={media} onProgress={handleProgress} store={store} />
      <Button block={true} onClick={handleAddOverlay}>
        Add Overlay Entry
      </Button>
      <ContentContainer>
        {media.overlay.map((overlay, index) => (
          <MediaOverlayEntry key={`${media.id}_${index}`} media={media} overlay={overlay} index={index} store={store} />
        ))}
      </ContentContainer>
    </React.Fragment>
  );
});
