/**
 * Created by andreaskarantzas on 28.10.20.
 */

import React, { useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { runInAction } from 'mobx';
import Typography from '@mui/material/Typography/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileInfo from '../../../../../Model/FileInfo/FileInfo';
import MediaService from '../../../../../Services/MediaService';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { GridListMediaItem } from '../../../../Media/GridListMediaItem';
import Dropzone from 'react-dropzone';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

export type ComponentStylesVideoProps = {
  component: NativeFeedComponent;
};

export const ComponentStylesVideo: React.FC<ComponentStylesVideoProps> = observer((props) => {
  const { classes } = useStyles();
  const { component } = props;

  const store = useLocalStore(
    (source) => ({
      uploading: false,
      filesToUpload: new Array<FileInfo>(),
      uploadHandler(file: FileInfo, event: any) {
        console.log('upload', event);
        (file as any).loaded = event.loaded;
      },
      async handleDrop(files: Array<File>) {
        if (!store.uploading) {
          store.uploading = true;
          store.filesToUpload = files.map((f) => new FileInfo(f));
          try {
            const medias = await Promise.all(
              store.filesToUpload.map((f) =>
                MediaService.uploadMedia(f.file, (event) => store.uploadHandler(f, event)),
              ),
            );
            runInAction(() => {
              if (medias.length > 0) {
                source.component.media = medias[0];
              }
            });
          } catch (e) {
            console.log('Upload failed', files, e);
          } finally {
            store.filesToUpload = [];
            store.uploading = false;
          }
        }
      },
    }),
    props,
  );

  const handleRemoveFile = useCallback(() => {
    runInAction(() => {
      component.media = undefined;
    });
  }, [component]);

  const toggleRepeat = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      runInAction(() => {
        component.props.extraProps.set('repeat', checked);
      });
    },
    [component],
  );

  const toggleMuted = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      runInAction(() => {
        component.props.extraProps.set('muted', checked);
      });
    },
    [component],
  );

  return (
    <Grid className={classes.container}>
      <Typography>Margin</Typography>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={!!component.props.extraProps.get('muted') ?? false}
                onChange={toggleMuted}
                name="checkedA"
              />
            }
            label="Repeat"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={!!component.props.extraProps.get('repeat') ?? false}
                onChange={toggleRepeat}
                name="checkedA"
              />
            }
            label="Repeat"
          />
        </Grid>
      </Grid>
      <div>
        <Typography variant="h6">Video</Typography>
        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item>
              {store.uploading ? (
                <CircularProgress />
              ) : (
                <Dropzone onDrop={store.handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className="dropzone"
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <input {...getInputProps()} />
                      {'Drop Medias (Images or Videos) here'}
                    </div>
                  )}
                </Dropzone>
              )}
            </Grid>
            <Grid item xs>
              {component.media ? <GridListMediaItem media={component.media} onRemove={handleRemoveFile} /> : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
}));
