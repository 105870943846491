import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { NotInNodeWidget } from './NotInNodeWidget';
import { NotInNodeModel } from './NotInNodeModel';
import { NotInStage } from '../../../../../../../../Model/Coach/Stages/Logical/NotInStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class NotInNodeModelFactory extends AbstractReactFactory<NotInNodeModel, DiagramEngine> {
  constructor() {
    super('logical:nin');
  }

  generateReactWidget(event): JSX.Element {
    return <NotInNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new NotInNodeModel(new NotInStage(new Pipeline()));
  }
}
