import * as React from 'react';
import { Route, Routes } from 'react-router';
import { observer } from 'mobx-react';
import { EarnModelTemplateViewScreen } from './EarnModelTemplate/View/EarnModelTemplateViewScreen';
import { EarnModelTemplateListScreen } from './EarnModelTemplate/EarnModelTemplateListScreen';
import { ChallengeListViewScreen } from './Challenge/ChallengeListViewScreen';
import { RouteChallengeListScreen } from './RouteChallenge/RouteChallengeListScreen';
import { RouteChallengeViewScreen } from './RouteChallenge/View/RouteChallengeViewScreen';

export const EngagementRouter: React.FC<unknown> = observer(() => {
  return (
    <Routes>
      <Route path="earn-model-templates">
        <Route path=":earnModelTemplateId/*" element={<EarnModelTemplateViewScreen />} />
        <Route index element={<EarnModelTemplateListScreen />} />
      </Route>
      <Route path="route-challenges/*">
        <Route path=":challengeId/*" element={<RouteChallengeViewScreen />} />
        <Route index element={<RouteChallengeListScreen />} />
      </Route>
      <Route path="challenges/*">
        <Route path="*" element={<ChallengeListViewScreen />} />
      </Route>
      <Route path="ledgers">
        {/*<Route path=":workoutId" element={<WorkoutViewScreen />} />*/}
        {/*<Route index element={<WorkoutListScreen />} />*/}
      </Route>
    </Routes>
  );
});
