/**
 * Created by neo on 06.02.17.
 */

import React from 'react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseFilterBodyPartCard } from './ExerciseFilterBodyPartCard';
import { observer } from 'mobx-react';

export type ExerciseVariationFilterBodyPartsExcludedProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterBodyPartsExcluded: React.FC<ExerciseVariationFilterBodyPartsExcludedProps> = observer(
  ({ filter }: ExerciseVariationFilterBodyPartsExcludedProps) => {
    return <ExerciseFilterBodyPartCard header="Excluded Body Parts" bodyPartIds={filter.excludedBodyPartIds ?? []} />;
  },
);
