/**
 * Created by neo on 29.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { Col, Row } from 'reactstrap';
import { Button, Form, Modal, Select, Tabs } from 'antd';
import { observable, runInAction, toJS } from 'mobx';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { TestTable } from './TestTable';
import { WorkoutResponse } from '../../../../../Model/Coach/Script/WorkoutResponse';
import { PipelineContext } from '../../../../../Model/Coach/PipelineContext';
import { TagCombination } from '../../../../../Model/Coach/TagCombination';
import { chunk } from '../../../../../Utils/chunk';
import { AthleteEquipment } from '../../../../../Model/AthleteEquipment/AthleteEquipment';
import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { EquipmentTypeInput } from '../../../../../Components/EquipmentTypeInput';

const equipmentConfigurations = [
  new AthleteEquipment({
    id: 'gym',
    name: 'Gym',
    equipmentTypes: [],
  }),
  new AthleteEquipment({
    id: 'free',
    name: 'Free',
    equipmentTypes: ['FREE', 'FREE_OUTDOOR'],
  }),
  new AthleteEquipment({
    id: 'home_generic',
    name: 'Home Generic',
    equipmentTypes: ['BENCH_INCLINED', 'FLAT_BENCH', 'BARBELL', 'DUMBBELL', 'KETTLEBELL', 'FREE'],
  }),
  new AthleteEquipment({
    id: 'jose',
    name: 'Jose',
    equipmentTypes: [
      'POWER_RACK',
      'CABLE_PULL_DOWN',
      'BENCH_INCLINED',
      'FREE',
      'DUMBBELL',
      'CABLE_PULL_UP',
      'FREE_OUTDOOR',
      'CABLE_PULL_SEATED_CHEST_DOWN',
      'LEG_CURL_MACHINE_LAID',
      'FLAT_BENCH',
      'SZ_BAR',
      'BICYCLE',
      'BARBELL_GUIDED',
      'LEG_EXTENSION_MACHINE',
      'CABLE_PULL_SEATED',
      'BARBELL',
    ],
  }),
  new AthleteEquipment({
    id: 'alex',
    name: 'Alex',
    equipmentTypes: [
      'BENCH_INCLINED',
      'FLAT_BENCH',
      'BARBELL',
      'KETTLEBELL',
      'BAND',
      'POWER_RACK',
      'CHIN_UP_BAR',
      'SLING_TRAINER',
      'DIPS_STATION',
      'FREE',
    ],
  }),
  new AthleteEquipment({
    id: 'dumbbell',
    name: 'Kurzhantel Only',
    equipmentTypes: ['FREE_OUTDOOR', 'FREE', 'DUMBBELL'],
  }),
  new AthleteEquipment({
    id: 'jenny',
    name: 'Jenny Lau',
    equipmentTypes: ['FREE_OUTDOOR', 'FREE', 'TREADMILL', 'FOAM_ROLL', 'BAND'],
  }),
];

export type TestTabProps = {
  workoutTemplate: WorkoutTemplate;
};

export const TestTab: React.FC<TestTabProps> = observer(({ workoutTemplate }) => {
  const [store] = useState(() =>
    observable({
      activeTab: 'level:beginner',
      loading: false,
      cancelled: false,
      result: new Array<WorkoutResponse>(),
      equipmentTypes: new Array<string>(),
      selectedEquipment: 'gym',
      customTags: new Array<{ label: string; value: string }>(),
    }),
  );

  // const generateBatch = (batch: PipelineContext[]) =>
  //   Promise.all(batch.map((context) => workoutTemplate.execute(context))).then((result) =>
  //     runInAction(() => result.forEach((res) => store.result.push(res))),
  //   );
  //
  // const data = store.result.sort((a, b) => a.sortedTags.join(', ').localeCompare(b.sortedTags.join(', ')));

  const generate = React.useCallback(async () => {
    runInAction(() => {
      store.loading = true;
      store.result = [];
    });
    const chunks = chunk(
      TagCombination.permutationsWorkout
        .map((tags) => tags.concat(store.customTags.map((t) => t.value)))
        .map((tags) => new PipelineContext({ tags, equipmentTypes: toJS(store.equipmentTypes), debug: true })),
    );
    try {
      for (const chunk of chunks) {
        await Promise.all(
          chunk.map((context) =>
            workoutTemplate.execute(context).then((result) => runInAction(() => store.result.push(result))),
          ),
        );
        if (store.cancelled) {
          runInAction(() => (store.cancelled = false));
          return;
        }
      }
    } finally {
      runInAction(() => (store.loading = false));
    }
  }, [store, workoutTemplate]);

  const cancel = React.useCallback(() => {
    runInAction(() => (store.cancelled = true));
  }, [store]);

  const handleStartTest = React.useCallback(() => {
    if (store.loading) {
      cancel();
    } else {
      generate();
    }
  }, [store, cancel, generate]);

  const handleChangeEquipment = React.useCallback(
    (value) => {
      const equipment = equipmentConfigurations.find((e) => e.id === value)?.equipmentTypes;
      runInAction(() => {
        store.equipmentTypes = equipment ?? [];
        store.selectedEquipment = value;
      });
    },
    [store],
  );

  const handleRetest = React.useCallback(
    (workout: WorkoutResponse) => {
      const equipmentTypes =
        equipmentConfigurations.find((e) => e.id === store.selectedEquipment)?.equipmentTypes ?? [];
      workout.retest(workoutTemplate, equipmentTypes);
    },
    [store, workoutTemplate],
  );

  const handleTabChange = React.useCallback((tab) => runInAction(() => (store.activeTab = tab)), [store]);

  const handleChange = React.useCallback(
    (tags: { label: string; value: string }[]) => {
      runInAction(() => (store.customTags = tags ?? []));
    },
    [store],
  );

  return (
    <Form layout="vertical">
      <Row>
        <Col>
          <Form.Item label="Equipment config">
            <Select value={store.selectedEquipment} onChange={handleChangeEquipment}>
              {equipmentConfigurations.map((config) => (
                <Select.Option key={config.id} value={config.id}>
                  {config.name}
                </Select.Option>
              ))}
            </Select>
            <EquipmentTypeInput container={store.equipmentTypes} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Custom Tags">
            <CreatableSelect isClearable isMulti onChange={handleChange as any} value={store.customTags.slice()} />
          </Form.Item>
        </Col>
        <Col xs="auto">
          <Button
            type={store.loading ? 'primary' : 'default'}
            danger
            onClick={handleStartTest}
            disabled={store.cancelled}
          >
            {store.loading ? 'Cancel Test' : 'Start Test'}
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Tabs activeKey={store.activeTab} animated={false} onChange={handleTabChange}>
          <Tabs.TabPane key="level:beginner" tab="Beginner">
            <TestTable result={store.result} tags={['level:beginner']} onRetest={handleRetest} />
          </Tabs.TabPane>
          <Tabs.TabPane key="level:intermediate" tab="Intermediate">
            <TestTable result={store.result} tags={['level:intermediate']} onRetest={handleRetest} />
          </Tabs.TabPane>
          <Tabs.TabPane key="level:advanced" tab="Advanced">
            <TestTable result={store.result} tags={['level:advanced']} onRetest={handleRetest} />
          </Tabs.TabPane>
        </Tabs>
      </SingleColRow>
    </Form>
  );
});
