/**
 * Created by neo on 18.12.20.
 */
import { MeasureEntry, MeasureEntryJson } from './MeasureEntry';
import { observable } from 'mobx';

export type MeasureJson = {
  us?: MeasureEntryJson;
  metric?: MeasureEntryJson;
};

export class Measure {
  @observable
  us?: MeasureEntry;
  @observable
  metric?: MeasureEntry;

  constructor(json?: Partial<MeasureJson>) {
    if (json) {
      this.us = json.us ? new MeasureEntry(json.us) : undefined;
      this.metric = json.metric ? new MeasureEntry(json.metric) : undefined;
    }
  }

  toJS(): MeasureJson {
    return {
      us: this.us?.toJS(),
      metric: this.metric?.toJS(),
    };
  }
}
