/**
 * Created by neo on 18.01.17.
 */
import { action, computed, observable, ObservableMap } from 'mobx';
import { ExerciseBlock } from './ExerciseBlock';
import { ExerciseUtil } from '../../Services/Util/ExerciseUtil';
import { Workout } from './Workout';

import { v4 as UUID } from 'uuid';
import { AggregateEvent } from '../Axon/AggregateEvent';
import { Exercise } from '../Exercise/Exercise';
import { ExerciseId } from '../Exercise/ExerciseId';
import { AbstractWorkout } from './AbstractWorkout';

export class Phase {
  @observable
  phaseId: string = UUID();
  @observable
  type: string = 'strength';
  @observable
  name: string = '';
  @observable
  description?: string = undefined;
  @observable
  exerciseBlocks: Array<ExerciseBlock> = [];
  @observable
  options: ObservableMap = observable.map({});
  @observable
  workout?: AbstractWorkout = undefined;

  constructor(workout?: AbstractWorkout, json?: any) {
    this.workout = workout;
    if (json) {
      this.setData(json);
    }
  }

  @action
  setBasicData(json: any) {
    this.phaseId = json.phaseId || UUID();
    this.type = json.type || 'strength';
    this.name = json.name || '';
    this.description = json.description;
  }

  @action
  updateData(json: any) {
    this.setBasicData(json);
    this.exerciseBlocks.forEach((e: ExerciseBlock, index: number) => {
      if (
        !json.exerciseBlocks.find(
          (w0: any) => ExerciseUtil.uniqueId(w0.exercise.id, w0.exercise.variationId) === e.exercise.uniqueId,
        )
      ) {
        console.log(`Removing block ${e.exercise.name}`);
        this.exerciseBlocks.splice(index, 1);
      }
    });
    json.exerciseBlocks.forEach((e: any, i: number) => {
      const existing = this.exerciseBlocks.find(
        (w0: ExerciseBlock) => ExerciseUtil.uniqueId(e.exercise.id, e.exercise.variationId) === w0.exercise.uniqueId,
      );
      const block = existing || new ExerciseBlock(this, e);
      if (existing) {
        console.log(`Updating ${existing.exercise.name}`);
        existing.updateData(e);
      }

      const element = this.exerciseBlocks[i];
      if (!element || element.exercise.uniqueId !== block.exercise.uniqueId) {
        if (i < this.exerciseBlocks.length) {
          console.log(`Inserting ${block.exercise.name} at ${i}`);
          this.exerciseBlocks.splice(i, 0, block);
        } else {
          console.log(`Pushing block ${block.exercise.name}`);
          this.exerciseBlocks.push(block);
        }
      } else {
        console.log(`Block ${block.exercise.name} is the same`);
      }
    });
  }

  @action
  setData(json: any) {
    this.setBasicData(json);
    this.exerciseBlocks = (json.exerciseBlocks || [])
      .filter((b: any) => b && b.exercise && b.exercise.id)
      .map((b: any) => new ExerciseBlock(this, b));
  }

  @action
  removeExerciseBlock(block: ExerciseBlock) {
    const index = this.exerciseBlocks.findIndex((b) => b.exerciseBlockId === block.exerciseBlockId);
    if (index !== -1) {
      this.exerciseBlocks.splice(index, 1);
    }
  }

  toJS(newId?: boolean): any {
    return {
      phaseId: newId ? UUID() : this.phaseId,
      type: this.type,
      name: this.name,
      description: this.description,
      exerciseBlocks: this.exerciseBlocks.map((b) => b.toJS(newId)),
    };
  }

  createExerciseBlock(exercise: Exercise, description?: string): ExerciseBlock {
    return new ExerciseBlock(this, {
      exercise,
      description,
    });
  }

  @action
  addExerciseBlock(block: ExerciseBlock) {
    block.phase = this;
    this.exerciseBlocks.push(block);
  }

  @computed
  get valid(): boolean {
    if (this.name && this.name.trim().length > 0) {
      if (this.exerciseBlocks.length > 0) {
        for (const block of this.exerciseBlocks) {
          if (!block.valid) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  @computed
  get totalExercises(): number {
    return this.exerciseBlocks.length;
  }

  @computed
  get duration(): number {
    return this.exerciseBlocks.reduce((total: number, block: ExerciseBlock) => total + block.duration, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.exerciseBlocks.reduce((total: number, block: ExerciseBlock) => total + block.totalTimeExercising, 0);
  }

  @computed
  get totalSets(): number {
    return this.exerciseBlocks.reduce((total: number, b: ExerciseBlock) => total + b.sets.length, 0);
  }

  @computed
  get tons(): number {
    return this.exerciseBlocks.reduce((total: number, b: ExerciseBlock) => total + b.tons, 0);
  }

  @computed
  get lastBlock(): ExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[length - 1];
    }
    return undefined;
  }

  @computed
  get firstBlock(): ExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[0];
    }
    return undefined;
  }

  @computed
  get prevPhase(): Phase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      if (phaseIndex > 0) {
        return this.workout.phases[phaseIndex - 1];
      }
    }
    return undefined;
  }

  @computed
  get nextPhase(): Phase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      const nextIndex = phaseIndex + 1;
      return nextIndex < this.workout.phases.length ? this.workout.phases[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get phaseIndex(): number {
    return this.workout ? this.workout.phases.findIndex((p) => p.phaseId === this.phaseId) : -1;
  }

  @computed
  get prevBlock(): ExerciseBlock | undefined {
    return this.lastBlock || (this.prevPhase ? this.prevPhase.prevBlock : undefined);
  }

  @computed
  get nextBlock(): ExerciseBlock | undefined {
    return this.firstBlock || (this.nextPhase ? this.nextPhase.nextBlock : undefined);
  }

  @computed
  get minDuration(): number {
    let totalTime = 0;
    const blocks = (this.exerciseBlocks || []).slice();
    for (const block of blocks) {
      totalTime += block.minDuration;
    }
    return totalTime;
  }

  @computed
  get maxDuration(): number {
    let totalTime = 0;
    const blocks = (this.exerciseBlocks || []).slice();
    for (const block of blocks) {
      totalTime += block.maxDuration;
    }
    return totalTime;
  }

  calculateCalories(bmr24: number): number {
    return this.exerciseBlocks.reduce((total: number, b: ExerciseBlock) => total + b.calculateCalories(bmr24), 0);
  }

  hasExercise(exerciseId: ExerciseId, exerciseBlockId?: string): boolean {
    if (exerciseBlockId) {
      return !!this.exerciseBlocks.find((b) => b.exerciseBlockId === exerciseBlockId);
    }
    const index = this.exerciseBlocks.findIndex((b) => b.exercise.id === exerciseId.variationId);
    return index !== -1;
  }
}
