/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { TrackingKeysSelectionView } from '../../View/MetaData/Exercise/View/TrackingKeysSelectionView';

export type Props = {
  filter: ExerciseFilter;
};

@observer
export class ExerciseVariationFilterTrackingKeysExcluded extends React.Component<Props> {
  @computed
  get value(): string[] {
    return this.props.filter.excludedTrackingKeys || [];
  }

  render() {
    return <TrackingKeysSelectionView label="Excluded Tracking Keys" container={this.value} />;
  }
}

// const ExerciseVariationsTab = withRouter(ExerciseVariationsTabComponent);
// export default ExerciseVariationsTab;
