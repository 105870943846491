/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { CoachWorkout } from '../../../../../Model/Coach/Program/Workout/CoachWorkout';
import { CoachWorkoutPhaseTable } from './CoachWorkoutPhaseTable';
import styled from '@emotion/styled';
import { WorkoutResponse } from '../../../../../Model/Coach/Script/WorkoutResponse';

export type CoachWorkoutViewProps = {
  workout: CoachWorkout | WorkoutResponse;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
`;

export const CoachWorkoutView: React.FC<CoachWorkoutViewProps> = observer(({ workout }: CoachWorkoutViewProps) => {
  return (
    <Container>
      {workout instanceof CoachWorkout
        ? workout.phases.map((phase) => <CoachWorkoutPhaseTable key={phase.phaseId} phase={phase} />)
        : workout.phases.map((phase) => <CoachWorkoutPhaseTable key={phase.phaseId} phase={phase} />)}
    </Container>
  );
});
