/**
 * Created by neo on 04.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Form, Input } from 'antd';

export type ModalAddKeyContentProps = {
  defaultNamespace?: string;
  onTranslationKeyChange?: (key: string) => void;
  onNamespaceChange?: (namspace: string) => void;
};

export const ModalAddKeyContent: React.FC<ModalAddKeyContentProps> = observer(
  ({ defaultNamespace, onNamespaceChange, onTranslationKeyChange }) => {
    const [translationKey, setTranslationKey] = useState('');
    const [namespace, setNamespace] = useState(defaultNamespace);

    const handleChangeKey = React.useCallback(
      ({ target: { value } }) => {
        setTranslationKey(value.trim());
        onTranslationKeyChange && onTranslationKeyChange(value.trim());
      },
      [onTranslationKeyChange],
    );

    const handleNamespaceChange = React.useCallback(
      ({ target: { value } }) => {
        setNamespace(value.trim());
        onNamespaceChange && onNamespaceChange(value.trim());
      },
      [onNamespaceChange],
    );

    return (
      <React.Fragment>
        <SingleColRow>
          <Form.Item label="Key" extra="No spaces">
            <Input onChange={handleChangeKey} value={translationKey} />
          </Form.Item>
        </SingleColRow>
        <SingleColRow>
          <Form.Item label="Namespace">
            <Input
              onChange={handleNamespaceChange}
              value={namespace}
              placeholder="coach-app or backend or studio-web etc..."
            />
          </Form.Item>
        </SingleColRow>
      </React.Fragment>
    );
  },
);
