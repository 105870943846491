/**
 * @flow
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Badge, FormGroup, Label } from 'reactstrap';
import { ReactTags } from '../../../../Components/ReactTags';
import { SuggestionEntry } from '../../../../Components/ReactTags/Suggestions';
import { BodyPartRegion } from '../../../../Model/BodyPart/BodyPartRegion';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';

export type Props = {
  bodyPart: BodyPartRegionAdmin;
  label?: string;
  allowIncludes?: boolean;
  onRemove?: (index: number) => any;
  disabled?: boolean;
};

interface TagEntry extends SuggestionEntry {
  bodyPart: BodyPartRegionAdmin;
}

@observer
export class BodyPartChildrenInput extends React.Component<Props> {
  @observable
  bodyParts: TagEntry[] = [];
  debounce: any;

  static defaultProps = {
    label: 'Children',
  };

  async load(query: string) {
    const result = await BodyPartRegionAdmin.find({ query, sort: 'defaultName,ASC', size: 6 });
    runInAction(() => {
      this.bodyParts = result.map((bodyPart) => ({
        id: bodyPart.id,
        bodyPart,
        name: `${bodyPart.defaultName} ${bodyPart.identifier}`,
      }));
    });
  }

  handleInputChange = (input: string) => {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.load(input);
    }, 300);
  };

  handleDelete = (index: number) => {
    const {
      props: {
        bodyPart: { childrenIds, children },
      },
    } = this;
    const id = childrenIds.splice(index, 1);
    if (children && id.length > 0) {
      const otherIndex = children.findIndex((b) => b.id === id[0]);
      if (otherIndex !== -1) {
        children.splice(otherIndex, 1);
      }
    }
  };

  handleAddition = async (tag: TagEntry) => {
    console.log('Adding', tag, this.props.bodyPart.children);
    this.props.bodyPart.childrenIds.push(tag.bodyPart.id);
    this.props.bodyPart.children && this.props.bodyPart.children.push(tag.bodyPart);
  };

  @computed
  get tags(): TagEntry[] {
    return (this.props.bodyPart.children || []).map((bodyPart) => ({
      id: bodyPart.id,
      bodyPart,
      name: bodyPart.defaultName,
    }));
  }

  @computed
  get suggestions(): TagEntry[] {
    const { tags } = this;
    return this.bodyParts.filter((e) => tags.findIndex((t) => t.id === e.bodyPart.id) === -1);
  }

  render() {
    const {
      suggestions,
      tags,
      props: { label, disabled },
    } = this;
    return (
      <FormGroup>
        <Label>{label}</Label>
        <React.Fragment>
          <br />
          {tags.map((t) => (
            <Badge key={t.id} color="primary">
              {t.name}
            </Badge>
          ))}
        </React.Fragment>
      </FormGroup>
    );
  }
}
