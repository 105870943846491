import { observable } from 'mobx';

export type AudioEncoding = 'LINEAR16' | 'MP3' | 'OGG_OPUS';

/**
 * Created by neo on 07.05.21.
 */

export type GoogleTtsAudioConfigJson = {
  audioEncoding: AudioEncoding;
  pitch: number;
  speakingRate: number;
  volumeGainDb: number;
};

export class GoogleTtsAudioConfig {
  @observable
  audioEncoding: AudioEncoding = 'MP3';
  @observable
  pitch: number = -5.2;
  @observable
  speakingRate: number = 1.0;
  /**
   Optional. Input only. Volume gain (in dB) of the normal native volume
   supported by the specific voice, in the range [-96.0, 16.0]. If unset, or
   set to a value of 0.0 (dB), will play at normal native signal amplitude. A
   value of -6.0 (dB) will play at approximately half the amplitude of the
   normal native signal amplitude. A value of +6.0 (dB) will play at
   approximately twice the amplitude of the normal native signal amplitude.
   Strongly recommend not to exceed +10 (dB) as there's usually no effective
   increase in loudness for any value greater than that.

   double volume_gain_db = 4 [(.google.api.field_behavior) = INPUT_ONLY, (.google.api.field_behavior) = OPTIONAL];
   */
  @observable
  volumeGainDb: number = 0.0;

  constructor(json?: Partial<GoogleTtsAudioConfigJson>) {
    if (json) {
      this.audioEncoding = json.audioEncoding ?? 'MP3';
      this.pitch = json.pitch ?? -5.2;
      this.speakingRate = json.speakingRate ?? 1.0;
    }
  }

  toJS(): GoogleTtsAudioConfigJson {
    return {
      audioEncoding: this.audioEncoding,
      pitch: this.pitch,
      speakingRate: this.speakingRate,
      volumeGainDb: this.volumeGainDb,
    };
  }
}
