import { Stage } from './Stage';
import { DataSignal } from '../Signal/DataSignal';

export class CollectStage extends Stage<any> {
  process() {
    const data = this.dataSignals.reduce((arr, signal) => arr.concat([signal.data]), new Array<DataSignal>());
    this.processNext(new DataSignal(data, 0));
  }

  get type(): string {
    return 'collect';
  }
}
