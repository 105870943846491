/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { StyledLayer } from './WorkoutTemplateContainer';
import { PipelineBuilder } from '../../../../../Model/Coach/PipelineBuilder';
import { StageModelBuilder } from '../../../WorkoutScript/View/Flow/Model/StageModelBuilder';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { WorkoutPhaseTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutPhaseTemplate';
import { Pipeline } from '../../../../../Model/Coach/Pipeline';
import { ParamCondition } from '../../../../../Model/Coach/SuperMacro/ParamCondition';

export type PhaseTemplateDropZoneProps = {
  index: number;
  onDrop?: (phaseTemplate: WorkoutPhaseTemplate, index: number) => any;
  onChangeIndex?: (phaseTemplateId: string, index: number) => any;
};

const DropZone = styled.div`
  border: 2px dashed lightgray;
  height: 50px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
`;

export const PhaseTemplateDropZone: React.FC<PhaseTemplateDropZoneProps> = observer(
  ({ onDrop, onChangeIndex, index }) => {
    const [over, setOver] = useState(false);

    const handleDragOver = React.useCallback((event) => {
      console.log('dragOver');
      event.preventDefault();
      setOver(true);
    }, []);
    const handleDragLeave = React.useCallback((event) => {
      console.log('dragLEave');
      event.preventDefault();
      setOver(false);
    }, []);

    const handleDrop = React.useCallback(
      (event) => {
        console.log('onDrop', event);
        const phaseTemplateId = event.dataTransfer.getData('phase-template-id');
        if (phaseTemplateId) {
          onChangeIndex && onChangeIndex(phaseTemplateId, index);
        } else {
          const pipeline = JSON.parse(event.dataTransfer.getData('phase-template-data'));
          console.log('new pipeline', pipeline, index);
          const phaseTemplate = new WorkoutPhaseTemplate({
            scriptId: pipeline.id,
            pipeline,
            params: [{}],
          });
          onDrop && onDrop(phaseTemplate, index);
        }
        setOver(false);
      },
      [index, onDrop, onChangeIndex],
    );

    return (
      <DropZone
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragLeave}
        onDrop={handleDrop}
        style={{ border: over ? '2px dashed black' : '2px dashed lightgray' }}
      >
        Drop here
      </DropZone>
    );
  },
);
