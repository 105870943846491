/**
 * Created by neo on 06.02.17.
 */

import { observer } from 'mobx-react';
import * as React from 'react';
import { Badge, Button } from 'reactstrap';
import { Exercise } from '../../Model/Exercise/Exercise';
import { TableRowMedia } from '../TableRowMedia';
import { ComponentColors } from '../ComponentColors';

export type Props = {
  variation: Exercise;
  onClick?: (exercise: Exercise) => any;
  query?: string;
};

@observer
export class ExerciseVariationTableRow extends React.Component<Props> {
  render() {
    const {
      props: { variation, query },
    } = this;
    return (
      <tr style={variation.archived ? { color: 'red' } : undefined}>
        <td>
          <TableRowMedia medias={variation.medias?.slice()} />
        </td>
        <td>{variation.name || '<no name specified>'}</td>
        <td>
          {variation.equipmentTypes.map((e) => (
            <Badge key={e} color="primary">
              {e}
            </Badge>
          ))}
        </td>
        <td>
          {(variation.bodyParts || []).map((e) => (
            <React.Fragment key={e.id}>
              <Badge color="warning">{e.name}</Badge>
              <br />
            </React.Fragment>
          ))}
        </td>
        <td>
          {variation.tags
            .slice()
            .sort()
            .map((e: string, index: number) => (
              <React.Fragment key={e}>
                <Badge color={ComponentColors[index % ComponentColors.length]} pill={true}>
                  {e}
                </Badge>
                <br />
              </React.Fragment>
            ))}
        </td>
        <td>
          {(variation.trackingParameters || []).map((e) => (
            <React.Fragment key={e}>
              <Badge color="danger">{e}</Badge>
              <br />
            </React.Fragment>
          ))}
        </td>
        <td>
          {variation.force ? (
            <React.Fragment>
              <Badge color="primary">{variation.force}</Badge>
              <br />
            </React.Fragment>
          ) : null}
          <Badge color="secondary">{variation.mechanics}</Badge>
          <br />
          <Badge color="success">{variation.utility}</Badge>
        </td>
        <td>{variation.type}</td>
      </tr>
    );
  }
}
