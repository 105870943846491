/**
 * Created by neo on 18.12.20.
 */
import { observable } from 'mobx';

export type CaloricBreakdownJson = {
  percentProtein: number;
  percentFat: number;
  percentCarbs: number;
};

export class CaloricBreakdown {
  @observable
  percentProtein: number = 0.0;
  @observable
  percentFat: number = 0.0;
  @observable
  percentCarbs: number = 0.0;

  constructor(json?: Partial<CaloricBreakdownJson>) {
    if (json) {
      this.percentCarbs = json.percentCarbs ?? 0;
      this.percentFat = json.percentFat ?? 0;
      this.percentProtein = json.percentProtein ?? 0;
    }
  }

  toJS(): CaloricBreakdownJson {
    return {
      percentCarbs: this.percentCarbs,
      percentFat: this.percentFat,
      percentProtein: this.percentProtein,
    };
  }
}
