/**
 * Created by neo on 27.06.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { ExploreFaqAnswerTabContent } from './ExploreFaqAnswerTabContent';
import { ExploreFaqRequestsTabContent } from './ExploreFaqRequestsTabContent';
import { ExploreFaqAnsweredTabContent } from './ExploreFaqAnsweredTabContent';
import { ExploreFaqRequest } from '../../../../Model/Explore/ExploreFaqRequest';

export type ExploreFaqIndexScreenProps = {};

export const ExploreFaqIndexScreen: React.FC<ExploreFaqIndexScreenProps> = observer((props) => {
  const [activeTab, setActiveTab] = useState('answers');
  const [requestsCount, setRequestsCount] = useState(0);

  useEffect(() => {
    ExploreFaqRequest.count({ answered: false }).then((count) => setRequestsCount(count));
  }, []);

  const handleChangeTab = (newTab: string) => setActiveTab(newTab);

  return (
    <Tabs activeKey={activeTab} onChange={handleChangeTab} destroyInactiveTabPane={true}>
      <Tabs.TabPane tab="Answers" key="answers">
        <ExploreFaqAnswerTabContent />
      </Tabs.TabPane>
      <Tabs.TabPane tab={`Requests (${requestsCount})`} key="requests">
        <ExploreFaqRequestsTabContent />
      </Tabs.TabPane>
      <Tabs.TabPane tab={`Answered Requests`} key="requests-answered">
        <ExploreFaqAnsweredTabContent />
      </Tabs.TabPane>
    </Tabs>
  );
});
