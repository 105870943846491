export const paramSort = [
  'limit',
  'sets',
  'rounds',
  'duration',
  'repetitions',
  'duration',
  'break',
  'alternativeBreak',
  'weight',
  'warmupDuration',
  'alternativeWarmupDuration',
];
