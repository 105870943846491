import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { CollectNodeModel } from './CollectNodeModel';
import { CollectNodeWidget } from './CollectNodeWidget';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { CollectStage } from '../../../../../../../Model/Coach/Stages/CollectStage';

export class CollectNodeModelFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('collect');
  }

  generateReactWidget(event): JSX.Element {
    return <CollectNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new CollectNodeModel(new CollectStage(new Pipeline()));
  }
}
