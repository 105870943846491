/**
 * Created by katarinababic on 16.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { NativeFeedSideDrawerComponentItemType } from './NativeFeedSideDrawerComponents';
import Title from '@mui/icons-material/Title';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import AddLinkIcon from '@mui/icons-material/AddLink';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Image from '@mui/icons-material/Image';
import Video from '@mui/icons-material/Movie';
import Link from '@mui/icons-material/Link';
import { ThemeConfig } from '../../../../../Config/Theme';

export type NativeFeedSideDrawerComponentDraggableItemSwitchProps = {
  item: NativeFeedSideDrawerComponentItemType;
};

export const NativeFeedSideDrawerComponentDraggableItemSwitch: React.FC<NativeFeedSideDrawerComponentDraggableItemSwitchProps> =
  observer(({ item }) => {
    const { classes } = useStyles();

    switch (item.type) {
      case 'Image':
        return <Image className={classes.icon} />;
      case 'Video':
        return <Video className={classes.icon} />;
      case 'Heading':
        return <Title className={classes.icon} />;
      case 'ItalicHeading':
        return <FormatItalicIcon className={classes.icon} />;
      case 'Paragraph':
        return <TextFieldsIcon className={classes.icon} />;
      case 'ItalicParagraph':
        return <FormatItalicIcon className={classes.icon} />;
      case 'Caption':
        return <Title className={classes.icon} />;
      case 'Summary':
        return <SummarizeIcon className={classes.icon} />;
      case 'InnerBoldText':
        return <FormatBoldIcon className={classes.icon} />;
      case 'InnerLinkText':
        return <AddLinkIcon className={classes.icon} />;
      case 'BulletPoint':
        return <FormatListBulletedIcon className={classes.icon} />;
      case 'NumberPoint':
        return <FormatListNumberedIcon className={classes.icon} />;
      case 'Button':
      case 'SecondaryButton':
        return <Link className={classes.icon} />;
      default:
        return null;
    }
  });

const useStyles = makeStyles()(() => ({
  icon: {
    color: ThemeConfig.Colors.white,
  },
}));
