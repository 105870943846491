/**
 *
 */
import * as React from 'react';
import { Container } from 'reactstrap';
import { SingleColRow } from './Components/SingleColRow';
import { Empty } from 'antd';
import { observer } from 'mobx-react';

export type NoMatchProps = {};

export const NoMatch: React.FC<NoMatchProps> = observer(() => {
  return (
    <React.Fragment>
      <Container>
        <SingleColRow>
          <Empty description="Page Not Found" />
        </SingleColRow>
      </Container>
    </React.Fragment>
  );
});
