import { Stage } from './Stages/Stage';
import { DistinctStage } from './Stages/DistinctStage';
import { FetchStage } from './Stages/FetchStage';
import { FlattenStage } from './Stages/FlattenStage';
import { GroupStage } from './Stages/GroupStage';
import { LimitStage } from './Stages/LimitStage';
import { MapStage } from './Stages/MapStage';
import { PhaseStage } from './Stages/PhaseStage';
import { RandomStage } from './Stages/RandomStage';
import { SortStage } from './Stages/SortStage';
import { SwitchStage } from './Stages/SwitchStage';
import { BodyPartRegion } from '../BodyPart/BodyPartRegion';
import { ReverseStage } from './Stages/ReverseStage';
import { ExerciseBlockStage } from './Stages/ExerciseBlockStage';
import { GraphSortingStage } from './Stages/GraphSortingStage';
import { BodyPartClusterSortStage } from './Stages/BodyPartClusterSortStage';
import { ConcatStage } from './Stages/ConcatStage';
import { Pipeline } from './Pipeline';
import { PipelineStage } from './Stages/PipelineStage';
import { WorkoutStage } from './Stages/WorkoutStage';
import { AllStage } from './Stages/Logical/AllStage';
import { OrStage } from './Stages/Logical/OrStage';
import { NotInStage } from './Stages/Logical/NotInStage';
import { NotEqualsStage } from './Stages/Logical/NotEqualsStage';
import { LessThanEqualsStage } from './Stages/Logical/LessThanEqualsStage';
import { LessThanStage } from './Stages/Logical/LessThanStage';
import { InStage } from './Stages/Logical/InStage';
import { GreaterThanStage } from './Stages/Logical/GreaterThanStage';
import { GreaterThanEqualsStage } from './Stages/Logical/GreaterThanEqualsStage';
import { EqualsStage } from './Stages/Logical/EqualsStage';
import { AndStage } from './Stages/Logical/AndStage';
import { NoopStage } from './Stages/NoopStage';
import { InputSortStage } from './Stages/InputSortStage';
import { LimitFieldStage } from './Stages/LimitField';
import { SinkStage } from './Stages/SinkStage';
import { CollectStage } from './Stages/CollectStage';
import { DuplicateStage } from './Stages/DuplicateStage';

export type StageDefinition = {
  id?: string;
  type: string;
  config?: any;
};
export type PipelineDefinition = StageDefinition[];

export class PipelineBuilder {
  static async compile(pipeline: Pipeline, json?: PipelineDefinition): Promise<Stage<any>[]> {
    if (json) {
      console.log('compiling', json);
      return Promise.all(json.map((def) => PipelineBuilder.createStageCompiled(pipeline, def)));
    }
    return [];
  }

  static deserialize(pipeline: Pipeline, json?: PipelineDefinition): Stage<any>[] {
    if (json) {
      console.log('deserializing', json);
      return json.map((def) => PipelineBuilder.createStage(pipeline, def));
    }
    return [];
  }

  static createStage(pipeline: Pipeline, definition: StageDefinition): Stage<any> {
    switch (definition.type) {
      case 'bodyPartClusterSort':
        return new BodyPartClusterSortStage(pipeline, definition);
      case 'collect':
        return new CollectStage(pipeline, definition);
      case 'concat':
        return new ConcatStage(pipeline, definition);
      case 'distinct':
        return new DistinctStage(pipeline, definition);
      case 'duplicate':
        return new DuplicateStage(pipeline, definition);
      case 'exerciseBlock':
        return new ExerciseBlockStage(pipeline, definition);
      case 'fetch':
        return new FetchStage(pipeline, definition);
      case 'flatten':
        return new FlattenStage(pipeline, definition);
      case 'graphSort':
        return new GraphSortingStage(pipeline, definition);
      case 'group':
        return new GroupStage(pipeline, definition);
      case 'inputSort':
        return new InputSortStage(pipeline, definition);
      case 'limit':
        return new LimitStage(pipeline, definition);
      case 'limitField':
        return new LimitFieldStage(pipeline, definition);
      case 'map':
        return new MapStage(pipeline, definition);
      case 'noop':
        return new NoopStage(pipeline, definition);
      case 'phase':
        return new PhaseStage(pipeline, definition);
      case 'pipeline':
        return new PipelineStage(pipeline, definition);
      case 'random':
        return new RandomStage(pipeline, definition);
      case 'reverse':
        return new ReverseStage(pipeline, definition);
      case 'sink':
        return new SinkStage(pipeline, definition);
      case 'sort':
        return new SortStage(pipeline, definition);
      case 'switch':
        return new SwitchStage(pipeline, definition);
      case 'workout':
        return new WorkoutStage(pipeline, definition);
      case 'logical:all':
        return new AllStage(pipeline, definition);
      case 'logical:and':
        return new AndStage(pipeline, definition);
      case 'logical:eq':
        return new EqualsStage(pipeline, definition);
      case 'logical:gte':
        return new GreaterThanEqualsStage(pipeline, definition);
      case 'logical:gt':
        return new GreaterThanStage(pipeline, definition);
      case 'logical:in':
        return new InStage(pipeline, definition);
      case 'logical:lt':
        return new LessThanStage(pipeline, definition);
      case 'logical:lte':
        return new LessThanEqualsStage(pipeline, definition);
      case 'logical:neq':
        return new NotEqualsStage(pipeline, definition);
      case 'logical:nin':
        return new NotInStage(pipeline, definition);
      case 'logical:or':
        return new OrStage(pipeline, definition);
      default:
        return new NoopStage(pipeline, definition);
    }
  }

  private static async createStageCompiled(pipeline: Pipeline, definition: StageDefinition): Promise<Stage<any>> {
    switch (definition.type) {
      case 'group':
        return this.createGroupStageCompiled(pipeline, definition);
      case 'pipeline':
        return new PipelineStage(
          pipeline,
          Object.assign(definition, {
            config: {
              pipeline: definition.config.pipelineId
                ? await Pipeline.getCompiled(definition.config.pipelineId)
                : undefined,
              executionMode: definition.config.executionMode,
              pipelineId: definition.config.pipelineId,
              tags: definition.config.tags,
              params: definition.config.params,
            },
          }),
        );
      case 'phase':
        return new PhaseStage(
          pipeline,
          Object.assign(definition, {
            config: Object.assign(definition.config, {
              substitutes: await Promise.all(
                (definition.config.substitutes ?? []).map((sub) =>
                  Pipeline.getCompiled(sub.pipelineId).then((newPipeline) => ({
                    pipeline: newPipeline, // to not shadow pipeline
                    pipelineId: sub.pipelineId,
                    tags: sub.tags,
                    params: sub.params,
                  })),
                ),
              ),
            }),
          }),
        );
      default:
        return this.createStage(pipeline, definition);
    }
  }

  private static async createGroupStageCompiled(pipeline: Pipeline, definition: StageDefinition): Promise<Stage<any>> {
    const groupParams = Object.assign({}, definition.config ?? {});
    if (definition.config.bodyPartGroups) {
      groupParams.bodyPartGroups = new Array(definition.config.bodyPartGroups.length);
      for (let i = 0, l = definition.config.bodyPartGroups.length; i < l; i++) {
        const group = definition.config.bodyPartGroups[i];
        let resolved: Array<string> = [];
        for (const entry of group) {
          const result = await BodyPartRegion.flatten(entry);
          resolved = resolved.concat(result);
        }
        groupParams.bodyPartGroups[i] = resolved;
      }
    }

    console.log('compiling group', definition);

    return new GroupStage(pipeline, Object.assign(definition, { config: groupParams }));
  }
}
