import { RandomNodeModel } from './Random/RandomNodeModel';
import { Stage } from '../../../../../../Model/Coach/Stages/Stage';
import { DefaultStageNodeModel } from './Default/DefaultStageNodeModel';
import { BodyPartClusterSortModel } from './BodyPartClusterSort/BodyPartClusterSortModel';
import { ConcatModel } from './Concat/ConcatModel';
import { DistinctNodeModel } from './Distinct/DistinctNodeModel';
import { ExerciseBlockNodeModel } from './ExerciseBlock/ExerciseBlockNodeModel';
import { FetchNodeModel } from './Fetch/FetchNodeModel';
import { FlattenNodeModel } from './Flatten/FlattenNodeModel';
import { GraphSortNodeModel } from './GraphSort/GraphSortNodeModel';
import { GroupNodeModel } from './Group/GroupNodeModel';
import { LimitNodeModel } from './Limit/LimitNodeModel';
import { MapNodeModel } from './Map/MapNodeModel';
import { PhaseNodeModel } from './Phase/PhaseNodeModel';
import { PipelineNodeModel } from './Pipeline/PipelineNodeModel';
import { ReverseNodeModel } from './Reverse/ReverseNodeModel';
import { SortNodeModel } from './Sort/SortNodeModel';
import { WorkoutNodeModel } from './Workout/WorkoutNodeModel';
import { SwitchNodeModel } from './Switch/SwitchNodeModel';
import { AllNodeModel } from './Logical/All/AllNodeModel';
import { AndNodeModel } from './Logical/And/AndNodeModel';
import { EqualsNodeModel } from './Logical/Equals/EqualsNodeModel';
import { GreaterThanEqualsNodeModel } from './Logical/GreaterThanEquals/GreaterThanEqualsNodeModel';
import { GreaterThanNodeModel } from './Logical/GreaterThan/GreaterThanNodeModel';
import { InNodeModel } from './Logical/In/InNodeModel';
import { LessThanEqualsNodeModel } from './Logical/LessThanEquals/LessThanEqualsNodeModel';
import { LessThanNodeModel } from './Logical/LessThan/LessThanNodeModel';
import { NotEqualsNodeModel } from './Logical/NotEquals/NotEqualsNodeModel';
import { NotInNodeModel } from './Logical/NotIn/NotInNodeModel';
import { OrNodeModel } from './Logical/Or/OrNodeModel';
import { NoopNodeModel } from './Noop/NoopNodeModel';
import { InputSortNodeModel } from './InputSort/InputSortNodeModel';
import { LimitFieldNodeModel } from './LimitField/LimitFieldNodeModel';
import { SinkNodeModel } from './Sink/SinkNodeModel';
import { CollectNodeModel } from './Collect/CollectNodeModel';
import { DuplicateNodeModel } from './Duplicate/DuplicateNodeModel';

export class StageModelBuilder {
  static build(stage: Stage<any>) {
    const type = stage.type;
    switch (type) {
      case 'bodyPartClusterSort':
        return new BodyPartClusterSortModel(stage as any);
      case 'collect':
        return new CollectNodeModel(stage as any);
      case 'concat':
        return new ConcatModel(stage as any);
      case 'distinct':
        return new DistinctNodeModel(stage as any);
      case 'duplicate':
        return new DuplicateNodeModel(stage as any);
      case 'exerciseBlock':
        return new ExerciseBlockNodeModel(stage as any);
      case 'fetch':
        return new FetchNodeModel(stage as any);
      case 'flatten':
        return new FlattenNodeModel(stage as any);
      case 'graphSort':
        return new GraphSortNodeModel(stage as any);
      case 'group':
        return new GroupNodeModel(stage as any);
      case 'inputSort':
        return new InputSortNodeModel(stage as any);
      case 'limit':
        return new LimitNodeModel(stage as any);
      case 'limitField':
        return new LimitFieldNodeModel(stage as any);
      case 'map':
        return new MapNodeModel(stage as any);
      case 'noop':
        return new NoopNodeModel(stage as any);
      case 'phase':
        return new PhaseNodeModel(stage as any);
      case 'pipeline':
        return new PipelineNodeModel(stage as any);
      case 'reverse':
        return new ReverseNodeModel(stage as any);
      case 'sink':
        return new SinkNodeModel(stage as any);
      case 'sort':
        return new SortNodeModel(stage as any);
      case 'workout':
        return new WorkoutNodeModel(stage as any);
      case 'switch':
        return new SwitchNodeModel(stage as any);
      case 'logical:all':
        return new AllNodeModel(stage as any);
      case 'logical:and':
        return new AndNodeModel(stage as any);
      case 'logical:eq':
        return new EqualsNodeModel(stage as any);
      case 'logical:gte':
        return new GreaterThanEqualsNodeModel(stage as any);
      case 'logical:gt':
        return new GreaterThanNodeModel(stage as any);
      case 'logical:in':
        return new InNodeModel(stage as any);
      case 'logical:lte':
        return new LessThanEqualsNodeModel(stage as any);
      case 'logical:lt':
        return new LessThanNodeModel(stage as any);
      case 'logical:neq':
        return new NotEqualsNodeModel(stage as any);
      case 'logical:nin':
        return new NotInNodeModel(stage as any);
      case 'logical:or':
        return new OrNodeModel(stage as any);
      case 'random':
        return new RandomNodeModel(stage as any);
      default:
        return new DefaultStageNodeModel(stage as any);
    }
  }
}
