/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Tabs } from 'antd';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicTab } from './BasicTab';
import { SimpleRuleEditorTab } from './SimpleRuleEditorTab';
import { TimeConstraintTab } from './TimeConstraintTab';
import { ActivationFrequencyTab } from './ActivationFrequencyTab';
import { EarnModelTemplateRuleSimple } from '../../../../../Model/Engagement/EarnModelTemplateRuleSimple';
import { EarnModelTemplateRuleInstant } from '../../../../../Model/Engagement/EarnModelTemplateRuleInstant';
import { EarnModelTemplateRuleGrouped } from '../../../../../Model/Engagement/EarnModelTemplateRuleGrouped';
import { GroupedRuleEditorTab } from './GroupedRuleEditorTab';
import { EarnModelTemplateRuleStepped } from '../../../../../Model/Engagement/EarnModelTemplateRuleStepped';

export type EarnModelTemplateRuleEditModalProps = {
  template: EarnModelTemplate;
};

export const EarnModelTemplateRuleEditModal: React.FC<EarnModelTemplateRuleEditModalProps> = observer(
  ({ template }) => {
    const { ruleId, categoryId } = useParams<{ ruleId: string; categoryId: string }>();

    const [activeTab, setActiveTab] = useState('general');

    const category = template.categories.find((c) => c.id === categoryId);
    const rule = category?.rules.find((c) => c.id === ruleId);

    const history = useNavigate();

    const handleCancel = React.useCallback(() => {
      history(-1);
    }, [history]);

    const handleTabChange = React.useCallback((tab) => setActiveTab(tab), []);

    return (
      <Modal
        open={true}
        title={rule?.templateName ?? '<not found>'}
        onCancel={handleCancel}
        footer={[
          <Button key="1" onClick={handleCancel}>
            Save & Close
          </Button>,
        ]}
        width={1280}
      >
        {category && rule && (
          <React.Fragment>
            <Form layout="vertical">
              <Tabs activeKey={activeTab} animated={false} onChange={handleTabChange}>
                <Tabs.TabPane tab="General" key="general">
                  <BasicTab template={template} category={category} rule={rule} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Activation Frequencies" key="frequency">
                  <ActivationFrequencyTab template={template} category={category} rule={rule} />
                </Tabs.TabPane>
                {(rule instanceof EarnModelTemplateRuleSimple ||
                  rule instanceof EarnModelTemplateRuleGrouped ||
                  rule instanceof EarnModelTemplateRuleStepped ||
                  rule instanceof EarnModelTemplateRuleInstant) && (
                  <Tabs.TabPane tab="Time Constraints" key="time_constraints">
                    <TimeConstraintTab template={template} category={category} rule={rule} />
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="Rules" key="rules">
                  {(rule instanceof EarnModelTemplateRuleSimple ||
                    rule instanceof EarnModelTemplateRuleInstant ||
                    rule instanceof EarnModelTemplateRuleStepped) && (
                    <SimpleRuleEditorTab template={template} category={category} rule={rule} />
                  )}
                  {rule instanceof EarnModelTemplateRuleGrouped && (
                    <GroupedRuleEditorTab template={template} category={category} rule={rule} />
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </React.Fragment>
        )}
      </Modal>
    );
  },
);
