/**
 * Created by neo on 16.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../Model/Gym/Gym';
import { Form } from 'antd';
import { ActivityPopularityWeekly } from './ActivityPopularityWeekly';
import { HourDistribution } from './HourDistribution';
import { SelectContentDaily } from './SelectContentDaily';
import { SelectContentAggregated } from './SelectContentAggregated';
import { SelectContentWeekly } from './SelectContentWeekly';
import { SelectContentHourDistribution } from './SelectContentHourDistribution';
import { ActivityWeekDayDistribution } from './ActivityWeekDayDistribution';
import { ActivityWeekDayHourStats } from './ActivityWeekDayHourStats';
import { ActivityUserWeekDayHourStats } from './ActivityUserWeekDayHourStats';
import { Col, Row } from 'reactstrap';
import { TotalCompletedActivities } from './TotalCompletedActivities';
import { DailyUserActivitiesStats } from './DailyUserActivitiesStats';
import { WeeklyUserActivitiesStats } from './WeeklyUserActivitiesStats';
import { MonthlyUserActivitiesStats } from './MonthlyUserActivitiesStats';
import { UserActivityDistribution } from './UserActivityDistribution';
import { ActivityPopularityDaily } from './ActivityPopularityDaily';

export type UserActivityTabProps = {
  gym?: Gym;
};

export const UserActivityTab: React.FC<UserActivityTabProps> = observer(({ gym }) => {
  return (
    <React.Suspense fallback="Loading...">
      <Form layout="vertical">
        <Row>
          <Col md={6}>
            <DailyUserActivitiesStats gym={gym} />
          </Col>
          <Col md={6}>
            <WeeklyUserActivitiesStats gym={gym} />
          </Col>
          <Col md={6}>
            <MonthlyUserActivitiesStats gym={gym} />
          </Col>
          <Col md={6}>
            <UserActivityDistribution gym={gym} />
          </Col>
          <Col md={6}>
            <ActivityPopularityDaily gym={gym} />
          </Col>
          <Col md={6}>
            <ActivityPopularityWeekly gym={gym} />
          </Col>
          <Col md={6}>
            <HourDistribution gym={gym} />
          </Col>
          <Col md={6}>
            <ActivityWeekDayDistribution gym={gym} />
          </Col>
          <Col md={6}>
            <ActivityWeekDayHourStats gym={gym} />
          </Col>
          <Col md={6}>
            <ActivityUserWeekDayHourStats gym={gym} />
          </Col>
          <Col md={6}>
            <SelectContentDaily gym={gym} />
          </Col>
          <Col md={6}>
            <SelectContentWeekly gym={gym} />
          </Col>
          <Col md={6}>
            <SelectContentAggregated gym={gym} />
          </Col>
          <Col md={6}>
            <SelectContentHourDistribution gym={gym} />
          </Col>
          <Col md={6}>
            <TotalCompletedActivities gym={gym} />
          </Col>
        </Row>
      </Form>
    </React.Suspense>
  );
});
