/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
  Line,
  BarProps,
} from 'recharts';
import { ChartColors } from '../ChartColors';

export type ActivityConversionChartProps = Pick<BarProps, 'stackId'> & {
  data: AnalyticsRow[];
};

export const ActivityConversionChart: React.FC<ActivityConversionChartProps> = observer(({ data, stackId }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart height={250} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date_key" />
        <YAxis yAxisId="left" label={{ value: 'User Count', position: 'insideTopLeft', dy: -25 }} />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickLine={false}
          axisLine={false}
          label={{ value: 'Line Charts', position: 'insideTopRight', dy: -25 }}
        />
        <Tooltip />
        <Legend />

        <Bar yAxisId="left" type="monotone" dataKey="user_count" stackId={stackId} fill="#CE7537" />
        <Bar yAxisId="left" type="monotone" dataKey="select_user_count" stackId={stackId} fill="#D2A45F" />

        <Line yAxisId="left" dataKey="count" stroke={ChartColors[2]} />
        <Line yAxisId="left" dataKey="select_count" stroke={ChartColors[1]} connectNulls />
        <Line yAxisId="right" dataKey="avg_percentage_completed" stroke={ChartColors[3]} connectNulls />
        <Line yAxisId="right" dataKey="converted" stroke={ChartColors[4]} connectNulls />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
