import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { ExerciseTagsDefinition } from '../../../../../Model/Exercise/ExerciseTags';
import { TagCategory } from '../../../../../Components/TagCategory';
import { runInAction } from 'mobx';

export type ExerciseVariationTagsProps = {
  container: string[];
  disabled?: boolean;
  label?: string;
};

export const ExerciseVariationTags: React.FC<ExerciseVariationTagsProps> = observer(
  (props: ExerciseVariationTagsProps) => {
    const { container, disabled, label } = props;

    const store = useLocalStore(
      (source: ExerciseVariationTagsProps) => ({
        get tags(): any[] {
          return source.container.map((value) => ({ label: value, value }));
        },
      }),
      props,
    );

    const handleAddTag = (tag: string) => {
      container.push(tag);
    };

    const handleChange = (tags?: any[]) => {
      runInAction(() => {
        container.splice(0, container.length);
        (tags ?? []).map((t) => t.value).forEach((t) => container.push(t));
      });
    };

    const getColor = (category: string) => {
      switch (category) {
        case 'level':
          return 'magenta';
        case 'focus':
          return 'green';
        default:
          return 'purple';
      }
    };

    return (
      <FormGroup>
        <Label>{label ?? 'Tags'}</Label>
        <CreatableSelect isClearable isMulti onChange={handleChange as any} value={store.tags} isDisabled={disabled} />
        {!disabled ? (
          <React.Fragment>
            {Array.from(ExerciseTagsDefinition.entries()).map(([key, entry], index: number) => (
              <TagCategory
                key={key}
                category={key}
                color={getColor(key)}
                tags={container}
                definition={ExerciseTagsDefinition}
                onAddTag={handleAddTag}
              />
            ))}
          </React.Fragment>
        ) : null}
      </FormGroup>
    );
  },
);
