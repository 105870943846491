import { RecipeIngredient, RecipeIngredientJson } from './RecipeIngredient';
import { NutritionInformation, NutritionInformationJson } from '../Ingredient/NutritionInformation';
import { RecipeInstruction, RecipeInstructionJson } from './RecipeInstruction';
import { computed, observable, toJS } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { v4 as UUID } from 'uuid';
import { RecipeSearchParams, SeasonType } from './RecipeSearchParams';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { Media, MediaJson } from '../../Media/Media';

/**
 * Created by neo on 18.12.20.
 */

export type RecipeJson = LocalizedArrayEntityJson & {
  archived?: boolean;
  spoonacularId: number;
  veryHealthy?: boolean;
  cheap?: boolean;
  veryPopular?: boolean;
  sustainable?: boolean;
  weightWatcherSmartPoints: number;
  healthScore: number;
  creditsText?: string;
  license?: string;
  sourceName?: string;
  pricePerServing: number;
  ingredients: RecipeIngredientJson[];
  readyInMinutes: number;
  servings: number;
  sourceUrl?: string;
  image?: MediaJson;
  nutrition?: NutritionInformationJson;
  cuisines: string[];
  dishTypes: string[];
  diets: string[];
  occasions: string[];
  seasons: SeasonType[];
  instructions: RecipeInstructionJson[];
  spoonacularSourceUrl?: string;
};

export class Recipe extends LocalizedArrayEntity {
  @observable
  id = UUID();
  @observable
  archived?: boolean;
  @observable
  spoonacularId = 0;
  @observable
  veryHealthy?: boolean;
  @observable
  cheap?: boolean;
  @observable
  veryPopular?: boolean;
  @observable
  sustainable?: boolean;
  @observable
  weightWatcherSmartPoints: number = 0;
  @observable
  healthScore: number = 0;
  @observable
  creditsText?: string;
  @observable
  license?: string;
  @observable
  sourceName?: string;
  @observable
  pricePerServing: number = 0;
  @observable
  ingredients: RecipeIngredient[] = [];
  @observable
  readyInMinutes: number = 0;
  @observable
  servings: number = 1;
  @observable
  sourceUrl?: string;
  @observable
  image?: Media;
  @observable
  nutrition = new NutritionInformation();
  @observable
  cuisines: string[] = [];
  @observable
  dishTypes: string[] = [];
  @observable
  diets: string[] = [];
  @observable
  occasions: string[] = [];
  @observable
  seasons: SeasonType[] = [];
  @observable
  instructions: RecipeInstruction[] = [new RecipeInstruction()];
  @observable
  spoonacularSourceUrl?: string;

  constructor(json?: Partial<RecipeJson>) {
    super(json);
    if (json) {
      this.id = json.id ?? UUID();
      this.archived = json.archived ?? false;
      this.spoonacularId = json.spoonacularId ?? 0;
      this.veryHealthy = json.veryHealthy;
      this.cheap = json.cheap;
      this.veryPopular = json.veryPopular;
      this.sustainable = json.sustainable;
      this.weightWatcherSmartPoints = json.weightWatcherSmartPoints ?? 0;
      this.healthScore = json.healthScore ?? 0;
      this.creditsText = json.creditsText;
      this.license = json.license;
      this.sourceName = json.sourceName;
      this.pricePerServing = json.pricePerServing ?? 0;
      this.ingredients = (json.ingredients ?? []).map((i) => new RecipeIngredient(i));
      this.readyInMinutes = json.readyInMinutes ?? 0;
      this.servings = json.servings ?? 1;
      this.sourceUrl = json.sourceUrl;
      this.image = json.image ? new Media(json.image) : undefined;
      this.nutrition = new NutritionInformation(json.nutrition);
      this.cuisines = json.cuisines ?? [];
      this.dishTypes = json.dishTypes ?? [];
      this.diets = json.diets ?? [];
      this.occasions = json.occasions ?? [];
      this.seasons = json.seasons ?? [];
      this.instructions = json.instructions?.map((i) => new RecipeInstruction(i)) ?? [new RecipeInstruction()];
      this.spoonacularSourceUrl = json.spoonacularSourceUrl;
    }
  }

  toJS(): RecipeJson {
    return Object.assign(super.toJS(), {
      archived: this.archived,
      spoonacularId: this.spoonacularId,
      veryHealthy: this.veryHealthy,
      cheap: this.cheap,
      veryPopular: this.veryPopular,
      sustainable: this.sustainable,
      weightWatcherSmartPoints: this.weightWatcherSmartPoints,
      healthScore: this.healthScore,
      creditsText: this.creditsText,
      license: this.license,
      sourceName: this.sourceName,
      pricePerServing: this.pricePerServing,
      ingredients: this.ingredients.map((i) => i.toJS()),
      readyInMinutes: this.readyInMinutes,
      servings: this.servings,
      sourceUrl: this.sourceUrl,
      image: this.image?.toJS(),
      nutrition: this.nutrition.toJS(),
      cuisines: toJS(this.cuisines),
      dishTypes: toJS(this.dishTypes),
      diets: toJS(this.diets),
      occasions: toJS(this.occasions),
      seasons: toJS(this.seasons),
      instructions: this.instructions.map((i) => i.toJS()),
      spoonacularSourceUrl: this.spoonacularSourceUrl,
    });
  }

  save(): Promise<Recipe> {
    return HttpBackend.post(`/diet/admin/recipes`, this.toJS()).then(() => this);
  }

  archive(): Promise<Recipe> {
    return HttpBackend.delete(`/diet/admin/recipes/${this.id}`).then(() => this);
  }

  @computed
  get isValid(): boolean {
    return (
      this.ingredients.length > 1 &&
      (this.instructions[0]?.steps.length ?? 0) > 1 &&
      this.ingredients.every((i) => i.description.length >= 4) &&
      this.readyInMinutes > 0
    );
  }

  static search(params?: RecipeSearchParams): Promise<Recipe[]> {
    return HttpBackend.get(`/diet/admin/recipes`, params).then((result) => result.map((r) => new Recipe(r)));
  }

  static count(params?: RecipeSearchParams): Promise<number> {
    return HttpBackend.get(`/diet/recipes/count`, params);
  }

  static get(id: string): Promise<Recipe> {
    return HttpBackend.get(`/diet/admin/recipes/${id}`).then((result) => new Recipe(result));
  }
}
