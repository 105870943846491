/**
 * Created by neo on 08.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Text as MarkdownText } from 'mdast-util-from-markdown/lib';

export type MarkdownTextRendererProps = {
  content: MarkdownText;
  textStyle?: React.CSSProperties;
};

export const MarkdownTextRenderer: React.FC<MarkdownTextRendererProps> = observer(({ content, textStyle }) => {
  return <span style={textStyle}>{content.value}</span>;
});
