/**
 * Created by neo on 10.01.21.
 */
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { Media, MediaJson } from '../Media/Media';
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction, toJS,
} from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreCategory } from './ExploreCategory';
import { NotificationMessage, NotificationMessageJson } from './NotificationMessage';
import { ExploreContent, ExploreContentJson } from './ExploreContent';
import { ExploreEntryOrientation } from './ExploreEntryOrientation';

const defaultNotificationMessages = {
  de: {
    breathing: 'Zeit, deinen Geist mit einer kurzen Atemübung zu fokussieren',
    live: 'Eine Liveübertragung startet jetzt gleich!',
    meditation: 'Zeit, deinen Geist zu beruhigen und zu entspannen',
    recipe: 'Zeit, etwas Gesundes und Leckeres zu essen',
    workoutTemplate: 'Zeit, deine Arbeit kurz zu pausieren und etwas für deine Gesundheit zu tun.',
    workplace: 'Zeit, deine Arbeit kurz zu pausieren und etwas für deine Gesundheit zu tun.',
    yoga: 'Zeit, deine Kraft, Balance und Flexibilität mit einer Yoga-Session zu verbessern',
  },
  en: {
    breathing: 'Time to focus your mind with a quick breathing session',
    live: 'A live broadcast is going to start now!',
    meditation: 'Time to calm your mind and relax',
    recipe: 'Time to eat something healthy and delicious',
    workoutTemplate: 'Time to quickly pause work and do something for your health',
    workplace: 'Time to quickly pause work and do something for your health',
    yoga: 'Time to improve your strength, balance and flexibility with a yoga session',
  },
};

export type ExploreEntryJson = ExploreContentJson & {
  backgroundImage?: MediaJson;
  backgroundVideos: MediaJson[];
  notificationMessages: NotificationMessageJson[];
  quotes: LocalizedValueJson[];
  orientation: ExploreEntryOrientation;
  spotifyPlaylistLink?: string;
  availableLanguages: string[];
  exportDisabled: boolean;
  minVersion?: string;
  maxVersion?: string;
  subscriberCount: number;
};

export class ExploreEntry extends ExploreContent {
  @observable
  backgroundImage?: Media;
  @observable
  backgroundVideos: Media[] = [];
  @observable
  notificationMessages: NotificationMessage[] = [];
  @observable
  quotes: LocalizedValue[] = [];
  @observable
  orientation: ExploreEntryOrientation = 'landscape';
  @observable
  exportDisabled = false;
  @observable
  subscriberCount = 0;

  constructor(json?: Partial<ExploreEntryJson>) {
    super(Object.assign({}, json ?? {}, { contentType: 'entry' }));
    if (json) {
      this.backgroundImage = json.backgroundImage ? new Media(json.backgroundImage) : undefined;
      this.backgroundVideos = (json.backgroundVideos ?? [])
        .map((m) => new Media(m))
        .sort((a, b) => (a.language === 'de' ? -1 : 0));
      this.notificationMessages = (json.notificationMessages ?? []).map((n) => new NotificationMessage(n));
      this.quotes = (json.quotes ?? []).map((q) => new LocalizedValue(q));
      this.orientation = json.orientation ?? 'landscape';
      this.exportDisabled = json.exportDisabled ?? false;
      this.subscriberCount = json.subscriberCount ?? 0;
    }
  }

  toJS(newId: boolean = false): ExploreEntryJson {
    return Object.assign(super.toJS(newId), {
      backgroundImage: this.backgroundImage?.toJS(),
      backgroundVideos: this.backgroundVideos.map((n) => n.toJS()),
      notificationMessages: this.notificationMessages.map((n) => n.toJS()),
      quotes: this.quotes.map((q) => q.toJS()),
      orientation: this.orientation,
      availableLanguages: toJS(this.availableLanguages),
      exportDisabled: this.exportDisabled,
      subscriberCount: this.subscriberCount,
    });
  }

  save(): Promise<ExploreEntry> {
    return HttpBackend.post(`/coach/explore/admin?gymId=${this.gymId ?? ''}`, this.toJS()).then(() => this);
  }

  delete(): Promise<ExploreEntry> {
    return HttpBackend.delete(`/coach/explore/admin/${this.id}`).then(() => this);
  }

  getNotificationTitle(language: string, tags: string[]): string {
    return (
      this.notificationMessages.find((m) => m.tagCondition.matches(tags))?.title?.find((l) => l.lang === language)
        ?.value ?? this.getName(language)
    );
  }

  getNotificationMessage(language: string, tags: string[]): string {
    const defaultMessage = defaultNotificationMessages[language] ?? defaultNotificationMessages.de;
    return (
      this.notificationMessages.find((m) => m.tagCondition.matches(tags))?.message?.find((l) => l.lang === language)
        ?.value ??
      defaultMessage[this.type] ??
      ''
    );
  }

  @computed
  get tagString(): string {
    return this.tagConditions.map((t) => t.tagString).join(' | ');
  }
}
