/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { UserPropertiesDataEntry } from '../UsersOverviewTab/UserPropertiesStatsChart';
import { Form } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { ChartColors } from '../ChartColors';

export type SelectContentAggregatedChartProps = {
  data: AnalyticsRow[];
};

export const SelectContentAggregatedChart: React.FC<SelectContentAggregatedChartProps> = observer(({ data }) => {
  console.log('SelectContentChartAggregated', data);

  return (
    <Form.Item label="Select Content">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="value" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar type="monotone" dataKey="select_count" fill={ChartColors[0]} />
          <Bar type="monotone" dataKey="user_count" fill={ChartColors[1]} />
        </BarChart>
      </ResponsiveContainer>
    </Form.Item>
  );
});
