import { computed, observable } from 'mobx';
import { notUndefined } from '../../Utils/notUndefined';

export type BodyPartPositionSuperiorInferior = 'superior' | 'inferior';
export type BodyPartPositionAnteroposterior = 'anterior' | 'posterior';
export type BodyPartPositionMediolateral = 'medial' | 'lateral';
export type BodyPartPositionCranialCaudal = 'cranial' | 'caudal' | 'rostral';
export type BodyPartPositionProximalDistal = 'proximal' | 'distal';
export type BodyPartPositionSuperficialDeep = 'superficial' | 'deep';

export class BodyPartPosition {
  @observable
  superiorInferior?: BodyPartPositionSuperiorInferior = undefined;
  @observable
  anteriorPosterior?: BodyPartPositionAnteroposterior = undefined;
  @observable
  medialLateral?: BodyPartPositionMediolateral = undefined;
  @observable
  cranialCaudal?: BodyPartPositionCranialCaudal = undefined;
  @observable
  proximalDistal?: BodyPartPositionProximalDistal = undefined;
  @observable
  superficialDeep?: BodyPartPositionSuperficialDeep = undefined;

  constructor(json?: any) {
    if (json) {
      this.superiorInferior = json.superiorInferior;
      this.anteriorPosterior = json.anteriorPosterior;
      this.medialLateral = json.medialLateral;
      this.cranialCaudal = json.cranialCaudal;
      this.proximalDistal = json.proximalDistal;
      this.superficialDeep = json.superficialDeep;
    }
  }

  toJS(): any {
    return {
      superiorInferior: this.superiorInferior,
      anteriorPosterior: this.anteriorPosterior,
      medialLateral: this.medialLateral,
      cranialCaudal: this.cranialCaudal,
      proximalDistal: this.proximalDistal,
      superficialDeep: this.superficialDeep,
    };
  }

  @computed
  get positionString(): string {
    return (
      [
        this.superiorInferior,
        this.anteriorPosterior,
        this.medialLateral,
        this.cranialCaudal,
        this.proximalDistal,
        this.superficialDeep,
      ]
        .filter(notUndefined)
        .join(', ') ?? ''
    );
  }
}
