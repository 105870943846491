/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseFilterJointsCard } from './ExerciseFilterJointsCard';
import { observer } from 'mobx-react';

export type ExerciseVariationFilterJointsExcludedProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterJointsExcluded: React.FC<ExerciseVariationFilterJointsExcludedProps> = observer(
  ({ filter }: ExerciseVariationFilterJointsExcludedProps) => {
    return <ExerciseFilterJointsCard header="Excluded Joints" joints={filter.excludedJoints ?? []} />;
  },
);
