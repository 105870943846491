/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CampaignCalendarEntry } from '../../../../Model/CampaignCalendar/CampaignCalendarEntry';
import { Col, Row, Table } from 'reactstrap';
import { Badge, Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { CampaignCalendarEntryModalFilesTabTableRow } from './CampaignCalendarEntryModalFilesTabTableRow';
import { RcFile } from 'antd/es/upload';
import MediaService from '../../../../Services/MediaService';
import { runInAction } from 'mobx';
import { CampaignCalendarEntryAttachement } from '../../../../Model/CampaignCalendar/CampaignCalendarEntryAttachement';

export type CampaignCalendarEntryModalFilesTabProps = {
  campaign: CampaignCalendarEntry;
  onStartUpload?: () => void;
  onUploadComplete?: () => void;
};

export const CampaignCalendarEntryModalFilesTab: React.FC<CampaignCalendarEntryModalFilesTabProps> = observer(
  ({ campaign, onUploadComplete, onStartUpload }) => {
    const [uploading, setUploading] = useState(false);

    const uploadProgress = React.useRef(false);

    const beforeUpload = (singleFile: RcFile, fileList: RcFile[]) => {
      if (!uploadProgress.current) {
        uploadProgress.current = true;
        setUploading(true);
        onStartUpload?.();
        console.log('Uploading', fileList);
        Promise.all(fileList.map((file) => MediaService.uploadMedia(file)))
          .then((files) => {
            runInAction(() => {
              files
                .map((file) => new CampaignCalendarEntryAttachement({ file: file.toJS() }))
                .forEach((entry) => campaign.attachments.unshift(entry));
            });
          })
          .catch(() => {
            message.error('Failed to upload file');
          })
          .finally(() => (uploadProgress.current = false))
          .finally(() => setUploading(false))
          .then(() => onUploadComplete?.());
      }

      return false;
    };

    return (
      <Row>
        <Col>
          <h5>Files</h5>
        </Col>
        <Col xs="auto">
          <Upload beforeUpload={beforeUpload} multiple={true} disabled={uploading} showUploadList={false}>
            <Button icon={<UploadOutlined />} disabled={uploading}>
              Click to Upload or Drag & Drop here to this button
            </Button>
          </Upload>
          {uploading && (
            <div>
              <Badge status="processing" text="Uploading..." />
            </div>
          )}
        </Col>
        <Col xs={12}>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>File</th>
                <th>Language</th>
                <th>Template?</th>
                <th>Name</th>
                <th>Size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {campaign.attachments.map((attachment, index) => (
                <CampaignCalendarEntryModalFilesTabTableRow
                  key={attachment.id}
                  index={index}
                  campaign={campaign}
                  attachment={attachment}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  },
);
