import { observable } from 'mobx';

export class ActivityLogSource {
  /**
   * If coming from us it's `flinq`
   */
  @observable source?: string = 'flinq';
  /**
   * if external and nothing else provided it's the "workoutType" from the other system
   * if internal and workoutLog it's usually `workout`. If an activity it's `activity`
   */
  @observable sourceType?: string = undefined;
  /**
   * If internal and in case of sourceType = `workoutLog`. sourceId is the workoutLogId
   * If internal and it's an activity then sourceId = activityLogId
   * If external and nothing provided = random
   */
  @observable sourceId?: string = undefined;

  constructor(json?: any) {
    if (json) {
      this.source = json.source;
      this.sourceType = json.sourceType;
      this.sourceId = json.sourceId;
    }
  }

  toJS() {
    return {
      source: this.source,
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    };
  }
}
