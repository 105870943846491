/**
 * Created by katarinababic on 14.11.23.
 */
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { FileStorageContent, FileStorageContentJson } from './FileStorageContent';
import dayjs, { Dayjs } from 'dayjs';

export type FileContentJson = FileStorageContentJson & {
  contentType: string;
  created: string;
  lastModified: string;
};

export class FileContent extends FileStorageContent {
  @observable
  isDirectory: boolean = false;
  @observable
  contentType: string = '';
  @observable
  created: Dayjs = dayjs();
  @observable
  lastModified: Dayjs = dayjs();

  constructor(json?: Partial<FileContentJson>) {
    super(json);
    if (json) {
      this.isDirectory = json.isDirectory ?? false;
      this.contentType = json.contentType ?? '';
      this.created = json.created ? dayjs(json.created) : dayjs();
      this.lastModified = json.lastModified ? dayjs(json.lastModified) : dayjs();
    }
  }

  static async uploadFiles(path: string = '/', files: File[]) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    const formattedPath = this.transformPath(path);
    const endpoint = `media/file-storage/admin/file?path=${formattedPath}`;

    return HttpBackend.postMultipartFormData(endpoint, formData).then((res) => res);
  }

  static async renameFile(currentName: string = '', newName: string = '') {
    return HttpBackend.put(`media/file-storage/admin/file?currentFileName=${currentName}&newFileName=${newName}`).then(
      (res) => res,
    );
  }

  static async deleteFile(fileName: string = '') {
    return HttpBackend.delete(`media/file-storage/admin/file?fileName=${fileName}`).then((res) => res);
  }

  static async download(paths: string[]) {
    return HttpBackend.postRaw(`/media/file-storage/download`, paths, { responseType: 'blob' });
  }
}
