import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { Alert, Button, Form, Input } from 'antd';
import { reaction, runInAction } from 'mobx';
import { Col, Row } from 'reactstrap';
import { RecipeGenerator } from '../Generator/RecipeGenerator';

export type RecipeEditModalChatGptJsonTabProps = {
  recipe: Recipe;
  onBeforeProcessing?: () => void;
  onAfterProcessing?: () => void;
};

export const RecipeEditModalChatGptJsonTab: React.FC<RecipeEditModalChatGptJsonTabProps> = observer(
  ({ recipe, onBeforeProcessing, onAfterProcessing }) => {
    const [jsonText, setJsonText] = useState('');
    const [jsonError, setJsonError] = useState(false);
    const [recipeName, setRecipeName] = useState('');
    const [generator, setGenerator] = useState<RecipeGenerator | undefined>();

    const generatedJson = generator?.tmpMessage ?? '';

    useEffect(
      () =>
        reaction(
          () => generator?.status,
          (status) => {
            if (status === 'generating' || status === 'creating') {
              onBeforeProcessing && onBeforeProcessing();
            } else if (status === 'done' || status === 'saved') {
              onAfterProcessing && onAfterProcessing();
            }
          },
        ),
      [generator, onBeforeProcessing, onAfterProcessing],
    );

    const handleChangeRecipeName = React.useCallback(
      ({ target: { value } }) => {
        setGenerator(new RecipeGenerator(value, recipe));
        setRecipeName(value);
      },
      [recipe],
    );

    const handleGenerate = React.useCallback(() => {
      if (recipeName.trim()) {
        // (response as any).data.on('data', (data) => console.log(data.toString()));
      }
    }, [recipeName]);

    const handleSetJson = React.useCallback(
      ({ target: { value } }) => {
        setJsonText(value);
        if (generator) {
          runInAction(() => (generator.jsonText = value));
        }
      },
      [generator],
    );

    return (
      <Row>
        <Col>
          <Input
            value={recipeName}
            onChange={handleChangeRecipeName}
            disabled={generator?.creating || generator?.generating}
          />
        </Col>
        <Col xs="auto">
          <Button onClick={handleGenerate} disabled={generator?.creating || generator?.generating}>
            Generate
          </Button>
        </Col>
        <Col xs={12}>
          <Form.Item>
            {generator?.generating ? (
              <Input.TextArea rows={32} value={generatedJson} disabled={true} />
            ) : (
              <Input.TextArea rows={32} onChange={handleSetJson} value={jsonText} />
            )}
            {jsonError && <Alert type="error" message="The JSON is invalid" />}
            {generator?.creating && <Alert type="info" message="Processing input..." />}
          </Form.Item>
        </Col>
      </Row>
    );
  },
);
