/**
 * Created by neo on 17.04.22.
 */
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { observable, toJS } from 'mobx';

export type QuoteJson = {
  tags: string[];
  values: LocalizedValueJson[];
};

export class Quote {
  @observable
  tags: string[] = [];
  @observable
  values: LocalizedValue[] = [];

  constructor(json?: Partial<QuoteJson>) {
    if (json) {
      this.tags = json.tags ?? [];
      this.values = (json.values ?? []).map((v) => new LocalizedValue(v));
    }
  }

  toJS(): QuoteJson {
    return {
      tags: toJS(this.tags),
      values: this.values.map((v) => v.toJS()),
    };
  }
}
