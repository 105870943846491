import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from './DefaultStageNodeModel';
import { DefaultStageNodeWidget } from './DefaultStageNodeWidget';
import { NoopStage } from '../../../../../../../Model/Coach/Stages/NoopStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class DefaultStageNodeFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('stage');
  }

  generateReactWidget(event): JSX.Element {
    return <DefaultStageNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new DefaultStageNodeModel(new NoopStage(new Pipeline()));
  }
}
