/**
 * Created by neo on 18.01.17.
 */

import { action, computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedValue } from '../../../LocalizedValue';
import { CoachWorkoutPhase } from './CoachWorkoutPhase';
import { languagePriority } from '../../../LocalizedArrayEntity';
import { CoachExerciseBlockSet } from './CoachExerciseBlockSet';
import { BaseTrackingKey, TrackingKeys } from '../../../ProgramPortfolio/TrackingKeys';
import { Exercise } from '../../../Exercise/Exercise';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { PipelineContext } from '../../PipelineContext';

export class CoachExerciseBlock {
  @observable exerciseBlockId: string = UUID();
  @observable
  description: Array<LocalizedValue> = [];
  @observable exercise: Exercise = new Exercise();
  @observable.shallow sets: Array<CoachExerciseBlockSet> = [];
  @observable phase: CoachWorkoutPhase;

  constructor(phase: CoachWorkoutPhase, json?: any) {
    this.phase = phase;
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): any {
    return {
      exerciseBlockId: newId ? UUID() : this.exerciseBlockId,
      description: this.description.map((l) => l.toJS()),
      exercise: this.exercise.toJS(),
      sets: this.sets.map((s) => s.toJS()),
    };
  }

  @action
  setBasicData(json: any) {
    this.exerciseBlockId = json.exerciseBlockId || UUID();
    this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
    this.exercise = json.exercise instanceof Exercise ? json.exercise : new Exercise(json.exercise);
  }

  @action
  setData(json: any) {
    this.setBasicData(json);
    this.sets = (json.sets || []).map((s: any) => new CoachExerciseBlockSet(this, s));
  }

  calculateCalories(bmr24: number): number {
    return this.sets.reduce((total: number, set: CoachExerciseBlockSet) => total + set.calculateCalories(bmr24), 0);
  }

  suggestNew(params?: PipelineContext): Promise<CoachExerciseBlock[]> {
    const context = params ?? new PipelineContext();
    context.excludeExerciseIds = this.phase.exerciseBlocks.map((b) => b.exercise.id);
    return HttpBackend.post(
      `/coach/workout/${this.phase.workout.id}/${this.phase.phaseId}/suggest`,
      context.toJS(),
    ).then((result) => result.map((r) => new CoachExerciseBlock(this.phase, r)));
  }

  replace(newBlock: CoachExerciseBlock) {
    const promise = HttpBackend.post(`/coach/workout/${this.phase.workout.id}/replaceExerciseBlock`, {
      exerciseBlockId: this.exerciseBlockId,
      exerciseBlock: newBlock.toJS(),
    }).then(() => this);
    this.setData(newBlock);
    return promise;
  }

  @action
  async save() {
    return true;
  }

  @computed
  get valid(): boolean {
    return true;
  }

  @computed
  get duration(): number {
    return this.sets.reduce((total: number, set: CoachExerciseBlockSet) => total + set.totalDuration, 0);
  }

  @computed
  get breakTime(): number {
    return this.sets.reduce((total: number, set: CoachExerciseBlockSet) => total + set.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.sets.reduce((total: number, set: CoachExerciseBlockSet) => total + set.plannedDurationMs, 0);
  }

  @computed
  get tons(): number {
    return this.sets.reduce((total: number, set: CoachExerciseBlockSet) => total + set.tons, 0);
  }

  @computed
  get lastSet(): CoachExerciseBlockSet | undefined {
    const { length } = this.sets;
    if (length > 0) {
      return this.sets[length - 1];
    }
    return undefined;
  }

  @computed
  get firstSet(): CoachExerciseBlockSet | undefined {
    const { sets } = this;
    if (sets.length > 0) {
      return sets[0];
    }
    return undefined;
  }

  @computed
  get prevSet(): CoachExerciseBlockSet | undefined {
    if (!this.lastSet) {
      return this.prevBlock && this.prevBlock.prevSet;
    }
    return this.lastSet;
  }

  @computed
  get followingSet(): CoachExerciseBlockSet | undefined {
    return this.firstSet || (this.nextBlock ? this.nextBlock.followingSet : undefined);
  }

  @computed
  get exerciseBlockIndex(): number {
    return this.phase ? this.phase.exerciseBlocks.findIndex((b) => b.exerciseBlockId === this.exerciseBlockId) : -1;
  }

  @computed
  get isLastBlock(): boolean {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      return exerciseBlockIndex + 1 === this.phase.exerciseBlocks.length;
    }
    return true;
  }

  @computed
  get prevBlock(): CoachExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      if (exerciseBlockIndex === 0) {
        return this.phase.prevPhase?.prevBlock;
      }
      return this.phase.exerciseBlocks[exerciseBlockIndex - 1];
    }
    return undefined;
  }

  @computed
  get nextBlock(): CoachExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex, isLastBlock } = this;
      if (isLastBlock) {
        return this.phase.nextPhase?.nextBlock;
      }
      const nextIndex = exerciseBlockIndex + 1;
      return nextIndex < this.phase.exerciseBlocks.length ? this.phase.exerciseBlocks[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get defaultTrackingKeys(): BaseTrackingKey[] {
    return this.exercise.trackingParameters;
  }

  @computed
  get trackingParameters(): BaseTrackingKey[] {
    return this.defaultTrackingKeys;
  }

  @computed
  get sortedTrackingParameters(): BaseTrackingKey[] {
    const trackingKeys = Object.keys(TrackingKeys);
    return this.trackingParameters.slice().sort((a, b) => trackingKeys.indexOf(a) - trackingKeys.indexOf(b));
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.sets.reduce((total: number, set: CoachExerciseBlockSet) => total + set.totalDuration, 0);
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }

  @computed
  get minDuration(): number {
    let totalTime = 0;
    const sets = (this.sets || []).slice();
    for (const set of sets) {
      totalTime += set.minDuration;
    }
    return totalTime;
  }

  @computed
  get maxDuration(): number {
    let totalTime = 0;
    const sets = (this.sets || []).slice();
    for (const set of sets) {
      totalTime += set.maxDuration;
    }
    return totalTime;
  }
}
