/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { Table } from 'reactstrap';
import { CoachProgram } from '../../../../Model/Coach/Program/CoachProgram';
import { AthleteCoachProgramTableRow } from './AthleteCoachProgramTableRow';
import { observer } from 'mobx-react';

export type AthleteCoachProgramTableProps = {
  programs: CoachProgram[];
  onRemove?: (program: CoachProgram) => any;
};

export const AthleteCoachProgramTable: React.FC<AthleteCoachProgramTableProps> = observer(
  ({ programs, onRemove }: AthleteCoachProgramTableProps) => {
    return (
      <Table className="table table-striped table-sm">
        <thead className="thead-inverse">
          <tr>
            <th>{'Name'}</th>
            <th>{'Started'}</th>
            <th>{'Ended'}</th>
            <th>{'Created'}</th>
            <th>{'Actions'}</th>
          </tr>
        </thead>
        <tbody>
          {programs.map((program) => (
            <AthleteCoachProgramTableRow key={program.id} program={program} onRemove={onRemove} />
          ))}
        </tbody>
      </Table>
    );
  },
);
