/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { CoachProgram } from '../../../Model/Coach/Program/CoachProgram';
import { Table } from 'reactstrap';
import { CoachProgramTableRow, CoachProgramTableRowProps } from './CoachProgramTableRow';

export type CoachProgramTableProps = {
  programs: CoachProgram[];
} & Omit<CoachProgramTableRowProps, 'program'>;

export const CoachProgramTable: React.FC<CoachProgramTableProps> = observer(
  ({ programs, onRemove }) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Athlete</th>
            <th>Tags</th>
            <th>Started</th>
            <th>Ended</th>
            <th>Created</th>
            <th>Version</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {programs.map((superMacro) => (
            <CoachProgramTableRow key={superMacro.id} program={superMacro} onRemove={onRemove} />
          ))}
        </tbody>
      </Table>
    );
  },
);
