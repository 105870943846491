/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { PageResult } from '../../../../Model/PageResult';
import { AthleteDeviceInfo } from '../../../../Model/Athlete/AthleteDeviceInfo';
import { Pager } from '../../../../Components/Pager';
import { DeviceInfoTable } from './DeviceInfoTable';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useEffect, useState } from 'react';

export type DeviceInfoTabProps = {
  athlete: Athlete;
};

export const DeviceInfoTab: React.FC<DeviceInfoTabProps> = observer(({ athlete }) => {
  const [result, setResult] = useState<AthleteDeviceInfo[]>([]);

  const load = React.useCallback((athleteId?: string) => {
    AthleteDeviceInfo.find({
      athleteId,
      sort: 'updatedAt,DESC',
      size: 20,
    }).then((result) => setResult(result));
  }, []);

  useEffect(() => {
    load(athlete.id);
  }, [athlete, load]);

  return (
    <React.Fragment>
      <SingleColRow>
        <DeviceInfoTable deviceInfos={result} />
      </SingleColRow>
    </React.Fragment>
  );
});
