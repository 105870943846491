/**
 * Created by neo on 09.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { ExploreCategoryLink } from '../../../Model/Explore/ExploreCategoryLink';
import { Col, Row } from 'reactstrap';
import { TranslationInputArray } from '../../../Components/Translation/TranslationInputArray';
import { Button, Form, Input } from 'antd';
import { runInAction } from 'mobx';
import { ExploreEntryModalMediaField } from './ExploreEntryModalMediaField';
import { Media } from '../../../Model/Media/Media';
import { ExploreCategoryModalLinkListEditorEntryParam } from './ExploreCategoryModalLinkListEditorEntryParam';

export type ExploreCategoryModalLinkListEditorEntryProps = {
  category: ExploreCategory;
  link: ExploreCategoryLink;
  index: number;
};

export const ExploreCategoryModalLinkListEditorEntry: React.FC<ExploreCategoryModalLinkListEditorEntryProps> = observer(
  ({ category, link, index }) => {
    const [newEntryKey, setNewEntryKey] = useState<string | undefined>();

    const linkParams = Array.from(Object.keys(link.params));

    const handleChangeLink = React.useCallback(({ target: { value } }) => runInAction(() => (link.link = value)), [
      link,
    ]);

    const handleImageUploaded = React.useCallback(
      (media: Media) => {
        runInAction(() => (link.image = media));
      },
      [link],
    );

    const handleRemoveImage = React.useCallback(() => {
      runInAction(() => (link.image = undefined));
    }, [link]);

    const handleChangeNewParamKey = React.useCallback(({ target: { value } }) => setNewEntryKey(value), []);

    const handleAddNewParam = React.useCallback(() => {
      if (newEntryKey) {
        runInAction(() => (link.params[newEntryKey] = ''));
      }
      setNewEntryKey(undefined);
    }, [link, newEntryKey]);

    const handleRemove = React.useCallback(() => {
      runInAction(() => category.links.splice(index, 1));
    }, [category]);

    return (
      <Row key={index.toString()}>
        <Col sm="auto">{`${index + 1}.`}</Col>
        {category.type === 'tools' && (
          <Col>
            <ExploreEntryModalMediaField
              label="Image"
              onRemove={handleRemoveImage}
              onUploaded={handleImageUploaded}
              media={link.image}
            />
          </Col>
        )}
        <Col>
          <TranslationInputArray entity={link} field="name" label="Link Name" />
        </Col>
        <Col>
          <Form.Item label="Link">
            <Input value={link.link} onChange={handleChangeLink} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Link Parameters">
            {linkParams.map((key) => (
              <ExploreCategoryModalLinkListEditorEntryParam key={key} category={category} link={link} paramKey={key} />
            ))}
          </Form.Item>
          <Row>
            <Col>
              <Input value={newEntryKey} onChange={handleChangeNewParamKey} />
            </Col>
            <Col xs="auto">
              <Button type="primary" onClick={handleAddNewParam}>
                Add Param
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Button onClick={handleRemove} danger>
            Remove
          </Button>
        </Col>
      </Row>
    );
  },
);
