/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { observer } from 'mobx-react';
import { EquipmentType } from '../../../../Model/Equipment/EquipmentType';
import { EquipmentTypeTableRow } from './EquipmentTypeTableRow';

export type EquipmentTypeTableProps = {
  types: EquipmentType[];
  onDelete: (type: EquipmentType) => any;
  coachEquipmentTypeIds: string[];
};

export const EquipmentTypeTable: React.FC<EquipmentTypeTableProps> = observer(
  ({ types, onDelete, coachEquipmentTypeIds }) => {
    return (
      <Row>
        <Col>
          <Table size="sm" striped>
            <thead className="thead-inverse">
              <tr>
                <th>{'ID'}</th>
                <th>{'Image'}</th>
                <th>{'Name'}</th>
                <th>{'Coach equipment'}</th>
                <th>{'Action'}</th>
              </tr>
            </thead>
            <tbody>
              {types.map((l: EquipmentType) => (
                <EquipmentTypeTableRow
                  key={l.id}
                  type={l}
                  coachEquipmentTypeIds={coachEquipmentTypeIds}
                  onDelete={onDelete}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  },
);
