/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Input, Checkbox, Select, InputNumber, Button } from 'antd';
import { Gym, GymType } from '../../../../../Model/Gym/Gym';
import { ViewMedia } from '../../../../../Components/ViewMedia/ViewMedia';
import { GymLogoMedia } from '../GymLogoMedia';
import { TranslationInput } from '../../../../../Components/Translation/TranslationInput';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { GymContactForm } from './GymContactForm';
import { GymAddressForm } from './GymAddressForm';
import { runInAction } from 'mobx';
import CreatableSelect from 'react-select/creatable';
import { GymInfoToCopy } from './GymInfoToCopy';

export type GymBasicInfoTabProps = {
  gym: Gym;
  onSave?: () => any;
  isNew?: boolean;
};

export const GymBasicInfoTab: React.FC<GymBasicInfoTabProps> = observer(({ gym, onSave, isNew }) => {
  const handleChanceCoBrandingEnabled = React.useCallback(
    ({ target: { checked } }) => (gym.axaCoBrandingEnabled = checked),
    [gym],
  );

  const handleChangeAllowDomainSignup = React.useCallback(
    ({ target: { checked } }) => (gym.allowDomainSignup = checked),
    [gym],
  );

  const handleChangeName = React.useCallback((e) => (gym.name = e.target.value), [gym]);

  const handleChangeCustomerLimit = React.useCallback(
    (count?: number | string | null) => {
      const limit = typeof count === 'string' ? Number(count) : count ?? 0;
      runInAction(() => (gym.customerLimit = limit > 0 ? limit : undefined));
    },
    [gym],
  );

  const handleChangeTotalPotentialUsers = React.useCallback(
    (count?: number | string | null) => {
      const limit = typeof count === 'string' ? Number(count) : count ?? 0;
      runInAction(() => (gym.totalPotentialUsers = limit > 0 ? limit : 0));
    },
    [gym],
  );

  const handleDomainsChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        gym.domains.splice(0, gym.domains.length);
        (tags ?? []).map((t) => t.value).forEach((t) => gym.domains.push(t));
      });
    },
    [gym],
  );

  return (
    <React.Fragment>
      <SingleColRow>
        <Form onSubmitCapture={onSave} layout="vertical">
          <Row>
            <Col md={6}>
              <Form.Item label="Name">
                <Input type="text" value={gym.name || ''} onChange={handleChangeName} />
              </Form.Item>
            </Col>
            <Col xs={6} lg={3}>
              <Row>
                <Col xs="auto">
                  <Form.Item label="AXA Co-Branding">
                    <Checkbox onChange={handleChanceCoBrandingEnabled} checked={gym.axaCoBrandingEnabled}>
                      AXA Co-Branding enabled
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={6} lg={3}>
              <GymInfoToCopy gym={gym} isNew={isNew} />
            </Col>
            <Col xs={6}>
              <Row>
                <Col md={4}>
                  <Form.Item label="Customer Limit">
                    <InputNumber value={gym.customerLimit} min={0} onChange={handleChangeCustomerLimit} />
                    <span style={{ display: 'none' }}>{gym.customerLimit}</span>
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item label="Total Potential Users">
                    <InputNumber value={gym.totalPotentialUsers} min={0} onChange={handleChangeTotalPotentialUsers} />
                    <span style={{ display: 'none' }}>{gym.totalPotentialUsers}</span>
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item
                    label="Users can join via domain"
                    extra="if not set then users can only join via invite via studio.kinastic.com"
                  >
                    <Checkbox onChange={handleChangeAllowDomainSignup} checked={gym.allowDomainSignup}>
                      Users can join via domain
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Form.Item label="Domains">
                <CreatableSelect
                  isClearable
                  isMulti
                  onChange={handleDomainsChange as any}
                  value={gym.domains.map((d) => ({ value: d, label: d }))}
                />
              </Form.Item>
            </Col>
            <GymAddressForm gym={gym} />
          </Row>
        </Form>
      </SingleColRow>
      <GymLogoMedia gym={gym} />
    </React.Fragment>
  );
});
