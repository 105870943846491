/**
 *
 * Created by andreaskarantzas on 01.08.18.
 */

export const DayOfWeek = [
  {
    key: 'MONDAY',
    number: 1,
  },
  {
    key: 'TUESDAY',
    number: 2,
  },
  {
    key: 'WEDNESDAY',
    number: 3,
  },
  {
    key: 'THURSDAY',
    number: 4,
  },
  {
    key: 'FRIDAY',
    number: 5,
  },
  {
    key: 'SATURDAY',
    number: 6,
  },
  {
    key: 'SUNDAY',
    number: 7,
  },
];
