/**
 * Created by neo on 08.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { TagConditionsInput } from '../../SuperMacro/View/TagConditionsInput';
import { CoachTts } from '../../../../Model/Coach/TextToSpeech/CoachTts';
import CreatableSelect from 'react-select/creatable';
import { Col, Row } from 'reactstrap';
import { SsmlVoiceGender } from '../../../../Model/Coach/TextToSpeech/GoogleTtsVoice';
import { AudioEncoding } from '../../../../Model/Coach/TextToSpeech/GoogleTtsAudioConfig';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/theme-textmate';
import { VariableTestInput } from './VariableTestInput';
import { Gender } from '../../../../Model/Person/Gender';

const IDENTIFIER_REGEX = /^[a-z-_0-9]{3,48}$/;

export const availableLanguages = [
  {
    label: 'Deutsch',
    value: 'de',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Français',
    value: 'fr',
  },
  {
    label: 'Italiano',
    value: 'it',
  },
  {
    label: 'Español',
    value: 'es',
  },
];

const evaluateLanguageCode = (lang: string): string => {
  if (lang === 'de') {
    return 'de-DE';
  } else if (lang === 'en') {
    return 'en-US';
  } else if (lang === 'fr') {
    return 'fr-FR';
  } else if (lang === 'it') {
    return 'it-IT';
  }
  return lang;
};

export type CoachTtsEditModalProps = {
  onSaved?: (tts: CoachTts) => void;
  onDeleted?: (tts: CoachTts) => void;
};

export const CoachTtsEditModal: React.FC<CoachTtsEditModalProps> = observer(({ onSaved, onDeleted }) => {
  const history = useNavigate();
  const location = useLocation();
  const playingAudio = React.useRef<any>();
  const identifier = new URLSearchParams(location.search).get('identifier') ?? undefined;
  const language = new URLSearchParams(location.search).get('language') ?? 'de';
  const ssml = new URLSearchParams(location.search).get('ssml') ?? undefined;
  const text = new URLSearchParams(location.search).get('text') ?? undefined;

  const { ttsId } = useParams<{ ttsId: string }>();
  const [processing, setProcessing] = useState(false);
  const [tts, setTts] = useState(new CoachTts());
  const [testVoice, setTestVoice] = useState('female_01');
  const identifierInvalid = !tts.identifier.match(IDENTIFIER_REGEX);
  const validateStatus = identifierInvalid ? 'error' : undefined;
  const isNew = ttsId === 'new';

  useEffect(() => {
    if (ttsId && ttsId !== 'new') {
      setProcessing(true);
      CoachTts.findOne(ttsId)
        .then((result) => setTts(result))
        .finally(() => setProcessing(false));
    } else
      setTts(
        new CoachTts({
          identifier,
          language,
          googleTtsRequest: {
            input: { ssml, text },
          },
        }),
      );
  }, [ttsId, identifier, language, ssml, text]);

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    onSaved && onSaved(tts);
    tts
      .save()
      .finally(() => setProcessing(false))
      .then(() => history(-1));
  }, [tts, onSaved, history]);

  const handleCancel = React.useCallback(() => history(-1), [history]);

  const handleChangeIdentifier = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (tts.identifier = value));
    },
    [tts],
  );

  const handleChange = React.useCallback(
    (tags: any[]) => {
      runInAction(() => (tts.tags = (tags ?? []).map((t) => t.value)));
    },
    [tts],
  );

  const handleChangeLanguage = React.useCallback(
    (lang: string) => {
      runInAction(() => {
        tts.language = lang;
        tts.googleTtsRequest.voice.languageCode = evaluateLanguageCode(lang);
      });
    },
    [tts],
  );

  const handleChangeVoice = React.useCallback((voice: string) => {
    setTestVoice(voice);
  }, []);

  const handleChangeAudioEncoding = React.useCallback(
    (encoding: string) => {
      runInAction(() => {
        tts.googleTtsRequest.audioConfig.audioEncoding = encoding as AudioEncoding;
      });
    },
    [tts],
  );

  const handleChangeInputSsml = React.useCallback(
    (value) => {
      runInAction(() => (tts.googleTtsRequest.input.ssml = value));
    },
    [tts],
  );

  const handleChangeInputText = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (tts.googleTtsRequest.input.text = value));
    },
    [tts],
  );

  const handleDelete = React.useCallback(() => {
    setProcessing(true);
    onDeleted && onDeleted(tts);
    tts
      .delete()
      .finally(() => setProcessing(false))
      .then(() => history(-1));
  }, [tts, history, onDeleted]);

  const handleTest = React.useCallback(() => {
    setProcessing(true);
    tts.googleTtsRequest.voice.languageCode = evaluateLanguageCode(tts.language);
    tts
      .test(testVoice)
      .then((res) => {
        playingAudio.current && playingAudio.current.pause();
        const audio = new Audio(res);
        playingAudio.current = audio;
        audio.play();
      })
      .finally(() => setProcessing(false));
  }, [tts, testVoice]);

  const handleChangeAudioVolumeGain = React.useCallback(
    (value: number | string | null) => {
      if (typeof value === 'number') {
        runInAction(() => (tts.googleTtsRequest.audioConfig.volumeGainDb = value));
      }
    },
    [tts],
  );

  return (
    <Modal
      open={true}
      title={isNew ? 'New TTS' : tts.identifier}
      onOk={handleSave}
      onCancel={handleCancel}
      confirmLoading={processing}
      width={1280}
      footer={[
        <Button key="1" onClick={handleCancel} disabled={processing}>
          Close
        </Button>,
        <Button key="2" onClick={handleTest} disabled={processing}>
          Test
        </Button>,
        !isNew ? (
          <Button key="3" danger onClick={handleDelete} disabled={processing}>
            Delete
          </Button>
        ) : null,
        <Button key="4" type="primary" onClick={handleSave} disabled={processing}>
          Save
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Row>
          <Col>
            <Form.Item label="Identifier" hasFeedback={identifierInvalid} validateStatus={validateStatus}>
              <Input value={tts.identifier} onChange={handleChangeIdentifier} disabled={!isNew} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Tags">
              <CreatableSelect
                isClearable={true}
                isMulti={true}
                value={tts.tags.map((value) => ({ value, label: value }))}
                hideSelectedOptions={false}
                onChange={handleChange as any}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Language">
              <Select value={tts.language} onChange={handleChangeLanguage}>
                {availableLanguages.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Voices" extra="Only for testing">
              <Select value={testVoice} onChange={handleChangeVoice}>
                <Select.Option value="female_01">Female 01</Select.Option>
                <Select.Option value="female_02">Female 02</Select.Option>
                <Select.Option value="male_01">Male 01</Select.Option>
                <Select.Option value="male_02">Male 02</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Encoding" extra="Usually just MP3">
              <Select value={tts.googleTtsRequest.audioConfig.audioEncoding} onChange={handleChangeAudioEncoding}>
                <Select.Option value="LINEAR16">Linear 16</Select.Option>
                <Select.Option value="MP3">MP3</Select.Option>
                <Select.Option value="OGG_OPUS">Ogg Opus</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Volume Gain" extra="Usually it's just 0">
              <InputNumber
                value={tts.googleTtsRequest.audioConfig.volumeGainDb}
                onChange={handleChangeAudioVolumeGain}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Text" extra="When ssml is set it will always be preferred">
              <Input.TextArea value={tts.googleTtsRequest.input.text} onChange={handleChangeInputText} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="SSML" extra="https://cloud.google.com/text-to-speech/docs/ssml">
              <AceEditor
                mode="xml"
                theme="textmate"
                onChange={handleChangeInputSsml}
                value={tts.googleTtsRequest.input.ssml}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
              />
            </Form.Item>
          </Col>
        </Row>
        <SingleColRow>
          <Form.Item label="Variables for testing only">
            <Row>
              {tts.variables.map((variable) => (
                <Col sm={6} key={variable}>
                  <VariableTestInput tts={tts} variable={variable} />
                </Col>
              ))}
            </Row>
          </Form.Item>
        </SingleColRow>
        <SingleColRow>
          <Form.Item label="Tag Conditions">
            <TagConditionsInput tagConditions={tts.tagConditions} />
          </Form.Item>
        </SingleColRow>
      </Form>
    </Modal>
  );
});
