import { v4 as UUID } from 'uuid';
import {
  ExerciseExecutionType,
  ExerciseForce,
  ExerciseMechanics,
  ExerciseUtility,
  SpineForceType,
} from './AbstractExercise';
import { TagCondition } from '../Coach/SuperMacro/TagCondition';
import { BodyPartRegion } from '../BodyPart/BodyPartRegion';
import { ExerciseType } from './ExerciseType';
import { ExercisePosition } from './ExercisePosition';
import { observable, onBecomeObserved, runInAction, toJS } from 'mobx';
import { Exercise } from './Exercise';
import { BaseTrackingKey } from '../ProgramPortfolio/TrackingKeys';


export default class ExerciseVariationDocument {
  id = UUID();
  archived = false;
  type: ExerciseType = 'STRENGTH';
  met: number = 5.5;
  force?: ExerciseForce;
  mechanics?: ExerciseMechanics;
  utility?: ExerciseUtility;
  initialPosition?: ExercisePosition;
  executionType?: ExerciseExecutionType;
  synergists: string[] = [];
  bodyParts: string[] = [];
  stabilizers: string[] = [];
  joints: string[] = [];
  trackingKeys: BaseTrackingKey[] = [];
  optionalTrackingKeys: string[] = [];
  sourceType?: string;
  sourceId?: string;
  tags: string[] = [];
  tagConditions: TagCondition[] = [];
  equipmentTypes: string[] = [];
  secondaryEquipmentTypes: string[] = [];
  hipFlexing: boolean = false;
  spineFlexing: boolean = false;
  spineForceType?: SpineForceType;
  allTags: string[] = [];
  allBodyPartIds: string[] = []; // from backend
  allSynergistIds: string[] = []; // from backend
  allStabilizerIds: string[] = []; // from backend
  @observable
  exercise?: Exercise;

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.type = json.type || 'STRENGTH';
      this.met = json.met || 5.5;
      this.force = json.force;
      this.mechanics = json.mechanics;
      this.utility = json.utility;
      this.initialPosition = json.initialPosition ? new ExercisePosition(json.initialPosition) : undefined;
      this.executionType = json.executionType;
      this.synergists = json.synergists || [];
      this.bodyParts = json.bodyParts || [];
      this.stabilizers = json.stabilizers || [];
      this.joints = json.joints || [];
      this.trackingKeys = json.trackingKeys || [];
      this.optionalTrackingKeys = json.optionalTrackingKeys || [];
      this.sourceType = json.sourceType;
      this.sourceId = json.sourceId;
      this.tags = json.tags || [];
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.archived = json.archived ?? false;
      this.equipmentTypes = json.equipmentTypes || [];
      this.secondaryEquipmentTypes = json.secondaryEquipmentTypes || [];
      this.hipFlexing = !!json.hipFlexing;
      this.spineFlexing = !!json.spineFlexing;
      this.spineForceType = json.spineForceType;
      this.allTags = json.allTags || [];
      this.allBodyPartIds = json.allBodyParts || [];
      this.allSynergistIds = json.allSynergists || [];
      this.allStabilizerIds = json.allStabilizers || [];
    }

    onBecomeObserved(this, 'exercise', () => this.fetchExercise());
  }

  fetchExercise(): Promise<Exercise | undefined> {
    if (!this.exercise) {
      return Exercise.get(this.id, this.sourceType, this.sourceId).then((result) => {
        runInAction(() => (this.exercise = result));
        return result;
      });
    }
    return Promise.resolve(this.exercise);
  }

  async flattenBodyParts(bodyParts: Array<BodyPartRegion>) {
    let results: Array<BodyPartRegion> = [];
    for (const bodyPart of bodyParts) {
      results = results.concat(await bodyPart.flatten());
    }
    return results;
  }

  get defaultTrackingKeys(): BaseTrackingKey[] {
    switch (this.type.toLowerCase()) {
      case 'strength':
        return this.equipmentTypes.includes('FREE') || this.equipmentTypes.includes('SLING_TRAINER')
          ? ['REPETITIONS', 'DURATION', 'BREAK']
          : ['REPETITIONS', 'WEIGHT', 'DURATION', 'BREAK'];
      case 'endurance':
      case 'flexibility':
      case 'balance':
      case 'relax':
      default:
        return ['DURATION', 'BREAK'];
    }
  }

  get trackingParameters(): BaseTrackingKey[] {
    if (this.trackingKeys.length > 0) {
      const hasBreak = this.trackingKeys.includes('BREAK');
      const hasDuration = this.trackingKeys.includes('DURATION');
      if (!hasBreak) {
        if (!hasDuration) {
          return this.trackingKeys.concat(['DURATION', 'BREAK']);
        }
        return this.trackingKeys.concat(['BREAK']);
      } else if (!hasDuration) {
        return this.trackingKeys.concat(['DURATION']);
      }
      return this.trackingKeys;
    }
    return this.defaultTrackingKeys;
  }

  toJS(newId?: boolean): any {
    return {
      id: newId ? UUID() : this.id,
      type: this.type,
      met: this.met,
      force: this.force,
      mechanics: this.mechanics,
      utility: this.utility,
      initialPosition: this.initialPosition?.toJS(),
      executionType: this.executionType,
      synergists: toJS(this.synergists),
      bodyParts: toJS(this.bodyParts),
      stabilizers: toJS(this.stabilizers),
      joints: toJS(this.joints),
      trackingKeys: toJS(this.trackingKeys),
      optionalTrackingKeys: toJS(this.optionalTrackingKeys),
      sourceType: this.sourceType,
      sourceId: this.sourceId,
      tags: toJS(this.tags),
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      equipmentTypes: toJS(this.equipmentTypes),
      secondaryEquipmentTypes: toJS(this.secondaryEquipmentTypes),
      hipFlexing: this.hipFlexing,
      spineFlexing: this.spineFlexing,
      spineForceType: this.spineForceType,
    };
  }

  // matches(params: ExerciseFilterJson): boolean {
  //   const universalTags = params.tags.filter((it) => it.startsWith('group:') || it.startsWith('focus:'));
  //   return (
  //     (this.hasArray(params.excludeExerciseIds) ? !params.excludeExerciseIds.includes(this.id) : true) &&
  //     this.evaluate(params.type, this.type) &&
  //     this.evaluate(params.force, this.force) &&
  //     this.evaluate(params.mechanics, this.mechanics) &&
  //     this.evaluate(params.utilities, this.utility) &&
  //     this.evaluate(params.primaryPositions, this.initialPosition?.primary) &&
  //     this.evaluate(params.secondaryPositions, this.initialPosition?.secondary) &&
  //     (this.initialPosition?.primary && this.hasArray(params.excludedPrimaryPositions)
  //       ? params.excludedPrimaryPositions.includes(this.initialPosition?.primary)
  //       : true) &&
  //     (this.initialPosition?.secondary && this.hasArray(params.excludedSecondaryPositions)
  //       ? params.excludedSecondaryPositions.includes(this.initialPosition?.secondary)
  //       : true) &&
  //     (this.hasArray(params.requiredEquipmentTypes)
  //       ? params.requiredEquipmentTypes.some((e) => this.equipmentTypes.includes(e))
  //       : true) &&
  //     (this.hasArray(params.equipmentTypes)
  //       ? this.equipmentTypes.every((e) => params.equipmentTypes.includes(e))
  //       : true) &&
  //     (this.hasArray(params.excludedEquipmentTypes)
  //       ? !this.equipmentTypes.some((e) => params.excludedEquipmentTypes.includes(e))
  //       : true) &&
  //     (params.excludeEmptyEquipmentTypes ? this.equipmentTypes.length > 0 : true) &&
  //     (this.hasArray(params.secondaryEquipmentTypes)
  //       ? params.secondaryEquipmentTypes.some((e) => this.secondaryEquipmentTypes.includes(e))
  //       : true) &&
  //     this.evaluate(params.executionTypes, this.executionType) &&
  //     (this.hasArray(params.excludedExecutionTypes)
  //       ? !this.executionType || !params.excludedExecutionTypes.includes(this.executionType)
  //       : true) &&
  //     (params.excludeEmptyEquipmentTypes ? this.equipmentTypes.length > 0 : true) &&
  //     (this.hasArray(params.tags) ? universalTags.every((t) => this.tags.includes(t)) : true) &&
  //     (this.hasArray(params.tags) && this.tagConditions.length > 0
  //       ? this.tagConditions.some((c) => c.matches(params.tags))
  //       : true) &&
  //     (this.hasArray(params.excludedTags) ? !this.tags.some((t) => params.excludedTags.includes(t)) : true) &&
  //     (this.hasArray(params.joints) ? params.joints.some((j) => this.joints.includes(j)) : true) &&
  //     (this.hasArray(params.excludedJoints) ? !this.joints.some((t) => params.excludedJoints.includes(t)) : true) &&
  //     (this.hasArray(params.trackingKeys)
  //       ? params.trackingKeys.every((t) => this.trackingParameters.includes(t))
  //       : true) &&
  //     (this.hasArray(params.excludedTrackingKeys)
  //       ? !params.trackingKeys.some((t) => this.trackingParameters.includes(t))
  //       : true) &&
  //     (this.hasArray(params.excludedBodyPartIds)
  //       ? !this.bodyParts.some((b) => params.excludedBodyPartIds.includes(b))
  //       : true) &&
  //     (params.bodyPartRoots?.every((root) => this.allBodyPartIds.some((b) => root.includes(b))) ?? true) &&
  //     (params.synergistRoots?.every((root) => this.allSynergistIds.some((b) => root.includes(b))) ?? true) &&
  //     (params.stabilizerRoots?.every((root) => this.allStabilizerIds.some((b) => root.includes(b))) ?? true) &&
  //     (params.hipFlexing ? this.hipFlexing : true) &&
  //     (params.spineFlexing ? this.spineFlexing : true) &&
  //     (params.spineForceType ? this.spineForceType === params.spineForceType : true)
  //   );
  // }

  private evaluate(value?: any, compare?: any): boolean {
    if (value) {
      if (Array.isArray(value)) {
        if (this.hasArray(value)) {
          if (compare) {
            if (Array.isArray(compare)) {
              return value.some((v) => compare.includes(v));
            } else {
              return value.includes(compare);
            }
          }
          return false;
        }
        return true;
      } else if (Array.isArray(compare)) {
        return compare.includes(value);
      }
      return value === compare;
    }
    return true;
  }

  private eqSet(value: any[], other: any[]): boolean {
    const set0 = new Set(value);
    const set1 = new Set(other);
    return set0.size === set1.size;
  }

  private hasArray(value?: any[]): boolean {
    return !!(value && value.length > 0);
  }

  accept(tags: string[]) {
    if (tags.length > 0) {
      const globalTags = tags.filter((t) => t.startsWith('focus:') || t.startsWith('group:'));
      if (globalTags.length === 0 || globalTags.every((t) => this.tags.indexOf(t) !== -1)) {
        return this.tagConditions.length === 0 || this.tagConditions.findIndex((c) => c.matches(tags)) !== -1;
      }
      return false;
    }
    return true;
  }
}
