/**
 * Created by neo on 18.12.20.
 */
import { observable } from 'mobx';

export type WeightPerServingJson = {
  amount: number;
  unit: string;
};

export class WeightPerServing {
  @observable
  amount: number = 0;
  @observable
  unit = '';

  constructor(json?: Partial<WeightPerServingJson>) {
    if (json) {
      this.amount = json.amount ?? 0;
      this.unit = json.unit ?? '';
    }
  }

  toJS(): WeightPerServingJson {
    return {
      amount: this.amount,
      unit: this.unit,
    };
  }
}
