/**
 * Created by neo on 19.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { PropsWithChildren, useEffect } from 'react';
import styled from '@emotion/styled';

export type TrayWidgetProps = {};

export const StyledTray = styled.div`
  min-width: 200px;
  background: rgb(20, 20, 20);
  flex-grow: 0;
  flex-shrink: 0;
`;

export const TrayWidget: React.FC<PropsWithChildren<TrayWidgetProps>> = observer(({ children }) => {
  return <StyledTray>{children}</StyledTray>;
});
