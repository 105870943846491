/**
 * Created by neo on 08.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CoachTtsGroupedResult } from '../../../Model/Coach/TextToSpeech/CoachTtsGroupedResult';
import { CoachTtsTableRowEntry } from './CoachTtsTableRowEntry';
import { Table } from 'reactstrap';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { availableLanguages } from './CoachTtsEditModal/CoachTtsEditModal';
import { DeeplService } from '../../../Services/DeeplService';

export type CoachTtsTableRowProps = {
  group: CoachTtsGroupedResult;
};

export const CoachTtsTableRow: React.FC<CoachTtsTableRowProps> = observer(({ group }) => {
  const history = useNavigate();
  const [show, setShow] = useState(false);

  const handleToggleShow = React.useCallback(() => setShow((s) => !s), []);

  const handleCreateVariation = React.useCallback(() => {
    const missingLanguage = availableLanguages.filter((l) => !group.entries.some((e) => e.language === l.value))[0];
    const existing =
      group.entries.find(
        (e) =>
          e.language === 'de' && (!!e.googleTtsRequest.input.ssml?.trim() || !!e.googleTtsRequest.input.text?.trim()),
      ) ??
      group.entries.find(
        (e) =>
          e.language === 'en' && (!!e.googleTtsRequest.input.ssml?.trim() || !!e.googleTtsRequest.input.text?.trim()),
      ) ??
      group.entries.find((e) => !!e.googleTtsRequest.input.ssml?.trim() || !!e.googleTtsRequest.input.text?.trim());
    if (missingLanguage && existing) {
      const input = existing.googleTtsRequest.input.ssml?.trim() || existing.googleTtsRequest.input.text?.trim();
      const isSsml = !!existing.googleTtsRequest.input.ssml?.trim();
      if (input) {
        DeeplService.translate({
          sourceLanguage: existing.language,
          targetLanguage: missingLanguage.value,
          text: input,
        }).then((res) =>
          history(
            `new?identifier=${group.identifier}&language=${missingLanguage?.value}&${isSsml ? 'ssml' : 'text'}=${
              res ?? ''
            }`,
          ),
        );
      }
    } else {
      history(`new?identifier=${group.identifier}&language=${missingLanguage?.value}`);
    }
  }, [group, history]);

  return (
    <React.Fragment>
      <tr onClick={handleToggleShow}>
        <td>{group.identifier}</td>
        <td>{group.entries.length}</td>
        <td>{group.entries.map((e) => e.language).join(', ')}</td>
        <td style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={handleCreateVariation}>
            Create Variation
          </Button>
        </td>
      </tr>
      {show && (
        <tr>
          <td colSpan={4}>
            <Table size="sm" striped={false}>
              <tbody>
                {group.entries.map((entry) => (
                  <CoachTtsTableRowEntry key={entry.id} group={group} entry={entry} />
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
});
