/**
 * Created by neo on 09.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { Image as MarkdownImage } from 'mdast-util-from-markdown/dev/lib';

export type MarkdownImageRendererProps = {
  content: MarkdownImage;
};

export const MarkdownImageRenderer: React.FC<MarkdownImageRendererProps> = observer(({ content }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.image} src={content.url} alt="" />;
    </div>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingVertical: 16,
  },
  image: {
    width: '100%',
    height: undefined,
  },
}));
