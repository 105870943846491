/**
 * Created by neo on 23.08.21.
 */
import { LocalizedValue, LocalizedValueJson } from '../../../LocalizedValue';
import { Media, MediaJson } from '../../../Media/Media';
import { WorkoutDayConfiguration, WorkoutDayConfigurationJson } from './WorkoutDayConfiguration';
import { computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';

export type MesoCycleTemplateJson = {
  id: string;
  type: string;
  templateName?: string;
  name: LocalizedValueJson[];
  description: LocalizedValueJson[];
  image?: MediaJson;
  videos: MediaJson[];
  workoutDays: WorkoutDayConfigurationJson[];
  cycles: number;
};

export class MesoCycleTemplate {
  @observable
  id = UUID();
  @observable
  type = 'hypertrophy_1';
  @observable
  templateName?: string;
  @observable
  name: LocalizedValue[] = [];
  @observable
  description: LocalizedValue[] = [];
  @observable
  image?: Media;
  @observable
  videos: Media[] = [];
  @observable
  workoutDays: WorkoutDayConfiguration[] = [];
  @observable
  cycles = 4;

  constructor(json?: Partial<MesoCycleTemplateJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.type = json.type ?? 'hypertrophy_1';
      this.templateName = json.templateName;
      this.name = (json.name ?? []).map((l) => new LocalizedValue(l));
      this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
      this.image = json.image ? new Media(json.image) : undefined;
      this.videos = (json.videos ?? []).map((v) => new Media(v));
      this.workoutDays = (json.workoutDays ?? []).map((d) => new WorkoutDayConfiguration(d));
      this.cycles = json.cycles ?? 4;
    }
  }

  toJS(newId: boolean = false): MesoCycleTemplateJson {
    return {
      id: newId ? UUID() : this.id,
      type: this.type,
      templateName: this.templateName,
      name: this.name.map((n) => n.toJS()),
      description: this.description.map((d) => d.toJS()),
      image: this.image?.toJS(),
      videos: this.videos.map((v) => v.toJS()),
      workoutDays: this.workoutDays.map((d) => d.toJS(newId)),
      cycles: this.cycles,
    };
  }

  @computed
  get minWorkoutDurationInMinutes(): number | undefined {
    return this.workoutDays.reduce(
      (agg, cycle) =>
        cycle.minWorkoutDurationInMinutes
          ? Math.min(cycle.minWorkoutDurationInMinutes, agg ?? Number.MAX_SAFE_INTEGER)
          : agg,
      undefined as number | undefined,
    );
  }

  @computed
  get maxWorkoutDurationInMinutes(): number | undefined {
    return this.workoutDays.reduce(
      (agg, cycle) =>
        cycle.maxWorkoutDurationInMinutes
          ? Math.max(cycle.maxWorkoutDurationInMinutes, agg ?? Number.MIN_SAFE_INTEGER)
          : agg,
      undefined as number | undefined,
    );
  }
}
