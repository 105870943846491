/**
 * Created by neo on 18.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Button, Form } from 'antd';
import { Col, Row } from 'reactstrap';
import { ExploreEntryNotificationMessageEditor } from './ExploreEntryNotificationMessageEditor';
import { runInAction } from 'mobx';
import { NotificationMessage } from '../../../../Model/Explore/NotificationMessage';

export type TabNotificationProps = {
  entry: ExploreEntry;
};

export const TabNotification: React.FC<TabNotificationProps> = observer(({ entry }) => {
  return (
    <SingleColRow style={{ marginBottom: 16 }}>
      <Form.Item label="Notification Messages">
        <Row>
          {entry.notificationMessages.map((notification, index) => (
            <Col key={index.toString()} md={6}>
              <ExploreEntryNotificationMessageEditor entry={entry} message={notification} index={index} />
            </Col>
          ))}
          `
        </Row>
      </Form.Item>
      <SingleColRow>
        <Button
          block
          type="primary"
          onClick={() => runInAction(() => entry.notificationMessages.push(new NotificationMessage()))}
        >
          Add Notification Message
        </Button>
      </SingleColRow>
    </SingleColRow>
  );
});
