import * as React from 'react';

function escapeForRegExp(query) {
  return query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
}

function markIt(input: string, query?: string) {
  const regex = query ? RegExp(escapeForRegExp(query), 'gi') : input;

  return {
    __html: input.replace(regex, '<mark>$&</mark>'),
  };
}

function filterSuggestions(query, suggestions, length) {
  const regex = new RegExp(`(?:^|\\s)${escapeForRegExp(query)}`, 'i');
  return suggestions.filter((item) => regex.test(item.name)).slice(0, length);
}

export interface SuggestionEntry {
  id: string;
  name: string;
}

export type SuggestionsProps = {
  query?: string;
  suggestions?: SuggestionEntry[];
  maxSuggestionsLength?: any;
  addTag?: (item?: any) => any;
  expandable?: boolean;
  classNames?: any;
  selectedIndex?: number;
  listboxId?: any;
};

export class Suggestions extends React.Component<SuggestionsProps> {
  state: {
    options?: any;
  };

  constructor(props: SuggestionsProps) {
    super(props);

    this.state = {
      options: filterSuggestions(this.props.query, this.props.suggestions, this.props.maxSuggestionsLength),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: SuggestionsProps) {
    this.setState({
      options: filterSuggestions(newProps.query, newProps.suggestions, newProps.maxSuggestionsLength),
    });
  }

  handleMouseDown(item, e) {
    // focus is shifted on mouse down but calling preventDefault prevents this
    e.preventDefault();
    this.props.addTag && this.props.addTag(item);
  }

  render() {
    if (!this.props.expandable || !this.state.options.length) {
      return null;
    }

    const options = this.state.options.map((item, i) => {
      const key = `${this.props.listboxId}-${i}`;
      const classNames: any[] = [];

      if (this.props.selectedIndex === i) {
        classNames.push(this.props.classNames.suggestionActive);
      }

      if (item.disabled) {
        classNames.push(this.props.classNames.suggestionDisabled);
      }

      return (
        <li
          id={key}
          key={key}
          role="option"
          className={classNames.join(' ')}
          aria-disabled={item.disabled === true}
          onMouseDown={this.handleMouseDown.bind(this, item)}
        >
          <span dangerouslySetInnerHTML={markIt(item.name, this.props.query)} />
        </li>
      );
    });

    return (
      <div className={this.props.classNames.suggestions}>
        <ul role="listbox" id={this.props.listboxId}>
          {options}
        </ul>
      </div>
    );
  }
}
