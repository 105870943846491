/**
 * Created by neo on 01.12.21.
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { ChallengeMilestone, ChallengeMilestoneJson } from './ChallengeMilestone';
import { Media, MediaJson } from '../Media/Media';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import dayjs, { Dayjs } from 'dayjs';
import {
  GymChallengeNotificationMessage,
  GymChallengeNotificationMessageJson,
} from './GymChallengeNotificationMessage';
import { GymChallengeRankingEntry } from './GymChallengeRankingEntry';
import { GymChallengeParticipant } from './GymChallengeParticipant';
import { Gym } from '../Gym/Gym';
import { Pageable } from '../Pageable';

export type GymChallengeStatus = 'not_started' | 'in_progress' | 'finished' | 'achieved';

export type GymChallengeJson = LocalizedArrayEntityJson & {
  gymId: string;
  name: LocalizedValueJson[];
  description: LocalizedValueJson[];
  images: MediaJson[];
  videos: MediaJson[];
  pointsGoal: number;
  dailyMilestones: ChallengeMilestoneJson[];
  startDateTime: string;
  endDateTime: string;
  /**
   * to determine to start & end of a day
   */
  timezone: string;
  goalAchievedNotificationMessage: GymChallengeNotificationMessageJson;
  totalPoints: number;
};

export class GymChallenge extends LocalizedArrayEntity {
  @observable
  gymId: string = '';
  @observable
  name: LocalizedValue[] = [];
  @observable
  description: LocalizedValue[] = [];
  @observable
  images: Media[] = [];
  @observable
  videos: Media[] = [];
  @observable
  pointsGoal: number = 0;
  @observable
  dailyMilestones: ChallengeMilestone[] = [];
  @observable
  startDateTime = new Date();
  @observable
  endDateTime = dayjs().add(1, 'month').toDate();
  /**
   * to determine to start & end of a day
   */
  @observable
  timezone: string = 'Europe/Zurich';
  @observable
  totalPoints = 0;
  @observable
  goalAchievedNotificationMessage = new GymChallengeNotificationMessage();

  @observable
  gym?: Gym;

  constructor(json?: Partial<GymChallengeJson>) {
    super(json);
    if (json) {
      this.gymId = json.gymId ?? '';
      this.name = (json.name ?? []).map((n) => new LocalizedValue(n));
      this.description = (json.description ?? []).map((n) => new LocalizedValue(n));
      this.images = (json.images ?? []).map((m) => new Media(m));
      this.videos = (json.videos ?? []).map((m) => new Media(m));
      this.pointsGoal = json.pointsGoal ?? 0;
      this.dailyMilestones = (json.dailyMilestones ?? []).map((m) => new ChallengeMilestone(m));
      this.startDateTime = json.startDateTime ? new Date(json.startDateTime) : new Date();
      this.endDateTime = json.endDateTime ? new Date(json.endDateTime) : dayjs().add(1, 'month').toDate();
      this.goalAchievedNotificationMessage = new GymChallengeNotificationMessage(json.goalAchievedNotificationMessage);
      this.totalPoints = json.totalPoints ?? 0;
    }

    onBecomeObserved(this, 'gym', () => {
      if (!this.gym) {
        Gym.get(this.gymId).then((res) => runInAction(() => (this.gym = res)));
      }
    });
  }

  toJS(newId: boolean = false): GymChallengeJson {
    return Object.assign(super.toJS(newId), {
      gymId: this.gymId,
      name: this.name.map((n) => n.toJS()),
      description: this.description.map((n) => n.toJS()),
      images: this.images.map((m) => m.toJS()),
      videos: this.videos.map((m) => m.toJS()),
      pointsGoal: this.pointsGoal,
      dailyMilestones: this.dailyMilestones.sort((a, b) => a.limitInMinutes - b.limitInMinutes).map((m) => m.toJS()),
      startDateTime: this.startDateTime.toISOString(),
      endDateTime: this.endDateTime.toISOString(),
      timezone: this.timezone,
      goalAchievedNotificationMessage: this.goalAchievedNotificationMessage.toJS(),
      totalPoints: this.totalPoints,
    });
  }

  save(): Promise<GymChallenge> {
    return HttpBackend.post(`/engagement/gym/challenges/admin?gymId=${this.gymId}`, this.toJS()).then(() => this);
  }

  delete(): Promise<GymChallenge> {
    return HttpBackend.delete(`/engagement/gym/challenges/admin/${this.id}`).then(() => this);
  }

  fetchRankingAdmin(page: number = 0, size: number = 10): Promise<GymChallengeParticipant[]> {
    return HttpBackend.get(`/engagement/gym/challenges/admin/${this.id}/ranking`, { page, size }).then((res) =>
      (res ?? []).map((r) => new GymChallengeParticipant(r)),
    );
  }

  countRankingAdmin(page: number = 0): Promise<number> {
    return HttpBackend.get(`/engagement/gym/challenges/admin/${this.id}/ranking/count`, { page }).then(
      (res) => res ?? 0,
    );
  }

  @computed
  get valid(): boolean {
    return (
      !!this.gymId.trim() &&
      dayjs(this.endDateTime).isAfter(dayjs(this.startDateTime).add(7, 'days')) &&
      this.pointsGoal > 0 &&
      this.name.every((n) => n.value.trim().length > 0)
    );
  }

  @computed
  get status(): GymChallengeStatus {
    if (dayjs().isBefore(this.startDateTime)) {
      return 'not_started';
    } else if (this.totalPoints >= this.pointsGoal) {
      return 'achieved';
    } else if (dayjs().isBefore(this.endDateTime)) {
      return 'in_progress';
    }
    return 'finished';
  }

  static find(params?: Partial<{ gymId: string } & Pageable>): Promise<GymChallenge[]> {
    return HttpBackend.get(`/engagement/gym/challenges/admin`, params).then((json) =>
      (json ?? []).map((c) => new GymChallenge(c)),
    );
  }

  static count(params?: Partial<{ gymId: string }>): Promise<number> {
    return HttpBackend.get(`/engagement/gym/challenges/admin/count`, params);
  }

  static findOne(challengeId: string): Promise<GymChallenge | undefined> {
    return HttpBackend.get(`/engagement/gym/challenges/admin/${challengeId}`).then((json) =>
      json ? new GymChallenge(json) : undefined,
    );
  }
}
