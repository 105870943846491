import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { BodyPartClusterSortModel } from './BodyPartClusterSortModel';
import { BodyPartClusterSortWidget } from './BodyPartClusterSortWidget';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { BodyPartClusterSortStage } from '../../../../../../../Model/Coach/Stages/BodyPartClusterSortStage';

export class BodyPartClusterSortFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('bodyPartClusterSort');
  }

  generateReactWidget(event): JSX.Element {
    return <BodyPartClusterSortWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new BodyPartClusterSortModel(new BodyPartClusterSortStage(new Pipeline()));
  }
}
