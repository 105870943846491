/**
 * Created by neo on 10.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Button, message, Popconfirm, Space } from 'antd';
import { Pipeline } from '../../../../Model/Coach/Pipeline';
import { useNavigate } from 'react-router-dom';
import { PipelineContext } from '../../../../Model/Coach/PipelineContext';

export type PipelineViewScreenTopButtonsProps = {
  pipeline: Pipeline;
  context: PipelineContext;
  onExecuted?: (result: any) => any;
  onBeforeExecute?: () => any;
};

export const PipelineViewScreenTopButtons: React.FC<PipelineViewScreenTopButtonsProps> = observer(
  ({ pipeline, context, onBeforeExecute, onExecuted }: PipelineViewScreenTopButtonsProps) => {
    const history = useNavigate();

    const handleCopyJsonToClipboard = React.useCallback(() => {
      const json = pipeline.toJS();
      navigator.clipboard.writeText(JSON.stringify(json, null, 4));
    }, [pipeline]);

    const handleDelete = React.useCallback(() => {
      pipeline.delete().then(() => history(-1));
    }, [history, pipeline]);

    const handleSave = React.useCallback(() => {
      pipeline
        .save()
        .then((p) => {
          message.success('Pipeline saved');
          history(`/coach/workout-script/${p.id}`, { replace: true });
        })
        .catch(() => message.error('Error saving pipeline'));
    }, [history, pipeline]);

    const handleExecute = React.useCallback(async () => {
      onBeforeExecute && onBeforeExecute();
      pipeline
        .execute(context)
        .then((result) => {
          console.log('result', result);
          onExecuted && onExecuted(result);
        })
        .catch((error) => {
          console.error(error);
          message.error('Error executing pipeline');
        });
    }, [pipeline, context, onBeforeExecute, onExecuted]);

    return (
      <Space>
        <Popconfirm title={`Delete?`} onConfirm={handleDelete}>
          <Button type="link" danger onClick={handleDelete}>
            Delete
          </Button>
        </Popconfirm>
        <Button type="dashed" onClick={handleCopyJsonToClipboard}>
          Copy JSON to Clipboard
        </Button>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
        <Button type="primary" danger onClick={handleExecute}>
          Execute
        </Button>
      </Space>
    );
  },
);
