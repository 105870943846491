/**
 * Created by neo on 08.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'mdast-util-from-markdown/lib';
import { MarkdownNodeRenderer } from './MarkdownNodeRenderer';
import { ThemeConfig } from '../../Config/Theme';

export type MarkdownLinkRendererProps = {
  content: Link;
  textStyle?: React.CSSProperties;
};

export const MarkdownLinkRenderer: React.FC<MarkdownLinkRendererProps> = observer(({ content, textStyle }) => {
  return (
    <a href={content.url} style={{ ...styles.defaultStyle, ...textStyle }}>
      {content.children.map((child, index) => (
        <MarkdownNodeRenderer key={index.toString()} child={child} />
      ))}
    </a>
  );
});

const styles = {
  defaultStyle: {
    color: ThemeConfig.Colors.black,
    fontSize: 14,
    lineHeight: '1.2',
    flexShrink: 1,
  },
};
