/**
 * Created by neo on 08.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplateRuleSimple } from '../../../../../Model/Engagement/EarnModelTemplateRuleSimple';
import { EarnModelRuleActivationLogicOrBlock } from '../../../../../Model/Engagement/EarnModelRuleActivationLogicOrBlock';
import { Button, Card, Popconfirm, Space } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { runInAction } from 'mobx';
import { RuleLogicBlockEntryEditor } from './RuleLogicBlockEntryEditor';
import { EarnModelRuleActivationLogicEntry } from '../../../../../Model/Engagement/EarnModelRuleActivationLogicEntry';
import { Col, Row } from 'reactstrap';

export type RuleLogicBlockEditorProps = {
  rule: EarnModelTemplateRuleSimple;
  index: number;
  logicBlock: EarnModelRuleActivationLogicOrBlock;
};

export const RuleLogicBlockEditor: React.FC<RuleLogicBlockEditorProps> = observer(({ rule, index, logicBlock }) => {
  const handleUp = React.useCallback(() => {
    runInAction(() => {
      rule.activationLogic.logicBlocks.splice(index, 1);
      rule.activationLogic.logicBlocks.splice(index - 1, 0, logicBlock);
    });
  }, [index, logicBlock, rule]);

  const handleDown = React.useCallback(() => {
    const willBeLast = index + 1 === rule.activationLogic.logicBlocks.length - 1;
    runInAction(() => rule.activationLogic.logicBlocks.splice(index, 1));
    if (willBeLast) {
      runInAction(() => rule.activationLogic.logicBlocks.push(logicBlock));
    } else {
      runInAction(() => rule.activationLogic.logicBlocks.splice(index - 1, 0, logicBlock));
    }
  }, [index, logicBlock, rule]);

  const handleRemove = React.useCallback(
    () => runInAction(() => rule.activationLogic.logicBlocks.splice(index, 1)),
    [rule, index],
  );

  const handleAdd = React.useCallback(
    () =>
      runInAction(() => logicBlock.entries.push(new EarnModelRuleActivationLogicEntry({ event: 'activity_logged' }))),
    [logicBlock],
  );

  return (
    <Card
      size="small"
      title={`${index + 1}. Rule`}
      extra={
        <Space>
          {index > 0 && <Button icon={<ArrowUpOutlined />} onClick={handleUp} />}
          {index < rule.activationLogic.logicBlocks.length - 1 && (
            <Button icon={<ArrowDownOutlined />} onClick={handleDown} />
          )}
          <Button type="link" size="small" onClick={handleAdd}>
            Add Entry (OR)
          </Button>
          <Popconfirm title="Remove Rule?" onConfirm={handleRemove}>
            <Button type="link" size="small">
              Remove
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      {logicBlock.entries.map((entry, idx, arr) => (
        <Row key={index.toString()}>
          <Col xs={12}>
            <RuleLogicBlockEntryEditor
              key={idx.toString()}
              index={idx}
              rule={rule}
              logicBlock={logicBlock}
              entry={entry}
            />
            {idx < arr.length - 1 && <h6 style={{ marginTop: 8, marginBottom: 8, textAlign: 'center' }}>OR</h6>}
          </Col>
        </Row>
      ))}
    </Card>
  );
});
