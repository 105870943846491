/**
 * Created by neo on 12.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import { DomainEvent } from '../../../../../Model/Axon/DomainEvent';

export type ExportKotlinButtonProps = {
  events: DomainEvent[];
  onExport?: (exports: any[]) => any;
};

export const ExportKotlinButton: React.FC<ExportKotlinButtonProps> = observer(
  ({ events, onExport }: ExportKotlinButtonProps) => {
    const handleExportKotlin = React.useCallback(() => {
      if (onExport) {
        exportKotlin(onExport, events);
      }
    }, [onExport, events]);

    const exportKotlin = (onExport, events) => {
      const exports = events
        .sort((d0, d1) => d0.aggregateSequenceNumber - d1.aggregateSequenceNumber)
        .map((r: DomainEvent) => {
          return `${r.payloadTypeShort}("${r.aggregateIdentifier}"${mapParams(r.aggregateType, r.payloadData, [], {
            exerciseId: (value) => `\nExerciseId("${value.variationId}", "${value.sourceType}", "${value.sourceId}")`,
            interval: (value) => (value ? `\nIntervalRoundId("${value.intervalId}", ${value.round})` : 'null'),
            values: (value) =>
              `mapOf(${Object.entries(value).map(([key, val]) => `"${key}" to ${(val as any).toFixed(2)}`)})`,
            met: (value) => value.toFixed(2),
            calories: (value) => value.toFixed(2),
          })})`;
        });
      onExport(exports);
    };

    const mapValue = (key: string, value: any, valueWrapper: { [key: string]: (value) => any }): string => {
      const wrapper = valueWrapper[key];
      if (wrapper) {
        return wrapper(value);
      }
      if (Array.isArray(value)) {
        return `listOf(${value.map((v) => mapValue(key, v, valueWrapper))})`;
      } else if (typeof value === 'object' && !!value) {
        const entries = Object.entries(value);
        return `mapOf(${entries.map(([key, value]) => `"${key}" to ${mapValue(key, value, valueWrapper)}`)})`;
      } else if (typeof value === 'string') {
        return `"${value}"`;
      }
      return `${value}`;
    };

    const mapParams = (
      aggregateType: string,
      payload: any,
      ignoreKeys: string[],
      valueWrapper: { [key: string]: (value) => any },
    ): string => {
      // aggregateIdentifier = "00815281-37ee-4ee7-a1f9-56e61cece8e4"
      const aggregegateIdKey = `${aggregateType.toLowerCase()}id`;
      const entries = Array.from(Object.entries(payload));
      if (entries.length) {
        return entries.reduce((acc: string, [key, value]) => {
          console.log(key.toLowerCase(), aggregegateIdKey, key.toLowerCase() !== aggregegateIdKey);
          return key.toLowerCase() !== aggregegateIdKey || -1 !== ignoreKeys.indexOf(key)
            ? `${acc}, ${mapValue(key, value, valueWrapper)}`
            : acc;
        }, '');
      }
      return '';
    };

    return events.length > 0 ? (
      <Button color="info" onClick={handleExportKotlin}>
        Export Kotlin
      </Button>
    ) : null;
  },
);
