/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import {
  computed,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry } from './ActivityWorkoutEntry';
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { NthDayOfMonth, RecurringPattern, RecurringPatternJson } from '../Coach/Schedule/RecurringPattern';
import { ZoomJoinMeetingClientParams } from './ZoomJoinMeetingClientParams';
import dayjs, { Dayjs } from 'dayjs';
import { Activity } from '../Activity/Activity';
import { Instructor, InstructorJson } from './Instructor';
import { DayOfWeek, DayOfWeekList, IsoDayOfWeekList } from '../DayOfWeek';
import { EMPTY_ARRAY } from '../../Utils/Constants';

export type SubscribableEntryJson = ExploreEntryJson & {
  hosts: InstructorJson[];
  startDate: string;
  startTime: string;
  timezone: string;
  duration: string;
  recurringPattern?: RecurringPatternJson;
};

export class SubscribableEntry extends ExploreEntry {
  @observable
  hosts: Instructor[] = [];
  @observable
  startDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
  @observable
  startTime = dayjs().add(1, 'day').format('HH:mm:ss');
  @observable
  timezone = 'Europe/Zurich';
  @observable
  duration = 'PT1H';
  @observable
  recurringPattern?: RecurringPattern;

  constructor(json?: Partial<SubscribableEntryJson>) {
    super(json);
    if (json) {
      this.type = 'zoomMeeting';
      this.hosts = (json.hosts ?? []).map((h) => new Instructor(h));
      this.startDate = json.startDate ?? dayjs().add(1, 'day').format('YYYY-MM-DD');
      this.startTime = json.startTime ?? dayjs().add(1, 'day').format('HH:mm:ss');
      this.timezone = json.timezone ?? dayjs.tz.guess();
      this.duration = json.duration ?? 'PT1H';
      this.recurringPattern = json.recurringPattern ? new RecurringPattern(json.recurringPattern) : undefined;
    }
  }

  toJS(newId: boolean = false): SubscribableEntryJson {
    return Object.assign(super.toJS(newId), {
      hosts: this.hosts.map((h) => h.toJS()),
      startDate: this.startDate,
      startTime: this.startTime,
      timezone: this.timezone,
      duration: this.duration,
      recurringPattern: this.recurringPattern?.toJS(),
    });
  }

  exportCsvSubscribed() {
    return HttpBackend.get(`/coach/schedule/event/subscription/exportCsv`, { objectId: this.id });
  }

  countSubscribed() {
    return HttpBackend.get(`/coach/schedule/event/subscription/count`, { objectId: this.id });
  }

  @computed
  get startTimeFixed(): string | undefined {
    if (this.startTime) {
      if (this.startTime?.length === 5) {
        return `${this.startTime}:00`;
      } else if ((this.startTime?.length ?? 0) >= 8) {
        return this.startTime.substr(0, 8);
      }
    }
    return undefined;
  }

  @computed
  get startDateTime(): Dayjs | undefined {
    if (this.startDate) {
      const time = this.startTimeFixed ?? '00:00';
      return dayjs.tz(`${this.startDate} ${time}`, this.timezone);
    }
    return undefined;
  }

  @computed
  get localStartDateTime(): Dayjs | undefined {
    if (this.startDateTime) {
      return dayjs(this.startDateTime.toDate());
    }
    return undefined;
  }

  @computed
  get durationDayjs(): Dayjs {
    return dayjs.utc(0).add(dayjs.duration(this.duration));
  }

  @computed
  get possibleDates(): Dayjs[] {
    const { localStartDateTime: startDateTime } = this;
    if (startDateTime) {
      return this.recurringPattern?.calculatePossibleDates(startDateTime) ?? EMPTY_ARRAY;
    }
    return EMPTY_ARRAY;
  }

  static find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<SubscribableEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'zoomMeeting' })).then((res) =>
      (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static findOne(id: string): Promise<SubscribableEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new SubscribableEntry(res));
  }
}
