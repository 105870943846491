import { computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { FeedObjectCollectionItemData } from './FeedObjectCollectionItemData';

export class FeedObjectCollectionItem {
  @observable
  id: string = UUID();
  @observable
  collection: string = 'article';
  @observable
  data: FeedObjectCollectionItemData = new FeedObjectCollectionItemData();

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.collection = json.collection || 'article';
      this.data = new FeedObjectCollectionItemData(json.data || {});
    }
  }

  @computed
  get valid() {
    return this.data.valid;
  }
}
