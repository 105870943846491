/**
 * Created by neo on 09.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { ListItem } from 'mdast-util-from-markdown/dev/lib';
import { MarkdownNodeRenderer } from './MarkdownNodeRenderer';

export type MarkdownListItemRendererProps = {
  content: ListItem;
  textStyle?: React.CSSProperties;
};

export const MarkdownListItemRenderer: React.FC<MarkdownListItemRendererProps> = observer(({ content, textStyle }) => {
  const { classes } = useStyles();

  return (
    <li className={classes.container}>
      {content.children.map((child, index) => (
        <MarkdownNodeRenderer key={index.toString()} child={child} textStyle={textStyle} />
      ))}
    </li>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: 'row',
    marginVertical: 2,
  },
}));
