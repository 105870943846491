/**
 * Created by neo on 03.06.22.
 */
import { Audited, AuditedJson } from '../Audited';
import { computed, observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export type TranslationKeyJson = AuditedJson & {
  language: string;
  namespace: string;
  version: string;
  key: string;
  value: string;
  checkedInternal: boolean;
  checkedExternal: boolean;
};

export class TranslationKey extends Audited {
  @observable
  language = 'de';
  @observable
  namespace = 'translation';
  @observable
  version = 'latest';
  @observable
  key = '';
  @observable
  value = '';
  @observable
  checkedInternal = false;
  @observable
  checkedExternal = false;

  constructor(json?: Partial<TranslationKeyJson>) {
    super(json);
    if (json) {
      this.language = json.language ?? 'de';
      this.namespace = json.namespace ?? 'coach-app';
      this.version = json.version ?? 'latest';
      this.key = json.key ?? '';
      this.value = json.value ?? '';
      this.checkedInternal = json.checkedInternal ?? false;
      this.checkedExternal = json.checkedExternal ?? false;
    }
  }

  toJS(newId?: boolean): TranslationKeyJson {
    return Object.assign(super.toJS(newId), {
      language: this.language,
      namespace: this.namespace,
      version: this.version,
      key: this.key,
      value: this.value,
      checkedInternal: this.checkedInternal,
      checkedExternal: this.checkedExternal,
    });
  }

  save(): Promise<TranslationKey> {
    return HttpBackend.post(`/translation/admin`, this.toJS()).then(() => this);
  }

  delete(): Promise<TranslationKey> {
    return HttpBackend.delete(`/translation/admin/${this.id}?namespace=${this.namespace}`).then(() => this);
  }

  @computed
  get validationState(): 'error' | 'warning' | undefined {
    return this.key === this.value.trim() ? 'error' : !this.value.trim() ? 'warning' : undefined;
  }

  static findOne(keyOrId: string, namespace?: string): Promise<TranslationKey | undefined> {
    return HttpBackend.get(`/translation/admin/${keyOrId}?namespace=${namespace}`).then((res) =>
      res ? new TranslationKey(res) : undefined,
    );
  }
}
