/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Media } from '../../../../../../Model/Media/Media';
import { Button, Card, Checkbox, Form, Input, InputNumber, Space } from 'antd';
import { Col, Row } from 'reactstrap';
import { MediaLoop } from '../../../../../../Model/Media/MediaLoop';
import { runInAction } from 'mobx';
import CreatableSelect from 'react-select/creatable';

export type MediaLoopEntryProps = {
  media: Media;
  loop: MediaLoop;
  index: number;
  maxSeconds?: number;
  store: {
    currentSeek;
  };
};

export const MediaLoopEntry: React.FC<MediaLoopEntryProps> = observer(
  ({ media, loop, index, maxSeconds, store }: MediaLoopEntryProps) => {
    const handleKeyDownStart = React.useCallback(
      (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowRight') {
          loop.setStartSeconds(loop.startSeconds + 0.05);
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowLeft') {
          loop.setStartSeconds(Math.max(0, loop.startSeconds - 0.05));
        }
      },
      [loop],
    );

    const handleKeyDownEnd = React.useCallback(
      (event) => {
        if (loop.endSeconds) {
          if (event.key === 'ArrowUp' || event.key === 'ArrowRight') {
            loop.setEndSeconds(loop.endSeconds + 0.05);
          } else if (event.key === 'ArrowDown' || event.key === 'ArrowLeft') {
            loop.setEndSeconds(Math.max(0, loop.endSeconds - 0.05));
          }
        } else {
          loop.setEndSeconds(loop.startSeconds);
        }
      },
      [loop],
    );

    const handleSetCurrentSeek = React.useCallback(() => {
      loop.setStartSeconds(store.currentSeek);
    }, [store, loop]);

    const handleSetEndTime = React.useCallback(() => {
      loop.setEndSeconds(store.currentSeek);
    }, [loop, store]);

    const handleRemove = React.useCallback(() => {
      media.loops.splice(index, 1);
    }, [media, index]);

    const handleChangeTag = React.useCallback(
      (tag: any) => {
        runInAction(() => (loop.tag = tag?.value));
      },
      [loop],
    );

    const handleChangeLoopCount = React.useCallback(
      (value: number | string | undefined | null) => {
        runInAction(() => (loop.loopCount = value !== undefined && value !== null ? Number(value) : -1));
      },
      [loop],
    );

    const handleChangeDynamicLoopCount = React.useCallback(
      ({ target: { checked } }) => {
        runInAction(() => (loop.dynamicLoopCount = checked));
      },
      [loop],
    );

    return (
      <Form layout="vertical">
        <Card
          extra={
            <Button type="link" danger onClick={handleRemove}>
              Remove
            </Button>
          }
        >
          <Row>
            <Col>
              <Form.Item label="Tag (optional)">
                <CreatableSelect
                  isClearable
                  options={[
                    { value: 'exercise', label: 'exercise' },
                    { value: 'break', label: 'break' },
                  ]}
                  onChange={handleChangeTag as any}
                  value={loop.tag ? { value: loop.tag, label: loop.tag } : undefined}
                  placeholder="Included Tags"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Start Time">
                <Input
                  value={loop.startTime}
                  onKeyDown={handleKeyDownStart}
                  addonAfter={
                    <Button type="link" onClick={handleSetCurrentSeek}>
                      Set
                    </Button>
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="End Time">
                <Input
                  value={loop.endTime}
                  onKeyDown={handleKeyDownEnd}
                  addonAfter={
                    <Button type="link" onClick={handleSetEndTime}>
                      Set
                    </Button>
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Loop Count dynamic" extra="e.g. loopCount = repetitions">
                <Checkbox onChange={handleChangeDynamicLoopCount} checked={loop.dynamicLoopCount}>
                  Dynamic
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Loop Count" extra="Repeat how often? -1 = forever">
                <InputNumber
                  value={loop.loopCount}
                  onChange={handleChangeLoopCount}
                  min={-1}
                  step={1}
                  disabled={loop.dynamicLoopCount}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  },
);
