/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Tabs } from 'antd';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { GymChallenge } from '../../../Model/GymChallenge/GymChallenge';
import { GymChallengeModalParticipantTab } from './GymChallengeModalParticipantTab';
import { GymChallengeModalBasicInfoTab } from './GymChallengeModalBasicInfoTab';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type GymChallengeModalProps = {
  onSaved?: (challenge: GymChallenge) => void;
  onDeleted?: (challenge: GymChallenge) => void;
};

export const GymChallengeModal: React.FC<GymChallengeModalProps> = observer(({ onSaved, onDeleted }) => {
  const history = useNavigate();
  const { challengeId } = useParams<{ challengeId: string }>();
  const [challenge, setChallenge] = useState(new GymChallenge());
  const [processing, setProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('general');

  const isNew = challengeId === 'new';

  const tabItems = React.useMemo(
    () => [
      { label: 'General', key: 'general', children: <GymChallengeModalBasicInfoTab challenge={challenge} /> },
      {
        label: 'Participants',
        key: 'participants',
        children: <GymChallengeModalParticipantTab challenge={challenge} />,
      },
    ],
    [challenge],
  );

  useEffect(() => {
    if (challengeId && challengeId !== 'new') {
      GymChallenge.findOne(challengeId).then((result) => setChallenge(result ?? new GymChallenge()));
    } else {
      setChallenge(new GymChallenge());
    }
  }, [challengeId]);

  const handleCancel = React.useCallback(() => history(-1), [history]);

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    onSaved && onSaved(challenge);
    challenge
      .save()
      .finally(() => setProcessing(false))
      .then(() => history(-1));
  }, [challenge, onSaved, history]);

  const handleDelete = React.useCallback(() => {
    setProcessing(true);
    onDeleted && onDeleted(challenge);
    challenge
      .delete()
      .finally(() => setProcessing(false))
      .then(() => history(-1));
  }, [challenge, history, onDeleted]);

  const handleChangeTab = React.useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  const handleExportCsv = React.useCallback(() => {
    setProcessing(true);
    HttpBackend.get(`/engagement/gym/challenges/admin/${challenge.id}/exportCsv`)
      .then((res) => new Blob([res], { type: 'text/plain;charset=iso-8859-1' }))
      .then((blob) => saveAs(blob, `${challenge.getName('de')}-customers-${dayjs().format('YYYYMMDDHHmm')}.csv`))
      .finally(() => setProcessing(false));
  }, [challenge]);

  return (
    <Modal
      open={true}
      title={isNew ? 'New Challenge' : challenge.defaultName}
      onCancel={handleCancel}
      footer={[
        <React.Fragment key="0">
          {!isNew && (
            <Button key="0" onClick={handleExportCsv} disabled={processing}>
              Export CSV
            </Button>
          )}
        </React.Fragment>,
        <Button key="1" onClick={handleCancel} disabled={processing}>
          Close
        </Button>,
      ]}
      confirmLoading={processing}
      width={window.innerWidth * 0.75}
      style={{ paddingBottom: 64 }}
    >
      <Tabs
        activeKey={activeTab}
        onChange={handleChangeTab}
        size="small"
        items={tabItems}
        destroyInactiveTabPane={true}
      />
    </Modal>
  );
});
