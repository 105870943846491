import { DefaultStageNodeModel } from '../../Default/DefaultStageNodeModel';
import { GreaterThanStage } from '../../../../../../../../Model/Coach/Stages/Logical/GreaterThanStage';

export class GreaterThanNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: GreaterThanStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'logical:gt' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
