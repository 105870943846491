/**
 * Created by neo on 07.05.21.
 */
import { TagCondition, TagConditionJson } from '../SuperMacro/TagCondition';
import { GoogleTtsRequest, GoogleTtsRequestJson } from './GoogleTtsRequest';
import { computed, observable, toJS } from 'mobx';
import { Audited, AuditedJson } from '../../Audited';
import { Pageable } from '../../Pageable';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { Gender } from '../../Person/Gender';

const VARIABLES_REGEX = /(\{\{.*?\}\})/gi;

export type CoachTtsQueryRequest = Pageable & {
  identifier?: string;
  language?: string;
  tags?: string[];
};

export type CoachTtsJson = AuditedJson & {
  identifier: string;
  language: string;
  gender?: Gender;
  tags: string[];
  tagConditions: TagConditionJson[];
  googleTtsRequest: Partial<GoogleTtsRequestJson>;
};

export class CoachTts extends Audited {
  @observable
  identifier = '';
  @observable
  language = 'de';
  @observable
  gender?: Gender;
  @observable
  tags: string[] = [];
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  googleTtsRequest = new GoogleTtsRequest();
  @observable
  testValues: Record<string, string> = {};

  constructor(json?: Partial<CoachTtsJson>) {
    super(json);
    if (json) {
      this.identifier = json.identifier ?? '';
      this.language = json.language ?? 'de';
      this.gender = json.gender;
      this.tags = json.tags ?? [];
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.googleTtsRequest = new GoogleTtsRequest(json.googleTtsRequest);
    }
  }

  save(): Promise<CoachTts> {
    return HttpBackend.post('/coach/text-to-speech/admin', this.toJS()).then(() => this);
  }

  delete(): Promise<CoachTts> {
    return HttpBackend.delete(`/coach/text-to-speech/admin/${this.id}`).then(() => this);
  }

  test(voiceIdentifier: string): Promise<string> {
    return HttpBackend.post(`/coach/text-to-speech/admin/test`, Object.assign(this.toJS(), { values: toJS(this.testValues), voiceIdentifier }));
  }

  toJS(newId?: boolean): CoachTtsJson {
    return Object.assign(super.toJS(newId), {
      identifier: this.identifier,
      language: this.language,
      gender: this.gender,
      tags: this.tags,
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      googleTtsRequest: this.googleTtsRequest.toJS(),
    });
  }

  @computed
  get ssmlOrText(): string | undefined {
    return this.googleTtsRequest.input.ssml || this.googleTtsRequest.input.text;
  }

  @computed
  get variables(): string[] {
    console.log('matches', (this.ssmlOrText || '').match(VARIABLES_REGEX) ?? []);
    return ((this.ssmlOrText || '').match(VARIABLES_REGEX) ?? []).map((t) =>
      t.replace('{{', '').replace('}}', '').trim(),
    );
  }

  static find(request?: Partial<CoachTtsQueryRequest>): Promise<CoachTts[]> {
    return HttpBackend.get(`/coach/text-to-speech/admin`, request).then((res) => (res ?? []).map((t) => new CoachTts(t)));
  }

  static count(request: CoachTtsQueryRequest): Promise<number> {
    return HttpBackend.get(`/coach/text-to-speech/admin/count`, request);
  }

  static findOne(id: string): Promise<CoachTts> {
    return HttpBackend.get(`/coach/text-to-speech/admin/${id}`).then((res) => new CoachTts(res));
  }
}
