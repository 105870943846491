import * as React from 'react';

const styles = {
  footer: {
    padding: '0.5rem',
    fontSize: '1rem',
    backgroundColor: '#1f1f1f',
    textAlign: 'center',
    color: 'white',
  },
};

export class Footer extends React.Component {
  render() {
    return (
      <footer style={styles.footer as any} className="fixed-bottom footer">
        &copy; 2020 - kinastic AG
      </footer>
    );
  }
}
