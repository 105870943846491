/**
 * Created by neo on 08.12.22.
 */
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { observer } from 'mobx-react';
import { Emphasis } from 'mdast-util-from-markdown/lib';
import { MarkdownNodeRenderer } from './MarkdownNodeRenderer';

export type MarkdownEmphasisRendererProps = {
  content: Emphasis;
  textStyle?: React.CSSProperties;
};

export const MarkdownEmphasisRenderer: React.FC<MarkdownEmphasisRendererProps> = observer(({ content, textStyle }) => {
  const { classes } = useStyles();

  return (
    <span className={classes.container} style={textStyle}>
      {content.children.map((child, index) => (
        <MarkdownNodeRenderer key={index.toString()} child={child} />
      ))}
    </span>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    fontStyle: 'italic',
  },
}));
