import { Stage } from './Stage';
import { Phase } from '../../ProgramPortfolio/Phase';
import { Workout } from '../../ProgramPortfolio/Workout';
import { DataSignal, dataSignal } from '../Signal/DataSignal';

export interface WorkoutStageConfig {
  type?: string;
  // name?: LocalizedValue[];
  name?: string;
  // description?: LocalizedValue[];
  description?: string;
}

export class WorkoutStage extends Stage<WorkoutStageConfig> {
  process() {
    const phases = this.dataSignals.map((s) => s.data).filter((p) => p instanceof Phase);
    this.processNext(
      new DataSignal(
        new Workout({
          name: this.config.name,
          description: this.config.description,
          phases,
        }),
        0,
      ),
    );
  }

  get type(): string {
    return 'workout';
  }
}
