/**
 * Created by neo on 05.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategory } from '../../../../../Model/Engagement/EarnModelTemplateCategory';
import { EarnModelTemplateRuleGrouped } from '../../../../../Model/Engagement/EarnModelTemplateRuleGrouped';

export type GroupedRuleEditorTabProps = {
  template: EarnModelTemplate;
  category: EarnModelTemplateCategory;
  rule: EarnModelTemplateRuleGrouped;
};

export const GroupedRuleEditorTab: React.FC<GroupedRuleEditorTabProps> = observer(({ template, category, rule }) => {
  // const handleAdd = React.useCallback(
  //   () =>
  //     runInAction(() =>
  //       rule.activationLogic.logicBlocks.push(
  //         new EarnModelRuleActivationLogicOrBlock({ entries: [{ count: 1, event: 'activity_logged' }] }),
  //       ),
  //     ),
  //   [rule],
  // );
  //
  // return (
  //   <Row>
  //     <Col>
  //       <h6>The rules will be applied in order (first must match then the second etc.)</h6>
  //     </Col>
  //     <Col xs="auto">
  //       <Button type="primary" onClick={handleAdd}>
  //         Add Rule (AND)
  //       </Button>
  //     </Col>
  //     <Col xs={12}>
  //       {rule.activationLogic.logicBlocks.map((logicBlock, index, arr) => (
  //         <Row key={index.toString()}>
  //           <Col xs={12}>
  //             <RuleLogicBlockEditor index={index} rule={rule} logicBlock={logicBlock} />
  //             {index < arr.length - 1 && <h5 style={{ marginTop: 8, marginBottom: 8, textAlign: 'center' }}>AND</h5>}
  //           </Col>
  //         </Row>
  //       ))}
  //     </Col>
  //   </Row>
  // );

  return null;
});
