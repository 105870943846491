/**
 * Created by neo on 18.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Form, InputNumber, Select, TimePicker } from 'antd';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';
import { useParams } from 'react-router-dom';
import { MindfulnessProgramTemplateDayUnit } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnit';
import { notUndefined } from '../../../../../../Utils/notUndefined';
import { MindfulnessProgramTemplateDayUnitBuilder } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitBuilder';
import { TranslationInputArray } from '../../../../../../Components/Translation/TranslationInputArray';
import { ExploreEntryModalMediaField } from '../../../../Explore/ExploreEntryModalMediaField';
import { Media } from '../../../../../../Model/Media/Media';
import AsyncSelect from 'react-select/async';
import { Activity } from '../../../../../../Model/Activity/Activity';
import dayjs, { Dayjs } from 'dayjs';
import { TimeRange } from '../../../../../../Model/Gym/TimeRange';

const allowedTags = [
  'duration:short',
  'duration:medium',
  'duration:long',
  'age:junior',
  'age:adult',
  'age:senior',
  'age:elder',
  'gender:male',
  'gender:female',
  'activity:sitting',
  'activity:standing',
  'activity:physical',
  'equipment:yes',
  'equipment:no',
  'equipment:bottle',
  'level:beginner',
  'level:intermediate',
  'level:advanced',
].sort((a, b) => a.localeCompare(b));

const defaultOptions = allowedTags.map((value) => ({ value, label: value }));

export type TabBasicInfoProps = {
  entry: MindfulnessProgramTemplateDayUnit;
  onEntryChanged: (newEntry: MindfulnessProgramTemplateDayUnit) => void;
};

export const TabBasicInfo: React.FC<TabBasicInfoProps> = observer(({ entry, onEntryChanged }) => {
  const { contentId } = useParams<{ contentId: string }>();

  const includedTags = entry.includedTags.map((value) => ({ label: value, value }));
  const includedTagCategories = entry.includedTags.map((t) => t.split(':')[0]).filter(notUndefined);

  const allowedIncludedTags = defaultOptions.filter(
    ({ value }) => !includedTagCategories.some((cat) => value.startsWith(cat)),
  );

  const isNew = contentId === 'new';

  const handleChangeType = React.useCallback(
    (type) => {
      if (entry.type !== type) {
        onEntryChanged(MindfulnessProgramTemplateDayUnitBuilder.build(Object.assign({}, entry.toJS(), { type })));
      }
    },
    [entry, onEntryChanged],
  );

  const handleChangeTimeOfDay = React.useCallback(
    (type) => {
      runInAction(() => (entry.timeOfDay = type));
    },
    [entry],
  );

  const handleTagsChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        entry.includedTags.splice(0, entry.includedTags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => entry.includedTags.push(t));
      });
    },
    [entry],
  );

  const handleChangeDuration = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        runInAction(() => (entry.durationInMinutes = Number(value)));
      }
    },
    [entry],
  );

  const handleImageUploaded = React.useCallback(
    (media: Media) => {
      runInAction(() => {
        entry.image = media;
      });
    },
    [entry],
  );

  const handleRemoveImage = React.useCallback(() => {
    runInAction(() => (entry.image = undefined));
  }, [entry]);

  const handleChangeActivity = React.useCallback(
    (activity: Activity | undefined) => {
      entry.activityIdentifier = activity?.identifier ?? '';
    },
    [entry],
  );

  const fetch = React.useCallback((query: string) => Activity.find({ query }), []);

  const getOptionLabel = React.useCallback((option: Activity) => `${option.defaultName} (${option.identifier})`, []);

  const getOptionValue = React.useCallback((option: Activity) => option, []);

  const handleChangeDefaultTime = React.useCallback(
    (date, dateString) => {
      if (dateString) {
        runInAction(() => (entry.defaultTime = new TimeRange({ from: dateString })));
      } else {
        runInAction(() => (entry.defaultTime = undefined));
      }
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col md={4}>
          <Form.Item label="Type">
            <Select value={entry.type} onChange={handleChangeType}>
              {/*<Select.Option value="article">Article</Select.Option>*/}
              <Select.Option value="audio">Audio</Select.Option>
              <Select.Option value="video">Video</Select.Option>
              {/*<Select.Option value="videoSeries">Video Series</Select.Option>*/}
              <Select.Option value="workoutTemplate">Workout Template</Select.Option>
              {/*<Select.Option value="meditation">Meditation</Select.Option>*/}
              <Select.Option value="breathing">Breathing</Select.Option>
              {/*<Select.Option value="yoga">Yoga</Select.Option>*/}
              {/*<Select.Option value="link">Link</Select.Option>*/}
            </Select>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label="Time of Day">
            <Select value={entry.timeOfDay} onChange={handleChangeTimeOfDay}>
              <Select.Option value="any">Any time</Select.Option>
              <Select.Option value="afterWakeUp">After Wake Up</Select.Option>
              <Select.Option value="morning">Morning</Select.Option>
              <Select.Option value="noon">Noon</Select.Option>
              <Select.Option value="afternoon">Afternoon</Select.Option>
              <Select.Option value="evening">Evening</Select.Option>
              <Select.Option value="beforeBedTime">Before Bed Time</Select.Option>
              <Select.Option value="night">Night</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {/*<Col md={3}>*/}
        {/*  <Form.Item label="Default Time" extra="Specify a default time (optional)">*/}
        {/*    <TimePicker*/}
        {/*      onChange={handleChangeDefaultTime}*/}
        {/*      format="HH:mm"*/}
        {/*      minuteStep={15}*/}
        {/*      allowClear={true}*/}
        {/*      value={entry.defaultTime?.fromFixed ? dayjs(entry.defaultTime?.from, 'HH:mm:ss') : undefined}*/}
        {/*    />*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        <Col md={4}>
          <Form.Item label="Duration in Minutes">
            <InputNumber value={entry.durationInMinutes} onChange={handleChangeDuration} />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label="Activity" extra="REQUIRED">
            <AsyncSelect
              defaultOptions
              isClearable={true}
              loadOptions={fetch}
              value={entry.activity}
              hideSelectedOptions={false}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue as any}
              onChange={handleChangeActivity as any}
            />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label="Tags">
            <CreatableSelect
              isClearable
              isMulti
              options={allowedIncludedTags}
              onChange={handleTagsChange as any}
              value={includedTags}
              placeholder="Tags"
            />
          </Form.Item>
        </Col>
        <Col md={4}>
          <ExploreEntryModalMediaField
            label="Image"
            onRemove={handleRemoveImage}
            onUploaded={handleImageUploaded}
            media={entry.image}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <TranslationInputArray entity={entry} field="name" label="Name" />
        </Col>
        <Col xs={6}>
          <TranslationInputArray
            entity={entry}
            type="textarea"
            field="description"
            label="Description"
          />
        </Col>
        {/*<Col xs={3}>*/}
        {/*  <TranslationInputArray entity={entry} type="textarea" field="quotes" label="Quotes" noDefault={true} />*/}
        {/*</Col>*/}
      </Row>
      <Row></Row>
      {/*<SingleColRow>*/}
      {/*  <Form.Item label="Tag Conditions">*/}
      {/*    <TagConditionsInput tagConditions={entry.tagConditions} />*/}
      {/*  </Form.Item>*/}
      {/*</SingleColRow>*/}
    </React.Fragment>
  );
});
