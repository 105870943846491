/**
 * Created by neo on 05.08.22.
 */
import { NthDayOfMonth, RecurringPatternType } from '../Coach/Schedule/RecurringPattern';
import { DayOfWeek } from '../DayOfWeek';
import { observable } from 'mobx';
import { EarnModelRuleActivationFrequencyTriggerWindow } from './EarnModelRuleActivationFrequencyTriggerWindow';

export type EarnModelRuleTimeConstraintJson = {
  triggerWindow: EarnModelRuleActivationFrequencyTriggerWindow;
  type: RecurringPatternType;
  separationCount: number;
  /**
   * How often during that window can the rule be activated. like twice a day or once a month or twice a month
   */
  count: number;
  daysOfWeek: DayOfWeek[];
  weekOfMonth?: number;
  dayOfMonth?: number;
  monthOfYear?: number;
  nthDayOfTheMonth?: NthDayOfMonth;
  weekDayOfMonth?: DayOfWeek;
  validFrom?: string;
  validUntil?: string;
};

export class EarnModelRuleTimeConstraint {
  @observable
  triggerWindow: EarnModelRuleActivationFrequencyTriggerWindow = 'any';
  @observable
  type: RecurringPatternType = 'daily';
  @observable
  separationCount: number = 1;
  /**
   * How often during that window can the rule be activated. like twice a day or once a month or twice a month
   */
  @observable
  count: number = -1;
  @observable
  daysOfWeek: DayOfWeek[] = [];
  @observable
  weekOfMonth?: number;
  @observable
  dayOfMonth?: number;
  @observable
  monthOfYear?: number;
  @observable
  nthDayOfTheMonth?: NthDayOfMonth;
  @observable
  weekDayOfMonth?: DayOfWeek;
  @observable
  validFrom?: Date;
  @observable
  validUntil?: Date;

  constructor(json?: Partial<EarnModelRuleTimeConstraintJson>) {
    if (json) {
      this.triggerWindow = json.triggerWindow ?? 'any';
      this.type = json.type ?? 'daily';
      this.separationCount = json.separationCount ?? 1;
      this.count = json.count ?? -1;
      this.daysOfWeek = json.daysOfWeek ?? [];
      this.weekOfMonth = json.weekOfMonth;
      this.dayOfMonth = json.dayOfMonth;
      this.nthDayOfTheMonth = json.nthDayOfTheMonth;
      this.weekDayOfMonth = json.weekDayOfMonth;
      this.validFrom = json.validFrom ? new Date(json.validFrom) : undefined;
      this.validUntil = json.validUntil ? new Date(json.validUntil) : undefined;
    }
  }

  toJS(): EarnModelRuleTimeConstraintJson {
    return {
      triggerWindow: this.triggerWindow,
      type: this.type,
      separationCount: this.separationCount,
      count: this.count,
      daysOfWeek: this.daysOfWeek,
      weekOfMonth: this.weekOfMonth,
      dayOfMonth: this.dayOfMonth,
      monthOfYear: this.monthOfYear,
      nthDayOfTheMonth: this.nthDayOfTheMonth,
      weekDayOfMonth: this.weekDayOfMonth,
      validFrom: this.validFrom?.toISOString(),
      validUntil: this.validUntil?.toISOString(),
    };
  }
}
