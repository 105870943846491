/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useParams, useNavigate, useLocation, useMatch } from 'react-router-dom';
import { observer } from 'mobx-react';

export type RouterTabNavItemProps = {
  tabKey?: string;
  label: string;
  baseUrl: string;
};

export const RouterTabNavItem: React.FC<RouterTabNavItemProps> = observer(({ tabKey, label, baseUrl }) => {
  const { pathname } = useLocation();
  const history = useNavigate();
  const fullUrl = `${baseUrl}${tabKey ? `/${tabKey}` : ''}`;
  const active = React.useMemo(() => fullUrl === pathname, [pathname, fullUrl]);

  const handleClick = React.useCallback(() => {
    history(`${baseUrl}${tabKey ? `/${tabKey}` : ''}`, { replace: true });
  }, [baseUrl, history, tabKey]);

  return (
    <NavItem>
      <NavLink className={classnames({ active })} onClick={handleClick}>
        {label}
      </NavLink>
    </NavItem>
  );
});
