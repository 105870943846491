/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Branch } from '../../../../../Model/Gym/Branch';
import { DayOfWeek } from '../../../../../Model/Gym/DayOfWeek';
import { OpeningHoursListEntry } from './OpeningHoursListEntry';
import { EditOpeningHoursModal } from './EditOpeningHoursModal';

export type Props = {
  branch: Branch;
};

@observer
export class OpeningHoursList extends React.Component<Props> {
  @observable
  openModal: boolean = false;
  @observable
  selectedDay: string = '';

  onEdit = (dayKey: string) => {
    this.selectedDay = dayKey;
    this.openModal = true;
  };

  onSave = (from: string, to: string) => {
    const { branch } = this.props;
    branch.branchInfo.openingHours.setTimeRangeFrom(this.selectedDay, from, to);
    this.onCancel();
  };

  onCancel = () => {
    this.openModal = false;
    this.selectedDay = '';
  };

  render() {
    const { branch } = this.props;
    return (
      <Row>
        <Col>
          <h3>Opening Hours</h3>
          {DayOfWeek.map((d, i) => (
            <OpeningHoursListEntry key={d.key} branch={branch} dayKey={d.key} onEdit={this.onEdit} />
          ))}
        </Col>
        {/*<EditOpeningHoursModal onSave={this.onSave} openModal={this.openModal} onCancel={this.onCancel} />*/}
      </Row>
    );
  }
}
