import * as React from 'react';
import { observer } from 'mobx-react';
import ReactJson from 'react-json-view';
import { AxonSaga } from '../../../../../Model/Axon/AxonSaga';
import { AxonSagaAssociation } from '../../../../../Model/Axon/AxonSagaAssociation';
import { observable } from 'mobx';

export interface AxonSnapshotTableRowProps {
  saga: AxonSaga;
}

@observer
export class AxonSagaTableRow extends React.Component<AxonSnapshotTableRowProps> {
  @observable
  json?: any = undefined;

  componentDidMount(): void {
    this.parse();
  }

  parse() {
    // xml2js.parseString(this.props.saga.serializedSaga, (err, result) => {
    //   if (err) {
    //     console.error(err);
    //   } else {
    //     this.json = result;
    //   }
    // });
  }

  render() {
    const {
      props: { saga: result },
    } = this;
    return (
      <tr>
        <td>{result.sagaIdentifier}</td>
        <td>{result.sagaTypeShort}</td>
        <td>
          {this.json ? <ReactJson src={this.json} displayDataTypes={false} sortKeys={true} collapsed={3} /> : null}
        </td>
        <td>
          {result.associations.map((a: AxonSagaAssociation) => (
            <React.Fragment key={a.key}>
              <b>{a.key}</b> {a.value}
              <br />
            </React.Fragment>
          ))}
        </td>
      </tr>
    );
  }
}
