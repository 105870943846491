/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { SetsEntry } from './SetsEntry';
import { ExerciseBlock } from '../../../../../Model/ProgramPortfolio/ExerciseBlock';
import { ExerciseBlockSet } from '../../../../../Model/ProgramPortfolio/ExerciseBlockSet';

export type SetsTableRowProps = {
  exerciseBlock: ExerciseBlock;
  set: ExerciseBlockSet;
  index: number;
};

const isKey = (key: string, valueKey: string): boolean => {
  const min = `MIN_${valueKey}`;
  const max = `MAX_${valueKey}`;
  return key === min || key === max || key === valueKey;
};

const validSetKey = (type: string, setKey: string): boolean => {
  if (
    type === 'ENDURANCE' ||
    type === 'BALANCE' ||
    type === 'FLEXIBILITY' ||
    type === 'MOBILISATION' ||
    type === 'RELAX'
  ) {
    return isKey(setKey, 'DURATION') || isKey(setKey, 'BREAK');
  }
  return true;
};

export const SetsTableRow: React.FC<SetsTableRowProps> = observer(
  ({ exerciseBlock, set, index }: SetsTableRowProps) => {
    const handleRemove = React.useCallback(
      (e) => {
        e.preventDefault();
        exerciseBlock.sets.splice(index, 1);
      },
      [exerciseBlock, index],
    );

    const keys = Array.from(set.values.keys());

    return (
      <tr key={`${index}`}>
        <td>
          <Row>
            {keys
              .filter((key) => validSetKey(exerciseBlock.exercise.type, key))
              .map((key) => (
                <Col key={key}>
                  <SetsEntry setKey={key} values={set.values} />
                </Col>
              ))}
            <Col xs="auto">
              <a href="" onClick={handleRemove}>
                {'X'}
              </a>
            </Col>
          </Row>
        </td>
      </tr>
    );
  },
);
