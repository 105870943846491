import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { SwitchStage } from '../../../../../../../Model/Coach/Stages/SwitchStage';

export class SwitchNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: SwitchStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'switch' }));
    this.addInPort('in');
    this.addInPort('in-1', true);
    this.addInPort('in-2', true);
    this.addInPort('in-3', true);
    this.addOutPort('if', true);
    this.addOutPort('if-1', true);
    this.addOutPort('if-2', true);
    this.addOutPort('if-3', true);
    this.addOutPort('else', true);
  }
}
