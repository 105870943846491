import * as React from 'react';
import { Route, Routes } from 'react-router';
import { PipelineListScreen } from './WorkoutScript/PipelineListScreen';
import { PipelineViewScreen } from './WorkoutScript/View/PipelineViewScreen';
import { SuperMacroListScreen } from './SuperMacro/SuperMacroListScreen';
import { SuperMacroViewScreen } from './SuperMacro/View/SuperMacroViewScreen';
import { CoachProgramListScreen } from './AthleteSuperMacro/CoachProgramListScreen';
import { AthleteFitnessProgramViewScreen } from './AthleteSuperMacro/View/AthleteFitnessProgramViewScreen';
import { observer } from 'mobx-react';
import { RecipeListScreen } from './Diet/Recipe/RecipeListScreen';
import { IngredientListScreen } from './Diet/Ingredient/IngredientListScreen';
import { ExploreEntriesListScreen } from './Explore/Entries/ExploreEntriesListScreen';
import { ExploreIndexScreen } from './Explore/ExploreIndexScreen';
import { EventsIndexScreen } from './Events/EventsIndexScreen';
import { TextToSpeechIndexScreen } from './TextToSpeech/TextToSpeechIndexScreen';
import { CoachWorkoutTemplateListScreen } from './CoachWorkoutTemplate/CoachWorkoutTemplateListScreen';
import { WorkoutTemplateViewScreen } from './CoachWorkoutTemplate/View/WorkoutTemplateViewScreen';
import { WorkoutModulesIndexScreen } from './WorkoutModules/WorkoutModulesIndexScreen';
import { WorkoutModuleViewScreen } from './WorkoutModules/View/WorkoutModuleViewScreen';
import { FitnessProgramTemplateViewScreen } from './CoachProgramTemplate/View/FitnessProgramTemplateViewScreen';
import { CoachProgramTemplateListScreen } from './CoachProgramTemplate/CoachProgramTemplateListScreen';
import { MindfulnessProgramTemplateViewScreen } from './Mindfulness/Templates/View/MindfulnessProgramTemplateViewScreen';
import { MindfulnessProgramTemplateListScreen } from './Mindfulness/Templates/MindfulnessProgramTemplateListScreen';
import { RecipeGeneratorScreen } from './Diet/Generator/RecipeGeneratorScreen';
import { ExploreFaqIndexScreen } from './Explore/Faq/ExploreFaqIndexScreen';

export type CoachRouterProps = {};

export const CoachRouter: React.FC<CoachRouterProps> = observer(() => {
  return (
    <Routes>
      <Route path="diet">
        <Route path="recipes/generator" element={<RecipeGeneratorScreen />} />
        <Route path="recipes/*" element={<RecipeListScreen />} />
        <Route path="ingredients/*" element={<IngredientListScreen />} />
      </Route>
      <Route path="explore/*">
        <Route path="entries/*" element={<ExploreEntriesListScreen />} />
        <Route path="faq/*" element={<ExploreFaqIndexScreen />} />
        <Route path=":categoryId/*" element={<ExploreIndexScreen />} />
        <Route path="*" element={<ExploreIndexScreen />} />
      </Route>
      <Route path="events/*">
        <Route index element={<EventsIndexScreen />} />
      </Route>
      <Route path="tts/*">
        <Route path="*" element={<TextToSpeechIndexScreen />} />
      </Route>
      <Route path="workout-template">
        <Route index element={<CoachWorkoutTemplateListScreen />} />
        <Route path=":templateId" element={<WorkoutTemplateViewScreen />} />
      </Route>
      <Route path="workout-script">
        <Route path=":pipelineId" element={<PipelineViewScreen />} />
        <Route index element={<PipelineListScreen />} />
      </Route>

      <Route path="workout-modules">
        <Route path=":moduleId" element={<WorkoutModuleViewScreen />} />
        <Route index element={<WorkoutModulesIndexScreen />} />
      </Route>

      <Route path="super-macro">
        <Route path=":superMacroId" element={<SuperMacroViewScreen />} />
        <Route index element={<SuperMacroListScreen />} />
      </Route>

      <Route path="fitness-program">
        <Route path=":programId" element={<AthleteFitnessProgramViewScreen />} />
        <Route index element={<CoachProgramListScreen />} />
      </Route>

      <Route path="program-template">
        <Route path=":templateId" element={<FitnessProgramTemplateViewScreen />} />
        <Route index element={<CoachProgramTemplateListScreen />} />
      </Route>

      <Route path="mindfulness-template">
        <Route path=":templateId" element={<MindfulnessProgramTemplateViewScreen />} />
        <Route index element={<MindfulnessProgramTemplateListScreen />} />
      </Route>
    </Routes>
  );
});
