/**
 * Created by neo on 23.04.21.
 */
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type DietRequirementsJson = {
  basalMetabolicRate: number;
  palValue: number;
  kcalPerDay: number;
  carbs: number;
  fat: number;
  protein: number;
};

export class DietRequirements {
  @observable
  basalMetabolicRate = 0.0;
  @observable
  palValue = 0.0;
  @observable
  kcalPerDay = 0.0;
  @observable
  carbs = 0.0;
  @observable
  fat = 0.0;
  @observable
  protein = 0.0;

  constructor(json?: Partial<DietRequirementsJson>) {
    if (json) {
      this.basalMetabolicRate = json.basalMetabolicRate ?? 0.0;
      this.palValue = json.palValue ?? 0.0;
      this.kcalPerDay = json.kcalPerDay ?? 0.0;
      this.carbs = json.carbs ?? 0.0;
      this.fat = json.fat ?? 0.0;
      this.protein = json.protein ?? 0.0;
    }
  }

  static async get(): Promise<DietRequirements> {
    return HttpBackend.get('/diet/recommendations/requirements').then((res) => new DietRequirements(res));
  }
}
