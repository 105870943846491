/**
 * Created by neo on 12.04.22.
 */
import {
  MindfulnessProgramTemplateDayUnit,
  MindfulnessProgramTemplateDayUnitJson,
} from './MindfulnessProgramTemplateDayUnit';
import { Media, MediaJson } from '../../../Media/Media';
import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction } from 'mobx';
import { Activity } from '../../../Activity/Activity';
import { Instructor, InstructorJson } from '../../../Explore/Instructor';
import { ConditionalMedia, ConditionalMediaJson } from '../../SuperMacro/ConditionalMedia';

export type MindfulnessProgramTemplateDayUnitAudioJson = MindfulnessProgramTemplateDayUnitJson & {
  audios: MediaJson[];
  instructor: InstructorJson;
  backgroundImages: ConditionalMediaJson[];
};

export class MindfulnessProgramTemplateDayUnitAudio extends MindfulnessProgramTemplateDayUnit {
  @observable.ref
  instructor: Instructor = new Instructor();
  @observable
  backgroundImages: ConditionalMedia[] = [];
  @observable
  audios: Media[] = [];

  constructor(json?: Partial<MindfulnessProgramTemplateDayUnitAudioJson>) {
    super(Object.assign({}, json ?? {}, { type: 'audio' }));
    if (json) {
      this.audios = (json.audios ?? []).map((a) => new Media(a));
      this.backgroundImages = (json.backgroundImages ?? []).map((m) => new ConditionalMedia(m));
      this.instructor = new Instructor(json.instructor);
    }
  }

  toJS(newId: boolean = false): MindfulnessProgramTemplateDayUnitAudioJson {
    return Object.assign(super.toJS(newId), {
      instructor: this.instructor.toJS(),
      audios: this.audios.map((a) => a.toJS()),
      backgroundImages: this.backgroundImages.map((m) => m.toJS()),
    });
  }
}
