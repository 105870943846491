/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { observer } from 'mobx-react';
import { OAuthScopeTableRow } from './OAuthScopeTableRow';
import { OAuthScope } from '../../../Model/OAuthScope';

export type Props = {
  scopes: OAuthScope[];
  onDelete: (type: OAuthScope) => any;
};

@observer
export class OAuthScopeTable extends React.Component<Props> {
  render() {
    const {
      props: { scopes, onDelete },
    } = this;
    return (
      <Row>
        <Col>
          <Table size="sm" striped>
            <thead className="thead-inverse">
              <tr>
                <th>{'ID'}</th>
                <th>{'Name'}</th>
                <th>{'Action'}</th>
              </tr>
            </thead>
            <tbody>
              {scopes.map((scope) => (
                <OAuthScopeTableRow key={scope.id} scope={scope} onDelete={onDelete} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}
