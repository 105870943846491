/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { EarnModelTemplate } from '../../../Model/Engagement/EarnModelTemplate';
import { Table } from 'reactstrap';
import { EarnModelTemplateTableRow } from './EarnModelTemplateTableRow';

export type EarnModelTemplateTableProps = {
  templates: EarnModelTemplate[];
};

export const EarnModelTemplateTable: React.FC<EarnModelTemplateTableProps> = observer(({ templates }) => {
  return (
    <Table size="sm">
      <thead>
      <tr>
        <th>ID</th>
        <th>Company</th>
        <th>Name</th>
        <th>Active From</th>
        <th>Active Until</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      {templates.map((template) => (
          <EarnModelTemplateTableRow key={template.id} template={template} />
        ))}
      </tbody>
    </Table>
  );
});
