/**
 * Created by neo on 12.04.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import MediaService from '../../../../Services/MediaService';
import { Form, Input, InputNumber, Select, Space, Switch } from 'antd';
import { Col, Row } from 'reactstrap';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { SingleColRow } from '../../../../Components/SingleColRow';
import CreatableSelect from 'react-select/creatable';
import { CuisinesList, DishList } from '../../../../Model/Diet/Recipe/RecipeSearchParams';
import { ViewMediaItem } from '../../../../Components/ViewMedia/ViewMediaItem';
import Dropzone from 'react-dropzone';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { PossibleUnitEntry } from './PossibleUnitEntry';

export type IngredientEditModalBasicInfoProps = {
  ingredient: Ingredient;
};

export const IngredientEditModalBasicInfo: React.FC<IngredientEditModalBasicInfoProps> = observer(({ ingredient }) => {
  const changeBoolean = React.useCallback(
    (field: string) => () => {
      runInAction(() => (ingredient[field] = !ingredient[field]));
    },
    [ingredient],
  );

  const changeNumber = React.useCallback(
    (field: string) => (value: number | string | undefined | null) => {
      runInAction(() => (ingredient[field] = value !== undefined && value !== null ? Number(value) : 0));
    },
    [ingredient],
  );

  const changeText = React.useCallback(
    (field: string) => ({ target: { value } }) => {
      runInAction(() => (ingredient[field] = value));
    },
    [ingredient],
  );

  const changeStringArray = React.useCallback(
    (field: string) => (tags?: any[]) => {
      runInAction(() => {
        ingredient[field].splice(0, ingredient[field].length);
        (tags ?? []).map((t) => t.value).forEach((t) => ingredient[field].push(t));
      });
    },
    [ingredient],
  );

  const handleDrop = React.useCallback(
    (files: File[]) => {
      const file = files.length > 0 ? files[0] : undefined;
      if (file) {
        MediaService.uploadMedia(file).then((media) => runInAction(() => (ingredient.image = media)));
      }
    },
    [ingredient],
  );

  const handleRemoveFile = React.useCallback(() => {
    runInAction(() => (ingredient.image = undefined));
  }, [ingredient]);

  return (
    <Form layout="vertical">
      <Row>
        <Col md={6}>
          <TranslationInputArray entity={ingredient} field="name" label="Name" />
        </Col>
        <Col md={6}>
          <TranslationInputArray
            entity={ingredient}
            type="textarea"
            field="description"
            label="Description"
          />
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <Form.Item label="Base Amount" extra="In 99.9999% of the cases it will be 100ml/g">
                <InputNumber value={ingredient.amount} onChange={changeNumber('weightWatcherSmartPoints')} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Base Unit" extra="Either g or milliliter">
                <Select value={ingredient.unit}>
                  <Select.Option value="g">Gram</Select.Option>
                  <Select.Option value="milliliter">Milliliter</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Form.Item label="Possible Units">
            {Object.entries(ingredient.possibleUnits).map(([unit, amount]) => (
              <PossibleUnitEntry key={unit} unit={unit} ingredient={ingredient} />
            ))}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Image">
            <Row>
              <Col>{ingredient.image && <ViewMediaItem media={ingredient.image} onRemove={handleRemoveFile} />}</Col>
              <Col>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className="dropzone"
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <input {...getInputProps()} />
                      {'Drop Medias (Images or Videos) here'}
                    </div>
                  )}
                </Dropzone>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
