/**
 * Created by neo on 19.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Pager } from '../../../Components/Pager';
import { UserRoleTable } from './UserRoleTable';
import { UserRole } from '../../../Model/UserRole';
import { PageResult } from '../../../Model/PageResult';

export type UserRoleListScreenProps = {};

export const UserRoleListScreen: React.FC<UserRoleListScreenProps> = observer((props) => {
  const [result, setResult] = useState(new PageResult<UserRole>());
  const [query, setQuery] = useState('');

  const debounce = React.useRef<ReturnType<typeof setTimeout>>();
  const page = React.useRef(0);

  const history = useNavigate();

  const load = React.useRef((query: string, page: number) => {
    PageResult.execute(UserRole.find({ query, page }), UserRole.count({ query, page }), page, 10).then((res) =>
      setResult(res),
    );
  }).current;

  useEffect(() => {
    load('', 0);
  }, []);

  const handlePage = React.useCallback((newPage: number): void => {
    if (page.current !== newPage) {
      page.current = newPage;
      load(query, newPage);
    }
  }, []);

  const handleSelect = React.useCallback((type: UserRole) => {
    history(`/security/userRole/${type.id}`);
  }, []);

  const handleDelete = React.useCallback(
    async (userRole: UserRole) => {
      if (window.confirm(`CAREFUL: Delete ${userRole.fullName}?`)) {
        if (window.confirm(`Are you really sure? Delete ${userRole.fullName}?`)) {
          await userRole.remove();
          load(query, page.current);
        }
      }
    },
    [query],
  );

  const handleQueryChange = React.useCallback(({ target: { value } }: any) => {
    setQuery(value);
    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      page.current = 0;
      load(value, page.current);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'User Roles'}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={() => history('/security/userRole/create')}>
            {'Create Role'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input type="text" onChange={handleQueryChange} value={query} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserRoleTable roles={result.content} onSelect={handleSelect} onDelete={handleDelete} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
      </Row>
    </Container>
  );
});
