/**
 * Created by katarinababic on 15.12.21..
 */

export const allSelectedCategoryAggregated = `
SELECT
  params_category.value.string_value AS category_id,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS select_count
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params_name,
  UNNEST (event_params) AS params_category
WHERE
  _TABLE_SUFFIX BETWEEN @start_date AND @end_date
  AND event_name = 'touchable_pressed'
  AND params_name.key = 'eventName'
  AND params_name.value.string_value = 'exploreCategoryListItem'
  AND params_category.key = 'categoryId'
GROUP BY
  category_id
ORDER BY
  select_count DESC
  `;

export const gymSelectedCategoryAggregated = `
SELECT
  params_category.value.string_value AS category_id,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS select_count
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params_name,
  UNNEST (event_params) AS params_category,
  UNNEST (user_properties) AS p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date AND @end_date
  AND event_name = 'touchable_pressed'
  AND params_name.key = 'eventName'
  AND params_name.value.string_value = 'exploreCategoryListItem'
  AND params_category.key = 'categoryId'
  AND p.key = 'gymId'
  AND p.value.string_value = @gym_id
GROUP BY
  category_id
ORDER BY
  select_count DESC
  `;
