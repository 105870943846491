/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { GymChallenge } from '../../../../../Model/GymChallenge/GymChallenge';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Tabs } from 'antd';
import { CustomerGroupModalBasicInfoTab } from './CustomerGroupModalBasicInfoTab';
import { CustomerGroupModalMembersTab } from './CustomerGroupModalMembersTab';
import { CustomerGroup } from '../../../../../Model/Gym/CustomerGroup/CustomerGroup';

export type CustomerGroupModalProps = {
  onSaved?: (challenge: CustomerGroup) => void;
  onDeleted?: (challenge: CustomerGroup) => void;
};

export const CustomerGroupModal: React.FC<CustomerGroupModalProps> = observer(({ onSaved, onDeleted }) => {
  const history = useNavigate();
  const { groupId, gymId } = useParams<{ groupId: string; gymId: string }>();
  const [customerGroup, setCustomerGroup] = useState(new CustomerGroup({ gymId }));
  const [processing, setProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('general');

  const isNew = groupId === 'new';

  const tabItems = React.useMemo(
    () => [
      { label: 'General', key: 'general', children: <CustomerGroupModalBasicInfoTab customerGroup={customerGroup} /> },
      {
        label: 'Members',
        key: 'members',
        children: <CustomerGroupModalMembersTab customerGroup={customerGroup} />,
      },
    ],
    [customerGroup],
  );

  useEffect(() => {
    setCustomerGroup(new CustomerGroup({ gymId }));
    if (groupId && gymId && groupId !== 'new') {
      CustomerGroup.findOne(groupId, gymId).then((result) => setCustomerGroup(result ?? new CustomerGroup()));
    }
  }, [groupId, gymId]);

  const handleCancel = React.useCallback(() => history(-1), [history]);

  const handleSave = React.useCallback(() => {
    setProcessing(true);
    onSaved && onSaved(customerGroup);
    if (gymId) {
      customerGroup
        .save()
        .finally(() => setProcessing(false))
        .then(() => history(-1));
    }
  }, [customerGroup, onSaved, gymId, history]);

  const handleDelete = React.useCallback(() => {
    setProcessing(true);
    onDeleted && onDeleted(customerGroup);
    if (gymId) {
      customerGroup
        .delete()
        .finally(() => setProcessing(false))
        .then(() => history(-1));
    }
  }, [customerGroup, history, onDeleted, gymId]);

  const handleChangeTab = React.useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  // const handleExportCsv = React.useCallback(() => {
  //   setProcessing(true);
  //   HttpBackend.get(`/engagement/gym/challenges/admin/${challenge.id}/exportCsv`)
  //     .then((res) => new Blob([res], { type: 'text/plain;charset=iso-8859-1' }))
  //     .then((blob) => saveAs(blob, `${challenge.getName('de')}-customers-${dayjs().format('YYYYMMDDHHmm')}.csv`))
  //     .finally(() => setProcessing(false));
  // }, [challenge]);

  return (
    <Modal
      open={true}
      title={isNew ? 'New Group' : customerGroup.name}
      onCancel={handleCancel}
      footer={[
        <Button key="1" onClick={handleCancel} disabled={processing}>
          Close
        </Button>,
        <Button key="2" danger onClick={handleDelete} disabled={processing || isNew}>
          Delete
        </Button>,
        <Button key="3" type="primary" onClick={handleSave} disabled={processing}>
          Save
        </Button>,
      ]}
      confirmLoading={processing}
      width={window.innerWidth * 0.75}
      style={{ paddingBottom: 64 }}
    >
      <Tabs
        activeKey={activeTab}
        onChange={handleChangeTab}
        size="small"
        items={tabItems}
        destroyInactiveTabPane={true}
      />
    </Modal>
  );
});
