/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { useLocalStore, observer, Observer } from 'mobx-react';
import { Col, Row, Nav, Container } from 'reactstrap';
import { AthleteBasicTab } from './AthleteBasicTab/AthleteBasicTab';
import { NotificationTab } from './NotificationTab/NotificationTab';
import { Athlete } from '../../../Model/Athlete/Athlete';
import { DeviceInfoTab } from './DeviceInfoTab/DeviceInfoTab';
import { AthleteGymsTab } from './AthleteGymsTab';
import { ProfilePicture } from '../../../Components/ProfilePicture';
import { AthleteConfigTab } from './AthleteConfigTab/AthleteConfigTab';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useMatch } from 'react-router-dom';
import { Button, message, Popconfirm, Space, Tabs } from 'antd';
import { useRootStore } from '../../../Store/useRootStore';
import { AthleteEquipmentTab } from './AthleteEquipment';
import { AthleteCoachProgramTab } from './AthleteCoachProgramTab/AthleteCoachProgramTab';
import { AthleteCoachSubscriptionTab } from './AthleteCoachSubscriptionTab/AthleteCoachSubscriptionTab';
import { PointsHistoryTab } from './PointsHistoryTab/PointsHistoryTab';
import { AthleteActivityLogTab } from './AthleteActivityLogTab/AthleteActivityLogTab';
import { AthleteRouteChallengeTab } from './RouteChallengeTab/AthleteRouteChallengeTab';

export type AthleteViewScreenProps = {};

export const AthleteViewScreen: React.FC<AthleteViewScreenProps> = observer(() => {
  const { authentication } = useRootStore();
  const { athleteId } = useParams<{ athleteId: string }>();
  const [activeTab, setActiveTab] = useState('basic');

  const history = useNavigate();
  const isNew = React.useMemo(() => !athleteId || athleteId === 'create' || athleteId === 'new', [athleteId]);
  const [athlete, setAthlete] = useState(new Athlete());
  const [messageApi, messageContext] = message.useMessage();

  useEffect(() => {
    if (athleteId && athleteId !== 'new') {
      Athlete.get(athleteId).then((result) => setAthlete(result ?? new Athlete()));
    } else setAthlete(new Athlete());
  }, [athleteId]);

  const handleSave = React.useCallback(() => {
    athlete
      .save()
      .then((res) => isNew && history(`/athlete/${res.id}`, { replace: true }))
      .then(() =>
        messageApi.open({
          type: 'info',
          content: 'Athlete saved',
        }),
      )
      .catch(() =>
        messageApi.open({
          type: 'error',
          content: "Couldn't save athlete",
        }),
      );
  }, [athlete, history, isNew, messageApi]);

  const handleDelete = React.useCallback(() => {
    athlete
      .remove()
      .then(() => messageApi.info('Athlete deleted'))
      .then(() => {
        history(-1);
      });
  }, [athlete, history, messageApi]);

  const handleChangeTab = React.useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  const basicTabs = React.useMemo(
    () => [
      { label: 'Gyms', key: 'gyms', children: <AthleteGymsTab athlete={athlete} /> },
      { label: 'Subscription', key: 'subscription', children: <AthleteCoachSubscriptionTab athlete={athlete} /> },
      { label: 'Points History', key: 'pointsHistory', children: <PointsHistoryTab athlete={athlete} /> },
      { label: 'Coach Program', key: 'coach-program', children: <AthleteCoachProgramTab athlete={athlete} /> },
      { label: 'Activity Logs', key: 'activityLogs', children: <AthleteActivityLogTab athlete={athlete} /> },
      { label: 'Route Challenge', key: 'routeChallenges', children: <AthleteRouteChallengeTab athlete={athlete} /> },
      { label: 'Configs', key: 'config', children: <AthleteConfigTab athlete={athlete} /> },
      { label: 'Notifications', key: 'notification', children: <NotificationTab athlete={athlete} /> },
      { label: 'Device Info', key: 'deviceInfo', children: <DeviceInfoTab athlete={athlete} /> },
      { label: 'Equipment', key: 'equipment', children: <AthleteEquipmentTab athlete={athlete} /> },
    ],
    [athlete],
  );

  const tabItems = React.useMemo(
    () => [{ label: 'Basic Info', key: 'basic', children: <AthleteBasicTab athlete={athlete} /> }],
    [athlete],
  );

  const allTabs = React.useMemo(
    () => tabItems.concat(!isNew ? basicTabs : []).concat([]),
    [tabItems, basicTabs, isNew, authentication.isGodAdmin],
  );

  return (
    <Container>
      {messageContext}
      <Row>
        <Col>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', marginBottom: 16 }}>
            <ProfilePicture image={athlete.profilePicture} size={128} />
            <div style={{ marginLeft: 16 }}>
              <h1>{isNew ? 'New Athlete' : athlete.fullName}</h1>
              <Link to={`/security/user/${athlete.user.id}`}>{athlete.user.email || '<no email>'}</Link>
            </div>
          </div>
        </Col>
        <Col xs="auto">
          <Space className="float-right">
            <Button type="primary" onClick={handleSave}>
              {'Save'}
            </Button>
            {authentication.isGodAdmin ? (
              <Popconfirm
                title={`Delete ${athlete.fullName || athlete.user.email || athlete.id}`}
                okText="DELETE"
                onConfirm={handleDelete}
              >
                <Button danger>{'Delete'}</Button>
              </Popconfirm>
            ) : null}
          </Space>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onChange={handleChangeTab}
        size="small"
        items={allTabs}
        destroyInactiveTabPane={true}
      />
    </Container>
  );
});
