/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { observable, runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import classnames from 'classnames';
import { EquipmentType } from '../../../../../Model/Equipment/EquipmentType';
import { EquipmentTypeViewBasicInfoTab } from './EquipmentTypeViewBasicInfoTab';
import { EquipmentTypeViewExercisesTab } from './EquipmentTypeViewExercisesTab';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, message } from 'antd';

export type EquipmentTypeViewScreenProps = {};

export const EquipmentTypeViewScreen: React.FC<EquipmentTypeViewScreenProps> = observer((props) => {
  const { id } = useParams<any>();
  const history = useNavigate();

  const store = useLocalStore(() => ({
    equipmentType: new EquipmentType(),
    processing: false,
    activeTab: 'basic',
    async load(id?: string) {
      if (id && id !== 'create' && id !== 'new') {
        store.processing = true;
        EquipmentType.get(id)
          .then((type) =>
            runInAction(() => {
              store.equipmentType = type ?? new EquipmentType();
            }),
          )
          .finally(() => runInAction(() => (store.processing = false)));
      } else {
        store.equipmentType = new EquipmentType();
      }
    },
  }));

  useEffect(() => {
    store.load(id);
  }, [id, store]);

  const handleSave = React.useCallback(() => {
    store.processing = true;
    store.equipmentType
      .save()
      .then(() => message.success('Equipment Type succesfully saved'))
      .then(() => history(`/metadata/equipment/type/${store.equipmentType.id}`, { replace: true }))
      .catch(() => message.error('Error saving Equipment Type'))
      .finally(() => runInAction(() => (store.processing = false)));
  }, [store, history]);

  const toggleTab = (tab: string) => (store.activeTab = tab);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{store.equipmentType.isNew ? 'New Type' : store.equipmentType.defaultName || '<no name>'}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleSave} disabled={store.processing}>
            {'Save'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: store.activeTab === 'basic' })}
                onClick={() => toggleTab('basic')}
              >
                {'Basic Info'}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: store.activeTab === 'exercises' })}
                onClick={() => toggleTab('exercises')}
              >
                {'Exercises'}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={store.activeTab}>
            {store.activeTab === 'basic' ? (
              <TabPane tabId="basic">
                <EquipmentTypeViewBasicInfoTab equipmentType={store.equipmentType} />
              </TabPane>
            ) : null}
            {store.activeTab === 'exercises' ? (
              <TabPane tabId="exercises">
                <EquipmentTypeViewExercisesTab equipmentType={store.equipmentType} />
              </TabPane>
            ) : null}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
});
