/**
 * Created by neo on 01.02.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Button, Form, InputNumber, Select } from 'antd';
import { runInAction } from 'mobx';
import { BreathingEntry } from '../../../../../Model/Explore/BreathingEntry';
import { BreathingTechniqueStep } from '../../../../../Model/Explore/BreathingTechniqueStep';

export type BreathingTechniqueEntryProps = {
  entry: BreathingEntry;
  techniqueStep: BreathingTechniqueStep;
  index: number;
};

export const BreathingTechniqueEntry: React.FC<BreathingTechniqueEntryProps> = observer(
  ({ entry, techniqueStep, index }) => {
    const handleChangeType = React.useCallback(
      (type) => {
        if (techniqueStep.type !== type) {
          techniqueStep.type = type;
        }
      },
      [techniqueStep],
    );

    const handleChangeDuration = React.useCallback(
      (value) => {
        if (Number(value) > 0) {
          techniqueStep.duration = Number(value);
        }
      },
      [techniqueStep],
    );

    const handleRemoveStep = React.useCallback(() => {
      runInAction(() => entry.technique.splice(index, 1));
    }, [entry, index]);

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Form.Item label="Type">
              <Select value={techniqueStep.type} onChange={handleChangeType}>
                <Select.Option value="inhale">Inhale</Select.Option>
                <Select.Option value="hold">Hold</Select.Option>
                <Select.Option value="exhale">Exhale</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Duration (seconds)">
              <InputNumber value={techniqueStep.duration} onChange={handleChangeDuration} />
            </Form.Item>
          </Col>
          <Col xs="auto">
            <h6>Actions</h6>
            <Button danger onClick={handleRemoveStep}>
              Remove step
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  },
);
