/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry, ActivityWorkoutEntryJson } from './ActivityWorkoutEntry';
import { Instructor, InstructorJson } from './Instructor';

export type YogaEntryJson = ActivityWorkoutEntryJson & {
  instructor: InstructorJson;
  narrator: InstructorJson;
  recommendedMusicKey?: string;
};

export class YogaEntry extends ActivityWorkoutEntry {
  @observable.ref
  instructor: Instructor = new Instructor();
  @observable.ref
  narrator: Instructor = new Instructor();
  @observable
  recommendedMusicKey?: string;

  constructor(json?: Partial<YogaEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'yoga', activityIdentifier: 'yoga' }));
    if (json) {
      this.type = 'yoga';
      this.instructor = new Instructor(json.instructor);
      this.narrator = new Instructor(json.narrator);
      this.recommendedMusicKey = json.recommendedMusicKey;
    }
  }

  toJS(): YogaEntryJson {
    return Object.assign(super.toJS(), {
      type: 'yoga',
      instructor: this.instructor.toJS(),
      narrator: this.narrator.toJS(),
      recommendedMusicKey: this.recommendedMusicKey,
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<YogaEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'yoga' })).then((res) =>
      (res ?? []).map((r) => new YogaEntry(r)),
    );
  }

  static async findOne(id: string): Promise<YogaEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new YogaEntry(res));
  }
}
