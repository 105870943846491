/**
 * Created by neo on 14.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Form } from 'antd';
import AsyncSelect from 'react-select/async';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';

export type BodyPartParentInputProps = {
  bodyPart: BodyPartRegionAdmin;
};

export const BodyPartParentInput: React.FC<BodyPartParentInputProps> = observer(
  ({ bodyPart }: BodyPartParentInputProps) => {
    const store = useLocalStore(() => ({
      // here goes the local state
    }));

    const handleChange = React.useCallback(
      (parents: BodyPartRegionAdmin[] | undefined) => {
        bodyPart.parentIds = (parents || []).map((p) => p.id);
        bodyPart.parents = parents ?? [];
      },
      [bodyPart],
    );

    const fetch = React.useCallback(
      (query: string) => BodyPartRegionAdmin.find({ query }),
      [],
    );

    const getOptionLabel = React.useCallback(
      (option: BodyPartRegionAdmin) => `${option.defaultName} (${option.identifier})`,
      [],
    );

    const getOptionValue = React.useCallback((option: BodyPartRegionAdmin) => option, []);

    return (
      <Form.Item label="Parents">
        <AsyncSelect
          defaultOptions
          isClearable={true}
          loadOptions={fetch}
          isMulti={true}
          value={bodyPart.parents ?? []}
          hideSelectedOptions={false}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue as any}
          onChange={handleChange as any}
        />
      </Form.Item>
    );
  },
);
