/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { MacroCycleTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleTemplate';
import { MacroCycleLaneTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleLaneTemplate';
import { Button, Card, Collapse, Form, Popconfirm, Space } from 'antd';
import { MacroCycleLaneCard } from './MacroCycleLaneCard';
import { MacroCycleLaneCardExtra } from './MacroCycleLaneCardExtra';

export type MacroCycleLaneListProps = {
  macroCycle: MacroCycleTemplate;
  onRemove?: (lane: MacroCycleLaneTemplate) => any;
};

export const MacroCycleLaneList: React.FC<MacroCycleLaneListProps> = observer(
  ({ macroCycle, onRemove }: MacroCycleLaneListProps) => {
    return (
      <Collapse>
        {macroCycle.lanes.map((lane) => (
          // <Collapse.Panel
          //   key={lane.id}
          //   header={lane.tagConditions.map((t) => t.includedTags.join(', ')).join(' | ') || '<default>'}
          //   extra={<MacroCycleLaneCardExtra lane={lane} onRemove={onRemove} />}
          // >
          <MacroCycleLaneCard key={lane.id} lane={lane} onRemove={onRemove} />
          // </Collapse.Panel>
        ))}
      </Collapse>
    );
  },
);
