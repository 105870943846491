import { DefaultStageNodeModel } from '../../Default/DefaultStageNodeModel';
import { LessThanStage } from '../../../../../../../../Model/Coach/Stages/Logical/LessThanStage';

export class LessThanNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: LessThanStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'logical:lt' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
