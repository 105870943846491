/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer } from 'mobx-react';
import { ComponentStylesText } from './ComponentStylesText';
import { ComponentStylesView } from './ComponentStylesView';
import { ComponentStylesImage } from './ComponentStylesImage';
import { ComponentStylesVideo } from './ComponentStylesVideo';
import { ComponentStylesHeading } from './ComponentStylesHeading';
import { ComponentStylesButton } from './ComponentStylesButton';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { ComponentStylesInnerBoldText } from './ComponentStylesInnerBoldText';
import { ComponentStylesInnerLinkText } from './ComponentStylesInnerLinkText';
import { ComponentStylesBulletPoint } from './ComponentStylesBulletPoint';
import { ComponentStylesNumberPoint } from './ComponentStylesNumberPoint';

interface NativeFeedSideDrawerComponentStylesSwitchProps {
  component: NativeFeedComponent;
}

export const NativeFeedSideDrawerComponentStylesSwitch: React.FC<NativeFeedSideDrawerComponentStylesSwitchProps> =
  observer(({ component }) => {
    switch (component.type) {
      case 'View':
        return <ComponentStylesView component={component} />;
      case 'Image':
        return <ComponentStylesImage component={component} />;
      case 'Video':
        return <ComponentStylesVideo component={component} />;
      case 'Heading':
      case 'ItalicHeading':
        return <ComponentStylesHeading component={component} />;
      case 'Paragraph':
      case 'ItalicParagraph':
      case 'Caption':
      case 'Summary':
        return <ComponentStylesText component={component} />;
      case 'InnerBoldText':
        return <ComponentStylesInnerBoldText component={component} />;
      case 'InnerLinkText':
        return <ComponentStylesInnerLinkText component={component} />;
      case 'BulletPoint':
        return <ComponentStylesBulletPoint component={component} />;
      case 'NumberPoint':
        return <ComponentStylesNumberPoint component={component} />;
      case 'Button':
      case 'SecondaryButton':
        return <ComponentStylesButton component={component} />;
      default:
        return null;
    }
  });
