/**
 * Created by neo on 26.04.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../../Model/Gym/Gym';

export type GymViewScreenTitleProps = {
  gym: Gym;
  isNew?: boolean;
};

export const GymViewScreenTitle: React.FC<GymViewScreenTitleProps> = observer(
  ({ isNew, gym }: GymViewScreenTitleProps) => {
    return <h1>{isNew ? 'New Company' : gym.name}</h1>;
  },
);
