/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Customer } from '../../../../../Model/Customer/Customer';
import { ProfilePicture } from '../../../../../Components/ProfilePicture';
import { useRootStore } from '../../../../../Store/useRootStore';
import { AthleteLink } from '../../../../../Components/AthleteLink';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { ActivityLog } from '../../../../../Model/Activity/ActivityLog';
import { Button, Popconfirm, Space, Tag } from 'antd';
import { useNavigate } from 'react-router';

export type CustomerTableRowProps = {
  customer: Customer;
  onRemove?: (customer: Customer) => void;
};

export const CustomerTableRow: React.FC<CustomerTableRowProps> = observer(
  ({ customer, onRemove }: CustomerTableRowProps) => {
    const { authentication } = useRootStore();
    const [loggedActivities, setLoggedActivities] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
      ActivityLog.count({ athleteId: customer.athlete.id }).then((count) => setLoggedActivities(count));
    }, [customer]);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(customer);
    }, [onRemove, customer]);

    const handleEdit = React.useCallback(() => {
      navigate(`/infrastructure/gym/${customer.gymId}/client/${customer.id}`);
    }, [customer, navigate]);

    return (
      <tr>
        <td>
          <AthleteLink athlete={customer.athlete} />
        </td>
        <td>{customer.athlete.user.email}</td>
        <td>
          {customer.athlete.lastActivity?.updatedAt
            ? dayjs(customer.athlete.lastActivity.updatedAt).format('DD.MM.YYYY HH:mm')
            : ''}
        </td>
        <td>{loggedActivities}</td>
        <td>{customer.rewardsPointsCollectionEnabled ? <Tag color="success">yes</Tag> : <Tag color="red">no</Tag>}</td>
        <td>{customer.pointsLedger?.totalCollectedPoints?.toLocaleString()}</td>
        <td>{customer.wallet?.totalCollectedRewardCoins?.toLocaleString()}</td>
        <td>{customer.wallet?.totalSpentRewardCoins?.toLocaleString()}</td>
        <td>{dayjs(customer.athlete.createdAt).format('DD.MM.YYYY HH:mm')}</td>
        <td>
          <Space>
            {authentication.isGodAdmin && (
              <Popconfirm title={'BE CAREFUL!!! Are you sure????'} onConfirm={handleRemove}>
                <Button type="link" danger>
                  Remove
                </Button>
              </Popconfirm>
            )}
            {authentication.isGodAdmin && (
              <Button type="link" onClick={handleEdit}>
                Edit
              </Button>
            )}
          </Space>
        </td>
      </tr>
    );
  },
);
