/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Checkbox, Form, InputNumber, Modal, Progress } from 'antd';
import { ExerciseFilter } from '../../../../Model/Exercise/ExerciseFilter';
import { Col, Row } from 'reactstrap';
import { ExerciseTypeSelect } from '../View/VariationsTab/ExerciseTypeSelect';
import { ExerciseExecutionTypeSelect } from '../View/VariationsTab/ExerciseExecutionTypeSelect';
import { ExercisePositionSelect } from '../View/VariationsTab/ExercisePositionSelect';
import { ExerciseForceSelect } from '../View/VariationsTab/ExerciseForceSelect';
import { ExerciseMechanicsSelect } from '../View/VariationsTab/ExerciseMechanicsSelect';
import { ExerciseUtilitySelect } from '../View/VariationsTab/ExerciseUtilitySelect';
import { EquipmentTypeInput } from '../../../../Components/EquipmentTypeInput';
import { BodyPartCard } from '../View/BodyPartCard';
import { TrackingKeysSelectionView } from '../View/TrackingKeysSelectionView';
import { ExerciseVariationTags } from '../View/VariationsTab/ExerciseVariationTags';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { runInAction, toJS } from 'mobx';
import { PageResult } from '../../../../Model/PageResult';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { ExerciseHipFlexingSelect } from '../View/VariationsTab/ExerciseHipFlexingSelect';
import { ExerciseSpineFlexingSelect } from '../View/VariationsTab/ExerciseSpineFlexingSelect';
import { ExerciseSpineForceTypeSelect } from '../View/VariationsTab/ExerciseSpineForceTypeSelect';
import { BodyPartJointCard } from '../View/BodyPartJointCard';
import { BaseTrackingKey } from '../../../../Model/ProgramPortfolio/TrackingKeys';

export type MultiEditModalProps = {
  onClose?: () => any;
  variables: Map<string, any>;
  filter: ExerciseFilter;
};

export const MultiEditModal: React.FC<MultiEditModalProps> = observer((props: MultiEditModalProps) => {
  const { onClose, variables, filter } = props;
  const variation = React.useMemo(() => new ExerciseVariationAdmin(), [variables, filter]);
  const store = useLocalStore(
    (source) => ({
      count: 0,
      saving: false,
      editType: false,
      editMet: false,
      editExecutionType: false,
      editPosition: false,
      editForce: false,
      editMechanics: false,
      editUtility: false,
      editEquipmentTypes: false,
      editAddEquipmentTypes: false,
      editRemoveEquipmentTypes: false,
      editSecondaryEquipmentTypes: false,
      editAddSecondaryEquipmentTypes: false,
      editRemoveSecondaryEquipmentTypes: false,
      editBodyParts: false,
      editSynergists: false,
      editStabilizers: false,
      editJoints: false,
      editTrackingKeys: false,
      editAddTrackingKeys: false,
      editRemoveTrackingKeys: false,
      editHipFlexing: false,
      editSpineFlexing: false,
      editSpineForce: false,
      editTags: false,
      editAddTags: false,
      editRemoveTags: false,
      progress: 0,
      addTags: new Array<string>(),
      removeTags: new Array<string>(),
      addTrackingKeys: new Array<BaseTrackingKey>(),
      removeTrackingKeys: new Array<BaseTrackingKey>(),
      addEquipmentTypes: new Array<string>(),
      removeEquipmentTypes: new Array<string>(),
      addSecondaryEquipmentTypes: new Array<string>(),
      removeSecondaryEquipmentTypes: new Array<string>(),
      loadCount() {
        ExerciseVariationAdmin.count(Object.assign(toJS(source.variables), source.filter.toJS())).then((result) =>
          runInAction(() => (store.count = result)),
        );
      },
      async save() {
        store.saving = true;
        let result: Array<ExerciseVariationAdmin> | undefined;
        const size = 20;
        let page = -1;
        let processed = 0;
        while (!result || result.length >= size) {
          result = await ExerciseVariationAdmin.find(
            Object.assign(toJS(source.variables), source.filter.toJS(), { page: ++page, size }),
          );
          console.log('result', result);
          await Promise.all(result.map((e) => store.saveExercise(e)));
          processed += result.length;
          runInAction(() => {
            store.progress = store.count > 0 ? Math.round(Math.min(1.0, processed / store.count)) * 100.0 : 0;
          });
        }
        runInAction(() => {
          store.saving = false;
        });
      },
      saveExercise(exercise: ExerciseVariationAdmin): Promise<ExerciseVariationAdmin> {
        if (store.editType) {
          exercise.type = variation.type;
        }
        if (store.editMet) {
          exercise.met = variation.met;
        }
        if (store.editExecutionType) {
          exercise.executionType = variation.executionType;
        }
        if (store.editPosition) {
          exercise.initialPosition = variation.initialPosition;
        }
        if (store.editForce) {
          exercise.force = variation.force;
        }
        if (store.editMechanics) {
          exercise.mechanics = variation.mechanics;
        }
        if (store.editUtility) {
          exercise.utility = variation.utility;
        }
        if (store.editEquipmentTypes) {
          exercise.equipmentTypes = variation.equipmentTypes;
        }
        // if (store.editAddEquipmentTypes) {
        //   store.addEquipmentTypes.forEach((t) => exercise.equipmentTypes.push(t));
        // }
        if (store.editRemoveEquipmentTypes) {
          store.removeEquipmentTypes.forEach((t) => {
            const index = exercise.equipmentTypes.findIndex((t0) => t0.id === t);
            if (index !== -1) {
              exercise.equipmentTypes.splice(index, 1);
            }
          });
        }
        if (store.editSecondaryEquipmentTypes) {
          exercise.secondaryEquipmentTypes = variation.secondaryEquipmentTypes;
        }
        // if (store.editAddSecondaryEquipmentTypes) {
        //   store.addSecondaryEquipmentTypes.forEach((t) => exercise.secondaryEquipmentTypes.push(t));
        // }
        if (store.editRemoveSecondaryEquipmentTypes) {
          store.removeSecondaryEquipmentTypes.forEach((t) => {
            const index = exercise.secondaryEquipmentTypes.findIndex((t0) => t0.id === t);
            if (index !== -1) {
              exercise.secondaryEquipmentTypes.splice(index, 1);
            }
          });
        }
        if (store.editBodyParts) {
          exercise.bodyParts = variation.bodyParts;
        }
        if (store.editSynergists) {
          exercise.synergists = variation.synergists;
        }
        if (store.editStabilizers) {
          exercise.stabilizers = variation.stabilizers;
        }
        if (store.editJoints) {
          exercise.joints = variation.joints;
        }
        if (store.editTrackingKeys) {
          exercise.trackingKeys = variation.trackingKeys;
        }
        if (store.editAddTrackingKeys) {
          store.addTrackingKeys.forEach((t) => exercise.trackingKeys.push(t));
        }
        if (store.editRemoveTrackingKeys) {
          store.removeTrackingKeys.forEach((t) => {
            const index = exercise.trackingKeys.findIndex((t0) => t0 === t);
            if (index !== -1) {
              exercise.trackingKeys.splice(index, 1);
            }
          });
        }
        if (store.editTags) {
          exercise.tags = variation.tags;
        }
        if (store.editAddTags) {
          store.addTags.forEach((t) => exercise.tags.push(t));
        }
        if (store.editRemoveTags) {
          store.removeTags.forEach((t) => {
            const index = exercise.tags.findIndex((t0) => t0 === t);
            if (index !== -1) {
              exercise.tags.splice(index, 1);
            }
          });
        }
        if (store.editHipFlexing) {
          exercise.hipFlexing = variation.hipFlexing;
        }
        if (store.editSpineFlexing) {
          exercise.spineFlexing = variation.spineFlexing;
        }
        if (store.editSpineForce) {
          exercise.spineForceType = variation.spineForceType;
        }
        return exercise.save();
      },
    }),
    props,
  );

  useEffect(() => {
    store.loadCount();
  }, [store, variables, filter]);

  const handleSave = React.useCallback(() => store.save(), [store]);

  return (
    <Modal
      width={1024}
      open={true}
      title={`Edits multiple exercises at once (${store.count} exercises)`}
      onOk={handleSave}
      onCancel={onClose}
      confirmLoading={store.saving}
    >
      <Form layout="vertical">
        <Row>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editType} onChange={(v) => (store.editType = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseTypeSelect disabled={!store.editType} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editMet} onChange={(v) => (store.editMet = v.target.checked)} />
              </Col>
              <Col>
                <Form.Item label="MET">
                  <InputNumber
                    min={1}
                    max={30}
                    step={0.1}
                    value={variation.met}
                    disabled={!store.editMet}
                    onChange={(e) => (variation.met = Number(e) ?? 0.0)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editExecutionType}
                  onChange={(v) => (store.editExecutionType = v.target.checked)}
                />
              </Col>
              <Col>
                <ExerciseExecutionTypeSelect disabled={!store.editExecutionType} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editPosition} onChange={(v) => (store.editPosition = v.target.checked)} />
              </Col>
              <Col>
                <ExercisePositionSelect disabled={!store.editPosition} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editForce} onChange={(v) => (store.editForce = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseForceSelect disabled={!store.editForce} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editMechanics} onChange={(v) => (store.editMechanics = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseMechanicsSelect disabled={!store.editMechanics} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editUtility} onChange={(v) => (store.editUtility = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseUtilitySelect disabled={!store.editUtility} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editHipFlexing} onChange={(v) => (store.editHipFlexing = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseHipFlexingSelect disabled={!store.editHipFlexing} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editSpineFlexing}
                  onChange={(v) => (store.editSpineFlexing = v.target.checked)}
                />
              </Col>
              <Col>
                <ExerciseSpineFlexingSelect disabled={!store.editSpineFlexing} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editSpineForce} onChange={(v) => (store.editSpineForce = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseSpineForceTypeSelect disabled={!store.editSpineForce} exercise={variation} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editEquipmentTypes}
                  onChange={(v) => (store.editEquipmentTypes = v.target.checked)}
                />
              </Col>
              <Col>
                <EquipmentTypeInput
                  disabled={!store.editEquipmentTypes}
                  container={variation.equipmentTypes.map((e) => e.id)}
                  label="Equipment Types"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editAddEquipmentTypes}
                  onChange={(v) => (store.editAddEquipmentTypes = v.target.checked)}
                />
              </Col>
              <Col>
                <EquipmentTypeInput
                  disabled={!store.editAddEquipmentTypes}
                  container={store.addEquipmentTypes}
                  label="Add Equipment Types"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editRemoveEquipmentTypes}
                  onChange={(v) => (store.editRemoveEquipmentTypes = v.target.checked)}
                />
              </Col>
              <Col>
                <EquipmentTypeInput
                  disabled={!store.editRemoveEquipmentTypes}
                  container={store.removeEquipmentTypes}
                  label="Remove Equipment Types"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editSecondaryEquipmentTypes}
                  onChange={(v) => (store.editSecondaryEquipmentTypes = v.target.checked)}
                />
              </Col>
              <Col>
                <EquipmentTypeInput
                  disabled={!store.editSecondaryEquipmentTypes}
                  container={variation.secondaryEquipmentTypes.map((e) => e.id)}
                  label="Secondary Equipment Types"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editAddSecondaryEquipmentTypes}
                  onChange={(v) => (store.editAddSecondaryEquipmentTypes = v.target.checked)}
                />
              </Col>
              <Col>
                <EquipmentTypeInput
                  disabled={!store.editAddSecondaryEquipmentTypes}
                  container={store.addSecondaryEquipmentTypes}
                  label="Add Secondary Equipment Types"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editRemoveSecondaryEquipmentTypes}
                  onChange={(v) => (store.editRemoveSecondaryEquipmentTypes = v.target.checked)}
                />
              </Col>
              <Col>
                <EquipmentTypeInput
                  disabled={!store.editRemoveSecondaryEquipmentTypes}
                  container={store.removeSecondaryEquipmentTypes}
                  label="Remove Secondary Equipment Types"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editBodyParts} onChange={(v) => (store.editBodyParts = v.target.checked)} />
              </Col>
              <Col>
                <BodyPartCard disabled={!store.editBodyParts} bodyParts={variation.bodyParts} header="Body Parts" />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editSynergists} onChange={(v) => (store.editSynergists = v.target.checked)} />
              </Col>
              <Col>
                <BodyPartCard disabled={!store.editSynergists} bodyParts={variation.synergists} header="Synergists" />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editStabilizers}
                  onChange={(v) => (store.editStabilizers = v.target.checked)}
                />
              </Col>
              <Col>
                <BodyPartCard
                  disabled={!store.editStabilizers}
                  bodyParts={variation.stabilizers}
                  header="Stabilizers"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editJoints} onChange={(v) => (store.editJoints = v.target.checked)} />
              </Col>
              <Col>
                <BodyPartJointCard disabled={!store.editJoints} joints={variation.joints} header="Joints" />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editTrackingKeys}
                  onChange={(v) => (store.editTrackingKeys = v.target.checked)}
                />
              </Col>
              <Col>
                <TrackingKeysSelectionView
                  disabled={!store.editTrackingKeys}
                  container={variation.trackingKeys}
                  viewContainer={variation.trackingParameters}
                  label="Tracking Keys"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editAddTrackingKeys}
                  onChange={(v) => (store.editAddTrackingKeys = v.target.checked)}
                />
              </Col>
              <Col>
                <TrackingKeysSelectionView
                  disabled={!store.editAddTrackingKeys}
                  container={store.addTrackingKeys}
                  label="Add Tracking Keys"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox
                  checked={store.editRemoveTrackingKeys}
                  onChange={(v) => (store.editRemoveTrackingKeys = v.target.checked)}
                />
              </Col>
              <Col>
                <TrackingKeysSelectionView
                  disabled={!store.editRemoveTrackingKeys}
                  container={store.removeTrackingKeys}
                  label="Remove Tracking Keys"
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editTags} onChange={(v) => (store.editTags = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseVariationTags disabled={!store.editTags} container={variation.tags} label="Replace Tags" />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editAddTags} onChange={(v) => (store.editAddTags = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseVariationTags disabled={!store.editAddTags} container={store.addTags} label="Add Tags" />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs="auto">
                <Checkbox checked={store.editRemoveTags} onChange={(v) => (store.editRemoveTags = v.target.checked)} />
              </Col>
              <Col>
                <ExerciseVariationTags
                  disabled={!store.editRemoveTags}
                  container={store.removeTags}
                  label="Remove Tags"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {store.saving ? (
          <SingleColRow>
            <Progress percent={store.progress} status="active" />
          </SingleColRow>
        ) : null}
      </Form>
    </Modal>
  );
});
