/**
 * Created by katarinababic on 5.10.22.
 */
export const totalCompletedActivities = `
SELECT
  *
FROM (
  SELECT
    event_name,
    count (*) AS completed_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND ( event_name = 'yogaSession_finished'
      OR event_name = 'meditationSession_finished'
      OR event_name = 'breathingSession_finished'
      OR event_name = 'FeedEntry_read'
      OR event_name = 'activity_logged')
  GROUP BY
    event_name )
UNION ALL (
  SELECT
    e.value.string_value AS type,
    count (*) AS completed_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'Workout_finished'
    AND e.key = 'workoutType'
  GROUP BY
    type )
UNION ALL (
  SELECT
    e.value.string_value AS event_name,
    COUNT (*) AS completed_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = 'recipe'
  GROUP BY
    event_name )
ORDER BY
  completed_count DESC
`;

export const gymTotalCompletedActivities = `
SELECT
  *
FROM (
  SELECT
    event_name,
    count (*) AS completed_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND ( event_name = 'yogaSession_finished'
      OR event_name = 'meditationSession_finished'
      OR event_name = 'breathingSession_finished'
      OR event_name = 'FeedEntry_read'
      OR event_name = 'activity_logged')
     AND p.key = 'gymId'
     AND p.value.string_value = @gym_id
  GROUP BY
    event_name )
UNION ALL (
  SELECT
    e.value.string_value AS type,
    count (*) AS completed_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e,
    UNNEST(user_properties) p
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'Workout_finished' 
    AND e.key = 'workoutType'
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
  GROUP BY
    type )
UNION ALL (
  SELECT
    e.value.string_value AS event_name,
    COUNT (*) AS completed_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e,
    UNNEST(user_properties) p
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = 'recipe'
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
  GROUP BY
    event_name )
ORDER BY
  completed_count DESC
`;
