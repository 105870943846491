import { Gym } from '../../../../Model/Gym/Gym';
import dayjs from '../../../../Utils/dayjs';

export type Resolution = 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER';

const excludedEvents = [
  'notification_receive',
  'notification_dismiss',
  'user_became_inactive_14d',
  'user_became_inactive_21d',
  'user_became_inactive_28d',
  'user_became_inactive_7d',
  'os_update',
  'app_remove',
  'app_update',
  'firebase_campaign',
  'zoomMeeting_explore_deleted',
  'app_clear_data',
  'screen_view',
  'login',
  'like_content',
  'workout_deleted',
  'onboarding_question_changed',
  'breathing_explore_deleted',
  'activityWorkout_explore_deleted',
  'fitnessProgramFiltersApplied',
  'activitylog_deleted',
  'first_open',
  'video_loaded',
  'body_value_changed',
  'dynamic_link_app_open',
  'event_scheduled_onboarding',
  'logout',
  'touchable_pressed',
  'magicLink_requested',
  'explore_entry_rating_submitted',
  'yoga_explore_deleted',
  'Registration_completed',
  'firebase_in_app_message_impression',
  'app_initialized',
  'search',
  'workoutTemplate_seen',
  'nutritionFiltersCleared',
  'app_open',
  'Account_Deleted',
  'ActivityLog_Shared',
  'Network_Connection_Lost',
  'webView_view',
  'ProfilePicture_update',
  'Account_Deleted',
  'onboarding_completed',
  'tutorial_begin',
  'countdownTimer_finished',
  'share',
  'Exercise_Info_View',
  'topic_deleted',
  'Exercise_Info_View',
  'exercise_skipped',
  'workplace_seen',
  'exercise_exchanged',
  'fitness_program_started',
  'event_scheduled',
  'tutorial_complete',
  'meditation_explore_deleted',
  'fitness_week_completed',
  'touchable_long_pressed',
  'activity_scheduled',
  'onboardingSection_completed',
  'notification_foreground',
  'event_unscheduled',
  'error',
  'fitness_program_ended',
  'onboarding_started',
  'firebase_in_app_message_dismiss',
  'slow_initialization',
  'notification_open',
  'workout_rating_submitted',
  'userRatedApp',
  'explore_modal_closed',
  'dynamic_link_first_open',
  'activityWorkout_seen',
  'Password_reset',
  'sign_up',
  'notification_opened',
  'Summary_Analytics_Chart_View',
  'meditation_seen',
  'fitnessProgramFiltersCleared',
  'topic_added',
  'Exercise_Video_View',
  'user_engagement',
  'nutritionFiltersApplied',
  'Network_Connected',
];

function queryGymIds(gym?: Gym, excludedGymIds: string[] = []): string {
  if (gym) {
    return `AND p.value.string_value = '${gym.id}'`;
  } else if (excludedGymIds.length > 0) {
    return excludedGymIds.reduce(
      (query, gymId) => `${query} AND p.value.string_value <> '${gymId}'`,
      'AND p.value.string_value IS NOT NULL',
    );
  }

  return `AND p.value.string_value <> 'flinq' AND p.value.string_value IS NOT NULL`;
}

export const usersActive = (gym?: Gym, resolution: Resolution = 'WEEK', excludedGymIds: string[] = []) => `
    SELECT l.minWeek,
           l.new_users,
           l.total_users_cumulative,
           IFNULL(r.users_active, 0) as users_active,
           ROUND(IFNULL(SAFE_DIVIDE(IFNULL(r.users_active, 0.0), l.total_users_cumulative), 0) * 100) AS active_users_percentage
    FROM (
             SELECT cum.week AS        minWeek,
                    cum.new_users,
                    SUM(cum.new_users) OVER (PARTITION BY 1 ORDER BY cum.week) AS total_users_cumulative
             FROM (
                      SELECT week,
                             IFNULL(userCount.new_users, 0) AS new_users
                      FROM UNNEST(GENERATE_DATE_ARRAY(DATE_TRUNC(${
                        gym ? `'${dayjs(gym.createdAt).format('YYYY-MM-DD')}'` : "'2021-06-01'"
                      }, ${resolution}), @end_date_iso, INTERVAL 1 ${resolution})) AS week
                               LEFT JOIN (
                          SELECT x.minWeek,
                                 x.new_users,
                          FROM (
                                   SELECT w.minWeek,
                                          COUNT(w.user_id) AS new_users
                                   FROM (
                                            SELECT MIN(t.week) AS minWeek,
                                                   t.user_id
                                            FROM (
                                                     SELECT DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution}) AS week,
                                                            user_id
                                                     FROM \`flinq-6796d.analytics_201736462.events_*\`,
                                                          UNNEST(user_properties) p
                                                     WHERE _TABLE_SUFFIX BETWEEN ${
                                                       gym
                                                         ? `'${dayjs(gym.createdAt).format('YYYYMMDD')}'`
                                                         : "'20210601'"
                                                     } AND @end_date
                                                      AND p.key = 'gymId'
                                                      ${queryGymIds(gym, excludedGymIds)}) t
                                            GROUP BY t.user_id
                                            ORDER BY minWeek) w
                                   GROUP BY w.minWeek
                                   ORDER BY w.minWeek) x) userCount ON userCount.minWeek = week) cum) l
             LEFT JOIN (
              SELECT
                  week,
                  COUNT(user_id) AS users_active FROM 
            (SELECT t.week, COUNT(t.user_id) AS users_active, t.user_id
            FROM (
                 SELECT user_id,
                        DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution}) AS week
                 FROM \`flinq-6796d.analytics_201736462.events_*\`,
                      UNNEST(user_properties) p
                 WHERE _TABLE_SUFFIX BETWEEN ${
                   gym ? `'${dayjs(gym.createdAt).format('YYYYMMDD')}'` : "'20210601'"
                 } AND @end_date
                   AND event_name <> 'notification_receive'
                   AND event_name <> 'notification_dismiss'
                   AND event_name <> 'user_became_inactive_14d'
                   AND event_name <> 'user_became_inactive_21d'
                   AND event_name <> 'user_became_inactive_28d'
                   AND event_name <> 'user_became_inactive_7d'
                   AND event_name <> 'os_update'
                   AND event_name <> 'app_remove'
                   AND event_name <> 'app_update'
                   AND event_name <> 'firebase_campaign'
                   AND event_name <> 'zoomMeeting_explore_deleted'
                   AND event_name <> 'app_clear_data'
                   AND event_name <> 'screen_view'
                   AND event_name <> 'login'
                   AND event_name <> 'like_content'
                   AND event_name <> 'workout_deleted'
                   AND event_name <> 'onboarding_question_changed'
                   AND event_name <> 'breathing_explore_deleted'
                   AND event_name <> 'activityWorkout_explore_deleted'
                   AND event_name <> 'fitnessProgramFiltersApplied'
                   AND event_name <> 'activitylog_deleted'
                   AND event_name <> 'first_open'
                   AND event_name <> 'video_loaded'
                   AND event_name <> 'body_value_changed'
                   AND event_name <> 'dynamic_link_app_open'
                   AND event_name <> 'event_scheduled_onboarding'
                   AND event_name <> 'logout'
                   AND event_name <> 'touchable_pressed'
                   AND event_name <> 'magicLink_requested'
                   AND event_name <> 'explore_entry_rating_submitted'
                   AND event_name <> 'yoga_explore_deleted'
                   AND event_name <> 'Registration_completed'
                   AND event_name <> 'firebase_in_app_message_impression'
                   AND event_name <> 'app_initialized'
                   AND event_name <> 'search'
                   AND event_name <> 'workoutTemplate_seen'
                   AND event_name <> 'nutritionFiltersCleared'
                   AND event_name <> 'app_open'
                   AND event_name <> 'Account_Deleted'
                   AND event_name <> 'ActivityLog_Shared'
                   AND event_name <> 'Network_Connection_Lost'
                   AND event_name <> 'webView_view'
                   AND event_name <> 'ProfilePicture_update'
                   AND event_name <> 'Account_Deleted'
                   AND event_name <> 'onboarding_completed'
                   AND event_name <> 'tutorial_begin'
                   AND event_name <> 'countdownTimer_finished'
                   AND event_name <> 'share'
                   AND event_name <> 'Exercise_Info_View'
                   AND event_name <> 'topic_deleted'
                   AND event_name <> 'Exercise_Info_View'
                   AND event_name <> 'exercise_skipped'
                   AND event_name <> 'workplace_seen'
                   AND event_name <> 'exercise_exchanged'
                   AND event_name <> 'fitness_program_started'
                   AND event_name <> 'event_scheduled'
                   AND event_name <> 'tutorial_complete'
                   AND event_name <> 'meditation_explore_deleted'
                   AND event_name <> 'fitness_week_completed'
                   AND event_name <> 'touchable_long_pressed'
                   AND event_name <> 'activity_scheduled'
                   AND event_name <> 'onboardingSection_completed'
                   AND event_name <> 'notification_foreground'
                   AND event_name <> 'event_unscheduled'
                   AND event_name <> 'error'
                   AND event_name <> 'fitness_program_ended'
                   AND event_name <> 'onboarding_started'
                   AND event_name <> 'firebase_in_app_message_dismiss'
                   AND event_name <> 'slow_initialization'
                   AND event_name <> 'notification_open'
                   AND event_name <> 'workout_rating_submitted'
                   AND event_name <> 'userRatedApp'
                   AND event_name <> 'explore_modal_closed'
                   AND event_name <> 'dynamic_link_first_open'
                   AND event_name <> 'activityWorkout_seen'
                   AND event_name <> 'Password_reset'
                   AND event_name <> 'sign_up'
                   AND event_name <> 'notification_opened'
                   AND event_name <> 'Summary_Analytics_Chart_View'
                   AND event_name <> 'meditation_seen'
                   AND event_name <> 'fitnessProgramFiltersCleared'
                   AND event_name <> 'topic_added'
                   AND event_name <> 'Exercise_Video_View'
                   AND event_name <> 'user_engagement'
                   AND event_name <> 'nutritionFiltersApplied'
                   AND event_name <> 'Network_Connected'
                   AND event_name <> 'session_start'
                   AND p.key = 'gymId'
                   ${queryGymIds(gym, excludedGymIds)}) t
        GROUP BY week, t.user_id) WHERE users_active >= @min_active GROUP BY week
        ) r  ON l.minWeek = r.week
`;

export const userRetention = (gym?: Gym, resolution: Resolution = 'WEEK', excludedGymIds: string[] = []) => `
SELECT
  weekIndex,
  COUNT(user_id) AS total_users,
  SUM(counter) AS total_active_users,
  AVG(activities_count) AS average_activity_count,
  SUM(activities_count) AS total_activities,
  SUM(open_count) AS total_opens,
  AVG(open_count) AS average_opens_per_user,
  SUM(open_count_counter) AS open_once_per_user
FROM (
  SELECT
    user_id,
    users.week,
    users.activities_count,
    users.weekIndex,
    users.counter,
    IFNULL(opens.open_count, 0) AS open_count,
    IF (opens.open_count > 0, 1, 0) AS open_count_counter
  FROM (
    SELECT
      user_id,
      dates AS week,
      IFNULL(activities_count, 0) AS activities_count,
      ROW_NUMBER() OVER (PARTITION BY user_id ORDER BY dates ASC) AS weekIndex,
      IF (activities_count >= @min_active, 1, 0) AS counter
    FROM (
      WITH
        temp AS (
        SELECT
          user_id,
          dates
        FROM (
          SELECT
            user_id,
            MIN(DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution})) min_date,
            MAX(DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution})) max_date
          FROM
            \`flinq-6796d.analytics_201736462.events_*\`,
            UNNEST(user_properties) p
          WHERE
            _TABLE_SUFFIX BETWEEN @start_date AND @end_date
            AND p.key = 'gymId'
            ${queryGymIds(gym, excludedGymIds)}
            AND p.value.string_value IS NOT NULL
          GROUP BY
            user_id ),
          UNNEST(GENERATE_DATE_ARRAY(min_date, max_date, INTERVAL 1 ${resolution})) dates )
      SELECT
        *
      FROM
        temp
      LEFT JOIN (
        SELECT
          DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution}) AS week,
          user_id AS user,
          COUNT(*) AS activities_count
        FROM
          \`flinq-6796d.analytics_201736462.events_*\`,
          UNNEST(user_properties) p
        WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name <> 'notification_receive'
          AND event_name <> 'notification_dismiss'
          AND event_name <> 'user_became_inactive_14d'
          AND event_name <> 'user_became_inactive_21d'
          AND event_name <> 'user_became_inactive_28d'
          AND event_name <> 'user_became_inactive_7d'
          AND event_name <> 'os_update'
          AND event_name <> 'app_remove'
          AND event_name <> 'app_update'
          AND event_name <> 'firebase_campaign'
          AND event_name <> 'zoomMeeting_explore_deleted'
          AND event_name <> 'app_clear_data'
          AND event_name <> 'screen_view'
          AND event_name <> 'login'
          AND event_name <> 'like_content'
          AND event_name <> 'workout_deleted'
          AND event_name <> 'onboarding_question_changed'
          AND event_name <> 'breathing_explore_deleted'
          AND event_name <> 'activityWorkout_explore_deleted'
          AND event_name <> 'fitnessProgramFiltersApplied'
          AND event_name <> 'activitylog_deleted'
          AND event_name <> 'first_open'
          AND event_name <> 'video_loaded'
          AND event_name <> 'body_value_changed'
          AND event_name <> 'dynamic_link_app_open'
          AND event_name <> 'event_scheduled_onboarding'
          AND event_name <> 'logout'
          AND event_name <> 'touchable_pressed'
          AND event_name <> 'magicLink_requested'
          AND event_name <> 'explore_entry_rating_submitted'
          AND event_name <> 'yoga_explore_deleted'
          AND event_name <> 'Registration_completed'
          AND event_name <> 'firebase_in_app_message_impression'
          AND event_name <> 'app_initialized'
          AND event_name <> 'search'
          AND event_name <> 'workoutTemplate_seen'
          AND event_name <> 'nutritionFiltersCleared'
          AND event_name <> 'app_open'
          AND event_name <> 'Account_Deleted'
          AND event_name <> 'ActivityLog_Shared'
          AND event_name <> 'Network_Connection_Lost'
          AND event_name <> 'webView_view'
          AND event_name <> 'ProfilePicture_update'
          AND event_name <> 'Account_Deleted'
          AND event_name <> 'onboarding_completed'
          AND event_name <> 'tutorial_begin'
          AND event_name <> 'countdownTimer_finished'
          AND event_name <> 'share'
          AND event_name <> 'Exercise_Info_View'
          AND event_name <> 'topic_deleted'
          AND event_name <> 'Exercise_Info_View'
          AND event_name <> 'exercise_skipped'
          AND event_name <> 'workplace_seen'
          AND event_name <> 'exercise_exchanged'
          AND event_name <> 'fitness_program_started'
          AND event_name <> 'event_scheduled'
          AND event_name <> 'tutorial_complete'
          AND event_name <> 'meditation_explore_deleted'
          AND event_name <> 'fitness_week_completed'
          AND event_name <> 'touchable_long_pressed'
          AND event_name <> 'activity_scheduled'
          AND event_name <> 'onboardingSection_completed'
          AND event_name <> 'notification_foreground'
          AND event_name <> 'event_unscheduled'
          AND event_name <> 'error'
          AND event_name <> 'fitness_program_ended'
          AND event_name <> 'onboarding_started'
          AND event_name <> 'firebase_in_app_message_dismiss'
          AND event_name <> 'slow_initialization'
          AND event_name <> 'notification_open'
          AND event_name <> 'workout_rating_submitted'
          AND event_name <> 'userRatedApp'
          AND event_name <> 'explore_modal_closed'
          AND event_name <> 'dynamic_link_first_open'
          AND event_name <> 'activityWorkout_seen'
          AND event_name <> 'Password_reset'
          AND event_name <> 'sign_up'
          AND event_name <> 'notification_opened'
          AND event_name <> 'Summary_Analytics_Chart_View'
          AND event_name <> 'meditation_seen'
          AND event_name <> 'fitnessProgramFiltersCleared'
          AND event_name <> 'topic_added'
          AND event_name <> 'Exercise_Video_View'
          AND event_name <> 'user_engagement'
          AND event_name <> 'nutritionFiltersApplied'
          AND event_name <> 'Network_Connected'
          AND event_name <> 'session_start'
          AND p.key = 'gymId'
          ${queryGymIds(gym, excludedGymIds)}
          AND p.value.string_value IS NOT NULL
        GROUP BY
          user,
          week) u
      ON
        u.user = temp.user_id
        AND u.week = temp.dates ) ) users
  LEFT JOIN (
    SELECT
      DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution}) AS week,
      user_id AS user,
      COUNT(*) AS open_count
    FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      UNNEST(user_properties) p
    WHERE
      _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      AND event_name = 'app_open'
      AND p.key = 'gymId'
      ${queryGymIds(gym, excludedGymIds)}
      AND p.value.string_value IS NOT NULL
    GROUP BY
      user,
      week) opens
  ON
    opens.user = users.user_id
    AND users.week = opens.week
  ORDER BY
    user_id,
    weekIndex )
GROUP BY
  weekIndex
ORDER BY
  weekIndex
`;

export const gymRetention = (gym?: Gym, resolution: Resolution = 'WEEK', excludedGymIds: string[] = []) => `
SELECT
  weekIndex,
  SUM(new_users) AS new_users,
  ROUND(AVG(new_users) * 10000) / 100 AS average_new_users,
  SUM(total_users) AS total_users,
  SUM(total_active_users) AS total_active_users,
  SUM(total_activities) AS total_activities,
  ROUND(AVG(average_activities_per_user) * 100) / 100 AS average_activities_per_user,
  SUM(total_active_activities) AS total_active_activities,
  ROUND(AVG(active_users_percentage) * 100) / 100 as average_active_users_percentage
FROM (
  SELECT
    *,
    ROUND(IFNULL(SAFE_DIVIDE(total_active_users, total_users_cumulative), 0) * 10000) / 100 AS active_users_percentage
  FROM (
    SELECT
      *,
      SUM(new_users) OVER (PARTITION BY gymId ORDER BY weekIndex) AS total_users_cumulative
    FROM (
      SELECT
        gymId,
        ANY_VALUE(week) AS week,
        weekIndex,
        ANY_VALUE(new_users) AS new_users,
        COUNT(user) AS total_users,
        COUNT(counter) AS total_active_users,
        IFNULL(SUM(activities_count), 0) AS total_activities,
        IFNULL(AVG(activities_count), 0) AS average_activities_per_user,
        SUM(activity_counter) AS total_active_activities
      FROM (
        SELECT
          *,
        IF (activities_count >= @min_active, 1, 0) AS counter,
        IF (activities_count >= @min_active, activities_count, 0) AS activity_counter
        FROM (
          SELECT
            gymUser.gymId,
            gymUser.week,
            gymUser.weekIndex,
            gymUser.new_users,
            user.user,
            user.activities_count
          FROM (
            SELECT
              gymUser.gymId,
              ANY_VALUE(gymUser.week) AS week,
              gymUser.weekIndex,
              COUNT(gymUser.user_id) AS new_users
            FROM (
              SELECT
                gym.gymId,
                gym.dates AS week,
                gym.weekIndex,
                nu.user_id
              FROM (
                SELECT
                  *,
                  ROW_NUMBER() OVER (PARTITION BY gymId ORDER BY dates ASC) AS weekIndex
                FROM (
                  WITH
                    temp AS (
                    SELECT
                      gymId,
                      dates
                    FROM (
                      SELECT
                        p.value.string_value AS gymId,
                        MIN(DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution})) min_date,
                        MAX(DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution})) max_date
                      FROM
                        \`flinq-6796d.analytics_201736462.events_*\`,
                        UNNEST(user_properties) p
                      WHERE
                        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
                        AND p.key = 'gymId'
                        ${queryGymIds(gym, excludedGymIds)}
                      GROUP BY
                        gymId ),
                      UNNEST(GENERATE_DATE_ARRAY(min_date, max_date, INTERVAL 1 ${resolution})) dates )
                  SELECT
                    *
                  FROM
                    temp ) ) gym
              LEFT JOIN (
                SELECT
                  ANY_VALUE(p.value.string_value) AS gymId,
                  user_id,
                  MIN(DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution})) joinWeek
                FROM
                  \`flinq-6796d.analytics_201736462.events_*\`,
                  UNNEST(user_properties) p
                WHERE
                  _TABLE_SUFFIX BETWEEN @start_date AND @end_date
                  AND p.key = 'gymId'
                  ${queryGymIds(gym, excludedGymIds)}
                  AND p.value.string_value IS NOT NULL
                GROUP BY
                  user_id ) nu
              ON
                nu.gymId = gym.gymId
                AND nu.joinWeek = gym.dates ) gymUser
            GROUP BY
              gymUser.gymId,
              gymUser.weekIndex ) gymUser
          LEFT JOIN (
            SELECT
              DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), ${resolution}) AS week,
              ANY_VALUE(p.value.string_value) AS gymId,
              user_id AS user,
              COUNT(*) AS activities_count
            FROM
              \`flinq-6796d.analytics_201736462.events_*\`,
              UNNEST(user_properties) p
            WHERE
              _TABLE_SUFFIX BETWEEN @start_date AND @end_date
              AND event_name <> 'notification_receive'
              AND event_name <> 'notification_dismiss'
              AND event_name <> 'user_became_inactive_14d'
              AND event_name <> 'user_became_inactive_21d'
              AND event_name <> 'user_became_inactive_28d'
              AND event_name <> 'user_became_inactive_7d'
              AND event_name <> 'os_update'
              AND event_name <> 'app_remove'
              AND event_name <> 'app_update'
              AND event_name <> 'firebase_campaign'
              AND event_name <> 'zoomMeeting_explore_deleted'
              AND event_name <> 'app_clear_data'
              AND event_name <> 'screen_view'
              AND event_name <> 'login'
              AND event_name <> 'like_content'
              AND event_name <> 'workout_deleted'
              AND event_name <> 'onboarding_question_changed'
              AND event_name <> 'breathing_explore_deleted'
              AND event_name <> 'activityWorkout_explore_deleted'
              AND event_name <> 'fitnessProgramFiltersApplied'
              AND event_name <> 'activitylog_deleted'
              AND event_name <> 'first_open'
              AND event_name <> 'video_loaded'
              AND event_name <> 'body_value_changed'
              AND event_name <> 'dynamic_link_app_open'
              AND event_name <> 'event_scheduled_onboarding'
              AND event_name <> 'logout'
              AND event_name <> 'touchable_pressed'
              AND event_name <> 'magicLink_requested'
              AND event_name <> 'explore_entry_rating_submitted'
              AND event_name <> 'yoga_explore_deleted'
              AND event_name <> 'Registration_completed'
              AND event_name <> 'firebase_in_app_message_impression'
              AND event_name <> 'app_initialized'
              AND event_name <> 'search'
              AND event_name <> 'workoutTemplate_seen'
              AND event_name <> 'nutritionFiltersCleared'
              AND event_name <> 'app_open'
              AND event_name <> 'Account_Deleted'
              AND event_name <> 'ActivityLog_Shared'
              AND event_name <> 'Network_Connection_Lost'
              AND event_name <> 'webView_view'
              AND event_name <> 'ProfilePicture_update'
              AND event_name <> 'Account_Deleted'
              AND event_name <> 'onboarding_completed'
              AND event_name <> 'tutorial_begin'
              AND event_name <> 'countdownTimer_finished'
              AND event_name <> 'share'
              AND event_name <> 'Exercise_Info_View'
              AND event_name <> 'topic_deleted'
              AND event_name <> 'Exercise_Info_View'
              AND event_name <> 'exercise_skipped'
              AND event_name <> 'workplace_seen'
              AND event_name <> 'exercise_exchanged'
              AND event_name <> 'fitness_program_started'
              AND event_name <> 'event_scheduled'
              AND event_name <> 'tutorial_complete'
              AND event_name <> 'meditation_explore_deleted'
              AND event_name <> 'fitness_week_completed'
              AND event_name <> 'touchable_long_pressed'
              AND event_name <> 'activity_scheduled'
              AND event_name <> 'onboardingSection_completed'
              AND event_name <> 'notification_foreground'
              AND event_name <> 'event_unscheduled'
              AND event_name <> 'error'
              AND event_name <> 'fitness_program_ended'
              AND event_name <> 'onboarding_started'
              AND event_name <> 'firebase_in_app_message_dismiss'
              AND event_name <> 'slow_initialization'
              AND event_name <> 'notification_open'
              AND event_name <> 'workout_rating_submitted'
              AND event_name <> 'userRatedApp'
              AND event_name <> 'explore_modal_closed'
              AND event_name <> 'dynamic_link_first_open'
              AND event_name <> 'activityWorkout_seen'
              AND event_name <> 'Password_reset'
              AND event_name <> 'sign_up'
              AND event_name <> 'notification_opened'
              AND event_name <> 'Summary_Analytics_Chart_View'
              AND event_name <> 'meditation_seen'
              AND event_name <> 'fitnessProgramFiltersCleared'
              AND event_name <> 'topic_added'
              AND event_name <> 'Exercise_Video_View'
              AND event_name <> 'user_engagement'
              AND event_name <> 'nutritionFiltersApplied'
              AND event_name <> 'Network_Connected'
              AND event_name <> 'session_start'
              AND p.key = 'gymId'
              ${queryGymIds(gym, excludedGymIds)}
            GROUP BY
              user,
              week ) user
          ON
            user.gymId = gymUser.gymId
            AND user.week = gymUser.week ) )
      GROUP BY
        gymId,
        weekIndex ) ) )
GROUP BY
  weekIndex
ORDER BY
  weekIndex
`;
