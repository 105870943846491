/**
 * Created by neo on 12.04.22.
 */
import { computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import {
  MindfulnessProgramTemplateDayUnit,
  MindfulnessProgramTemplateDayUnitJson,
} from './MindfulnessProgramTemplateDayUnit';
import { MindfulnessProgramTemplateDayUnitBuilder } from './MindfulnessProgramTemplateDayUnitBuilder';

export type MindfulnessProgramTemplateDayJson = {
  id: string;
  units: MindfulnessProgramTemplateDayUnitJson[];
};

export class MindfulnessProgramTemplateDay {
  @observable
  id = UUID();
  @observable
  units: MindfulnessProgramTemplateDayUnit[] = [];

  constructor(json?: Partial<MindfulnessProgramTemplateDayJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.units = (json.units ?? []).map((u) => MindfulnessProgramTemplateDayUnitBuilder.build(u));
    }
  }

  toJS(newId: boolean = false): MindfulnessProgramTemplateDayJson {
    return {
      id: newId ? UUID() : this.id,
      units: this.units.map((u) => u.toJS(newId)),
    };
  }

  @computed
  get minDurationInMinutes(): number | undefined {
    return this.units.reduce(
      (agg, cycle) =>
        cycle.durationInMinutes ? Math.min(cycle.durationInMinutes, agg ?? Number.MAX_SAFE_INTEGER) : agg,
      undefined as number | undefined,
    );
  }

  @computed
  get maxDurationInMinutes(): number | undefined {
    return this.units.reduce(
      (agg, cycle) =>
        cycle.durationInMinutes ? Math.max(cycle.durationInMinutes, agg ?? Number.MIN_SAFE_INTEGER) : agg,
      undefined as number | undefined,
    );
  }
}
