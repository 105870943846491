/**
 * Created by neo on 22.11.2023
 */
import { Audited, AuditedJson } from '../../Audited';
import { Pageable } from '../../Pageable';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { RouteChallengeTeamMember } from './RouteChallengeTeamMember';
import { observable, onBecomeObserved, runInAction } from 'mobx';
import { Media, MediaJson } from '../../Media/Media';
import {
  RouteChallengeTeamReachedLocation,
  RouteChallengeTeamReachedLocationJson,
} from './RouteChallengeTeamReachedLocation';
import { RouteChallengeTeamJoinRequest } from './RouteChallengeTeamJoinRequest';
import { Gym } from '../../Gym/Gym';
import { RouteChallengeLocationPosition, RouteChallengeLocationPositionJson } from './RouteChallengeLocationPosition';

export type RouteChallengeTeamRequest = Pageable & {
  query?: string;
  gymId?: string;
  challengeId?: string;
  athleteId?: string;
};

export type RouteChallengeTeamJson = AuditedJson & {
  challengeId: string;
  gymId: string;
  name: string;
  image?: MediaJson;
  isPrivate: boolean;
  totalCollectedPoints: number;
  reachedLocations: RouteChallengeTeamReachedLocationJson[];
  memberCount: number;
  maxAllowedMembers: number;
  minAllowedMembers: number;
  currentPosition: RouteChallengeLocationPositionJson;
  globalRank: number;
  companyRank: number;
  lastReachedLocationTimestamp?: string;
};

export class RouteChallengeTeam extends Audited {
  @observable
  challengeId: string = '';
  @observable
  gymId = '';
  @observable
  name: string = '';
  @observable
  image?: Media;
  @observable
  isPrivate: boolean = false;
  @observable
  totalCollectedPoints: number = 0;
  @observable
  reachedLocations: RouteChallengeTeamReachedLocation[] = [];
  @observable
  memberCount: number = 0;
  @observable
  maxAllowedMembers: number = 0;
  @observable
  minAllowedMembers: number = 0;
  @observable
  currentPosition = new RouteChallengeLocationPosition();
  @observable
  globalRank: number = 0;
  @observable
  companyRank: number = 0;
  @observable
  lastReachedLocationTimestamp?: Date;

  @observable
  members?: RouteChallengeTeamMember[];
  @observable
  gym?: Gym;

  constructor(json?: Partial<RouteChallengeTeamJson>) {
    super(json);
    if (json) {
      this.challengeId = json.challengeId ?? '';
      this.gymId = json.gymId ?? '';
      this.name = json.name ?? '';
      this.image = json.image ? new Media(json.image) : undefined;
      this.isPrivate = json.isPrivate ?? false;
      this.totalCollectedPoints = json.totalCollectedPoints ?? 0;
      this.reachedLocations = json.reachedLocations?.map((l) => new RouteChallengeTeamReachedLocation(l)) ?? [];
      this.memberCount = json.memberCount ?? 0;
      this.maxAllowedMembers = json.maxAllowedMembers ?? 0;
      this.minAllowedMembers = json.minAllowedMembers ?? 0;
      this.currentPosition = new RouteChallengeLocationPosition(json.currentPosition);
      this.globalRank = json.globalRank ?? 0;
      this.companyRank = json.companyRank ?? 0;
      this.lastReachedLocationTimestamp = json.lastReachedLocationTimestamp ? new Date(json.lastReachedLocationTimestamp) : undefined;
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
    onBecomeObserved(this, 'members', () => {
      if (!this.members) {
        this.fetchMembers();
      }
    });
  }

  fetchGym(): Promise<Gym | undefined> {
    if (!this.gym) {
      return Gym.get(this.gymId).then((res) => {
        runInAction(() => (this.gym = res));
        return res;
      });
    }

    return Promise.resolve(this.gym);
  }

  fetchMembers(): Promise<RouteChallengeTeamMember[]> {
    return HttpBackend.get(`/engagement/route-challenges/teams/${this.id}/members`)
      .then((response) => response.map((r) => new RouteChallengeTeamMember(r)))
      .then((members) => {
        runInAction(() => (this.members = members));
        return members;
      });
  }

  save(): Promise<RouteChallengeTeam> {
    return HttpBackend.post('/engagement/route-challenges/teams', {
      teamId: this.id,
      challengeId: this.challengeId,
      name: this.name,
      isPrivate: this.isPrivate,
      image: this.image?.toJS(),
    }).then((response) => new RouteChallengeTeam(response));
  }

  delete(): Promise<RouteChallengeTeam> {
    return HttpBackend.delete(`/engagement/route-challenges/teams/${this.id}`).then(() => this);
  }

  join(): Promise<RouteChallengeTeam> {
    return HttpBackend.post(`/engagement/route-challenges/teams/${this.id}/join`).then(() => this);
  }

  leave(): Promise<RouteChallengeTeam> {
    return HttpBackend.post(`/engagement/route-challenges/teams/${this.id}/leave`).then(() => this);
  }

  removeMember(memberId: string): Promise<RouteChallengeTeam> {
    return HttpBackend.delete(`/engagement/route-challenges/teams/admin/${this.id}/members/${memberId}`).then(() => this);
  }

  requests(): Promise<RouteChallengeTeamJoinRequest[]> {
    return HttpBackend.get(`/engagement/route-challenges/teams/${this.id}/join-requests`).then((response) =>
      response.map((r) => new RouteChallengeTeamJoinRequest(r)),
    );
  }

  addMember(athleteId: string): Promise<RouteChallengeTeam> {
    return HttpBackend.post(`/engagement/route-challenges/teams/admin/${this.id}/addMember?athleteId=${athleteId}`).then(
      () => this,
    );
  }

  static findOne(teamId: string): Promise<RouteChallengeTeam | undefined> {
    return HttpBackend.get(`/engagement/route-challenges/teams/admin/${teamId}`).then((response) =>
      response ? new RouteChallengeTeam(response) : undefined,
    );
  }

  static find(request?: Partial<RouteChallengeTeamRequest>): Promise<RouteChallengeTeam[]> {
    return HttpBackend.get('/engagement/route-challenges/teams/admin', request).then((response) =>
      response.map((r) => new RouteChallengeTeam(r)),
    );
  }

  static count(request?: Partial<RouteChallengeTeamRequest>): Promise<number> {
    return HttpBackend.get('/engagement/route-challenges/teams/admin/count', request);
  }

  static myTeam(challengeId: string): Promise<RouteChallengeTeam | undefined> {
    return HttpBackend.get(`/engagement/route-challenges/teams/my-team`, { challengeId }).then((response) =>
      response ? new RouteChallengeTeam(response) : undefined,
    );
  }
}
