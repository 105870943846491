/**
 * Created by neo on 20.03.2024
 */
import { Media, MediaJson } from '../../Media/Media';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { observable } from 'mobx';

export type RouteChallengeWalkthroughEntryJson = LocalizedArrayEntityJson & {
  image?: MediaJson;
};

export class RouteChallengeWalkthroughEntry extends LocalizedArrayEntity {
  @observable
  image?: Media;

  constructor(json?: Partial<RouteChallengeWalkthroughEntryJson>) {
    super(json);
    if (json) {
      this.image = json.image ? new Media(json.image) : undefined;
    }
  }

  toJS(newId: boolean = false): RouteChallengeWalkthroughEntryJson {
    return {
      ...super.toJS(newId),
      image: this.image?.toJS(),
    };
  }
}
