/**
 *
 */
import * as React from 'react';

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 32,
  },
};

export type Props = {
  text?: string;
};

export class LoadingContainer extends React.Component<Props> {
  render() {
    const {
      props: { text },
    } = this;
    return <div style={styles.container}>{text || 'Loading...'}</div>;
  }
}
