/**
 * Created by neo on 16.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../Model/Gym/Gym';
import { Form } from 'antd';
import { Col, Row } from 'reactstrap';
import { UserActivityPercentage } from './UserActivityPercentage';
import { UserRetention } from './UserRetention';
import { GymRetentionNew } from './GymRetentionNew';

export type KpiTabProps = {
  gym?: Gym;
};

export const KpiTab: React.FC<KpiTabProps> = observer(({ gym }) => {
  return (
    <Form layout="vertical">
      <Row>
        <Col md={6}>
          <UserActivityPercentage title="User Activity % - Weekly" resolution="WEEK" gym={gym} />
        </Col>
        <Col md={6}>
          <UserActivityPercentage title="User Activity % - Monthly" resolution="MONTH" gym={gym} />
        </Col>
        <Col md={6}>
          <UserActivityPercentage title="User Activity % - Quarterly" resolution="QUARTER" gym={gym} />
        </Col>
        <Col md={6}>
          <UserActivityPercentage title="User Activity % - Daily" resolution="DAY" gym={gym} />
        </Col>
        <Col md={6}>
          <UserRetention title="User Retention - Daily" resolution="DAY" gym={gym} />
        </Col>
        <Col md={6}>
          <UserRetention title="User Retention - Weekly" resolution="WEEK" gym={gym} />
        </Col>
        <Col md={6}>
          <UserRetention title="User Retention - Monthly" resolution="MONTH" gym={gym} />
        </Col>
        <Col md={12}>
          <GymRetentionNew title="User Activity % after n Months" resolution="MONTH" gym={gym} />
        </Col>
        {/*<Col md={12}>*/}
        {/*  <GymRetention title="User Activity % after n Months" resolution="MONTH" />*/}
        {/*</Col>*/}
        <Col md={12}>
          <GymRetentionNew title="User Activity % after n weeks" resolution="WEEK" gym={gym} />
        </Col>
        {/*<Col md={12}>*/}
        {/*  <GymRetention title="User Activity % after n weeks" resolution="WEEK" />*/}
        {/*</Col>*/}
      </Row>
    </Form>
  );
});
