/**
 * @flow
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col, Table, Badge, FormGroup, Label, Input, Button, Card, CardBody } from 'reactstrap';
import { observer } from 'mobx-react';
import { EquipmentTypeInput } from '../../../../Components/EquipmentTypeInput';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { AthleteEquipment } from '../../../../Model/AthleteEquipment/AthleteEquipment';

export type Props = {
  athleteEquipment: AthleteEquipment;
  selected: boolean;
  onSelect: (item: AthleteEquipment) => any;
  onRemove?: (equipment: AthleteEquipment) => any;
};

@observer
export class AthleteEquipmentTableRow extends React.Component<Props> {
  handleSelect = () => {
    this.props.onSelect(this.props.athleteEquipment);
  };

  handleChangeName = ({ target: { value } }) => {
    this.props.athleteEquipment.name = value;
  };

  handleSave = () => {
    this.props.athleteEquipment.save();
  };

  handleRemove = () => {
    this.props.onRemove && this.props.onRemove(this.props.athleteEquipment);
  };

  render() {
    const {
      props: { athleteEquipment, selected, onRemove },
    } = this;
    return (
      <tbody>
        <tr onClick={this.handleSelect}>
          <td>{athleteEquipment.name}</td>
          <td>{athleteEquipment.locationType}</td>
          <td>
            {athleteEquipment.equipmentTypes.sort().map((equipment) => (
              <Badge key={equipment} color="info" pill={true} style={{ marginRight: 4 }}>
                {equipment}
              </Badge>
            ))}
          </td>
          <td>
            {onRemove ? (
              <Button color="danger" onClick={this.handleRemove}>
                Delete
              </Button>
            ) : null}
          </td>
        </tr>
        {selected ? (
          <tr>
            <td colSpan={4}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Name</Label>
                        <Input type="text" onChange={this.handleChangeName} value={athleteEquipment.name} />
                      </FormGroup>
                    </Col>
                    <Col xs="auto">
                      <Button color="success" onClick={this.handleSave} size="sm">
                        Save
                      </Button>
                    </Col>
                  </Row>
                  <SingleColRow>
                    <EquipmentTypeInput container={athleteEquipment.equipmentTypes} />
                  </SingleColRow>
                </CardBody>
              </Card>
            </td>
          </tr>
        ) : null}
      </tbody>
    );
  }
}
