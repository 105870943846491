/**
 * Created by neo on 22.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';

export type ExploreIndexScreenCategoryPathProps = {
  category: ExploreCategory;
  gymId?: string;
};

export const ExploreIndexScreenCategoryPath: React.FC<ExploreIndexScreenCategoryPathProps> = observer(
  ({ category, gymId }) => {
    const [parents, setParents] = useState<ExploreCategory[]>([]);

    const fetchParents = React.useCallback(async () => {
      let result: ExploreCategory | undefined = category;
      const results = [category];

      while (result) {
        result = await result.fetchParent(result.parentId);
        if (result) {
          results.push(result);
        }
      }

      setParents(results.reverse());
    }, [category]);

    useEffect(() => {
      fetchParents();
    }, [fetchParents]);

    return (
      <React.Fragment>
        <Link to={`/coach/explore?gymId=${gymId ?? ''}`}>Explore Tab</Link>
        {parents.map((parent, index) => (
          <React.Fragment key={parent.id}>
            {' / '}
            <Link to={`/coach/explore/${parent.id}?gymId=${gymId ?? ''}`}>{parent.defaultName}</Link>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  },
);
