/**
 * Created by neo on 06.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategory } from '../../../../../Model/Engagement/EarnModelTemplateCategory';
import { ActivationFrequencyTabInput } from './ActivationFrequencyTabInput';
import { Col, Row } from 'reactstrap';
import { Button } from 'antd';
import { runInAction } from 'mobx';
import { EarnModelTemplateRuleActivationFrequency } from '../../../../../Model/Engagement/EarnModelTemplateRuleActivationFrequency';
import { EarnModelTemplateRuleAbstract } from '../../../../../Model/Engagement/EarnModelTemplateRuleAbstract';

export type ActivationFrequencyTabProps = {
  template: EarnModelTemplate;
  category: EarnModelTemplateCategory;
  rule: EarnModelTemplateRuleAbstract;
};

export const ActivationFrequencyTab: React.FC<ActivationFrequencyTabProps> = observer(
  ({ template, category, rule }) => {
    const handleAdd = React.useCallback(
      () => runInAction(() => rule.activationFrequencies.push(new EarnModelTemplateRuleActivationFrequency())),
      [rule],
    );

    return (
      <Row>
        <Col></Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleAdd}>
            Add Frequency Constraint
          </Button>
        </Col>
        <Col xs={12}>
          {rule.activationFrequencies.map((frequency, index) => (
            <ActivationFrequencyTabInput
              key={index.toString()}
              rule={rule}
              index={index}
              activationFrequency={frequency}
            />
          ))}
        </Col>
      </Row>
    );
  },
);
