import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { CollectStage } from '../../../../../../../Model/Coach/Stages/CollectStage';

export class CollectNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: CollectStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'collect' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
