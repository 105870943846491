import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { NotEqualsNodeWidget } from './NotEqualsNodeWidget';
import { NotEqualsNodeModel } from './NotEqualsNodeModel';
import { NotEqualsStage } from '../../../../../../../../Model/Coach/Stages/Logical/NotEqualsStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class NotEqualsNodeModelFactory extends AbstractReactFactory<NotEqualsNodeModel, DiagramEngine> {
  constructor() {
    super('logical:neq');
  }

  generateReactWidget(event): JSX.Element {
    return <NotEqualsNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new NotEqualsNodeModel(new NotEqualsStage(new Pipeline()));
  }
}
