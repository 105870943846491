/**
 * Created by katarinababic on 16.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { FileContent } from '../../../Model/Media/FileStorage/FileContent';
import { useParams } from 'react-router';

export type EditFileDialogProps = {
  isOpen?: boolean;
  file: FileContent;
  onClose?: () => void;
};

export const EditFileDialog: React.FC<EditFileDialogProps> = observer(({ isOpen, file, onClose }) => {
  const { folderId } = useParams();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [processing, setProcessing] = React.useState(false);

  let displayName = file.displayName;
  if (folderId && file.displayName.startsWith(folderId)) {
    displayName = file.displayName.substring(folderId.length + 1);
  }
  const [name, setName] = React.useState(displayName);

  const handleNameChange = React.useCallback(({ target: { value } }) => {
    setName(value);
  }, []);

  const handleRenameFolder = React.useCallback(() => {
    setProcessing(true);
    FileContent.renameFile(file.name, `${folderId}/${name}`)
      .then(() => {
        onClose?.();
      })
      .catch((e) => setErrorMessage(e.message))
      .finally(() => setProcessing(false));
  }, [file, name, onClose]);

  const toggle = React.useCallback(() => {
    if (isOpen) {
      onClose?.();
    }
  }, [onClose]);

  const dismiss = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Create Folder</ModalHeader>
      <ModalBody>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <FormGroup>
          <Label>{'Add a name that describes this file, including extension. Example: "document.pdf"'}</Label>
          <Input type="text" onChange={handleNameChange} value={name} autoFocus />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {processing ? (
          <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </Col>
        ) : (
          <React.Fragment>
            <Button color="primary" onClick={handleRenameFolder}>
              Rename
            </Button>
            <Button color="secondary" onClick={dismiss}>
              Cancel
            </Button>
          </React.Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
});
