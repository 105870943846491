/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { runInAction, toJS } from 'mobx';
import { useLocalStore, observer } from 'mobx-react';
import { Athlete } from '../../Model/Athlete/Athlete';
import { Row, Col, Container, Table } from 'reactstrap';
import { Pager } from '../../Components/Pager';
import { PageResult } from '../../Model/PageResult';
import { CreateAthleteModal } from './CreateAthleteModal';
import { ModalPromise } from '../../Components/ModalPromise';
import { useEffect, useState } from 'react';
import { Token } from '../../Services/Security/Token';
import { useRootStore } from '../../Store/useRootStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingleColRow } from '../../Components/SingleColRow';
import { User } from '../../Model/User';
import { AthleteTableRow } from './AthleteTableRow';
import { Button, Input, Modal, Select } from 'antd';

export type AthleteListScreenProps = {};

export const AthleteListScreen: React.FC<AthleteListScreenProps> = observer(() => {
  const { authentication } = useRootStore();
  const location = useLocation();
  const history = useNavigate();
  const search = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [query, setQuery] = useState(search.get('query') ?? '');
  const [result, setResult] = useState(new PageResult<Athlete>());
  const [showCreateAthleteModal, setShowCreateAthleteModal] = useState(false);
  const [oauthToken, setOauthToken] = useState<Token | undefined>();

  const modalRef = React.useRef<ModalPromise | null>(null);
  const debounce = React.useRef<any>(undefined);
  const app = search.get('apps') ?? '';

  useEffect(() => {
    const page = Number(search.get('page') ?? '0');
    const size = Number(search.get('size') ?? '20');
    const params = Object.assign({ page: 0, size: 20 }, Object.fromEntries(search), { sort: 'createdAt,DESC' });
    params.apps = params.apps === 'coach-all' ? 'coach-web,coach' : params.apps;
    PageResult.execute(Athlete.find(params), Athlete.count(toJS(params)), page, size).then((res) => setResult(res));
  }, [search]);

  const handleChange = React.useCallback(
    ({ target: { value: query } }: any) => {
      setQuery(query);
      clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        search.set('query', query);
        history({
          pathname: location.pathname,
          search: search.toString(),
        });
      }, 300);
    },
    [history, location, search],
  );

  const handleRemove = React.useCallback((athlete: Athlete) => {
    Modal.confirm({
      title: `Do you really want to delete ${athlete.fullName || athlete.user.email}?`,
      onOk() {
        athlete.remove(); //.then(() => store.loadAthletes());
      },
    });
  }, []);

  const handlePage = React.useCallback(
    (newPage: number) => {
      search.set('page', newPage.toString());
      history({
        pathname: location.pathname,
        search: search.toString(),
      });
    },
    [history, location, search],
  );

  const handleCreate = React.useCallback(() => {
    setShowCreateAthleteModal(true);
  }, []);

  const handleCloseCreateAthleteModal = React.useCallback(
    (athlete?: Athlete) => {
      setShowCreateAthleteModal(false);
      if (athlete) {
        runInAction(() => result.content.unshift(athlete));
      }
    },
    [result],
  );

  const handleFetchToken = React.useCallback(async (athlete: Athlete) => {
    athlete
      .fetchOAuthToken()
      .then((res) => setOauthToken(res))
      .then(() => modalRef.current && modalRef.current.show());
  }, []);

  const handleClear = React.useCallback(() => {
    history({
      pathname: location.pathname,
      search: new URLSearchParams(`sort=createdAt,DESC`).toString(),
    });
  }, [history, location]);

  const handleChangeApp = React.useCallback(
    (value) => {
      history({
        pathname: location.pathname,
        search: new URLSearchParams(`apps=${value}&query=${query}`).toString(),
      });
    },
    [history, location, query],
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'Athletes'}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreate}>
            Create New Athlete
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input.Search type="text" value={query} onChange={handleChange} onSearch={handleClear} enterButton="Clear" />
        </Col>
        <Col md="auto">
          <Select value={app} onChange={handleChangeApp}>
            <Select.Option value="">All</Select.Option>
            <Select.Option value="coach-all">Coach</Select.Option>
            <Select.Option value="coach">App</Select.Option>
            <Select.Option value="coach-web">Web</Select.Option>
            <Select.Option value="club">kGym</Select.Option>
          </Select>
        </Col>
      </Row>
      <SingleColRow style={{ marginTop: 4, marginBottom: 4 }}>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <Table size="sm" striped hover>
          <thead className="thead-inverse">
            <tr>
              <th />
              <th>{'Name'}</th>
              <th>{'User'}</th>
              <th>{'Apps'}</th>
              <th>{'Last Seen'}</th>
              <th>{'Created'}</th>
              {authentication.isGodAdmin ? <th>{''}</th> : null}
            </tr>
          </thead>
          <tbody>
            {result.content.slice().map((athlete) => (
              <AthleteTableRow
                key={athlete.id}
                athlete={athlete}
                onRemove={handleRemove}
                onFetchToken={handleFetchToken}
              />
            ))}
          </tbody>
        </Table>
        {showCreateAthleteModal ? <CreateAthleteModal onClose={handleCloseCreateAthleteModal} /> : null}
      </SingleColRow>
      <ModalPromise title={'OAuth Token'} ref={modalRef} size="lg">
        <Input.TextArea rows={30} disabled value={JSON.stringify(oauthToken, null, 4)} />
      </ModalPromise>
    </Container>
  );
});
