import { EmptySignal } from '../../Signal/EmptySignal';
import { Stage, StagePortMode } from '../Stage';
import { Pipeline } from '../../Pipeline';
import { dataSignal } from '../../Signal/DataSignal';

export class AndStage extends Stage<any> {
  constructor(pipeline: Pipeline, json?: any) {
    super(pipeline, json, 'SYNC');
  }

  process() {
    let signals = this.take();
    if (signals.length > 0) {
      while (signals.length > 0) {
        const dataSignals = signals.filter(dataSignal);
        if (dataSignals.length === signals.length) {
          // TODO this will replicate a possible signal
          dataSignals.forEach((signal) => this.processNext(signal));
        } else {
          this.processNext(new EmptySignal());
        }
        signals = this.take();
      }
    } else {
      this.processNext(new EmptySignal());
    }
  }

  toJS(): any {
    return super.toJS();
  }

  get type(): string {
    return 'logical:and';
  }
}
