/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { LogicalNodeWidget, LogicalNodeWidgetProps } from '../LogicalNodeWidget';

export type GreaterThanEqualsNodeWidgetProps = {} & LogicalNodeWidgetProps;

export const LessThanEqualsNodeWidget: React.FC<GreaterThanEqualsNodeWidgetProps> = observer(
  ({ node, engine, size }: GreaterThanEqualsNodeWidgetProps) => {
    return <LogicalNodeWidget engine={engine} node={node} size={size} title="LTE" />;
  },
);
