import { EarnModelTemplateRuleSimple } from './EarnModelTemplateRuleSimple';
import { EarnModelTemplateRuleAbstract, EarnModelTemplateRuleAbstractJson } from './EarnModelTemplateRuleAbstract';
import { EarnModelTemplateRuleGrouped } from './EarnModelTemplateRuleGrouped';
import { EarnModelTemplateRuleInstant } from './EarnModelTemplateRuleInstant';
import { EarnModelTemplateRuleStepped } from './EarnModelTemplateRuleStepped';

export class EarnModelTemplateRuleBuilder {
  static build(json: Partial<EarnModelTemplateRuleAbstractJson>): EarnModelTemplateRuleAbstract {
    switch (json.type) {
      case 'grouped':
        return new EarnModelTemplateRuleGrouped(json);
      case 'instant':
        return new EarnModelTemplateRuleInstant(json);
      case 'stepped':
        return new EarnModelTemplateRuleStepped(json);
      case 'simple':
      default:
        return new EarnModelTemplateRuleSimple(json);
    }
  }
}
