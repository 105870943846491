/**
 * Created by katarinababic on 17.11.23.
 */
import { computed, observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type FileStorageContentJson = {
  name: string;
  isDirectory?: boolean;
};

export class FileStorageContent {
  @observable
  name: string = '';
  @observable
  isDirectory?: boolean;

  constructor(json?: Partial<FileStorageContentJson>) {
    if (json) {
      this.name = json.name ?? '';
      this.isDirectory = json.isDirectory ?? false;
    }
  }

  static transformPath(path: string) {
    if (path.endsWith('/')) {
      return path;
    }
    return `${path}/`;
  }

  static async download(paths: string[]) {
    const pathsWithSlash = paths.map((path) => this.transformPath(path));
    return HttpBackend.postRaw(`/media/file-storage/download`, pathsWithSlash, { responseType: 'blob' });
  }

  @computed
  get displayName(): string {
    let displayName = this.name;
    if (displayName.endsWith('/')) {
      displayName = displayName.slice(0, -1);
    }
    return displayName;
  }
}
