/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { MicroCycle } from '../../../../Model/Coach/Program/MicroCycle';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Button, Card, Modal, Space } from 'antd';
import { Row, Col } from 'reactstrap';
import { MicroCycleDayView } from './MicroCycleDayView';
import { MicroCycleDay } from '../../../../Model/Coach/Program/MicroCycleDay';
import { ResultViewer } from '../../WorkoutScript/View/ResultViewer';
import { MicroCycleDayModal } from './MicroCycleDayModal';
import { CoachProgram } from '../../../../Model/Coach/Program/CoachProgram';
import dayjs, { Dayjs } from 'dayjs';
import { MesoCycle } from '../../../../Model/Coach/Program/MesoCycle';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';

function countTotalWeeksUntilNow(template: FitnessProgramTemplate, mesoCycle: MesoCycle): number {
  let total = 1;
  for (const mesoCycleTemplate of template.mesoCycles) {
    if (mesoCycleTemplate.id === mesoCycle.configuration.mesoCycleTemplateId) {
      return total;
    }
    total += mesoCycleTemplate.cycles;
  }

  return total;
}

export type MicroCycleViewProps = {
  program: CoachProgram;
  mesoCycle: MesoCycle;
  microCycle: MicroCycle;
};

export const MicroCycleView: React.FC<MicroCycleViewProps> = observer(({ program, mesoCycle, microCycle }) => {
  const [selectedDay, setSelectedDay] = useState<MicroCycleDay | undefined>();
  const [refreshing, setRefreshing] = useState(false);

  const isCurrentMicroCycle = program.currentMesoCycle?.microCycleIds.at(-1) === microCycle.id;

  const totalWeeksUntilNow = program.template
    ? countTotalWeeksUntilNow(program.template, mesoCycle) + microCycle.weekIndex
    : microCycle.weekIndex;

  const handleRefresh = React.useCallback(() => {
    setRefreshing(true);
    microCycle.refresh().finally(() => setRefreshing(false));
  }, [microCycle]);

  const handleClick = React.useCallback((day) => {
    setSelectedDay(day);
  }, []);

  const handleCloseResultModal = React.useCallback(() => {
    setSelectedDay(undefined);
  }, []);

  return (
    <React.Fragment>
      <Card
        title={`Week ${totalWeeksUntilNow} / Micro Cycle ${microCycle.weekIndex + 1} / ${dayjs(
          microCycle.startDateTime,
        ).format('DD.MM.YYYY HH:mm')} - ${
          microCycle.deadline ? dayjs(microCycle.deadline).format('DD.MM.YYYY HH:mm') : ''
        }`}
        extra={
          <React.Fragment>
            {!program.endDateTime && isCurrentMicroCycle && (
              <Button onClick={handleRefresh} loading={refreshing} disabled={refreshing}>
                Refresh
              </Button>
            )}
          </React.Fragment>
        }
      >
        <Row>
          {microCycle.entries.map((day) => (
            <Col key={day.id} md={Math.floor(12 / microCycle.entries.length)}>
              <MicroCycleDayView athleteSuperMacro={program} microCycleDay={day} onClick={handleClick} />
            </Col>
          ))}
        </Row>
      </Card>
      {selectedDay ? (
        <MicroCycleDayModal athleteSuperMacro={program} microCycleDay={selectedDay} onClose={handleCloseResultModal} />
      ) : null}
    </React.Fragment>
  );
});
