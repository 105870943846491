import * as React from 'react';
import { Input } from './Input';
import { Suggestions, SuggestionEntry } from './Suggestions';
import { Tag } from './Tag';

import './style.css';

const KEYS = {
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
};

const CLASS_NAMES = {
  root: 'react-tags',
  rootFocused: 'is-focused',
  selected: 'react-tags__selected',
  selectedTag: 'react-tags__selected-tag',
  selectedTagName: 'react-tags__selected-tag-name',
  search: 'react-tags__search',
  searchInput: 'react-tags__search-input',
  suggestions: 'react-tags__suggestions',
  suggestionActive: 'is-active',
  suggestionDisabled: 'is-disabled',
};

export type ReactTagsProps<T extends SuggestionEntry> = {
  tags: any[];
  placeholder?: string;
  suggestions: T[];
  autofocus?: boolean;
  autoresize?: boolean;
  delimiters: number[];
  delimiterChars: string[];
  onRemove?: (index: number, entry?: SuggestionEntry) => any;
  onAddition?: (tag: T) => any;
  onInputChange?: (query: string) => any;
  onFocus?: () => any;
  onBlur?: () => any;
  minQueryLength?: number;
  maxSuggestionsLength?: number;
  classNames?: any;
  allowNew?: boolean;
  allowBackspace?: boolean;
  tagComponent?: any;
  inputAttributes?: any;
  disabled?: boolean;
};

export class ReactTags<T extends SuggestionEntry> extends React.Component<ReactTagsProps<T>> {
  suggestions: any = {};
  input?: any;

  static defaultProps = {
    tags: [],
    placeholder: 'Add new tag',
    suggestions: [],
    autofocus: false,
    autoresize: true,
    delimiters: [KEYS.TAB, KEYS.ENTER],
    delimiterChars: [],
    minQueryLength: 2,
    maxSuggestionsLength: 6,
    allowNew: false,
    allowBackspace: true,
    tagComponent: null,
    inputAttributes: {},
  };

  state: {
    query: string;
    focused?: boolean;
    expandable?: boolean;
    selectedIndex: number;
    classNames?: any;
  };

  constructor(props: ReactTagsProps<T>) {
    super(props);

    this.state = {
      query: '',
      focused: false,
      expandable: false,
      selectedIndex: -1,
      classNames: Object.assign({}, CLASS_NAMES, this.props.classNames),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: ReactTagsProps<T>) {
    this.setState({
      classNames: Object.assign({}, CLASS_NAMES, newProps.classNames),
    });
  }

  handleInput(e) {
    const query = e.target.value;

    if (this.props.onInputChange) {
      this.props.onInputChange(query);
    }

    this.setState({ query });
  }

  handleKeyDown(e) {
    const { query, selectedIndex } = this.state;
    const { delimiters, delimiterChars } = this.props;

    // when one of the terminating keys is pressed, add current query to the tags.
    if (delimiters.indexOf(e.keyCode) > -1 || delimiterChars.indexOf(e.key) > -1) {
      if (query || selectedIndex > -1) {
        e.preventDefault();
      }

      if (query.length >= (this.props.minQueryLength ?? 1)) {
        // Check if the user typed in an existing suggestion.
        const match = this.suggestions.state.options.findIndex((suggestion) => {
          return suggestion.name.search(new RegExp(`^${query}$`, 'i')) === 0;
        });

        const index = selectedIndex === -1 ? match : selectedIndex;

        if (index > -1) {
          this.addTag(this.suggestions.state.options[index]);
        } else if (this.props.allowNew) {
          this.addTag({ name: query });
        }
      }
    }

    // when backspace key is pressed and query is blank, delete the last tag
    if (e.keyCode === KEYS.BACKSPACE && query.length === 0 && this.props.allowBackspace) {
      this.deleteTag(this.props.tags.length - 1);
    }

    if (e.keyCode === KEYS.UP_ARROW) {
      e.preventDefault();

      // if last item, cycle to the bottom
      if (selectedIndex <= 0) {
        this.setState({ selectedIndex: this.suggestions.state.options.length - 1 });
      } else {
        this.setState({ selectedIndex: selectedIndex - 1 });
      }
    }

    if (e.keyCode === KEYS.DOWN_ARROW) {
      e.preventDefault();

      this.setState({ selectedIndex: (selectedIndex + 1) % this.suggestions.state.options.length });
    }
  }

  handleClick(e) {
    if (document.activeElement !== e.target) {
      this.input.input.focus();
    }
  }

  handleBlur() {
    this.setState({ focused: false, selectedIndex: -1 });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  handleFocus() {
    this.setState({ focused: true });

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  addTag(tag) {
    if (tag.disabled) {
      return;
    }

    this.props.onAddition && this.props.onAddition(tag);

    // reset the state
    this.setState({
      query: '',
      selectedIndex: -1,
    });
  }

  deleteTag(i: number) {
    this.props.onRemove && this.props.onRemove(i, this.props.tags[i]);
    this.setState({ query: '' });
  }

  render() {
    const listboxId = 'ReactTags-listbox';

    const TagComponent = this.props.tagComponent || Tag;

    const tags = this.props.tags.map((tag, i) => (
      <TagComponent key={i} tag={tag} classNames={this.state.classNames} onDelete={this.deleteTag.bind(this, i)} />
    ));

    const expandable = this.state.focused && this.state.query.length >= (this.props.minQueryLength ?? 1);
    const classNames = [this.state.classNames.root];

    this.state.focused && classNames.push(this.state.classNames.rootFocused);

    return (
      <div className={classNames.join(' ')} onClick={this.handleClick.bind(this)}>
        <div className={this.state.classNames.selected} aria-live="polite">
          {tags}
        </div>
        <div
          className={this.state.classNames.search}
          onBlurCapture={this.handleBlur.bind(this)}
          onFocusCapture={this.handleFocus.bind(this)}
          onInput={this.handleInput.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        >
          <Input
            {...this.state}
            inputAttributes={this.props.inputAttributes}
            ref={(c) => {
              this.input = c;
            }}
            listboxId={listboxId}
            autofocus={this.props.autofocus}
            autoresize={this.props.autoresize}
            expandable={expandable}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
          />
          <Suggestions
            {...this.state}
            ref={(c) => {
              this.suggestions = c;
            }}
            listboxId={listboxId}
            expandable={expandable}
            suggestions={this.props.suggestions}
            addTag={this.addTag.bind(this)}
            maxSuggestionsLength={this.props.maxSuggestionsLength}
          />
        </div>
      </div>
    );
  }
}
