/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EarnModelTemplate } from '../../../../Model/Engagement/EarnModelTemplate';
import { EarnModelTemplateCategory } from '../../../../Model/Engagement/EarnModelTemplateCategory';
import { Button, Card, Collapse, Dropdown, Menu, Popconfirm, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { runInAction } from 'mobx';
import { EarnModelTemplateRuleTable } from './EarnModelTemplateRuleTable';
import { DownOutlined } from '@ant-design/icons';
import { EarnModelTemplateRuleBuilder } from '../../../../Model/Engagement/EarnModelTemplateRuleBuilder';

export type EarnModelTemplateCategoryPanelProps = {
  template: EarnModelTemplate;
  category: EarnModelTemplateCategory;
};

export const EarnModelTemplateCategoryPanel: React.FC<EarnModelTemplateCategoryPanelProps> = observer(
  ({ template, category }) => {
    const history = useNavigate();

    const handleEdit = React.useCallback(() => {
      history(`${category.id}`);
    }, [history, category]);

    const handleAddRule = React.useCallback(
      (type: string = 'simple') => {
        const newRule = EarnModelTemplateRuleBuilder.build({
          templateName: `New Rule ${category.rules.length + 1}`,
          type,
        });
        runInAction(() => category.rules.push(newRule));
        history(`${category.id}/${newRule.id}`);
      },
      [category, history],
    );

    const handleRemove = React.useCallback(() => {
      const index = template.categories.findIndex((r) => r.id === category.id);
      if (index !== -1) {
        template.categories.splice(index, 1);
      }
    }, [template, category]);

    const menu = React.useMemo(
      () => (
        <Menu
          items={[
            {
              key: '1',
              label: (
                <Button type="link" onClick={() => handleAddRule('simple')}>
                  Simple Rule
                </Button>
              ),
            },
            {
              key: 'stepped',
              label: (
                <Button type="link" onClick={() => handleAddRule('stepped')}>
                  Stepped Rule
                </Button>
              ),
            },
            {
              key: '3',
              label: (
                <Button type="link" onClick={() => handleAddRule('grouped')}>
                  Grouped Rule
                </Button>
              ),
            },
            {
              key: '4',
              label: (
                <Button type="link" onClick={() => handleAddRule('instant')}>
                  Instant Rule
                </Button>
              ),
            },
          ]}
        />
      ),
      [handleAddRule],
    );

    return (
      <React.Fragment>
        <Card
          title={category.templateName}
          size="small"
          extra={
            <React.Fragment>
              <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Add Rule
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
              <Button type="link" onClick={handleEdit}>
                Edit
              </Button>
              <Popconfirm title={`Delete rule ${category.templateName}?`} onConfirm={handleRemove}>
                <Button type="link" size="small">
                  Remove
                </Button>
              </Popconfirm>
            </React.Fragment>
          }
          style={{ marginTop: 16 }}
        >
          <EarnModelTemplateRuleTable template={template} category={category} />
        </Card>
      </React.Fragment>
    );
  },
);
