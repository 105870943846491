/**
 * Created by neo on 29.04.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Athlete } from '../Model/Athlete/Athlete';
import { Link } from 'react-router-dom';

export type AthleteLinkProps = {
  athlete?: Athlete;
};

export const AthleteLink: React.FC<AthleteLinkProps> = observer(({ athlete }) => {
  return athlete?.id ? (
    <Link to={`/athlete/${athlete?.id}`}>{athlete?.fullName || athlete?.id || '<does not exists>'}</Link>
  ) : null;
});
