/**
 * Created by neo on 23.03.22.
 */
import { Audited, AuditedJson } from '../../Audited';
import { PhaseType } from '../../ProgramPortfolio/PhaseType';
import { ExerciseFilter, ExerciseFilterJson } from '../../Exercise/ExerciseFilter';
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type WorkoutModuleJson = AuditedJson & {
  name: string;
  phaseType: PhaseType;
  tags: string[];
  exerciseQuery: ExerciseFilterJson;
  limitExercises?: number;
  randomizeExercises: boolean;
  exerciseIds: string[];
  sets: number[];
  trackingKeyValues: Record<string, number[]>;
  substitutionModuleId?: string;
};

export class WorkoutModule extends Audited {
  @observable
  name: string = '';
  @observable
  phaseType: PhaseType = 'strength';
  @observable
  tags: string[] = [];
  @observable
  exerciseQuery = new ExerciseFilter();
  @observable
  limitExercises?: number;
  @observable
  randomizeExercises: boolean = true;
  @observable
  exerciseIds: string[] = [];
  @observable
  sets: number[] = [];
  @observable
  trackingKeyValues: Record<string, number[]> = {};
  @observable
  substitutionModuleId?: string;

  constructor(json?: Partial<WorkoutModuleJson>) {
    super(json);
    if (json) {
      this.name = json.name ?? '';
      this.phaseType = json.phaseType ?? 'strength';
      this.tags = json.tags ?? [];
      this.exerciseQuery = new ExerciseFilter(json.exerciseQuery);
      this.limitExercises = json.limitExercises;
      this.randomizeExercises = json.randomizeExercises ?? true;
      this.exerciseIds = json.exerciseIds ?? [];
      this.trackingKeyValues = json.trackingKeyValues ?? {};
      this.substitutionModuleId = json.substitutionModuleId;
    }
  }

  toJS(newId: boolean = false): WorkoutModuleJson {
    return Object.assign(super.toJS(newId), {
      name: this.name,
      phaseType: this.phaseType,
      tags: toJS(this.tags),
      exerciseQuery: this.exerciseQuery.toJS(),
      limitExercises: this.limitExercises,
      randomizeExercises: this.randomizeExercises,
      exerciseIds: this.exerciseIds,
      sets: this.sets,
      trackingKeyValues: toJS(this.trackingKeyValues),
      substitutionModuleId: this.substitutionModuleId,
    });
  }

  static findOne(moduleId: string): Promise<WorkoutModule> {
    return HttpBackend.get(`/coach/program/template/module/${moduleId}`).then((res) => new WorkoutModule(res));
  }
}
