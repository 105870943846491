/* eslint-disable camelcase */

import { Token } from './Token';
import Backend from '../Http/Backend';
import { Config } from '../../Config/Config';

export class UserToken extends Token {
  async refresh(): Promise<UserToken> {
    console.log(`Refreshing token`, this.refresh_token);
    const res = await new Backend(undefined, undefined, Config.backend.authUrl).postForm(
      `/uaa/oauth/token`,
      {
        grant_type: 'refresh_token',
        refresh_token: this.refresh_token,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
    if (res) {
      return new UserToken({ created: Date.now(), ...res });
    }
    throw new Error('Unable to refresh token');
  }
}
