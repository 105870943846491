/**
 * Created by neo on 28.04.20.
 */
import * as React from 'react';
import { Tabs, TabsProps } from 'antd';
import { GymBasicInfoTab } from './GymBasicInfoTab/GymBasicInfoTab';
import { EmployeeTab } from './EmployeeTab/EmployeeTab';
import { GymCustomersTab } from './GymCustomersTab/GymCustomersTab';
import { ActivityLogTab } from './ActivityLogTab/ActivityLogTab';
import { Gym } from '../../../../Model/Gym/Gym';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { GymCoachSubscriptionTab } from './CoachSubscriptionTab/GymCoachSubscriptionTab';
import { GymChallengesTab } from './GymChallengesTab/GymChallengesTab';
import { GymExerciseStatisticsTab } from './GymExerciseStatisticsTab/GymExerciseStatisticsTab';
import { useRootStore } from '../../../../Store/useRootStore';
import { CustomerGroupTab } from './CustomerGroupTab/CustomerGroupTab';
import { GymMessagingTab } from './GymMailTab/GymMessagingTab';
import { GymRewardsConfigurationTab } from './GymRewardsConfigurationTab/GymRewardsConfigurationTab';

export type GymViewScreenTabsProps = {
  gym: Gym;
  onTabChange?: TabsProps['onChange'];
};

export const GymViewScreenTabs: React.FC<GymViewScreenTabsProps> = observer(({ gym, onTabChange }) => {
  const { authentication } = useRootStore();
  const { gymId, tab = 'basic' } = useParams<any>();

  const isNew = React.useMemo(() => !gymId || gymId === 'new', [gymId]);

  return (
    <Tabs activeKey={tab} onChange={onTabChange} animated={false} destroyInactiveTabPane={true}>
      {authentication.hasAnyRole(['ROLE_SUPPORT', 'ROLE_SALES']) && (
        <React.Fragment>
          <Tabs.TabPane tab="Basic Info" key="basic">
            <GymBasicInfoTab gym={gym} isNew={isNew} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Subscription" key="subscription" disabled={isNew}>
            <GymCoachSubscriptionTab gym={gym} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Studio Access" key="employee" disabled={isNew}>
            <EmployeeTab gym={gym} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Employees" key="client" disabled={isNew}>
            <GymCustomersTab gym={gym} />
          </Tabs.TabPane>
        </React.Fragment>
      )}
      {authentication.hasAnyRole(['ROLE_SUPPORT', 'ROLE_SALES']) && (
        <React.Fragment>
          <Tabs.TabPane tab="Exercise Statistics" key="exerciseStats" disabled={isNew}>
            <GymExerciseStatisticsTab gym={gym} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Activity Logs" key="workoutlog" disabled={isNew}>
            <ActivityLogTab gym={gym} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="User Groups" key="customerGroup" disabled={isNew}>
            <CustomerGroupTab gym={gym} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Challenges" key="challenges" disabled={isNew}>
            <GymChallengesTab gym={gym} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Mail / Messaging" key="messaging">
            <GymMessagingTab gym={gym} />
          </Tabs.TabPane>
        </React.Fragment>
      )}
      <Tabs.TabPane tab="Reward Config" key="reward-config" disabled={isNew}>
        <GymRewardsConfigurationTab gym={gym} />
      </Tabs.TabPane>
    </Tabs>
  );
});
