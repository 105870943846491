/**
 * Created by neo on 19.12.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Card, Form, Input, Slider, Space, Switch } from 'antd';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useEffect, useState } from 'react';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeTable } from './RecipeTable';
import {
  CuisinesList,
  CuisinesType,
  DietsList,
  DietsType,
  DishList,
  DishType,
  RecipeSearchParams,
  SeasonList,
} from '../../../../Model/Diet/Recipe/RecipeSearchParams';
import { Route, useNavigate, Routes } from 'react-router-dom';
import { RecipeEditModal } from './RecipeEditModal';
import { observable, runInAction } from 'mobx';
import { PageResult } from '../../../../Model/PageResult';
import { Pager } from '../../../../Components/Pager';
import { RecipeDietRecommendation } from './RecipeDietRecommendation';
import { MealRequirementCalculation } from '../../../../Model/Diet/Planning/MealRequirementCalculation';
import CreatableSelect from 'react-select/creatable';
import { TranslateRecipesComponent } from './TranslateRecipesComponent';
import { FixIngredientDescriptionComponent } from './FixIngredientDescriptionComponent';

export type RecipeListScreenProps = {};

export const RecipeListScreen: React.FC<RecipeListScreenProps> = observer((props) => {
  const history = useNavigate();
  const [store] = useState(() =>
    observable({
      queryData: {
        query: '',
        page: 0,
        archived: false,
      } as RecipeSearchParams,
    }),
  );
  // const [query, setQuery] = useState('');
  // const [diet, setDiet] = useState<string[]>([]);
  // const [dishTypes, setDishTypes] = useState(string[])([]);
  const offset = React.useRef(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<PageResult<Recipe>>(new PageResult());
  const [importing, setImporting] = useState(false);
  const debounce = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const search = React.useCallback(
    (params: RecipeSearchParams) => {
      setLoading(true);
      setResult(new PageResult());
      PageResult.execute(
        Recipe.search(Object.assign({}, params, { sort: 'createdAt,DESC' })),
        Recipe.count(params),
        store.queryData.page,
      )
        .then((result) => setResult(result))
        .finally(() => setLoading(false));
    },
    [store],
  );

  const handleQueryChange = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (store.queryData.query = value));
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 300);
    },
    [search, store],
  );

  const handleQueryClear = React.useCallback(() => {
    runInAction(() => (store.queryData.query = ''));
    setResult(new PageResult());
  }, [store]);

  const handleChangeDiet = React.useCallback(
    (values?: any[]) => {
      runInAction(() => {
        store.queryData.diet = (values ?? []).map((t) => t.value);
        search(store.queryData);
      });
    },
    [search, store],
  );

  const handleChangeDish = React.useCallback(
    (values?: any[]) => {
      runInAction(() => {
        store.queryData.dishTypes = (values ?? []).map((t) => t.value);
        search(store.queryData);
      });
    },
    [search, store],
  );

  const handleChangeCuisine = React.useCallback(
    (values?: any[]) => {
      runInAction(() => {
        store.queryData.cuisine = (values ?? []).map((t) => t.value);
        search(store.queryData);
      });
    },
    [search, store],
  );

  const handleChangeSeason = React.useCallback(
    (values?: any[]) => {
      runInAction(() => {
        store.queryData.seasons = (values ?? []).map((t) => t.value);
        search(store.queryData);
      });
    },
    [search, store],
  );

  const handleShowArchived = React.useCallback(
    (checked: boolean) => {
      runInAction(() => (store.queryData.archived = checked));
      search(store.queryData);
    },
    [store],
  );

  const handleToggleEmpty = React.useCallback(
    (checked: boolean) => {
      runInAction(() => (store.queryData.empty = checked));
      search(store.queryData);
    },
    [store, search],
  );

  const handleCreate = React.useCallback(() => {
    history(`new`);
  }, [history]);

  const handlePage = React.useCallback(
    (page: number) => {
      if (store.queryData.page !== page) {
        runInAction(() => (store.queryData.page = page));
        debounce.current && clearTimeout(debounce.current);
        debounce.current = setTimeout(() => search(store.queryData), 300);
      }
    },
    [search, store],
  );

  const handleKcalChange = React.useCallback(
    ([min, max]: number[]) => {
      store.queryData.minCalories = min === max && min === 0 ? undefined : min;
      store.queryData.maxCalories = min === max && max === 0 ? undefined : max;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleCarbsChange = React.useCallback(
    ([min, max]: number[]) => {
      store.queryData.minCarbs = min === max && min === 0 ? undefined : min;
      store.queryData.maxCarbs = min === max && max === 0 ? undefined : max;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleFatChange = React.useCallback(
    ([min, max]: number[]) => {
      store.queryData.minFat = min === max && min === 0 ? undefined : min;
      store.queryData.maxFat = min === max && max === 0 ? undefined : max;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleProteinChange = React.useCallback(
    ([min, max]: number[]) => {
      store.queryData.minProtein = min === max && min === 0 ? undefined : min;
      store.queryData.maxProtein = min === max && max === 0 ? undefined : max;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleCarbsPercentChange = React.useCallback(
    ([min, max]: number[]) => {
      store.queryData.minCarbsPercent = min;
      store.queryData.maxCarbsPercent = max;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleFatPercentChange = React.useCallback(
    ([min, max]: number[]) => {
      runInAction(() => {
        store.queryData.minFatPercent = min;
        store.queryData.maxFatPercent = max;
      });
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleProteinPercentChange = React.useCallback(
    ([min, max]: number[]) => {
      store.queryData.minProteinPercent = min;
      store.queryData.maxProteinPercent = max;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleReadyInMinutesChange = React.useCallback(
    ([min, max]: number[]) => {
      store.queryData.minReadyTime = min;
      store.queryData.maxReadyTime = max;
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(store.queryData), 500);
    },
    [store, search],
  );

  const handleRecommendationChanged = React.useCallback(
    (recommendation: MealRequirementCalculation) => {
      runInAction(() => {
        store.queryData = Object.assign({}, recommendation.searchParams);
        // queryData.maxCarbs = recommendation.carbs * 0.9;
        // queryData.maxFat = recommendation.fat * 1.1;
        // queryData.minProtein = recommendation.protein * 1.1;
        // queryData.dishTypes = recommendation.searchParams.dishTypes;
        // queryData.diet = recommendation.searchParams.diet;
        // store.queryData = {
        //   maxCalories: recommendation.searchParams.maxCalories,
        //   minCarbsPercent: recommendation.searchParams.minCarbsPercent,
        //   maxCarbsPercent: recommendation.searchParams.maxCarbsPercent,
        // };
        search(store.queryData);
      });
    },
    [store, search],
  );

  const handleSaved = () => search(store.queryData);

  const handleArchived = () => search(store.queryData);

  useEffect(() => {
    search({});
  }, [search]);

  return (
    <Form layout="vertical">
      <Container>
        <Row>
          <Col>
            <h1>Recipes</h1>
          </Col>
          <Col xs="auto">
            <Space>
              <Button type="primary" onClick={handleCreate}>
                Create Recipe
              </Button>
              <FixIngredientDescriptionComponent />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Input.Search
                onChange={handleQueryChange}
                value={store.queryData.query}
                autoFocus={true}
                placeholder="Search for recipes..."
                enterButton="Clear"
                onSearch={handleQueryClear}
                loading={loading}
                disabled={importing}
              />
            </Form.Item>
          </Col>
          <Col xs="auto" key={'archived'}>
            <Switch
              checkedChildren="Show archived"
              unCheckedChildren="Hide archived"
              checked={store.queryData.archived}
              onChange={handleShowArchived}
            />
          </Col>
          <Col xs="auto" key={'archived'}>
            <Switch
              checkedChildren="Show empty"
              unCheckedChildren="Show empty"
              checked={store.queryData.empty}
              onChange={handleToggleEmpty}
            />
          </Col>
        </Row>
        <SingleColRow>
          <Form.Item label="Recommendations">
            <RecipeDietRecommendation onRecommendationChanged={handleRecommendationChanged} />
          </Form.Item>
        </SingleColRow>
        <Row>
          <Col>
            <Form.Item label="Diet">
              <CreatableSelect
                isClearable
                isMulti
                options={DietsList.map((value) => ({ label: value, value }))}
                onChange={handleChangeDiet as any}
                value={(store.queryData.diet ?? []).map((value) => ({ label: value, value }))}
                placeholder="Diets"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Dishes">
              <CreatableSelect
                isClearable
                isMulti
                options={DishList.map((value) => ({ label: value, value }))}
                onChange={handleChangeDish as any}
                value={(store.queryData.dishTypes ?? []).map((value) => ({ label: value, value }))}
                placeholder="Dishes"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Cuisines">
              <CreatableSelect
                isClearable
                isMulti
                options={CuisinesList.map((value) => ({ label: value, value }))}
                onChange={handleChangeCuisine as any}
                value={(store.queryData.cuisine ?? []).map((value) => ({ label: value, value }))}
                placeholder="Cuisines"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Seasons">
              <CreatableSelect
                isClearable
                isMulti
                options={SeasonList.map((value) => ({ label: value, value }))}
                onChange={handleChangeSeason as any}
                value={(store.queryData.seasons ?? []).map((value) => ({ label: value, value }))}
                placeholder="Seasons"
              />
            </Form.Item>
          </Col>
        </Row>
        {/*<SingleColRow>*/}
        {/*  <Card title="Nutrient" size="small">*/}
        {/*    <Form layout="vertical">*/}
        {/*      <Row>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Kcal">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minCalories ?? 0, store.queryData.maxCalories ?? 0]}*/}
        {/*              onChange={handleKcalChange}*/}
        {/*              range={true}*/}
        {/*              max={2500}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Carbs">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minCarbs ?? 0, store.queryData.maxCarbs ?? 0]}*/}
        {/*              onChange={handleCarbsChange}*/}
        {/*              range={true}*/}
        {/*              max={100}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Fat">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minFat ?? 0, store.queryData.maxFat ?? 0]}*/}
        {/*              onChange={handleFatChange}*/}
        {/*              range={true}*/}
        {/*              max={100}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Protein">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minProtein ?? 0, store.queryData.maxProtein ?? 0]}*/}
        {/*              onChange={handleProteinChange}*/}
        {/*              range={true}*/}
        {/*              max={100}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Carbs %">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minCarbsPercent ?? 0, store.queryData.maxCarbsPercent ?? 100]}*/}
        {/*              onChange={handleCarbsPercentChange}*/}
        {/*              range={true}*/}
        {/*              max={100}*/}
        {/*              step={1}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Fat %">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minFatPercent ?? 0, store.queryData.maxFatPercent ?? 100]}*/}
        {/*              onChange={handleFatPercentChange}*/}
        {/*              range={true}*/}
        {/*              max={100}*/}
        {/*              step={1}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Protein %">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minProteinPercent ?? 0, store.queryData.maxProteinPercent ?? 100]}*/}
        {/*              onChange={handleProteinPercentChange}*/}
        {/*              range={true}*/}
        {/*              min={0}*/}
        {/*              max={100}*/}
        {/*              step={1}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*        <Col md={3}>*/}
        {/*          <Form.Item label="Ready in Minutes">*/}
        {/*            <Slider*/}
        {/*              value={[store.queryData.minReadyTime ?? 0, store.queryData.maxReadyTime ?? 240]}*/}
        {/*              onChange={handleReadyInMinutesChange}*/}
        {/*              range={true}*/}
        {/*              min={0}*/}
        {/*              max={240}*/}
        {/*              step={5}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*        </Col>*/}
        {/*      </Row>*/}
        {/*    </Form>*/}
        {/*  </Card>*/}
        {/*</SingleColRow>*/}
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
        <SingleColRow>
          <RecipeTable recipes={result.content} />
        </SingleColRow>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
      </Container>
      <Routes>
        <Route path=":recipeId" element={<RecipeEditModal onSaved={handleSaved} onArchived={handleArchived} />} />
      </Routes>
    </Form>
  );
});
