/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Button, Form } from 'antd';
import { Col, Row } from 'reactstrap';
import { PipelineParam } from '../../../../../Model/Coach/PipelineParam';
import { PhaseParamEditorInput } from './PhaseParamEditorInput';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { has, isObservable } from 'mobx';
import { ParamCondition } from '../../../../../Model/Coach/SuperMacro/ParamCondition';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';

export type PhaseParamEditorProps = {
  phaseTemplate: ParamCondition | WorkoutTemplate | PhaseConfiguration;
  param: PipelineParam;
  phaseConfiguration?: PhaseConfiguration;
  workoutTemplate?: WorkoutTemplate;
};

export const PhaseParamEditor: React.FC<PhaseParamEditorProps> = observer(
  ({ phaseTemplate, param, phaseConfiguration, workoutTemplate }) => {
    const exists = isObservable(phaseTemplate.params ?? {}) ? has(phaseTemplate.params ?? {}, param.name) ?? false : false;

    const handleRemove = React.useCallback(() => {
      phaseTemplate.removeParam(param.name);
    }, [phaseTemplate, param]);

    const handleSet = React.useCallback(() => {
      phaseTemplate.setParam(param.name);
    }, [phaseTemplate, param]);

    return (
      <Form.Item label={param.name} style={{ marginBottom: 8 }}>
        <PhaseParamEditorInput
          param={param}
          phaseTemplate={phaseTemplate}
          phaseConfiguration={phaseConfiguration}
          workoutTemplate={workoutTemplate}
          disabled={!exists}
          suffix={
            exists ? (
              <Button size="small" danger onClick={handleRemove}>
                Reset
              </Button>
            ) : (
              <Button size="small" type="primary" ghost onClick={handleSet}>
                Set
              </Button>
            )
          }
        />
      </Form.Item>
    );
  },
);
