import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { PipelineNodeModel } from './PipelineNodeModel';
import { PipelineNodeWidget } from './PipelineNodeWidget';
import { PipelineStage } from '../../../../../../../Model/Coach/Stages/PipelineStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class PipelineNodeFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('pipeline');
  }

  generateReactWidget(event): JSX.Element {
    return <PipelineNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new PipelineNodeModel(new PipelineStage(new Pipeline()));
  }
}
