export const withDayAndHour = (weekDayColName: string = 'weekDay', hourColName: string = 'hour') => `
WITH root AS (
    SELECT 'Monday' as ${weekDayColName}, '00' as ${hourColName} UNION ALL
    SELECT 'Monday', '01' UNION ALL    
    SELECT 'Monday', '02' UNION ALL    
    SELECT 'Monday', '03' UNION ALL    
    SELECT 'Monday', '04' UNION ALL    
    SELECT 'Monday', '05' UNION ALL    
    SELECT 'Monday', '06' UNION ALL    
    SELECT 'Monday', '07' UNION ALL    
    SELECT 'Monday', '08' UNION ALL 
    SELECT 'Monday', '09' UNION ALL       
    SELECT 'Monday', '10' UNION ALL       
    SELECT 'Monday', '11' UNION ALL       
    SELECT 'Monday', '12' UNION ALL       
    SELECT 'Monday', '13' UNION ALL       
    SELECT 'Monday', '14' UNION ALL       
    SELECT 'Monday', '15' UNION ALL       
    SELECT 'Monday', '16' UNION ALL       
    SELECT 'Monday', '17' UNION ALL       
    SELECT 'Monday', '18' UNION ALL       
    SELECT 'Monday', '19' UNION ALL       
    SELECT 'Monday', '20' UNION ALL       
    SELECT 'Monday', '21' UNION ALL       
    SELECT 'Monday', '22' UNION ALL       
    SELECT 'Monday', '23' UNION ALL  
    SELECT 'Tuesday', '00' UNION ALL    
    SELECT 'Tuesday', '01' UNION ALL    
    SELECT 'Tuesday', '02' UNION ALL    
    SELECT 'Tuesday', '03' UNION ALL    
    SELECT 'Tuesday', '04' UNION ALL    
    SELECT 'Tuesday', '05' UNION ALL    
    SELECT 'Tuesday', '06' UNION ALL    
    SELECT 'Tuesday', '07' UNION ALL    
    SELECT 'Tuesday', '08' UNION ALL 
    SELECT 'Tuesday', '09' UNION ALL       
    SELECT 'Tuesday', '10' UNION ALL       
    SELECT 'Tuesday', '11' UNION ALL       
    SELECT 'Tuesday', '12' UNION ALL       
    SELECT 'Tuesday', '13' UNION ALL       
    SELECT 'Tuesday', '14' UNION ALL       
    SELECT 'Tuesday', '15' UNION ALL       
    SELECT 'Tuesday', '16' UNION ALL       
    SELECT 'Tuesday', '17' UNION ALL       
    SELECT 'Tuesday', '18' UNION ALL       
    SELECT 'Tuesday', '19' UNION ALL       
    SELECT 'Tuesday', '20' UNION ALL       
    SELECT 'Tuesday', '21' UNION ALL       
    SELECT 'Tuesday', '22' UNION ALL       
    SELECT 'Tuesday', '23' UNION ALL 
    SELECT 'Wednesday', '00' UNION ALL    
    SELECT 'Wednesday', '01' UNION ALL    
    SELECT 'Wednesday', '02' UNION ALL    
    SELECT 'Wednesday', '03' UNION ALL    
    SELECT 'Wednesday', '04' UNION ALL    
    SELECT 'Wednesday', '05' UNION ALL    
    SELECT 'Wednesday', '06' UNION ALL    
    SELECT 'Wednesday', '07' UNION ALL    
    SELECT 'Wednesday', '08' UNION ALL 
    SELECT 'Wednesday', '09' UNION ALL       
    SELECT 'Wednesday', '10' UNION ALL       
    SELECT 'Wednesday', '11' UNION ALL       
    SELECT 'Wednesday', '12' UNION ALL       
    SELECT 'Wednesday', '13' UNION ALL       
    SELECT 'Wednesday', '14' UNION ALL       
    SELECT 'Wednesday', '15' UNION ALL       
    SELECT 'Wednesday', '16' UNION ALL       
    SELECT 'Wednesday', '17' UNION ALL       
    SELECT 'Wednesday', '18' UNION ALL       
    SELECT 'Wednesday', '19' UNION ALL       
    SELECT 'Wednesday', '20' UNION ALL       
    SELECT 'Wednesday', '21' UNION ALL       
    SELECT 'Wednesday', '22' UNION ALL       
    SELECT 'Wednesday', '23' UNION ALL 
    SELECT 'Thursday', '00' UNION ALL    
    SELECT 'Thursday', '01' UNION ALL    
    SELECT 'Thursday', '02' UNION ALL    
    SELECT 'Thursday', '03' UNION ALL    
    SELECT 'Thursday', '04' UNION ALL    
    SELECT 'Thursday', '05' UNION ALL    
    SELECT 'Thursday', '06' UNION ALL    
    SELECT 'Thursday', '07' UNION ALL    
    SELECT 'Thursday', '08' UNION ALL 
    SELECT 'Thursday', '09' UNION ALL       
    SELECT 'Thursday', '10' UNION ALL       
    SELECT 'Thursday', '11' UNION ALL       
    SELECT 'Thursday', '12' UNION ALL       
    SELECT 'Thursday', '13' UNION ALL       
    SELECT 'Thursday', '14' UNION ALL       
    SELECT 'Thursday', '15' UNION ALL       
    SELECT 'Thursday', '16' UNION ALL       
    SELECT 'Thursday', '17' UNION ALL       
    SELECT 'Thursday', '18' UNION ALL       
    SELECT 'Thursday', '19' UNION ALL       
    SELECT 'Thursday', '20' UNION ALL       
    SELECT 'Thursday', '21' UNION ALL       
    SELECT 'Thursday', '22' UNION ALL       
    SELECT 'Thursday', '23' UNION ALL 
    SELECT 'Friday', '00' UNION ALL    
    SELECT 'Friday', '01' UNION ALL    
    SELECT 'Friday', '02' UNION ALL    
    SELECT 'Friday', '03' UNION ALL    
    SELECT 'Friday', '04' UNION ALL    
    SELECT 'Friday', '05' UNION ALL    
    SELECT 'Friday', '06' UNION ALL    
    SELECT 'Friday', '07' UNION ALL    
    SELECT 'Friday', '08' UNION ALL 
    SELECT 'Friday', '09' UNION ALL       
    SELECT 'Friday', '10' UNION ALL       
    SELECT 'Friday', '11' UNION ALL       
    SELECT 'Friday', '12' UNION ALL       
    SELECT 'Friday', '13' UNION ALL       
    SELECT 'Friday', '14' UNION ALL       
    SELECT 'Friday', '15' UNION ALL       
    SELECT 'Friday', '16' UNION ALL       
    SELECT 'Friday', '17' UNION ALL       
    SELECT 'Friday', '18' UNION ALL       
    SELECT 'Friday', '19' UNION ALL       
    SELECT 'Friday', '20' UNION ALL       
    SELECT 'Friday', '21' UNION ALL       
    SELECT 'Friday', '22' UNION ALL       
    SELECT 'Friday', '23' UNION ALL 
    SELECT 'Saturday', '00' UNION ALL    
    SELECT 'Saturday', '01' UNION ALL    
    SELECT 'Saturday', '02' UNION ALL    
    SELECT 'Saturday', '03' UNION ALL    
    SELECT 'Saturday', '04' UNION ALL    
    SELECT 'Saturday', '05' UNION ALL    
    SELECT 'Saturday', '06' UNION ALL    
    SELECT 'Saturday', '07' UNION ALL    
    SELECT 'Saturday', '08' UNION ALL 
    SELECT 'Saturday', '09' UNION ALL       
    SELECT 'Saturday', '10' UNION ALL       
    SELECT 'Saturday', '11' UNION ALL       
    SELECT 'Saturday', '12' UNION ALL       
    SELECT 'Saturday', '13' UNION ALL       
    SELECT 'Saturday', '14' UNION ALL       
    SELECT 'Saturday', '15' UNION ALL       
    SELECT 'Saturday', '16' UNION ALL       
    SELECT 'Saturday', '17' UNION ALL       
    SELECT 'Saturday', '18' UNION ALL       
    SELECT 'Saturday', '19' UNION ALL       
    SELECT 'Saturday', '20' UNION ALL       
    SELECT 'Saturday', '21' UNION ALL       
    SELECT 'Saturday', '22' UNION ALL       
    SELECT 'Saturday', '23' UNION ALL
    SELECT 'Sunday', '00' UNION ALL    
    SELECT 'Sunday', '01' UNION ALL    
    SELECT 'Sunday', '02' UNION ALL    
    SELECT 'Sunday', '03' UNION ALL    
    SELECT 'Sunday', '04' UNION ALL    
    SELECT 'Sunday', '05' UNION ALL    
    SELECT 'Sunday', '06' UNION ALL    
    SELECT 'Sunday', '07' UNION ALL    
    SELECT 'Sunday', '08' UNION ALL 
    SELECT 'Sunday', '09' UNION ALL       
    SELECT 'Sunday', '10' UNION ALL       
    SELECT 'Sunday', '11' UNION ALL       
    SELECT 'Sunday', '12' UNION ALL       
    SELECT 'Sunday', '13' UNION ALL       
    SELECT 'Sunday', '14' UNION ALL       
    SELECT 'Sunday', '15' UNION ALL       
    SELECT 'Sunday', '16' UNION ALL       
    SELECT 'Sunday', '17' UNION ALL       
    SELECT 'Sunday', '18' UNION ALL       
    SELECT 'Sunday', '19' UNION ALL       
    SELECT 'Sunday', '20' UNION ALL       
    SELECT 'Sunday', '21' UNION ALL       
    SELECT 'Sunday', '22' UNION ALL       
    SELECT 'Sunday', '23'
) 
SELECT * FROM root 
`;
