/**
 * Created by katarinababic on 29/12/2020.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { NativeFeedSideDrawerComponentItemType } from './NativeFeedSideDrawerComponents';
import Typography from '@mui/material/Typography';
import { ThemeConfig } from '../../../../../Config/Theme';
import { NativeFeedSideDrawerComponentDraggableItemSwitch } from './NativeFeedSideDrawerComponentDraggableItemSwitch';
import { Paper } from '@mui/material';

export type NativeFeedSideDrawerComponentDraggableItemProps = {
  item: NativeFeedSideDrawerComponentItemType;
};

export const NativeFeedSideDrawerComponentDraggableItem: React.FC<NativeFeedSideDrawerComponentDraggableItemProps> =
  observer(({ item }) => {
    const { classes } = useStyles();

    return (
      <Paper className={classes.container}>
        <Grid item>
          <NativeFeedSideDrawerComponentDraggableItemSwitch item={item} />
        </Grid>
        <Grid item>
          <Typography className={classes.text}>{item.type}</Typography>
        </Grid>
      </Paper>
    );
  });

const useStyles = makeStyles()(() => ({
  container: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: 'grey',
    margin: '8px 8px 8px 0px',
  },
  text: {
    color: ThemeConfig.Colors.white,
  },
  icon: {
    color: ThemeConfig.Colors.white,
  },
}));
