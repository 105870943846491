/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { MesoCycleConfigurationTemplate } from '../../../../../Model/Coach/SuperMacro/MesoCycleConfigurationTemplate';
import styled from '@emotion/styled';
import { SuperMacroTagsInput } from '../SuperMacroTagsInput';
import { MicroCycleConfigurationEditor } from './MicroCycleConfigurationEditor';
import { MicroCycleTemplateConfiguration } from '../../../../../Model/Coach/SuperMacro/MicroCycleTemplateConfiguration';
import { SuperMacroMesoCycleTemplate } from '../../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';

export type MesoCycleConfigurationTemplateEditorProps = {
  mesoCycle: SuperMacroMesoCycleTemplate;
  configuration: MesoCycleConfigurationTemplate;
};

const Header = styled.div`
  background-color: #12cdd4;
  padding: 4px;
`;

const Body = styled.div`
  display: flex;
`;

const AddConfigurationButton = styled.button`
  border: 1px dashed lightgray;
  flex: 1;
  &:hover {
    border: 1px dashed black;
  }
`;

export const MesoCycleConfigurationTemplateEditor: React.FC<MesoCycleConfigurationTemplateEditorProps> = observer(
  ({ configuration, mesoCycle }: MesoCycleConfigurationTemplateEditorProps) => {
    const handleAddConfiguration = React.useCallback(() => {
      configuration.configurations.push(new MicroCycleTemplateConfiguration());
      mesoCycle.save();
    }, [configuration, mesoCycle]);

    return (
      <React.Fragment>
        <Header>
          <SuperMacroTagsInput superMacro={configuration} />
        </Header>
        {configuration.configurations.map((microCycleConfig) => (
          <Body key={microCycleConfig.id}>
            <MicroCycleConfigurationEditor mesoCycle={mesoCycle} microCycleConfiguration={microCycleConfig} />
          </Body>
        ))}
        <Body>
          {/*<AddConfigurationButton onClick={handleAddConfiguration}>Add Configuration</AddConfigurationButton>*/}
        </Body>
      </React.Fragment>
    );
  },
);
