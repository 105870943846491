/**
 * Created by neo on 22.11.2023
 */
import { Audited, AuditedJson } from '../../Audited';
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type RouteChallengeTeamJoinRequestJson = AuditedJson & {
  teamId: string;
  challengeId: string;
  gymId: string;
  customerId: string;
  athleteId: string;
  username: string;
};

export class RouteChallengeTeamJoinRequest extends Audited {
  @observable
  teamId: string = '';
  @observable
  challengeId: string = '';
  @observable
  gymId: string = '';
  @observable
  customerId: string = '';
  @observable
  athleteId: string = '';
  @observable
  username: string = '';

  constructor(json?: Partial<RouteChallengeTeamJoinRequestJson>) {
    super(json);
    if (json) {
      this.teamId = json.teamId ?? '';
      this.challengeId = json.challengeId ?? '';
      this.gymId = json.gymId ?? '';
      this.customerId = json.customerId ?? '';
      this.athleteId = json.athleteId ?? '';
      this.username = json.username ?? '';
    }
  }

  accept() {
    return HttpBackend.post(`/engagement/route-challenges/teams/${this.teamId}/join-requests/${this.id}/accept`);
  }

  decline() {
    return HttpBackend.delete(`/engagement/route-challenges/teams/${this.teamId}/join-requests/${this.id}`);
  }
}
