/**
 * Created by neo on 19.12.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import styled from '@emotion/styled';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeCard } from './RecipeCard';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Child = styled.div`
  flex: 1 0 31%;
  margin: 5px;
`;

export type RecipeTableProps = {
  recipes: Recipe[];
};

export const RecipeTable: React.FC<RecipeTableProps> = observer(({ recipes }) => {
  return (
    <Container>
      {recipes.map((recipe) => (
        <Child key={recipe.id}>
          <RecipeCard recipe={recipe} />
        </Child>
      ))}
    </Container>
  );
});
