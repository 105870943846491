/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { MediaOverlay } from '../../../../../../Model/Media/MediaOverlay';
import { Media } from '../../../../../../Model/Media/Media';
import { Button, Card, Form, Input, Space } from 'antd';
import { Col, Row } from 'reactstrap';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import { TranslationInputArray } from '../../../../../../Components/Translation/TranslationInputArray';

export type MediaOverlayEntryProps = {
  media: Media;
  overlay: MediaOverlay;
  index: number;
  maxSeconds?: number;
  store: {
    currentSeek;
  };
};

export const MediaOverlayEntry: React.FC<MediaOverlayEntryProps> = observer(
  ({ media, overlay, index, maxSeconds, store }: MediaOverlayEntryProps) => {
    const handleKeyDownStart = React.useCallback(
      (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowRight') {
          overlay.setStartSeconds(overlay.startSeconds + 0.05);
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowLeft') {
          overlay.setStartSeconds(Math.max(0, overlay.startSeconds - 0.05));
        }
      },
      [overlay],
    );

    const handleKeyDownEnd = React.useCallback(
      (event) => {
        if (overlay.endSeconds) {
          if (event.key === 'ArrowUp' || event.key === 'ArrowRight') {
            overlay.setEndSeconds(overlay.endSeconds + 0.05);
          } else if (event.key === 'ArrowDown' || event.key === 'ArrowLeft') {
            overlay.setEndSeconds(Math.max(0, overlay.endSeconds - 0.05));
          }
        } else {
          overlay.setEndSeconds(overlay.startSeconds);
        }
      },
      [overlay],
    );

    const handleSetCurrentSeek = React.useCallback(() => {
      overlay.setStartSeconds(store.currentSeek);
    }, [store, overlay]);

    const handleResetEndTime = React.useCallback(() => {
      overlay.setEndSeconds(undefined);
    }, [overlay]);

    const handleRemove = React.useCallback(() => {
      media.overlay.splice(index, 1);
    }, [media, index]);

    return (
      <Card
        extra={
          <Space>
            <Button type="link" danger onClick={handleRemove}>
              Remove
            </Button>
          </Space>
        }
      >
        <Row>
          <Col>
            <Form.Item label="Start Time">
              <Input
                value={overlay.startTime}
                onKeyDown={handleKeyDownStart}
                addonAfter={
                  <Button type="link" onClick={handleSetCurrentSeek}>
                    Set
                  </Button>
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="End Time">
              <Input
                value={overlay.endTime}
                onKeyDown={handleKeyDownEnd}
                addonAfter={
                  <Button type="link" onClick={handleResetEndTime}>
                    X
                  </Button>
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <SingleColRow>
          <TranslationInputArray entity={overlay} field="text" />
        </SingleColRow>
      </Card>
    );
  },
);
