/**
 * Created by neo on 17.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, BarChart } from 'recharts';

export type SignUpHourDistributionChartProps = {
  data: AnalyticsRow[];
  xAxisDataKey?: string;
};

export const SignUpHourDistributionChart: React.FC<SignUpHourDistributionChartProps> = observer(
  ({ data, xAxisDataKey = 'time' }) => {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar type="monotone" dataKey="sign_up_total" fill="#913CA4" />
          <Bar type="monotone" dataKey="onboarding_completed_total" fill="#09D6B6" />
        </BarChart>
      </ResponsiveContainer>
    );
  },
);
