/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Col, Row, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { NotificationToken } from '../../../../Model/Notification/NotificationToken';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

export type NotificationTabProps = {
  athlete: Athlete;
};

export const NotificationTab: React.FC<NotificationTabProps> = observer(({ athlete }) => {
  const [tokens, setTokens] = useState<NotificationToken[]>([]);

  useEffect(() => {
    NotificationToken.list(athlete.id).then((res) => setTokens(res));
  }, [athlete]);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Table size="sm" striped>
              <thead>
              <tr>
                <th>Token</th>
                <th>Type</th>
                <th>System</th>
                <th>Updated</th>
              </tr>
              </thead>
              <tbody>
                {tokens.map((token) => (
                  <tr key={token.id}>
                    <td style={{ display: 'inline-block', wordBreak: 'break-all' }}>{token.token}</td>
                    <td>{token.type}</td>
                    <td>{token.system}</td>
                    <td>{dayjs(token.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
