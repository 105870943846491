/**
 * Created by neo on 13.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Form, Tabs } from 'antd';
import { UserStatisticsTab } from './UserStatisticsTab/UserStatisticsTab';
import { UsersOverviewTab } from './UsersOverviewTab/UsersOverviewTab';
import { Col, Container, Row } from 'reactstrap';
import { Gym } from '../../Model/Gym/Gym';
import AsyncSelect from 'react-select/async';
import { UserActivityTab } from './UserActivityTab/UserActivityTab';
import { OnboardingAnalyticsTab } from './OnboardingAnalyticsTab/OnboardingAnalyticsTab';
import { ActivityConversionTab } from './ActivityConversionTab/ActivityConversionTab';
import { ExploreAnalyticsTab } from './ExploreAnalytics/ExploreAnalyticsTab';
import { ExploreEntryAnalyticsTab } from './ExploreEntryAnalyticsTab/ExploreEntryAnalyticsTab';
import { KpiTab } from './KpiTab/KpiTab';
import { ActivitiesLoggedTab } from './ActivitiesLogged/ActivitiesLoggedTab';

export type AnalyticsIndexScreenProps = {};

export const AnalyticsIndexScreen: React.FC<AnalyticsIndexScreenProps> = observer((props) => {
  const [activeTab, setActiveTab] = useState('kpis');

  const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

  const [selectedGym, setSelectedGym] = useState<Gym | undefined>();

  const fetchGym = React.useCallback(
    (query: string) =>
      Gym.find({
        query,
      }),
    [],
  );

  const handleChange = React.useCallback((gym?: Gym | null) => {
    setSelectedGym(gym ?? undefined);
  }, []);

  const getOptionLabel = React.useCallback((option: Gym) => option.name, []);

  const getOptionValue = React.useCallback((option: Gym) => option, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xs={12}>
            <Form layout="vertical">
              <Form.Item label="Select a gym">
                <AsyncSelect
                  value={selectedGym}
                  cacheOptions
                  defaultOptions
                  isClearable={true}
                  loadOptions={fetchGym}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue as any}
                  onChange={handleChange}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Container>
      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        <Tabs.TabPane tab="KPIs" key="kpis">
          <KpiTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Activities" key="userActivities">
          <UserActivityTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Activity Conversion" key="activityConversion">
          <ActivityConversionTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Explore Analytics" key="exploreAnalytics">
          <ExploreAnalyticsTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Explore Entry Analytics" key="exploreEntryAnalytics">
          <ExploreEntryAnalyticsTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Stats Table" key="userStatsTable">
          <UserStatisticsTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Overview" key="usersOverview">
          <UsersOverviewTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Onboarding Statistics" key="onboarding">
          <OnboardingAnalyticsTab gym={selectedGym} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Logged Activities" key="loggedActivities">
          <ActivitiesLoggedTab gym={selectedGym} />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  );
});
