/**
 * Created by katarinababic on 20.12.21..
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../../Model/Analytics/AnalyticsData';
import { Form } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useState } from 'react';
import { ChartColors } from '../../ChartColors';

export type ExploreEntryAnalyticsChartProps = {
  data: AnalyticsRow[];
  categories: Array<string>;
};

export const ExploreEntryAnalyticsChart: React.FC<ExploreEntryAnalyticsChartProps> = observer(
  ({ data, categories }) => {
    const [disabledFields, setDisabledFields] = useState<{ [key: string]: boolean }>({});

    const handleLegendClick = React.useCallback((data) => {
      const dataKey = typeof data.dataKey === 'string' ? data.dataKey : 'avg_activities_per_user';
      setDisabledFields((existing) => Object.assign({}, existing, { [dataKey]: !existing[dataKey] }));
    }, []);

    return (
      <Form.Item label="Select Content">
        <ResponsiveContainer width="100%" height={650}>
          <BarChart height={300} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="event_date" />
            <YAxis />
            <Tooltip />
            <Legend onClick={handleLegendClick} />
            {categories.map((category, index) => (
              <Bar
                key={category}
                type="monotone"
                dataKey={category}
                fill={ChartColors[index % ChartColors.length]}
                stackId="a"
                name={`# Unique users ${category}`}
                hide={disabledFields[category]}
              />
            ))}
            {categories.map((category, index) => (
              <Bar
                key={`${category}_select`}
                type="monotone"
                dataKey={`${category}_select`}
                fill={ChartColors[index % ChartColors.length]}
                stackId="b"
                name={`# selected ${category} `}
                hide={disabledFields[`${category}_select`]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Form.Item>
    );
  },
);
