/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { Form, Select } from 'antd';
import { ExerciseExecutionType } from '../../../../../Model/Exercise/AbstractExercise';

export type ExerciseExecutionTypeSelectProps = {
  exercise: ExerciseVariationAdmin;
  disabled?: boolean;
};

export const ExerciseExecutionTypeSelect: React.FC<ExerciseExecutionTypeSelectProps> = observer(
  ({ exercise, disabled }: ExerciseExecutionTypeSelectProps) => {
    const handleChange = React.useCallback(
      (e) => {
        exercise.executionType = e ? (e as ExerciseExecutionType) : undefined;
      },
      [exercise],
    );

    return (
      <Form.Item label="Execution Type">
        <Select value={exercise.executionType} onChange={handleChange} disabled={disabled}>
          <Select.Option value="">{''}</Select.Option>
          <Select.Option value="ONE_SIDED">{'One Sided'}</Select.Option>
          <Select.Option value="BOTH_SIDED">{'Both Sided'}</Select.Option>
          <Select.Option value="ALTERNATING">{'Alternating'}</Select.Option>
        </Select>
      </Form.Item>
    );
  },
);
