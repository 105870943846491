/**
 * Created by katarinababic on 4.5.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../../../Model/Analytics/AnalyticsData';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartColors } from '../../../../Analytics/ChartColors';
import { Form } from 'antd';

export type ExploreEntryModalAnalyticsPlayedVideosChartProps = {
  data: AnalyticsRow[];
};

export const ExploreEntryModalAnalyticsPlayedVideosChart: React.FC<ExploreEntryModalAnalyticsPlayedVideosChartProps> =
  observer(({ data }) => {
    return data.length > 0 ? (
      <Form.Item label="Number of plays">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart height={300} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="video_id" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar type="monotone" dataKey="no_of_plays" fill={ChartColors[5]} />
            <Bar type="monotone" dataKey="user_count" fill={ChartColors[6]} />
            <Bar type="monotone" dataKey="avg_percentage_completed" fill={ChartColors[9]} />
          </BarChart>
        </ResponsiveContainer>
      </Form.Item>
    ) : (
      <p>No results for the selected time period</p>
    );
  });
