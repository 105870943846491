/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observable, toJS, runInAction, reaction } from 'mobx';
import { useLocalStore, observer } from 'mobx-react';
import { Row, Col, FormGroup, Label, Collapse, Input } from 'reactstrap';
import { PageResult } from '../../../../Model/PageResult';
import { Pager } from '../../../../Components/Pager';
import { Gym } from '../../../../Model/Gym/Gym';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { SortingDirection } from '../../../../Components/Table/SortableHeadColumn';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { ExerciseVariationFilter } from '../../../../Components/ExerciseVariationFilter/ExerciseVariationFilter';
import { ExerciseFilter } from '../../../../Model/Exercise/ExerciseFilter';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { ExerciseListScreenSearchInput } from './ExerciseListScreenSearchInput';
import { useRootStore } from '../../../../Store/useRootStore';
import { Button, Checkbox, Modal, Space, Form } from 'antd';
import { MultiEditModal } from './MultiEditModal';
import { ExerciseVariationAdminTable } from './ExerciseVariationAdminTable';
import { EntityMedia } from '../../../../Model/Media/EntityMedia';

export type ExerciseListScreenContentProps = {
  sourceType?: string;
  sourceId?: string;
  gym?: Gym;
};

export const ExerciseListScreenContent: React.FC<ExerciseListScreenContentProps> = observer((props) => {
  const { authentication } = useRootStore();
  const { sourceType, sourceId, gym } = props;
  const location = useLocation();
  const history = useNavigate();
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [multiEditVisible, setMultiEditVisible] = useState(false);

  const debounce = React.useRef<any>(undefined);
  const store = useLocalStore(
    (source) => ({
      isOpen: false,
      deleting: false,
      loading: false,
      filterVisible: false,
      importing: false,
      filtering: false,
      filteringDone: false,
      deletingFiltered: false,
      filter: new ExerciseFilter(),
      result: new PageResult<ExerciseVariationAdmin>(),
      count: 0,
      input: '',
      variables: observable.map<string, string | undefined>(),
      initVariables(params: URLSearchParams) {
        store.variables = observable.map({
          sort: 'nameMap.de,ASC',
          page: '0',
          size: '10',
          sourceType: source.sourceType ?? (source.sourceId ? 'gym' : ''),
          sourceId: source.sourceId,
        });
        params.forEach((value, key) => value !== undefined && store.variables.set(key, value));
        store.input = store.variables.get('query') ?? '';
      },
      removeVariable(key: string) {
        store.variables.set(key, '');
        store.buildUrl();
      },
      updateVariable(key: string, value: any) {
        store.updateVariables([[key, value]]);
      },
      updateVariables(values: Array<[string, any]>) {
        values.forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            store.variables.set(key, value);
          } else {
            store.variables.set(key, '');
          }
        });
        store.buildUrl();
      },
      buildUrl() {
        const params = new URLSearchParams();
        store.variables.forEach((value, key) => {
          if (value) {
            params.set(key, value);
          }
        });
        history(
          {
            pathname: location.pathname,
            search: params.toString(),
          },
          { replace: true },
        );
      },
      loadExercises() {
        store.loading = true;
        const page = Number(store.variables.get('page') ?? '0');
        const size = Number(store.variables.get('size') ?? '10');
        const request = Object.assign(toJS(store.variables), this.filter.toJS());

        PageResult.execute(ExerciseVariationAdmin.find(request), ExerciseVariationAdmin.count(request), page, size)
          .then((result) => {
            runInAction(() => (store.result = result));
          })
          .finally(() => runInAction(() => (store.loading = false)));
      },
      loadFresh() {
        store.clear();
        store.loadExercises();
      },
      clear() {
        store.variables = observable.map({
          sourceType: source.sourceType ?? (source.sourceId ? 'gym' : undefined),
          sourceId: source.sourceId,
          size: '10',
        });
        store.filter = new ExerciseFilter();
      },
      toggleFilter() {
        store.filterVisible = !store.filterVisible;
      },
      import() {
        store.importing = true;
        HttpBackend.post(`/exercise/v3/admin/import?gymId=${source.sourceId}`)
          .then(() => store.loadFresh())
          .finally(() => runInAction(() => (store.importing = false)));
      },
      removeExericse(exerciseId: string) {
        store.result.content = store.result.content.filter((e) => e.id !== exerciseId);
      },
      async filterWithoutImage() {
        store.filtering = true;
        store.result = new PageResult();
        const size = 50;
        let page = 0;
        let result: Array<ExerciseVariationAdmin> | undefined;
        while (!result || result.length >= size) {
          result = await ExerciseVariationAdmin.find({
            page,
            size,
            sourceType: source.sourceType ?? (source.sourceId ? 'gym' : ''),
            sourceId: source.sourceId,
            archived: true,
            sort: 'nameMap.de,ASC',
          });
          await Promise.all(
            result
              .filter((ex) => ex.medias.length === 0)
              .map((ex) => EntityMedia.get(`exercise:${ex.id}`).then((media) => [ex, media])),
          )
            .then((results) => results.filter(([ex, media]) => !media || media.medias.length === 0).map(([ex]) => ex))
            .then((results) =>
              runInAction(() => results.forEach((res) => store.result.content.push(res as ExerciseVariationAdmin))),
            );
          page += 1;
        }
        store.filtering = false;
        store.filteringDone = true;
      },
      async filterLostImage() {
        store.filtering = true;
        store.result = new PageResult();
        const size = 50;
        let page = 0;
        let result: Array<ExerciseVariationAdmin> | undefined;
        while (!result || result.length >= size) {
          result = await ExerciseVariationAdmin.find({
            page,
            size,
            sourceType: source.sourceType ?? (source.sourceId ? 'gym' : undefined),
            sourceId: source.sourceId,
            archived: true,
            sort: 'nameMap.de,ASC',
          });
          await Promise.all(
            result
              .filter((ex) => ex.medias.length === 0)
              .map((ex) => EntityMedia.get(`exercise:${ex.id}`).then((media) => [ex, media])),
          )
            .then((results) => results.filter(([ex, media]) => media && media.medias.length > 0))
            .then((tuples) =>
              runInAction(() =>
                tuples
                  .map(([res, media]) => {
                    if (res) {
                      console.log('media', toJS(res), toJS(media?.medias));
                      res.medias = media?.medias ?? [];
                    }
                    return res;
                  })
                  .forEach((res) => store.result.content.push(res as ExerciseVariationAdmin)),
              ),
            );
          page += 1;
        }
        store.filtering = false;
        store.filteringDone = true;
      },
      async deleteFiltered() {
        store.deletingFiltered = true;
        await Promise.all(
          store.result.map((entry) =>
            entry.archived
              ? entry
                  .remove(true)
                  .then(() => store.removeExericse(entry.id))
                  .then(() => true)
              : entry
                  .remove()
                  .then(() => runInAction(() => (entry.archived = true)))
                  .then(() => true),
          ),
        ).finally(() => runInAction(() => (store.deletingFiltered = false)));
      },
      async fixLostImages() {
        store.deletingFiltered = true;
        await Promise.all(
          store.result.map((entry) =>
            EntityMedia.assign(
              source.sourceId ? `exercise_gym_${source.sourceId}` : `exercise`,
              entry.id,
              entry.medias,
            ),
          ),
        ).finally(() => runInAction(() => (store.deletingFiltered = false)));
      },
    }),
    props,
  );

  // useEffect(
  //   () =>
  //     reaction(
  //       () => store.filter.json,
  //       () => {
  //         store.variables.set('page', '0');
  //         store.buildUrl();
  //         // store.loadExercises();
  //       },
  //       { delay: 500 },
  //     ),
  //   [store],
  // );

  useEffect(() => {
    store.initVariables(params);
    store.loadExercises();
  }, [store, sourceId]);

  const handleFilterWithoutImage = React.useCallback(() => {
    store.filterWithoutImage();
  }, [store]);

  const handleFilterLostImage = React.useCallback(() => {
    store.filterLostImage();
  }, [store]);

  const handleDeleteFiltered = React.useCallback(() => {
    store.deleteFiltered();
  }, [store]);

  const handleFixLostImages = React.useCallback(() => {
    store.fixLostImages();
  }, [store]);

  const toggleArchived = React.useCallback(
    ({ target: { checked } }: any) => {
      store.updateVariable('archived', checked ? 'true' : 'false');
      store.loadExercises();
    },
    [store],
  );

  const toggleNoAlternatives = React.useCallback(
    ({ target: { checked } }: any) => {
      store.updateVariable('noAlternatives', checked ? 'true' : 'false');
      store.loadExercises();
    },
    [store],
  );

  const toggleNoHarderAlternatives = React.useCallback(
    ({ target: { checked } }: any) => {
      store.updateVariable('noHarderAlternatives', checked ? 'true' : 'false');
      store.loadExercises();
    },
    [store],
  );

  const handleCreate = React.useCallback(() => {
    history(`$new?sourceType=${sourceType ?? ''}&sourceId=${sourceId ?? ''}`);
  }, [sourceType, sourceId, history]);

  const handleRemove = React.useCallback(
    (exercise: ExerciseVariationAdmin, hard: boolean) => {
      Modal.confirm({
        title: hard ? `DELETE ${exercise.nameGermanOrNext} FOREVER???` : `Archive ${exercise.nameGermanOrNext}`,
        content: hard
          ? `Deleting ${exercise.nameGermanOrNext} forever. We CANNOT RECOVER it later.`
          : `Archiving ${exercise.nameGermanOrNext} won't delete it. We can restore it later.`,
        onOk() {
          exercise
            .remove(hard)
            .then(() => (hard ? store.removeExericse(exercise.id) : runInAction(() => (exercise.archived = true))));
        },
      });
    },
    [store],
  );

  const handleQueryChange = React.useCallback(
    ({ target: { value } }: any) => {
      if (store.input !== value) {
        store.input = value;
        debounce.current && clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
          if (store.variables.get('query') !== value.trim()) {
            store.updateVariables([
              ['query', value.trim()],
              ['page', 0],
            ]);
            store.loadExercises();
          }
        }, 300);
      }
    },
    [store],
  );

  const handlePage = React.useCallback(
    (page?: number) => {
      const newPage = `${page}` ?? `${Number(store.variables.get('page') ?? '0') + 1}`;
      store.variables.set('page', newPage);
      store.loadExercises();
    },
    [store],
  );

  const handleSort = React.useCallback(
    (col?: string, dir?: SortingDirection) => {
      if (col) {
        store.updateVariables([
          ['sort', `${col},${dir}`],
          ['page', 0],
        ]);
      } else {
        store.removeVariable('sort');
      }
    },
    [store],
  );

  const handleApplyFilter = React.useCallback(
    (filter: ExerciseFilter) => {
      store.filter = filter;
      store.loadExercises();
    },
    [store],
  );

  const toggleAvailable = React.useCallback(
    ({ target: { checked } }: any) => {
      store.updateVariable('available', checked ? true : undefined);
    },
    [store],
  );

  const handleImport = React.useCallback(async () => {
    if (window.confirm('WARNING! It imports all core exercises! (Does not overwrite gym ONLY exercises)')) {
      store.import();
    }
  }, [store]);

  const handleCopy = React.useCallback(
    (exercise: ExerciseVariationAdmin) => {
      const copy = exercise.copy();
      copy.changeName('de', `${copy.getName('de')} (Copy)`);
      const index = store.result.content.findIndex((r) => r.id === exercise.id);
      if (index !== -1) {
        if (index < store.result.content.length - 1) {
          store.result.content.splice(index + 1, 0, copy);
        } else {
          store.result.content.push(copy);
        }
      } else {
        store.result.content.unshift(copy);
      }
    },
    [store],
  );

  const handleShowMultiEdit = React.useCallback(() => {
    setMultiEditVisible(true);
  }, []);

  const handleCloseMultiEdit = React.useCallback(() => {
    setMultiEditVisible(false);
  }, []);

  const handleClear = React.useCallback(() => {
    store.clear();
    store.buildUrl();
  }, [store]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ExerciseListScreenSearchInput
            store={store}
            onChange={handleQueryChange}
            onClear={handleClear}
            disabled={store.deletingFiltered || store.filtering}
          />
        </Col>
        <Col xs="auto">
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox
              onChange={toggleArchived}
              checked={store.variables.get('archived') === 'true'}
              disabled={store.deletingFiltered || store.filtering}
            >
              Show archived
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox
              onChange={toggleNoAlternatives}
              checked={store.variables.get('noAlternatives') === 'true'}
              disabled={store.deletingFiltered || store.filtering}
            >
              Show no alternatives
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox
              onChange={toggleNoHarderAlternatives}
              checked={store.variables.get('noHarderAlternatives') === 'true'}
              disabled={store.deletingFiltered || store.filtering}
            >
              Show no harder alternatives
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="default" onClick={store.toggleFilter} disabled={store.deletingFiltered || store.filtering}>
              {store.filterVisible ? 'Hide Filter' : 'Show Filter'}
            </Button>
            <Button type="primary" onClick={handleCreate} disabled={store.deletingFiltered || store.filtering}>
              {'Create Exercise'}
            </Button>
            <Button ghost onClick={handleShowMultiEdit} disabled={store.deletingFiltered || store.filtering}>
              {'Multi Edit'}
            </Button>
            {authentication.isGodAdmin && store.result.content.length === 0 && (
              <Button danger onClick={handleImport} disabled={store.importing}>
                {'Import'}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        <Collapse isOpen={store.filterVisible}>
          <ExerciseVariationFilter filter={store.filter} onApply={handleApplyFilter} />
        </Collapse>
      </SingleColRow>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <ExerciseVariationAdminTable
          variations={store.result.content.slice()}
          query={store.variables.get('query') as string}
          onRemove={handleRemove}
          onSort={handleSort}
          onCopy={handleCopy}
        />
      </SingleColRow>
      <SingleColRow>
        <Pager page={store.result} onPage={handlePage} />
      </SingleColRow>
      {multiEditVisible ? (
        <MultiEditModal variables={store.variables} filter={store.filter} onClose={handleCloseMultiEdit} />
      ) : null}
    </React.Fragment>
  );
});
