/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Progress } from 'reactstrap';
import { SingleColRow } from '../../../../Components/SingleColRow';
import Dropzone from 'react-dropzone';
import { ExerciseImportExport } from '../../../../Model/Exercise/ExerciseImportExport';
import saveAs from 'file-saver';
import { Exercise } from '../../../../Model/Exercise/Exercise';

export type Props = {
  onClose?: () => void;
};

@observer
export class ImportExportModal extends React.Component<Props> {
  @observable
  lang: string = 'de';
  @observable
  field: string = 'name';
  @observable
  file?: File = undefined;
  @observable
  processing: boolean = false;
  @observable
  import?: any = undefined;
  @observable
  importing: boolean = false;
  @observable
  totalImports: number = 0;
  @observable
  imported: number = 0;
  cancelImport: boolean = false;
  dropzone: any;
  fileReader?: FileReader;

  @computed
  get importProgress(): number {
    return Math.round((this.imported / this.totalImports) * 100);
  }

  toggle = () => {
    this.props.onClose && this.props.onClose();
  };

  handleExport = async () => {
    // try {
    //   this.processing = true;
    //   const data = await ExerciseVariation.exportTranslations(this.lang, this.field);
    //   this.exportToFile(data);
    // } catch (err) {
    // } finally {
    //   this.processing = false;
    // }
  };

  chunk(array: any[], size: number) {
    const chunkedArr: any[] = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  }

  handleImport = async () => {
    try {
      this.importing = this.processing = true;
      const exercises = Object.keys(this.import.exercise);
      const variations = Object.keys(this.import.variation);
      this.totalImports = exercises.length + variations.length;

      const exerciseChunks = this.chunk(exercises, 20);
      const variationChunks = this.chunk(variations, 20);

      for (const chunk of exerciseChunks) {
        await Promise.all(
          chunk.map((k) => Exercise.importTranslation(k, this.field, this.import.exercise[k], this.lang)),
        );
        runInAction(() => {
          this.imported += chunk.length;
        });
        if (this.cancelImport) {
          return;
        }
      }

      for (const chunk of variationChunks) {
        await Promise.all(
          chunk.map((k) => Exercise.importTranslation(k, this.field, this.import.variation[k], this.lang)),
        );
        runInAction(() => {
          this.imported += chunk.length;
        });
        if (this.cancelImport) {
          return;
        }
      }

      this.file = undefined;
      this.toggle();
    } catch (e) {
      window.alert('Import failed');
    } finally {
      this.cancelImport = false;
      this.importing = this.processing = false;
    }
  };

  exportToFile(data: ExerciseImportExport) {
    const fileName = `exercise_${this.field}_${this.lang}.json`;
    // const fileStream = streamSaver.createWriteStream(fileName);
    // streamSaver.mitm = 'xxx';
    // const writer = fileStream.getWriter();
    // try {
    const json = JSON.stringify(data, null, 4);
    const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
    //   const encoder = new TextEncoder();
    //   const uint8Array = encoder.encode(`${json}\n\n`);
    //   writer.write(uint8Array);
    //   console.log('written', fileName);
    // } catch (e) {
    //   console.log('writer error', fileName, e);
    // } finally {
    //   writer.close();
    // }
  }

  handleCancel = () => {
    if (this.importing) {
      this.cancelImport = true;
    }
    this.dismiss();
  };

  dismiss = () => {
    this.props.onClose && this.props.onClose();
  };

  handleChangeLang = ({ target: { value } }: any) => {
    this.lang = value;
  };

  handleChangeField = ({ target: { value } }: any) => {
    this.field = value;
  };

  handleDrop = async (files: File[]) => {
    if (files.length > 0) {
      this.file = files[0];
      this.fileReader = new FileReader();
      this.fileReader.onloadend = this.handleFileRead;
      this.fileReader.readAsText(files[0]);
    } else {
      this.file = undefined;
    }
  };

  handleFileRead = (e) => {
    if (this.fileReader && this.fileReader.result) {
      this.import = JSON.parse(this.fileReader.result as string);
      console.log(this.import);
    }
  };

  render() {
    return (
      <Modal isOpen backdrop={false} toggle={this.toggle} size="lg">
        <ModalHeader toggle={this.toggle}>Import / Export Exercise Translations</ModalHeader>
        <ModalBody>
          <SingleColRow>
            <FormGroup>
              <Label>Language</Label>
              <Input type="select" value={this.lang} onChange={this.handleChangeLang}>
                <option value="de">German</option>
                <option value="en">English</option>
                <option value="fr">French</option>
                <option value="it">Italian</option>
                <option value="es">Spanish</option>
                <option value="pt">Portugese</option>
              </Input>
            </FormGroup>
          </SingleColRow>
          <SingleColRow>
            <FormGroup>
              <Label>Field</Label>
              <Input type="select" value={this.field} onChange={this.handleChangeField}>
                <option value="name">Name</option>
                <option value="description">Description</option>
              </Input>
            </FormGroup>
          </SingleColRow>
          <SingleColRow>
            <FormGroup>
              <Label>File to Import</Label>
              <Dropzone ref={(d) => (this.dropzone = d)} onDrop={this.handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="dropzone"
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <input {...getInputProps()} />
                    {'Drop translations to import'}
                  </div>
                )}
              </Dropzone>
            </FormGroup>
          </SingleColRow>
        </ModalBody>
        {this.importing ? (
          <ModalBody>
            <div className="text-center">{`${this.imported} / ${this.totalImports}`}</div>
            <Progress value={this.importProgress} />
          </ModalBody>
        ) : null}
        <ModalFooter>
          <Button color="success" onClick={this.handleExport} disabled={this.processing}>
            Export
          </Button>{' '}
          <Button color="primary" onClick={this.handleImport} disabled={!this.file || this.processing}>
            Import
          </Button>{' '}
          <Button color="secondary" onClick={this.handleCancel} disabled={this.processing && !this.importing}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
