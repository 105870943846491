/**
 * Created by Azat Fazlyev on 20.09.23.
 */
import { observable } from 'mobx';
import { HttpBackend } from "../../Services/Http/HttpBackend";
import { LocalizedValue, LocalizedValueJson } from "../LocalizedValue";
import { Audited, AuditedJson } from "../Audited";

export type DailyTipJson = AuditedJson & {
    value: LocalizedValueJson[];
};

export class DailyTip extends Audited {
    @observable
    value: LocalizedValue[] = []

    constructor(json?: Partial<DailyTipJson>) {
        super(json);
        if (json) {
            this.id = json.id ?? '';
            this.value = json.value?.map((el) => new LocalizedValue(el)) ?? []
        }
    }

    save() {
        return HttpBackend.post('/coach/explore/daily-tip/admin', [this.toJS()])
    }

    update(): Promise<DailyTip> {
        return HttpBackend.put(`/coach/explore/daily-tip/admin/${this.id}`, this.toJS())
    }

    delete() {
        return HttpBackend.delete(`/coach/explore/daily-tip/admin/${this.id}`)
    }

    toJS(): DailyTipJson {
        return Object.assign(super.toJS(), {
            value: this.value.map(el => el.toJS())
        });
    }

    static list(params): Promise<DailyTip[]> {
        return HttpBackend.get('/coach/explore/daily-tip/admin', params).then((result) => result.map(el => new DailyTip(el)))
    }

    static count(params): Promise<number> {
        return HttpBackend.get('/coach/explore/daily-tip/admin/count', params)
    }
}
