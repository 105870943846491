/**
 * Created by neo on 25.05.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';
import { SingleColRow } from '../../../Components/SingleColRow';
import { TranslationTable } from './TranslationTable';
import { Button, Checkbox, Form, Input, Modal, Select, Space, Switch } from 'antd';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { TranslationKey } from '../../../Model/Translation/TranslationKey';
import { TranslateMissingTranslationsButton } from './TranslateMissingTranslationsButton';
import { availableLanguages } from '../../../Utils/availableLanguages';
import { ModalAddKeyContent } from './ModalAddKeyContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebouncedEffect } from '../../../Utils/useDebouncedEffect';

export type TranslationsListScreenProps = {};

export const TranslationsListScreen: React.FC<TranslationsListScreenProps> = observer((props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const search = new URLSearchParams(location.search);
  const namespace = search.get('namespace') ?? undefined;
  const searchQuery = search.get('query') ?? '';
  const page = Number(search.get('page') ?? '0');

  const [translations, setTranslations] = useState<PageResult<MergedTranslation>>(new PageResult());
  const [query, setQuery] = useState(searchQuery);
  const [showMissing, setShowMissing] = useState(false);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  // const [namespace, setNamespace] = useState<string | undefined>();
  const [onlyInValue, setOnlyValue] = useState(false);
  const [caseSensitive, setCaseSensitive] = useState(false);
  // const [page, setPage] = useState(0);

  const translationRequest = React.useMemo(
    () => ({
      query,
      namespace,
      onlyInValue,
      caseSensitive,
    }),
    [query, namespace, onlyInValue, caseSensitive],
  );

  useEffect(() => {
    MergedTranslation.listNamespaces().then((res) => setNamespaces(res.sort((a, b) => a.localeCompare(b))));
  }, []);

  useDebouncedEffect(
    () => {
      setTranslations(new PageResult());
      PageResult.execute(
        MergedTranslation.list({ ...translationRequest, page, size: 20 }),
        MergedTranslation.count(translationRequest),
        page,
        20,
      ).then((res) => setTranslations(res));
    },
    [translationRequest, page],
  );

  const handleChangeQuery = React.useCallback(
    ({ target: { value } }) => {
      setQuery(value);
      search.set('page', '0');
      search.set('query', encodeURIComponent(value ?? ''));
      navigate({
        pathname: location.pathname,
        search: `?${search.toString()}`,
      });
    },
    [search, navigate, location],
  );

  const handleAddLanguage = React.useCallback(() => {
    let language = '';
    Modal.confirm({
      type: 'confirm',
      content: (
        <Select style={{ minWidth: 120 }} onSelect={(value) => (language = value)}>
          {availableLanguages.map((lang) => (
            <Select.Option key={lang.value} value={lang.value}>
              {lang.label}
            </Select.Option>
          ))}
        </Select>
      ),
      okText: 'Add Language',
      onOk: () => {
        console.log(language.trim());
        // mergedObject?.addLanguage(language.trim());
      },
    });
  }, []);

  const handleAddKey = React.useCallback(() => {
    let key = '';
    let ns = namespace || 'coach-app';
    Modal.confirm({
      type: 'confirm',
      content: (
        <ModalAddKeyContent
          defaultNamespace={namespace}
          onTranslationKeyChange={(newKey) => (key = newKey)}
          onNamespaceChange={(newNs) => (ns = newNs)}
        />
      ),
      okText: 'Add',
      onOk: () => {
        const cleanKey = key.trim();
        if (cleanKey) {
          TranslationKey.findOne(cleanKey, ns).then((res) => {
            if (!res) {
              Promise.all(
                availableLanguages.map((language) =>
                  new TranslationKey({
                    key: cleanKey,
                    language: language.value,
                    namespace: ns,
                  }).save(),
                ),
              ).then((keys) =>
                setTranslations(
                  (prev) =>
                    new PageResult(
                      Object.assign({}, prev.toJS(), {
                        content: [
                          new MergedTranslation({
                            id: cleanKey,
                            keys: keys.map((k) => k.toJS()),
                          }),
                        ].concat(prev.content),
                      }),
                    ),
                ),
              );
            }
          });
        }
      },
    });
  }, [namespace]);

  const handleShowMissing = React.useCallback((checked: boolean) => {
    setShowMissing(checked);
  }, []);

  const handlePage = React.useCallback(
    (newPage: number) => {
      search.set('page', newPage.toString());
      navigate({
        pathname: location.pathname,
        search: `?${search.toString()}`,
      });
    },
    [search, location, navigate],
  );

  const handleChangeNamespace = React.useCallback(
    (ns?: string) => {
      search.set('namespace', ns ?? '');
      navigate({
        pathname: location.pathname,
        search: `?${search.toString()}`,
      });
    },
    [search, location, navigate],
  );

  const toggleOnlyInValue = React.useCallback(({ target: { checked } }: any) => {
    setOnlyValue((p) => !p);
  }, []);

  const toggleCaseSensitive = React.useCallback(({ target: { checked } }: any) => {
    setCaseSensitive((p) => !p);
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Translations</h1>
        </Col>
        <Col xs="auto">
          <Space>
            <Select value={namespace} style={{ minWidth: 360 }} onChange={handleChangeNamespace} allowClear={true}>
              <Select.Option>{'<all namespaces>'}</Select.Option>
              {namespaces.map((ns) => (
                <Select.Option key={ns} value={ns}>
                  {ns}
                </Select.Option>
              ))}
            </Select>
            {/*<Button type="primary" onClick={handleAddLanguage} disabled={!translations || translating}>*/}
            {/*  Add Language*/}
            {/*</Button>*/}
            <Button type="primary" onClick={handleAddKey} disabled={!translations}>
              Add Key
            </Button>
            <TranslateMissingTranslationsButton request={translationRequest} />
            <Switch
              checkedChildren="Show only missing"
              unCheckedChildren="Show only missing"
              checked={showMissing}
              onChange={handleShowMissing}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input onChange={handleChangeQuery} value={query} allowClear={true} />
        </Col>
        <Col xs="auto">
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox onChange={toggleOnlyInValue} checked={onlyInValue}>
              Search in value only
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Checkbox onChange={toggleCaseSensitive} checked={caseSensitive}>
              Case Sensitive
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={translations} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <TranslationTable mergedTranslations={translations.content} namespaces={namespaces} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={translations} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
