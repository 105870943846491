/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { CardBody, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SetsTable } from './SetsTable/index';
import { ExerciseBlockLog } from '../../../../Model/WorkoutLog/ExerciseBlockLog';
import { ExerciseSet } from '../../../../Model/WorkoutLog/ExerciseSet';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { TableRowMedia } from '../../../../Components/TableRowMedia';

export type Props = {
  exerciseBlock: ExerciseBlockLog;
  setId?: string;
};

@observer
export class WorkoutLogExerciseBlock extends React.Component<Props> {
  @observable
  set?: ExerciseSet = undefined;

  constructor(props: Props) {
    super(props);
    if (props.setId && props.setId.length) {
      const set = props.exerciseBlock.sets.find((s) => s.setId === props.setId);
      set && this.handleClickSet(set);
    }
  }

  handleClickSet = (set: ExerciseSet) => {
    this.set = set;
  };

  render() {
    const {
      exerciseBlock,
      exerciseBlock: { exercise },
    } = this.props;
    return (
      <CardBody style={{ borderBottom: '1px solid grey' }}>
        <Row>
          <Col>
            <Link
              to={`/metadata/exercise/${exercise?.id}?sourceType=${exercise?.sourceType}&sourceId=${exercise?.sourceId}`}
            >
              <h6>{exercise ? exercise.name : ''}</h6>
            </Link>
          </Col>
          <Col xs="auto">
            {exercise && exercise.medias && exercise.imageMedia ? (
              <SingleColRow>
                <TableRowMedia medias={exercise.medias} showVideo={true} width={160} height={90} />
              </SingleColRow>
            ) : null}
          </Col>
        </Row>
        <SetsTable exerciseBlock={exerciseBlock} />
      </CardBody>
    );
  }
}
