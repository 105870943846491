import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, CardBody, Button, ButtonGroup } from 'reactstrap';
import { ExerciseBlock } from '../../../../../Model/ProgramPortfolio/ExerciseBlock';
import { SetsTable } from './SetsTable';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { TableRowMedia } from '../../../../../Components/TableRowMedia';
import { ExerciseBlockExerciseVariationLink } from './ExerciseBlockExerciseVariationLink';

export type ExerciseBlockCardContentProps = {
  exerciseBlock: ExerciseBlock;
  index: number;
  onRemove?: (block: ExerciseBlock) => any;
};

export const ExerciseBlockCardContent: React.FC<ExerciseBlockCardContentProps> = observer(
  ({ exerciseBlock, index, onRemove }: ExerciseBlockCardContentProps) => {

    const handleAddSet = React.useCallback(() => {
      exerciseBlock.createSet();
    }, [exerciseBlock]);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(exerciseBlock);
    }, [exerciseBlock, onRemove]);

    return (
      <CardBody style={{ borderBottom: '1px solid grey' }}>
        <Row>
          <Col>
            <div>
              <ExerciseBlockExerciseVariationLink exercise={exerciseBlock.exercise} />
              <div>
                {exerciseBlock.exercise.imageMedia ? (
                  <SingleColRow>
                    <TableRowMedia medias={exerciseBlock.exercise.medias} showVideo={true} width={160} height={90} />
                  </SingleColRow>
                ) : null}
              </div>
            </div>
          </Col>
          <Col md="auto">
            <ButtonGroup size="sm" className="float-right">
              <Button color="success" onClick={handleAddSet}>
                Add Set
              </Button>
              {onRemove ? (
                <Button color="danger" onClick={handleRemove}>
                  Remove
                </Button>
              ) : null}
            </ButtonGroup>
          </Col>
        </Row>
        <SetsTable exerciseBlock={exerciseBlock} />
      </CardBody>
    );
  },
);
