/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps, StyledRow } from '../Default/DefaultStageNodeWidget';
import { ConfigAttributeSelect } from '../ConfigAttributeSelect';

export type MapNodeWidgetProps = {} & StageNodeWidgetProps;

export const MapNodeWidget: React.FC<MapNodeWidgetProps> = observer(({ node, engine, size }: MapNodeWidgetProps) => {
  return (
    <DefaultStageNodeWidget
      engine={engine}
      node={node}
      size={size}
      disableEdit={true}
      content={
        <StyledRow>
          <ConfigAttributeSelect node={node} />
        </StyledRow>
      }
    />
  );
});
