/**
 * Created by neo on 17.01.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallengeTeam } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { Col, Row, Table } from 'reactstrap';
import { Button, Form, Popconfirm } from 'antd';
import AsyncSelect from 'react-select/async';
import { Customer } from '../../../../../Model/Customer/Customer';
import { RouteChallengeTeamMember } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeamMember';
import { Link } from 'react-router-dom';
import { RouteChallengeTeamsTableRowMembersTableMemberRow } from './RouteChallengeTeamsTableRowMembersTableMemberRow';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';

export type RouteChallengeTeamsTableRowMembersTableProps = {
  challenge: RouteChallenge;
  team: RouteChallengeTeam;
};

export const RouteChallengeTeamsTableRowMembersTable: React.FC<RouteChallengeTeamsTableRowMembersTableProps> = observer(
  ({ challenge, team }) => {
    const [memberToAdd, setMemberToAdd] = useState<Customer | undefined>();
    const [processing, setProcessing] = useState(false);

    const canAddMember = team.memberCount < team.maxAllowedMembers;

    const fetchCustomer = React.useCallback((query: string) => Customer.find({ query, gymId: team.gymId }), []);

    const optionLabel = React.useCallback((obj) => `${obj.athlete.firstname} (${obj.athlete.user.email})`, []);

    const optionValue = React.useCallback((obj) => obj, []);

    const handleChangeObject = React.useCallback((value?: Customer) => {
      setMemberToAdd(value);
    }, []);

    const handleAddMember = () => {
      if (memberToAdd) {
        setProcessing(true);
        team
          .addMember(memberToAdd.athlete.id)
          .then(() => team.fetchMembers())
          .finally(() => {
            setMemberToAdd(undefined);
            setProcessing(false);
          });
      }
    };

    const removeMember = (member: RouteChallengeTeamMember) => {
      setProcessing(true);
      team
        .removeMember(member.id)
        .then(() => team.fetchMembers())
        .finally(() => setProcessing(false));
    };

    return (
      <tr>
        <td colSpan={7}>
          <Table size="sm">
            <thead>
              <tr>
                <th>Member ID</th>
                <th>Username</th>
                <th>Name</th>
                <th>E-Mail</th>
                <th>Points</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {team.members
                ?.sort((a, b) => b.totalCollectedPoints - a.totalCollectedPoints)
                .map((member) => (
                  <RouteChallengeTeamsTableRowMembersTableMemberRow
                    key={member.id}
                    challenge={challenge}
                    team={team}
                    member={member}
                    onRemove={removeMember}
                    disabled={processing}
                  />
                ))}
              {canAddMember && (
                <tr>
                  <td colSpan={5}>
                    <Form.Item label="Customer / Employee">
                      <Row>
                        <Col xs>
                          <AsyncSelect
                            cacheOptions
                            value={memberToAdd}
                            isClearable={true}
                            loadOptions={fetchCustomer as any}
                            getOptionLabel={optionLabel}
                            getOptionValue={optionValue as any}
                            onChange={handleChangeObject as any}
                            isDisabled={processing}
                          />
                        </Col>
                        <Col xs="auto">
                          <Button type="primary" onClick={handleAddMember} disabled={!memberToAdd || processing}>
                            Add Member
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </td>
      </tr>
    );
  },
);
