/**
 * Created by neo on 14.11.2023
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { RouteChallengeLocationPosition, RouteChallengeLocationPositionJson } from './RouteChallengeLocationPosition';
import { Media, MediaJson } from '../../Media/Media';
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type RouteChallengeLocationJson = LocalizedArrayEntityJson & {
  challengeId: string;
  pointsRequired: number;
  position: RouteChallengeLocationPositionJson;
  image?: MediaJson;
  medias: MediaJson[];
  googleMapsRouteToNextLocation?: string;
  totalPointsRequired: number;
};

export class RouteChallengeLocation extends LocalizedArrayEntity {
  @observable
  challengeId = '';
  @observable
  pointsRequired = 0;
  @observable
  position = new RouteChallengeLocationPosition();
  @observable
  image?: Media;
  @observable
  medias: Media[] = [];
  @observable
  googleMapsRouteToNextLocation?: string;
  @observable
  totalPointsRequired = 0;

  constructor(json?: Partial<RouteChallengeLocationJson>) {
    super(json);
    if (json) {
      this.challengeId = json.challengeId ?? '';
      this.pointsRequired = json.pointsRequired ?? 0;
      this.position = new RouteChallengeLocationPosition(json.position);
      this.image = json.image ? new Media(json.image) : undefined;
      this.medias = json.medias ? json.medias.map((media) => new Media(media)) : [];
      this.googleMapsRouteToNextLocation = json.googleMapsRouteToNextLocation;
      this.totalPointsRequired = json.totalPointsRequired ?? 0;
    }
  }

  toJS(newId: boolean = false): LocalizedArrayEntityJson {
    return Object.assign(super.toJS(newId), {
      challengeId: this.challengeId,
      pointsRequired: this.pointsRequired,
      position: this.position.toJS(),
      image: this.image?.toJS(),
      medias: this.medias.map((media) => media.toJS()),
      googleMapsRouteToNextLocation: this.googleMapsRouteToNextLocation,
      totalPointsRequired: this.totalPointsRequired,
    });
  }

  static findOne(challengeId: string, locationId: string): Promise<RouteChallengeLocation> {
    return HttpBackend.get(`/route-challenges/${challengeId}/locations/${locationId}`).then(
      (res) => new RouteChallengeLocation(res),
    );
  }
}
