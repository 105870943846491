/**
 * Created by neo on 12.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeTeam } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { RouteChallengeTeamMember } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeamMember';
import { Table } from 'reactstrap';
import dayjs from 'dayjs';

export type RouteChallengeTeamsTableRowMembersTableMemberTransactionsTableProps = {
  challenge: RouteChallenge;
  team: RouteChallengeTeam;
  member: RouteChallengeTeamMember;
};

export const RouteChallengeTeamsTableRowMembersTableMemberTransactionsTable: React.FC<RouteChallengeTeamsTableRowMembersTableMemberTransactionsTableProps> =
  observer(({ challenge, team, member }) => {
    const [transactions, setTransactions] = useState<any[]>();

    useEffect(() => {
      member.listTransactions().then((res) => setTransactions(res));
    }, [member]);

    return (
      <Table size="sm" hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Points</th>
            <th>Total Points</th>
            <th>Date / Time</th>
          </tr>
        </thead>
        <tbody>
          {transactions?.map((transaction) => (
            <tr key={transaction.id}>
              <td>{transaction.id}</td>
              <td>{transaction.points}</td>
              <td>{transaction.totalCollectedPoints}</td>
              <td>{dayjs(transaction.transactionDateTime).format('DD.MM.YYYY HH:mm')}</td>
            </tr>
          )) ?? null}
        </tbody>
      </Table>
    );
  });
