/**
 * Created by andreaskarantzas on 06.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';

export type ExploreEntryCoachUrlProps = {
  recipe: Recipe;
};

export const ExploreEntryCoachUrl: React.FC<ExploreEntryCoachUrlProps> = observer(({ recipe }) => {
  const [copiedDynamicLink, setCopiedDynamicLink] = useState<boolean>(false);
  const [copiedLink, setCopiedLink] = useState<boolean>(false);
  const [copiedImageUrl, setCopiedImageUrl] = useState<boolean>(false);

  const link = `https://coach.kinastic.com/explore/recipe/${recipe.id}`;
  const dynamicLink = `https://open.kinastic.com?link=${encodeURIComponent(
    link,
  )}&apn=app.flinq.athlete&isi=1465156373&ibi=app.flinq.athlete`;

  const handleCopyDynamicLink = React.useCallback(() => {
    copy(dynamicLink);
    setCopiedDynamicLink(true);
    setTimeout(() => {
      setCopiedDynamicLink(false);
    }, 3000);
  }, [dynamicLink]);

  const handleCopyLink = React.useCallback(() => {
    copy(link);
    setCopiedLink(true);
    setTimeout(() => {
      setCopiedLink(false);
    }, 3000);
  }, [dynamicLink]);

  const handleCopyImageUrl = React.useCallback(() => {
    copy(`${recipe.image?.smallest}`);
    setCopiedImageUrl(true);
    setTimeout(() => {
      setCopiedImageUrl(false);
    }, 3000);
  }, [recipe]);

  return (
    <>
      <Col xs={12} style={styles.container}>
        <h6>Dynamic Link (NOT notifications / emails etc. only)</h6>
        <Row>
          <Col>
            <p>{dynamicLink || 'No link available'}</p>
          </Col>
          <Col>
            <Button type="primary" shape="round" size="small" disabled={!recipe.id} onClick={handleCopyDynamicLink}>
              {copiedDynamicLink ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={12} style={styles.container}>
        <h6>Link (notifications only / NOT emails etc)</h6>
        <Row>
          <Col>
            <p>{link || 'No link available'}</p>
          </Col>
          <Col>
            <Button type="primary" shape="round" size="small" disabled={!recipe.id} onClick={handleCopyLink}>
              {copiedLink ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={12} style={styles.container}>
        <h6>Notification Image link (Make sure it is below 300KB)</h6>
        <Row>
          <Col>
            {recipe.image && (
              <a href={recipe.image.smallest} rel="noreferrer" target="_blank">
                {recipe.image.smallest}
              </a>
            )}
          </Col>
          <Col>
            <Button
              type="primary"
              shape="round"
              size="small"
              disabled={!recipe.image?.smallest}
              onClick={handleCopyImageUrl}
            >
              {copiedImageUrl ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
});

const styles = {
  container: {
    paddingBottom: 16,
    paddingLeft: 0,
  },
};
