import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { ConcatStage } from '../../../../../../../Model/Coach/Stages/ConcatStage';

export class ConcatModel extends DefaultStageNodeModel {
  constructor(public readonly stage: ConcatStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'concat' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
