/**
 * Created by neo on 28.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { FetchStage } from '../../../../../Model/Coach/Stages/FetchStage';
import { ExerciseVariationFilter } from '../../../../../Components/ExerciseVariationFilter/ExerciseVariationFilter';
import { PipelineContext } from '../../../../../Model/Coach/PipelineContext';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Form, Checkbox, Input, Select } from 'antd';
import { Row, Col } from 'reactstrap';

export type FetchStageEditorProps = {
  stage: FetchStage;
  context?: PipelineContext;
};

export const FetchStageEditor: React.FC<FetchStageEditorProps> = observer((props: FetchStageEditorProps) => {
  const { stage } = props;
  const store = useLocalStore(
    (source: FetchStageEditorProps) => ({
      get tags(): any[] {
        return source.stage.config.contextVariables.map((value) => ({ label: value, value }));
      },
    }),
    props,
  );

  const handleChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        stage.config.contextVariables = (tags ?? []).map((t) => t.value);
      });
    },
    [stage],
  );

  const handleChangeRandom = React.useCallback(
    ({ target: { checked } }) => {
      runInAction(() => (stage.config.random = checked));
    },
    [stage],
  );

  const handleChangeSize = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (stage.config.size = value.startsWith('$') ? value : Number(value)));
    },
    [stage],
  );

  const handleDistinctKeyChange = React.useCallback(
    (value) => {
      runInAction(() => (stage.config.distinctKey = value));
    },
    [stage],
  );

  const handleChangeDistinctValue = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (stage.config.distinctValue = value));
    },
    [stage],
  );

  return (
    <Form layout="vertical">
      <SingleColRow>
        <Form.Item label="Context Variables">
          <CreatableSelect isClearable isMulti onChange={handleChange as any} value={store.tags} />
        </Form.Item>
      </SingleColRow>
      <SingleColRow>
        <ExerciseVariationFilter filter={stage.config} />
      </SingleColRow>
      <Row>
        <Col>
          <Form.Item>
            <Checkbox onChange={handleChangeRandom} checked={stage.config.random}>
              Random
            </Checkbox>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Size">
            <Input onChange={handleChangeSize} value={stage.config.size} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Distinct Key">
            <Select value={stage.config.distinctKey} onChange={handleDistinctKeyChange}>
              <Select.Option value="tagCategory">Tag Category</Select.Option>
              <Select.Option value="type">Type</Select.Option>
              <Select.Option value="equipmentTypes">Equipment Types</Select.Option>
              <Select.Option value="executionType">Execution Type</Select.Option>
              <Select.Option value="bodyParts">Body Parts</Select.Option>
              <Select.Option value="synergists">Synergists</Select.Option>
              <Select.Option value="stabilizers">Stabilizers</Select.Option>
              <Select.Option value="joints">Joints</Select.Option>
              <Select.Option value="force">Force</Select.Option>
              <Select.Option value="mechanics">Mechanics</Select.Option>
              <Select.Option value="utility">Utility</Select.Option>
              <Select.Option value="trackingKeys">Tracking Keys</Select.Option>
              <Select.Option value="initialPosition">Initial Position</Select.Option>
              <Select.Option value="initialPosition.primary">Primary Position</Select.Option>
              <Select.Option value="initialPosition.secondary">Secondary Position</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          {stage.config.distinctKey === 'tagCategory' ? (
            <Form.Item label="Value">
              <Input value={stage.config.distinctValue} onChange={handleChangeDistinctValue} />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
    </Form>
  );
});
