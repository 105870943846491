/**
 * Created by andreaskarantzas on 20.04.21.
 */
import { observable, computed, action } from 'mobx';

export type ExerciseSetPauseJson = {
  startDateTime?: string;
  endDateTime?: string;
};

export class ExerciseSetPause {
  @observable
  startDateTime: Date = new Date();
  @observable
  endDateTime?: Date;

  constructor(source?: ExerciseSetPauseJson) {
    if (source) {
      this.startDateTime = source.startDateTime ? new Date(source.startDateTime) : new Date();
      this.endDateTime = source.endDateTime ? new Date(source.endDateTime) : undefined;
    }
  }

  toJS(): ExerciseSetPauseJson {
    return {
      startDateTime: this.startDateTime?.toISOString(),
      endDateTime: this.endDateTime?.toISOString(),
    };
  }

  @action
  stop() {
    if (!this.endDateTime) {
      this.endDateTime = new Date();
    }
  }

  @computed
  get isPauseRunning(): boolean {
    return !this.endDateTime;
  }

  @computed
  get pauseDuration(): number {
    return (this.endDateTime ?? new Date()).valueOf() - this.startDateTime.valueOf();
  }
}
