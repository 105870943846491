/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { CoachProgram } from '../../../Model/Coach/Program/CoachProgram';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

export type CoachProgramTableRowProps = {
  program: CoachProgram;
  onRemove?: (athleteSuperMacro: CoachProgram) => any;
};

export const CoachProgramTableRow: React.FC<CoachProgramTableRowProps> = observer(({ program, onRemove }) => {
  const handleRemove = React.useCallback(() => {
    onRemove && onRemove(program);
  }, [onRemove, program]);

  return (
    <tr>
      <td>
        <Link to={`/coach/fitness-program/${program.id}`}>{program.template?.templateName || '<no name>'}</Link>
      </td>
      <td>
        <Link to={`/athlete/${program.athleteId}`}>{program.athlete?.fullName}</Link>
      </td>
      <td>
        <div style={{ fontSize: 12 }}>{program.currentParams?.tags.sort((a, b) => a.localeCompare(b)).join(', ')}</div>
      </td>
      <td>{program.startDateTime ? dayjs(program.startDateTime).format('DD.MM.YYYY HH:mm') : '-'}</td>
      <td>{program.endDateTime ? dayjs(program.endDateTime).format('DD.MM.YYYY HH:mm') : '-'}</td>
      <td>{dayjs(program.createdAt).format('DD.MM.YYYY HH:mm')}</td>
      <td>{program.type === 'superMacro' ? <Tag color="warning">V1</Tag> : <Tag color="success">V2</Tag>}</td>
      <td>
        <Space>
          {onRemove && !program.endDateTime ? (
            <Popconfirm title={`Delete Super Macro ${program.template?.templateName}?`} onConfirm={handleRemove}>
              <Button type="link" danger>
                End Program
              </Button>
            </Popconfirm>
          ) : null}
        </Space>
      </td>
    </tr>
  );
});
