/**
 * Created by katarinababic on 26.1.22..
 */

export const allBreathingFinishedAggregated = `
SELECT
  ANY_VALUE(event_name) AS event_name,
  params_id.value.string_value AS entry_id,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS finished_count,
  AVG(params_percentage.value.double_value) AS avg_percentage_completed,
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params_percentage,
  UNNEST (event_params) AS params_id
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'breathingSession_finished'
  AND params_percentage.key = 'percentageCompleted'
  AND params_id.key = 'id'
GROUP BY
  entry_id
ORDER BY
  entry_id
`;

export const gymBreathingFinishedAggregated = `
SELECT
  ANY_VALUE(event_name) AS event_name,
  params_id.value.string_value AS entry_id,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS finished_count,
  AVG(params_percentage.value.double_value) AS avg_percentage_completed,
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS params_percentage,
  UNNEST (event_params) AS params_id,
  UNNEST (user_properties) AS p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'breathingSession_finished'
  AND params_percentage.key = 'percentageCompleted'
  AND params_id.key = 'id'
  AND p.key = 'gymId'
  AND p.value.string_value = @gym_id
GROUP BY
  entry_id
ORDER BY
  entry_id
`;
