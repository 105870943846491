import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { WorkoutNodeModel } from './WorkoutNodeModel';
import { WorkoutNodeWidget } from './WorkoutNodeWidget';
import { WorkoutStage } from '../../../../../../../Model/Coach/Stages/WorkoutStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class WorkoutNodeModelFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('workout');
  }

  generateReactWidget(event): JSX.Element {
    return <WorkoutNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new WorkoutNodeModel(new WorkoutStage(new Pipeline()));
  }
}
