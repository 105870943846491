/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { CoachProgram } from '../../../../Model/Coach/Program/CoachProgram';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { ProgramParams } from '../../../../Model/Coach/Program/ProgramParams';
import { ProgramParamsEditor } from './ProgramParamsEditor';
import { Alert, Button, Form, Select, Space } from 'antd';
import { MicroCycleView } from './MicroCycleView';
import AsyncSelect from 'react-select/async';
import { BodyPartRegionAdmin } from '../../../../Model/BodyPart/BodyPartRegionAdmin';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { EquipmentTypeGroup } from '../../../../Model/Equipment/EquipmentTypeGroup';
import { AthleteSuperMacro } from '../../../../Model/Coach/Program/AthleteSuperMacro';
import { CoachProgramType } from '../../../../Model/Coach/Program/CoachProgramType';
import { ValueEntry } from '../../../../Components/ExerciseVariationFilter/ExerciseVariationFilterSingleSelection';
import { MesoCycle } from '../../../../Model/Coach/Program/MesoCycle';
import { FitnessProgramMesoCycleView } from './CoachWorkoutView/FitnessProgramMesoCycleView';
import { runInAction } from 'mobx';

export type AthleteFitnessProgramViewScreenProps = {};

export const AthleteFitnessProgramViewScreen: React.FC<AthleteFitnessProgramViewScreenProps> = observer((props) => {
  const { programId } = useParams<any>();
  const history = useNavigate();
  const [program, setProgram] = useState<CoachProgram | undefined>(undefined);
  const [mesoCycles, setMesoCycles] = useState<MesoCycle[]>([]);
  const [params, setParams] = useState(new ProgramParams());
  const [athlete, setAthlete] = useState<Athlete | undefined>();
  const [processing, setProcessing] = useState(false);
  const [type, setType] = useState<CoachProgramType>('superMacro');

  useEffect(() => {
    if (programId && programId !== 'create' && programId !== 'new') {
      CoachProgram.get(programId).then((result) => setProgram(result));
    } else {
      setProgram(undefined);
    }
  }, [programId]);

  useEffect(() => {
    setMesoCycles([]);
    if (program) {
      MesoCycle.find({ athleteSuperMacroId: program.id, athleteId: program.athleteId }).then((res) =>
        setMesoCycles(res.sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime())),
      );
    }
  }, [program]);

  const handleChangeParams = React.useCallback(() => {
    if (program) {
      program.changeParams(program.currentParams);
    }
  }, [program]);

  const handleCreate = React.useCallback(() => {
    if (athlete) {
      CoachProgram.createAdmin(type, athlete.id, params).then((result) => {
        setProgram(result);
        history(`/coach/fitness-program/${result.id}`, { replace: true });
      });
    }
  }, [type, athlete, params, history]);

  const handleRecreate = React.useCallback(() => {
    if (program) {
      CoachProgram.createAdmin(type, program.athleteId, program.currentParams).then((result) => {
        setProgram(result);
        history(`/coach/fitness-program/${result.id}`);
      });
    }
  }, [type, program, history]);

  const handleStartNextCycle = React.useCallback(() => {
    setProcessing(true);
    program?.startCycle().finally(() => setProcessing(false));
  }, [program]);

  const handleChangeAthlete = React.useCallback(
    (athlete?: Athlete) => {
      setAthlete(athlete);
    },
    [setAthlete],
  );

  const fetch = React.useCallback(
    (query?: string) =>
      Athlete.find({
        query,
        page: 0,
      }),
    [],
  );

  const getOptionLabel = React.useCallback((option: Athlete) => `${option.fullName} (${option.user.email})`, []);

  const getOptionValue = React.useCallback((option: Athlete) => option, []);

  const handleChangeType = React.useCallback((value: string) => setType(value as CoachProgramType), []);

  if (program) {
    return (
      <Container>
        <Row>
          <Col>
            <h1>{program.template?.templateName ?? 'New Athlete Super Macro'}</h1>
            <h6><Link to={`/coach/program-template/${program.superMacroTemplateId}`}>To Template</Link></h6>
          </Col>
          <Col xs="auto">
            {program.endDateTime ? (
              <Alert type="error" message="Program ended" />
            ) : (
              <Space>
                <Button type="primary" onClick={handleChangeParams} disabled={processing}>
                  Change Params
                </Button>
                <Button type="dashed" onClick={handleStartNextCycle} disabled={processing}>
                  Start Next Cycle
                </Button>
              </Space>
            )}
          </Col>
        </Row>
        {program.athlete && (
          <SingleColRow>
            <ProgramParamsEditor athlete={program.athlete ?? athlete} params={program.currentParams ?? params} />
          </SingleColRow>
        )}
        {mesoCycles
          .slice()
          .reverse()
          .map((mesoCycle, index) => (
            <FitnessProgramMesoCycleView
              key={mesoCycle.id}
              program={program}
              mesoCycle={mesoCycle}
              index={mesoCycles.length - index - 1}
            />
          ))}
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'New Athlete Super Macro'}</h1>
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="primary" onClick={handleCreate} disabled={processing}>
              Create
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col>
          <AsyncSelect
            value={athlete as any}
            cacheOptions
            defaultOptions
            isClearable={true}
            loadOptions={fetch}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue as any}
            onChange={handleChangeAthlete as any}
          />
        </Col>
        <Col xs="auto">
          <Form.Item label="Program Type">
            <Select value={type} onChange={handleChangeType}>
              <Select.Option value="">{''}</Select.Option>
              <Select.Option value="program">New Program</Select.Option>
              <Select.Option value="superMacro">Old Program</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Container>
  );
});
