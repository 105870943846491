import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { InNodeWidget } from './InNodeWidget';
import { InNodeModel } from './InNodeModel';
import { InStage } from '../../../../../../../../Model/Coach/Stages/Logical/InStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class InNodeModelFactory extends AbstractReactFactory<InNodeModel, DiagramEngine> {
  constructor() {
    super('logical:in');
  }

  generateReactWidget(event): JSX.Element {
    return <InNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new InNodeModel(new InStage(new Pipeline()));
  }
}
