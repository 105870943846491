/**
 * Created by neo on 20.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeBeforeWalkthroughEntryCard } from './RouteChallengeBeforeWalkthroughEntryCard';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Col, Row } from 'reactstrap';
import { Button } from 'antd';
import { runInAction } from 'mobx';
import { RouteChallengeWalkthroughEntry } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeWalkthroughEntry';
import { RouteChallengeAfterStartWalkthroughEntryCard } from './RouteChallengeAfterStartWalkthroughEntryCard';

export type RouteChallengeAfterStartWalkthroughEditorTabProps = {
  challenge: RouteChallenge;
};

export const RouteChallengeAfterStartWalkthroughEditorTab: React.FC<RouteChallengeAfterStartWalkthroughEditorTabProps> =
  observer(({ challenge }) => {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h4>Walkthrough Entries</h4>
          </Col>
          <Col xs="auto">
            <Button
              type="primary"
              onClick={() =>
                runInAction(() => challenge.afterEndWalkthroughEntries.push(new RouteChallengeWalkthroughEntry()))
              }
            >
              Add Walkthrough Entry
            </Button>
          </Col>
        </Row>
        <SingleColRow>
          {challenge.afterEndWalkthroughEntries.map((entry, index) => (
            <RouteChallengeAfterStartWalkthroughEntryCard key={entry.id} challenge={challenge} entry={entry} />
          ))}
        </SingleColRow>
      </React.Fragment>
    );
  });
