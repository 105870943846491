import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { SortNodeModel } from './SortNodeModel';
import { SortNodeWidget } from './SortNodeWidget';
import { SortStage } from '../../../../../../../Model/Coach/Stages/SortStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class SortNodeModelFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('sort');
  }

  generateReactWidget(event): JSX.Element {
    return <SortNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new SortNodeModel(new SortStage(new Pipeline()));
  }
}
