import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { DistinctNodeModel } from './DistinctNodeModel';
import { DistinctNodeWidget } from './DistinctNodeWidget';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { DistinctStage } from '../../../../../../../Model/Coach/Stages/DistinctStage';

export class DistinctNodeFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('distinct');
  }

  generateReactWidget(event): JSX.Element {
    return <DistinctNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new DistinctNodeModel(new DistinctStage(new Pipeline()));
  }
}
