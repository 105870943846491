import { Stage } from './Stage';
import { DataSignal } from '../Signal/DataSignal';
import objectPath from 'object-path';

export interface LimitFieldStageConfig {
  limit: number;
  attribute: string;
}

export class LimitFieldStage extends Stage<LimitFieldStageConfig> {
  process() {
    this.dataSignals
      .map((signal) => [objectPath.get(signal.data, this.config.attribute), signal.data])
      .map(([data, container]) => [
        Array.isArray(data) ? data.slice(0, Math.min(this.config.limit, data.length)) : data,
        container,
      ])
      .map(([newData, container], index) => {
        objectPath.set(container, this.config.attribute, newData);
        // console.log(
        //   'limitField::data',
        //   newData,
        //   container,
        //   objectPath.get(container, this.config.attribute),
        //   this.config.attribute,
        //   objectPath.set(container, this.config.attribute, newData),
        // );
        return new DataSignal(container, index);
      })
      .forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'limitField';
  }
}
