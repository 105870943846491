import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { action, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Media, MediaJson } from '../../Media/Media';
import { LocalizedValue } from '../../LocalizedValue';
import { MesoCycleConfigurationTemplate, MesoCycleConfigurationTemplateJson } from './MesoCycleConfigurationTemplate';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type SuperMacroMesoCycleTemplateJson = LocalizedArrayEntityJson & {
  templateName?: string;
  type: string;
  image?: MediaJson;
  includedTags: string[];
  excludedTags: string[];
  tags: string[];
  minCycles: number;
  maxCycles: number;
  refreshWorkoutInterval: number;
  configurations: Partial<MesoCycleConfigurationTemplateJson>[];
};

export class SuperMacroMesoCycleTemplate extends LocalizedArrayEntity {
  @observable
  templateName?: string = undefined;
  @observable
  type: string = 'hypertrophy_1';
  @observable
  image?: Media = undefined;
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  tags: string[] = [];
  @observable
  minCycles: number = 4;
  @observable
  maxCycles: number = 8;
  @observable
  refreshWorkoutInterval: number = 0;
  @observable
  configurations: MesoCycleConfigurationTemplate[] = [];

  constructor(json?: Partial<SuperMacroMesoCycleTemplateJson>) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.setData(json);
    }
  }

  toJS(newId: boolean = false): any {
    return Object.assign(super.toJS(newId), {
      type: this.type,
      templateName: this.templateName,
      includedTags: toJS(this.includedTags),
      excludedTags: toJS(this.excludedTags),
      configurations: this.configurations.map((m) => m.toJS()),
      minCycles: this.minCycles,
      maxCycles: this.maxCycles,
      refreshWorkoutInterval: this.refreshWorkoutInterval,
      tags: toJS(this.tags),
    });
  }

  @action
  setData(json?: any) {
    this.templateName = json.templateName;
    this.type = json.type ?? 'hypertrophy_1';
    this.image = json.image ? new Media(json.image) : undefined;
    this.name = (json.name || []).map((v) => new LocalizedValue(v));
    this.tags = json.tags || [];
    this.includedTags = json.includedTags || [];
    this.excludedTags = json.excludedTags || [];
    this.description = (json.description || []).map((v) => new LocalizedValue(v));
    this.configurations = (json.configurations ?? []).map((m) => new MesoCycleConfigurationTemplate(m));
    this.minCycles = json.minCycles || 4;
    this.maxCycles = json.maxCycles || 8;
    this.refreshWorkoutInterval = json.refreshWorkoutInterval || 0;
    this.createdAt = json.createdAt ? new Date(json.createdAt) : new Date();
    this.updatedAt = json.updatedAt ? new Date(json.updatedAt) : new Date();
  }

  save() {
    return HttpBackend.post(`/coach/meso-cycle`, this.toJS()).then(() => this);
  }

  static async find(params: any = {}): Promise<Array<SuperMacroMesoCycleTemplate>> {
    const res = await HttpBackend.get('/coach/meso-cycle', params);
    if (res) {
      return res.map((w) => new SuperMacroMesoCycleTemplate(w));
    }
    return [];
  }

  static async get(workoutId: string): Promise<SuperMacroMesoCycleTemplate | undefined> {
    const res = await HttpBackend.get(`/coach/meso-cycle/${workoutId}`);
    if (res) {
      return new SuperMacroMesoCycleTemplate(res);
    }
    return undefined;
  }

  static async getAll(workoutIds: Array<string>): Promise<Array<SuperMacroMesoCycleTemplate>> {
    const result = await Promise.all(workoutIds.map((w) => SuperMacroMesoCycleTemplate.get(w)));
    return result.filter((w) => !!w) as Array<SuperMacroMesoCycleTemplate>;
  }
}
