/**
 * Created by neo on 16.09.21.
 */
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { observable } from 'mobx';

export type ProgramTemplateResultJson = {
  name: LocalizedValueJson[];
};

export class ProgramTemplateResult {
  @observable
  name: LocalizedValue[] = [];

  constructor(json?: Partial<ProgramTemplateResultJson>) {
    if (json) {
      this.name = (json.name ?? []).map((l) => new LocalizedValue(l));
    }
  }

  toJS(): ProgramTemplateResultJson {
    return {
      name: this.name.map((n) => n.toJS()),
    };
  }
}
