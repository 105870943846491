import * as React from 'react';
import { Route, Routes } from 'react-router';
import { WorkoutImagesScreen } from './WorkoutImages';
import { EntityMediaListScreen } from './EntityMedia/EntityMediaListScreen';
import { MediaListScreen } from './Media/MediaListScreen';
import { observer } from 'mobx-react';
import { EquipmentTypeViewScreen } from './Equipment/EquipmentType/View';
import { ExerciseListScreen } from './Exercise/ExerciseListScreen/ExerciseListScreen';
import { ExerciseViewScreen } from './Exercise/View/ExerciseViewScreen';
import { EquipmentTypeListScreen } from './Equipment/EquipmentType/EquipmentTypeListScreen';
import { ActivityListScreen } from './Activity/ActivityListScreen';
import { ActivityViewScreen } from './Activity/View/ActivityViewScreen';
import { BodyPartListScreen } from './BodyPart/BodyPartListScreen';
import { BodyPartJointViewScreen } from './BodyPart/Joint/BodyPartJointViewScreen';
import { BodyPartViewScreen } from './BodyPart/View/BodyPartViewScreen';
import { TranslationsListScreen } from './Translations/TranslationsListScreen';
import { DailyTipsListScreen } from "./DailyTips/DailyTipsListScreen";

export type MetaDataRouterProps = {};

export const MetaDataRouter: React.FC<MetaDataRouterProps> = observer(() => {
  return (
    <Routes>
      <Route path="activity/*">
        <Route index element={<ActivityListScreen />} />
        <Route path=":activityId" element={<ActivityViewScreen />} />
      </Route>
      <Route path="exercise">
        <Route index element={<ExerciseListScreen />} />
        <Route path=":exerciseId" element={<ExerciseViewScreen />} />
      </Route>
      <Route path="bodypart/*">
        <Route path="*" element={<BodyPartListScreen />} />
        <Route path="joint/view/:jointId" element={<BodyPartJointViewScreen />} />
        <Route path="view/:bodyPartId" element={<BodyPartViewScreen />} />
      </Route>
      <Route path="workoutImages/*" element={<WorkoutImagesScreen />} />
      <Route path="equipment">
        <Route path="type/*">
          <Route index element={<EquipmentTypeListScreen />} />
          <Route path=":id" element={<EquipmentTypeViewScreen />} />
        </Route>
        {/*<Route path="typeGroup">*/}
        {/*  <Route path=":id" element={<EquipmentTypeGroupViewScreen />} />*/}
        {/*  <Route element={<EquipmentTypeGroupListScreen />} />*/}
        {/*</Route>*/}
      </Route>
      <Route path="entity-media/*" element={<EntityMediaListScreen />} />
      <Route path="media" element={<MediaListScreen />} />
      <Route path="translations" element={<TranslationsListScreen />} />
      <Route path="dailyTips" element={<DailyTipsListScreen />} />
    </Routes>
  );
});
