/**
 * Created by neo on 25.02.19
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { LocalizedValue } from '../../Model/LocalizedValue';
import { Select, Input, Button } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { DeeplService } from '../../Services/DeeplService';
import { runInAction } from 'mobx';
import { useState } from 'react';
import { CustomMarkdownEditor } from '../CustomMarkdownEditor';
import { availableLanguages } from '../../Utils/availableLanguages';

export type TranslationInputArrayEntryProps = {
  language: string;
  values: LocalizedValue[];
  type?: string;
  disabled?: boolean;
  rows?: number;
  maxLength?: number;
  markdown?: boolean;
};

export const TranslationInputArrayEntry: React.FC<TranslationInputArrayEntryProps> = observer(
  ({ type, disabled, rows, maxLength, values, language, markdown }) => {
    const entry = React.useMemo(
      () => values.find((l) => l.lang === language) || new LocalizedValue({ lang: language, value: '' }),
      [language, values],
    );

    const selectBefore = React.useMemo(
      () => (
        <React.Fragment>{availableLanguages.find(l => l.value === language)?.label}</React.Fragment>
      ),
      [language],
    );

    const handleChange = React.useCallback(
      ({ target: { value } }: any) => {
        entry.value = value;
      },
      [entry],
    );

    const handleRemove = React.useCallback(() => {
      const index = values.findIndex((l) => l.lang === language);
      -1 !== index && values.splice(index, 1);
    }, [language, values]);

    const handleTranslate = React.useCallback(() => {
      const source =
        values.find((e) => e.lang === 'de' && e.lang !== language && !!e.value.trim()) ??
        values.find((e) => e.lang === 'en' && e.lang !== language && !!e.value.trim()) ??
        values.find((e) => e.lang !== language && !!e.value.trim());
      if (source) {
        DeeplService.translate({ text: source.value, sourceLanguage: source.lang, targetLanguage: language }).then((result) =>
          runInAction(() => (entry.value = result ?? '')),
        );
      }
    }, [values, language, entry]);

    return (
      <Row style={{ marginBottom: 8 }}>
        <Col>
          {type === 'textarea' ? (
            <React.Fragment>
              <h6>{selectBefore}</h6>
                {markdown ? (
                  <CustomMarkdownEditor
                    value={entry.value}
                    initialValue={entry.value}
                    onChangeValue={(val) => runInAction(() => entry.value = val ?? '')}
                  />
                ) : (
                  <Input.TextArea
                    value={entry.value}
                    disabled={disabled}
                    rows={rows ?? 3}
                    onChange={handleChange}
                    maxLength={maxLength}
                  />
                )}
            </React.Fragment>
          ) : (
            <Input
              addonBefore={selectBefore}
              addonAfter={
                !entry.value.trim() && (
                  <Button onClick={handleTranslate} className="select-before">
                    DeepL
                  </Button>
                )
              }
              value={entry.value}
              disabled={disabled}
              onChange={handleChange}
            />
          )}
        </Col>
        <Col xs="auto">
          <MinusCircleOutlined
            className="dynamic-delete-button"
            style={{ margin: '0 8px' }}
            onClick={handleRemove}
            disabled={disabled}
          />
        </Col>
      </Row>
    );
  },
);
