/**
 * Created by neo on 19.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { ViewBasic } from './ViewBasic';
import { Branch } from '../../../../Model/Gym/Branch';
import * as qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

export type BranchViewScreenProps = {};

export const BranchViewScreen: React.FC<BranchViewScreenProps> = observer((props) => {
  const { gymId, branchId } = useParams();

  const [branch, setBranch] = useState(new Branch({ gymId }));
  const [activeTab, setActiveTab] = useState('basic');

  const { pathname, search } = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (branchId && 'new' !== branchId) {
      Branch.get(branchId).then((res) => setBranch(res ?? new Branch({ gymId })));
    } else {
      setBranch(new Branch({ gymId }));
    }
  }, [gymId, branchId]);

  const toggle = React.useCallback(
    (newTab: string) => {
      if (activeTab !== newTab) {
        const { query } = qs.parse(search);
        const newQuery = Object.assign(query || {}, { activeTab: newTab });
        setActiveTab(newTab);
        history({
          pathname,
          search: qs.stringify(newQuery),
        });
      }
    },
    [activeTab, history, pathname, search],
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1>{branch._isNew ? 'New Branch' : `Branch - ${branch.name}`}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'basic' })} onClick={() => toggle('basic')}>
                {'Basic Info'}
              </NavLink>
            </NavItem>
            {branch._isNew ? null : (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 'control',
                  })}
                  onClick={() => toggle('control')}
                >
                  {'Spot Control'}
                </NavLink>
              </NavItem>
            )}
            {branch._isNew ? null : (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 'statistics',
                  })}
                  onClick={() => toggle('statistics')}
                >
                  {'Statistics'}
                </NavLink>
              </NavItem>
            )}
            {branch._isNew ? null : (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 'workoutlog',
                  })}
                  onClick={() => toggle('workoutlog')}
                >
                  {'Workout Logs'}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="basic">
              <ViewBasic branch={branch} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
});
