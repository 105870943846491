export const allActivityUserDistribution = `
SELECT *, total_users_sum - total_users as delta_users FROM (
 SELECT *,
        SUM(total_users) OVER (PARTITION BY 1 ORDER BY activities_count DESC) AS total_users_sum,
 FROM (
          SELECT t.activities_count,
                 COUNT(user_id) AS total_users
          FROM (
                   SELECT user_id,
                          COUNT(*) AS activities_count
                   FROM \`flinq-6796d.analytics_201736462.events_*\`,
                        UNNEST(event_params) e
                   WHERE _TABLE_SUFFIX BETWEEN @start_date AND @end_date
                     AND ((event_name = 'yogaSession_finished'
                       AND e.key = 'athleteId')
                       OR (event_name = 'breathingSession_finished'
                           AND e.key = 'athleteId')
                       OR (event_name = 'meditationSession_finished'
                           AND e.key = 'athleteId')
                       OR (event_name = 'Workout_finished'
                           AND e.key = 'athleteId')
                       OR (event_name = 'activity_logged'
                           AND e.key = 'athleteId')
                       OR (event_name = 'FeedEntry_read'
                           AND e.key = 'athleteId')
                       OR (e.key = 'content_type'
                           AND e.value.string_value = 'recipe'))
                   GROUP BY user_id) t
          GROUP BY t.activities_count)

 )
ORDER BY
  activities_count
`;

export const queryActivityUserDistribution = `
SELECT *, total_users_sum - total_users as delta_users FROM (
 SELECT *,
        SUM(total_users) OVER (PARTITION BY 1 ORDER BY activities_count DESC) AS total_users_sum,
 FROM (
          SELECT t.activities_count,
                 COUNT(user_id) AS total_users
          FROM (
                   SELECT user_id,
                          COUNT(*) AS activities_count
                   FROM \`flinq-6796d.analytics_201736462.events_*\`,
                        UNNEST(user_properties) p,
                        UNNEST(event_params) e
                   WHERE _TABLE_SUFFIX BETWEEN @start_date
                     AND @end_date
                     AND p.key = 'gymId'
                     AND p.value.string_value = @gym_id
                     AND ((event_name = 'yogaSession_finished'
                       AND e.key = 'athleteId')
                       OR (event_name = 'breathingSession_finished'
                           AND e.key = 'athleteId')
                       OR (event_name = 'meditationSession_finished'
                           AND e.key = 'athleteId')
                       OR (event_name = 'Workout_finished'
                           AND e.key = 'athleteId')
                       OR (event_name = 'activity_logged'
                           AND e.key = 'athleteId')
                       OR (event_name = 'FeedEntry_read'
                           AND e.key = 'athleteId')
                       OR (e.key = 'content_type'
                           AND e.value.string_value = 'recipe'))
                   GROUP BY user_id) t
          GROUP BY t.activities_count)

 )
ORDER BY
  activities_count
`;
