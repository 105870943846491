/**
 * Created by neo on 08.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { CoachTts } from '../../../Model/Coach/TextToSpeech/CoachTts';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CoachTtsGroupedResult } from '../../../Model/Coach/TextToSpeech/CoachTtsGroupedResult';

export type CoachTtsTableRowEntryProps = {
  group: CoachTtsGroupedResult;
  entry: CoachTts;
};

export const CoachTtsTableRowEntry: React.FC<CoachTtsTableRowEntryProps> = observer(({ group, entry }) => {
  const history = useNavigate();
  const playingAudio = React.useRef<any>();

  const handleClick = React.useCallback(() => {
    history(entry.id);
  }, [entry, history]);

  const handlePlay = React.useCallback(() => {
    entry.test('male_01').then((result) => {
      playingAudio.current && playingAudio.current.pause();
      const audio = new Audio(result);
      playingAudio.current = audio;
      audio.play();
    });
  }, [entry]);

  return (
    <tr style={{ backgroundColor: 'white' }}>
      <td>{entry.language}</td>
      <td>{entry.tags.join(', ')}</td>
      <td>{entry.variables.join(', ')}</td>
      <td>{entry.tagConditions.map((t) => t.includedTags.join(', ')).join(' | ')}</td>
      <td style={{ textAlign: 'right' }}>
        <Button type="primary" size="small" onClick={handleClick}>
          Edit
        </Button>
        <Button type="link" size="small" onClick={handlePlay}>
          Play
        </Button>
      </td>
    </tr>
  );
});
