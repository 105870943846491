/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React, { useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { observable, ObservableMap, runInAction } from 'mobx';
import TextField from '@mui/material/TextField';

interface NativeFeedEditorGroupedStylesInputProps {
  styles?: any;
  styleTypes: string[];
  styleTitles: string[];
  endAdornment?: string;
  defaultValue?: number;
  title?: string;
}

export const NativeFeedEditorGroupedStylesInput = observer((props: NativeFeedEditorGroupedStylesInputProps) => {
  const { classes } = useStyles();

  const store = useLocalStore(
    (source: NativeFeedEditorGroupedStylesInputProps) => ({
      get styles(): ObservableMap<string, string | number> {
        return source.styles || observable.map({});
      },
    }),
    props,
  );

  const handleChangeStyle = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      if (event.target.value) {
        store.styles.set(prop, Number(event.target.value ?? 0));
      }
    });
  };

  const handleFocus = useCallback((event) => event.target.select(), []);

  return (
    <Grid container className={classes.container} direction="row">
      {props.styleTypes.map((s: string, index: number) => (
        <Grid item key={`${s}`} xs={3} className={classes.container}>
          <FormControl className={classes.margin} variant="outlined">
            <TextField
              id={`${s}`}
              value={store.styles.get(`${s}`) ?? props.defaultValue ?? 0}
              type="number"
              onChange={handleChangeStyle(`${s}`)}
              aria-describedby={`${s}-helper-text`}
              onFocus={handleFocus}
              InputProps={{
                endAdornment: props.endAdornment ? (
                  <InputAdornment position="end">{props.endAdornment}</InputAdornment>
                ) : undefined,
              }}
              label={props.styleTitles[index]}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
