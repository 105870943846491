/**
 * Created by neo on 23.08.21.
 */
import { Audited, AuditedJson } from '../../../Audited';
import { LocalizedValue, LocalizedValueJson } from '../../../LocalizedValue';
import { Media, MediaJson } from '../../../Media/Media';
import { MesoCycleTemplate, MesoCycleTemplateJson } from './MesoCycleTemplate';
import { computed, observable, toJS } from 'mobx';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { Pageable } from '../../../Pageable';
import { ConditionalMedia, ConditionalMediaJson } from '../../SuperMacro/ConditionalMedia';
import { ProgramTemplateProgramParams, ProgramTemplateProgramParamsJson } from './ProgramTemplateProgramParams';
import { ProgramTemplateResult, ProgramTemplateResultJson } from '../../ProgramTemplateResult';
import { TimesOfDay, TimesOfDayJson } from '../../TimesOfDay';
import { TimeRange } from '../../../Gym/TimeRange';
import { TimeOfDay } from '../../../TimeOfDay';

export type CoachProgramQueryRequest = Pageable & {
  query: string;
  tags: string[];
};

export type CoachProgramTemplateJson = AuditedJson & {
  includedTags: string[];
  excludedTags: string[];
  templateName?: string;
  name: LocalizedValueJson[];
  description: LocalizedValueJson[];
  mesoCycles: MesoCycleTemplateJson[];
  medias: ConditionalMediaJson[];
  image?: MediaJson;
  videos: MediaJson[];
  params?: ProgramTemplateProgramParamsJson;
  minWorkoutDurationInMinutes?: number;
  maxWorkoutDurationInMinutes?: number;
  archived: boolean;
  expectedResults: ProgramTemplateResultJson[];
  gymId?: string;
  defaultTimesOfDay: TimesOfDayJson;
};

export class FitnessProgramTemplate extends Audited {
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  templateName?: string;
  @observable
  name: LocalizedValue[] = [];
  @observable
  description: LocalizedValue[] = [];
  @observable
  mesoCycles: MesoCycleTemplate[] = [];
  @observable
  medias: ConditionalMedia[] = [];
  @observable
  image?: Media;
  @observable
  videos: Media[] = [];
  @observable
  params?: ProgramTemplateProgramParams;
  @observable
  archived: boolean;
  @observable
  expectedResults: ProgramTemplateResult[] = [];
  @observable
  gymId?: string;
  @observable
  defaultTimesOfDay: TimesOfDay = {};

  constructor(json?: Partial<CoachProgramTemplateJson>) {
    super(json);
    if (json) {
      this.includedTags = json.includedTags ?? [];
      this.excludedTags = json.excludedTags ?? [];
      this.templateName = json.templateName;
      this.name = (json.name ?? []).map((l) => new LocalizedValue(l));
      this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
      this.mesoCycles = (json.mesoCycles ?? []).map((m) => new MesoCycleTemplate(m));
      this.medias = (json.medias ?? []).map((m) => new ConditionalMedia(m));
      this.image = json.image ? new Media(json.image) : undefined;
      this.videos = (json.videos ?? []).map((v) => new Media(v));
      this.params = json.params ? new ProgramTemplateProgramParams(json.params) : undefined;
      this.archived = json.archived ?? false;
      this.expectedResults = (json.expectedResults ?? []).map((l) => new ProgramTemplateResult(l));
      this.gymId = json.gymId;
      this.defaultTimesOfDay = Object.entries(json.defaultTimesOfDay ?? ({} as TimesOfDay))
        .map(([key, value]) => [key, new TimeRange(value)])
        .reduce((obj, [key, value]) => Object.assign(obj, { [key as TimeOfDay]: value }), {} as TimesOfDay);
    }
  }

  toJS(newId: boolean = false): CoachProgramTemplateJson {
    return Object.assign(super.toJS(newId), {
      includedTags: this.includedTags,
      excludedTags: this.excludedTags,
      templateName: this.templateName,
      name: this.name.map((n) => n.toJS()),
      description: this.description.map((n) => n.toJS()),
      mesoCycles: this.mesoCycles.map((m) => m.toJS(newId)),
      medias: this.medias.map((m) => m.toJS()),
      image: this.image?.toJS(),
      videos: this.videos.map((v) => v.toJS()),
      params: this.params?.toJS(),
      minWorkoutDurationInMinutes: this.minWorkoutDurationInMinutes,
      maxWorkoutDurationInMinutes: this.maxWorkoutDurationInMinutes,
      archived: this.archived,
      expectedResults: this.expectedResults.map((l) => l.toJS()),
      gymId: this.gymId,
      defaultTimesOfDay: Array.from(Object.entries(this.defaultTimesOfDay))
        .map(([key, value]) => [key, value.toJS()])
        .reduce((agg, [key, entries]) => Object.assign(agg, { [key as string]: entries }), {} as TimesOfDayJson),
    });
  }

  save(): Promise<FitnessProgramTemplate> {
    return HttpBackend.post(`/coach/program/template/admin`, this.toJS()).then(() => this);
  }

  delete(): Promise<FitnessProgramTemplate> {
    return HttpBackend.delete(`/coach/program/template/admin/${this.id}`, this.toJS()).then(() => this);
  }

  copy(): FitnessProgramTemplate {
    return new FitnessProgramTemplate(this.toJS(true));
  }

  @computed
  get minWorkoutDurationInMinutes(): number | undefined {
    return this.mesoCycles.reduce(
      (agg, cycle) =>
        cycle.minWorkoutDurationInMinutes
          ? Math.min(cycle.minWorkoutDurationInMinutes, agg ?? Number.MAX_SAFE_INTEGER)
          : agg,
      undefined as number | undefined,
    );
  }

  @computed
  get maxWorkoutDurationInMinutes(): number | undefined {
    return this.mesoCycles.reduce(
      (agg, cycle) =>
        cycle.maxWorkoutDurationInMinutes
          ? Math.max(cycle.maxWorkoutDurationInMinutes, agg ?? Number.MIN_SAFE_INTEGER)
          : agg,
      undefined as number | undefined,
    );
  }

  static find(request?: Partial<CoachProgramQueryRequest>): Promise<FitnessProgramTemplate[]> {
    return HttpBackend.get(`/coach/program/template/admin`, toJS(request)).then((res) =>
      res.map((r) => new FitnessProgramTemplate(r)),
    );
  }

  static findOne(id: string): Promise<FitnessProgramTemplate> {
    return HttpBackend.get(`/coach/program/template/admin/${id}`).then((res) => new FitnessProgramTemplate(res));
  }

  static count(request?: Partial<CoachProgramQueryRequest>): Promise<number> {
    return HttpBackend.get(`/coach/program/template/admin/count`, toJS(request));
  }
}
