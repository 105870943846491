/**
 * Created by neo on 24.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SuperMacro } from '../../../../Model/Coach/SuperMacro/SuperMacro';
import { Affix, Button, Form, message, Popconfirm, Space } from 'antd';
import { Col, Container, Row } from 'reactstrap';
import { WorkoutSelectionContainer } from '../../SuperMacro/View/WorkoutSelectionContainer/WorkoutSelectionContainer';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { MesoCycleTemplate } from '../../../../Model/Coach/Program/Template/MesoCycleTemplate';
import { CoachProgramMainAttributesEditor } from './CoachProgramMainAttributesEditor';
import { MesoCyclesEditor } from './MesoCyclesEditor';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { FitnessProgramTemplateLinks } from './FitnessProgramTemplateLinks';

export type FitnessProgramTemplateViewScreenProps = {};

export const FitnessProgramTemplateViewScreen: React.FC<FitnessProgramTemplateViewScreenProps> = observer((props) => {
  const history = useNavigate();
  const { templateId } = useParams<any>();
  const [template, setTemplate] = useState<FitnessProgramTemplate>(
    () => new FitnessProgramTemplate({ includedTags: ['gender:male', 'gender:female', 'gender:unknown'] }),
  );

  useEffect(() => {
    if (templateId && templateId !== 'create' && templateId !== 'new') {
      FitnessProgramTemplate.findOne(templateId).then((macro) => setTemplate(macro ?? new SuperMacro()));
    } else {
      setTemplate(new FitnessProgramTemplate({ includedTags: ['gender:male', 'gender:female', 'gender:unknown'] }));
    }
  }, [templateId]);

  const handleSave = React.useCallback(() => {
    template
      .save()
      .then((superMacro) => {
        message.success('Template saved');
        history(`/coach/program-template/${superMacro.id}`, { replace: true });
      })
      .catch(() => message.error('Error saving Super Macro'));
  }, [history, template]);

  const handleDelete = React.useCallback(() => {
    template.delete().then(() => history(-1));
  }, [history, template]);

  const handleAddMesoCycle = React.useCallback(() => {
    runInAction(() => template.mesoCycles.push(new MesoCycleTemplate()));
  }, [template]);

  return (
    <Form layout="vertical">
      <Container style={{ paddingBottom: 0 }}>
        <Row>
          <Col>
            <h1>Program Template</h1>
          </Col>
          <Col md="auto">
            <Space>
              <Popconfirm title={`Delete?`} onConfirm={handleDelete}>
                <Button type="link" danger onClick={handleDelete}>
                  Delete
                </Button>
              </Popconfirm>
              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        <SingleColRow>
          <FitnessProgramTemplateLinks template={template} />
        </SingleColRow>
        <CoachProgramMainAttributesEditor template={template} />
        <Row>
          <Col>
            <h3>Meso Cycles</h3>
          </Col>
          <Col md="auto">
            <Button type="dashed" onClick={handleAddMesoCycle}>
              Add Meso Cycle
            </Button>
          </Col>
        </Row>
      </Container>
      <div style={{ display: 'flex' }}>
        <div style={{ minWidth: 250, width: '20%', maxWidth: 300 }}>
          <Affix offsetTop={36}>
            <WorkoutSelectionContainer />
          </Affix>
        </div>
        <div style={{ flex: 1 }}>
          <MesoCyclesEditor template={template} />
        </div>
      </div>
    </Form>
  );
});
