/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { useLocalStore, observer } from 'mobx-react';
import { Row, Col, Table } from 'reactstrap';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Customer } from '../../../../../Model/Customer/Customer';
import { CustomerTableRow } from './CustomerTableRow';
import { Pager } from '../../../../../Components/Pager';
import { CustomerCreateModal } from './CustomerCreateModal/CustomerCreateModal';
import { PageResult } from '../../../../../Model/PageResult';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { Button, Input, message, Popconfirm, Space } from 'antd';
import { HttpBackend } from '../../../../../Services/Http/HttpBackend';
import saveAs from 'file-saver';
import dayjs, { Dayjs } from 'dayjs';
import { useRootStore } from '../../../../../Store/useRootStore';
import { Route, Routes } from 'react-router';
import { GymViewScreenTabs } from '../GymViewScreenTabs';
import { CustomerEditModal } from './CustomerEditModal';

export type GymCustomersTabProps = {
  gym: Gym;
};

export const GymCustomersTab: React.FC<GymCustomersTabProps> = observer(({ gym }) => {
  const { authentication } = useRootStore();

  const [searchParams, setSearchParams] = useSearchParams();

  const [exporting, setExporting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [result, setResult] = useState(new PageResult<Customer>());
  const [showCustomerCreate, setShowCustomerCreate] = useState(false);

  const debounce = React.useRef<ReturnType<typeof setTimeout>>();

  const searchPage = Number(searchParams.get('page') ?? '0');
  const searchQuery = searchParams.get('query') ?? '';

  const [query, setQuery] = useState(searchQuery);

  const loadCustomers = React.useCallback((gymId: string, newQuery?: string, newPage: number = 0) => {
    PageResult.execute(
      Customer.find({
        gymId,
        query: newQuery,
        page: newPage,
        sort: 'athlete.createdAt,DESC',
        size: 20,
      }),
      Customer.count({
        gymId,
        query: newQuery,
        sort: 'athlete.createdAt,DESC',
      }),
      newPage,
      20,
    ).then((res) => setResult(res));
  }, []);

  useEffect(() => {
    loadCustomers(gym.id, searchQuery, searchPage);
  }, [gym, loadCustomers, searchQuery, searchPage]);

  const handleChangeQuery = React.useCallback(
    ({ target: { value } }: any) => {
      setQuery(value);
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        setSearchParams(
          (p) =>
            new URLSearchParams({
              ...Object.fromEntries(p.entries()),
              query: value,
            }),
        );
      }, 300);
    },
    [setSearchParams],
  );

  const handleRemove = React.useCallback(
    async (customer: Customer) => {
      if (window.confirm(`Do you really want to delete ${customer.athlete.fullName}?`)) {
        customer
          .remove()
          .then(() => {
            message.success('Customer deleted');
            loadCustomers(gym.id, searchQuery, searchPage);
          })
          .catch(() => message.error('Could not delete customer'));
      }
    },
    [loadCustomers, gym, searchQuery, searchPage],
  );

  const handleCreateCustomer = React.useCallback(() => {
    setShowCustomerCreate(true);
  }, []);

  const handleCustomerCreateClose = React.useCallback(() => {
    setShowCustomerCreate(false);
    loadCustomers(gym.id, searchQuery, searchPage);
  }, [gym, loadCustomers, searchPage, searchQuery]);

  const handleExportCsv = React.useCallback(() => {
    setExporting(true);
    HttpBackend.get(`/gym/customer/admin/exportCsv`, { gymId: gym.id })
      .then((res) => {
        const blob = new Blob([res], { type: 'text/plain;charset=iso-8859-1' });
        saveAs(blob, `${gym.name}-customers-${dayjs().format('YYYYMMDDHHmm')}.csv`);
      })
      .finally(() => setExporting(false));
  }, [gym]);

  const handleDeleteAll = React.useCallback(() => {
    setDeleting(true);
    HttpBackend.delete(`/gym/customer/admin/deleteAllAccounts`, { gymId: gym.id })
      .then(() => {
        message.success('All accounts deleted');
      })
      .then(() => {
        setResult(new PageResult<Customer>());
      })
      .finally(() => setDeleting(false));
  }, [gym]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Input type="text" onChange={handleChangeQuery} value={query} />
        </Col>
        <Col xs="auto">
          <Space>
            <Button type="primary" onClick={handleCreateCustomer}>
              Create New Customer
            </Button>
            <Button onClick={handleExportCsv} disabled={exporting}>
              Export Customers CSV
            </Button>
            {authentication.isOneAboveAll && (
              <Popconfirm
                title="ARE YOU SURE??? This will delete the accounts with all data associated"
                onConfirm={handleDeleteAll}
              >
                <Button type="primary" danger disabled={deleting}>
                  Delete All Accounts
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={result} />
      </SingleColRow>
      <SingleColRow>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>{'Name'}</th>
              <th>{'Email'}</th>
              <th>{'Last Seen'}</th>
              <th>{'Activities'}</th>
              <th>{'Rewards?'}</th>
              <th>{'Points'}</th>
              <th>{'Coll. Coins'}</th>
              <th>{'Spent Coins'}</th>
              <th>{'Created'}</th>
              <th>{'Action'}</th>
            </tr>
          </thead>
          <tbody>
            {result.content.slice().map((c: Customer) => (
              <CustomerTableRow key={c.id} customer={c} onRemove={handleRemove} />
            ))}
          </tbody>
        </Table>
        {showCustomerCreate ? <CustomerCreateModal onClose={handleCustomerCreateClose} gym={gym} /> : null}
      </SingleColRow>
      <Routes>
        <Route path=":customerId" element={<CustomerEditModal />} />
      </Routes>
    </React.Fragment>
  );
});
