import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { action, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedValue } from '../../LocalizedValue';
import {
  MicroCycleTemplateConfiguration,
  MicroCycleTemplateConfigurationJson,
} from './MicroCycleTemplateConfiguration';

export type MesoCycleConfigurationTemplateJson = LocalizedArrayEntityJson & {
  workoutsPerCycles: number;
  includedTags: string[];
  excludedTags: string[];
  tags: string[];
  configurations: MicroCycleTemplateConfigurationJson[];
};

export class MesoCycleConfigurationTemplate extends LocalizedArrayEntity {
  @observable
  workoutsPerCycle: number = 0;
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  tags: string[] = [];
  @observable
  configurations: MicroCycleTemplateConfiguration[] = [];

  constructor(json?: Partial<MesoCycleConfigurationTemplateJson>) {
    super(json);
    if (json) {
      this.name = (json.name || []).map((v) => new LocalizedValue(v));
      this.tags = json.tags || [];
      this.includedTags = json.includedTags || [];
      this.excludedTags = json.excludedTags || [];
      this.description = (json.description || []).map((v) => new LocalizedValue(v));
      this.configurations = (json.configurations ?? []).map((m) => new MicroCycleTemplateConfiguration(m));
      this.workoutsPerCycle = json.workoutsPerCycles ?? 0;
      this.createdAt = json.createdAt ? new Date(json.createdAt) : new Date();
      this.updatedAt = json.updatedAt ? new Date(json.updatedAt) : new Date();
    }
  }

  toJS(replaceId: boolean = false): any {
    return Object.assign(super.toJS(replaceId), {
      includedTags: toJS(this.includedTags),
      excludedTags: toJS(this.excludedTags),
      configurations: this.configurations.map((m) => m.toJS()),
      tags: toJS(this.tags),
      workoutsPerCycle: this.workoutsPerCycle,
    });
  }
}
