/**
 * Created by neo on 05.08.22.
 */
import { observable } from 'mobx';
import { EarnModelRuleActivationLogicValueCriteriaMatch } from './EarnModelRuleActivationLogicValueCriteriaMatch';

export type EarnModelRuleActivationLogicValueCriteriaJson = {
  field: string;
  value: string;
  match: EarnModelRuleActivationLogicValueCriteriaMatch;
};

export class EarnModelRuleActivationLogicValueCriteria {
  @observable
  field = '';
  @observable
  value = '';
  @observable
  match: EarnModelRuleActivationLogicValueCriteriaMatch = 'exact';

  constructor(json?: Partial<EarnModelRuleActivationLogicValueCriteriaJson>) {
    if (json) {
      this.field = json.field ?? '';
      this.value = json.value ?? '';
      this.match = json.match ?? 'exact';
    }
  }

  toJS(): EarnModelRuleActivationLogicValueCriteriaJson {
    return {
      field: this.field,
      value: this.value,
      match: this.match,
    };
  }
}
