/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { PipelineStage } from '../../../../../Model/Coach/Stages/PipelineStage';
import { PipelineParam } from '../../../../../Model/Coach/PipelineParam';
import { Button, Form } from 'antd';
import { PipelineStageEditorParamInput } from './PipelineStageEditorParamInput';
import { Col, Row } from 'reactstrap';

export type PipelineStageEditorParamProps = {
  stage: PipelineStage;
  param: PipelineParam;
};

export const PipelineStageEditorParam: React.FC<PipelineStageEditorParamProps> = observer(
  ({ stage, param }: PipelineStageEditorParamProps) => {
    const handleRemove = React.useCallback(() => {
      stage.removeParam(param.name);
    }, [stage, param]);

    return (
      <Row>
        <Col>
          <Form.Item label={param.name}>
            <PipelineStageEditorParamInput param={param} stage={stage} />
          </Form.Item>
        </Col>
        <Col md="auto">
          <Form.Item label={' '}>
            <Button type="dashed" onClick={handleRemove}>
              Reset
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  },
);
