import { action, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { EquipmentConfiguration } from '../Coach/EquipmentConfiguration';
import { UserLocationType } from './UserLocationType';

export class AthleteEquipment {
  @observable
  id: string = UUID();
  @observable
  athleteId?: string = undefined;
  @observable
  name?: string = undefined;
  @observable
  locationType: UserLocationType = 'facility';
  @observable
  equipmentTypes: Array<string> = [];
  @observable
  excludedEquipmentTypes: Array<string> = [];

  constructor(json?: any) {
    if (json) {
      this.setData(json);
    }
  }

  toJS(replaceId: boolean = false) {
    return {
      id: replaceId ? UUID() : this.id,
      athleteId: this.athleteId,
      name: this.name,
      locationType: this.locationType,
      equipmentTypes: toJS(this.equipmentTypes),
      excludedEquipmentTypes: toJS(this.excludedEquipmentTypes),
    };
  }

  @action
  setData(json: any) {
    this.id = json.id || UUID();
    this.athleteId = json.athleteId;
    this.name = json.name;
    this.locationType = json.locationType || 'facility';
    this.equipmentTypes = json.equipmentTypes || [];
    this.excludedEquipmentTypes = json.excludedEquipmentTypes || [];
  }

  save() {
    return HttpBackend.post('/athlete-equipment', this.toJS());
  }

  delete() {
    return HttpBackend.delete(`/athlete-equipment/${this.id}`);
  }

  toConfiguration(): EquipmentConfiguration {
    return new EquipmentConfiguration(this.toJS());
  }

  static async find(params?: any): Promise<Array<AthleteEquipment>> {
    const res = await HttpBackend.get('/athlete-equipment', params);
    return (res || []).map((e) => new AthleteEquipment(e));
  }

  static async list(params?: any): Promise<AthleteEquipment[]> {
    const res = await HttpBackend.get('/athlete-equipment/list', params);
    return (res || []).map((e) => new AthleteEquipment(e));
  }

  static async get(id: string): Promise<AthleteEquipment | undefined> {
    const res = await HttpBackend.get(`/athlete-equipment/${id}`);
    return new AthleteEquipment(res);
  }
}
