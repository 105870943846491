/**
 * Created by neo on 29.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Media } from '../../../Model/Media/Media';
import { Col } from 'reactstrap';
import { MediaListItemContent, MediaListItemContentProps } from './MediaListItemContent';
import { message, Tooltip } from 'antd';

export type MediaListItemProps = MediaListItemContentProps & {};

export const MediaListItem: React.FC<MediaListItemProps> = observer(({ media, onClick }: MediaListItemProps) => {
  const handleCopyUrl = React.useCallback(() => {
    navigator.clipboard.writeText(media.url).then(() => message.info(`Copied ${media.url}`));
  }, [media]);

  return (
    <Col xs={6} lg={3}>
      <a style={{ fontSize: 10, textAlign: 'center' }} onClick={handleCopyUrl} target="javascript:void(0)">
        {media.name}
      </a>
      <MediaListItemContent media={media} onClick={onClick} />
      {/*<div*/}
      {/*  style={{*/}
      {/*    position: 'absolute',*/}
      {/*    top: 0,*/}
      {/*    left: 0,*/}
      {/*    right: 0,*/}
      {/*    bottom: 0,*/}
      {/*    display: 'flex',*/}
      {/*    paddingLeft: 16,*/}
      {/*    color: 'white',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {dayjs(media.createdAt).format('DD.MM.YYYY HH:mm')}*/}
      {/*</div>*/}
    </Col>
  );
});

const styles = {
  text: {
    fontSize: 10,
  },
};
