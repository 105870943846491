import { observable, computed } from 'mobx';
import { languagePriority } from '../LocalizedArrayEntity';
import { Media } from '../Media/Media';
import { LocalizedValue } from '../LocalizedValue';
import NativeFeedComponent from './NativeFeedComponent';

export class FeedObjectCollectionItemData {
  @observable
  content: string | Array<NativeFeedComponent> = [];
  @observable contentType: 'text/html' | 'native' = 'native';
  @observable medias?: Media = undefined;
  @observable
  preview: string | LocalizedValue[];
  @observable
  title: string | LocalizedValue[];

  constructor(json?: any) {
    if (json) {
      this.content = this.setContent(json.content);
      this.contentType = json.contentType || 'native';
      this.medias = json.medias ? new Media(json.medias) : undefined;
      this.preview =
        typeof json.preview === 'string'
          ? json.preview ?? ''
          : (json.preview ?? []).map((l: any) => new LocalizedValue(l));
      this.title =
        typeof json.title === 'string' ? json.title ?? '' : (json.title ?? []).map((l: any) => new LocalizedValue(l));
    } else {
      this.preview = [];
      this.title = [];
    }
  }

  setContent(content: any) {
    if (typeof content === 'string') {
      return content;
    }
    return (content ?? []).map((c: any) => new NativeFeedComponent(c));
  }

  getTitle(lang: string) {
    if (typeof this.title === 'string') {
      return this.title;
    }
    return this.title.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultTitle;
  }

  getPreview(lang: string) {
    if (typeof this.preview === 'string') {
      return this.preview;
    }
    return this.preview.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultPreview;
  }

  @computed
  get defaultTitle(): string {
    if (typeof this.title === 'string') {
      return this.title;
    }
    for (const lang of languagePriority) {
      const entry = this.title.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.title[0];
    return first?.value ?? '';
  }

  @computed
  get defaultPreview(): string {
    if (typeof this.preview === 'string') {
      return this.preview;
    }
    for (const lang of languagePriority) {
      const entry = this.preview.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.preview[0];
    return first?.value ?? '';
  }

  @computed
  get valid() {
    if (typeof this.title === 'string' && this.title) {
      if (typeof this.preview === 'string' && this.preview) {
        return true;
      }
      return (this.preview as LocalizedValue[]).length > 0;
    } else if ((this.title as LocalizedValue[]).length > 0) {
      if (typeof this.preview === 'string' && this.preview) {
        return true;
      }
      return (this.preview as LocalizedValue[]).length > 0;
    }
    return false;
  }
}
