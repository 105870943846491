import objectPath from 'object-path';
import { InStage } from './InStage';
import { Signal } from '../../Signal/Signal';
import { DataSignal } from '../../Signal/DataSignal';
import { StartSignal } from '../../Signal/StartSignal';

export class NotInStage extends InStage {
  canProceed(signal: Signal): boolean {
    if (signal instanceof DataSignal || signal instanceof StartSignal) {
      const object =
        this.config.source === 'input' && signal instanceof DataSignal ? signal.data : this.pipeline.context;
      const value = objectPath.get(object, this.config.attribute);
      return !this.compareValues(value, this.getValue());
    }
    return false;
  }

  toJS(): any {
    return super.toJS();
  }

  get type(): string {
    return 'logical:nin';
  }
}
