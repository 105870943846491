/**
 * Created by neo on 21.11.18
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { NavLink as RRLink } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { DropdownHeader } from '../DropdownHeader';
import { useRootStore } from '../../../Store/useRootStore';

export interface InfrastructureMenuProps {
  activeKey?: string;
  onToggle: (key?: string) => any;
}

export const InfrastructureMenu: React.FC<InfrastructureMenuProps> = observer(
  ({ activeKey, onToggle }: InfrastructureMenuProps) => {
    const { authentication } = useRootStore();
    return (
      <DropdownHeader dropdown={'infrastructure'} activeKey={activeKey} onToggle={onToggle} label={'Infrastructure'}>
        {authentication.hasAnyRole(['ROLE_SALES', 'ROLE_SUPPORT']) && (
          <DropdownItem tag={RRLink} to="/infrastructure/gym" activeClassName="active">
            Companies
          </DropdownItem>
        )}
        {authentication.hasAnyRole(['ROLE_SALES', 'ROLE_SUPPORT']) && (
          <DropdownItem tag={RRLink} to="/infrastructure/customers" activeClassName="active">
            Customers
          </DropdownItem>
        )}
        <DropdownItem tag={RRLink} to="/infrastructure/gym/flinq" activeClassName="active">
          Company - kinastic Coach
        </DropdownItem>
        {authentication.isGodAdmin ? (
          <>
            <DropdownItem tag={RRLink} to="/infrastructure/axon" activeClassName="active">
              Axon Server
            </DropdownItem>
          </>
        ) : null}
      </DropdownHeader>
    );
  },
);
