/**
 * Created by neo on 29.02.2024
 */
import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';
import { Pageable } from '../Pageable';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import {
  CampaignCalendarEntryAttachement,
  CampaignCalendarEntryAttachementJson,
} from './CampaignCalendarEntryAttachement';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { Gym } from '../Gym/Gym';
import { Media, MediaJson } from '../Media/Media';

export type CampaignCalendarQueryRequest = Pageable & {
  query?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  timezone?: string;
};

export type CampaignCalendarEntryJson = LocalizedArrayEntityJson & {
  gymId?: string;
  entryDate: string;
  entryTime: string;
  entryDateTime: string;
  attachments: CampaignCalendarEntryAttachementJson[];
  instructions: LocalizedValueJson[];
  publishDate?: string;
  unpublishDate?: string;
  tags: string[];
  images: MediaJson[];
};

export class CampaignCalendarEntry extends LocalizedArrayEntity {
  @observable
  gymId?: string;
  @observable
  entryDate: string = dayjs().add(1, 'month').format('YYYY-MM-DD');
  @observable
  entryTime: string = dayjs().add(1, 'month').format('HH:mm:ss');
  @observable
  attachments: CampaignCalendarEntryAttachement[] = [];
  @observable
  instructions: Array<LocalizedValue> = [];
  @observable
  publishDate?: Date;
  @observable
  unpublishDate?: Date;
  @observable
  tags: string[] = [];
  @observable
  images: Media[] = [];
  @observable
  gym?: Gym;

  constructor(json?: Partial<CampaignCalendarEntryJson>) {
    super(json);
    if (json) {
      this.gymId = json.gymId;
      this.entryDate = json.entryDate ?? dayjs().add(1, 'month').format('YYYY-MM-DD');
      this.entryTime = json.entryTime ?? dayjs().add(1, 'month').format('HH:mm:ss');
      this.attachments = json.attachments?.map((a) => new CampaignCalendarEntryAttachement(a)) ?? [];
      this.instructions = (json.instructions || []).map((l) => new LocalizedValue(l));
      this.publishDate = json.publishDate ? new Date(json.publishDate) : undefined;
      this.unpublishDate = json.unpublishDate ? new Date(json.unpublishDate) : undefined;
      this.tags = json.tags ?? [];
      this.images = json.images?.map((i) => new Media(i)) ?? [];
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
  }

  fetchGym() {
    if (!this.gym && this.gymId) {
      Gym.get(this.gymId).then((gym) => runInAction(() => (this.gym = gym)));
    }
  }

  toJS(newId?: boolean): CampaignCalendarEntryJson {
    return {
      ...super.toJS(newId),
      gymId: this.gymId,
      entryDate: this.entryDate,
      entryTime: this.entryTime,
      entryDateTime: this.entryDateTime,
      attachments: this.attachments.map((a) => a.toJS()),
      instructions: this.instructions.map((v) => v.toJS()),
      publishDate: this.publishDate?.toISOString(),
      unpublishDate: this.unpublishDate?.toISOString(),
      tags: this.tags,
      images: this.images.map((i) => i.toJS()),
    };
  }

  copy(): CampaignCalendarEntry {
    const copy = new CampaignCalendarEntry(this.toJS(true));
    copy.name = copy.name.map(({ lang, value }) => new LocalizedValue({ lang, value: `${value} (Copy)` }));
    copy.entryDate = this.localDateTime.add(1, 'day').startOf('day').format('YYYY-MM-DD');
    copy.entryTime = this.localDateTime.add(1, 'day').startOf('day').format('HH:mm:00');
    copy.publishDate = undefined;
    copy.unpublishDate = undefined;
    return copy;
  }

  save(): Promise<CampaignCalendarEntry> {
    return HttpBackend.post(`/gym/campaign/calendar/admin`, this.toJS()).then((res) => this);
  }

  delete(): Promise<CampaignCalendarEntry> {
    return HttpBackend.delete(`/gym/campaign/calendar/admin/${this.id}`).then((res) => this);
  }

  @computed
  get entryDateTime(): string {
    return `${this.entryDate}T${this.entryTime}`;
  }

  @computed
  get localDateTime(): Dayjs {
    return dayjs(this.entryDateTime, 'YYYY-MM-DDTHH:mm:ss');
  }

  @computed
  get published(): boolean {
    return (
      !!this.publishDate &&
      this.publishDate.valueOf() < Date.now() &&
      (!this.unpublishDate || this.unpublishDate.valueOf() > Date.now())
    );
  }

  static findOne(campaignId: string): Promise<CampaignCalendarEntry> {
    return HttpBackend.get(`/gym/campaign/calendar/admin/${campaignId}`).then((res) => new CampaignCalendarEntry(res));
  }

  static find(request?: Partial<CampaignCalendarQueryRequest>): Promise<CampaignCalendarEntry[]> {
    return HttpBackend.get(`/gym/campaign/calendar/admin`, request).then((res) =>
      res.map((c) => new CampaignCalendarEntry(c)),
    );
  }

  static count(request?: Partial<CampaignCalendarQueryRequest>): Promise<number> {
    return HttpBackend.get(`/gym/campaign/calendar/admin/count`, request).then((res) => res ?? 0);
  }
}
