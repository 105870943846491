/**
 * Created by neo on 08.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { Paragraph } from 'mdast-util-from-markdown/lib';
import { MarkdownNodeRenderer } from './MarkdownNodeRenderer';
import { ThemeConfig } from '../../Config/Theme';

export type MarkdownParagraphRendererProps = {
  content: Paragraph;
  textStyle?: React.CSSProperties;
};

export const MarkdownParagraphRenderer: React.FC<MarkdownParagraphRendererProps> = observer(
  ({ content, textStyle }) => {
    const { classes } = useStyles();

    return (
      <p className={classes.defaultStyle} style={textStyle}>
        {content.children.map((child, index) => (
          <MarkdownNodeRenderer key={index.toString()} child={child} />
        ))}
      </p>
    );
  },
);

const useStyles = makeStyles()((theme) => ({
  defaultStyle: {
    color: ThemeConfig.Colors.black,
    fontSize: 14,
    lineHeight: '1.2',
    flexShrink: 1,
  },
}));
