/**
 * Created by neo on 10.06.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { GymChallengeNotificationMessage } from '../../../../../Model/GymChallenge/GymChallengeNotificationMessage';
import { GymChallenge } from '../../../../../Model/GymChallenge/GymChallenge';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';

export type GymChallengeNotificationMessageEditorProps = {
  entry: GymChallenge;
  message: GymChallengeNotificationMessage;
};

export const GymChallengeNotificationMessageEditor: React.FC<GymChallengeNotificationMessageEditorProps> = observer(
  ({ entry, message }) => {
    return (
      <Row>
        <Col>
          <TranslationInputArray entity={message} field="title" label="Title"  />
        </Col>
        <Col>
          <TranslationInputArray entity={message} field="message" label="Message" />
        </Col>
      </Row>
    );
  },
);
