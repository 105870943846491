import { computed, observable, toJS } from 'mobx';
import {
  EarnModelTemplateRuleActivationFrequency,
  EarnModelTemplateRuleActivationFrequencyJson,
} from './EarnModelTemplateRuleActivationFrequency';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { NotificationMessage, NotificationMessageJson } from '../Explore/NotificationMessage';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';

/**
 * Created by neo on 17.08.22.
 */

export type EarnModelTemplateRuleAbstractJson = LocalizedArrayEntityJson & {
  ruleIdentifier: string;
  type: string;
  templateName: string;
  pointsPerActivation: number;
  maxActivations: number;
  activationFrequencies: EarnModelTemplateRuleActivationFrequencyJson[];
  dependsOnRuleIds: string[];
  appActionLink?: string;
  notificationMessage?: NotificationMessageJson;
  instantMessage: LocalizedValueJson[];
  allowInChallenges: boolean;
};

export class EarnModelTemplateRuleAbstract extends LocalizedArrayEntity {
  @observable
  ruleIdentifier = '';
  @observable
  type: string = '';
  @observable
  templateName: string = '';
  @observable
  pointsPerActivation: number = 0;
  @observable
  maxActivations: number = 1;
  @observable
  activationFrequencies: EarnModelTemplateRuleActivationFrequency[] = [
    new EarnModelTemplateRuleActivationFrequency({ duration: 'PT1D', maxActivations: 1 }),
  ];
  @observable
  dependsOnRuleIds: string[] = [];
  @observable
  appActionLink?: string;
  @observable
  notificationMessage?: NotificationMessage;
  @observable
  instantMessage: LocalizedValue[] = [];
  @observable
  allowInChallenges = false;

  constructor(json?: Partial<EarnModelTemplateRuleAbstractJson>) {
    super(json);
    if (json) {
      this.ruleIdentifier = json.ruleIdentifier ?? '';
      this.type = json.type ?? 'simple';
      this.templateName = json.templateName ?? '';
      this.pointsPerActivation = json.pointsPerActivation ?? 0;
      this.maxActivations = json.maxActivations ?? 1;
      this.activationFrequencies =
        json.activationFrequencies?.map((w) => new EarnModelTemplateRuleActivationFrequency(w)) ?? [];
      this.dependsOnRuleIds = json.dependsOnRuleIds ?? [];
      this.appActionLink = json.appActionLink;
      this.notificationMessage = json.notificationMessage
        ? new NotificationMessage(json.notificationMessage)
        : undefined;
      this.instantMessage = json.instantMessage?.map((l) => new LocalizedValue(l)) ?? [];
      this.allowInChallenges = json.allowInChallenges ?? false;
    }
  }

  toJS(newId: boolean = false): EarnModelTemplateRuleAbstractJson {
    return Object.assign(super.toJS(newId), {
      ruleIdentifier: this.ruleIdentifier,
      type: this.type,
      templateName: this.templateName,
      pointsPerActivation: this.pointsPerActivation,
      maxActivations: this.maxActivations,
      activationFrequencies: this.activationFrequencies.map((f) => f.toJS()),
      dependsOnRuleIds: toJS(this.dependsOnRuleIds),
      appActionLink: this.appActionLink,
      notificationMessage: this.notificationMessage?.toJS(),
      instantMessage: this.instantMessage.map((l) => l.toJS()),
      allowInChallenges: this.allowInChallenges,
    });
  }

  copy(): EarnModelTemplateRuleAbstract {
    return new EarnModelTemplateRuleAbstract(this.toJS(true));
  }

  @computed
  get maxPointsPerActivation(): number {
    return this.pointsPerActivation;
  }
}
