export const allUserTopics = `
SELECT *, GREATEST(IFNULL(user_count, 0) - IFNULL(user_count_deleted, 0), 0) as current_user_count FROM (
    SELECT
    p.value.string_value as topic,
    COUNT(DISTINCT user_id) as user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) p
  WHERE 
    event_name = 'topic_added'
    AND p.key = 'id'
  GROUP BY p.value.string_value
) a
LEFT JOIN (
    SELECT
    p.value.string_value as topic,
    COUNT(DISTINCT user_id) as user_count_deleted
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) p
  WHERE 
    event_name = 'topic_deleted'
    AND p.key = 'id'
  GROUP BY p.value.string_value
) d
ON a.topic = d.topic
ORDER BY current_user_count
`;

export const gymUserTopics = `
SELECT *, GREATEST(IFNULL(user_count, 0) - IFNULL(user_count_deleted, 0), 0) as current_user_count FROM (
    SELECT
    p.value.string_value as topic,
    COUNT(DISTINCT user_id) as user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) p,
    UNNEST(user_properties) u
  WHERE 
    event_name = 'topic_added'
    AND p.key = 'id'
    AND u.key = 'gymId'
    AND u.value.string_value = @gym_id
  GROUP BY p.value.string_value
) a
LEFT JOIN (
    SELECT
    p.value.string_value as topic,
    COUNT(DISTINCT user_id) as user_count_deleted
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) p,
    UNNEST(user_properties) u
    WHERE 
    event_name = 'topic_deleted'
    AND p.key = 'id'
    AND u.key = 'gymId'
    AND u.value.string_value = @gym_id
  GROUP BY p.value.string_value
) d
ON a.topic = d.topic
ORDER BY current_user_count
`;
