import * as React from 'react';
import { observer } from 'mobx-react';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { observable } from 'mobx';
import { Col, Container, Input, Row, Table } from 'reactstrap';
import { AxonSagaTableRow } from './AxonSagaTableRow';
import { AxonSaga } from '../../../../../Model/Axon/AxonSaga';

export interface AxonSnapshotTabProps {
  aggregateIdentifier?: string;
}

@observer
export class AxonSagaTab extends React.Component<AxonSnapshotTabProps> {
  @observable
  query: string = '';
  @observable.shallow
  results: AxonSaga[] = [];
  debounce?: any;

  componentDidMount(): void {
    this.setup();
  }

  componentWillUnmount(): void {
    this.debounce && clearTimeout(this.debounce);
  }

  async setup() {
    this.submit();
  }

  handleQueryChange = ({ target: { value } }) => {
    this.query = value;
    // this.debounce = setTimeout(() => {
    //   this.submit();
    // }, 300);
  };

  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.submit();
    }
  };

  async submit() {
    const { query } = this;
    this.results = await AxonSaga.find({ query, sort: 'sagaType,ASC' });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Input type="text" value={this.query} onChange={this.handleQueryChange} onKeyDown={this.handleKeyDown} />
          </Col>
        </Row>
        <SingleColRow>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Data</th>
                <th>Associations</th>
              </tr>
            </thead>
            <tbody>
              {this.results.map((result: AxonSaga) => (
                <AxonSagaTableRow key={result.id} saga={result} />
              ))}
            </tbody>
          </Table>
        </SingleColRow>
      </Container>
    );
  }
}
