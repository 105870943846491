import { Stage } from '../../../../../../../Model/Coach/Stages/Stage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { PhaseStage } from '../../../../../../../Model/Coach/Stages/PhaseStage';

export class PhaseNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: PhaseStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'phase' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
