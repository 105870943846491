/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { CustomersTableRow } from './CustomersTableRow';
import { Customer } from '../../../../Model/Customer/Customer';

export type CustomersTableProps = {
  customers: Customer[];
  onClick?: (customer: Customer) => any;
};

export const CustomersTable: React.FC<CustomersTableProps> = observer(({ customers, onClick }) => {
  return (
    <Table className="table table-striped table-sm">
      <thead className="thead-inverse">
        <tr>
          <th>{'ID'}</th>
          <th>{'Gym'}</th>
        </tr>
      </thead>
      <tbody>
        {customers.map((customer) => (
          <CustomersTableRow key={customer.id} customer={customer} onClick={onClick} />
        ))}
      </tbody>
    </Table>
  );
});
