/**
 * Created by neo on 18.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Form, Input, InputNumber, Space, Switch } from 'antd';
import { Row, Col } from 'reactstrap';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { runInAction } from 'mobx';
import MediaService from '../../../../Services/MediaService';
import CreatableSelect from 'react-select/creatable';
import { CuisinesList, DietsList, DishList, SeasonList } from '../../../../Model/Diet/Recipe/RecipeSearchParams';
import { ViewMediaItem } from '../../../../Components/ViewMedia/ViewMediaItem';
import Dropzone from 'react-dropzone';

export type RecipeEditModalBasicInfoProps = {
  recipe: Recipe;
};

export const RecipeEditModalBasicInfo: React.FC<RecipeEditModalBasicInfoProps> = observer(({ recipe }) => {
  const changeBoolean = React.useCallback(
    (field: string) => () => {
      runInAction(() => (recipe[field] = !recipe[field]));
    },
    [recipe],
  );

  const changeNumber = React.useCallback(
    (field: string) => (value: number | string | undefined | null) => {
      runInAction(() => (recipe[field] = value !== undefined && value !== null ? Number(value) : 0));
    },
    [recipe],
  );

  const changeText = React.useCallback(
    (field: string) => ({ target: { value } }) => {
      runInAction(() => (recipe[field] = value));
    },
    [recipe],
  );

  const handleChangeDiet = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        recipe.diets = (tags ?? []).map((t) => t.value);
        if (recipe.diets.some((t) => t.startsWith('gaps_'))) {
          if (!recipe.diets.some((t) => t === 'gaps')) {
            recipe.diets.push('gaps');
          }
        } else {
          recipe.diets = recipe.diets.filter((t) => t !== 'gaps');
        }
      });
    },
    [recipe],
  );

  const changeStringArray = React.useCallback(
    (field: string) => (tags?: any[]) => {
      runInAction(() => {
        recipe[field].splice(0, recipe[field].length);
        (tags ?? []).map((t) => t.value).forEach((t) => recipe[field].push(t));
      });
    },
    [recipe],
  );

  const handleDrop = React.useCallback(
    (files: File[]) => {
      const file = files.length > 0 ? files[0] : undefined;
      if (file) {
        MediaService.uploadMedia(file).then((media) => runInAction(() => (recipe.image = media)));
      }
    },
    [recipe],
  );

  const handleRemoveFile = React.useCallback(() => {
    runInAction(() => (recipe.image = undefined));
  }, [recipe]);

  return (
    <Form layout="vertical">
      <Row>
        <Col>
          <TranslationInputArray entity={recipe} field="name" label="Name" />
        </Col>
        <Col>
          <TranslationInputArray
            entity={recipe}
            type="textarea"
            field="description"
            label="Description"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Diets">
            <CreatableSelect
              isClearable
              isMulti
              options={DietsList.map((value) => ({ label: value, value }))}
              onChange={handleChangeDiet as any}
              value={recipe.diets.map((value) => ({ label: value, value }))}
              placeholder="Diets"
            />
          </Form.Item>
        </Col>
        <Col xs="auto">
          <Form.Item label="Tags">
            <Space>
              <Switch
                checkedChildren="very healthy"
                unCheckedChildren="very healthy"
                checked={recipe.veryHealthy}
                onChange={changeBoolean('veryHealthy')}
              />
              <Switch
                checkedChildren="cheap"
                unCheckedChildren="cheap"
                checked={recipe.cheap}
                onChange={changeBoolean('cheap')}
              />
              <Switch
                checkedChildren="sustainable"
                unCheckedChildren="sustainable"
                checked={recipe.sustainable}
                onChange={changeBoolean('sustainable')}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Weight Watchers Points">
            <InputNumber value={recipe.weightWatcherSmartPoints} onChange={changeNumber('weightWatcherSmartPoints')} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Health Score">
            <InputNumber value={recipe.healthScore} onChange={changeNumber('healthScore')} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Servings">
            <InputNumber value={recipe.servings} onChange={changeNumber('servings')} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Price per Serving">
            <InputNumber value={recipe.pricePerServing} onChange={changeNumber('pricePerServing')} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Ready in Minutes">
            <InputNumber value={recipe.readyInMinutes} onChange={changeNumber('readyInMinutes')} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Cuisines">
            <CreatableSelect
              isClearable
              isMulti
              options={CuisinesList.map((value) => ({ label: value, value }))}
              onChange={changeStringArray('cuisines') as any}
              value={recipe.cuisines.map((value) => ({ label: value, value }))}
              placeholder="Cuisines"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Dish Types">
            <CreatableSelect
              isClearable
              isMulti
              options={DishList.map((value) => ({ label: value, value }))}
              onChange={changeStringArray('dishTypes') as any}
              value={recipe.dishTypes.map((value) => ({ label: value, value }))}
              placeholder="Dish Types"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Seasons">
            <CreatableSelect
              isClearable
              isMulti
              options={SeasonList.map((value) => ({ label: value, value }))}
              onChange={changeStringArray('seasons') as any}
              value={recipe.seasons.map((value) => ({ label: value, value }))}
              placeholder="Seasons"
            />
          </Form.Item>
        </Col>
      </Row>
      <SingleColRow>
        <Form.Item label="Image">
          {recipe.image ? (
            <ViewMediaItem media={recipe.image} onRemove={handleRemoveFile} />
          ) : (
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="dropzone"
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  {'Drop Medias (Images or Videos) here'}
                </div>
              )}
            </Dropzone>
          )}
        </Form.Item>
      </SingleColRow>
      <Row>
        <Col>
          <Form.Item label="Credits Text">
            <Input value={recipe.creditsText} onChange={changeText('creditsText')} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="License">
            <Input value={recipe.license} onChange={changeText('license')} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Source Name">
            <Input value={recipe.sourceName} onChange={changeText('sourceName')} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Source URL">
            <Input value={recipe.sourceUrl} onChange={changeText('sourceUrl')} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
