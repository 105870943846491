/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { EquipmentType } from '../../../../../Model/Equipment/EquipmentType';
import { PageResult } from '../../../../../Model/PageResult';
import { Pager } from '../../../../../Components/Pager';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { ExerciseVariationAdmin } from '../../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { ExerciseVariationAdminTable } from '../../../Exercise/ExerciseListScreen/ExerciseVariationAdminTable';

export type Props = {
  equipmentType: EquipmentType;
};

@observer
export class EquipmentTypeViewExercisesTab extends React.Component<Props> {
  @observable
  result: Array<ExerciseVariationAdmin> = [];
  @observable
  page: number = 0;
  @observable
  query: string = '';
  debounce?: number;

  componentDidMount() {
    this.load(this.page);
  }

  async load(page: number) {
    const allTypes = await this.props.equipmentType.resolveAll();
    console.log(allTypes);
    this.result = await ExerciseVariationAdmin.find({
      query: this.query,
      page,
      equipmentTypes: allTypes.map((e) => e.id).join(','),
      equipmentTypeMatchNotAll: true,
    });
  }

  handlePage = (page: number) => {
    if (this.page !== page) {
      this.page = page;
      this.load(this.page);
    }
  };

  handleQueryChange = ({ target: { value } }) => {
    clearTimeout(this.debounce);
    this.query = value;
    this.debounce = window.setTimeout(() => {
      this.page = 0;
      this.load(this.page);
    }, 250);
  };

  render() {
    const { result, query } = this;
    return (
      <React.Fragment>
        <SingleColRow>
          <Input type="text" value={this.query} onChange={this.handleQueryChange} />
        </SingleColRow>
        <SingleColRow>
          {/*<Pager page={result} onPage={this.handlePage} />*/}
        </SingleColRow>
        <SingleColRow>
          <ExerciseVariationAdminTable variations={result} query={query} />
        </SingleColRow>
        <SingleColRow>
          {/*<Pager page={result} onPage={this.handlePage} />*/}
        </SingleColRow>
      </React.Fragment>
    );
  }
}
