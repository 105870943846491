/**
 * Created by neo on 14.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useCallback, useState } from 'react';
import { ProgramParams } from '../../../../Model/Coach/Program/ProgramParams';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import Select from 'react-select';
import { AthleteEquipment } from '../../../../Model/AthleteEquipment/AthleteEquipment';
import { EquipmentConfiguration } from '../../../../Model/Coach/EquipmentConfiguration';
import { runInAction } from 'mobx';

export type EquipmentConfigurationInputProps = {
  params: ProgramParams;
  athlete: Athlete;
  onChange?: () => any;
};

export const EquipmentConfigurationInput: React.FC<EquipmentConfigurationInputProps> = observer(
  ({ athlete, params }) => {
    const [options, setOptions] = useState(Array<AthleteEquipment>());

    const selected = options.find((o) => o.id === params.equipmentConfiguration.id);

    const load = React.useCallback((athleteId: string) => {
      AthleteEquipment.list({ athleteId })
        .then((result) =>
          result.concat([
            new AthleteEquipment({ id: 'gym', name: 'Gym' }),
            new AthleteEquipment({ id: 'free', name: 'No Equipment', equipmentTypes: ['FREE', 'FREE_OUTDOOR'] }),
          ]),
        )
        .then((result) => setOptions(result));
    }, []);

    useEffect(() => {
      load(athlete.id);
    }, [athlete, load]);

    const handleChange = React.useCallback(
      (config?: AthleteEquipment) => {
        if (config) {
          runInAction(() => {
            params.equipmentConfiguration = new EquipmentConfiguration(config);
            params.removeTagGroup('equipment');
            if (config.id === 'free') {
              params.tags.push('equipment:no');
            }
          });
        }
      },
      [params],
    );

    const getOptionLabel = React.useCallback((option: AthleteEquipment) => option.name || 'Personal', []);

    const getOptionValue = React.useCallback((option: AthleteEquipment) => option, []);

    return (
      <Select
        value={selected}
        options={options}
        isClearable={true}
        getOptionLabel={getOptionLabel as any}
        getOptionValue={getOptionValue as any}
        onChange={handleChange as any}
      />
    );
  },
);
