/**
 * @fow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { Nav, Container } from 'reactstrap';
import { AxonQueryTab } from './AxonQueryTab/AxonQueryTab';
import { EventProcessorTab } from './EventProcessorTab/EventProcessorTab';
import { AxonSnapshotTab } from './AxonSnapshotTab/AxonSnapshotTab';
import { AxonSagaTab } from './AxonSagaTab/AxonSagaTab';
import { AxonTrackingTokenTab } from './AxonTrackingTokenTab/AxonTrackingTokenTab';
import { Route, Routes } from 'react-router';
import { useState } from 'react';
import { RouterTabNavItem } from '../../../../Components/RouterTabNavItem';
import { observer } from 'mobx-react';

export type EventProcessorsScreenProps = {};

export const EventProcessorsScreen: React.FC<EventProcessorsScreenProps> = observer(() => {
  const [aggregateIdentifier, setAggregateIdentifier] = useState<string | undefined>(undefined);

  const baseUrl = '/infrastructure/axon';

  return (
    <React.Fragment>
      <Container style={{ paddingBottom: 0 }}>
        <Nav tabs>
          <RouterTabNavItem label="Query" baseUrl={baseUrl} />
          <RouterTabNavItem tabKey="snapshot" label="Snapshots" baseUrl={baseUrl} />
          <RouterTabNavItem tabKey="saga" label="Sagas" baseUrl={baseUrl} />
          <RouterTabNavItem tabKey="trackingtoken" label="Tracking Tokens" baseUrl={baseUrl} />
          <RouterTabNavItem tabKey="processors" label="Event Processors" baseUrl={baseUrl} />
        </Nav>
      </Container>
      <Routes>
        <Route index element={<AxonQueryTab />} />
        <Route path="snapshot" element={<AxonSnapshotTab aggregateIdentifier={aggregateIdentifier} />} />
        <Route path="saga" element={<AxonSagaTab />} />
        <Route path="trackingtoken" element={<AxonTrackingTokenTab />} />
        <Route path="processors" element={<EventProcessorTab />} />
      </Routes>
    </React.Fragment>
  );
});
