/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AnalyticsData } from '../../../Model/Analytics/AnalyticsData';
import { Gym } from '../../../Model/Gym/Gym';
import { allUserTopics, gymUserTopics } from './Queries/queryUserTopics';
import { TopicDistributionChart } from './TopicDistributionChart';

export type TopicDistributionProps = {
  gym?: Gym;
};

export const TopicDistribution: React.FC<TopicDistributionProps> = observer(({ gym }) => {
  const [data, setData] = useState<{ name: string; value: number }[] | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(undefined);
    setLoading(true);
    AnalyticsData.query({
      query: gym ? gymUserTopics : allUserTopics,
      parameters: gym
        ? {
            gym_id: {
              value: gym.id,
            },
          }
        : {},
    })
      .then((result) =>
        setData(result.map((entry) => ({ name: entry.topic, value: Number(entry.current_user_count) }))),
      )
      .finally(() => setLoading(false));
  }, [gym]);

  if (data) {
    return <TopicDistributionChart data={data} />;
  }

  return null;
});
