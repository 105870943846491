import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { MicroCycleConfiguration } from './MicroCycleConfiguration';

export class MesoCycleConfiguration {
  @observable
  id = UUID();
  @observable
  mesoCycleTemplateId: string = '';
  @observable
  mesoCycleConfigurationTemplateId: string = '';
  @observable
  type: string = '';
  @observable
  workoutsPerCycle: number = 0;
  @observable
  cycles: number = 4;
  @observable
  refreshWorkoutInterval: number = 0;
  @observable
  microCycleConfiguration?: MicroCycleConfiguration = undefined;

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.mesoCycleTemplateId = json.mesoCycleTemplateId || '';
      this.mesoCycleConfigurationTemplateId = json.mesoCycleConfigurationTemplateId || '';
      this.type = json.type || '';
      this.workoutsPerCycle = json.workoutsPerCycle ?? 0;
      this.cycles = json.cycles ?? 4;
      this.refreshWorkoutInterval = json.refreshWorkoutInterval ?? 0;
      this.microCycleConfiguration = json.microCycleConfiguration
        ? new MicroCycleConfiguration(json.microCycleConfiguration)
        : undefined;
    }
  }

  toJS() {
    return {
      id: this.id,
      mesoCycleTemplateId: this.mesoCycleTemplateId,
      mesoCycleConfigurationTemplateId: this.mesoCycleConfigurationTemplateId,
      type: this.type,
      workoutsPerCycle: this.workoutsPerCycle,
      cycles: this.cycles,
      refreshWorkoutInterval: this.refreshWorkoutInterval,
      microCycleConfiguration: this.microCycleConfiguration?.toJS(),
    };
  }
}
