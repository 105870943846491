/**
 * Created by neo on 25.05.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';
import { Button, Checkbox, Input, Popconfirm } from 'antd';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { TranslationKey } from '../../../Model/Translation/TranslationKey';
import { DeleteOutlined } from '@ant-design/icons';

export type TranslationTableRowEntryProps = {
  translation: MergedTranslation;
  entry: TranslationKey;
};

export const TranslationTableRowEntry: React.FC<TranslationTableRowEntryProps> = observer(({ entry, translation }) => {
  const [input, setInput] = useState(entry.value ?? '');
  const [dirty, setDirty] = useState(false);

  const defaultValue = entry.value ?? '';
  const hasDuplicateLanguage = translation.keys.filter((e) => e.language === entry.language).length > 1;

  useEffect(() => {
    setInput(defaultValue);
  }, [defaultValue]);

  const handleChangeInput = React.useCallback(
    ({ target: { value: newInput } }) => {
      setInput(newInput);
      setDirty(newInput !== defaultValue);
    },
    [defaultValue],
  );

  const handleSave = React.useCallback(() => {
    console.log('saving');
    runInAction(() => (entry.value = input));
    setDirty(false);
    entry.save();
  }, [entry, input]);

  const handleRemove = React.useCallback(() => {
    entry.delete().then(() =>
      runInAction(() => {
        const index = translation.keys.findIndex((k) => k.id === entry.id);
        console.log('delete index', index, translation.keys.length);
        if (index !== -1) {
          translation.keys.splice(index, 1);
          console.log('delete index AFTER', index, translation.keys.length);
        }
      }),
    );
  }, [entry, translation]);

  const handleChangeCheckedInternal = React.useCallback(
    ({ target: { checked } }) => {
      runInAction(() => (entry.checkedInternal = checked));
      entry.save();
    },
    [entry],
  );

  const handleChangeCheckedExternal = React.useCallback(
    ({ target: { checked } }) => {
      runInAction(() => (entry.checkedExternal = checked));
      entry.save();
    },
    [entry],
  );

  return (
    <React.Fragment>
      <Row>
        <Col xs="auto">
          <b style={{ color: hasDuplicateLanguage ? 'red' : 'black' }}>
            <Popconfirm title={`Delete this key?`} onConfirm={handleRemove}>
              <Button type="link" danger={true} icon={<DeleteOutlined />} />
            </Popconfirm>
            {entry.language}
          </b>
        </Col>
        <Col>
          <Row className="align-items-center">
            <Col xs={6}>
              <Input.TextArea
                value={input}
                onChange={handleChangeInput}
                onSubmit={handleSave}
                rows={2}
                cols={32}
                status={entry.validationState}
              />
            </Col>
            <Col xs={3}>
              <Checkbox checked={entry.checkedInternal} onChange={handleChangeCheckedInternal}>
                <p style={{ fontSize: 12 }}>Checked by KINASTIC</p>
              </Checkbox>
            </Col>
            <Col xs={3}>
              <Checkbox checked={entry.checkedExternal} onChange={handleChangeCheckedExternal}>
                <p style={{ fontSize: 12 }}>Checked by Translator</p>
              </Checkbox>
            </Col>
          </Row>
        </Col>
        {dirty && (
          <Col xs="auto">
            <Button size="small" type="primary" onClick={handleSave}>
              Save
            </Button>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
});
