import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { LimitFieldStage } from '../../../../../../../Model/Coach/Stages/LimitField';

export class LimitFieldNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: LimitFieldStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'limitField' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
