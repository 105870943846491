/**
 * Created by neo on 18.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { RecipeIngredient } from '../../../../Model/Diet/Recipe/RecipeIngredient';
import { Col, Row } from 'reactstrap';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { MeasureEntry } from '../../../../Model/Diet/MeasureEntry';
import { runInAction } from 'mobx';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { defaultPossibleUnits } from '../../../../Model/Diet/Ingredient/Ingredient';
import { useEffect } from 'react';
import { DeeplService } from '../../../../Services/DeeplService';
import { LocalizedValue } from '../../../../Model/LocalizedValue';

function getUnit(amount: number, unit: string): string | undefined {
  const unitSmall = unit.toLowerCase();
  if (unitSmall === 'tbsp' || unitSmall === 'tablespoon' || unitSmall === 'tablespoons') {
    if (amount > 1) {
      return `${amount} tablespoons`;
    } else {
      return `${amount} tablespoon`;
    }
  } else if (unitSmall === 'tsp' || unitSmall === 'teaspoon' || unitSmall === 'teaspoons') {
    if (amount > 1) {
      return `${amount} teaspoons`;
    } else {
      return `${amount} teaspoon`;
    }
  } else if (unitSmall === 'g') {
    return `${amount} grams`;
  } else if (unitSmall === 'ml') {
    if (amount > 1) {
      return `${amount} milliliters`;
    } else {
      return `${amount} milliliter`;
    }
  } else if (unitSmall === 'l') {
    if (amount > 1) {
      return `${amount} liters`;
    } else {
      return `${amount} liter`;
    }
  } else if (unitSmall === 'kg') {
    if (amount > 1) {
      return `${amount} kilograms`;
    } else {
      return `${amount} kilogram`;
    }
  } else if (unitSmall === 'piece') {
    if (amount > 1) {
      return `${amount} pieces`;
    } else {
      return `${amount} piece`;
    }
  } else if (unitSmall) {
    return `${amount} ${unitSmall}`;
  }

  return undefined;
}

export type RecipeEditModalIngredientEntryProps = {
  ingredient: RecipeIngredient;
  onRemove?: (ingredient: RecipeIngredient) => void;
};

export const RecipeEditModalIngredientEntry: React.FC<RecipeEditModalIngredientEntryProps> = observer(
  ({ ingredient, onRemove }) => {
    // const unitsArray =
    //   Object.entries(ingredient.possibleUnits).length > 0
    //     ? Object.entries(ingredient.possibleUnits)
    //     : Object.entries(defaultPossibleUnits);

    const unitsArray = Object.entries(defaultPossibleUnits);

    useEffect(() => {
      const amount = ingredient.measures.metric?.amount ?? 0;
      const unit = ingredient.measures.metric?.unitShort;
      if (ingredient.description.length === 0 && amount > 0 && unit) {
        const english = getUnit(amount, unit);
        if (english) {
          Promise.all([
            DeeplService.translate({ text: english, sourceLanguage: 'en', targetLanguage: 'de' }),
            DeeplService.translate({ text: english, sourceLanguage: 'en', targetLanguage: 'fr' }),
            DeeplService.translate({ text: english, sourceLanguage: 'en', targetLanguage: 'it' }),
          ]).then(([de, fr, it]) => {
            runInAction(() => {
              ingredient.description = [
                new LocalizedValue({ lang: 'en', value: english }),
                new LocalizedValue({ lang: 'de', value: de }),
                new LocalizedValue({ lang: 'fr', value: fr }),
                new LocalizedValue({ lang: 'it', value: it }),
              ];
            });
          });
        }
      }
    }, [ingredient, ingredient.measures.metric?.amount, ingredient.measures.metric?.unitShort]);

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(ingredient);
    }, [onRemove, ingredient]);

    const handleChangeUsAmount = React.useCallback(
      (amount?: string | number | null) => {
        if (amount !== undefined && amount !== null) {
          runInAction(() => {
            if (ingredient.measures.us) {
              ingredient.measures.us.amount = Number(amount);
            } else {
              ingredient.measures.us = new MeasureEntry({ amount: Number(amount) });
            }
          });
        } else {
          runInAction(() => (ingredient.measures.us = undefined));
        }
      },
      [ingredient],
    );

    const handleChangeMetricAmount = React.useCallback(
      (amount?: string | number | null) => {
        if (amount !== undefined && amount !== null) {
          runInAction(() => {
            if (ingredient.measures.metric) {
              ingredient.measures.metric.amount = Number(amount);
            } else {
              ingredient.measures.metric = new MeasureEntry({ amount: Number(amount) });
            }
          });
        } else {
          runInAction(() => (ingredient.measures.metric = undefined));
        }
      },
      [ingredient],
    );

    const handleChangeUsUnit = React.useCallback(
      (value) => {
        runInAction(() => {
          if (ingredient.measures.us) {
            ingredient.measures.us.unitShort = value;
          } else {
            ingredient.measures.us = new MeasureEntry({ unitShort: value });
          }
        });
      },
      [ingredient],
    );

    const handleChangeMetricUnit = React.useCallback(
      (value) => {
        runInAction(() => {
          if (ingredient.measures.metric) {
            ingredient.measures.metric.unitShort = value;
          } else {
            ingredient.measures.metric = new MeasureEntry({ unitShort: value });
          }
        });
      },
      [ingredient],
    );

    console.log(
      `ingredient ${ingredient.defaultName}`,
      ingredient.possibleUnits,
      ingredient.measures.metric?.unitShort,
    );

    const selectedUsUnit = ingredient.measures.us?.unitShort
      ? ingredient.possibleUnits[ingredient.measures.us.unitShort] * (ingredient.measures.us?.amount ?? 0) ??
        (ingredient.measures.us.unitShort === 'g' || ingredient.measures.us.unitShort === 'milliliter'
          ? ingredient.measures.us?.amount ?? 0
          : undefined)
      : undefined;
    const selectedMetricUnit = ingredient.measures.metric?.unitShort
      ? ingredient.possibleUnits[ingredient.measures.metric.unitShort] * (ingredient.measures.metric?.amount ?? 0) ??
        (ingredient.measures.metric.unitShort === 'g' || ingredient.measures.metric.unitShort === 'milliliter'
          ? ingredient.measures.metric?.amount ?? 0
          : undefined)
      : undefined;

    return (
      <Form layout="vertical">
        <Row>
          <Col xs={12}>
            <Row>
              <Col>
                <Form.Item label="Ingredient">
                  <Input value={ingredient.defaultName} disabled={true} />
                </Form.Item>
              </Col>
              <Col xs="auto">
                <Form.Item label={' '}>
                  <Button type="primary" danger onClick={handleRemove}>
                    Remove
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <SingleColRow>
              <Form.Item label="Description">
                <TranslationInputArray entity={ingredient} field="description" />
              </Form.Item>
            </SingleColRow>
          </Col>
          <Col xs={12}>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Item label="US Amount">
                      <InputNumber value={ingredient.measures.us?.amount} onChange={handleChangeUsAmount} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label="Unit"
                      extra={selectedUsUnit ? `${selectedUsUnit} ${ingredient.unit}` : ''}
                      validateStatus={selectedUsUnit ? '' : 'error'}
                    >
                      <Select value={ingredient.measures.us?.unitShort} onChange={handleChangeUsUnit}>
                        {unitsArray
                          .filter(([unit, value]) => unit === 'g' || unit === 'ml' || value !== 1)
                          .map(([unit, value]) => (
                            <Select.Option key={unit} value={unit}>
                              {unit}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Item label="Metric Amount">
                      <InputNumber value={ingredient.measures.metric?.amount} onChange={handleChangeMetricAmount} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label="Unit"
                      extra={selectedMetricUnit ? `${selectedMetricUnit} ${ingredient.unit}` : ''}
                      validateStatus={selectedMetricUnit ? '' : 'error'}
                    >
                      <Select value={ingredient.measures.metric?.unitShort} onChange={handleChangeMetricUnit}>
                        {Object.entries(defaultPossibleUnits).map(([unit, value]) => (
                          <Select.Option key={unit} value={unit}>
                            {unit}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  },
);
