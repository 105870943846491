/**
 * Created by neo on 08.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { CoachTtsGroupedResult } from '../../../Model/Coach/TextToSpeech/CoachTtsGroupedResult';
import { CoachTtsTableRow } from './CoachTtsTableRow';

export type CoachTtsTableProps = {
  result: CoachTtsGroupedResult[];
};

export const CoachTtsTable: React.FC<CoachTtsTableProps> = observer(({ result }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Identifier</th>
          <th>Count</th>
          <th>Languages</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {result.map((tts) => (
          <CoachTtsTableRow key={tts.identifier} group={tts} />
        ))}
      </tbody>
    </Table>
  );
});
