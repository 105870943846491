/**
 * Created by neo on 23.03.21.
 */
import { observable } from 'mobx';
import { DayOfWeek, IsoDayOfWeekList } from '../../DayOfWeek';
import dayjs, { Dayjs } from 'dayjs';
import { notUndefined } from '../../../Utils/notUndefined';

export type RecurringPatternType = 'daily' | 'weekly' | 'monthly' | 'yearly';

export type NthDayOfMonth = 'every' | 'first' | 'second' | 'third' | 'fourth' | 'last';

export type RecurringPatternJson = {
  type: RecurringPatternType;
  endDate?: string;
  maxOccurrences?: number;
  separationCount: number;
  daysOfWeek: DayOfWeek[];
  weekOfMonth?: number;
  dayOfMonth?: number;
  monthOfYear?: number;
  nthDayOfTheMonth?: NthDayOfMonth;
  weekDayOfMonth?: DayOfWeek;
};

export class RecurringPattern {
  @observable
  type: RecurringPatternType = 'daily';
  @observable
  endDate?: Date = dayjs().add(6, 'month').toDate();
  @observable
  maxOccurrences?: number;
  @observable
  separationCount: number = 1;
  @observable
  daysOfWeek: DayOfWeek[] = [];
  @observable
  weekOfMonth?: number;
  @observable
  dayOfMonth?: number;
  @observable
  monthOfYear?: number;
  @observable
  nthDayOfTheMonth?: NthDayOfMonth;
  @observable
  weekDayOfMonth?: DayOfWeek;

  constructor(json?: Partial<RecurringPatternJson>) {
    if (json) {
      this.type = json.type ?? 'daily';
      this.endDate = json.endDate ? new Date(json.endDate) : undefined;
      this.maxOccurrences = json.maxOccurrences;
      this.separationCount = json.separationCount ?? 1;
      this.daysOfWeek = json.daysOfWeek ?? [];
      this.weekOfMonth = json.weekOfMonth;
      this.dayOfMonth = json.dayOfMonth;
      this.monthOfYear = json.monthOfYear;
      this.nthDayOfTheMonth = json.nthDayOfTheMonth;
      this.weekDayOfMonth = json.weekDayOfMonth;
    }
  }

  toJS(): RecurringPatternJson {
    return {
      type: this.type,
      endDate: this.endDate?.toISOString(),
      maxOccurrences: this.maxOccurrences,
      separationCount: this.separationCount,
      daysOfWeek: this.daysOfWeek,
      weekOfMonth: this.weekOfMonth,
      dayOfMonth: this.dayOfMonth,
      monthOfYear: this.monthOfYear,
      nthDayOfTheMonth: this.nthDayOfTheMonth,
      weekDayOfMonth: this.weekDayOfMonth,
    };
  }

  calculatePossibleDates(startDateTime: Dayjs): Dayjs[] {
    const dates = [startDateTime];
    const endDate = (this.endDate ? dayjs(this.endDate) : undefined) ?? startDateTime.add(5, 'year');
    let currentDate = startDateTime;
    if (this.type === 'daily') {
      while (!currentDate.isAfter(endDate) && dates.length < (this.maxOccurrences ?? 1000)) {
        const nextDate = currentDate.add(this.separationCount, 'day');
        dates.push(nextDate.hour(startDateTime.hour()).minute(startDateTime.minute()));
        currentDate = nextDate;
      }
    } else if (this.type === 'weekly') {
      while (!currentDate.isAfter(endDate) && dates.length < (this.maxOccurrences ?? 1000)) {
        const endOfWeek = currentDate.endOf('isoWeek');
        this.daysOfWeek
          .map((day) => IsoDayOfWeekList.indexOf(day) + 1)
          .map((idx) => currentDate.isoWeekday(idx))
          .filter((date) => date.isAfter(startDateTime) && !date.isAfter(endOfWeek) && !date.isAfter(endDate))
          .forEach((date) => dates.push(date.hour(startDateTime.hour()).minute(startDateTime.minute())));
        currentDate = currentDate.add(this.separationCount, 'week').startOf('isoWeek');
      }
    } else if (this.type === 'monthly') {
      while (!currentDate.isAfter(endDate) && dates.length < (this.maxOccurrences ?? 1000)) {
        if (this.dayOfMonth) {
          const nextDate = currentDate.add(this.separationCount, 'month').date(this.dayOfMonth);
          dates.push(nextDate);
          currentDate = nextDate;
        } else if (this.nthDayOfTheMonth && this.weekDayOfMonth) {
          const nextDates = ((start: Dayjs, nthDayOfTheMonth: NthDayOfMonth, weekDayOfMonth: DayOfWeek) => {
            let startOfMonth = start.startOf('month');
            const possibleDates: Dayjs[] = [];
            const endOfMonth = startOfMonth.endOf('month');

            while (!startOfMonth.isAfter(endOfMonth)) {
              const nextDate = startOfMonth.isoWeekday(IsoDayOfWeekList.indexOf(weekDayOfMonth) + 1);
              if (!nextDate.isAfter(endOfMonth) && !nextDate.isBefore(startOfMonth)) {
                possibleDates.push(nextDate);
              }
              startOfMonth = startOfMonth.add(1, 'week').startOf('isoWeek');
            }

            const result = ((nthDayOfTheMonth: NthDayOfMonth, result: Dayjs[]) => {
              switch (nthDayOfTheMonth) {
                case 'every':
                  return result;
                case 'first':
                  return [result[0]];
                case 'second':
                  return [result[1]];
                case 'third':
                  return [result[2]];
                case 'fourth':
                  return [result[3]];
                default:
                  return [result[result.length - 1]].filter(notUndefined);
              }
            })(nthDayOfTheMonth, possibleDates);

            return result.filter((date) => !date.isBefore(start));
          })(currentDate, this.nthDayOfTheMonth, this.weekDayOfMonth);

          nextDates
            .map((nextDate) => nextDate.hour(startDateTime.hour()).minute(startDateTime.minute()))
            .forEach((date) => dates.push(date));

          currentDate = currentDate.add(this.separationCount, 'month').startOf('month');
        } else {
          currentDate = endDate.add(1, 'day');
        }
      }
    }

    return dates.sort((a, b) => a.valueOf() - b.valueOf());
  }
}
