/**
 *
 * Created by neo on 03.01.17.
 */

import { toJS, observable, computed } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Athlete } from './Athlete';
import { PersonalDetails } from '../Person/PersonDetails';

export class AthleteSignup extends Athlete {
  @observable
  email: string = '';
  @observable
  password: string = '';
  // @observable personalDetails: PersonalDetails = new PersonalDetails();

  constructor(json?: any) {
    super(json);
    if (json) {
      this.email = json.email;
      this.password = json.password;
      // this.personalDetails = new PersonalDetails(json.personalDetails);
    }
  }

  @computed
  get personalDetails(): PersonalDetails {
    return new PersonalDetails({
      firstname: this.firstname,
      lastname: this.lastname,
      gender: this.gender,
      birthYear: this.birthYear,
    });
  }

  async signup(): Promise<Athlete | undefined> {
    const json: any = toJS(this);
    json.personalDetails = toJS(this.personalDetails);
    delete json.user;
    const res = await HttpBackend.post(`/athlete/signup`, json);
    if (res) {
      return new Athlete(res);
    }
    return undefined;
  }
}
