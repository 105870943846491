/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { ExerciseVariationAdminTableRow, ExerciseVariationAdminTableRowProps } from './ExerciseVariationAdminTableRow';
import { SortingDirection } from '../../../../Components/Table/SortableHeadColumn';
import { ExerciseVariationAdmin } from '../../../../Model/Exercise/Admin/ExerciseVariationAdmin';
import { SortableTableHead, SortableTableHeadColumnEntry } from '../../../../Components/Table/SortableTableHead';
import { Route, useParams } from 'react-router-dom';
import { ExerciseEditModal } from '../View/VariationsTab/ExerciseEditModal';
import { Routes, useNavigate } from 'react-router';

const columns: SortableTableHeadColumnEntry[] = [
  { columnKey: 'image', label: 'Image' },
  { columnKey: 'defaultName', label: 'Name', sortable: true },
  { columnKey: 'equipment', label: 'Equipment' },
  { columnKey: 'bodyParts', label: 'Body Parts' },
  { columnKey: 'tags', label: 'Tags', sortable: false },
  { columnKey: 'trackingKeys', label: 'Tracking Keys' },
  { columnKey: 'attributes', label: 'Attributes' },
  { columnKey: 'type', label: 'Type', sortable: true },
  { columnKey: 'test', label: 'Has Media Rect', sortable: false },
  { columnKey: 'actions', label: '' },
];

export type ExerciseVariationAdminTableProps = Omit<ExerciseVariationAdminTableRowProps, 'onClick' | 'variation'> & {
  variations: ExerciseVariationAdmin[];
  onSort?: (columnKey?: string, direction?: SortingDirection) => any;
  query?: string;
};

export const ExerciseVariationAdminTable: React.FC<ExerciseVariationAdminTableProps> = observer(
  ({ variations, onRemove, query, onSort, onCopy }) => {
    const { gymId } = useParams<{ gymId?: string }>();
    const history = useNavigate();

    const handleClick = React.useCallback(
      (variation: ExerciseVariationAdmin) => {
        if (gymId) {
          history(`${variation.id}?sourceType=gym&sourceId=${gymId}`);
        } else {
          history(`${variation.id}`);
        }
      },
      [history, gymId],
    );

    return (
      <React.Fragment>
        <Table hover striped size="sm" className="pt-2" style={{ marginBottom: '100px' }}>
          <SortableTableHead className="thead-inverse" columns={columns} onSort={onSort} />
          <tbody>
            {variations.map((exercise) => (
              <ExerciseVariationAdminTableRow
                key={exercise.id}
                variation={exercise}
                query={query}
                onClick={handleClick}
                onRemove={onRemove}
                onCopy={onCopy}
              />
            ))}
          </tbody>
        </Table>
        <Routes>
          <Route path=":exerciseId" element={<ExerciseEditModal />} />
        </Routes>
      </React.Fragment>
    );
  },
);
