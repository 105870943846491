/**
 * Created by neo on 20.09.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { EarnModelTemplateRuleSimple } from '../../../../../Model/Engagement/EarnModelTemplateRuleSimple';
import { EarnModelTemplateRuleStepped } from '../../../../../Model/Engagement/EarnModelTemplateRuleStepped';
import { EarnModelTemplateRuleInstant } from '../../../../../Model/Engagement/EarnModelTemplateRuleInstant';
import { Col, Row } from 'reactstrap';
import { EarnModelRuleActivationLogicEntry } from '../../../../../Model/Engagement/EarnModelRuleActivationLogicEntry';
import { Button, Form, Input, Select } from 'antd';
import { runInAction } from 'mobx';
import { EarnModelRuleActivationLogicValueCriteria } from '../../../../../Model/Engagement/EarnModelRuleActivationLogicValueCriteria';

export type ActivationLogicValueCriteriaEditorProps = {
  rule: EarnModelTemplateRuleSimple | EarnModelTemplateRuleStepped | EarnModelTemplateRuleInstant;
  entry: EarnModelRuleActivationLogicEntry;
};

export const ActivationLogicValueCriteriaEditor: React.FC<ActivationLogicValueCriteriaEditorProps> = observer(
  ({ rule, entry }) => {
    const handleAdd = React.useCallback(
      () => runInAction(() => (entry.valueCriteria = new EarnModelRuleActivationLogicValueCriteria())),
      [entry],
    );

    const handleRemove = React.useCallback(() => runInAction(() => (entry.valueCriteria = undefined)), [entry]);

    const handleChangeField = React.useCallback(
      ({ target: { value } }) =>
        runInAction(() => {
          if (entry.valueCriteria) {
            entry.valueCriteria.field = value.trim();
          }
        }),
      [entry],
    );

    const handleChangeValue = React.useCallback(
      ({ target: { value } }) =>
        runInAction(() => {
          if (entry.valueCriteria) {
            entry.valueCriteria.value = value.trim();
          }
        }),
      [entry],
    );

    const handleChangeMatch = React.useCallback(
      (value) =>
        runInAction(() => {
          if (entry.valueCriteria) {
            entry.valueCriteria.match = value.trim();
          }
        }),
      [entry],
    );

    return (
      <Row>
        {entry.valueCriteria ? (
          <React.Fragment>
            <Col>
              <Form.Item label="Field" extra="Field from payload (data)">
                <Input value={entry.valueCriteria.field} onChange={handleChangeField} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Value" extra="Value that the field should be matched against">
                <Input value={entry.valueCriteria.value} onChange={handleChangeValue} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Match Type" extra="How should the value be compared">
                <Select value={entry.valueCriteria.match} onChange={handleChangeMatch}>
                  <Select.Option value="exact">Exact</Select.Option>
                  <Select.Option value="anyValue">Any Value</Select.Option>
                  <Select.Option value="notEquals ">Not Equals</Select.Option>
                  <Select.Option value="startsWith">Starts With</Select.Option>
                  <Select.Option value="endsWith">Ends With</Select.Option>
                  <Select.Option value="contains">Contains</Select.Option>
                  <Select.Option value="gt">Greater Than</Select.Option>
                  <Select.Option value="gte">Greater Than Equals</Select.Option>
                  <Select.Option value="lt">Less Than</Select.Option>
                  <Select.Option value="lte">Less Than Equals</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Button block ghost danger onClick={handleRemove}>
                Remove Value Criteria
              </Button>
            </Col>
          </React.Fragment>
        ) : (
          <Col>
            <Button block onClick={handleAdd}>
              Add Value Criteria
            </Button>
          </Col>
        )}
      </Row>
    );
  },
);
