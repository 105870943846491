import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';

/**
 * Created by neo on 14.04.21.
 */

export type RecipeEquipmentHeaderJson = {
  id: string;
  temperatureCelsius?: number;
};

export class RecipeEquipmentHeader {
  @observable
  id: string = UUID();
  @observable
  temperatureCelsius?: number;

  constructor(json?: Partial<RecipeEquipmentHeaderJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.temperatureCelsius = json.temperatureCelsius;
    }
  }

  toJS(): RecipeEquipmentHeaderJson {
    return {
      id: this.id,
      temperatureCelsius: this.temperatureCelsius,
    };
  }
}
