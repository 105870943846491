import { DefaultStageNodeModel } from '../../Default/DefaultStageNodeModel';
import { InStage } from '../../../../../../../../Model/Coach/Stages/Logical/InStage';

export class InNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: InStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'logical:in' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
