/**
 *
 * Created by neo on 08.02.17.
 */

import { observable, computed, action, runInAction } from 'mobx';
import { BackendFactory } from '../../Services/Http/BackendFactory';
import { PageResult } from '../PageResult';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { MediaSize, MediaSizeJson } from './MediaSize';
import { v4 as UUID } from 'uuid';
import { MediaOverlay, MediaOverlayJson } from './MediaOverlay';
import { MediaAudioTrack, MediaAudioTrackJson } from './MediaAudioTrack';
import { MediaTextTrack, MediaTextTrackJson } from './MediaTextTrack';
import { MediaVideoTrack, MediaVideoTrackJson } from './MediaVideoTrack';
import { MediaLoop, MediaLoopJson } from './MediaLoop';
import { Audited, AuditedJson } from '../Audited';
import { MediaVisibleRect, MediaVisibleRectJson } from './MediaVisibleRect';
import { MediaInfo } from './MediaInfo';

export type MediaJson = AuditedJson & {
  language?: string;
  mediaType: string;
  name?: string;
  description?: string;
  url: string;
  size?: number;
  sizes: MediaSizeJson[];
  mediaInfo: MediaInfo;
  overlay: MediaOverlayJson[];
  loops: MediaLoopJson[];
  audioTracks: MediaAudioTrackJson[];
  textTracks: MediaTextTrackJson[];
  videoTracks: MediaVideoTrackJson[];
  usedCount: number;
  visibleRect?: MediaVisibleRectJson;
  aiContent: boolean;
};

export class Media extends Audited {
  @observable
  language?: string;
  @observable
  mediaType: string = 'unknown';
  @observable
  name?: string = undefined;
  @observable
  description?: string = undefined;
  @observable
  url: string = '';
  @observable
  size?: number = undefined;
  @observable
  sizes: MediaSize[] = [];
  @observable
  mediaInfo: MediaInfo = {};
  @observable
  overlay: MediaOverlay[] = [];
  @observable
  loops: MediaLoop[] = [];
  @observable
  audioTracks: MediaAudioTrack[] = [];
  @observable
  textTracks: MediaTextTrack[] = [];
  @observable
  videoTracks: MediaVideoTrack[] = [];
  @observable
  usedCount = 0;
  @observable
  visibleRect?: MediaVisibleRect;
  @observable
  aiContent = false;

  constructor(json?: Partial<MediaJson>) {
    super(json);
    if (json) {
      this.language = json.language;
      this.mediaType = json.mediaType || 'unknown';
      this.name = json.name;
      this.description = json.description;
      this.url = json.url || '';
      this.size = json.size;
      this.sizes = (json.sizes || []).map((s) => new MediaSize(s));
      this.mediaInfo = json.mediaInfo ?? {};
      this.overlay = (json.overlay ?? [])
        .map((o) => new MediaOverlay(o))
        .sort((a, b) => a.startSeconds - b.startSeconds);
      this.loops = (json.loops ?? []).map((o) => new MediaLoop(o)).sort((a, b) => a.startSeconds - b.startSeconds);
      this.audioTracks = (json.audioTracks ?? []).map((t) => new MediaAudioTrack(t));
      this.textTracks = (json.textTracks ?? []).map((t) => new MediaTextTrack(t));
      this.videoTracks = (json.videoTracks ?? []).map((t) => new MediaVideoTrack(t));
      this.usedCount = json.usedCount ?? 0;
      this.visibleRect = json.visibleRect ? new MediaVisibleRect(json.visibleRect) : undefined;
      this.aiContent = json.aiContent ?? false;
    }
  }

  toJS(newId: boolean = false): MediaJson {
    return Object.assign(super.toJS(newId), {
      language: this.language,
      mediaType: this.mediaType,
      name: this.name,
      description: this.description,
      url: this.url,
      size: this.size,
      sizes: this.sizes ? this.sizes.map((s) => s.toJS()) : [],
      mediaInfo: this.mediaInfo,
      overlay: this.overlay.map((o) => o.toJS()),
      loops: this.loops.map((o) => o.toJS()),
      audioTracks: this.audioTracks.map((t) => t.toJS()),
      textTracks: this.textTracks.map((t) => t.toJS()),
      videoTracks: this.videoTracks.map((t) => t.toJS()),
      usedCount: this.usedCount,
      visibleRect: this.visibleRect?.toJS(),
      aiContent: this.aiContent,
    });
  }

  @action
  addAudioTrack(): MediaAudioTrack {
    const newTrack = new MediaAudioTrack({ index: this.audioTracks.length });
    this.audioTracks.push(newTrack);
    return newTrack;
  }

  save(): Promise<Media> {
    return HttpBackend.put(`/media/media/${this.id}`, this.toJS()).then(() => this);
  }

  delete(): Promise<Media> {
    return HttpBackend.delete(`/media/media/${this.id}`).then(() => this);
  }

  mixAudioPerTrack(): Promise<Media[]> {
    if (this.audioTracks.length > 0) {
      return Promise.all(
        this.audioTracks.map((track) =>
          HttpBackend.post(
            `/media/media/mixAudio`,
            Object.assign(this.toJS(true), { language: track.language, audioTracks: [track] }),
          ).then((res) => new Media(Object.assign(res, { audioTracks: this.audioTracks }))),
        ),
      );
    }
    return Promise.resolve([this]);
  }

  enqueueConvert(): Promise<Media> {
    return HttpBackend.post(`/media/media/${this.id}/enqueue-convert`, this.toJS()).then(() => this);
  }

  @computed
  get medium(): string {
    return this.sizes.find((s) => s.size === 'medium')?.url ?? this.smallest;
  }

  @computed
  get smallest(): string {
    if (this.sizes && this.sizes.length > 0) {
      const small = this.sizes.find((s) => s.size === 'small');
      if (small) {
        return small.url;
      }
      const medium = this.sizes.find((s) => s.size === 'medium');
      return medium ? medium.url : this.sizes[0].url;
    }
    return this.url;
  }

  @computed
  get isVideo(): boolean {
    return this.mediaType.startsWith('video');
  }

  static async load(entityType: string, entityId: string): Promise<Array<Media>> {
    const backend = await BackendFactory();
    const res = await backend.get(`/media/media/${entityType}/${entityId}`);
    if (res) {
      return res.map((m) => new Media(m));
    }
    return [];
  }

  static async list(uid: string): Promise<Media[]> {
    const res = await HttpBackend.get(`/media/${uid}/medias`);
    if (res) {
      return res.map((m) => new Media(m));
    }
    return [];
  }

  static find(params?: any): Promise<Media[]> {
    return HttpBackend.get('/media/media', params).then((res) => res.map((m) => new Media(m)));
  }

  static count(params?: any): Promise<number> {
    return HttpBackend.get('/media/media/count', params);
  }

  static async get(id: string): Promise<Media> {
    const res = await HttpBackend.get(`/media/media/findOne/${id}`);
    return res && new Media(res);
  }
}
