import { Stage } from './Stage';
import { EmptySignal } from '../Signal/EmptySignal';

export class NoopStage extends Stage<any> {
  process() {
    this.processNext(new EmptySignal());
  }

  get type(): string {
    return 'noop';
  }
}
