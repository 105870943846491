/**
 *
 * Created by neo on 18.01.17.
 */

import { action, observable, computed, toJS, ObservableMap } from 'mobx';
import { ExerciseBlock } from './ExerciseBlock';
import { AbstractSet } from './AbstractSet';

export class ExerciseBlockSet extends AbstractSet {
  @observable
  exerciseBlock?: ExerciseBlock = undefined;

  constructor(exerciseBlock?: ExerciseBlock, source?: any) {
    super(source);
    this.exerciseBlock = exerciseBlock;
  }

  toJS(): any {
    return {
      values: toJS(this.values),
      updateTimestap: this.updateTimestamp,
    };
  }

  calculateCalories(bmr24: number): number {
    const met = this.exerciseBlock ? this.exerciseBlock.exercise.met || 5.5 : 5.5;
    const timeInHours = Math.max(1, this.plannedDurationMs / 1000.0) / 3600.0;
    return Math.max(1, Math.round(bmr24 * (met || 5.5) * timeInHours));
  }

  /**
   * Includes breakTime if there is a next set
   */
  @computed
  get totalDuration(): number {
    return this.plannedDurationMs + (this.nextSet ? this.breakTimeMs : 0);
  }

  @computed
  get index(): number {
    return this.exerciseBlock ? this.exerciseBlock.sets.findIndex((s) => s === this) : -1;
  }

  @computed
  get isLastSet(): boolean {
    if (this.exerciseBlock) {
      const { index } = this;
      return index + 1 === this.exerciseBlock.sets.length;
    }
    return false;
  }

  @computed
  get prevSet(): ExerciseBlockSet | undefined {
    if (this.exerciseBlock) {
      const { index } = this;
      if (0 === index) {
        return this.exerciseBlock.prevBlock?.prevSet;
      }
      return this.exerciseBlock.sets[index - 1];
    }
    return undefined;
  }

  @computed
  get nextSet(): ExerciseBlockSet | undefined {
    if (this.exerciseBlock) {
      const { index, isLastSet } = this;
      if (isLastSet) {
        return this.exerciseBlock.nextBlock?.followingSet;
      }
      const nextIndex = index + 1;
      return nextIndex < this.exerciseBlock.sets.length ? this.exerciseBlock.sets[nextIndex] : undefined;
    }
    return undefined;
  }
}
