/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { GymChallenge } from '../../../../../Model/GymChallenge/GymChallenge';
import { Link } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { GymChallengeParticipant } from '../../../../../Model/GymChallenge/GymChallengeParticipant';

export type GymChallengeTableRowProps = {
  challenge: GymChallenge;
};

export const GymChallengeTableRow: React.FC<GymChallengeTableRowProps> = observer(({ challenge }) => {
  const [participantCount, setParticipantCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () =>
        GymChallengeParticipant.count({ challengeId: challenge.id, gymId: challenge.gymId }).then((result) =>
          setParticipantCount(result),
        ),
      5000,
    );
    return () => clearInterval(interval);
  }, [challenge]);

  return (
    <tr>
      <td>
        <Link to={`${challenge.id}`}>{challenge.id}</Link>
      </td>
      <td>{challenge.defaultName}</td>
      <td>{dayjs(challenge.startDateTime).format('DD.MM.YYYY HH:mm')}</td>
      <td>{dayjs(challenge.endDateTime).format('DD.MM.YYYY HH:mm')}</td>
      <td>{challenge.timezone}</td>
      <td>
        {challenge.totalPoints} / {challenge.pointsGoal}
      </td>
      <td>{participantCount}</td>
    </tr>
  );
});
