/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { OAuthScopeViewBasicInfoTab } from './OAuthScopeViewBasicInfoTab';
import { OAuthViewClientsTab } from './OAuthViewClientsTab';
import { OAuthScope } from '../../../../Model/OAuthScope';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';

export type OAuthScopeViewScreenProps = {};

export const OAuthScopeViewScreen: React.FC<OAuthScopeViewScreenProps> = observer(() => {
  const [scope, setScope] = useState(new OAuthScope());
  const [processing, setProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('basic');

  const history = useNavigate();

  const { id } = useParams();

  const isNew = id === 'create' || id === 'new';

  useEffect(() => {
    if (id && id !== 'create' && id !== 'new') {
      setProcessing(true);
      OAuthScope.get(id)
        .then((res) => setScope(res ?? new OAuthScope()))
        .finally(() => setProcessing(false));
    } else {
      setScope(new OAuthScope());
    }
  }, [id]);

  const handleSave = async () => {
    setProcessing(true);
    scope
      .save()
      .then(() => history(`/security/oauthScope/${scope.id}`, { replace: true }))
      .finally(() => setProcessing(false));
  };

  const toggleTab = (tab: string) => setActiveTab(tab);

  return (
    <Container>
      <Row>
        <Col>
          <h1>{isNew ? 'New Scope' : scope.name || '<no name>'}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleSave} disabled={processing}>
            {'Save'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'basic' })} onClick={() => toggleTab('basic')}>
                {'Basic Info'}
              </NavLink>
            </NavItem>
            {!isNew ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'clients' })}
                  onClick={() => toggleTab('clients')}
                >
                  {'OAuth Clients'}
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
          <TabContent activeTab={activeTab}>
            {activeTab === 'basic' ? (
              <TabPane tabId="basic">
                <OAuthScopeViewBasicInfoTab scope={scope} isNew={isNew} />
              </TabPane>
            ) : null}
            {activeTab === 'clients' ? (
              <TabPane tabId="clients">
                <OAuthViewClientsTab scope={scope} />
              </TabPane>
            ) : null}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
});
