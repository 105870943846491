/**
 * Created by katarinababic on 26/07/2021.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type ButtonElementProps = {
  component: NativeFeedComponent;
  lang?: string;
  isHover?: boolean;
};

export const ButtonElement: React.FC<ButtonElementProps> = observer(({ component, lang, isHover }) => {
  const { classes } = useStyles();
  const url = component.props.extraProps.get('link');

  const handleClick = React.useCallback(() => {
    if (typeof url === 'string') {
      window.open(url);
    }
  }, [url]);

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        className={isHover ? classes.hover : classes.button}
        onClick={handleClick}
      >
        {component.getText(lang ?? 'de') || component.type}
      </Button>
    </div>
  );
});

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '16px',
  },
  button: {
    fontFamily: 'Inter',
    backgroundColor: 'black',
    borderRadius: 16,
    width: '90%',
  },
  hover: {
    flex: 0.6,
    marginLeft: '8px',
    marginBottom: '16px',
    fontFamily: 'Inter',
    width: '60%',
    backgroundColor: 'black',
    borderRadius: 16,
  },
}));
