/**
 * Created by neo on 18.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { RecipeEditModalIngredientEntry, RecipeEditModalIngredientEntryProps } from './RecipeEditModalIngredientEntry';
import { Col, Row } from 'reactstrap';
import { RecipeIngredient } from '../../../../Model/Diet/Recipe/RecipeIngredient';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Form } from 'antd';
import AsyncSelect from 'react-select/async';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';

export type RecipeEditModalIngredientsProps = {
  recipe: Recipe;
};

export const RecipeEditModalIngredients: React.FC<RecipeEditModalIngredientsProps> = observer(({ recipe }) => {
  const handleRemove = React.useCallback(
    (ingredient: RecipeIngredient) => {
      runInAction(() => (recipe.ingredients = recipe.ingredients.filter((i) => i.id !== ingredient.id)));
    },
    [recipe],
  );

  const fetchObject = React.useCallback((query: string) => {
    return Ingredient.search({ query });
  }, []);

  const optionLabel = React.useCallback((obj) => obj.defaultName, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeObject = React.useCallback(
    (value?: Ingredient) => {
      if (value) {
        recipe.ingredients.push(new RecipeIngredient(value.toJS()));
      }
    },
    [recipe],
  );

  return (
    <React.Fragment>
      <Form layout="vertical" style={{ minHeight: 400 }}>
        <SingleColRow>
          <Form.Item label="Add Ingredient">
            <AsyncSelect
              cacheOptions
              defaultOptions
              isClearable={true}
              loadOptions={fetchObject as any}
              getOptionLabel={optionLabel}
              getOptionValue={optionValue as any}
              onChange={handleChangeObject as any}
            />
          </Form.Item>
        </SingleColRow>
        <Row>
          {recipe.ingredients.map((ingredient) => (
            <Col key={ingredient.id} md={6}>
              <RecipeEditModalIngredientEntry key={ingredient.id} ingredient={ingredient} onRemove={handleRemove} />
            </Col>
          ))}
        </Row>
      </Form>
    </React.Fragment>
  );
});
