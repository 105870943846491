import { Stage } from './Stage';
import { dataSignal } from '../Signal/DataSignal';
import { Pipeline } from '../Pipeline';

export interface InputSortResult {
  [key: string]: any[];
}

export class InputSortStage extends Stage<any> {
  result: InputSortResult = {};
  promise: Promise<any>;

  constructor(pipeline: Pipeline, json?: any) {
    super(pipeline, json, 'SYNC');
  }

  process() {
    this.inputs
      .sort((a, b) => Number(a.type.replace('in-', '')) - Number(b.type.replace('in-', '')))
      .flatMap((port) => port.takeAll().filter(dataSignal))
      .forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'inputSort';
  }
}
