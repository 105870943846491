import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { InputSortStage } from '../../../../../../../Model/Coach/Stages/InputSortStage';

export class InputSortNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: InputSortStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'inputSort' }));
    this.addInPort('in-0');
    this.addInPort('in-1', true);
    this.addInPort('in-2', true);
    this.addInPort('in-3', true);
    this.addInPort('in-4', true);
    this.addInPort('in-5', true);
    this.addOutPort('default');
  }
}
