/**
 * Created by katarinababic on 15.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { FolderContent } from '../../../Model/Media/FileStorage/FolderContent';

export type EditFolderDialogProps = {
  isOpen?: boolean;
  folder: FolderContent;
  onClose?: () => void;
};

export const EditFolderDialog: React.FC<EditFolderDialogProps> = observer(({ isOpen, folder, onClose }) => {
  const [name, setName] = React.useState(folder.displayName);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [processing, setProcessing] = React.useState(false);

  const handleNameChange = React.useCallback(({ target: { value } }) => {
    setName(value);
  }, []);

  const handleRenameFolder = React.useCallback(() => {
    setProcessing(true);
    FolderContent.renameFolder(folder.name, name)
      .then(() => {
        onClose?.();
      })
      .catch((e) => setErrorMessage(e.message))
      .finally(() => setProcessing(false));
  }, [folder, name, onClose]);

  const toggle = React.useCallback(() => {
    if (isOpen) {
      onClose?.();
    }
  }, [onClose]);

  const dismiss = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Create Folder</ModalHeader>
      <ModalBody>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <FormGroup>
          <Label>{'Add a name that describes this folder. Example: "Winter Kits"'}</Label>
          <Input type="text" onChange={handleNameChange} value={name} autoFocus />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {processing ? (
          <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </Col>
        ) : (
          <React.Fragment>
            <Button color="primary" onClick={handleRenameFolder}>
              Rename
            </Button>
            <Button color="secondary" onClick={dismiss}>
              Cancel
            </Button>
          </React.Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
});
