/**
 *
 * Created by neo on 20.01.17.
 */

import { toJS, observable, action, computed } from 'mobx';
import { HttpBackend } from '../Services/Http/HttpBackend';
import { PageResult } from './PageResult';

export class OAuthScope {
  @observable
  id: string = '';
  @observable
  newId?: string = undefined;
  @observable
  name: string = '';
  @observable
  description: string = '';
  @observable
  includes: Array<string> = [];

  constructor(json?: any) {
    if (json) {
      Object.assign(this, json);
      this.includes = json.includes || [];
    }
  }

  @computed
  get _isNew(): boolean {
    return !this.id;
  }

  @action
  remove() {
    return HttpBackend.delete(`/uaa/oauth/scope/${this.id}`);
  }

  @action
  save() {
    return HttpBackend.post(`/uaa/oauth/scope`, toJS(this)).then(() => this);
  }

  @computed
  get fullName(): string {
    if (this.name && this.name.length > 0) {
      return `${this.name} (${this.id})`;
    }
    return this.id;
  }

  static async get(id: string): Promise<OAuthScope | undefined> {
    const res = await HttpBackend.get(`/uaa/oauth/scope/${id}`);
    if (res) {
      return new OAuthScope(res);
    }
    return undefined;
  }

  static find(data: any = { page: 0, sort: 'name,ASC' }): Promise<OAuthScope[]> {
    return HttpBackend.get('/uaa/oauth/scope', data).then((res) => res.map((u) => new OAuthScope(u)));
  }

  static count(data: any = { page: 0, sort: 'name,ASC' }): Promise<number> {
    return HttpBackend.get('/uaa/oauth/scope/count', data);
  }
}
