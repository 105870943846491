export type TrackingKeyEntry = {
  key: string;
  defaultUnit?: string;
  // unlike defaultUnit siUnit is only set if there is a real SI unit (needed for conversion)
  siUnit?: string;
  imperialUnit?: string;
  precision?: number;
  imperialPrecision?: number;
  decimalNumber: boolean;
  icon: string;
  iconColor: string;
  iconContainerColor: string;
};

export type BaseTrackingKey =
  | 'REPETITIONS'
  | 'WEIGHT'
  | 'DURATION'
  | 'BREAK'
  | 'DISTANCE'
  | 'SPEED'
  | 'LEVEL'
  | 'CALORIES'
  | 'HEART_RATE'
  | 'WATTS'
  | 'STEPS'
  | 'STEPS_PER_MINUTE'
  | 'PACE';

export type TrackingKeysType = {
  [key in BaseTrackingKey]: TrackingKeyEntry;
};

export const TrackingKeys: TrackingKeysType = {
  REPETITIONS: {
    key: 'REPETITIONS',
    decimalNumber: false,
    icon: 'rotate-right',
    iconColor: '#011627',
    iconContainerColor: 'rgba(1, 22, 39, 0.15)',
  },
  WEIGHT: {
    key: 'WEIGHT',
    defaultUnit: 'kg',
    siUnit: 'kg',
    imperialUnit: 'lbs',
    decimalNumber: true,
    icon: 'dumbbell',
    precision: 0.01,
    imperialPrecision: 0.01,
    iconColor: '#4441D3',
    iconContainerColor: 'rgba(68, 65, 211, 0.15)',
  },
  DURATION: {
    key: 'DURATION',
    defaultUnit: 'min',
    siUnit: 'min',
    decimalNumber: false,
    icon: 'timer',
    iconColor: '#00c893',
    iconContainerColor: 'rgba(0, 200, 147, 0.15)',
  },
  BREAK: {
    key: 'BREAK',
    defaultUnit: 'min',
    siUnit: 'min',
    decimalNumber: false,
    icon: 'pause',
    iconColor: '#FF5722',
    iconContainerColor: 'rgba(255, 87, 34, 0.15)',
  },
  DISTANCE: {
    key: 'DISTANCE',
    defaultUnit: 'km',
    siUnit: 'km',
    imperialUnit: 'mi',
    decimalNumber: true,
    icon: 'map-marker-distance',
    iconColor: '#30292F',
    iconContainerColor: 'rgba(48, 41, 47, 0.15)',
  },
  SPEED: {
    key: 'SPEED',
    defaultUnit: 'km/h',
    siUnit: 'km/h',
    imperialUnit: 'mph',
    decimalNumber: true,
    icon: 'speedometer',
    iconColor: '#ff9f1c',
    iconContainerColor: 'rgba(255, 159, 28, 0.15)',
  },
  LEVEL: {
    key: 'LEVEL',
    defaultUnit: 'lvl',
    decimalNumber: false,
    icon: 'chart-line-variant',
    iconColor: '#007763',
    iconContainerColor: 'rgba(0, 119, 99, 0.15)',
  },
  CALORIES: {
    key: 'CALORIES',
    defaultUnit: 'kcal',
    decimalNumber: false,
    icon: 'fire',
    iconColor: '#EB1A41',
    iconContainerColor: 'rgba(235, 26, 65, 0.15)',
  },
  HEART_RATE: {
    key: 'HEART_RATE',
    defaultUnit: 'bpm',
    decimalNumber: false,
    icon: 'heart-pulse',
    iconColor: '#B084CC',
    iconContainerColor: 'rgba(176, 132, 204, 0.15)',
  },
  WATTS: {
    key: 'WATTS',
    defaultUnit: 'w',
    decimalNumber: true,
    icon: 'alpha-w',
    iconColor: '#1a5a51',
    iconContainerColor: 'rgba(65, 234, 212, 0.15)',
  },
  STEPS: {
    key: 'STEPS',
    decimalNumber: false,
    icon: 'run',
    iconColor: '#076282',
    iconContainerColor: 'rgba(7, 98, 130, 0.15)',
  },
  STEPS_PER_MINUTE: {
    key: 'STEPS_PER_MINUTE',
    defaultUnit: 'spm',
    decimalNumber: false,
    icon: 'run',
    iconColor: '#076282',
    iconContainerColor: 'rgba(7, 98, 130, 0.15)',
  },
  PACE: {
    key: 'PACE',
    defaultUnit: 'min/km',
    decimalNumber: false,
    icon: 'run',
    iconColor: '#076282',
    iconContainerColor: 'rgba(7, 98, 130, 0.15)',
  },
};

export type PlanableTrackingKey =
  | 'MIN_DURATION'
  | 'MAX_DURATION'
  | 'MIN_BREAK'
  | 'MAX_BREAK'
  | 'MIN_REPETITIONS'
  | 'MAX_REPETITIONS'
  | 'MIN_WEIGHT'
  | 'MAX_WEIGHT'
  | 'MIN_LEVEL'
  | 'MAX_LEVEL'
  | 'MIN_DISTANCE'
  | 'MAX_DISTANCE'
  | 'MIN_STEPS_PER_MINUTE'
  | 'MAX_STEPS_PER_MINUTE'
  | 'MIN_WATTS'
  | 'MAX_WATTS'
  | 'MIN_SPEED'
  | 'MAX_SPEED'
  | 'MIN_CALORIES'
  | 'MAX_CALORIES'
  | 'MIN_HEART_RATE'
  | 'MAX_HEART_RATE';

export type TrackingKey = BaseTrackingKey | PlanableTrackingKey;

export const availableTrackingKeys: Array<string> = [
  'MIN_DURATION',
  'MAX_DURATION',
  'MIN_BREAK',
  'MAX_BREAK',
  'MIN_REPETITIONS',
  'MAX_REPETITIONS',
  'MIN_WEIGHT',
  'MAX_WEIGHT',
  'MIN_LEVEL',
  'MAX_LEVEL',
  'MIN_DISTANCE',
  'MAX_DISTANCE',
  'MIN_STEPS_PER_MINUTE',
  'MAX_STEPS_PER_MINUTE',
  'MIN_WATTS',
  'MAX_WATTS',
  'MIN_SPEED',
  'MAX_SPEED',
  'MIN_CALORIES',
  'MAX_CALORIES',
  'MIN_HEART_RATE',
  'MAX_HEART_RATE',
];

export const TrackingKeysList: BaseTrackingKey[] = Object.keys(TrackingKeys) as BaseTrackingKey[];
