import * as React from 'react';
import { observer } from 'mobx-react';
import { SortableHeadColumnArrow } from './SortableHeadColumnArrow';
import { Link } from 'react-router-dom';

export type SortingDirection = 'ASC' | 'DESC';

export interface SortableHeadColumnProps {
  label: string;
  columnKey: string;
  sortable?: boolean;
  nowrap?: boolean;
  sortDirection?: SortingDirection;
  onClick?: (columnKey: string) => any;
}

@observer
export class SortableHeadColumn extends React.Component<SortableHeadColumnProps> {
  handlePress = () => {
    this.props.onClick && this.props.onClick(this.props.columnKey);
  };

  render() {
    const {
      props: { label, sortDirection, sortable, nowrap },
    } = this;
    return (
      <th>
        {sortable ? (
          <Link to="#" onClick={this.handlePress}>
            {label}
          </Link>
        ) : (
          <React.Fragment>{label}</React.Fragment>
        )}{' '}
        {sortable ? <SortableHeadColumnArrow sortDirection={sortDirection} /> : null}
      </th>
    );
  }
}
