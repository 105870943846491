export const Constants: any = {
  goldenRation: 1.61803398875,
  invertGoldenRation: 0.6180339887,
  MAX_INT: 4294967296,
};

export const EMPTY_ARRAY = [];

export const EMPTY_OBJECT = {};

export const EMPTY_STRING = '';

