import { computed, observable } from 'mobx';
import { Gender } from './Gender';
import dayjs, { Dayjs } from 'dayjs';

export type PersonalDetailsJson = {
  firstname?: string;
  lastname?: string;
  birthdate?: string;
  birthYear?: number;
  sex: Gender;
};

export class PersonalDetails {
  @observable
  firstname?: string;
  @observable
  lastname?: string;
  @observable
  birthYear?: number;
  @observable
  birthdate?: string;
  @observable
  sex: Gender = 'UNKNOWN';

  constructor(json?: any) {
    if (json) {
      this.firstname = json.firstname;
      this.lastname = json.lastname;
      this.birthdate = json.birthdate;
      this.birthYear = json.birthYear;
      this.sex = json.sex || 'UNKNOWN';
    }
  }

  toJS() {
    return {
      firstname: this.firstname,
      lastname: this.lastname,
      birthYear: this.yearOfBirth,
      sex: this.sex,
    };
  }

  @computed
  get age(): number {
    if (this.yearOfBirth) {
      return new Date().getFullYear() - this.yearOfBirth;
    }
    return 0;
  }

  @computed
  get yearOfBirth(): number | undefined {
    return this.birthYear ?? (this.birthdate ? dayjs(this.birthdate, 'YYYY-MM-DD').year() : undefined);
  }
}
