export class AxonSagaAssociation {
  key: string;
  value: string;

  constructor(json?: any) {
    if (json) {
      this.key = json.key;
      this.value = json.value;
    }
  }
}
