/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { DatePicker, Form } from 'antd';
import { Gym } from '../../../Model/Gym/Gym';
import { allSelectContent, gymSelectContent } from './Queries/querySelectContent';
import { SelectContentChart } from './SelectContentChart';

export type SelectContentDailyProps = {
  gym?: Gym;
};

export const SelectContentDaily: React.FC<SelectContentDailyProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    setData(undefined);
    AnalyticsData.query({
      query: gym ? gymSelectContent : allSelectContent,
      parameters: {
        start_date: {
          value: startDate.format('YYYYMMDD'),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
        gym_id: {
          value: gym?.id ?? '',
        },
      },
    }).then((data) => {
      const cats = new Array<string>();
      setData(
        data
          .reduce((agg, row) => {
            const existing = agg.find((r) => r.event_date === row.event_date);
            cats.push(row.value);
            if (existing) {
              existing[row.value] = row.user_count;
              existing[`${row.value}_select`] = row.select_count;
            } else {
              agg.push({
                event_date: row.event_date,
                [row.value]: row.user_count,
                [`${row.value}_select`]: row.select_count,
              });
            }
            return agg;
          }, new Array<AnalyticsRow>())
          .map((d) =>
            Array.from(Object.entries(d))
              .map(([key, value]) => [key, key === 'event_date' ? value : Number(value)])
              .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
          ),
      );
      setCategories([...new Set<string>(cats)]);
    });
  }, [gym, startDate, endDate]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  console.log('SelectContent', data, categories);

  return (
    <React.Fragment>
      <SingleColRow>
        <h5>Select Content Daily</h5>
      </SingleColRow>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <SelectContentChart data={data} categories={categories} />}</Col>
      </Row>
    </React.Fragment>
  );
});
