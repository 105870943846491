/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { MacroCycleLaneTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleLaneTemplate';
import { MesoCycleEditor } from './MesoCycleEditor/MesoCycleEditor';
import styled from '@emotion/styled';
import { SuperMacroMesoCycleTemplate } from '../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';
import { v4 as UUID } from 'uuid';

export type CyclesEditorProps = {
  macroCycleLane: MacroCycleLaneTemplate;
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AddNewMesoCycle = styled.button`
  border: 2px dashed lightgray;
  width: 200px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 2px dashed black;
  }
`;

export const CyclesEditor: React.FC<CyclesEditorProps> = observer(({ macroCycleLane }: CyclesEditorProps) => {
  const handleAdd = React.useCallback(() => {
    new SuperMacroMesoCycleTemplate({
      configurations: [
        {
          id: UUID(),
          includedTags: ['frequency:2'],
          configurations: [
            {
              id: UUID(),
              workoutDays: [
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      includedTags: [],
                      excludedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          includedTags: ['frequency:3'],
          configurations: [
            {
              id: UUID(),
              workoutDays: [
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          includedTags: ['frequency:4'],
          configurations: [
            {
              id: UUID(),
              workoutDays: [
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          includedTags: ['frequency:5'],
          configurations: [
            {
              id: UUID(),
              workoutDays: [
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
                {
                  id: UUID(),
                  workouts: [
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: [],
                    },
                    {
                      id: UUID(),
                      workoutTemplateId: '',
                      excludedTags: [],
                      includedTags: ['equipment:no'],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    })
      .save()
      .then((cycle) => {
        macroCycleLane.addCycle(cycle);
      });
  }, [macroCycleLane]);

  const handleRemove = React.useCallback(
    (mesoCycle) => {
      macroCycleLane.removeCycle(mesoCycle);
    },
    [macroCycleLane],
  );

  return (
    <Container>
      {macroCycleLane.cycles.map((mesoCycle, index) => (
        <MesoCycleEditor
          key={mesoCycle.id}
          mesoCycle={mesoCycle}
          onRemove={handleRemove}
          index={index}
          headerColor="#FFAD87"
        />
      ))}
      <AddNewMesoCycle onClick={handleAdd}>Add Meso Cycle</AddNewMesoCycle>
    </Container>
  );
});
