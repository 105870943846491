/**
 *
 * Created by neo on 25.02.17.
 */
import { computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';
import { DeeplService } from '../../Services/DeeplService';
import { LocalizedValue } from '../../Model/LocalizedValue';

const defaultLanguages = ['de', 'en', 'fr', 'it', 'es', 'cn', 'fi'];

export type Props = {
  entity: any;
  field: string;
  label?: string;
  type?: InputProps['type'];
  languages?: string[];
  disabled?: boolean;
};

@observer
export class TranslationInput extends React.Component<Props> {
  @observable
  adding = false;

  @computed
  get defaultField(): string {
    const {
      props: { field },
    } = this;
    const fieldCap = field.charAt(0).toUpperCase() + field.slice(1);
    return `default${fieldCap}`;
  }

  @computed
  get existingKeys(): string[] {
    return Array.from(this.props.entity[this.props.field].keys());
  }

  @computed
  get canAddTranslation(): boolean {
    return this.missingTranslations.length > 0;
  }

  @computed
  get missingTranslations(): string[] {
    return (this.props.languages || defaultLanguages).filter((l) => this.existingKeys.findIndex((k) => k === l) === -1);
  }

  @computed
  get valueMap(): Map<string, string> {
    return this.props.entity[this.props.field];
  }

  changeLang = (prevKey: string, newKey: string) => {
    const value = this.valueMap.get(prevKey);
    this.valueMap.delete(prevKey);
    value && this.valueMap.set(newKey, value);
  };

  changeValue = (key: string, value: string) => {
    this.valueMap.set(key, value);
  };

  handleAddTranslation = () => {
    const nextKey = this.missingTranslations[0];

    if (nextKey) {
      const sourceLang =
        this.valueMap.get('en') && nextKey !== 'en'
          ? 'en'
          : this.valueMap.get('de') && nextKey !== 'de'
          ? 'de'
          : undefined;
      const source = sourceLang ? this.valueMap.get(sourceLang) : undefined;
      if (source) {
        this.adding = true;
        DeeplService.translate({ text: source, sourceLanguage: sourceLang, targetLanguage: nextKey })
          .then((result) => this.valueMap.set(nextKey, result ?? ''))
          .finally(() => runInAction(() => (this.adding = false)));
      } else {
        this.valueMap.set(nextKey, '');
      }
    }
  };

  removeLang = (key: string) => {
    this.valueMap.delete(key);
  };

  render() {
    const {
      props: { entity, label, type, disabled },
    } = this;
    // console.log(`valueMap = `, this.valueMap);
    // console.log(`existingKeys = `, this.existingKeys);
    // console.log(`missingTranslations = `, this.missingTranslations);

    return (
      <Row>
        <Col>
          <FormGroup>
            <Label>{label}</Label>
            {this.canAddTranslation ? (
              <Button color="link" onClick={this.handleAddTranslation}>
                Add Translation
              </Button>
            ) : null}
          </FormGroup>
          {this.existingKeys.map((k: string) => (
            <Row key={k}>
              <Col xs="auto">
                <Input type="select" value={k} onChange={(e) => this.changeLang(k, e.target.value)} disabled={disabled}>
                  <option value={k}>{k.toUpperCase()}</option>
                  {this.missingTranslations.map((k1: string) => (
                    <option key={k1} value={k1}>
                      {k1.toUpperCase()}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col>
                <Input
                  type={type}
                  value={this.valueMap.get(k)}
                  disabled={disabled}
                  onChange={(e) => this.changeValue(k, e.target.value)}
                />
              </Col>
              <Col xs="auto">
                <Button color="danger" onClick={() => this.removeLang(k)} disabled={disabled}>
                  Remove
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    );
  }
}
