/**
 *
 * Created by neo on 18.01.17.
 */

import { action, observable, computed, onBecomeObserved } from 'mobx';
import { ExerciseSet } from './ExerciseSet';
import { WorkoutLog } from './index';
import { ExerciseId } from '../Exercise/ExerciseId';
import { Exercise } from '../Exercise/Exercise';

export class ExerciseBlockLog {
  workoutLog: WorkoutLog;
  @observable
  exercise?: Exercise = undefined;
  @observable
  exerciseId: ExerciseId;
  @observable
  sets: Array<ExerciseSet> = [];
  @observable
  phaseId?: string = undefined;
  @observable
  exerciseBlockId?: string = undefined;

  constructor(workoutLog: WorkoutLog, json?: any) {
    this.workoutLog = workoutLog;
    if (json) {
      this.phaseId = json.phaseId;
      this.exerciseBlockId = json.exerciseBlockId;
      this.exercise = json.exercise ? new Exercise(json.exercise) : undefined;
      this.exerciseId = ExerciseId.fromJson(json.exerciseId);

      this.sets = (json.sets || []).map((v: any) => new ExerciseSet(this, v));
    }

    onBecomeObserved(this, 'exercise', this.fetchVariation);
  }

  fetchVariation = async () => {
    if (this.exerciseId && !this.exercise) {
      this.exercise = await Exercise.get(
        this.exerciseId.variationId,
        this.exerciseId.sourceType,
        this.exerciseId.sourceId,
      );
    }
  };

  toJS(newId: boolean = false): any {
    return {
      workoutLogId: this.workoutLog.id,
      exercise: this.exercise?.toJS(),
      phaseId: this.phaseId,
      exerciseBlockId: this.exerciseBlockId,
      sets: this.sets.map((s) => s.toJS(newId)),
    };
  }

  @action
  addSet(setTemplate: any): ExerciseSet {
    const { lastSet } = this;
    if (lastSet && !lastSet.isStarted) {
      console.log('ExerciseBlockLog::addSet() returing lastSet');
      return lastSet;
    }
    console.log('ExerciseBlockLog::addSet() creating set');
    const set = new ExerciseSet(this, Object.assign(setTemplate, { workoutLogId: this.workoutLogId }));
    this.sets.push(set);
    return set;
  }

  @action
  removeLastSet() {
    const {
      sets: { length },
    } = this;
    const index = length - 1;
    if (index !== -1) {
      const set = this.sets[index];
      if (!set.isStarted) {
        this.sets.splice(index, 1);
      }
    }
  }

  @computed
  get workoutLogId(): string {
    return this.workoutLog.id;
  }

  @computed
  get setCount(): number {
    return this.sets.length;
  }

  @computed
  get started(): boolean {
    return this.firstSet ? this.firstSet.isStarted : false;
  }

  // TODO this is crap somehow
  @computed
  get index(): number {
    const {
      sets: { length },
    } = this;
    for (let index = 0; index < length; index += 1) {
      if (!this.sets[index].isFinished) {
        return index;
      }
    }
    return length - 1;
  }

  @computed
  get blockIndex(): number {
    return this.workoutLog.exerciseBlocks.findIndex((b) => b === this);
  }

  @computed
  get firstSet(): ExerciseSet | undefined {
    if (this.sets.length > 0) {
      return this.sets[0];
    }
    return undefined;
  }

  @computed
  get lastSet(): ExerciseSet | undefined {
    return this.sets.find((s) => !s.isFinished) ?? (this.sets.length > 0 ? this.sets[this.sets.length - 1] : undefined);
  }

  @computed
  get prevSet(): ExerciseSet | undefined {
    if (!this.lastSet) {
      return this.prevBlockLog && this.prevBlockLog.prevSet;
    }
    return this.lastSet;
  }

  @computed
  get followingSet(): ExerciseSet | undefined {
    return this.firstSet || (this.nextBlockLog ? this.nextBlockLog.followingSet : undefined);
  }

  @computed
  get nextSet(): ExerciseSet | undefined {
    return this.firstSet || (this.nextBlockLog ? this.nextBlockLog.nextSet : undefined);
  }

  @computed
  get lastFinishedSet(): ExerciseSet | undefined {
    for (let i = this.sets.length - 1; i >= 0; i--) {
      if (this.sets[i] && this.sets[i].isFinished) {
        return this.sets[i];
      }
    }
    return undefined;
  }

  @computed
  get prevBlockLog(): ExerciseBlockLog | undefined {
    const { blockIndex } = this;
    if (blockIndex > 0) {
      return this.workoutLog.exerciseBlocks[blockIndex - 1];
    }
    return undefined;
  }

  @computed
  get nextBlockLog(): ExerciseBlockLog | undefined {
    const { blockIndex } = this;
    const nextIndex = blockIndex + 1;
    return nextIndex < this.workoutLog.exerciseBlocks.length ? this.workoutLog.exerciseBlocks[nextIndex] : undefined;
  }

  @computed
  get completedSetsCount(): number {
    return this.setCount - 1 + (this.lastSet && this.lastSet.isFinished ? 1 : 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.sets.reduce((result: number, set: ExerciseSet) => result + set.duration, 0);
  }

  @computed
  get totalCaloriesBurnt(): number {
    return this.sets.reduce((result: number, set: ExerciseSet) => result + set.caloriesBurnt, 0);
  }

  @computed
  get tons(): number {
    return this.sets.reduce((result: number, set: ExerciseSet) => result + set.tons, 0);
  }
}
