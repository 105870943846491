/**
 * Created by katarinababic on 16.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { ThemeConfig } from '../../../../../Config/Theme';

export type SummaryElementProps = {
  component: NativeFeedComponent;
  lang?: string;
};

export const SummaryElement: React.FC<SummaryElementProps> = observer(({ component, lang }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <p className={classes.text}>
        {component.getText(lang ?? 'de') ||
          'This is a summary text where you can summarize the most important parts of the previous or the following paragraphs'}
      </p>
    </div>
  );
});

const useStyles = makeStyles()(() => ({
  container: {
    borderLeft: `4px solid ${ThemeConfig.Colors.primaryColor}`,
    marginLeft: 16,
  },
  text: {
    paddingLeft: 8,
    paddingRight: 80,
    color: ThemeConfig.Colors.warmGrey,
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Inter',
    textAlign: 'left',
  },
}));
