export class MicroCycleConfiguration {
  constructor(json?: any) {
    if (json) {
    }
  }

  toJS() {
    return {};
  }
}
