import { LogicalStage } from './LogicalStage';
import { toJS } from 'mobx';
import { Signal } from '../../Signal/Signal';
import { DataSignal } from '../../Signal/DataSignal';
import objectPath from 'object-path';
import { StartSignal } from '../../Signal/StartSignal';

export class EqualsStage extends LogicalStage {
  canProceed(signal: Signal): boolean {
    if (signal instanceof DataSignal || signal instanceof StartSignal) {
      const object =
        this.config.source === 'input' && signal instanceof DataSignal ? signal.data : this.pipeline.context;
      const value = objectPath.get(object, this.config.attribute);
      const compareValue = this.getValue();
      console.log('logical:eq::canProceed', this.config.source, this.config.attribute, object, value, compareValue);
      return this.compareValues(value, compareValue);
    }
    return false;
  }

  toJS(): any {
    return super.toJS();
  }

  get type(): string {
    return 'logical:eq';
  }

  protected compareValues(value0: any, value1: any): boolean {
    const array0 = Array.isArray(value0) ? value0 : undefined;
    const array1 = Array.isArray(value1) ? value1 : undefined;

    console.log('logical:eq', toJS(array0), toJS(array1), toJS(value0), toJS(value1));

    if (array0) {
      if (array1) {
        return this.eqSet(array0, array1);
      }
      return array0.findIndex((e) => e === value1) !== -1;
    } else if (array1) {
      return array1.findIndex((e) => e === value0) !== -1;
    }
    return value0 === value1;
  }

  private eqSet(a?: Array<string>, b?: Array<string>) {
    if (a !== b) {
      if (a && b) {
        if (a.length === b.length) {
          if (a.length > 0) {
            const as = new Set(a);
            const bs = new Set(b);
            if (as.size !== bs.size) return false;
            for (const a of as) {
              if (!bs.has(a)) {
                return false;
              }
            }
          } else {
            return true;
          }
        }
      }
      return false;
    }
    return true;
  }
}
