/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Pipeline } from '../../../Model/Coach/Pipeline';
import { Button, Popconfirm, Space } from 'antd';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { WorkoutTemplate } from '../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';

export type PipelineTableRowProps = {
  pipeline: Pipeline;
  onRemove?: (pipeline: Pipeline) => any;
  onCopy?: (pipeline: Pipeline) => any;
};

export const PipelineTableRow: React.FC<PipelineTableRowProps> = observer(({ pipeline, onRemove, onCopy }) => {
  const [open, setOpen] = useState(false);
  const [otherPipelines, setOtherPipelines] = useState<Pipeline[]>([]);
  const [templates, setTemplates] = useState<WorkoutTemplate[]>([]);

  useEffect(() => {
    if (open) {
      Promise.all([
        Pipeline.list({ scriptId: pipeline.id }),
        WorkoutTemplate.list({ phaseScriptId: pipeline.id }),
      ]).then(([others, templates]) => {
        setOtherPipelines(others);
        setTemplates(templates);
      });
    }
  }, [pipeline, open]);

  const handleRemove = React.useCallback(() => {
    onRemove && onRemove(pipeline);
  }, [pipeline, onRemove]);

  const handleCopy = React.useCallback(() => {
    onCopy && onCopy(pipeline);
  }, [onCopy, pipeline]);

  const handleToggleOpen = React.useCallback(() => setOpen((o) => !o), []);

  return (
    <React.Fragment>
      <tr onClick={handleToggleOpen}>
        <td>
          <Link to={`/coach/workout-script/${pipeline.id}`}>{pipeline.name || '<no name>'}</Link>
        </td>
        <td>{pipeline.tags.sort((a, b) => a.localeCompare(b)).join(', ')}</td>
        <td>
          <Space>
            <Popconfirm title={`Delete Pipeline ${pipeline.name}?`} onConfirm={handleRemove}>
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
            <Button type="dashed" onClick={handleCopy}>
              Copy
            </Button>
          </Space>
        </td>
      </tr>
      {open && (
        <tr>
          <td></td>
          <td colSpan={2}>
            <Row>
              <Col>
                {otherPipelines.map((other) => (
                  <div key={other.id}>
                    <Link to={`/coach/workout-script/${other.id}`}>{other.name}</Link>
                  </div>
                ))}
              </Col>
              <Col>
                {templates.map((template) => (
                  <div key={template.id}>
                    <Link to={`/coach/workout-template/${template.id}`}>{template.defaultName}</Link>
                  </div>
                ))}
              </Col>
            </Row>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
});
