/**
 * Created by neo on 08.05.21.
 */
import { CoachTts, CoachTtsJson, CoachTtsQueryRequest } from './CoachTts';
import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type CoachTtsGroupedResultJson = {
  identifier: string;
  entries: CoachTtsJson[];
};

export class CoachTtsGroupedResult {
  @observable
  identifier = UUID();
  @observable
  entries: CoachTts[] = [];

  constructor(json?: Partial<CoachTtsGroupedResultJson>) {
    if (json) {
      this.identifier = json.identifier ?? '';
      this.entries = (json.entries ?? []).map((e) => new CoachTts(e));
    }
  }

  static find(request: CoachTtsQueryRequest): Promise<CoachTtsGroupedResult[]> {
    return HttpBackend.get(`/coach/text-to-speech/admin/grouped`, request).then((res) =>
      res.map((t) => new CoachTtsGroupedResult(t)),
    );
  }

  static count(request: CoachTtsQueryRequest): Promise<number> {
    return HttpBackend.get(`/coach/text-to-speech/admin/grouped/count`, request);
  }
}
