/**
 * Created by neo on 23.11.20.
 */
import { observable, toJS } from 'mobx';
import { DayOfWeek } from '../../DayOfWeek';
import { TimeRange } from '../../Gym/TimeRange';

export type ScheduleEntries = {
  [key in DayOfWeek]?: TimeRange[];
};

export type ProgramScheduleJson = {
  entries: ScheduleEntries;
};

export class ProgramSchedule {
  @observable
  entries: ScheduleEntries = {};

  constructor(json?: Partial<ProgramScheduleJson>) {
    if (json) {
      this.entries = json.entries ?? {};
    }
  }

  toJS(): ProgramScheduleJson {
    return {
      entries: toJS(this.entries),
    };
  }
}
