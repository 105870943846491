/**
 * Created by neo on 28.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { PipelineContext } from '../../../../../Model/Coach/PipelineContext';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'antd';
import { PipelineStage } from '../../../../../Model/Coach/Stages/PipelineStage';
import { FormGroup, Label } from 'reactstrap';
import { PipelineStageEditorParam } from './PipelineStageEditorParam';

export type PipelineStageEditorProps = {
  stage: PipelineStage;
  context?: PipelineContext;
};

export const PipelineStageEditor: React.FC<PipelineStageEditorProps> = observer((props: PipelineStageEditorProps) => {
  const { stage } = props;

  const store = useLocalStore(
    (source: PipelineStageEditorProps) => ({
      get value() {
        return (stage.config.tags || []).map((e) => ({ value: e, label: e }));
      },
    }),
    props,
  );

  const handleChange = (tags: any[]) => {
    stage.config.tags = (tags ?? []).map((e) => (e.value ? e.value : e));
  };

  return (
    <Form layout="vertical">
      <FormGroup>
        <Label>Tags</Label>
        <CreatableSelect value={store.value} isClearable isMulti onChange={handleChange as any} />
      </FormGroup>
      {stage.innerPipeline?.params.map((param) => (
        <PipelineStageEditorParam key={param.name} stage={stage} param={param} />
      ))}
    </Form>
  );
});
