import { Stage } from './Stage';
import objectPath from 'object-path';
import { DataSignal, dataSignal } from '../Signal/DataSignal';
import { notUndefined } from '../../../Utils/notUndefined';

export interface DistinctStageConfig {
  attribute: string;
  tagCategory?: string;
}

export class DistinctStage extends Stage<DistinctStageConfig> {
  processed = new Map<any, boolean>();

  process() {
    this.dataSignals
      .map((signal) => this._process(signal))
      .filter(notUndefined)
      .forEach((signal) => this.processNext(signal));
  }

  private processTagCategory(tagCategory: string, signal: DataSignal) {
    const item = signal.data;
    const value =
      (item.tags ?? [])
        .find((t) => t.startsWith(tagCategory))
        ?.replace(`${tagCategory}:`)
        .trim() ?? 'undefined';

    if (!this.processed.has(value)) {
      // console.log('add distinct', value);
      this.processed.set(value, item); // set any value to Map
      return new DataSignal(item, signal.index);
    } else {
      // console.log('ignore distinct', value);
    }
    return undefined;
  }

  private processDefault(signal: DataSignal) {
    const item = signal.data;
    let value = objectPath.get(item, this.config.attribute);
    if (Array.isArray(value)) {
      value = value.join(',').trim();
    } else if (typeof value === 'string') {
      value = value.trim();
    }
    if (!this.processed.has(value)) {
      this.processed.set(value, item); // set any value to Map
      return new DataSignal(item);
    }
    return undefined;
  }

  private _process(signal: DataSignal): any {
    if (this.config.attribute === 'tagCategory' && this.config.tagCategory) {
      return this.processTagCategory(this.config.tagCategory, signal);
    }
    return this.processDefault(signal);
  }

  get type(): string {
    return 'distinct';
  }
}
