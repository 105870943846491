/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { DatePicker, Form, message, Modal, Select, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { CampaignCalendarEntry } from '../../../../Model/CampaignCalendar/CampaignCalendarEntry';
import { useNavigate } from 'react-router';
import { CampaignCalendarEntryModalBasicTab } from './CampaignCalendarEntryModalBasicTab';
import { CampaignCalendarEntryModalFilesTab } from './CampaignCalendarEntryModalFilesTab';

export type CampaignCalendarEntryModalProps = {
  onSave?: () => void;
};

export const CampaignCalendarEntryModal: React.FC<CampaignCalendarEntryModalProps> = observer(({ onSave }) => {
  const { campaignId } = useParams<{ campaignId }>();

  const history = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [campaign, setCampaign] = useState(new CampaignCalendarEntry());
  const [activeTab, setActiveTab] = useState('basic');

  const isNew = campaignId === 'new' || campaignId === 'create';

  useEffect(() => {
    if (campaignId && campaignId !== 'new' && campaignId !== 'create') {
      CampaignCalendarEntry.findOne(campaignId).then((result) => setCampaign(result));
    } else {
      setCampaign(new CampaignCalendarEntry());
    }
  }, [campaignId]);

  const handleSave = () => {
    if (!processing) {
      setProcessing(true);
      campaign
        .save()
        .finally(() => setProcessing(false))
        .then(() => {
          message.success('Campaign saved');
        })
        .then(() => onSave?.())
        .then(() => history(-1));
    }
  };

  const handleClose = () => {
    history(-1);
  };

  const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

  return (
    <Modal
      title={isNew ? 'New Campaign' : campaign.defaultName}
      open={true}
      width={1920}
      onOk={handleSave}
      onCancel={handleClose}
      confirmLoading={processing}
    >
      <Form layout="vertical">
        <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
          <Tabs.TabPane tab="Basic" key="basic">
            <CampaignCalendarEntryModalBasicTab campaign={campaign} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Files" key="files">
            <CampaignCalendarEntryModalFilesTab campaign={campaign} />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
});
