/**
 * Created by neo on 04.04.23.
 */
import { Customer } from '../../Customer/Customer';
import { Pageable } from '../../Pageable';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { observable } from 'mobx';

export type CustomerGroupMemberQueryRequest = Pageable & {
  query: string;
  gymId: string;
};

export type CustomerGroupMemberJson = {
  customerGroupId: string;
  customer: any;
};

export class CustomerGroupMember {
  @observable
  customerGroupId = '';

  @observable
  customer = new Customer();

  constructor(json?: Partial<CustomerGroupMemberJson>) {
    if (json) {
      this.customerGroupId = json.customerGroupId ?? '';
      this.customer = new Customer(json.customer);
    }
  }

  static find(
    customerGroupId: string,
    gymId: string,
    request?: Partial<CustomerGroupMemberQueryRequest>,
  ): Promise<CustomerGroupMember[]> {
    return HttpBackend.get(`/gym/customer/group/${customerGroupId}/members`, Object.assign({}, request ?? {}, { gymId })).then(
      (res) => res?.map((r) => new CustomerGroupMember(r)) ?? [],
    );
  }

  static count(customerGroupId: string, gymId: string, request?: Partial<CustomerGroupMemberQueryRequest>): Promise<number> {
    return HttpBackend.get(`/gym/customer/group/${customerGroupId}/members/count`, Object.assign({}, request ?? {}, { gymId })).then((res) => res ?? 0);
  }
}
