import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryBuilder } from './ExploreEntryBuilder';
import { ExploreContent, ExploreContentQueryRequest } from './ExploreContent';
import { ExploreCategory } from './ExploreCategory';
import { toJS } from 'mobx';

export class ExploreContentBuilder {
  static find(request: ExploreContentQueryRequest): Promise<ExploreContent[]> {
    return HttpBackend.get(`/coach/explore/admin`, toJS(request)).then((res) =>
      (res ?? []).map((r) => {
        switch (r.contentType) {
          case 'category':
            return new ExploreCategory(r);
          case 'entry':
          default:
            return ExploreEntryBuilder.build(r);
        }
      }),
    );
  }

  static count(request: ExploreContentQueryRequest): Promise<number> {
    return HttpBackend.get(`/coach/explore/admin/count`, toJS(request));
  }

  static findOne(id: string, request: Partial<ExploreContentQueryRequest>): Promise<ExploreContent> {
    return HttpBackend.get(`/coach/explore/admin/${id}`, request).then((res) => {
      switch (res.contentType) {
        case 'category':
          return new ExploreCategory(res);
        case 'entry':
        default:
          return ExploreEntryBuilder.build(res);
      }
    });
  }
}
