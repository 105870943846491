export const percentageCompleted = `
    SELECT ANY_VALUE(workoutType.value.string_value) AS type,
           COUNT(DISTINCT user_id)                   AS user_count,
           COUNT(*) AS count,
  LEAST(AVG(e.value.double_value), 100) AS avg_percentage_completed,
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST(event_params) e,
        UNNEST(event_params) workoutType
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date  AND @end_date
      AND event_name = 'Workout_finished'
      AND workoutType.key = 'workoutType'
      AND e.key = 'percentageCompleted'
    GROUP BY
        workoutType.value.string_value
  UNION ALL
  SELECT event_name              AS type,
         COUNT(DISTINCT user_id) AS user_count,
         COUNT(*) AS count,
         LEAST(AVG(e.value.double_value), 100) AS avg_percentage_completed,
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST(event_params) e
    WHERE
      _TABLE_SUFFIX BETWEEN @start_date  AND @end_date
      AND event_name <> 'Workout_finished'
      AND event_name <> 'explore_session_started'
      AND e.key = 'percentageCompleted'
    GROUP BY
        event_name`;

export const percentageCompletedGym = `
    SELECT ANY_VALUE(workoutType.value.string_value) AS type,
           COUNT(DISTINCT user_id)                   AS user_count,
           COUNT(*) AS count,
  LEAST(AVG(e.value.double_value), 100) AS avg_percentage_completed,
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST(event_params) e,
        UNNEST(event_params) workoutType,
        UNNEST(user_properties) props
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date  AND @end_date
      AND event_name = 'Workout_finished'
      AND workoutType.key = 'workoutType'
      AND e.key = 'percentageCompleted'
      AND props.key = 'gymId'
      AND props.value.string_value = @gym_id
    GROUP BY
        workoutType.value.string_value
    UNION ALL
    SELECT event_name              AS type,
           COUNT(DISTINCT user_id) AS user_count,
           COUNT(*) AS count,
           LEAST(AVG(e.value.double_value), 100) AS avg_percentage_completed,
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST(event_params) e,
        UNNEST(user_properties) props
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date  AND @end_date
      AND event_name <> 'Workout_finished'
      AND event_name <> 'explore_session_started'
      AND e.key = 'percentageCompleted'
      AND props.key = 'gymId'
      AND props.value.string_value = @gym_id
    GROUP BY
        event_name`;
