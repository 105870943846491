/**
 * Created by neo on 05.08.22.
 */
import {
  EarnModelRuleActivationLogicEntry,
  EarnModelRuleActivationLogicEntryJson,
} from './EarnModelRuleActivationLogicEntry';
import { observable } from 'mobx';

export type EarnModelRuleActivationLogicOrBlockJson = {
  entries: EarnModelRuleActivationLogicEntryJson[];
};

export class EarnModelRuleActivationLogicOrBlock {
  @observable
  entries: EarnModelRuleActivationLogicEntry[] = [];

  constructor(json?: Partial<EarnModelRuleActivationLogicOrBlockJson>) {
    if (json) {
      this.entries = json.entries?.map((e) => new EarnModelRuleActivationLogicEntry(e)) ?? [];
    }
  }

  toJS(): EarnModelRuleActivationLogicOrBlockJson {
    return {
      entries: this.entries.map((e) => e.toJS()),
    };
  }
}
