/* tslint:disable:object-literal-key-quotes */
/**
 * Created by andreaskarantzas on 2018-12-02
 */

import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { blue } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { ThemeConfig } from '../../Config/Theme';

export type FabStateType = 'loading' | 'success' | 'error' | 'active' | 'idle';

interface SimpleButtonProps {
  onClick?: (event?: any) => void;
  tooltipText?: string;
  animated?: boolean;
  buttonState?: FabStateType;
  icon?: any;
  buttonText?: string;
  style?: any;
  disabled?: boolean;
  showButtonTextAlways?: boolean;
}

export const SimpleButton: React.FC<SimpleButtonProps> = observer((props) => {
  const { onClick, tooltipText, animated, showButtonTextAlways, ...other } = props;
  const { classes } = useStyles();

  const store = useLocalStore(
    (source) => ({
      get icon() {
        if (source.buttonState === 'success') {
          return <CheckIcon />;
        } else if (source.buttonState === 'error') {
          return <ErrorIcon />;
        }
        return source.icon || <div />;
      },
      get classname() {
        if (source.buttonState === 'success') {
          return [classes.button, source.style, classes.buttonSuccess];
        } else if (source.buttonState === 'error') {
          return [classes.button, source.style, classes.buttonError];
        }
        return [classes.button, source.style, classes.buttonHover];
      },
      get text() {
        return source.buttonText || '';
      },
      get classnameText() {
        if (store.text === '') {
          return null;
        } else {
          return [classes.text];
        }
      },
      get isLoading() {
        return source.buttonState === 'loading';
      },
      get disabled() {
        return store.isLoading || !!source.disabled;
      },
      get fabColor() {
        return source.buttonState === 'active' ? classes.active : 'primary';
      },
    }),
    props,
  );

  return (
    <Tooltip {...other} TransitionComponent={Zoom} title={tooltipText || ''}>
      <>
        <Fab
          className={clsx(store.classname)}
          color={store.fabColor as any}
          disabled={store.disabled}
          onClick={onClick}
          classes={{ root: classes.root }}
          variant="extended"
        >
          <div className={clsx(store.classnameText)}>{store.text}</div>
          {store.isLoading && animated ? <CircularProgress size={24} className={classes.buttonProgress} /> : store.icon}
        </Fab>
      </>
    </Tooltip>
  );
});

const useStyles = makeStyles()((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
      lineHeight: 0,
    },
  },
  button: {
    color: '#ffffff',
    backgroundColor: '#386883',
    borderRadius: 8,
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: '#283A5A',
    },
  },
  buttonSuccess: {
    backgroundColor: ThemeConfig.Colors.successColorDarker,
  },
  buttonError: {
    backgroundColor: ThemeConfig.Colors.errorColor,
  },
  text: {
    paddingRight: 8,
  },
  buttonProgress: {
    color: ThemeConfig.Colors.charcoalGrey,
  },
  active: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}));
