import { action, computed, observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { AxonEventProcessorTracker } from './AxonEventProcessorTracker';

export class AxonEventProcessor {
  @observable
  name: string = '';
  @observable
  componentName: string = '';
  @observable
  mode: string = '';
  @observable
  tokenStoreIdentifier: string = '';
  @observable
  warnings: Array<any> = [];
  @observable
  freeThreadInstances: Array<any> = [];
  @observable
  canPause: boolean = false;
  @observable
  canPlay: boolean = false;
  @observable
  canSplit: boolean = false;
  @observable
  canMerge: boolean = false;
  @observable
  trackers: Array<AxonEventProcessorTracker> = [];

  constructor(json?: any) {
    if (json) {
      this.name = json.name;
      this.componentName = json.componentName;
      this.mode = json.mode;
      this.tokenStoreIdentifier = json.tokenStoreIdentifier;
      this.warnings = json.warnings;
      this.freeThreadInstances = json.freeThreadInstances;
      this.canPause = json.canPause;
      this.canPlay = json.canPlay;
      this.canSplit = json.canSplit;
      this.canMerge = json.canMerge;
      this.trackers = (json.trackers || []).map((t) => new AxonEventProcessorTracker(t));
    }
  }

  @action
  refresh(processor: AxonEventProcessor) {
    this.name = processor.name;
    this.mode = processor.mode;
    this.warnings = processor.warnings;
    this.freeThreadInstances = processor.freeThreadInstances;
    this.canPause = processor.canPause;
    this.canPlay = processor.canPlay;
    this.canSplit = processor.canSplit;
    this.canMerge = processor.canMerge;
    this.trackers = (processor.trackers || []).map((t) => new AxonEventProcessorTracker(t));
  }

  @computed
  get globalIndexMax(): number {
    return this.trackers.reduce((max: number, s: AxonEventProcessorTracker) => Math.max(max, s.tokenPosition), 0);
  }

  @computed
  get globalIndexMin(): number {
    return this.trackers.reduce(
      (max: number, s: AxonEventProcessorTracker) => Math.min(max, s.tokenPosition),
      this.globalIndexMax,
    );
  }

  @computed
  get supportsReset(): boolean {
    return this.mode.toLowerCase() === 'tracking';
  }

  start() {
    return HttpBackend.post(
      `/infrastructure/eventProcessor/start?processorName=${this.name}&componentName=${this.componentName}&tokenStoreIdentifier=${this.tokenStoreIdentifier}`,
    );
  }

  stop() {
    return HttpBackend.post(
      `/infrastructure/eventProcessor/stop?processorName=${this.name}&componentName=${this.componentName}&tokenStoreIdentifier=${this.tokenStoreIdentifier}`,
    );
  }

  reset(startTimestamp?: number) {
    const tsString = startTimestamp && startTimestamp >= 0 ? `&startTimestamp=${startTimestamp}` : '';
    return HttpBackend.post(
      `/infrastructure/eventProcessor/reset?processorName=${this.name}${tsString}&componentName=${this.componentName}&tokenStoreIdentifier=${this.tokenStoreIdentifier}`,
    );
  }

  static async find(params?: any): Promise<Array<AxonEventProcessor>> {
    const res = await HttpBackend.get('/infrastructure/eventProcessor');
    if (res) {
      return res
        .map((a) => new AxonEventProcessor(a))
        .sort((a: AxonEventProcessor, b: AxonEventProcessor) => a.componentName.localeCompare(b.componentName));
    }
    return [];
  }
}
