/**
 * Created by neo on 19.08.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Button, Checkbox, Form, Input } from 'antd';
import { Col, Row } from 'reactstrap';
import { SuperMacroTagsInput } from './SuperMacroTagsInput';
import { SuperMacroExcludedTagsInput } from './SuperMacroExcludedTagsInput';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { SuperMacro } from '../../../../Model/Coach/SuperMacro/SuperMacro';
import { runInAction } from 'mobx';

export type SuperMacroMainAttributesEditorProps = {
  superMacro: SuperMacro;
};

export const SuperMacroMainAttributesEditor: React.FC<SuperMacroMainAttributesEditorProps> = observer(
  ({ superMacro }) => {
    const handleTemplateNameChange = React.useCallback(
      ({ target: { value } }) => {
        superMacro.templateName = value;
      },
      [superMacro],
    );

    const handleChangeInfinite = React.useCallback(
      ({ target: { checked } }) => {
        runInAction(() => (superMacro.infinite = checked));
      },
      [superMacro],
    );

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Form.Item label="Template Name">
              <Input value={superMacro.templateName} onChange={handleTemplateNameChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Item label="Included Tags">
              <SuperMacroTagsInput superMacro={superMacro} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Excluded Tags">
              <SuperMacroExcludedTagsInput superMacro={superMacro} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <TranslationInputArray entity={superMacro} field="name" label="Name" />
          </Col>
          <Col xs={6}>
            <TranslationInputArray entity={superMacro} field="description" label="Description" />
          </Col>
        </Row>
      </React.Fragment>
    );
  },
);
