export const weeklySummary = (gym: boolean = false) =>
  events.reduce(
    (query, event, index) =>
      query + `LEFT JOIN (${weeklyEventSummary(event, gym)}) q${index}\n ON root.week = q${index}.week\n`,
    `SELECT root.week as event_date, possible_total_users - total_unique_users as delta_users, * FROM (${weeklyActivitiesTotalSummary(
      gym,
    )}) root\n`,
  ) +
  `LEFT JOIN (${weeklyRecipeSummary(gym)}) recipe ON root.week = recipe.week \n
    LEFT JOIN (${weeklyWorkoutSummary('Workout_finished', gym, 'gym_strength')}) gs ON root.week = gs.week \n
    LEFT JOIN (${weeklyWorkoutSummary('Workout_finished', gym, 'workplace')}) gw ON root.week = gw.week \n
    LEFT JOIN (${weeklyWorkoutSummary('Workout_finished', gym, 'stretching')}) gst ON root.week = gst.week \n
    LEFT JOIN (${weeklyPossibleUsers(gym)}) possible ON root.week = possible.week\n
   ORDER BY root.week`;

export const weeklyActivitiesTotalSummary = (gym: boolean = false) => `
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(DISTINCT user_id) as total_unique_users,
    COUNT (*) as total_activities_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    ${gym ? `UNNEST(user_properties) p,` : ''}
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND 
        (
            (event_name = 'yogaSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'breathingSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'meditationSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'Workout_finished' AND e.key = 'athleteId')
            OR (event_name = 'activity_logged' AND e.key = 'athleteId')
            OR (event_name = 'FeedEntry_read' AND e.key = 'athleteId')
            OR (e.key = 'content_type' AND e.value.string_value = 'recipe')
        )
  GROUP BY week
`;

export const weeklyWorkoutSummary = (eventName: string, gym: boolean = false, workoutType: string) => `
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(DISTINCT user_id) as ${eventName}_${workoutType}_unique_users,
    COUNT (*) as ${eventName}_${workoutType}_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
    ${gym ? ',UNNEST(user_properties) p' : ''}
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
      ${`AND e.key = 'workoutType' AND e.value.string_value = '${workoutType}'`}
    AND event_name = '${eventName}'
  GROUP BY week 
`;

export const weeklyEventSummary = (eventName: string, gym: boolean = false) => `
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(DISTINCT user_id) as ${eventName}_unique_users,
    COUNT (*) as ${eventName}_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`
    ${gym ? `,UNNEST(user_properties) p` : ''}
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND event_name = '${eventName}'
  GROUP BY week 
`;

export const weeklyRecipeSummary = (gym: boolean = false) => `
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(DISTINCT user_id) as recipe_unique_users,
    COUNT (*) as recipe_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    ${gym ? `UNNEST(user_properties) p,` : ''}
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND e.key = 'content_type'
    AND e.value.string_value = 'recipe'
  GROUP BY week 
`;

export const weeklyPossibleUsers = (gym: boolean = false) => `
SELECT
  week,
  ANY_VALUE(total) AS possible_total_users
FROM (
  SELECT
    *,
    COUNT(user_id) OVER (ORDER BY week) AS total
  FROM (
    SELECT
      user_id,
      DATE_TRUNC(PARSE_DATE('%Y%m%d', MIN(event_date)), WEEK) AS week,
    FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      ${gym ? `UNNEST(user_properties) p,` : ''}
      UNNEST(event_params) e
    WHERE
      _TABLE_SUFFIX BETWEEN @gym_start_date AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND e.key = 'athleteId'
    GROUP BY
      user_id )
  ORDER BY
    week )
GROUP BY
  week
`;

const events = [
  'yogaSession_finished',
  'breathingSession_finished',
  'meditationSession_finished',
  'Workout_finished',
  'activity_logged',
  'FeedEntry_read',
];
