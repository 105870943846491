/**
 * Created by neo on 11.01.21.
 */
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { observable, toJS } from 'mobx';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import NativeFeedComponent, { NativeFeedComponentJson } from '../Feed/NativeFeedComponent';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';

export type LinkEntryJson = ExploreEntryJson & {
  link: string;
};

export class LinkEntry extends ExploreEntry {
  @observable
  link = '';

  constructor(json?: Partial<LinkEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'link' }));
    if (json) {
      this.link = json.link ?? '';
    }
  }

  toJS(): LinkEntryJson {
    return Object.assign(super.toJS(), {
      link: this.link,
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<LinkEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request), { type: 'link' })).then((res) =>
      (res ?? []).map((r) => new LinkEntry(r)),
    );
  }

  static async findOne(id: string): Promise<LinkEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new LinkEntry(res));
  }
}
