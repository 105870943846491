/**
 * Created by neo on 22.09.21.
 */
import { ProgramParams, ProgramParamsJson } from '../ProgramParams';
import { observable } from 'mobx';

export type ProgramTemplateProgramParamsJson = ProgramParamsJson & {
  equipmentLocked: boolean;
};

export class ProgramTemplateProgramParams extends ProgramParams {
  @observable
  equipmentLocked = false;

  constructor(json?: Partial<ProgramTemplateProgramParamsJson>) {
    super(json);
    if (json) {
      this.equipmentLocked = json.equipmentLocked ?? false;
    }
  }

  toJS(): ProgramTemplateProgramParamsJson {
    return Object.assign(super.toJS(), { equipmentLocked: this.equipmentLocked });
  }
}
