/**
 * Created by katarinababic on 20.12.21..
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../../Model/Analytics/AnalyticsData';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartColors } from '../../ChartColors';
import { Form } from 'antd';

export type ExploreCategoryAnalyticsAggregatedChartProps = {
  data: AnalyticsRow[];
};

export const ExploreCategoryAnalyticsAggregatedChart: React.FC<ExploreCategoryAnalyticsAggregatedChartProps> = observer(
  ({ data }) => {
    console.log('ExploreCategoryAnalyticsAggregatedChart data', data);
    return (
      <Form.Item label="Select Content">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category_name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar type="monotone" dataKey="select_count" fill={ChartColors[0]} />
            <Bar type="monotone" dataKey="user_count" fill={ChartColors[1]} />
          </BarChart>
        </ResponsiveContainer>
      </Form.Item>
    );
  },
);
