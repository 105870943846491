import { action, computed, observable } from 'mobx';
import { LocalizedValue } from '../../../LocalizedValue';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { LocalizedArrayEntity, languagePriority } from '../../../LocalizedArrayEntity';
import { v4 as UUID } from 'uuid';
import { Media } from '../../../Media/Media';
import { CoachWorkoutPhase } from './CoachWorkoutPhase';
import { CoachExerciseBlock } from './CoachExerciseBlock';
import dayjs, { Dayjs } from 'dayjs';
import { PipelineContext } from '../../PipelineContext';

export class CoachWorkout extends LocalizedArrayEntity {
  @observable
  scriptId: string = '';
  @observable type: string = 'strength';
  @observable
  tags: string[] = [];
  @observable.shallow phases: CoachWorkoutPhase[] = [];
  @observable image?: Media = undefined;
  @observable createTimestamp: number = Date.now();
  @observable updateTimestamp: number = Date.now();

  constructor(json?: any) {
    super(json);
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): any {
    return Object.assign(super.toJS(), {
      id: newId ? UUID() : this.id,
      tags: this.tags,
      scriptId: this.scriptId,
      type: this.type,
      phases: this.phases.map((p) => p.toJS()),
      image: this.image ? this.image.toJS() : undefined,
      createTimestamp: this.createTimestamp,
      updateTimestamp: this.updateTimestamp,
    });
  }

  @action
  setBasicData(json: any): void {
    this.id = json.id || UUID();
    this.tags = json.tags ?? [];
    this.scriptId = json.scriptId ?? '';
    this.name = (json.name ?? []).map((l) => new LocalizedValue(l));
    this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
    this.type = json.type;
    this.image = json.image ? new Media(json.image) : undefined;
    this.createTimestamp = json.createTimestamp;
    this.updateTimestamp = json.updateTimestamp;
  }

  @action
  setData(json: any): void {
    this.setBasicData(json);
    this.phases = (json.phases || []).map((p: any) => new CoachWorkoutPhase(this, p));
    this.createTimestamp = this.createTimestamp || Date.now();
    this.updateTimestamp = this.updateTimestamp || Date.now();
  }

  calculateCalories(bmr24: number): number {
    return this.phases.reduce((total: number, p: CoachWorkoutPhase) => total + p.calculateCalories(bmr24), 0);
  }

  @computed
  get totalExercises(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.totalExercises, 0);
  }

  @computed
  get duration(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.duration, 0);
  }

  @computed
  get durationFormatted(): string {
    const { duration } = this;
    if (duration > 0) {
      if (duration > 3599999) {
        return dayjs.utc(duration).format('HH:mm:ss');
      }
      return dayjs.utc(duration).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get durationMinutes(): number {
    return Math.round(dayjs.duration(this.duration).asMinutes());
  }

  @computed
  get breakTime(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.totalTimeExercising, 0);
  }

  @computed
  get isNew(): boolean {
    return !this.id || this.id.length <= 0;
  }

  @computed
  get hasExercises() {
    return this.totalExercises > 0;
  }

  @computed
  get hasPhases(): boolean {
    return this.phases.length > 0;
  }

  @computed
  get totalSets(): number {
    return this.phases.reduce((total: number, p: CoachWorkoutPhase) => total + p.totalSets, 0);
  }

  @computed
  get tons(): number {
    return this.phases.reduce((total: number, p: CoachWorkoutPhase) => total + p.tons, 0);
  }

  @computed
  get exerciseBlocks(): Array<CoachExerciseBlock> {
    return this.phases.reduce(
      (arr: Array<CoachExerciseBlock>, phase: CoachWorkoutPhase) => arr.concat(phase.exerciseBlocks.slice()),
      [],
    );
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.estimatedTotalExerciseTime, 0);
  }

  @computed
  get maxDuration(): number {
    let totalTime = 0;
    const phases = (this.phases || []).slice();
    for (const phase of phases) {
      totalTime += phase.maxDuration;
    }
    return totalTime;
  }

  @computed
  get minDuration(): number {
    let totalTime = 0;
    const phases = (this.phases || []).slice();
    for (const phase of phases) {
      totalTime += phase.minDuration;
    }
    return totalTime;
  }

  @computed
  get sortedTags(): string[] {
    const sort = ['level:', 'age:', 'gender:', 'duration:', 'activity:'];
    return this.tags.sort((a, b) => {
      const indexA = sort.findIndex((t) => a.startsWith(t));
      const indexB = sort.findIndex((t) => b.startsWith(t));
      const indexOne = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
      const indexTwo = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;
      return indexOne - indexTwo;
    });
  }

  static get(id: string): Promise<CoachWorkout> {
    return HttpBackend.get(`/coach/workout/${id}`).then((result) => new CoachWorkout(result));
  }

  static generate(workoutTemplateId: String, context?: PipelineContext): Promise<CoachWorkout> {
    return HttpBackend.post(`/coach/workout/generate`, { workoutTemplateId, context: context?.toJS() }).then(
      (result) => new CoachWorkout(result),
    );
  }
}
