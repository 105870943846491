/**
 * Created by neo on 14.12.21.
 */
import { observable, onBecomeObserved, runInAction } from 'mobx';
import { Pageable } from '../../../Pageable';
import { FitnessProgramTemplate } from '../Template/FitnessProgramTemplate';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';

export type ProgramStatisticsUsageQueryRequest = Pageable & {
  type?: string;
  templateId?: string;
};

export type ProgramStatisticsUsageJson = {
  id: string;
  count: number;
  athletes: number;
};

export class ProgramStatisticsUsage {
  @observable
  id: string = '';
  @observable
  count: number = 0;
  @observable
  athletes: number = 0;
  @observable
  template?: FitnessProgramTemplate;

  constructor(json?: Partial<ProgramStatisticsUsageJson>) {
    if (json) {
      this.id = json.id ?? '';
      this.count = json.count ?? 0;
      this.athletes = json.athletes ?? 0;
    }

    onBecomeObserved(this, 'template', () => this.fetchTemplate());
  }

  fetchTemplate(): Promise<FitnessProgramTemplate | undefined> {
    if (!this.template) {
      return FitnessProgramTemplate.findOne(this.id).then((res) => {
        runInAction(() => (this.template = res));
        return res;
      });
    }

    return Promise.resolve(this.template);
  }

  static find(request: ProgramStatisticsUsageQueryRequest): Promise<ProgramStatisticsUsage[]> {
    return HttpBackend.get(`/coach/program/admin/analytics`, request).then((res) =>
      (res ?? []).map((r) => new ProgramStatisticsUsage(r)),
    );
  }

  static findOne(
    templateId: string,
    request: ProgramStatisticsUsageQueryRequest = {},
  ): Promise<ProgramStatisticsUsage> {
    return HttpBackend.get(`/coach/program/admin/analytics/${templateId}`, request).then(
      (res) => new ProgramStatisticsUsage(res),
    );
  }

  static count(request: ProgramStatisticsUsageQueryRequest): Promise<number> {
    return HttpBackend.get(`/coach/program/admin/analytics/count`, request).then((res) => res ?? 0);
  }
}
