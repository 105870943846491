/**
 * Created by neo on 06.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Form, Input } from 'antd';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CoachTtsEditModal } from './CoachTtsEditModal/CoachTtsEditModal';
import { PageResult } from '../../../Model/PageResult';
import { CoachTtsGroupedResult } from '../../../Model/Coach/TextToSpeech/CoachTtsGroupedResult';
import { CoachTts, CoachTtsQueryRequest } from '../../../Model/Coach/TextToSpeech/CoachTts';
import { observable, runInAction } from 'mobx';
import { Pager } from '../../../Components/Pager';
import { CoachTtsTable } from './CoachTtsTable';

export type TextToSpeechIndexScreenProps = {};

export const TextToSpeechIndexScreen: React.FC<TextToSpeechIndexScreenProps> = observer((props) => {
  const history = useNavigate();
  const [request] = useState(() => observable({} as CoachTtsQueryRequest));
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState(new PageResult<CoachTtsGroupedResult>());
  const debounce = React.useRef<ReturnType<typeof setTimeout>>();

  const search = React.useCallback((request: CoachTtsQueryRequest) => {
    setProcessing(true);
    PageResult.execute(CoachTtsGroupedResult.find(request), CoachTtsGroupedResult.count(request), request.page, 10)
      .then((result) => setResult(result))
      .finally(() => setProcessing(false));
  }, []);

  useEffect(() => {
    search(request);
  }, [request, search]);

  const handleChangeQuery = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (request.identifier = value));
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => search(request), 300);
    },
    [request, search],
  );

  const handleQueryClear = React.useCallback(() => {
    runInAction(() => (request.identifier = undefined));
    search(request);
  }, [request, search]);

  const handleClickCreate = React.useCallback(() => {
    history('/coach/tts/new');
  }, [history]);

  const handlePage = React.useCallback(
    (page: number) => {
      runInAction(() => (request.page = page));
      search(request);
    },
    [request, search],
  );

  const handleSaved = React.useCallback(
    (tts: CoachTts) => {
      const group = result.content.find((g) => g.identifier === tts.identifier);
      runInAction(() => {
        if (group) {
          const index = group.entries.findIndex((e) => e.id === tts.id);
          if (index !== -1) {
            group.entries[index] = tts;
          } else {
            group.entries.push(tts);
          }
        } else {
          result.content.push(new CoachTtsGroupedResult({ identifier: tts.identifier, entries: [tts.toJS()] }));
        }
      });
    },
    [result],
  );

  const handleDeleted = React.useCallback(
    (tts: CoachTts) => {
      const groupIndex = result.content.findIndex((g) => g.identifier === tts.identifier);
      const group = groupIndex !== -1 ? result.content[groupIndex] : undefined;
      runInAction(() => {
        if (group) {
          const index = group.entries.findIndex((e) => e.id === tts.id);
          if (index !== -1) {
            group.entries.splice(index, 1);
          }

          if (group.entries.length === 0) {
            result.content.splice(groupIndex, 1);
          }
        }
      });
    },
    [result],
  );

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1>Text To Speech</h1>
          </Col>
          <Col xs="auto">
            <Button type="primary" onClick={handleClickCreate}>
              Create
            </Button>
          </Col>
        </Row>
        <SingleColRow>
          <Form.Item>
            <Input.Search
              onChange={handleChangeQuery}
              value={request.identifier}
              autoFocus={true}
              placeholder="Search for tts..."
              enterButton="Clear"
              onSearch={handleQueryClear}
              loading={processing}
            />
          </Form.Item>
        </SingleColRow>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
        <SingleColRow>
          <CoachTtsTable result={result.content.slice()} />
        </SingleColRow>
        <SingleColRow>
          <Pager page={result} onPage={handlePage} />
        </SingleColRow>
      </Container>
      <Routes>
        <Route path=":ttsId" element={<CoachTtsEditModal onSaved={handleSaved} onDeleted={handleDeleted} />} />
      </Routes>
    </React.Fragment>
  );
});
