/**
 * Created by neo on 21.06.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ExploreContent } from '../../../../Model/Explore/ExploreContent';
import { ExploreContentBuilder } from '../../../../Model/Explore/ExploreContentBuilder';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { runInAction } from 'mobx';

export type AddHourTagsContentComponentProps = {};

export const AddHourTagsContentComponent: React.FC<AddHourTagsContentComponentProps> = observer((props) => {
  const [tagging, setTagging] = useState(false);

  const tagContent = React.useRef(async () => {
    const SIZE = 5;
    let page = 0;
    let results: ExploreContent[] | undefined;
    while (!results || results.length >= SIZE) {
      results = await ExploreContentBuilder.find({ size: SIZE, contentType: 'entry', page });
      results.forEach(
        (entry) => (entry.tags = [...new Set(...entry.tags.concat(['morning', 'afternoon', 'evening', 'night']))]),
      );

      if (results.length > 0) {
        await Promise.all(results.map((e) => e.save()));
      }
      page += 1;
    }
  }).current;

  const handleTagAll = React.useCallback(async () => {
    setTagging(true);
    try {
      await tagContent();
    } finally {
      setTagging(false);
    }
  }, [tagContent]);

  return (
    <Button onClick={handleTagAll} disabled={tagging}>
      Add Hour Tags
    </Button>
  );
});
