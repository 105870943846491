/**
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { DeleteIcon } from './DeleteIcon';
import { Media } from '../../Model/Media/Media';
import { VideoMediaItemTypeRenderer, VideoMediaItemTypeRendererProps } from './VideoMediaItemTypeRenderer';

const styles = {
  container: {
    border: '1px solid lightgray',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export type ViewMediaItemProps = Pick<VideoMediaItemTypeRendererProps, 'onClick'> & {
  media: Media;
  onRemove?: (media: Media) => any;
  disableEdit?: boolean;
  size?: number;
};

export const ViewMediaItem: React.FC<ViewMediaItemProps> = observer(
  ({ onClick, onRemove, media, disableEdit, size = 240 }: ViewMediaItemProps) => {
    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(media);
    }, [onRemove, media]);

    const handleClick = React.useCallback(() => {
      onClick && onClick(media);
    }, [onClick, media]);

    if (media) {
      return (
        <React.Fragment>
          <div>{media.language}</div>
          <div className="media" style={Object.assign({}, styles.container, { width: size })} onClick={handleClick}>
            {!!onRemove && !disableEdit && (
              <div className="delete-container">
                <DeleteIcon onClick={handleRemove} />
              </div>
            )}
            <VideoMediaItemTypeRenderer media={media} onClick={onClick} />
          </div>
        </React.Fragment>
      );
    }
    return null;
  },
);
