/**
 * Created by neo on 19.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Input } from 'antd';
import { Pager } from '../../../Components/Pager';
import { UserTable } from './UserTable';
import { PageResult } from '../../../Model/PageResult';
import { User } from '../../../Model/User';

export type UserListScreenProps = {};

export const UserListScreen: React.FC<UserListScreenProps> = observer((props) => {
  const [result, setResult] = useState<PageResult<User>>(new PageResult());
  const [query, setQuery] = useState('');
  const page = React.useRef(0);
  const debounce = React.useRef<ReturnType<typeof setTimeout>>();

  const load = React.useRef((email?: string, page: number = 0) => {
    PageResult.execute(User.find({ email, page, sort: 'email,ASC' }), User.count({ email }), page, 10).then((res) =>
      setResult(res),
    );
  }).current;

  useEffect(() => {
    load('', 0);
  }, []);

  const handleQuery = React.useCallback(
    ({ target: { value } }: any) => {
      setQuery(value);
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        page.current = 0;
        load(value, page.current);
      }, 300);
    },
    [load],
  );

  const handlePage = React.useCallback(
    (newPage: number) => {
      if (page.current !== newPage) {
        page.current = newPage;
        load(query, newPage);
      }
    },
    [load, query],
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1>{'Users'}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input type="text" onChange={handleQuery} value={query} placeholder="Search..." />
        </Col>
      </Row>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserTable users={result.content} />
        </Col>
      </Row>
    </Container>
  );
});
