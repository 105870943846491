/**
 * Created by katarinababic on 17.11.23.
 */

import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { FileStorageContent, FileStorageContentJson } from './FileStorageContent';

export type FileStorageResultRequestParams = {
  size: number;
  pageToken?: string;
};

export type FileStorageResultJson = {
  content: FileStorageContentJson[];
  nextPageToken?: string;
};

export class FileStorageResult {
  @observable
  content: FileStorageContent[] = [];
  @observable
  nextPageToken?: string;

  constructor(json?: Partial<FileStorageResultJson>) {
    if (json) {
      this.content = (json.content ?? []).map((item) => new FileStorageContent(item));
      this.nextPageToken = json.nextPageToken ?? undefined;
    }
  }

  private static transformPath(path: string) {
    if (path.endsWith('/')) {
      return path;
    }
    return `${path}/`;
  }

  static async getFolderContent(
    path: string = '/',
    params: FileStorageResultRequestParams,
  ): Promise<FileStorageResult> {
    return HttpBackend.get(`/media/file-storage?path=${this.transformPath(path)}`, params).then(
      (res) => new FileStorageResult(res),
    );
  }

  static count(path: string = '/'): Promise<number> {
    return HttpBackend.get(`/media/file-storage/count?path=${this.transformPath(path)}`);
  }
}
