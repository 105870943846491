/**
 * Created by neo on 20.05.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Col, Container } from 'reactstrap';
import { Alert, Button, Form, Input, Row, Space } from 'antd';
import { RecipeGenerator } from './RecipeGenerator';
import { RecipeGeneratorEntry } from './RecipeGeneratorEntry';
import { Sleep } from '../../../../Utils/Sleep';
import { runInAction } from 'mobx';
import { Recipe } from '../../../../Model/Diet/Recipe/Recipe';
import { Media } from '../../../../Model/Media/Media';
import MediaService from '../../../../Services/MediaService';
import Dropzone from 'react-dropzone';

const BATCH_SIZE = 10;

type MediaNamePair = {
  name: string;
  media: Media;
};

export type RecipeGeneratorScreenProps = {};

export const RecipeGeneratorScreen: React.FC<RecipeGeneratorScreenProps> = observer((props) => {
  const [recipeNames, setRecipeNames] = useState('');
  const [generators, setGenerators] = useState<RecipeGenerator[]>([]);
  const [mediaFiles, setMediaFiles] = useState<MediaNamePair[]>([]);
  const [processing, setProcessing] = useState(false);

  const disabled = processing || generators.some((g) => g.generating || g.creating);

  useEffect(() => {
    Promise.all(
      recipeNames
        .split('\n')
        .filter((t) => t.trim())
        .map((t) =>
          Recipe.search({ query: t.trim(), size: 1 })
            .then((res) =>
              res[0]?.name.map((n) => n.value.trim().toLowerCase()).some((n) => n === t.trim().toLowerCase())
                ? res[0]
                : undefined,
            )
            .then((recipe) => RecipeGenerator.create(t.trim(), recipe)),
        ),
    ).then((res) => setGenerators(res));
  }, [recipeNames]);

  const handleGenerate = React.useCallback(async () => {
    generators.forEach((g) => runInAction(() => (g.queued = true)));

    for (let i = 0; i < generators.length; i += BATCH_SIZE) {
      const batch = generators.slice(i, i + BATCH_SIZE);
      console.log('batch', batch);
      await Promise.all(batch.map((g) => g.generate()));
      await Sleep.sleep(1000);
    }
  }, [generators]);

  const handleRemove = React.useCallback(
    (generator: RecipeGenerator) => {
      const index = generators.findIndex((g) => g.recipe.id === generator.recipe.id);
      if (index !== -1) {
        setGenerators((prev) => {
          prev.splice(index, 1);
          return [...prev];
        });
      }
    },
    [generators],
  );

  const handleFilesChanged = React.useCallback(
    (files: File[]) => {
      console.log('files', files);
      setProcessing(true);

      Promise.all(
        files
          .filter((f) => {
            const cleanName = f.name
              .toString()
              .replace(/^\d+_?/, '')
              .split('.')
              .slice(0, -1)
              .join('.')
              .toLowerCase()
              .trim();
            return generators.some((g) => g.recipeName.toLowerCase().trim().localeCompare(cleanName) === 0);
          })
          .map((f) => {
            const cleanName = f.name
              .toString()
              .replace(/^\d+_?/, '')
              .split('.')
              .slice(0, -1)
              .join('.')
              .toLowerCase()
              .trim();
            console.log('cleanName', cleanName, f.name);
            return MediaService.uploadMedia(f)
              .then((media) => ({
                name: cleanName,
                media,
              }))
              .then((pair) => {
                console.log('pair', pair);
                setMediaFiles((prev) => [...prev, pair]);
                return pair;
              });
          }),
      )
        .then((pair) => {
          generators
            .filter((g) => !g.recipe.image)
            .forEach((generator) => {
              const pairMatch = pair.find(
                (p) => p.name.toLowerCase().trim().localeCompare(generator.recipeName.toLowerCase().trim()) === 0,
              );
              if (pairMatch) {
                generator.recipe.image = pairMatch?.media;
              }

              if (generator.saved) {
                generator.save();
              }
            });
        })
        .finally(() => setProcessing(false));
    },
    [generators],
  );

  return (
    <Container>
      <Form layout="vertical">
        <Row>
          <Col>
            <h1>Recipe Generator</h1>
          </Col>
          <Col xs="auto">
            <Button type="primary" disabled={generators.length === 0 || disabled} onClick={handleGenerate}>
              Generate
            </Button>
          </Col>
        </Row>
        <SingleColRow>
          <Form.Item label="Recipes to generate" extra="Put each recipe into a new line">
            <Input.TextArea
              rows={32}
              value={recipeNames}
              onChange={(e) => setRecipeNames(e.target.value)}
              disabled={disabled}
            />
          </Form.Item>
        </SingleColRow>
        <SingleColRow>
          <Form.Item
            label="Possible Media files"
            extra="Select all possible files we will assign them automtically where possible. Names must match"
          >
            <Dropzone onDrop={handleFilesChanged} disabled={processing}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="dropzone"
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  {'Drop recipe images here, or click to select files'}
                </div>
              )}
            </Dropzone>
            {mediaFiles.length > 0 && <Alert message={`${mediaFiles.length} medias available`} type="success" />}
          </Form.Item>
        </SingleColRow>
        <Space direction="vertical" style={{ width: '100%' }}>
          {generators.length > 0 && <Alert message={`${generators.length} recipes ready to generate`} type="success" />}
          {generators.map((generator, index) => (
            <SingleColRow key={generator.recipeName}>
              <RecipeGeneratorEntry generator={generator} onRemove={handleRemove} />
            </SingleColRow>
          ))}
        </Space>
      </Form>
    </Container>
  );
});
