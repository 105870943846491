/**
 * Created by neo on 07.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { ExploreCategoryRendererPreview } from './ExploreCategoryRendererPreview';
import { ExploreCategoryRendererRoot } from './ExploreCategoryRendererRoot';
import { ExploreCategoryRendererCardLink } from './ExploreCategoryRendererCardLink';
import { ExploreCategoryRendererCategoryGroup } from './ExploreCategoryRendererCategoryGroup';
import { ExploreCategoryRendererLinkList } from './ExploreCategoryRendererLinkList';
import { ExploreCategoryRendererBigPreview } from './ExploreCategoryRendererBigPreview';
import { ExploreCategoryRendererTools } from './ExploreCategoryRendererTools';
import { Gym } from '../../../Model/Gym/Gym';

export type ExploreCategoryRendererProps = {
  category: ExploreCategory;
  gym?: Gym;
};

export const ExploreCategoryRenderer: React.FC<ExploreCategoryRendererProps> = observer(({ category, gym }) => {
  switch (category.type) {
    case 'card_link':
      return <ExploreCategoryRendererCardLink category={category} gym={gym} />;
    case 'link_list':
      return <ExploreCategoryRendererLinkList category={category} gym={gym} />;
    case 'tools':
      return <ExploreCategoryRendererTools category={category} gym={gym} />;
    case 'category_group':
      return <ExploreCategoryRendererCategoryGroup category={category} gym={gym} />;
    case 'big_preview':
      return <ExploreCategoryRendererBigPreview category={category} gym={gym} />;
    case 'preview':
    default:
      return <ExploreCategoryRendererPreview category={category} gym={gym} />;
  }
  return null;
});
