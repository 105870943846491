/**
 * Created by neo on 14.08.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { useRootStore } from '../../../../Store/useRootStore';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { WorkoutTemplateEntry } from '../../../../Model/Explore/WorkoutTemplateEntry';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { runInAction } from 'mobx';
import { LocalizedValue } from '../../../../Model/LocalizedValue';
import { useSearchParams } from 'react-router-dom';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { DeeplService } from '../../../../Services/DeeplService';
import { ActivityWorkoutEntry } from '../../../../Model/Explore/ActivityWorkoutEntry';

export type EnhanceTagsAndDescriptionComponentProps = {};

export const EnhanceTagsAndDescriptionComponent: React.FC<EnhanceTagsAndDescriptionComponentProps> = observer(
  (props) => {
    const { authentication } = useRootStore();

    const [searchParams] = useSearchParams();

    const type = searchParams.get('type') ?? undefined;

    const [processing, setProcessing] = useState(false);

    const fetchDescription = (entry: ExploreEntry) => {
      if (entry.getDescription('en')?.trim()) {
        return Promise.resolve(entry.getDescription('en')?.trim());
      } else if (entry instanceof WorkoutTemplateEntry) {
        return entry.fetchWorkoutTemplate().then((t) => t?.getDescription('en')?.trim() ?? '');
      }
      return Promise.resolve('');
    };

    const fetchPrompt = (entry: ExploreEntry) => {
      return fetchDescription(entry).then((description) => {
        if (entry instanceof WorkoutTemplateEntry) {
          if (entry.context.equipmentTypes.length > 0) {
            if (entry.context.isWithoutEquipment) {
              return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
                'en',
              )}. Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry.
             It does not require any equipment and it's done only using your body weight. Include the tags "without equipment" and include "equipment:custom" and "equipment:gym" within the list of tags. It's mandatory.. ${
                entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
              } It has the following description: "${description}. You have to include the tag "equipment:free" within the list of tags.`;
            }
            return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName('en')}.${
              entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
            } It needs following equipment: ${entry.context.equipmentTypes
              .filter((e) => e !== 'FREE' && e !== 'FREE_OUTDOOR')
              .join(
                ',',
              )}. Include minimum the tag "with equipment". 
              Include "equipment:custom" and "equipment:gym" within the list of tags. It's mandatory.
              Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
              or just the ones you seem appropriate for this entry. 
              It has the following description: "${description}"`;
          }

          return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName('en')}.${
            entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
          } It can be done in a gym or with your own equipment at home. Include minimum the tag "with equipment". 
          It has the following description: "${description}.
          If you need equipment for this workout then you have to include the tag "equipment:custom" and/or "equipment:gym". 
          If this workout requires no equipment then you have to include "equipment:free" AND "equipment:no" within the tags.
          Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry."`;
        } else if (entry instanceof ActivityWorkoutEntry) {
          return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
            'en',
          )}. It has the following description: "${description}. 
          If you need equipment for this workout then you have to include the tag "equipment:custom" and/or "equipment:gym". 
          If this workout requires no equipment then you have to include "equipment:free" AND "equipment:no" within the tags.
          Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry.`;
        }
        return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
          'en',
        )}. Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry. It has the following description: "${description}".
        The JSON of this entry looks the following: 
          ${JSON.stringify(entry, null, 2)}`;
      });
    };

    const fixTags = (entry: ExploreEntry) =>
      fetchPrompt(entry)
        .then((prompt) =>
          new GptResponseService([
            {
              role: 'system',
              content: 'Provide all responses as JSON array containing only strings.',
            },
            {
              role: 'user',
              content: prompt,
            },
          ]).generate(),
        )
        .then((result) => JSON.parse(result))
        .then((res) => runInAction(() => (entry.tags = res.map((t) => t.toLowerCase().trim()))));

    const fetchType = (type) => {
      if (type === 'workoutTemplate') {
        return 'single workout';
      } else if (type === 'workplace') {
        return 'single workout that you can do at work';
      } else if (type === 'activityWorkout') {
        return 'single video based workout';
      } else if (type === 'meditation') {
        return 'meditation';
      } else if (type === 'breathing') {
        return 'breathing exercise';
      } else if (type === 'yoga') {
        return 'video based single yoga class';
      }
      return 'article';
    };

    const generateDescription = (entry: ExploreEntry) => {
      const type = fetchType(entry.type);

      return fetchDescription(entry)
        .then((description) =>
          new GptResponseService([
            {
              role: 'user',
              content: `Write a short and appealing description about a ${type} named ${entry.getName(
                'en',
              )}. Do not use more than 5 sentences. Use the following tags: "${entry.tags}" ${
                description ? `and the following description: "${description}"` : ''
              }`,
            },
          ]).generate((result) =>
            runInAction(() => (entry.description = [new LocalizedValue({ lang: 'en', value: result })])),
          ),
        )
        .then((res) => runInAction(() => (entry.description = [new LocalizedValue({ lang: 'en', value: res })])));
    };

    const processEntry = async (entry: ExploreEntry) => {
      // if (entry.tags.length === 0) {
      //   await fixTags(entry);
      // }
      // await generateDescription(entry);
      // await DeeplService.translateName(entry.description);
      if (entry instanceof WorkoutTemplateEntry) {
        // const template = await entry.fetchWorkoutTemplate();
        entry.tags = entry.tags.filter((t) => !t.startsWith('equipment:'));
        if (entry.context.isWithoutEquipment) {
          entry.tags.push('equipment:no');
        } else {
          entry.tags.push('equipment:gym');
          entry.tags.push('equipment:custom');
        }
      } else if (entry instanceof ActivityWorkoutEntry) {
        entry.tags = entry.tags.filter((t) => !t.startsWith('equipment:'));
        entry.tags.push('equipment:no');
        entry.tags.push('equipment:gym');
        entry.tags.push('equipment:custom');
      }
    };

    const handleProcess = async () => {
      setProcessing(true);
      try {
        const SIZE = 30;
        let page = 0;
        let results: ExploreEntry[] | undefined;
        while (!results || results.length >= SIZE) {
          results = await ExploreEntryBuilder.find({
            size: SIZE,
            type: type ? [type] : undefined,
            page,
          });

          for (const entry of results) {
            await processEntry(entry).then(() => entry.save());
          }

          page += 1;
        }
      } finally {
        setProcessing(false);
      }
    };

    if (authentication.isGodAdmin) {
      return (
        <Popconfirm
          title="This action will OVERWRITE the existing descriptions. Are you SURE?"
          onConfirm={handleProcess}
          disabled={processing}
        >
          <Button disabled={processing}>Enhance description and Tags</Button>
        </Popconfirm>
      );
    }

    return null;
  },
);
