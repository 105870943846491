/**
 * Created by neo on 14.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AnalyticsData } from '../../../Model/Analytics/AnalyticsData';
import { Gym } from '../../../Model/Gym/Gym';
import { allUsersAgeHistory, queryUsersAgeHistory } from '../Queries/queryUsersAgeHistory';
import { AgeDistributionHistoryChart } from './AgeDistributionHistoryChart';

type DataType = (Record<string, number> & { name: string })[];

export type AgeDistributionHistoryProps = {
  gym?: Gym;
};

export const AgeDistributionHistory: React.FC<AgeDistributionHistoryProps> = observer(({ gym }) => {
  const [data, setData] = useState<any | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(undefined);
    setLoading(true);
    AnalyticsData.query({
      query: gym ? queryUsersAgeHistory : allUsersAgeHistory,
      parameters: {
        gymId: {
          value: gym?.id ?? '',
          type: 'string',
        },
      },
    })
      .then((result) =>
        setData(
          result.reduce((agg, curr) => {
            const existing = agg.find((e) => e.name === curr.event_date);
            if (existing) {
              existing[curr.age] = Number(curr.accumulated_num_of_users);
            } else {
              agg.push({
                name: curr.event_date,
                [curr.age]: Number(curr.accumulated_num_of_users),
              });
            }
            return agg;
          }, new Array<any>()),
        ),
      )
      .finally(() => setLoading(false));
  }, [gym]);

  if (data) {
    return <AgeDistributionHistoryChart data={data} />;
  }

  return null;
});
