/**
 * Created by neo on 01.11.2023
 */
import { AuditedJson } from '../../Audited';
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type CustomerPointsLedgerJson = AuditedJson & {
  customerId: string;
  athleteId: string;
  gymId: string;
  earnModelId: string;
  totalCollectedPoints: number;
  totalSpentPoints: number;
  usablePoints: number;
  timezone: string;
};

export class CustomerPointsLedger {
  @observable
  customerId = '';
  @observable
  athleteId = '';
  @observable
  gymId = '';
  @observable
  earnModelId = '';
  @observable
  totalCollectedPoints = 0;
  @observable
  totalSpentPoints = 0;
  @observable
  usablePoints = 0;
  @observable
  timezone = '';

  constructor(json?: Partial<CustomerPointsLedgerJson>) {
    if (json) {
      this.customerId = json.customerId ?? '';
      this.athleteId = json.athleteId ?? '';
      this.gymId = json.gymId ?? '';
      this.earnModelId = json.earnModelId ?? '';
      this.totalCollectedPoints = json.totalCollectedPoints ?? 0;
      this.totalSpentPoints = json.totalSpentPoints ?? 0;
      this.usablePoints = json.usablePoints ?? 0;
      this.timezone = json.timezone ?? '';
    }
  }

  static findByCustomerId(customerId: string): Promise<CustomerPointsLedger> {
    return HttpBackend.get('/engagement/ledger/admin/findByCustomerId', { customerId }).then(
      (res) => new CustomerPointsLedger(res),
    );
  }
}
