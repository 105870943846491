import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseTrackingKey, TrackingKey, TrackingKeys } from '../../../../../Model/ProgramPortfolio/TrackingKeys';
import { computed } from 'mobx';
import { ExerciseSet } from '../../../../../Model/WorkoutLog/ExerciseSet';

export type Props = {
  set: ExerciseSet;
  trackingKey: BaseTrackingKey;
};

@observer
export class SetsTableColumn extends React.Component<Props> {
  @computed
  get value(): string | undefined {
    const {
      props: { set, trackingKey },
    } = this;
    const value = set.values.get(trackingKey);
    if (trackingKey === 'BREAK') {
      return `${set.breakTimeFormatted}`;
    } else if (trackingKey === 'DURATION') {
      return `${set.durationFormatted}`;
    } else if (trackingKey === 'CALORIES') {
      return `${(Math.round(set.calories * 100) / 100).toFixed(2)}`;
    }
    return value !== undefined ? `${value}` : '-';
  }

  @computed
  get unit(): string {
    const {
      props: { trackingKey },
    } = this;
    if (this.value !== '-' && trackingKey !== 'DURATION' && trackingKey !== 'BREAK') {
      return TrackingKeys[trackingKey].defaultUnit || '';
    }
    return '';
  }

  render() {
    const { value, unit } = this;
    return <td>{`${value}${unit}`}</td>;
  }
}
