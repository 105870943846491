/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { Tag } from 'antd';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { SubscribedEventEntry } from '../../../../Model/Coach/Schedule/SubscribedEventEntry';
import { SubscribableEntry } from '../../../../Model/Explore/SubscribableEntry';

const Container = styled.div`
  display: flex;
  border-radius: 16px;
  flex: 1 0 23%;
  height: 240px;
  background-position: center center;
  background-size: cover;
  margin: 4px;
  flex-direction: column;
`;

const Overlay = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  border-radius: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  &:hover {
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 24px;
  color: white;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const TagContainer = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  align-items: flex-end;
`;

const CategoriesContainer = styled.div`
  display: flex;
  align-items: space-between;
  flex-wrap: wrap;
`;

const SubscribedCount = styled.div`
  color: white;
  text-align: center;
`;

export type ExploreEntryListEntryCardProps = {
  entry: ExploreEntry;
  gymId?: string;
  onClick?: (entry: ExploreEntry) => void;
};

export const ExploreEntryListEntryCard: React.FC<ExploreEntryListEntryCardProps> = observer(
  ({ entry, gymId, onClick }) => {
    const history = useNavigate();

    const [subscribedCount, setSubscribedCount] = useState(0);

    useEffect(() => {
      if (entry.type === 'onlineMeeting') {
        SubscribedEventEntry.count({ objectId: entry.id }).then((res) => setSubscribedCount(res));
      }
    }, [entry]);

    const handleSelect = React.useCallback(() => {
      if (onClick) {
        onClick(entry);
      } else {
        history(`/coach/explore/entries/${entry.id}/edit?gymId=${entry.gymId ?? ''}`);
      }
    }, [entry, onClick, history]);

    const handleGoToCategory = React.useCallback(
      (category: string) => {
        window.open(`/coach/explore/${category}?gymId=${entry.gymId ?? ''}`, '_blank');
      },
      [entry],
    );

    return (
      <Container style={{ backgroundImage: `url('${entry.image?.medium}')` }} onClick={handleSelect}>
        <Overlay>
          <div>
            <Tag color="blue">{entry.type}</Tag>
            <Tag color={entry.state === 'published' ? 'green' : entry.state === 'draft' ? 'orange' : 'red'}>
              {entry.state}
            </Tag>
            {entry.isAdmin && <Tag color="red">not visible</Tag>}
            {(entry.tags.length === 0 || entry.description.length <= 0) && (
              <Tag color="red">MISSING TAGS / DESCRIPTION</Tag>
            )}
          </div>
          <Title>{entry.defaultName}</Title>
          <TagContainer>{entry.tagString}</TagContainer>
          <SubscribedCount>
            Subscribed:
            <br />
            {entry.subscriberCount}
          </SubscribedCount>
          {entry.gym && <Tag color="yellow">{entry.gym?.name}</Tag>}
          <CategoriesContainer>
            {entry.categories.map((category) => (
              <Tag key={category} style={{ fontSize: 10 }} color="black" onClick={() => handleGoToCategory(category)}>
                {category}
              </Tag>
            ))}
          </CategoriesContainer>
        </Overlay>
      </Container>
    );
  },
);
