export const allWorkoutConversion = (workoutType?: string) => `
SELECT *, IFNULL(t.event_date, s.event_date) as date_key, LEAST((t.count / s.select_count) * 100, 100) as converted  FROM (
    SELECT
    event_date,
    COUNT(DISTINCT e1.value.string_value) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e,
    ${workoutType ? `UNNEST(event_params) type,` : ''}
    UNNEST(event_params) e1
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = 'Workout_finished'
    AND e.key = 'percentageCompleted'
    AND e1.key = 'workoutLogId'
    ${workoutType ? `AND type.key = 'workoutType' AND type.value.string_value = '${workoutType}'` : ''}

    GROUP BY event_date
) t
LEFT JOIN (
  SELECT
    event_date,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = 'workout'
  GROUP BY event_date
) s
  ON t.event_date = s.event_date
  ORDER BY t.event_date
`;

export const gymWorkoutConversion = (workoutType?: string) => `
SELECT *, IFNULL(t.event_date, s.event_date) as date_key, LEAST((t.count / s.select_count) * 100, 100) as converted  FROM (
    SELECT
    event_date,
    COUNT(DISTINCT e1.value.string_value) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e,
    ${workoutType ? `UNNEST(event_params) type,` : ''}
    UNNEST(event_params) e1
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = 'Workout_finished'
    AND e.key = 'percentageCompleted'
    AND e1.key = 'workoutLogId'
    ${workoutType ? `AND type.key = 'workoutType' AND type.value.string_value = '${workoutType}'` : ''}
  GROUP BY event_date
) t
LEFT OUTER JOIN (
  SELECT
    event_date,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = 'workout'
  GROUP BY event_date
) s
  ON t.event_date = s.event_date
  ORDER BY t.event_date
`;

export const allActivityConversion = (event: string, contentType: string) => `
SELECT *, IFNULL(t.event_date, s.event_date) as date_key, LEAST((t.count / s.select_count) * 100, 100) as converted  FROM (
    SELECT
    event_date,
    COUNT(*) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = '${event}'
    AND e.key = 'percentageCompleted'
  GROUP BY event_date
) t
LEFT JOIN (
  SELECT
    event_date,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = '${contentType}'
  GROUP BY event_date
) s
  ON t.event_date = s.event_date
  ORDER BY t.event_date
`;

export const gymActivityConversion = (event: string, contentType: string) => `
SELECT *, IFNULL(t.event_date, s.event_date) as date_key, LEAST((t.count / s.select_count) * 100, 100) as converted  FROM (
    SELECT
    event_date,
    COUNT(*) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = '${event}'
    AND e.key = 'percentageCompleted'
  GROUP BY event_date
) t
LEFT JOIN (
  SELECT
    event_date,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = '${contentType}'
  GROUP BY event_date
) s
  ON t.event_date = s.event_date
  ORDER BY t.event_date
`;
