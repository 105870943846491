/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Input } from 'antd';
import { HealthDataPointQuantity } from '../../../../Model/HealthData/HealthDataPointQuantity';

export type HealthDataPointQuantityEntryProps = {
  dataPoint: HealthDataPointQuantity;
  onSave?: (point: HealthDataPointQuantity) => void;
};

export const HealthDataPointQuantityEntry: React.FC<HealthDataPointQuantityEntryProps> = observer(
  ({ dataPoint, onSave }) => {
    const edited = React.useRef(false);
    const newPoint = React.useMemo(
      () => dataPoint.clone({ startDate: new Date().toISOString(), endDate: new Date().toISOString() }),
      [dataPoint],
    );

    const handleChangeValue = React.useCallback(
      ({ target: { value } }: any) => {
        edited.current = true;
        newPoint.value = Number(value);
      },
      [newPoint],
    );

    const handleSave = React.useCallback(() => {
      if (edited.current) {
        newPoint.save();
        onSave && onSave(newPoint);
      }
    }, [newPoint, onSave]);

    return (
      <Row style={{ marginTop: 4, marginBottom: 4 }}>
        <Col>
          <Input type="text" value={newPoint.type} disabled={true} />
        </Col>
        <Col>
          <Input type="text" onChange={handleChangeValue} value={newPoint.value} onBlur={handleSave} />
        </Col>
      </Row>
    );
  },
);
