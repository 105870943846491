/**
 * Created by neo on 19.12.20.
 */
import { DietEquipment, DietEquipmentJson } from '../Equipment/DietEquipment';
import { observable } from 'mobx';

export type RecipeEquipmentJson = DietEquipmentJson & {
  temperatureCelsius?: number;
};

export class RecipeEquipment extends DietEquipment {
  @observable
  temperatureCelsius?: number;

  constructor(json?: Partial<RecipeEquipmentJson>) {
    super(json);
    if (json) {
      this.temperatureCelsius = json.temperatureCelsius;
    }
  }

  toJS(): RecipeEquipmentJson {
    return Object.assign(super.toJS(), {
      temperatureCelsius: this.temperatureCelsius,
    });
  }
}
