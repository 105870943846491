import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { ReverseStage } from '../../../../../../../Model/Coach/Stages/ReverseStage';

export class ReverseNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: ReverseStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'reverse' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
