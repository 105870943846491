export const allSignupOnboardingDaily = `
    SELECT *,
           SUM(sign_up_total) OVER (PARTITION BY 1 ORDER BY signup_date) AS sign_up_cumulative, 
           SUM(onboarding_completed_total) OVER (PARTITION BY 1 ORDER BY onboarding_date) AS onboarding_completed_cumulative
    FROM (
             SELECT *,
                    s.event_date as signup_date,
                    t.event_date as onboarding_date
             FROM (
                      SELECT event_date,
                             IFNULL(COUNT(DISTINCT user_pseudo_id), 0) AS sign_up_total
                      FROM \`flinq-6796d.analytics_201736462.events_*\`
                      WHERE _TABLE_SUFFIX BETWEEN @start_date AND @end_date
                        AND event_name = 'sign_up'
                      GROUP BY
                          event_date
                      ORDER BY
                          event_date) s
                      LEFT OUTER JOIN (
                 SELECT event_date,
                        IFNULL(COUNT(DISTINCT user_pseudo_id), 0) AS onboarding_completed_total
                 FROM \`flinq-6796d.analytics_201736462.events_*\`
                 WHERE _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      AND event_name = 'onboarding_completed'
                 GROUP BY
                     event_date
                 ORDER BY
                     event_date) t
                                      ON
                                          t.event_date = s.event_date)
    ORDER BY signup_date
`;

export const signupOnboardingDaily = `
    SELECT m.event_date,
           IFNULL(onboarding_completed_total, 0) as onboarding_completed_total,
           IFNULL(sign_up_total, 0)              as signup_total,
           SUM(IFNULL(sign_up_total, 0))            OVER (PARTITION BY 1 ORDER BY m.event_date) AS sign_up_cumulative, SUM(IFNULL(onboarding_completed_total, 0)) OVER (PARTITION BY 1 ORDER BY t.event_date) AS onboarding_completed_cumulative
    FROM (
             SELECT ocb.event_date, IFNULL(COUNT(user_pseudo_id), 0) as sign_up_total
             FROM (
                      SELECT oc.event_date as event_date,
                             oc.user_id,
                             oc.user_pseudo_id,
                             'signup'      as type
                      FROM (
                               SELECT event_date,
                                      user_id,
                                      user_pseudo_id,
                                      ROW_NUMBER() OVER (PARTITION BY user_pseudo_id ORDER BY event_timestamp ASC) AS rn
                               FROM \`flinq-6796d.analytics_201736462.events_*\`,
                                    UNNEST(user_properties) p
                               WHERE _TABLE_SUFFIX BETWEEN @start_date AND @end_date
                                AND p.key = 'gymId'
                                AND p.value.string_value = @gym_id
                               ORDER BY event_date) oc
                      WHERE oc.rn = 1
                  ) ocb
             GROUP BY ocb.event_date
         ) m
             LEFT JOIN (
        SELECT onboarding.event_date, IFNULL(COUNT(user_pseudo_id), 0) as onboarding_completed_total
        FROM (
                 SELECT event_date,
                        user_pseudo_id,
                        type
                 FROM (
                          SELECT event_date,
                                 user_pseudo_id,
                                 'onboarding' as type,
                                 ROW_NUMBER()    OVER (PARTITION BY user_pseudo_id ORDER BY event_timestamp ASC) AS rn
                          FROM \`flinq-6796d.analytics_201736462.events_*\`,
                               UNNEST(user_properties) p
                          WHERE _TABLE_SUFFIX BETWEEN @start_date AND @end_date
                          AND event_name = 'onboarding_completed'
                          AND p.key = 'gymId'
                          AND p.value.string_value = @gym_id
                          ORDER BY event_date) oc
                 WHERE oc.rn = 1
             ) onboarding
        GROUP BY onboarding.event_date
    ) t ON m.event_date = t.event_date
    ORDER BY m.event_date ASC
`;
