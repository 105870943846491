/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseVariationFilterMultipleSelection, ValueEntry } from './ExerciseVariationFilterMultipleSelection';

export type Props = {
  filter: ExerciseFilter;
};

const Values = [
  { value: 'BALANCE', label: 'Balance' },
  { value: 'ENDURANCE', label: 'Endurance' },
  { value: 'FLEXIBILITY', label: 'Flexibility' },
  { value: 'MEDITATION', label: 'Meditation' },
  { value: 'MOBILISATION', label: 'Mobilisation' },
  { value: 'RELAX', label: 'Relax' },
  { value: 'STRENGTH', label: 'Strength' },
  { value: 'WORKPLACE', label: 'Workplace' },
  { value: 'YOGA', label: 'Yoga' },
];

@observer
export class ExerciseVariationFilterType extends React.Component<Props> {
  handleChange = (values: ValueEntry[]) => {
    this.props.filter.type = (values ?? []).map((e) => e.value);
  };

  render() {
    const {
      props: { filter },
    } = this;
    return (
      <ExerciseVariationFilterMultipleSelection
        label="Exercise Type"
        container={filter.type || []}
        values={Values}
        onChange={this.handleChange}
      />
    );
  }
}
