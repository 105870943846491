/**
 * Created by neo on 07.06.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import { useEffect, useState } from 'react';
import { PageResult } from '../../../../../Model/PageResult';
import { Col, Row, Table } from 'reactstrap';
import { Pager } from '../../../../../Components/Pager';
import { Alert, Button } from 'antd';
import { ExploreFaqRequest } from '../../../../../Model/Explore/ExploreFaqRequest';
import { Link } from 'react-router-dom';

export type FaqRequestsAnsweredTabContentJson = {
  entry: ExploreEntry;
};

export const FaqRequestsAnsweredTabContent: React.FC<FaqRequestsAnsweredTabContentJson> = observer(({ entry }) => {
  const [results, setResults] = useState<PageResult<ExploreFaqRequest> | undefined>();
  const [page, setPage] = useState(0);

  const hasNoContent = !results || results?.content.length === 0;

  useEffect(() => {
    PageResult.execute(
      ExploreFaqRequest.find({ page, size: 20, contentId: entry.id, answered: true }),
      ExploreFaqRequest.count({ contentId: entry.id, answered: true}),
      page,
      20,
    ).then((result) => setResults(result));
  }, [entry, page]);

  const handlePage = (newPage: number) => setPage(newPage);

  return (
    <Row>
      {results && (
        <Col xs={12}>
          <Pager page={results} onPage={handlePage} />
        </Col>
      )}
      <Col xs={12}>
        {hasNoContent && <Alert message="No requests avilable yet" type="warning" />}
        <Table size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Question</th>
              <th>Asked by</th>
              <th>Company</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {results?.content.map((request) => (
              <tr key={request.id}>
                <td>{request.id}</td>
                <td>{request.question}</td>
                <td>
                  {request.athlete && (
                    <Link to={`/athlete/${request.athleteId}`}>
                      {request.athlete.fullName || request.athlete.nickname || request.athlete.user.email}
                    </Link>
                  )}
                </td>
                <td>{request.gym?.name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      {results && (
        <Col xs={12}>
          <Pager page={results} onPage={handlePage} />
        </Col>
      )}
    </Row>
  );
});
