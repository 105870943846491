/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Input, Label, FormGroup } from 'reactstrap';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { SingleColRow } from '../SingleColRow';
import CreatableSelect from 'react-select/creatable';

export type ExerciseVariationFilterTagsProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterTags: React.FC<ExerciseVariationFilterTagsProps> = observer(({ filter }) => {
  const handleChange = React.useCallback(
    (tags: { value: string; label: string }[]) => {
      filter.tags = (tags ?? []).map((e) => e.value);
    },
    [filter],
  );

  return (
    <FormGroup>
      <Label>Tags</Label>
      <CreatableSelect
        value={(filter.tags || []).map((value) => ({ value, label: value }))}
        isClearable
        isMulti
        onChange={handleChange as any}
      />
    </FormGroup>
  );
});

// const ExerciseVariationsTab = withRouter(ExerciseVariationsTabComponent);
// export default ExerciseVariationsTab;
