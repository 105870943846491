/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { SingleColRow } from '../SingleColRow';
import { FormGroup, Input, Label } from 'reactstrap';
import { ExerciseVariationFilterMultipleSelection, ValueEntry } from './ExerciseVariationFilterMultipleSelection';
import { Select } from 'antd';

export type Props = {
  filter: ExerciseFilter;
};

const Values = [
  { value: 'straight', label: 'Straight' },
  { value: 'inclined_front', label: 'Inclined Front' },
  { value: 'inclined_back', label: 'Inclined Back' },
  { value: 'inclined_side', label: 'Inclined Side' },
  { value: 'back', label: 'On the back' },
  { value: 'belly', label: 'On the belly' },
  { value: 'side', label: 'Side' },
];

@observer
export class ExerciseVariationFilterPositionSecondary extends React.Component<Props> {
  handleChange = (values: ValueEntry[]) => {
    this.props.filter.secondaryPositions = (values ?? []).map((e) => e.value);
  };

  @computed
  get hasValue(): boolean {
    return !!this.props.filter.secondaryPositions;
  }

  render() {
    const {
      props: { filter },
    } = this;
    return (
      <ExerciseVariationFilterMultipleSelection
        label="Secondary Position"
        container={filter.secondaryPositions || []}
        values={Values}
        onChange={this.handleChange}
      />
    );
  }
}

// const ExerciseVariationsTab = withRouter(ExerciseVariationsTabComponent);
// export default ExerciseVariationsTab;
