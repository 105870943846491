export const usersQuery = `SELECT
      user_id, event_timestamp
      FROM (
          SELECT
          user_id, event_timestamp, ROW_NUMBER() OVER (PARTITION BY user_id ORDER BY event_timestamp) as rn
          FROM
              \`flinq-6796d.analytics_201736462.events_*\`,
              UNNEST(event_params) e,
              UNNEST(user_properties) p
          WHERE
              _TABLE_SUFFIX BETWEEN @gym_start_date AND @end_date
              AND p.key = 'gymId'
              AND p.value.string_value = @gym_id
              AND e.key = 'athleteId'
          ORDER BY event_timestamp) users
      WHERE users.rn = 1`;

export const allUsersQuery = `SELECT
      user_id, event_timestamp
      FROM (
          SELECT
          user_id, event_timestamp, ROW_NUMBER() OVER (PARTITION BY user_id ORDER BY event_timestamp) as rn
          FROM
              \`flinq-6796d.analytics_201736462.events_*\`,
              UNNEST(event_params) e
          WHERE
              _TABLE_SUFFIX BETWEEN @gym_start_date AND @end_date
              AND e.key = 'athleteId'
          ORDER BY event_timestamp) users
      WHERE users.rn = 1`;

export const activityLoggedCount = `SELECT t.user_id, COUNT(t.user_id) as activity_logged_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t
--           UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'activity_logged'
--           AND e.key = 'content_type'
--           AND e.value.string_value = 'article'
      GROUP BY t.user_id`;

export const articleCount = `SELECT t.user_id, COUNT(t.user_id) as article_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t
--           UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'FeedEntry_read'
--           AND e.key = 'content_type'
--           AND e.value.string_value = 'article'
      GROUP BY t.user_id`;

export const feedEntryReadDuration = `SELECT t.user_id, AVG(e.value.double_value) as article_avg FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'FeedEntry_read'
          AND e.key = 'engagement_time_sec'
          AND e.value.double_value IS NOT NULL
      GROUP BY user_id`;

export const recipeCount = `SELECT t.user_id, COUNT(t.user_id) as recipe_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND e.key = 'content_type'
          AND e.value.string_value = 'recipe'
      GROUP BY t.user_id`;

export const breathingCount = `SELECT t.user_id, COUNT(t.user_id) as breathing_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'breathingSession_finished'
      GROUP BY user_id`;

export const breathingAvgCompleted = `SELECT t.user_id, AVG(e.value.double_value) as breathing_avg_percentage FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'breathingSession_finished'
          AND e.key = 'percentageCompleted'
          AND e.value.double_value IS NOT NULL
      GROUP BY user_id`;

export const breathingAvgDuration = `SELECT t.user_id, AVG(e.value.double_value) as breathing_avg_duration FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'breathingSession_finished'
          AND e.key = 'durationInSeconds'
          AND e.value.double_value IS NOT NULL
      GROUP BY user_id`;

export const meditationCount = `SELECT t.user_id, COUNT(t.user_id) as meditation_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'meditationSession_finished'
      GROUP BY user_id`;

export const meditationAvgCompleted = `SELECT t.user_id, AVG(e.value.double_value) as meditation_avg_percentage FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'meditationSession_finished'
          AND e.key = 'percentageCompleted'
          AND e.value.double_value IS NOT NULL
      GROUP BY user_id`;

export const mediationAvgDuration = `SELECT t.user_id, AVG(e.value.double_value) as meditation_avg_duration FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'meditationSession_finished'
          AND e.key = 'durationInSeconds'
          AND e.value.double_value IS NOT NULL
      GROUP BY user_id`;

export const yogaCount = `SELECT t.user_id, COUNT(t.user_id) as yoga_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'yogaSession_finished'
      GROUP BY user_id`;

export const yogaAvgCompleted = `SELECT t.user_id, AVG(e.value.double_value) as yoga_avg_percentage FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'yogaSession_finished'
          AND e.key = 'percentageCompleted'
          AND e.value.double_value IS NOT NULL
      GROUP BY user_id`;

export const yogaAvgDuration = `SELECT t.user_id, AVG(e.value.double_value) as yoga_avg_duration FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'yogaSession_finished'
          AND e.key = 'durationInSeconds'
          AND e.value.double_value IS NOT NULL
      GROUP BY user_id`;

export const workoutCount = `SELECT t.user_id, COUNT(t.user_id) as workout_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND t.event_name = 'Workout_finished'
          AND e.key = 'workoutType'
          AND e.value.string_value <> 'workplace'
      GROUP BY user_id`;

export const workoutAvgCompleted = `SELECT t.user_id, AVG(e.value.double_value) as workout_avg_percentage FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e,
          UNNEST(event_params) et
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND t.event_name = 'Workout_finished'
          AND e.key = 'percentageCompleted'
          AND e.value.double_value IS NOT NULL
          AND et.key = 'workoutType'
          AND et.value.string_value <> 'workplace'
      GROUP BY user_id`;

export const workoutAvgCompletedExercises = `SELECT t.user_id, AVG(e.value.double_value) as workout_avg_completed_exercises FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e,
          UNNEST(event_params) et
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'Workout_finished'
          AND e.key = 'completedExercises'
          AND e.value.double_value IS NOT NULL
          AND et.key = 'workoutType'
          AND et.value.string_value <> 'workplace'
      GROUP BY user_id`;

export const workoutAvgDuration = `SELECT t.user_id, AVG(e.value.double_value) as workout_avg_duration_seconds FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e,
          UNNEST(event_params) et
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'Workout_finished'
          AND e.key = 'durationInSeconds'
          AND e.value.double_value IS NOT NULL
          AND et.key = 'workoutType'
          AND et.value.string_value <> 'workplace'
      GROUP BY user_id`;

export const workplaceCount = `SELECT t.user_id, COUNT(t.user_id) as workplace_count FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND t.event_name = 'Workout_finished'
          AND e.key = 'workoutType'
          AND e.value.string_value = 'workplace'
      GROUP BY user_id`;

export const workplaceAvgCompleted = `SELECT t.user_id, AVG(e.value.double_value) as workplace_avg_percentage FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e,
          UNNEST(event_params) et
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND t.event_name = 'Workout_finished'
          AND e.key = 'percentageCompleted'
          AND e.value.double_value IS NOT NULL
          AND et.key = 'workoutType'
          AND et.value.string_value = 'workplace'
      GROUP BY user_id`;

export const workplaceAvgCompletedExercises = `SELECT t.user_id, AVG(e.value.double_value) as workplace_avg_completed_exercises FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e,
          UNNEST(event_params) et
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'Workout_finished'
          AND e.key = 'completedExercises'
          AND e.value.double_value IS NOT NULL
          AND et.key = 'workoutType'
          AND et.value.string_value = 'workplace'
      GROUP BY user_id`;

export const workplaceAvgDuration = `SELECT t.user_id, AVG(e.value.double_value) as workplace_avg_duration_seconds FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e,
          UNNEST(event_params) et
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'Workout_finished'
          AND e.key = 'durationInSeconds'
          AND e.value.double_value IS NOT NULL
          AND et.key = 'workoutType'
          AND et.value.string_value = 'workplace'
      GROUP BY user_id`;

export const engagementDuration = `SELECT t.user_id, SUM(e.value.int_value) / 1000.0 as avg_engagement_seconds FROM
          \`flinq-6796d.analytics_201736462.events_*\` t,
          UNNEST(event_params) e
      WHERE
          _TABLE_SUFFIX BETWEEN @start_date AND @end_date
          AND event_name = 'user_engagement'
          AND e.key = 'engagement_time_msec'
          AND e.value.int_value IS NOT NULL
      GROUP BY user_id`;

export const hugeUserQueries = [
  activityLoggedCount,
  articleCount,
  feedEntryReadDuration,
  recipeCount,
  breathingCount,
  breathingAvgCompleted,
  breathingAvgDuration,
  meditationCount,
  meditationAvgCompleted,
  mediationAvgDuration,
  yogaCount,
  yogaAvgCompleted,
  yogaAvgDuration,
  workoutCount,
  workoutAvgCompleted,
  workoutAvgCompletedExercises,
  workoutAvgDuration,
  workplaceCount,
  workplaceAvgCompleted,
  workplaceAvgCompletedExercises,
  workplaceAvgDuration,
  engagementDuration,
];
