/**
 * Created by neo on 25.05.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MergedTranslation } from '../../../Model/Translation/MergedTranslation';
import { TranslationTableRowEntry } from './TranslationTableRowEntry';
import { Button, message, Modal, Select, Space } from 'antd';
import { runInAction } from 'mobx';
import { availableLanguages } from '../../../Utils/availableLanguages';
import { TranslationKey } from '../../../Model/Translation/TranslationKey';
import copy from 'copy-to-clipboard';

export type TranslationTableRowProps = {
  mergedTranslation: MergedTranslation;
  index: number;
  namespaces: string[];
};

export const TranslationTableRow: React.FC<TranslationTableRowProps> = observer(
  ({ mergedTranslation, index, namespaces }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const missingLanguages = availableLanguages.filter(
      (l) => !mergedTranslation.keys.some((k) => k.language === l.value),
    );

    const handleDeleteKey = React.useCallback(() => {
      Modal.confirm({
        title: `Delete ${mergedTranslation.id}?`,
        onOk() {
          Promise.all(mergedTranslation.keys.map((k) => k.delete()));
          // translation.deleteKey(key);
        },
      });
    }, [mergedTranslation]);

    const handleAddLanguage = React.useCallback(() => {
      let language = missingLanguages[0]?.value;
      if (language) {
        Modal.confirm({
          type: 'confirm',
          content: (
            <Select style={{ minWidth: 120 }} value={language} onSelect={(value) => (language = value)}>
              {missingLanguages.map((lang) => (
                <Select.Option key={lang.value} value={lang.value}>
                  {lang.label}
                </Select.Option>
              ))}
            </Select>
          ),
          okText: 'Add Language',
          onOk: () => {
            console.log(language.trim());
            new TranslationKey({
              language: language.toLowerCase().trim(),
              key: mergedTranslation.id,
              namespace: mergedTranslation.namespace,
            })
              .save()
              .then((res) => runInAction(() => mergedTranslation.keys.push(res)));
            // mergedObject?.addLanguage(language.trim());
          },
        });
      }
    }, [mergedTranslation, missingLanguages]);

    const handleAddMissingLanguages = React.useCallback(() => {
      Promise.all(
        missingLanguages.map((language) =>
          new TranslationKey({
            language: language.value,
            key: mergedTranslation.id,
            namespace: mergedTranslation.namespace,
          }).save(),
        ),
      ).then((result) => runInAction(() => (mergedTranslation.keys = mergedTranslation.keys.concat(result))));
    }, [mergedTranslation, missingLanguages]);

    const handleChangeNamespace = React.useCallback(
      (ns?: string) => {
        if (ns) {
          Promise.all(
            mergedTranslation.keys.map((k) => {
              runInAction(() => (k.namespace = ns));
              return k.save();
            }),
          ).then(() => runInAction(() => (mergedTranslation.namespace = ns)));
        }
      },
      [mergedTranslation],
    );

    const handleCopy = React.useCallback(
      (e) => {
        if (copy(mergedTranslation.id)) {
          e.preventDefault();
          messageApi.success('Copied to clipboard');
        }
      },
      [mergedTranslation, messageApi],
    );

    return (
      <tr>
        <td>{`${index + 1}.`}</td>
        <td>
          {contextHolder}
          <a href="" onClick={handleCopy}>
            {mergedTranslation.id}
          </a>
          <br />
          <div>
            <Select
              value={mergedTranslation.namespace}
              style={{ minWidth: 360 }}
              onChange={handleChangeNamespace}
              disabled={true}
            >
              <Select.Option>{'<all namespaces>'}</Select.Option>
              {namespaces.map((ns) => (
                <Select.Option key={ns} value={ns}>
                  {ns}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Space>
            <Button danger size="small" onClick={handleDeleteKey}>
              Delete Key
            </Button>
            {missingLanguages.length > 0 && (
              <React.Fragment>
                <Button type="primary" size="small" onClick={handleAddLanguage}>
                  Add Language
                </Button>
                <Button type="primary" size="small" onClick={handleAddMissingLanguages}>
                  Add Missing Languages
                </Button>
              </React.Fragment>
            )}
          </Space>
        </td>
        <td>
          {mergedTranslation.keys.map((trans) => (
            <TranslationTableRowEntry key={trans.language} translation={mergedTranslation} entry={trans} />
          ))}
        </td>
      </tr>
    );
  },
);
