import { HttpBackend } from '../../Services/Http/HttpBackend';
import { action, computed, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Stage } from './Stages/Stage';
import { PipelineBuilder, PipelineDefinition } from './PipelineBuilder';
import { PipelineContext } from './PipelineContext';
import { notUndefined } from '../../Utils/notUndefined';
import { StartSignal } from './Signal/StartSignal';
import { Signal } from './Signal/Signal';
import { SinkStage } from './Stages/SinkStage';
import { StartStage } from './Stages/StartStage';
import { DataSignal } from './Signal/DataSignal';
import { EndOfStreamSignal } from './Signal/EndOfStreamSignal';
import { PipelineParam } from './PipelineParam';
import { paramSort } from './SuperMacro/paramSort';

export type PipelineEdge = {
  from: string;
  to: string;
  type?: string;
  inType?: string;
};

export class Pipeline {
  @observable
  id: string = UUID();
  @observable
  name: string = '';
  @observable
  tags: string[] = [];
  @observable
  pipeline: Stage<any>[] = [];
  @observable
  edges: PipelineEdge[] = [];
  @observable
  context: PipelineContext = new PipelineContext();
  @observable
  params: PipelineParam[] = [];
  start = new StartStage(this);
  sink = new SinkStage(this);

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.name = json.name || '';
      this.tags = json.tags || [];
      this.pipeline = json.pipeline || [];
      this.edges = json.edges ?? [];
      this.params = (json.params ?? []).map((p) => new PipelineParam(p));
    }
  }

  toJS(newId?: boolean): any {
    return {
      id: newId ? UUID() : this.id,
      name: this.name,
      tags: toJS(this.tags),
      pipeline: this.pipeline.map((p) => p.toJS()), // do not pass newId true since the edges won't match anymore
      edges: this.edges.length > 0 ? toJS(this.edges) : this.computedEdges,
      params: this.params.map((p) => p.toJS()),
      // edges: this.computedEdges,
      // edges: this.pipeline.flatMap((s) => s.nextStages.map((n) => ({ from: s.id, to: n.id }))),
    };
  }

  toSaveJS(): Promise<any> {
    return Promise.all(this.pipeline.map((p) => p.toSaveJS())).then((pipeline) =>
      Object.assign(this.toJS(), { pipeline }),
    );
  }

  @action
  link() {
    console.log('linking pipeline', toJS(this.edges));
    this.edges.forEach((edge) => {
      const from = this.pipeline.find((s) => s.id === edge.from);
      const to = this.pipeline.find((s) => s.id === edge.to);
      if (!from) {
        console.warn('source stage not found', edge.from);
      }
      if (!to) {
        console.warn('destination stage not found', edge.to);
      }
      from?.connectTo(to, edge.type, edge.inType);
    });
  }

  //TODO remove
  get computedEdges(): Array<any> {
    return this.pipeline
      .map((stage, index) => {
        const next = index < this.pipeline.length - 1 ? this.pipeline[index + 1] : undefined;
        if (next) {
          return { from: stage.id, to: next.id };
        }
        return undefined;
      })
      .filter(notUndefined);
  }

  copy(): Pipeline {
    const json = this.toJS(true);
    json.name = `${json.name} (Copy)`;
    return Pipeline.fromJSON(json);
  }

  @action
  connect(from: string, to: string, fromPortType: string = 'default', toPortType: string = 'in') {
    const nodeFrom = this.pipeline.find((n) => n.id === from);
    const nodeTo = this.pipeline.find((n) => n.id === to);
    if (nodeFrom && nodeTo && !this.edges.find((e) => e.from === from && e.to === to)) {
      console.log('connecting', `${from}:${fromPortType}`, `${to}:${toPortType}`);
      nodeFrom.connectTo(nodeTo, fromPortType, toPortType);
      this.edges.push({ from, to, type: fromPortType, inType: toPortType });
    }
  }

  @action
  disconnect(from: string, to: string) {
    const nodeFrom = this.pipeline.find((n) => n.id === from);
    const nodeTo = this.pipeline.find((n) => n.id === to);
    const index = this.edges.findIndex((e) => e.from === from && e.to === to);
    if (index !== -1) {
      const edge = this.edges[index];
      this.edges.splice(index, 1);
      nodeFrom?.disconnectFrom(nodeTo, edge.type);
    }
  }

  @action
  addStage(stage: Stage<any>) {
    if (this.pipeline.findIndex((s) => s.id === stage.id) === -1) {
      this.pipeline.push(stage);
    }
  }

  @action
  addEdge(edge: PipelineEdge) {
    if (this.edges.findIndex((e) => e.from === edge.from && e.to === edge.to) === -1) {
      this.edges.push(edge);
    }
  }

  @action
  removeStage(stage: Stage<any>) {
    console.log('removing stage', stage.id, stage.type);
    this.pipeline = this.pipeline.filter((s) => s.id !== stage.id);
    this.edges = this.edges.filter((e) => e.from !== stage.id && e.to !== stage.id);
    // const previous = index > 0 ? this.pipeline[index - 1] : undefined;
    // this.pipeline.splice(index, 1);
    // const next = this.pipeline[index];
    // if (previous) {
    //   previous.connectTo(next);
    // }
  }

  @action
  moveStage(index: number, newIndex: number) {
    if (index !== newIndex) {
      const current = this.pipeline.splice(index, 1)[0];
      this.pipeline.splice(newIndex, 0, current);
      let previous: Stage<any> | undefined = undefined;
      this.pipeline.reverse().forEach((stage) => {
        stage.connectTo(previous);
        previous = stage;
      });
    }
  }

  save(): Promise<Pipeline> {
    return this.toSaveJS()
      .then((json) => HttpBackend.post('/coach/program/template/pipeline', json))
      .then(() => this);
  }

  delete() {
    return HttpBackend.delete(`/coach/program/template/pipeline/${this.id}`);
  }

  async compile(): Promise<Pipeline> {
    return Pipeline.compile(this.toJS());
  }

  emit(signal: Signal) {
    this.start.sendSignal(signal);
  }

  private setupExecution(subscriber?: (signal: Signal) => any, complete?: () => any) {
    const { firstStages, lastStages } = this;
    const existingSink = lastStages.find((s) => s instanceof SinkStage);
    const sink: SinkStage = existingSink ?? this.sink;
    sink.subscriber = subscriber;
    sink.complete = complete;
    firstStages.forEach((stage) => this.start.connectTo(stage));
    if (!existingSink) {
      console.log('no sink found -> adding', toJS(lastStages));
      lastStages.forEach((stage) => stage.connectTo(this.sink));
    } else {
      console.log('got existing sink');
    }
    console.log('executing pipeline', toJS(firstStages));
  }

  executeLocal(
    signals: Signal[] = [],
    context: PipelineContext = new PipelineContext(),
    subscriber?: (signal: Signal) => any,
    complete?: () => any,
  ) {
    this.context = context;
    if (subscriber) {
      this.setupExecution(subscriber, complete);
      let startSignals = signals;
      if (signals.length === 0) {
        startSignals = [new StartSignal(), new EndOfStreamSignal()];
      }
      startSignals.forEach((signal) => this.emit(signal));
      // firstStages.forEach((stage) => stage.availablePorts.forEach((port) => port.signal(signal ?? new StartSignal())));
      // return Promise.all(firstStages.map((s) => s.process())).then((result) =>
      //   result.flatMap((r) => r),
      // );
    }
  }

  executePromise(context: PipelineContext = new PipelineContext(), signals: Signal[] = []): Promise<DataSignal[]> {
    return new Promise((resolve, reject) => {
      const result: any[] = [];
      this.executeLocal(
        signals,
        context,
        (signal) => {
          if (signal instanceof DataSignal) {
            result.push(signal);
          }
        },
        () => {
          resolve(result);
        },
      );
    });
  }

  execute(context: PipelineContext = new PipelineContext()) {
    return HttpBackend.post(`/coach/program/template/pipeline/execute`, {
      script: this.toJS(),
      context: context.toJS(),
    });
  }

  @computed
  get firstStage(): Stage<any> | undefined {
    return this.firstStages[0];
  }

  @computed
  get firstStages(): Stage<any>[] {
    return this.pipeline.filter((p) => this.edges.findIndex((e) => e.to === p.id) === -1);
    // return this.edges
    //   .filter((edge) => this.edges.findIndex((e0) => edge.from === e0.to) === -1)
    //   .map((edge) => this.pipeline.find((p) => p.id === edge.from))
    //   .filter(notUndefined).concat((this.pipeline.find(p => (this.edges.findIndex(e => e.from === ))));
  }

  @computed
  get lastStages(): Stage<any>[] {
    return this.pipeline.filter((stage) => !this.edges.find((edge) => edge.from === stage.id));
  }

  @computed
  get paramsSorted(): PipelineParam[] {
    return this.params.sort((a, b) => {
      const aIndex = paramSort.indexOf(a.name) === -1 ? Number.MAX_SAFE_INTEGER : paramSort.indexOf(a.name);
      const bIndex = paramSort.indexOf(b.name) === -1 ? Number.MAX_SAFE_INTEGER : paramSort.indexOf(b.name);
      return aIndex - bIndex;
    });
  }

  static async compile(json?: any) {
    const { pipeline: stages } = json;
    delete json.pipeline;
    const pipeline = new Pipeline(json);
    pipeline.pipeline = await PipelineBuilder.compile(pipeline, stages);
    pipeline.link();
    return pipeline;
  }

  static fromJSON(json?: any) {
    const { pipeline: stages } = json;
    delete json.pipeline;
    const pipeline = new Pipeline(json);
    pipeline.pipeline = PipelineBuilder.deserialize(pipeline, stages);
    console.log('fromJSON', toJS(pipeline.pipeline));
    pipeline.link();
    return pipeline;
  }

  static find(params?: any): Promise<Pipeline[]> {
    return HttpBackend.get('/coach/program/template/pipeline', params).then((result) =>
      result.map((r) => Pipeline.fromJSON(r)),
    );
  }

  static count(params?: any): Promise<number> {
    return HttpBackend.get('/coach/program/template/pipeline/count', params);
  }

  static list(params?: any): Promise<Pipeline[]> {
    return HttpBackend.get('/coach/program/template/pipeline/list', params).then((result) =>
      result.map((r) => Pipeline.fromJSON(r)),
    );
  }

  static get(id: string): Promise<Pipeline | undefined> {
    return HttpBackend.get(`/coach/program/template/pipeline/${id}`).then((r) =>
      r ? Pipeline.fromJSON(r) : undefined,
    );
  }

  static getAll(ids: string[]): Promise<Pipeline[]> {
    if (ids.length > 0) {
      return Promise.all(ids.map((id) => this.get(id))).then((result) => result.filter(notUndefined));
    }
    return Promise.resolve([]);
  }

  static getCompiled(id: string): Promise<Pipeline | undefined> {
    return HttpBackend.get(`/coach/program/template/pipeline/${id}`).then((r) => (r ? Pipeline.compile(r) : undefined));
  }

  static getAllCompiled(ids: string[]): Promise<Pipeline[]> {
    if (ids.length > 0) {
      return Promise.all(ids.map((id) => this.getCompiled(id))).then((result) => result.filter(notUndefined));
    }
    return Promise.resolve([]);
  }
}
