/**
 *
 * Created by neo on 20.01.17.
 */

import { toJS, observable, computed } from 'mobx';
import { HttpBackend } from '../Services/Http/HttpBackend';
import { PageResult } from './PageResult';

export class UserRole {
  @observable
  id: string = '';
  @observable
  newId: string = '';
  @observable
  name: string = '';
  @observable
  description: string = '';
  @observable
  includes: Array<string> = [];

  constructor(json?: any) {
    if (json) {
      Object.assign(this, json);
      this.includes = json.includes || [];
    }
  }

  @computed
  get isNew(): boolean {
    return !this.id;
  }

  remove() {
    return HttpBackend.delete(`/uaa/user/role/${this.id}`);
  }

  save() {
    return HttpBackend.post(`/uaa/user/role`, toJS(this));
  }

  @computed
  get fullName(): string {
    if (this.name && this.name.length > 0) {
      return `${this.name} (${this.id})`;
    }
    return this.id;
  }

  static async get(id: string): Promise<UserRole | undefined> {
    const res = await HttpBackend.get(`/uaa/user/role/${id}`);
    if (res) {
      return new UserRole(res);
    }
    return undefined;
  }

  static find(data: any = { page: 0, sort: 'name,ASC' }): Promise<UserRole[]> {
    return HttpBackend.get('/uaa/user/role', data).then((res) => res.map((u) => new UserRole(u)));
  }

  static count(data: any = {}): Promise<number> {
    return HttpBackend.get('/uaa/user/role/count', data);
  }
}
