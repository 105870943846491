/**
 * Created by neo on 18.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';
import { SuperMacro } from '../../../../Model/Coach/SuperMacro/SuperMacro';
import { SuperMacroMesoCycleTemplate } from '../../../../Model/Coach/SuperMacro/SuperMacroMesoCycleTemplate';
import { MacroCycleTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleTemplate';
import { MacroCycleLaneTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleLaneTemplate';

export type SuperMacroExcludedTagsInputProps = {
  superMacro: SuperMacro | SuperMacroMesoCycleTemplate;
};

export const SuperMacroExcludedTagsInput: React.FC<SuperMacroExcludedTagsInputProps> = observer(
  (props: SuperMacroExcludedTagsInputProps) => {
    const { superMacro } = props;
    const store = useLocalStore(
      (source: SuperMacroExcludedTagsInputProps) => ({
        get tags(): any[] {
          return source.superMacro.excludedTags.map((value) => ({ label: value, value }));
        },
      }),
      props,
    );

    const handleChange = React.useCallback(
      (tags?: any[]) => {
        runInAction(() => {
          superMacro.excludedTags.splice(0, superMacro.excludedTags.length);
          (tags ?? []).map((t) => t.value).forEach((t) => superMacro.excludedTags.push(t));
        });
      },
      [superMacro],
    );

    return <CreatableSelect isClearable isMulti onChange={handleChange as any} value={store.tags} />;
  },
);
