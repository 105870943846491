/**
 * Created by neo on 07.06.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreFaqRequest } from '../../../../../Model/Explore/ExploreFaqRequest';
import { ExploreEntry } from '../../../../../Model/Explore/ExploreEntry';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import { ExploreFaq } from '../../../../../Model/Explore/ExploreFaq';

export type FaqRequestsTabContentEntryProps = {
  entry: ExploreEntry;
  request: ExploreFaqRequest;
};

export const FaqRequestsTabContentEntry: React.FC<FaqRequestsTabContentEntryProps> = observer(({ entry, request }) => {
  const handleAnswer = () => {
    new ExploreFaq({
      contentId: entry.id,
      question: [{ lang: 'en', value: request.question }],
    })
      .save()
      .then((result) => request.answered(result.id))
      .then(() => message.success('Question answered'));
  };

  return (
    <tr key={request.id}>
      <td>{request.id}</td>
      <td>{request.question}</td>
      <td>
        {request.athlete && (
          <Link to={`/athlete/${request.athleteId}`}>
            {request.athlete.fullName || request.athlete.nickname || request.athlete.user.email}
          </Link>
        )}
      </td>
      <td>{request.gym?.name}</td>
      <td>
        <Button type="primary" size="small" onClick={handleAnswer}>
          Answer
        </Button>
      </td>
    </tr>
  );
});
