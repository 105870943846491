/**
 * Created by neo on 30.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { AbstractExercise } from '../../../../Model/Exercise/AbstractExercise';
import { Badge } from 'reactstrap';

export type ExerciseTrackingKeyBadgesProps = {
  exercise: AbstractExercise;
};

export const ExerciseTrackingKeyBadges: React.FC<ExerciseTrackingKeyBadgesProps> = observer(
  ({ exercise }: ExerciseTrackingKeyBadgesProps) => {
    return (
      <React.Fragment>
        {(exercise.trackingParameters || []).map((e) => (
          <React.Fragment key={e}>
            <Badge color="danger">{e}</Badge>
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  },
);
