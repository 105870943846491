import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { MesoCycleConfiguration } from './MesoCycleConfiguration';
import { MicroCycle } from './MicroCycle';
import { notUndefined } from '../../../Utils/notUndefined';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export class MesoCycle {
  @observable
  id = UUID();
  @observable
  startDateTime: Date = new Date();
  @observable
  endDateTime?: Date = undefined;
  @observable
  configuration: MesoCycleConfiguration = new MesoCycleConfiguration();
  @observable
  currentMicroCycleId?: string = undefined;
  @observable
  microCycleIds: string[] = [];
  @observable
  microCycles?: MicroCycle[];
  disposer?: IReactionDisposer;

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.startDateTime = new Date(json.startDateTime);
      this.endDateTime = json.endDateTime ? new Date(json.endDateTime) : undefined;
      this.configuration = new MesoCycleConfiguration(json.configuration);
      this.currentMicroCycleId = json.currentMicroCycleId;
      this.microCycleIds = json.microCycleIds ?? [];
    }

    onBecomeObserved(this, 'microCycles', this.startFetchMicroCycles);
    onBecomeUnobserved(this, 'microCycles', this.stopFetchMicroCycles);
  }

  startFetchMicroCycles = () => {
    this.disposer && this.disposer();
    this.disposer = reaction(
      () => this.microCycleIds.map((m) => m),
      (microCycleIds: string[]) => {
        Promise.all(microCycleIds.map((id) => MicroCycle.get(id)))
          .then((microCycles) => microCycles.filter(notUndefined))
          .then((microCycles) => runInAction(() => (this.microCycles = microCycles)));
      },
      { fireImmediately: true },
    );
  };

  stopFetchMicroCycles = () => {
    this.disposer && this.disposer();
    this.disposer = undefined;
  };

  static async find(params?: any): Promise<MesoCycle[]> {
    return HttpBackend.get(`/coach/meso-cycle`, params).then((result) => result.map((r) => new MesoCycle(r)));
  }

  toJS() {
    return {
      id: this.id,
      startDateTime: this.startDateTime.toISOString(),
      endDateTime: this.endDateTime?.toISOString(),
      currentMicroCycleId: this.currentMicroCycleId,
      microCycleIds: toJS(this.microCycleIds),
      configuration: this.configuration?.toJS(),
    };
  }
}
