/**
 * Created by Azat Fazlyev on 20.09.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { DailyTipsTable } from './DailyTipsTable';
import { Checkbox, Form, Input } from 'antd';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { DailyTipEditModal } from './DailyTipEditModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { DailyTip } from "../../../Model/DailyTip/DailyTip";
import { useDebouncedEffect } from "../../../Utils/useDebouncedEffect";

export const DailyTipsListScreen: React.FC = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();

    const search = new URLSearchParams(location.search);
    const searchQuery = search.get('query') ?? '';
    const page = Number(search.get('page') ?? '0');

    const [dailyTips, setDailyTips] = useState<PageResult<DailyTip>>(new PageResult());
    const [query, setQuery] = useState(searchQuery);
    const [onlyInValue, setOnlyValue] = useState(false);
    const [caseSensitive, setCaseSensitive] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)

    const dailyTipsRequest = React.useMemo(
        () => ({
            query,
            onlyInValue,
            caseSensitive,
        }),
        [query, onlyInValue, caseSensitive],
    );

    useDebouncedEffect(
        () => {
            setDailyTips(new PageResult<DailyTip>());
            PageResult.execute(
                DailyTip.list({ ...dailyTipsRequest, page, size: 20 }),
                DailyTip.count(dailyTipsRequest),
                page,
                20,
            ).then((res) => setDailyTips(res));
        },
        [dailyTipsRequest, page],
    );

    const handleChangeQuery = React.useCallback(
        ({ target: { value } }) => {
            setQuery(value);
            search.set('page', '0');
            search.set('query', encodeURIComponent(value ?? ''));
            navigate({
                pathname: location.pathname,
                search: `?${search.toString()}`,
            });
        },
        [search, navigate, location],
    );

    const handleCloseDailyTip = React.useCallback(() => setModalOpen(false), [])

    const handleAddDailyTips = React.useCallback(() => {
        setModalOpen(true)
    }, []);

    const handlePage = React.useCallback(
        (newPage: number) => {
            search.set('page', newPage.toString());
            navigate({
                pathname: location.pathname,
                search: `?${search.toString()}`,
            });
        },
        [search, location, navigate],
    );

    const toggleOnlyInValue = React.useCallback(({ target: { checked } }: any) => {
        setOnlyValue((p) => !p);
    }, []);

    const toggleCaseSensitive = React.useCallback(({ target: { checked } }: any) => {
        setCaseSensitive((p) => !p);
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Daily tips</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input onChange={handleChangeQuery} value={query} allowClear={true} />
                </Col>
                <Col xs="auto">
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Checkbox onChange={toggleOnlyInValue} checked={onlyInValue}>
                            Search in value only
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Checkbox onChange={toggleCaseSensitive} checked={caseSensitive}>
                            Case Sensitive
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Button onClick={handleAddDailyTips}>
                Add Daily Tips
            </Button>
            <SingleColRow>
                <Pager page={dailyTips} onPage={handlePage} />
            </SingleColRow>
            <SingleColRow>
                <DailyTipsTable dailyTips={dailyTips.content} />
            </SingleColRow>
            <SingleColRow>
                <Pager page={dailyTips} onPage={handlePage} />
            </SingleColRow>
            <DailyTipEditModal dailyTip={new DailyTip()} modalOpen={modalOpen} onClose={handleCloseDailyTip}/>
        </Container>
    );
});
