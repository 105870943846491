/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Container, Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { UserRoleViewBasicInfoTab } from './UserRoleViewBasicInfoTab';
import { UserRole } from '../../../../Model/UserRole';
import { UserRoleViewUsersTab } from './UserRoleViewUsersTab';
import { History } from 'history';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type UserRoleViewScreenProps = {};

export const UserRoleViewScreen: React.FC<UserRoleViewScreenProps> = observer(() => {
  const { id } = useParams();
  const location = useLocation();
  const history = useNavigate();

  const [role, setRole] = useState(new UserRole());
  const [processing, setProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('basic');

  const isNew = id === 'create';

  useEffect(() => {
    if (id && id !== 'create') {
      setProcessing(true);
      try {
        UserRole.get(id).then((res) => setRole(res ?? new UserRole()));
      } finally {
        setProcessing(false);
      }
    }
  }, [id]);

  const handleSave = async () => {
    setProcessing(true);
    try {
      await role.save();
      history(`/security/userRole/${role.id}`, { replace: true });
    } finally {
      setProcessing(false);
    }
  };

  const toggleTab = (tab: string) => setActiveTab(tab);

  return (
    <Container>
      <Row>
        <Col>
          <div className="float-right">
            <Button color="success" onClick={handleSave} disabled={processing}>
              {'Save'}
            </Button>
          </div>
          <h1>{isNew ? 'New Role' : role.name || '<no name>'}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'basic' })} onClick={() => toggleTab('basic')}>
                {'Basic Info'}
              </NavLink>
            </NavItem>
            {!isNew ? (
              <NavItem>
                <NavLink className={classnames({ active: activeTab === 'users' })} onClick={() => toggleTab('users')}>
                  {'Users'}
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
          <TabContent activeTab={activeTab}>
            {activeTab === 'basic' ? (
              <TabPane tabId="basic">
                <UserRoleViewBasicInfoTab role={role} isNew={isNew} />
              </TabPane>
            ) : null}
            {activeTab === 'users' ? (
              <TabPane tabId="users">
                <UserRoleViewUsersTab role={role} />
              </TabPane>
            ) : null}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
});
