import { action, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Media, MediaJson } from '../../Media/Media';
import { LocalizedValue } from '../../LocalizedValue';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { MacroCycleTemplate, MacroCycleTemplateJson } from './MacroCycleTemplate';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { notUndefined } from '../../../Utils/notUndefined';

export type SuperMacroJson = LocalizedArrayEntityJson & {
  templateName?: string;
  image?: MediaJson;
  includedTags: string[];
  excludedTags: string[];
  tags: string[];
  macroCycles: MacroCycleTemplateJson[];
  infinite: boolean;
  hidden: boolean;
};

export class SuperMacro extends LocalizedArrayEntity {
  @observable
  templateName?: string = undefined;
  @observable
  image?: Media = undefined;
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  tags: string[] = [];
  @observable
  macroCycles: MacroCycleTemplate[] = [new MacroCycleTemplate()];
  @observable
  infinite = false;
  /**
   * when hidden it won't appear in search results
   */
  @observable
  hidden = false;

  constructor(json?: Partial<SuperMacroJson>) {
    super(json);
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId: boolean = false): any {
    return Object.assign(super.toJS(newId), {
      templateName: this.templateName,
      includedTags: toJS(this.includedTags),
      excludedTags: toJS(this.excludedTags),
      macroCycles: this.macroCycles.map((m) => m.toJS(newId)),
      tags: toJS(this.tags),
    });
  }

  copy(): SuperMacro {
    return new SuperMacro(this.toJS(true));
  }

  save(): Promise<SuperMacro> {
    return HttpBackend.post(`/coach/super-macro/template`, this.toJS()).then(() => this);
  }

  @action
  setData(json: Partial<SuperMacroJson>) {
    this.templateName = json.templateName;
    this.image = json.image ? new Media(json.image) : undefined;
    this.name = (json.name || []).map((v) => new LocalizedValue(v));
    this.includedTags = json.includedTags || [];
    this.excludedTags = json.excludedTags || [];
    this.tags = json.tags || [];
    this.description = (json.description || []).map((v) => new LocalizedValue(v));
    this.macroCycles = (json.macroCycles ?? []).map((m) => new MacroCycleTemplate(m));
    this.infinite = json.infinite ?? false;
    this.hidden = json.hidden ?? false;
  }

  delete() {
    return HttpBackend.delete(`/coach/super-macro/template/${this.id}`);
  }

  static find(params: any = {}): Promise<SuperMacro[]> {
    return HttpBackend.get('/coach/super-macro/template', params).then((res) =>
      (res ?? []).map((e) => new SuperMacro(e)),
    );
  }

  static async get(id: string): Promise<SuperMacro | undefined> {
    const res = await HttpBackend.get(`/coach/super-macro/template/${id}`);
    if (res) {
      return new SuperMacro(res);
    }
    return undefined;
  }

  static getAll(workoutIds: Array<string>): Promise<Array<SuperMacro>> {
    return Promise.all(workoutIds.map((w) => SuperMacro.get(w))).then((result) => result.filter(notUndefined));
  }

  static completeCheck() {
    return HttpBackend.get('/coach/completeness-check/super-macro');
  }
}
