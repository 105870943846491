/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import * as React from 'react';
import { Row, Col, Label, Button } from 'reactstrap';
import { Branch } from '../../../../../Model/Gym/Branch';

const styles = {
  row: {
    marginBottom: 8,
    marginLeft: 16,
  },
  text: {
    fontWeight: 400,
  },
  button: {
    marginLeft: 16,
    marginBottom: 16,
  },
};

export type Props = {
  branch: Branch;
  dayKey: string;
  onEdit: (dayKey: string) => void;
};

@observer
export class OpeningHoursListEntry extends React.Component<Props> {
  onEditOpeningHours = () => {
    const { onEdit, dayKey } = this.props;
    onEdit(dayKey);
  };

  @computed
  get openingHours(): string | undefined {
    const {
      branch: { branchInfo },
      dayKey,
    } = this.props;
    return branchInfo.openingHours.getTimeRange(dayKey);
  }

  @computed
  get hasTimeRange(): boolean {
    const { branch, dayKey } = this.props;
    return branch.branchInfo.openingHours.timeRanges.has(dayKey);
  }

  render() {
    const {
      branch,
      branch: { branchInfo },
      dayKey,
    } = this.props;
    return (
      <Row>
        <Col>
          <Label>{dayKey}</Label>
        </Col>
        <Row>
          <Col>
            {branch && this.hasTimeRange ? (
              <Row style={styles.row}>
                <p style={styles.text}>{this.openingHours}</p>
                <Button style={styles.button} onClick={this.onEditOpeningHours}>
                  Edit
                </Button>
              </Row>
            ) : (
              <Row>
                <Button style={styles.button} onClick={this.onEditOpeningHours}>
                  Add opening hours
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </Row>
    );
  }
}
