export class Sleep {
  static sleep(time: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  static sleepRandom(min: number = 100, max: number = 500): Promise<void> {
    const ms = Math.random() * (max - min) + min;
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
