/**
 *
 * Created by neo on 04.03.17.
 */
import { observable } from 'mobx';

export class AdditionalData {
  @observable
  email?: string = undefined;
  @observable
  website?: string = undefined;
  @observable
  telephone?: string = undefined;
  @observable
  facebook?: string = undefined;
  @observable
  twitter?: string = undefined;
  @observable
  instagram?: string = undefined;
  @observable
  coursesLink?: string = undefined;

  constructor(source?: any) {
    if (source) {
      this.email = source.email;
      this.website = source.website;
      this.telephone = source.telephone;
      this.facebook = source.facebook;
      this.twitter = source.twitter;
      this.instagram = source.instagram;
      this.coursesLink = source.coursesLink;
    }
  }
}
