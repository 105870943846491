import { action, observable, runInAction } from 'mobx';
import { ChatCompletionRequestMessage, Configuration, OpenAIApi } from 'openai';
import { Config } from '../Config/Config';

type GptMessage = ChatCompletionRequestMessage & {
  internal?: boolean;
};

export class GptResponseService {
  private messages: GptMessage[];
  @observable
  generating = false;

  constructor(initialMessages: GptMessage[] = []) {
    this.messages = initialMessages.map((p) => ({ ...p, internal: true }));
  }

  @action
  generate(progressCallback?: (message: string) => void): Promise<string> {
    if (!this.generating) {
      this.generating = true;
      return new Promise((resolve, reject) => {
        this._generate(
          this.messages.length,
          (res) => {
            runInAction(() => (this.generating = false));
            resolve(res);
          },
          (err) => {
            runInAction(() => (this.generating = false));
            reject(err);
          },
          progressCallback,
        );
      });
    }
    return Promise.reject('Already generating');
  }

  @action
  private _generate(
    attempt: number = 0,
    completeCallback?: (response: string) => void,
    errorCallback?: (err?: Error) => void,
    progressCallback?: (message: string) => void,
  ) {
    const openai = new OpenAIApi(new Configuration({ apiKey: Config.openai.gptApiKey }));
    const response = openai
      .createChatCompletion(
        {
          model: 'gpt-4o',
          max_tokens: 4095,
          stream: true,
          messages: this.messages.map((m) => ({ role: m.role ?? 'assistant', content: m.content })),
        },
        {
          responseType: 'stream',
          onDownloadProgress: (progressEvent) => {
            const text = progressEvent.currentTarget.response.replace(/^data: /gm, '');
            const jsonResponse = text
              .split('\n')
              .filter((x) => x)
              .filter((x) => !x.startsWith('[DONE]'))
              .map((x) => JSON.parse(x));

            const response: ChatCompletionRequestMessage = {
              role: jsonResponse.role ?? 'assistant',
              content: jsonResponse.reduce(
                (res, part) => res + part.choices.map((c) => c.delta?.content ?? '').join(''),
                '',
              ),
            };
            if (attempt >= this.messages.length) {
              this.messages.push(response);
            } else {
              this.messages[attempt] = response;
            }

            const { completeResponse } = this;
            // console.log('progress', completeResponse);
            progressCallback && progressCallback(completeResponse);
          },
        },
      )
      // .then((res) => JSON.parse(res.data.choices[0]?.message?.content ?? ''))
      // .then((json) => console.log(json))
      .then((res) => {
        console.log('response', res);

        const text = res.data.toString().replace(/^data: /gm, '');
        const jsonResponse = text
          .split('\n')
          .filter((x) => x)
          .filter((x) => !x.startsWith('[DONE]'))
          .map((x) => JSON.parse(x));
        const last = jsonResponse[jsonResponse.length - 1];

        // console.log('resolved last', last, this.messages);

        if (last.choices[0]?.finish_reason !== 'stop') {
          setTimeout(() => this._generate(attempt + 1, completeCallback, errorCallback, progressCallback));
        } else {
          const jsonText = this.completeResponse;
          completeCallback && completeCallback(jsonText);
        }
      })
      .catch((err) => {
        console.error('we got error', err);
        if (err.response?.status !== 429 && err.response?.status !== 400) {
          this._generate(attempt + 1, completeCallback, errorCallback, progressCallback);
        } else {
          errorCallback && errorCallback(err);
        }
      });
  }

  get completeResponse(): string {
    return this.messages
      .filter((m) => !m.internal)
      .map((m) => m.content)
      .join('');
  }
}
