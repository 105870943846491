import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { FlattenNodeModel } from './FlattenNodeModel';
import { FlattenNodeWidget } from './FlattenNodeWidget';
import { FlattenStage } from '../../../../../../../Model/Coach/Stages/FlattenStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class FlattenNodeFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('flatten');
  }

  generateReactWidget(event): JSX.Element {
    return <FlattenNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new FlattenNodeModel(new FlattenStage(new Pipeline()));
  }
}
