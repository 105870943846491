import { HealthDataPoint, HealthDataPointJson } from './HealthDataPoint';
import { observable } from 'mobx';
import { HealthDataPointCorrelationType } from './HealthDataPointCorrelationType';
import { HealthDataPointQuantity, HealthDataPointQuantityJson } from './HealthDataPointQuantity';
import { HealthDataPointType } from './HealthDataPointType';

export type HealthDataPointCorrelationJson = {
  type: HealthDataPointCorrelationType;
  values: HealthDataPointQuantityJson[];
} & HealthDataPointJson;

export class HealthDataPointCorrelation extends HealthDataPoint {
  @observable
  type: HealthDataPointCorrelationType = HealthDataPointCorrelationType.bloodPressure;
  @observable
  values: HealthDataPointQuantity[] = [];

  constructor(json?: Partial<HealthDataPointCorrelationJson>) {
    super(Object.assign(json ?? {}, { pointType: HealthDataPointType.correlation }));
    if (json) {
      this.type = json.type ?? HealthDataPointCorrelationType.bloodPressure;
      this.values = (json.values || []).map((q) => new HealthDataPointQuantity(q));
    }
  }

  toJS(): HealthDataPointCorrelationJson {
    return Object.assign(super.toJS(), {
      type: this.type,
      values: this.values.map((q) => q.toJS()),
    });
  }

  save(): Promise<HealthDataPointCorrelation> {
    return super.save().then(() => this);
  }
}
