/**
 * Created by neo on 13.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { ProgramParams } from '../../../../Model/Coach/Program/ProgramParams';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import CreatableSelect from 'react-select/creatable';
import { EquipmentConfigurationInput } from './EquipmentConfigurationInput';
import { Athlete } from '../../../../Model/Athlete/Athlete';

export type ProgramParamsEditorProps = {
  athlete: Athlete;
  params: ProgramParams;
  onChange?: (params: ProgramParams) => any;
};

export const ProgramParamsEditor: React.FC<ProgramParamsEditorProps> = observer((props: ProgramParamsEditorProps) => {
  const { params, athlete, onChange } = props;

  const store = useLocalStore(
    (source: ProgramParamsEditorProps) => ({
      get tags(): any[] {
        return source.params.tags.map((value) => ({ label: value, value }));
      },
    }),
    props,
  );

  const handleChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        params.tags.splice(0, params.tags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => params.tags.push(t));
        onChange && onChange(params);
      });
    },
    [params, onChange],
  );

  const handleEquipmentChange = React.useCallback(() => {
    onChange && onChange(params);
  }, [params, onChange]);

  return (
    <Row>
      <Col>
        <CreatableSelect isClearable isMulti onChange={handleChange as any} value={store.tags} />
      </Col>
      <Col>
        <EquipmentConfigurationInput params={params} athlete={athlete} onChange={handleEquipmentChange} />
      </Col>
    </Row>
  );
});
