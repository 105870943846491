/**
 * Created by neo on 02.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ActivityLog } from '../../../../Model/Activity/ActivityLog';
import dayjs from '../../../../Utils/dayjs';

export type ActivityLogTableRowProps = {
  activityLog: ActivityLog;
};

export const ActivityLogTableRow: React.FC<ActivityLogTableRowProps> = observer(({ activityLog }) => {
  return (
    <tr>
      <td>{dayjs(activityLog.startDate).format('ddd LL')}</td>
      <td>{dayjs(activityLog.startDate).format('HH:mm')}</td>
      <td>{dayjs(activityLog.endDate).format('HH:mm')}</td>
      <td>{activityLog.type}</td>
      <td></td>
      <td>
        {activityLog.source?.source}
        <br />
        {activityLog.source?.sourceId}
        <br />
        {activityLog.linkedData?.activityType}
      </td>
    </tr>
  );
});
