/**
 * Created by neo on 04.12.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeTeam } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { RouteChallengeTeamPosition } from './RouteChallengeTeamPosition';

export type RouteChallengeTeamPositionsProps = {
  challenge: RouteChallenge;
};

export const RouteChallengeTeamPositions: React.FC<RouteChallengeTeamPositionsProps> = observer(({ challenge }) => {
  const [teams, setTeams] = useState<RouteChallengeTeam[]>([]);

  useEffect(() => {
    if (challenge.started) {
      RouteChallengeTeam.find({ challengeId: challenge.id, page: 0, size: 100, sort: 'globalRank,ASC' }).then((res) => setTeams(res));
    }
  }, [challenge]);

  if (challenge.started && !challenge.ended) {
    return (
      <React.Fragment>
        {teams.map((team) => (
          <RouteChallengeTeamPosition key={team.id} challenge={challenge} team={team} />
        ))}
      </React.Fragment>
    );
  }

  return null;
});
