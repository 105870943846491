/**
 * Created by neo on 03.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type ImageElementProps = {
  component: NativeFeedComponent;
};

export const ImageElement: React.FC<ImageElementProps> = observer(({ component }: ImageElementProps) => {
  const { classes } = useStyles();

  if (component.media) {
    return (
      <div className={classes.container}>
        <img className={classes.image} src={component.media.medium} alt="" />;
      </div>
    );
  }
  return (
    <Typography className={classes.title} variant="button">
      {component.type}
    </Typography>
  );
});

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  image: {
    width: '100%',
    borderRadius: 8,
  },
  title: {
    fontWeight: 'bold',
    paddingLeft: 16,
  },
}));
