/**
 * Created by neo on 09.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { ThematicBreak } from 'mdast-util-from-markdown/dev/lib';

export type MarkdownThematicBreakRendererProps = {
  content: ThematicBreak;
};

export const MarkdownThematicBreakRenderer: React.FC<MarkdownThematicBreakRendererProps> = observer((props) => {
  const { classes } = useStyles();

  return <div className={classes.container} />;
});

const useStyles = makeStyles()((theme) => ({
  container: {
    borderWidth: 1,
    marginVertical: 16,
  },
}));
