export const allWorkoutAnalytics = `
    SELECT
        event_date,
        workoutType.value.string_value as workoutType,
        COUNT(DISTINCT user_id) AS user_count,
        COUNT(*) AS count,
  LEAST(AVG(e.value.double_value), 100) as avg_percentage_completed,
  AVG(duration.value.double_value) as avg_duration_seconds
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST(event_params) e,
        UNNEST(event_params) workoutType,
        UNNEST(event_params) duration
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      AND event_name = 'Workout_finished'
      AND workoutType.key = 'workoutType'
      AND e.key = 'percentageCompleted'
      AND duration.key = 'durationInSeconds'
    GROUP BY event_date, workoutType
    ORDER BY event_date, workoutType
`;

export const gymWorkoutAnalytics = `
SELECT 
  event_date,
  workoutType.value.string_value as workoutType,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS count,
  LEAST(AVG(e.value.double_value), 100) as avg_percentage_completed,
  AVG(duration.value.double_value) as avg_duration_seconds
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST(user_properties) p,
  UNNEST(event_params) e,
  UNNEST(event_params) workoutType,
  UNNEST(event_params) duration
WHERE
  _TABLE_SUFFIX BETWEEN @start_date AND @end_date
  AND p.key = 'gymId'
  AND p.value.string_value = @gym_id
  AND event_name = 'Workout_finished'
  AND workoutType.key = 'workoutType'
  AND e.key = 'percentageCompleted'
  AND duration.key = 'durationInSeconds'
GROUP BY event_date, workoutType
ORDER BY event_date, workoutType
`;
