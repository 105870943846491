/**
 * Created by neo on 16.01.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { PageResult } from '../../../../Model/PageResult';
import { Table } from 'reactstrap';
import { RouteChallengeTeamMember } from '../../../../Model/Engagement/RouteChallenge/RouteChallengeTeamMember';
import { AthleteRouteChallengeTableRow } from './AthleteRouteChallengeTableRow';

export type AthleteRouteChallengeTabProps = {
  athlete: Athlete;
};

export const AthleteRouteChallengeTab: React.FC<AthleteRouteChallengeTabProps> = observer(({ athlete }) => {
  const [members, setMembers] = useState(new PageResult<RouteChallengeTeamMember>());
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      RouteChallengeTeamMember.find({ athleteId: athlete.id, page, size: 20 }),
      RouteChallengeTeamMember.count({ athleteId: athlete.id }),
      page,
      20,
    ).then((result) => setMembers(result));
  }, [page, athlete]);

  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>Team ID</th>
          <th>Challenge</th>
          <th>Points</th>
          <th>Team Points</th>
          <th>Reached Locations</th>
          <th>Current Location</th>
        </tr>
      </thead>
      <tbody>
        {members.content.map((member) => (
          <AthleteRouteChallengeTableRow key={member.id} athlete={athlete} member={member} />
        ))}
      </tbody>
    </Table>
  );
});
