import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { SwitchNodeModel } from './SwitchNodeModel';
import { SwitchNodeWidget } from './SwitchNodeWidget';
import { SwitchStage } from '../../../../../../../Model/Coach/Stages/SwitchStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class SwitchNodeFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('switch');
  }

  generateReactWidget(event): JSX.Element {
    return <SwitchNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new SwitchNodeModel(new SwitchStage(new Pipeline()));
  }
}
