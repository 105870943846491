/**
 * Created by neo on 01.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Pipeline } from '../../../../../Model/Coach/Pipeline';
import { Button, Col, Form, Input } from 'antd';
import { PipelineParamEntry } from './PipelineParamEntry';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { PipelineParam } from '../../../../../Model/Coach/PipelineParam';

export type PipelineParamEditorProps = {
  pipeline: Pipeline;
};

export const PipelineParamEditor: React.FC<PipelineParamEditorProps> = observer(
  ({ pipeline }: PipelineParamEditorProps) => {
    const handleAdd = React.useCallback(() => {
      pipeline.params.push(new PipelineParam());
    }, [pipeline]);

    const handleRemove = React.useCallback(
      (param: string) => {
        pipeline.params = pipeline.params.filter((p) => p.name !== param);
      },
      [pipeline],
    );

    return (
      <Form layout="vertical">
        <SingleColRow>
          <h3>Params</h3>
        </SingleColRow>
        {pipeline.params.map((param, index) => (
          <PipelineParamEntry key={`${index}`} param={param} onRemove={handleRemove} />
        ))}
        <SingleColRow>
          <Button type="dashed" onClick={handleAdd} block>
            Add Param
          </Button>
        </SingleColRow>
      </Form>
    );
  },
);
