import { action, computed, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction } from 'mobx';
import { ProgramParams } from './ProgramParams';
import { MicroCycle } from './MicroCycle';
import { WorkoutLog } from '../../WorkoutLog';
import { IDisposer } from 'mobx-utils';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { CoachWorkout } from './Workout/CoachWorkout';
import { WorkoutLogHeader } from '../../WorkoutLog/WorkoutLogHeader';
import { CoachProgramType } from './CoachProgramType';
import { MicroCycleDaySchedule } from './MicroCycleDaySchedule';

export class MicroCycleDay {
  @observable
  microCycle: MicroCycle;
  @observable
  id: string = '';
  @observable
  athleteSuperMacroId: string = '';
  @observable
  mesoCycleId: string = '';
  @observable
  microCycleId: string = '';
  @observable
  workoutDayTemplateId: string = '';
  @observable
  workoutId: string = '';
  @observable
  workoutLog?: WorkoutLogHeader;
  @observable
  params: ProgramParams = new ProgramParams();
  @observable
  workout: CoachWorkout = new CoachWorkout();
  @observable
  fullWorkoutLog?: WorkoutLog = undefined;
  @observable
  type: CoachProgramType = 'superMacro';
  @observable
  schedule?: MicroCycleDaySchedule;
  @observable
  skipped = false;
  @observable
  processing = false;
  disposeWorkoutFetch?: IDisposer;

  constructor(microCycle: MicroCycle, json?: any) {
    this.microCycle = microCycle;
    if (json) {
      this.setData(json);
    }

    onBecomeObserved(this, 'fullWorkoutLog', this.fetchWorkoutLog);
  }

  @action
  setData(json: any) {
    this.id = json.id || '';
    this.athleteSuperMacroId = json.athleteSuperMacroId || '';
    this.mesoCycleId = json.mesoCycleId || '';
    this.microCycleId = json.microCycleId || '';
    this.workoutDayTemplateId = json.workoutDayTemplateId || '';
    this.workoutId = json.workoutId || '';
    this.workoutLog = json.workoutLog || '';
    this.params = new ProgramParams(json.params);
    this.workout = new CoachWorkout(json.workout);
    this.skipped = json.skipped ?? false;
    this.type = json.type ?? 'superMacro';
    this.schedule = json.schedule ? new MicroCycleDaySchedule(json.schedule) : undefined;
  }

  @action
  updateData(json: any) {
    this.setData(json);
  }

  @action
  changeParams(params?: ProgramParams): Promise<MicroCycleDay> {
    this.params = params ?? this.params;
    if (this.type === 'program') {
      return HttpBackend.post(
        `/coach/program/${this.athleteSuperMacroId}/cycle/${this.microCycleId}/${this.id}/changeParams`,
        this.params.toJS(),
      ).then((result) => {
        if (result) {
          this.updateData(result);
        }
        return this;
      });
    }
    return HttpBackend.post(
      `/coach/${this.athleteSuperMacroId}/cycle/${this.microCycleId}/${this.id}/changeParams`,
      this.params.toJS(),
    ).then((result) => {
      if (result) {
        this.updateData(result);
      }
      return this;
    });
  }

  refresh(): Promise<MicroCycleDay> {
    return HttpBackend.post(
      `/coach/program/${this.athleteSuperMacroId}/cycle/${this.microCycleId}/${this.id}/refresh`,
    ).then((result) => {
      if (result) {
        this.updateData(result);
      }
      return this;
    });
  }

  @action
  skip(): Promise<MicroCycleDay> {
    this.processing = true;
    return HttpBackend.post(`/coach/program/${this.athleteSuperMacroId}/cycle/${this.microCycleId}/${this.id}/skip`)
      .then(() => runInAction(() => (this.skipped = true)))
      .then(() => this)
      .finally(() => runInAction(() => (this.processing = false)));
  }

  @computed
  get completed(): boolean {
    return !!this.workoutLog || this.skipped;
  }

  fetchWorkoutLog = async () => {
    if (!this.fullWorkoutLog && this.workoutLog) {
      this.fullWorkoutLog = await WorkoutLog.get(this.workoutLog.id);
    }
  };

  stopFetchWorkout = async () => {
    this.disposeWorkoutFetch && this.disposeWorkoutFetch();
    this.disposeWorkoutFetch = undefined;
  };
}
