/**
 * Created by neo on 18.12.20.
 */
import { Nutrient, NutrientJson } from './Nutrient';
import { CaloricBreakdown, CaloricBreakdownJson } from '../CaloricBreakdown';
import { WeightPerServing, WeightPerServingJson } from '../WeightPerServing';
import { observable } from 'mobx';

export type NutrientMapJson = {
  [key: string]: NutrientJson;
};

export type NutrientMap = {
  [key: string]: Nutrient;
};

export type NutritionInformationJson = {
  nutrients: NutrientMapJson;
  properties: NutrientMapJson;
  flavanoids: NutrientMapJson;
  caloricBreakdown: CaloricBreakdownJson;
  weightPerServing: WeightPerServingJson;
};

export class NutritionInformation {
  @observable
  nutrients: NutrientMap = {};
  @observable
  properties: NutrientMap = {};
  @observable
  flavanoids: NutrientMap = {};
  @observable
  caloricBreakdown = new CaloricBreakdown();
  @observable
  weightPerServing = new WeightPerServing();

  constructor(json?: Partial<NutritionInformationJson>) {
    if (json) {
      this.nutrients = Object.entries(json.nutrients ?? {})
        .map(([key, value]) => ({ [key]: new Nutrient(value) }))
        .reduce((acc, entry) => Object.assign(acc, entry), {} as NutrientMap);
      this.properties = Object.entries(json.properties ?? {})
        .map(([key, value]) => ({ [key]: new Nutrient(value) }))
        .reduce((acc, entry) => Object.assign(acc, entry), {} as NutrientMap);
      this.flavanoids = Object.entries(json.flavanoids ?? {})
        .map(([key, value]) => ({ [key]: new Nutrient(value) }))
        .reduce((acc, entry) => Object.assign(acc, entry), {} as NutrientMap);
      this.caloricBreakdown = new CaloricBreakdown(json.caloricBreakdown);
      this.weightPerServing = new WeightPerServing(json.weightPerServing);
    }
  }

  toJS(): NutritionInformationJson {
    return {
      nutrients: Object.entries(this.nutrients)
        .map(([key, value]) => ({ [key]: value.toJS() }))
        .reduce((acc, entry) => Object.assign(acc, entry), {} as NutrientMapJson),
      properties: Object.entries(this.properties)
        .map(([key, value]) => ({ [key]: value.toJS() }))
        .reduce((acc, entry) => Object.assign(acc, entry), {} as NutrientMapJson),
      flavanoids: Object.entries(this.flavanoids)
        .map(([key, value]) => ({ [key]: value.toJS() }))
        .reduce((acc, entry) => Object.assign(acc, entry), {} as NutrientMapJson),
      caloricBreakdown: this.caloricBreakdown.toJS(),
      weightPerServing: this.weightPerServing.toJS(),
    };
  }
}
