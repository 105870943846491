/**
 * Created by neo on 16.07.2024
 */
import {
  RegisteredUserPerGymAggregationEntry,
  RegisteredUserPerGymAggregationEntryJson,
} from './RegisteredUserPerGymAggregationEntry';
import { Gym } from '../../../Model/Gym/Gym';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type RegisteredUserPerGymAggregationJson = {
  id: string;
  entries: RegisteredUserPerGymAggregationEntryJson[];
  gym: any;
};

export class RegisteredUserPerGymAggregation {
  id = '';
  entries: RegisteredUserPerGymAggregationEntry[] = [];
  gym = new Gym();

  constructor(json?: Partial<RegisteredUserPerGymAggregationJson>) {
    if (json) {
      this.id = json.id ?? '';
      this.entries = json.entries?.map((entry) => new RegisteredUserPerGymAggregationEntry(entry)) ?? [];
      this.gym = new Gym(json.gym);
    }
  }

  static find(
    params?: Partial<{ gymId: string; window: 'day' | 'week' | 'month' }>,
  ): Promise<RegisteredUserPerGymAggregation[]> {
    return HttpBackend.get('/analytics/company-dashboard', params).then((res) =>
      res.map((json) => new RegisteredUserPerGymAggregation(json)),
    );
  }
}
