/**
 *
 */
import * as React from 'react';
import { computed } from 'mobx';
import Highlighter from 'react-highlight-words';
import { LocalizedValue } from '../Model/LocalizedValue';

export type Props = {
  strings?: LocalizedValue[];
  query?: string;
};

export class HighlightedLocalized extends React.Component<Props> {
  @computed
  get translations(): LocalizedValue[] {
    return (this.props.strings || [])
      .filter(({ lang }: LocalizedValue) => lang !== 'de')
      .filter(({ lang, value }: LocalizedValue) => lang && lang.length > 0 && value && value.length > 0)
      .sort((a, b) => a.lang.localeCompare(b.lang));
  }

  @computed
  get querySplitted(): string[] {
    return this.props.query ? this.props.query.trim().toLowerCase().split(/-|\s/) : [];
  }

  @computed
  get translationString(): string {
    return this.translations.map((a) => `${a.value} (${a.lang})`).join(', ');
  }

  sanitize = (text: string) => text.toLowerCase();

  render() {
    const { querySplitted, translationString, sanitize } = this;
    return (
      <Highlighter
        highlightClassName="text-danger"
        unhighlightClassName="text-muted"
        searchWords={querySplitted}
        autoEscape={true}
        sanitize={sanitize}
        textToHighlight={translationString}
      />
    );
  }
}
