/**
 * Created by neo on 07.06.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Alert, Button, Collapse, Form, message, Space } from 'antd';
import { runInAction } from 'mobx';
import { PageResult } from '../../../../Model/PageResult';
import { ExploreFaq } from '../../../../Model/Explore/ExploreFaq';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { Pager } from '../../../../Components/Pager';
import { LocalizedValue } from '../../../../Model/LocalizedValue';

export type ExploreFaqAnswerTabContentProps = {};

export const ExploreFaqAnswerTabContent: React.FC<ExploreFaqAnswerTabContentProps> = observer(() => {
  const [results, setResults] = useState<PageResult<ExploreFaq> | undefined>();
  const [page, setPage] = useState(0);

  const hasNoContent = !results || results?.content.length === 0;

  useEffect(() => {
    PageResult.execute(ExploreFaq.find({ page, size: 20 }), ExploreFaq.count(), page, 20).then((result) =>
      setResults(result),
    );
  }, [page]);

  const handlePage = (newPage: number) => setPage(newPage);

  return (
    <Row>
      {results && (
        <Col xs={12}>
          <Pager page={results} onPage={handlePage} />
        </Col>
      )}
      <Col xs={12}>
        {hasNoContent && <Alert message="No questions avilable yet" type="warning" />}
        <Collapse>
          {results?.content.map((question) => (
            <Collapse.Panel
              key={question.id}
              header={question.getQuestion('en')}
              extra={
                <Space>
                  <Button
                    onClick={() => {
                      question.save().then(() => message.success('Question saved'));
                    }}
                  >
                    Save Question
                  </Button>
                  {question.entry && (
                    <Button
                      onClick={() => {
                        if (question.entry) {
                          new GptResponseService([
                            {
                              role: 'system',
                              content:
                                'You have to answer with an unformatted text and only the answer no appendix or prefix.',
                            },
                            {
                              role: 'user',
                              content: `It's a ${question.entry.type} named ${question.entry.getName('en')}. 
                          The description is: ${question.entry.getDescription('en')}. Now answer the following question: ${question.getQuestion('en')}?`,
                            },
                          ])
                            .generate()
                            .then((response) => {
                              runInAction(() => {
                                question.answer = [new LocalizedValue({ lang: 'en', value: response })];
                              });
                            });
                        }
                      }}
                    >
                      Generate Answer
                    </Button>
                  )}
                  <Button
                    danger={true}
                    onClick={() => {
                      question.delete().then(() => message.success('Question deleted'));

                      PageResult.execute(ExploreFaq.find({ page, size: 20 }), ExploreFaq.count(), page, 20).then(
                        (result) => setResults(result),
                      );
                    }}
                  >
                    Delete Question
                  </Button>
                </Space>
              }
            >
              <Row>
                <Col>
                  <Form.Item label="Question">
                    <TranslationInputArray entity={question} field="question" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Answer">
                    <TranslationInputArray entity={question} field="answer" />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Col>
      {results && (
        <Col xs={12}>
          <Pager page={results} onPage={handlePage} />
        </Col>
      )}
    </Row>
  );
});
