export const allWorkoutConversionWeekly = `
SELECT *, LEAST((t.count / s.select_count) * 100, 100) as converted, t.week as event_date  FROM (
    SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(DISTINCT e1.value.string_value) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e,
    UNNEST(event_params) e1
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = 'Workout_finished'
    AND e.key = 'percentageCompleted'
    AND e1.key = 'workoutLogId'
  GROUP BY week
) t
LEFT JOIN (
  SELECT
      DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = 'workout'
  GROUP BY week
) s
  ON t.week = s.week
  ORDER BY t.week
`;

export const gymWorkoutConversionWeekly = `
SELECT *, LEAST((t.count / s.select_count) * 100, 100) as converted, t.week as event_date  FROM (
    SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(DISTINCT e1.value.string_value) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e,
    UNNEST(event_params) e1
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = 'Workout_finished'
    AND e.key = 'percentageCompleted'
    AND e1.key = 'workoutLogId'
  GROUP BY week
) t
LEFT JOIN (
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = 'workout'
  GROUP BY week
) s
  ON t.week = s.week
  ORDER BY t.week
`;

export const allActivityConversionWeekly = (event: string, contentType: string) => `
SELECT *, LEAST((t.count / s.select_count) * 100, 100) as converted, t.week as event_date  FROM (
    SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(*) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = '${event}'
    AND e.key = 'percentageCompleted'
  GROUP BY week
) t
LEFT JOIN (
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = '${contentType}'
  GROUP BY week
) s
  ON t.week = s.week
  ORDER BY t.week
`;

export const gymActivityConversionWeekly = (event: string, contentType: string) => `
SELECT *, LEAST((t.count / s.select_count) * 100, 100) as converted, t.week as event_date  FROM (
    SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(*) AS count,
    COUNT(DISTINCT user_id) as user_count,
    AVG(e.value.double_value) as avg_percentage_completed
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = '${event}'
    AND e.key = 'percentageCompleted'
  GROUP BY week
) t
LEFT JOIN (
  SELECT
    DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
    COUNT(*) AS select_count,
    COUNT(DISTINCT user_id) as select_user_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(user_properties) p,
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND e.value.string_value = '${contentType}'
  GROUP BY week
) s
  ON t.week = s.week
  ORDER BY t.week
`;
