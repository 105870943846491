/**
 * Created by andreaskarantzas on 05.05.20.
 */
import { observable, ObservableMap, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import NativeFeedComponentPropUrl, { NativeFeedComponentPropUrlJson } from './NativeFeedComponentPropUrl';

export type NativeFeedComponentPropsJson = {
  key: string;
  style: Record<string, string | number>;
  extraProps: Record<string, string | number | boolean>;
  urlAliases: NativeFeedComponentPropUrlJson[];
};

export default class NativeFeedComponentProps {
  @observable key: string = UUID();
  @observable style: ObservableMap<string, string | number> = observable.map({});
  @observable extraProps: ObservableMap<string, string | number | boolean> = observable.map({});
  @observable
  urlAliases: NativeFeedComponentPropUrl[] = [];

  constructor(json: Partial<NativeFeedComponentPropsJson>) {
    if (json) {
      this.key = json.key || UUID();
      this.style = observable.map(json.style || {});
      this.extraProps = observable.map(json.extraProps || {});
      this.urlAliases = (json.urlAliases || []).map((u: any) => new NativeFeedComponentPropUrl(u));
    }
  }

  toJS(): NativeFeedComponentPropsJson {
    return {
      key: this.key,
      style: this.style.toJSON(),
      extraProps: this.extraProps.toJSON(),
      urlAliases: this.urlAliases.map((u) => u.toJS()),
    };
  }
}
