/**
 *
 * Created by neo on 06.03.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { ExerciseBlockLog } from '../../../../../Model/WorkoutLog/ExerciseBlockLog';
import { ExerciseSet } from '../../../../../Model/WorkoutLog/ExerciseSet';
import { Table } from 'reactstrap';
import { SetsTableRow } from './SetsTableRow';
import { TrackingKeysList } from '../../../../../Model/ProgramPortfolio/TrackingKeys';

export type Props = {
  exerciseBlock: ExerciseBlockLog;
  onClick?: (set: ExerciseSet) => void;
};

@observer
export class SetsTable extends React.Component<Props> {
  render() {
    const {
      exerciseBlock: { sets },
    } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            {TrackingKeysList.map((t) => (
              <th key={t}>{t}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sets.map((s: ExerciseSet) => (
            <SetsTableRow key={s.setId} set={s} />
          ))}
        </tbody>
      </Table>
    );
  }
}
