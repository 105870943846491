/**
 * Created by neo on 02.10.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { CoachExerciseBlockSet } from '../../../../../Model/Coach/Program/Workout/CoachExerciseBlockSet';
import { CoachSetsTableRowTrackingKey } from './CoachSetsTableRowTrackingKey';
import { TrackingKeysList } from '../../../../../Model/ProgramPortfolio/TrackingKeys';
import styled from '@emotion/styled';

export type CoachSetsTableRowProps = {
  set: CoachExerciseBlockSet;
  index: number;
};

const Container = styled.div`
  display: flex;
  margin: 8px 0;
  border-bottom: 1px solid lightgray;
  padding-bottom: 8px;

  &:last {
    border-bottom: none;
  }
`;

const ValueContainer = styled.div`
  flex: 1;
  display: flex;
`;

const SetNumber = styled.div`
  padding-right: 16px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const CoachSetsTableRow: React.FC<CoachSetsTableRowProps> = observer(
  ({ set, index }: CoachSetsTableRowProps) => {
    return (
      <Container>
        <SetNumber>{`${index + 1}.`}</SetNumber>
        <ValueContainer>
          {TrackingKeysList.map((key) => (
            <CoachSetsTableRowTrackingKey key={key} setKey={key} values={set.values} />
          ))}
        </ValueContainer>
      </Container>
    );
  },
);
