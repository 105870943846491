/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Button } from 'reactstrap';
import { observer } from 'mobx-react';
import { EquipmentType } from '../../../../Model/Equipment/EquipmentType';
import { TableRowMedia } from '../../../../Components/TableRowMedia';
import { useRootStore } from '../../../../Store/useRootStore';
import { Checkbox } from 'antd';
import { Link } from 'react-router-dom';

export type EquipmentTypeTableRowProps = {
  type: EquipmentType;
  onDelete: (type: EquipmentType) => any;
  coachEquipmentTypeIds: string[];
};

export const EquipmentTypeTableRow: React.FC<EquipmentTypeTableRowProps> = observer(
  ({ type, onDelete, coachEquipmentTypeIds }: EquipmentTypeTableRowProps) => {
    const { authentication } = useRootStore();

    const handleRemove = React.useCallback(() => {
      onDelete(type);
    }, [onDelete, type]);

    const isIncludedInCoachSubset = coachEquipmentTypeIds.includes(type.id);

    return (
      <tr>
        <td>
          <Link to={type.id}>{type.id}</Link>
        </td>
        <td>
          <TableRowMedia medias={type.medias.slice()} />
        </td>
        <td>{type.defaultName}</td>
        <td>
          <Checkbox checked={isIncludedInCoachSubset} />
        </td>
        <td>
          {authentication.isGodAdmin ? (
            <Button color="danger" onClick={handleRemove}>
              {'Delete'}
            </Button>
          ) : null}
        </td>
      </tr>
    );
  },
);
