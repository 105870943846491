/**
 *
 * Created by neo on 30.03.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { User } from '../../../Model/User';

export type Props = {
  user: User;
  onRemove?: (user: User) => any;
};

@observer
export class UserTableRow extends React.Component<Props> {
  handleRemove = () => {
    this.props.onRemove && this.props.onRemove(this.props.user);
  };

  render() {
    const {
      props: { user },
    } = this;
    return (
      <tr>
        <td>
          <Link to={`/security/user/${user.id}`}>{user.email}</Link>
        </td>
        <td>
          {user.roles.map((r: string) => (
            <Badge key={r} color="info">
              {r}
            </Badge>
          ))}
        </td>
        <td></td>
      </tr>
    );
  }
}
