/**
 * Created by katarinababic on 15.12.21..
 */

export const allTopTenClickedEntriesAggregated = `
SELECT
  *
FROM (
  SELECT
    ROW_NUMBER() OVER (ORDER BY COUNT(*) DESC) AS rank,
    params_type.value.string_value AS entry_type,
    params_id.value.string_value AS entry_id,
    COUNT(DISTINCT user_id) AS user_count,
    COUNT(*) AS select_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST (event_params) AS params_type,
    UNNEST (event_params) AS params_id
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND params_type.key = 'content_type'
    AND params_type.value.string_value = @entry_type
    AND params_id.key = 'item_id'
  GROUP BY
   entry_type,
   entry_id
  ORDER BY
    select_count DESC)
WHERE
  rank <= 10`;

export const gymTopTenClickedEntriesAggregated = `
SELECT
  *
FROM (
  SELECT
    ROW_NUMBER() OVER (ORDER BY COUNT(*) DESC) AS rank,
    params_type.value.string_value AS entry_type,
    params_id.value.string_value AS entry_id,
    COUNT(DISTINCT user_id) AS user_count,
    COUNT(*) AS select_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST (event_params) AS params_type,
    UNNEST (event_params) AS params_id,
    UNNEST (user_properties) AS p
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND params_type.key = 'content_type'
    AND params_type.value.string_value = @entry_type
    AND params_id.key = 'item_id'
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
  GROUP BY
    entry_type,
    entry_id
  ORDER BY
    select_count DESC)
WHERE
  rank <= 10`;
