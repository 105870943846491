/**
 * Created by katarinababic on 26/07/2021.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { TranslationArrayInput } from '../../../../Translation/TranslationArrayInput';
import TextField from '@mui/material/TextField/TextField';

export type ComponentStylesButtonProps = {
  component: NativeFeedComponent;
};

export const ComponentStylesButton: React.FC<ComponentStylesButtonProps> = observer(({ component }) => {
  const { classes } = useStyles();

  const handleChangeUrl = React.useCallback(
    (e) => {
      runInAction(() => {
        component.props.extraProps.set('link', e.target.value);
      });
    },
    [component],
  );

  return (
    <Grid className={classes.container} id="nativeFeedSideDrawerComponentStylesButton">
      <Grid>
        <Grid item xs={12}>
          {component.text ? <TranslationArrayInput multiline={true} translations={component.text} /> : null}
        </Grid>
        <TextField
          placeholder="https://..."
          label="url"
          onChange={handleChangeUrl}
          className={classes.link}
          value={component.props.extraProps.get('link') ?? ''}
        />
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
  link: {
    width: '65ch',
    marginTop: 16,
  },
}));
