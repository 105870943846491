/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { useLocalStore, observer } from 'mobx-react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AthleteCoachProgramTable } from './AthleteCoachProgramTable';
import { CoachProgram } from '../../../../Model/Coach/Program/CoachProgram';
import { InfiniteScroll } from '../../../../Components/InfiniteScroll';
import { runInAction } from 'mobx';
import { Modal } from 'antd';

export type AthleteCoachProgramTabProps = {
  athlete: Athlete;
};

export const AthleteCoachProgramTab: React.FC<AthleteCoachProgramTabProps> = observer(
  (props: AthleteCoachProgramTabProps) => {
    const { athlete } = props;
    const history = useNavigate();

    const store = useLocalStore(
      (source) => ({
        result: new Array<CoachProgram>(),
        page: 0,
        loading: false,
        hasMore: false,
        async load() {
          CoachProgram.find({ athleteId: source.athlete.id, page: store.page, sort: 'createdAt,DESC' }).then(
            (result) =>
              runInAction(() => {
                if (store.page === 0) {
                  store.result = result;
                } else {
                  result.forEach((res) => store.result.push(res));
                }
              }),
          );
        },
        loadMore() {
          store.page += 1;
          store.load();
        },
      }),
      props,
    );

    useEffect(() => {
      store.load();
    }, [store]);

    const handleRemove = React.useCallback((program: CoachProgram) => {
      Modal.confirm({
        title: `Delete program ${program.template?.templateName}?`,
        onOk() {
          program.delete();
        },
      });
    }, []);

    const handleCreate = React.useCallback(() => {
      history(`/athlete/program/new?athleteId=${athlete.id}`);
    }, [history, athlete]);

    const handleLoadMore = React.useCallback(() => {
      store.loadMore();
    }, [store]);

    return (
      <React.Fragment>
        <SingleColRow>
          <div className="pt-1">
            <AthleteCoachProgramTable programs={store.result.slice()} onRemove={handleRemove} />
          </div>
        </SingleColRow>
        {store.hasMore ? <InfiniteScroll loading={store.loading} onLoad={handleLoadMore} /> : null}
      </React.Fragment>
    );
  },
);
