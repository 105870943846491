/**
 * Created by neo on 28.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import styled from '@emotion/styled';
import { StyledInput } from './StyledInput';
import { DefaultStageNodeModel } from './Default/DefaultStageNodeModel';

const attributes = [
  'bodyParts',
  'synergists',
  'stabilizers',
  'force',
  'mechanics',
  'utility',
  'name',
  'initialPosition.primary',
  'initialPosition.secondary',
  'tagCategory',
  'bodyPartSizeDesc',
  'bodyPartSizeAsc',
];

export type ConfigAttributeSelectProps = {
  node: DefaultStageNodeModel;
  onBlur?: () => any;
  onFocus?: () => any;
};

const StyledSelect = styled.select`
  flex: 1;
  color: black;
  margin-right: 4px;
`;

export const ConfigAttributeSelect: React.FC<ConfigAttributeSelectProps> = observer(
  ({ node, onFocus, onBlur }: ConfigAttributeSelectProps) => {
    const handleChange = React.useCallback(
      ({ target: { value } }: any) => {
        node.stage.config.attribute = value;
      },
      [node],
    );

    const handleChangeAttribute = React.useCallback(
      ({ target: { value } }: any) => {
        node.stage.config.attribute = value;
      },
      [node],
    );

    const disabled = !!attributes.find((a) => node.stage.config.attribute === a);

    return (
      <React.Fragment>
        <StyledSelect
          value={attributes.find((a) => a === node.stage.config.attribute) ? node.stage.config.attribute : ''}
          onChange={handleChange}
        >
          <option value="">Custom</option>
          <option value="type">Type</option>
          <option value="bodyParts">Body Parts</option>
          <option value="synergists">Synergists</option>
          <option value="stabilizers">Stabilizers</option>
          <option value="force">Force</option>
          <option value="mechanics">Mechanics</option>
          <option value="utility">Utility</option>
          <option value="name">Name</option>
          <option value="initialPosition.primary">Primary Position</option>
          <option value="initialPosition.secondary">Secondary Position</option>
          <option value="tagCategory">Tag Category</option>
          <option value="bodyPartSizeDesc">Body Part Size (Descending)</option>
          <option value="bodyPartSizeAsc">Body Part Size (Ascending)</option>
        </StyledSelect>
        <StyledInput
          node={node}
          value={node.stage.config.attribute}
          onChange={handleChangeAttribute}
          disabled={disabled}
        />
      </React.Fragment>
    );
  },
);
