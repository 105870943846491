/**
 *
 * Created by neo on 15.01.17.
 */

import { toJS, observable, action } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { PageResult } from '../PageResult';
import { Athlete } from '../Athlete/Athlete';
import { Token } from '../../Services/Security/Token';
import { Backend } from '../../Services/Http/Backend';
import { AuthBackend } from '../../Services/Http/AuthBackend';
import { Audited } from '../Audited';

export class Employee extends Audited {
  @observable
  gymId: string = '';
  @observable
  athlete: Athlete = new Athlete();
  @observable
  roles: Array<string> = [];
  @observable
  password: string = '';

  constructor(json?: any) {
    super(json);
    if (json) {
      this.gymId = json.gymId;
      this.athlete = new Athlete(json.athlete);
      this.roles = json.roles || [];
    }
  }

  @action
  save() {
    return HttpBackend.post(`/gym/employee?gymId=${this.gymId}`, toJS(this));
  }

  @action
  remove() {
    return HttpBackend.delete(`/gym/employee/${this.id}?gymId=${this.gymId}`);
  }

  async fetchOAuthToken(): Promise<Token> {
    return this.athlete.fetchOAuthToken().then((athleteToken) =>
      new AuthBackend(athleteToken).post(`/uaa/auth/gym?gymId=${this.gymId}`).then((result) => {
        if (result) {
          return new Token(result);
        }
        throw new Error('no employee');
      }),
    );
  }

  static find(gymId: string, pageable: any = { start: 0, sort: 'athlete.firstname,ASC' }): Promise<Employee[]> {
    return HttpBackend.get(`/gym/employee`, { gymId, ...pageable }).then(
      (res) => res?.map((r) => new Employee(r)) ?? [],
    );
  }

  static count(gymId: string, name: string = ''): Promise<number> {
    return HttpBackend.get(`/gym/employee/count`, { name, gymId });
  }
}
