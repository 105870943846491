/**
 * Created by andreaskarantzas on 28.10.20.
 */

import React, { useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import { ObservableMap, runInAction } from 'mobx';
import Typography from '@mui/material/Typography/Typography';
import FileInfo from '../../../../../Model/FileInfo/FileInfo';
import MediaService from '../../../../../Services/MediaService';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import Dropzone from 'react-dropzone';
import { GridListMediaItem } from '../../../../Media/GridListMediaItem';
import CircularProgress from '@mui/material/CircularProgress';

interface ComponentStylesImageProps {
  component: NativeFeedComponent;
}

export const ComponentStylesImage: React.FC<ComponentStylesImageProps> = observer((props) => {
  const { classes } = useStyles();
  const { component } = props;

  const store = useLocalStore(
    (source) => ({
      uploading: false,
      filesToUpload: new Array<FileInfo>(),
      uploadHandler(file: FileInfo, event: any) {
        console.log('upload', event);
        (file as any).loaded = event.loaded;
      },
      get styles(): ObservableMap<string, string | number> {
        return source.component.props.style;
      },
      get media() {
        return source.component.media;
      },
      async handleDrop(files: Array<File>) {
        if (!store.uploading) {
          store.uploading = true;
          store.filesToUpload = files.map((f) => new FileInfo(f));
          try {
            const medias = await Promise.all(
              store.filesToUpload.map((f) =>
                MediaService.uploadMedia(f.file, (event) => store.uploadHandler(f, event)),
              ),
            );
            runInAction(() => {
              if (medias.length > 0) {
                source.component.media = medias[0];
              }
            });
          } catch (e) {
            console.log('Upload failed', files, e);
          } finally {
            store.filesToUpload = [];
            store.uploading = false;
          }
        }
      },
    }),
    props,
  );

  const handleRemoveFile = useCallback(() => {
    runInAction(() => {
      component.media = undefined;
    });
  }, [component]);

  return (
    <Grid className={classes.container}>
      <Typography variant="h6">Image</Typography>
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item>
            {store.uploading ? (
              <CircularProgress />
            ) : (
              <Dropzone onDrop={store.handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="dropzone"
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <input {...getInputProps()} />
                    {'Drop Medias (Images or Videos) here'}
                  </div>
                )}
              </Dropzone>
            )}
          </Grid>
          <Grid item xs>
            {store.media ? <GridListMediaItem media={store.media} onRemove={handleRemoveFile} /> : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
}));
