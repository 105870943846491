/**
 * Created by neo on 01.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { IObservableArray, isObservableArray, runInAction } from 'mobx';
import { useEffect } from 'react';
import { SingleColRow } from './SingleColRow';
import { EquipmentTypeGroup } from '../Model/Equipment/EquipmentTypeGroup';

export type EquipmentTypeGroupInputProps = {
  container: IObservableArray<string> | Array<string>;
  label?: string;
  disabled?: boolean;
  onChange?: (groups: EquipmentTypeGroup[]) => any;
};

export const EquipmentTypeGroupInput: React.FC<EquipmentTypeGroupInputProps> = observer((props) => {
  const { container, label, disabled, onChange } = props;
  const store = useLocalStore(() => ({
    suggestions: new Array<EquipmentTypeGroup>(),
    existing: new Array<EquipmentTypeGroup>(),
    loadExisting(equipmentTypeGroups: string[]) {
      EquipmentTypeGroup.getAll(equipmentTypeGroups).then((result) => runInAction(() => (store.existing = result)));
    },
  }));

  useEffect(() => {
    store.loadExisting(container);
  }, [store, container]);

  const fetch = React.useCallback(
    (query?: string) =>
      EquipmentTypeGroup.list({
        query,
        page: 0,
        sort: 'defaultName,ASC',
      }).then((res) => res.filter((b) => !container.find((b1) => b1 === b.id))),
    [container],
  );

  const handleChange = React.useCallback(
    (equipmentTypeGroups?: EquipmentTypeGroup[]) => {
      if (isObservableArray(container)) {
        container.replace((equipmentTypeGroups || []).map((b) => b.id));
      } else {
        container.splice(0, container.length);
        (equipmentTypeGroups || []).forEach((b) => container.push(b.id));
      }
      store.existing = equipmentTypeGroups || [];
      onChange && onChange(equipmentTypeGroups || []);
    },
    [store, container, onChange],
  );

  const getOptionLabel = React.useCallback((option: EquipmentTypeGroup) => option.defaultName, []);

  const getOptionValue = React.useCallback((option: EquipmentTypeGroup) => option, []);

  return (
    <SingleColRow>
      <FormGroup>
        {label ? <Label>{label ? label : 'Equipment Type Groups'}</Label> : null}
        <AsyncSelect
          value={store.existing.slice()}
          cacheOptions
          defaultOptions
          isClearable={true}
          loadOptions={fetch}
          isMulti={true}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue as any}
          onChange={handleChange as any}
          isDisabled={disabled}
        />
      </FormGroup>
    </SingleColRow>
  );
});
