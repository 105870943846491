/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MesoCycleEditor } from './MesoCycleEditor/MesoCycleEditor';
import styled from '@emotion/styled';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { runInAction } from 'mobx';

export type MesoCyclesEditorProps = {
  template: FitnessProgramTemplate;
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MesoCyclesEditor: React.FC<MesoCyclesEditorProps> = observer(({ template }: MesoCyclesEditorProps) => {
  const handleRemove = React.useCallback(
    (mesoCycle) => {
      runInAction(() => (template.mesoCycles = template.mesoCycles.filter((m) => m.id !== mesoCycle.id)));
    },
    [template],
  );

  return (
    <Container>
      {template.mesoCycles.map((mesoCycle, index) => (
        <MesoCycleEditor
          key={mesoCycle.id}
          template={template}
          mesoCycle={mesoCycle}
          onRemove={handleRemove}
          index={index}
          headerColor="#FFAD87"
        />
      ))}
    </Container>
  );
});
