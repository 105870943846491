/**
 * Created by neo on 12.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MindfulnessProgramTemplateDayUnit } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnit';
import { MindfulnessProgramTemplateDayUnitBreathing } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitBreathing';
import { BreathingEntryContent } from './Breathing/BreathingEntryContent';
import { MindfulnessProgramTemplateDayUnitVideo } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitVideo';
import { VideoEntryContent } from './Video/VideoEntryContent';
import { MindfulnessProgramTemplateDayUnitAudio } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitAudio';
import { AudioEntryContent } from './Audio/AudioEntryContent';
import { MindfulnessProgramTemplateDayUnitWorkoutTemplate } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitWorkoutTemplate';
import { WorkoutTemplateEntryContent } from './WorkoutTemplate/WorkoutTemplateEntryContent';

export type UnitEntryModalContentSwitchProps = {
  entry: MindfulnessProgramTemplateDayUnit;
};

export const UnitEntryModalContentSwitch: React.FC<UnitEntryModalContentSwitchProps> = observer(({ entry }) => {
  if (entry instanceof MindfulnessProgramTemplateDayUnitBreathing) {
    return <BreathingEntryContent entry={entry} />;
  } else if (entry instanceof MindfulnessProgramTemplateDayUnitVideo) {
    return <VideoEntryContent entry={entry} />;
  } else if (entry instanceof MindfulnessProgramTemplateDayUnitAudio) {
    return <AudioEntryContent entry={entry} />;
  } else if (entry instanceof MindfulnessProgramTemplateDayUnitWorkoutTemplate) {
    return <WorkoutTemplateEntryContent entry={entry} />;
  } else return null;
});
