import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { SortableHeadColumn, SortingDirection } from './SortableHeadColumn';
import { useState } from 'react';

export interface SortableTableHeadColumnEntry {
  label: string;
  columnKey: string;
  sortable?: boolean;
  nowrap?: boolean;
}

export interface SortableTableHeadProps {
  columns: SortableTableHeadColumnEntry[];
  onSort?: (columnKey?: string, direction?: SortingDirection) => any;
  className?: any;
}

export const SortableTableHead: React.FC<SortableTableHeadProps> = observer(({ onSort, columns, className }) => {
  const [currentSortingColumn, setCurrentSortingColumn] = useState<string | undefined>();
  const [currentSortingDirection, setCurrentSortingDirection] = useState<SortingDirection | undefined>();

  const handleColumnPress = React.useCallback(
    (columnKey: string) => {
      if (currentSortingColumn === columnKey) {
        if (currentSortingDirection === 'DESC') {
          setCurrentSortingColumn(undefined);
          setCurrentSortingDirection(undefined);
        } else {
          setCurrentSortingDirection('DESC');
        }
      } else {
        setCurrentSortingColumn(columnKey);
        setCurrentSortingDirection('ASC');
      }
      onSort && onSort(currentSortingColumn, currentSortingDirection);
    },
    [onSort, currentSortingColumn, currentSortingDirection],
  );

  return (
    <thead className={className}>
      <tr>
        {columns.map((column) => (
          <SortableHeadColumn
            key={column.columnKey}
            label={column.label}
            columnKey={column.columnKey}
            sortable={column.sortable}
            sortDirection={column.columnKey === currentSortingColumn ? currentSortingDirection : undefined}
            onClick={handleColumnPress}
            nowrap={column.nowrap}
          />
        ))}
      </tr>
    </thead>
  );
});
