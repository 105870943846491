/**
 * Created by katarinababic on 15.11.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { FileContent } from '../../../Model/Media/FileStorage/FileContent';
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Button, Space } from 'antd';
import { useParams } from 'react-router';
import saveAs from 'file-saver';

export type FileTableRowProps = {
  index: number;
  file: FileContent;
  onRenameFile?: (file: FileContent) => void;
  onDeleteFile?: (file: FileContent) => void;
};

export const FileTableRow: React.FC<FileTableRowProps> = observer(({ index, file, onDeleteFile, onRenameFile }) => {
  const { folderId } = useParams();

  let displayName = file.displayName;
  if (folderId && file.displayName.startsWith(folderId)) {
    displayName = file.displayName.substring(folderId.length + 1);
  }

  const handleRenameFile = React.useCallback(() => {
    onRenameFile?.(file);
  }, []);

  const handleDeleteFile = React.useCallback(() => {
    onDeleteFile?.(file);
  }, []);

  const handleDownloadFile = React.useCallback(() => {
    FileContent.download([file.name])
      .then((res) => {
        saveAs(res.data, file.displayName);
      })
      .catch((e) => console.log('FileContent.download error', e));
  }, [file]);

  return (
    <tr>
      <td>{`${index + 1}.`}</td>
      <td style={{ cursor: 'pointer' }}>
        <FileIcon />
      </td>
      <td style={{ cursor: 'pointer' }}>{displayName}</td>
      <td>{file.created.toLocaleString()}</td>
      <td>{file.lastModified.toLocaleString()}</td>
      <td style={{ textAlign: 'right' }}>
        <Space wrap={false}>
          <Button type="dashed" size="small" onClick={handleDownloadFile}>
            Download
          </Button>
          <Button type="dashed" size="small" onClick={handleRenameFile}>
            Edit
          </Button>
          <Button type="dashed" size="small" danger={true} onClick={handleDeleteFile}>
            Delete
          </Button>
        </Space>
      </td>
    </tr>
  );
});
