import * as React from 'react';
import { observer } from 'mobx-react';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { observable } from 'mobx';
import { Col, Container, Input, Row, Table } from 'reactstrap';
import { AxonTrackingTokenTableRow } from './AxonTrackingTokenTableRow';
import { AxonTrackingToken } from '../../../../../Model/Axon/AxonTrackingToken';

export interface AxonSnapshotTabProps {
  aggregateIdentifier?: string;
}

@observer
export class AxonTrackingTokenTab extends React.Component<AxonSnapshotTabProps> {
  @observable
  query: string = '';
  @observable.shallow
  results: AxonTrackingToken[] = [];
  debounce?: any;

  componentDidMount(): void {
    this.setup();
  }

  componentWillUnmount(): void {
    this.debounce && clearTimeout(this.debounce);
  }

  async setup() {
    this.submit();
  }

  handleQueryChange = ({ target: { value } }) => {
    this.query = value;
    // this.debounce = setTimeout(() => {
    //   this.submit();
    // }, 300);
  };

  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.submit();
    }
  };

  handleRemove = async (token: AxonTrackingToken) => {
    if (window.confirm(`Delete ${token.processorName}?`)) {
      await token.delete();
      this.results = this.results.filter((r) => r.id !== token.id);
    }
  };

  async submit() {
    const { query } = this;
    this.results = await AxonTrackingToken.find({ query, sort: 'processorName,ASC' });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Input type="text" value={this.query} onChange={this.handleQueryChange} onKeyDown={this.handleKeyDown} />
          </Col>
        </Row>
        <SingleColRow>
          <Table>
            <thead>
              <tr>
                <th>Processor</th>
                <th>Owner</th>
                <th>Segment</th>
                <th>Data</th>
                <th>Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.results.map((result: AxonTrackingToken) => (
                <AxonTrackingTokenTableRow key={result.id} trackingToken={result} onRemove={this.handleRemove} />
              ))}
            </tbody>
          </Table>
        </SingleColRow>
      </Container>
    );
  }
}
