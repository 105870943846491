export const allSelectContentWeekly = `
SELECT *, week as event_date FROM (
  SELECT DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
         e.key AS key,
         e.value.string_value AS value,
         COUNT(DISTINCT user_id) AS user_count,
         COUNT(*) AS select_count
  FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      UNNEST (event_params) AS e
  WHERE
      _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
  GROUP BY
      week,
      key,
      value
  ORDER BY
      week,
      key,
      value                                  
)
`;

export const gymSelectContentWeekly = `
SELECT *, week as event_date FROM (
  SELECT DATE_TRUNC(PARSE_DATE('%Y%m%d', event_date), WEEK) as week,
          e.key AS key,
          e.value.string_value AS value,
          COUNT(DISTINCT user_id) AS user_count,
          COUNT(*) AS select_count
  FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      UNNEST (event_params) AS e,
      UNNEST (user_properties) AS p
  WHERE
      _TABLE_SUFFIX BETWEEN @start_date
    AND @end_date
    AND event_name = 'select_content'
    AND e.key = 'content_type'
    AND p.key = 'gymId'
    AND p.value.string_value = @gym_id
  GROUP BY
      week,
      key,
      value
  ORDER BY
      week,
      key,
      value

  )
`;
