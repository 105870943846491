/**
 * Created by neo on 20.01.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Media } from '../../../../../../Model/Media/Media';
import { Button, Space, Upload } from 'antd';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import MediaService from '../../../../../../Services/MediaService';
import { MediaTextTrackEntry } from './MediaTextTrackEntry';
import { UploadOutlined } from '@ant-design/icons';
import { toWebVTT } from './SrtToVttConverter';
import { v4 as UUID } from 'uuid';
import { MediaTextTrack } from '../../../../../../Model/Media/MediaTextTrack';
import { MediaTextTrackTranslationComponent } from './MediaTextTrackTranslationComponent';
import { MediaTextTrackVttEditor } from './MediaTextTrackVttEditor';
import webvtt from 'node-webvtt';

export type MediaTextTrackTabProps = {
  media: Media;
};

export const MediaTextTrackTab: React.FC<MediaTextTrackTabProps> = observer(({ media }) => {
  const [selectedTrack, setSelectedTrack] = useState<MediaTextTrack | undefined>();

  const handleBeforeUpload = React.useCallback(
    (file) => {
      toWebVTT(file).then((vtt) => {
        const parsed = webvtt.parse(vtt);
        parsed.cues.forEach((cue) => (cue.styles = 'align:center line:80%'));
        const compiled = webvtt.compile(parsed);

        const blob = new Blob([compiled], { type: 'text/vtt' });
        const newFile = new File([blob], `${UUID()}.vtt`, { type: 'text/vtt' });
        MediaService.uploadMedia(newFile).then((newMedia) => {
          runInAction(() =>
            media.textTracks.push(
              new MediaTextTrack({
                url: newMedia.url,
                index: newMedia.textTracks.length,
                language: 'en',
                mimeType: 'text/vtt',
                title: 'English',
              }),
            ),
          );
        });
      });

      return false;
    },
    [media],
  );

  const handleEditTrack = React.useCallback((track: MediaTextTrack) => {
    setSelectedTrack(track);
  }, []);

  return (
    <React.Fragment>
      <MediaTextTrackVttEditor media={media} selectedTrack={selectedTrack} />
      <SingleColRow>
        {media.textTracks.map((track) => (
          <MediaTextTrackEntry key={track.index} media={media} track={track} onEdit={handleEditTrack} />
        ))}
      </SingleColRow>
      <SingleColRow>
        <Space>
          <Upload accept=".srt,.vtt,text/vtt,text/srt" beforeUpload={handleBeforeUpload as any}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
          <MediaTextTrackTranslationComponent media={media} />
        </Space>
      </SingleColRow>
    </React.Fragment>
  );
});
