/**
 * Created by katarinababic on 3.5.22.
 */

export const querySelectedEntry = `
SELECT
  e_id.value.string_value AS entry_id,
  COUNT(DISTINCT user_id) AS user_count,
  COUNT(*) AS select_count
FROM
  \`flinq-6796d.analytics_201736462.events_*\`,
  UNNEST (event_params) AS e,
  UNNEST (event_params) AS e_id
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'select_content'
  AND e.key = 'content_type'
  AND e.value.string_value = @entry_type
  AND e_id.key = 'item_id'
  AND e_id.value.string_value = @entry_id
GROUP BY
  entry_id
`;
