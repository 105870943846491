import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { LimitNodeModel } from './LimitNodeModel';
import { LimitNodeWidget } from './LimitNodeWidget';
import { LimitStage } from '../../../../../../../Model/Coach/Stages/LimitStage';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';

export class LimitNodeFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('limit');
  }

  generateReactWidget(event): JSX.Element {
    return <LimitNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new LimitNodeModel(new LimitStage(new Pipeline()));
  }
}
