/**
 *
 * Created by neo on 13.03.17.
 */
import * as React from 'react';
import { isObservableArray, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { BodyPartJoint } from '../../../../Model/BodyPart/BodyPartJoint';
import { ActionMeta } from 'react-select';

export type Props = {
  joints: BodyPartJoint[];
  header?: string;
  disabled?: boolean;
};

@observer
export class BodyPartJointCard extends React.Component<Props> {
  @observable
  value: string = '';

  fetch = (name?: string): Promise<Array<BodyPartJoint>> =>
    BodyPartJoint.find({
      name,
      page: 0,
      sort: 'defaultName,ASC',
    }).then((result) => result.filter((b) => !this.props.joints.find((b1) => b1.id === b.id)));

  handleChange = (joints: BodyPartJoint[], action?: ActionMeta<any>) => {
    if (isObservableArray(this.props.joints)) {
      this.props.joints.replace(joints || []);
    } else {
      this.props.joints.splice(0, this.props.joints.length);
      (joints || []).forEach((b) => this.props.joints.push(b));
    }
  };

  getOptionLabel = (option: BodyPartJoint) => option.defaultName;

  getOptionValue = (option: BodyPartJoint) => option;

  render() {
    const {
      props: { header, disabled },
    } = this;

    return (
      <FormGroup>
        {header ? <Label>{header ? header : 'Joints'}</Label> : null}
        <AsyncSelect
          value={this.props.joints.slice()}
          cacheOptions
          defaultOptions
          isClearable={true}
          loadOptions={this.fetch}
          isMulti={true}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue as any}
          onChange={this.handleChange as any}
          isDisabled={disabled}
        />
      </FormGroup>
    );
  }
}
