import { Config } from '../../Config/Config';
import * as qs from 'qs';
import { Token } from '../Security/Token';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class Backend {
  private readonly api: AxiosInstance;

  constructor(authData?: Token, defaultHeader: any = {}, baseURL: string = Config.backend.url) {
    const headers = Object.assign(
      {
        Accept: 'application/json',
      },
      authData?.access_token ? { Authorization: `Bearer ${authData.access_token}` } : {},
      defaultHeader,
    );

    this.api = axios.create({
      baseURL,
      headers,
      timeout: 30000,
      paramsSerializer: {
        serialize: (params) =>
          qs.stringify(params, {
            arrayFormat: 'comma',
            skipNulls: true,
            serializeDate: (date) => date.toISOString(),
          }),
      },
    });
  }

  filterSuccess<T>(): (response: AxiosResponse<T>) => T {
    return (response) => response.data;
  }

  get(path: string, params?: any, headers?: any): Promise<any> {
    return this.api.get<any>(path, { headers: headers || {}, params }).then(this.filterSuccess());
  }

  delete(path: string, params?: any, headers?: any): Promise<any> {
    return this.api.delete<any>(path, { headers: headers || {}, params }).then(this.filterSuccess());
  }

  head(path: string, params?: any, headers?: any): Promise<AxiosResponse<any>> {
    return this.api.head<any>(path, { headers: headers || {}, params });
  }

  post(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
    return this.api.post(path, data, config).then(this.filterSuccess());
  }

  postRaw(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
    return this.api.post(path, data, config);
  }

  put(path: string, data?: any, headers?: any): Promise<any> {
    return this.api.put(path, data, { headers: headers || {} }).then(this.filterSuccess());
  }

  postForm(path: string, formData: Record<string, any>, options?: AxiosRequestConfig): Promise<any> {
    return this.post(
      path,
      formData,
      Object.assign({}, options ?? {}, {
        headers: Object.assign({}, options?.headers ?? {}, { 'Content-Type': 'application/x-www-form-urlencoded' }),
      }),
    );
  }

  postMultipartFormData(path: string, formData: Record<string, any>, options?: AxiosRequestConfig): Promise<any> {
    return this.post(
      path,
      formData,
      Object.assign({}, options ?? {}, {
        headers: Object.assign({}, options?.headers ?? {}, { 'Content-Type': 'multipart/form-data' }),
      }),
    );
  }

  upload(path: string, data: FormData, config?: AxiosRequestConfig): Promise<any> {
    console.log('uploading', config);
    return this.api.post(path, data, config).then(this.filterSuccess());
  }
}

export default Backend;
