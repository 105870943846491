/**
 * Created by neo on 23.08.21.
 */
import { v4 as UUID } from 'uuid';
import {
  computed,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { WorkoutTemplate } from '../../SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { ProgramTemplateProgramParams, ProgramTemplateProgramParamsJson } from './ProgramTemplateProgramParams';

export type WorkoutDayEntryJson = {
  id: string;
  includedTags: string[];
  excludedTags: string[];
  workoutTemplateId: string;
  params?: ProgramTemplateProgramParamsJson;
};

export class WorkoutDayEntry {
  @observable
  id = UUID();
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];
  @observable
  workoutTemplateId: string = '';
  @observable
  params?: ProgramTemplateProgramParams;
  @observable
  workoutTemplate?: WorkoutTemplate = undefined;
  disposer?: IReactionDisposer;

  constructor(json?: Partial<WorkoutDayEntryJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.includedTags = json.includedTags ?? [];
      this.excludedTags = json.excludedTags ?? [];
      this.workoutTemplateId = json.workoutTemplateId ?? '';
      this.params = json.params ? new ProgramTemplateProgramParams(json.params) : undefined;
    }
    onBecomeObserved(this, 'workoutTemplate', this.startFetch);
    onBecomeUnobserved(this, 'workoutTemplate', this.stopFetch);
  }

  startFetch = () => {
    this.disposer && this.disposer();
    this.disposer = reaction(
      () => this.workoutTemplateId,
      (templateId?: string) => {
        if (templateId) {
          WorkoutTemplate.get(templateId).then((result) => runInAction(() => (this.workoutTemplate = result)));
        } else {
          this.workoutTemplate = undefined;
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetch = () => {
    this.disposer && this.disposer();
  };

  toJS(newId: boolean = false): any {
    return {
      id: newId ? UUID() : this.id,
      includedTags: toJS(this.includedTags),
      excludedTags: toJS(this.excludedTags),
      workoutTemplateId: this.workoutTemplateId,
      params: this.params?.toJS(),
    };
  }

  @computed
  get minWorkoutDurationInMinutes(): number | undefined {
    return this.workoutTemplate?.minDurationInMinutes;
  }

  @computed
  get maxWorkoutDurationInMinutes(): number | undefined {
    return this.workoutTemplate?.maxDurationInMinutes;
  }
}
