/**
 * Created by neo on 01.01.17.
 */

import { Backend } from './Backend';
import { Token } from '../Security/Token';
import { AxiosRequestConfig } from 'axios';
import { Config } from '../../Config/Config';

/**
 * Special class that does calls the backend by using the app credentials (client_credentials)
 */
class AppBackendClass {
  authDataPromise?: Promise<Token>;
  authData?: Token;

  authorize(): Promise<Token> {
    if (!this.authDataPromise) {
      if (!this.authData || (this.authData && this.authData.expired)) {
        const headers = {
          Accept: 'application/json',
        };

        const payload = new FormData();
        payload.set('grant_type', 'client_credentials');
        this.authDataPromise = new Backend(undefined, undefined, Config.backend.authUrl)
          .postForm('/uaa/oauth/token', payload, { headers })
          .then((data: any) => {
            this.authDataPromise = undefined;
            this.authData = new Token(data);
            return this.authData;
          })
          .catch((err) => {
            this.authDataPromise = undefined;
            throw err;
          });
        return this.authDataPromise!;
      }
      return Promise.resolve(this.authData);
    }
    return this.authDataPromise;
  }

  post(path, data, headers = {}) {
    return this.authorize().then((authData) => {
      return new Backend(authData).post(path, data, headers);
    });
  }

  put(path, data, headers = {}) {
    return this.authorize().then((authData) => {
      return new Backend(authData).put(path, data, headers);
    });
  }

  delete(path, params?: any, headers: any = {}) {
    return this.authorize().then((authData) => {
      return new Backend(authData).delete(path, params, headers);
    });
  }

  get(path, data, headers) {
    return this.authorize().then((authData) => {
      return new Backend(authData).get(path, data, headers);
    });
  }

  upload(path: string, data: FormData, config?: AxiosRequestConfig) {
    return this.authorize().then((authData) => {
      return new Backend(authData).upload(path, data, config);
    });
  }
}

const AppBackend = new AppBackendClass();
export default AppBackend;
