/**
 * Created by neo on 05.11.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { GymTableRow } from './GymTableRow';
import { Table } from 'reactstrap';
import { Gym } from '../../../Model/Gym/Gym';

export type GymTableProps = {
  gyms: Gym[];
};

export const GymTable: React.FC<GymTableProps> = observer(({ gyms }: GymTableProps) => {
  return (
    <Table size="sm" striped responsive>
      <thead className="thead-inverse">
        <tr>
          <th />
          <th>{'Name'}</th>
          <th>{'Subscription'}</th>
          <th>{'Customers'}</th>
        </tr>
      </thead>
      <tbody>
        {gyms.slice().map((gym) => (
          <GymTableRow key={gym.id} gym={gym} />
        ))}
      </tbody>
    </Table>
  );
});
