/**
 * Created by neo on 03.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type HeadingElementProps = {
  component: NativeFeedComponent;
  lang?: string;
};

export const HeadingElement: React.FC<HeadingElementProps> = observer(({ component, lang }: HeadingElementProps) => {
  const { classes } = useStyles();

  return <div className={classes.content}>{component.getText(lang || 'de') || component.type}</div>;
});

const useStyles = makeStyles()(() => ({
  content: {
    padding: '0 16px',
    color: 'black',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
}));
