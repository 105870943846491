import { action, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Pipeline } from '../../Pipeline';
import { TagCondition } from '../TagCondition';
import { ParamCondition } from '../ParamCondition';
import { LocalizedValue } from '../../../LocalizedValue';

export class WorkoutPhaseTemplate {
  @observable
  id: string = UUID();
  @observable
  scriptId?: string = undefined;
  @observable
  description: LocalizedValue[] = [];
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  params: ParamCondition[];
  @observable
  pipeline: Pipeline = new Pipeline();

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.scriptId = json.scriptId;
      this.pipeline = Pipeline.fromJSON(json.pipeline);
      this.tagConditions = (json.tagConditions || []).map((t) => new TagCondition(t));
      this.params = (json.params ?? []).map((p) => new ParamCondition(p));
      this.description = (json.description ?? []).map((d) => new LocalizedValue(d));
    }
  }

  toJS(replaceId?: boolean) {
    return {
      id: replaceId ? UUID() : this.id,
      scriptId: this.scriptId,
      pipeline: this.pipeline.toJS(),
      description: this.description.map((d) => d.toJS()),
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      params: this.params.map((p) => p.toJS()),
    };
  }

  copy() {
    return new WorkoutPhaseTemplate(this.toJS(true));
  }

  @action
  setParam(paramName: string, value?: any) {
    this.params = this.params ?? {};
    this.params[paramName] = value;
  }

  @action
  removeParam(paramName: string) {
    this.params = this.params ?? {};
    delete this.params[paramName];
  }
}
