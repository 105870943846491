/**
 * Created by neo on 26.04.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Col, FormGroup } from 'reactstrap';
import { Input, Form, Select } from 'antd';

export type GymAddressFormProps = {
  gym: Gym;
};

export const GymAddressForm: React.FC<GymAddressFormProps> = observer(({ gym: { address } }: GymAddressFormProps) => {
  return (
    <React.Fragment>
      <Col xs={12}>
        <h4>Address</h4>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Form.Item label="Addressline">
            <Input
              type="text"
              value={address.addressLine1 || ''}
              onChange={(e) => (address.addressLine1 = e.target.value)}
            />
          </Form.Item>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Form.Item label="ZIP Code">
            <Input type="text" value={address.zipCode || ''} onChange={(e) => (address.zipCode = e.target.value)} />
          </Form.Item>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Form.Item label="City">
            <Input type="text" value={address.city || ''} onChange={(e) => (address.city = e.target.value)} />
          </Form.Item>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Form.Item label="State">
            <Input type="text" value={address.state || ''} onChange={(e) => (address.state = e.target.value)} />
          </Form.Item>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Form.Item label="Country">
            <Select value={address.country || ''} onChange={(e) => (address.country = e)}>
              <option value="CH">Switzerland</option>
              <option value="DE">Germany</option>
              <option value="FR">France</option>
              <option value="IT">Italy</option>
              <option value="ES">Spain</option>
            </Select>
          </Form.Item>
        </FormGroup>
      </Col>
    </React.Fragment>
  );
});
