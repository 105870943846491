/**
 * Created by neo on 16.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import {
  CartesianGrid,
  Legend,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  BarProps,
} from 'recharts';
import { ChartColors } from '../ChartColors';
import { useState } from 'react';

export type ActivityPopularityChartProps = Pick<BarProps, 'stackId'> & {
  data: AnalyticsRow[];
};

export const ActivityPopularityChart: React.FC<ActivityPopularityChartProps> = observer(({ data, stackId }) => {
  const [enabledFields, setEnabledFields] = useState<{ [key: string]: boolean }>({});

  const handleLegendClick = React.useCallback((data) => {
    const dataKey = typeof data.dataKey === 'string' ? data.dataKey : 'avg_activities_per_user';
    setEnabledFields((existing) => Object.assign({}, existing, { [dataKey]: !existing[dataKey] }));
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart height={250} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="event_date" />
        <YAxis yAxisId="left" label={{ value: '# Unique Users', position: 'insideTopLeft', dy: -25 }} />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickLine={false}
          axisLine={false}
          label={{ value: '# Activities', position: 'insideTopRight', dy: -25 }}
        />
        <Tooltip />
        <Legend onClick={handleLegendClick} />

        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="FeedEntry_read_count"
          stackId={stackId}
          fill="#CE7537"
          hide={enabledFields.FeedEntry_read_count}
          name="# Articles Read"
        />
        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="Workout_finished_gym_strength_count"
          stackId={stackId}
          fill="#D2A45F"
          hide={enabledFields.Workout_finished_gym_strength_count}
          name="# Workouts"
        />
        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="Workout_finished_workplace_count"
          stackId={stackId}
          fill="#6980C9"
          hide={enabledFields.Workout_finished_workplace_count}
          name="# Workplace Workouts"
        />
        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="activity_logged_count"
          stackId={stackId}
          fill="#0723D9"
          hide={enabledFields.activity_logged_count}
          name="# Activities Logged"
        />
        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="breathingSession_finished_count"
          stackId={stackId}
          fill="#E98FF1"
          hide={enabledFields.breathingSession_finished_count}
          name="# Breathing Sessions"
        />
        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="meditationSession_finished_count"
          stackId={stackId}
          fill="#1BDF91"
          hide={enabledFields.meditationSession_finished_count}
          name="# Meditation Sessions"
        />
        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="recipe_count"
          stackId={stackId}
          fill="#291412"
          hide={enabledFields.recipe_count}
          name="# Recipes Read"
        />
        <Bar
          yAxisId="right"
          type="monotone"
          dataKey="yogaSession_finished_count"
          stackId={stackId}
          fill="#53658D"
          hide={enabledFields.yogaSession_finished_count}
          name="# Yoga Sessions"
        />

        <Line
          yAxisId="left"
          dataKey="total_unique_users"
          stroke={ChartColors[1]}
          hide={enabledFields.total_unique_users}
          name="# Total Unique Users"
        />
        <Line
          yAxisId="left"
          dataKey="possible_total_users"
          stroke={ChartColors[2]}
          connectNulls
          hide={enabledFields.possible_total_users}
          name="# Total Possible Users"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
