/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { observer } from 'mobx-react';
import { UserRoleTableRow } from './UserRoleTableRow';
import { UserRole } from '../../../Model/UserRole';

export type Props = {
  roles: UserRole[];
  onSelect: (type: UserRole) => any;
  onDelete: (type: UserRole) => any;
};

@observer
export class UserRoleTable extends React.Component<Props> {
  render() {
    const {
      props: { roles, onSelect, onDelete },
    } = this;
    return (
      <Row>
        <Col>
          <Table size="sm" striped>
            <thead className="thead-inverse">
              <tr>
                <th>{'ID'}</th>
                <th>{'Name'}</th>
                <th>{'Action'}</th>
              </tr>
            </thead>
            <tbody>
              {roles.map((l: UserRole) => (
                <UserRoleTableRow key={l.id} role={l} onSelect={onSelect} onDelete={onDelete} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}
