/**
 * Created by katarinababic on 11.5.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { InputNumber } from 'antd';
import { NutrientData } from './IngredientEditModalNutrients';
import { NutrientMap } from '../../../../Model/Diet/Ingredient/NutritionInformation';
import { runInAction } from 'mobx';
import { Nutrient } from '../../../../Model/Diet/Ingredient/Nutrient';

export type IngredientEditModalNutrientItemProps = {
  nutrient: NutrientData;
  nutrientMap: NutrientMap;
};

export const IngredientEditModalNutrientItem: React.FC<IngredientEditModalNutrientItemProps> = observer(
  ({ nutrient, nutrientMap }) => {
    const value = nutrientMap[nutrient.key]?.amount ?? 0;

    const handleChangeValue = React.useCallback(
      (val) => {
        runInAction(() => (nutrientMap[nutrient.key] = new Nutrient({ amount: val, unit: nutrient.unit })));
      },
      [nutrientMap, nutrient],
    );

    return (
      <Row style={styles.row}>
        <Col xs={2}>
          <span>{nutrient.name}</span>
        </Col>
        <Col xs={'auto'}>
          <InputNumber min={0} onChange={handleChangeValue} value={value} />
        </Col>
        <Col xs={'auto'}>
          <span>{nutrient.unit}</span>
        </Col>
      </Row>
    );
  },
);

const styles = {
  row: {
    alignItems: 'center',
  },
};
