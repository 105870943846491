import { Stage, StagePortMode } from './Stage';
import { ExerciseBlock } from '../../ProgramPortfolio/ExerciseBlock';
import { Phase } from '../../ProgramPortfolio/Phase';
import { PhaseType } from '../../ProgramPortfolio/PhaseType';
import { toJS } from 'mobx';
import { DataSignal, dataSignal } from '../Signal/DataSignal';
import { Pipeline } from '../Pipeline';
import { ParamValues, PipelineContext } from '../PipelineContext';
import { notUndefined } from '../../../Utils/notUndefined';
import { TrackingKeyConfig } from './ExerciseBlockStage';

export type Substitute = {
  pipeline?: Pipeline;
  pipelineId?: string;
  params?: ParamValues;
};

export type PhaseStageOptions = {
  type: PhaseType;
  limit?: string;
  repeat?: string;
  substitutes: Substitute[];
  trackingKeys?: Array<TrackingKeyConfig>;
  startSets?: any; // default 1 or max if not set (can be higher than maxSets -> decrements)
};

export class PhaseStage extends Stage<PhaseStageOptions> {
  constructor(pipeline: Pipeline, json?: any, portMode: StagePortMode = 'ASYNC') {
    super(pipeline, json, portMode);
    this.config = Object.assign(
      {
        type: 'strength',
        substitutes: [],
        trackingKeys: [],
      },
      json.config ?? {},
    );
  }

  async process() {
    const { dataSignals } = this;
    console.log('phase', toJS(dataSignals));
    const limit = this.resolveParamNumber(this.config.limit, 1);
    const result = dataSignals.map((port) => port.data).filter((b) => b instanceof ExerciseBlock);
    const exerciseBlocks = await this.fetchRemaining(result.slice(0, Math.min(limit, result.length)), limit);
    if (exerciseBlocks.length > 0) {
      this.processNext(
        new DataSignal(
          new Phase(undefined, {
            type: this.config.type || 'strength',
            exerciseBlocks,
          }),
          0,
        ),
      );
    }
  }

  private async fetchRemaining(exerciseBlocks: ExerciseBlock[], limit: number): Promise<ExerciseBlock[]> {
    let remaining = limit - exerciseBlocks.length;
    if (remaining > 0) {
      let result = exerciseBlocks.slice();
      let excludedExerciseIds = exerciseBlocks.map((b) => b.exercise.id);
      for (const substitute of this.config.substitutes) {
        const newBlocks = await this.processSubstitute(substitute, remaining, excludedExerciseIds);
        result = result.concat(newBlocks);
        excludedExerciseIds = excludedExerciseIds.concat(newBlocks.map((b) => b.exercise.id));
        remaining = limit - result.length;
        if (remaining <= 0) {
          return result;
        }
      }
      return result;
    }
    return exerciseBlocks;
  }

  private processSubstitute(
    substitute: Substitute,
    limit: number,
    excludedExerciseIds: string[],
  ): Promise<ExerciseBlock[]> {
    const context = this.createContext(substitute, limit, excludedExerciseIds);
    return (
      substitute.pipeline
        ?.compile()
        .then((pipeline) => pipeline.executePromise(context))
        .then((signals) =>
          signals
            .map((s) => s.data)
            .filter(notUndefined)
            .flatMap((data: any) => this.filterData(data)),
        ) ?? Promise.resolve([])
    );
  }

  private filterData(data: any): ExerciseBlock[] {
    if (data instanceof ExerciseBlock) {
      return [data];
    } else if (data instanceof Phase) {
      return data.exerciseBlocks;
    } else if (Array.isArray(data)) {
      return data.flatMap((inner) => this.filterData(inner)).filter(notUndefined);
    }
    return [];
  }

  private createContext(substitute: Substitute, limit: number, excludedExerciseIds: string[]) {
    const context = this.pipeline.context.copy();
    context.excludeExerciseIds = excludedExerciseIds;
    const params = substitute.params ?? {};
    Object.keys(params).map((key) => {
      const value = params[key];
      if (typeof value === 'string' && value.startsWith('$')) {
        const param = value.replace('$', '');
        context.paramValues[key] = context.getValue(this.pipeline, param);
      } else {
        context.paramValues[key] = value;
      }
    });
    context.paramValues['limit'] = limit;
    console.log('createContext', toJS(context.paramValues), toJS(this.config));
    return context;
  }

  toJS(): any {
    return Object.assign(super.toJS(), {
      config: {
        limit: this.config.limit,
        repeat: this.config.repeat,
        type: this.config.type,
        startSets: this.config.startSets,
        trackingKeys: toJS(this.config.trackingKeys),
        substitutes: this.config.substitutes.map((sub) => ({
          pipelineId: sub.pipelineId,
          // tags: toJS(sub.tags),
          params: toJS(sub.params),
        })),
      },
    });
  }

  get type(): string {
    return 'phase';
  }
}
