/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from 'tss-react/mui';
import { v4 as UUID } from 'uuid';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { NativeFeedSideDrawerComponentDraggableItem } from './NativeFeedSideDrawerComponentDraggableItem';
import { NativeFeedComponentType } from '../../../../../Model/Feed/NativeFeedComponent';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';

export type NativeFeedSideDrawerComponentItemType = {
  id: string;
  type: NativeFeedComponentType;
};

export const nativeFeedSideDrawerComponentItems: Array<NativeFeedSideDrawerComponentItemType> = [
  {
    id: UUID(),
    type: 'Paragraph',
  },
  {
    id: UUID(),
    type: 'Button',
  },
  {
    id: UUID(),
    type: 'SecondaryButton',
  },
  {
    id: UUID(),
    type: 'Image',
  },
  {
    id: UUID(),
    type: 'Video',
  },
];

export const NativeFeedSideDrawerComponents: React.FC = observer(() => {
  const { classes } = useStyles();

  return (
    <Droppable droppableId="nativeFeedSideDrawerComponents" isDropDisabled={true}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography className={classes.heading}>Components</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.secondaryHeading}>
                    Drag the components below to the Feed structure
                  </Typography>
                </Grid>
                {nativeFeedSideDrawerComponentItems.map((item, index) => (
                  <Grid item xs={6} key={item.id}>
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <React.Fragment>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            id={`nativeFeedSideDrawerDraggableItem${item.type}`}
                          >
                            <NativeFeedSideDrawerComponentDraggableItem item={item} />
                          </div>
                        </React.Fragment>
                      )}
                    </Draggable>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Droppable>
  );
});

const useStyles = makeStyles()((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
    flexBasis: '100%',
  },
}));
