/**
 * Created by neo on 11.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreCategory } from '../../../Model/Explore/ExploreCategory';
import { useLocation, useParams } from 'react-router-dom';
import { ExploreContent } from '../../../Model/Explore/ExploreContent';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { DefaultExploreEntry } from '../../../Model/Explore/DefaultExploreEntry';
import { ExploreContentBuilder } from '../../../Model/Explore/ExploreContentBuilder';
import { ExploreCategoryModal } from './ExploreCategoryModal';
import { ExploreEntryModal } from './ExploreEntryModal/ExploreEntryModal';

export type ExploreContentEditModalProps = {
  onSaved?: (category: ExploreContent) => void;
  onDeleted?: (category: ExploreContent) => void;
};

export const ExploreContentEditModal: React.FC<ExploreContentEditModalProps> = observer(({ onSaved, onDeleted }) => {
  const location = useLocation();
  const parentId = new URLSearchParams(location.search).get('parentId') ?? undefined;
  const gymId = new URLSearchParams(location.search).get('gymId') ?? undefined;
  const contentType = new URLSearchParams(location.search).get('contentType') ?? undefined;

  const { contentId } = useParams<{ contentId: string }>();
  const [content, setContent] = useState<ExploreContent | undefined>();
  const isNew = contentId === 'new';

  useEffect(() => {
    if (contentId && contentId !== 'new') {
      ExploreContentBuilder.findOne(contentId, { gymId }).then((res) => setContent(res));
    } else if (contentType === 'entry') {
      setContent(new DefaultExploreEntry({ categories: parentId ? [parentId] : [], gymId }));
    } else {
      setContent(new ExploreCategory({ categories: parentId ? [parentId] : [], gymId }));
    }
  }, [parentId, contentId, contentType, gymId]);

  const handleEntryChanged = React.useCallback((newEntry: ExploreEntry) => setContent(newEntry), []);

  if (content instanceof ExploreCategory) {
    return <ExploreCategoryModal category={content} isNew={isNew} onSaved={onSaved} />;
  } else if (content instanceof ExploreEntry) {
    return (
      <ExploreEntryModal
        entry={content}
        isNew={isNew}
        onSaved={onSaved}
        onDeleted={onDeleted}
        onEntryChanged={handleEntryChanged}
      />
    );
  }

  return null;
});
