/**
 * Created by andreaskarantzas on 2018-12-03
 */

import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import { ThemeConfig } from '../../../Config/Theme';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import styled from '@emotion/styled';

const StyledImage = styled.div`
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export interface NativeFeedHeaderProps {
  entry: ArticleEntry;
  lang?: string;
}

export const NativeFeedHeader: React.FC<NativeFeedHeaderProps> = observer((props) => {
  const { classes } = useStyles();

  const store = useLocalStore(
    (source: NativeFeedHeaderProps) => ({
      /* computed */
      get picture(): string | undefined {
        return source.entry.image?.medium;
      },
      get style(): any {
        if (store.picture) {
          return { backgroundImage: `url('${store.picture}')` };
        }
        return { backgroundImage: `url(${ThemeConfig.Images.logoBanner})` };
      },
    }),
    props,
  );

  return (
    <StyledImage className={classes.container} style={store.style}>
      <div className={classes.overlay} />
      <Grid
        className={classes.textContainer}
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Typography align="left" variant="subtitle1" className={classes.text}>
          by kinastic Coach
        </Typography>
        <Typography align="left" variant="h6" className={classes.text}>
          {props.entry.getName(props.lang ?? 'de')}
        </Typography>
      </Grid>
    </StyledImage>
  );
});

const useStyles = makeStyles()(() => ({
  container: {
    height: 250,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  },
  text: {
    color: ThemeConfig.Colors.white,
  },
  textContainer: {
    paddingBottom: 8,
    paddingLeft: 8,
    zIndex: 100,
  },
}));
