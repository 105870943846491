/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';

export type PipelineTrayItemProps = {
  workoutTemplate: WorkoutTemplate;
};

export const StyledTray = styled.div<{ color: string }>`
  color: black;
  background-color: #414bb2;
  font-family: Helvetica, Arial;
  padding: 5px;
  color: white;
  margin: 0px 10px;
  margin-bottom: 2px;
  cursor: pointer;
`;

export const WorkoutTrayItem: React.FC<PipelineTrayItemProps> = observer(({ workoutTemplate }) => {
  const handleDragStart = React.useCallback(
    (event) => {
      event.dataTransfer.setData('workout-template-data', workoutTemplate.id);
    },
    [workoutTemplate],
  );

  return (
    <StyledTray color="yellow" draggable={true} onDragStart={handleDragStart}>
      {workoutTemplate.templateName}
    </StyledTray>
  );
});
