/**
 * Created by neo on 19.06.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TrayWidget } from './TrayWidget';
import { TrayItemWidget } from './TrayItemWidget';
import { CanvasContainer } from '../PipelineParamEditor/CanvasContainer';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import createEngine, { DiagramEngine } from '@projectstorm/react-diagrams';
import { Pipeline } from '../../../../../Model/Coach/Pipeline';
import { Form, Input } from 'antd';
import { PipelineBuilder } from '../../../../../Model/Coach/PipelineBuilder';
import { StageModelBuilder } from './Model/StageModelBuilder';
import { PipelineTagsInput } from '../PipelineTagsInput';
import { Col, Row } from 'reactstrap';
import { NodeColors } from './NodeColors';

export type FlowEditorProps = {
  pipeline?: Pipeline;
  engine?: DiagramEngine;
};

export const StyledBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const StyledHeader = styled.div`
  display: flex;
  background: rgb(30, 30, 30);
  flex-grow: 0;
  flex-shrink: 0;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  padding: 10px;
  align-items: center;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const StyledLayer = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const FlowEditor: React.FC<FlowEditorProps> = observer(({ engine, pipeline }: FlowEditorProps) => {
  const handleChangePipelineName = React.useCallback(
    ({ target: { value } }: any) => {
      if (pipeline) {
        pipeline.name = value;
      }
    },
    [pipeline],
  );

  const handleDrop = React.useCallback(
    (event) => {
      if (engine && pipeline) {
        const data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'));
        const stage = PipelineBuilder.createStage(pipeline, { type: data.stage, config: {} });
        pipeline?.addStage(stage);
        const node = StageModelBuilder.build(stage); // new DefaultStageNodeModel(pipeline, stage);
        const point = engine.getRelativeMousePoint(event);
        node.setPosition(point);
        stage.x = point.x;
        stage.y = point.y;
        engine.getModel().addNode(node);
      }
    },
    [engine, pipeline],
  );

  return (
    <StyledBody>
      <StyledHeader>
        <div className="title" style={{ width: '100%' }}>
          <Row>
            <Col>
              <Input value={pipeline?.name} onChange={handleChangePipelineName} />
            </Col>
            <Col>{pipeline ? <PipelineTagsInput pipeline={pipeline} /> : null}</Col>
          </Row>
        </div>
      </StyledHeader>
      <StyledContent>
        <TrayWidget>
          <TrayItemWidget
            model={{ type: 'stage', stage: 'bodyPartClusterSort' }}
            name="bodyPartClusterSort"
            color={NodeColors.bodyPartClusterSort ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'collect' }}
            name="collect"
            color={NodeColors.collect ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'concat' }}
            name="concat"
            color={NodeColors.concat ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'distinct' }}
            name="distinct"
            color={NodeColors.distinct ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'duplicate' }}
            name="duplicate"
            color={NodeColors.duplicate ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'exerciseBlock' }}
            name="exerciseBlock"
            color={NodeColors.exerciseBlock ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'fetch' }}
            name="fetch"
            color={NodeColors.fetch ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'filter' }}
            name="filter"
            color={NodeColors.filter ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'flatten' }}
            name="flatten"
            color={NodeColors.flatten ?? NodeColors.default}
          />
          {/*<TrayItemWidget*/}
          {/*  model={{ type: 'stage', stage: 'graphSort' }}*/}
          {/*  name="graphSort"*/}
          {/*  color={NodeColors['graphSort'] ?? NodeColors.default}*/}
          {/*/>*/}
          <TrayItemWidget
            model={{ type: 'stage', stage: 'group' }}
            name="group"
            color={NodeColors['group'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'inputSort' }}
            name="inputSort"
            color={NodeColors['inputSort'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'limit' }}
            name="limit"
            color={NodeColors['limit'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'limitField' }}
            name="limitField"
            color={NodeColors['limitField'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'map' }}
            name="map"
            color={NodeColors['map'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'noop' }}
            name="noop"
            color={NodeColors['noop'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'phase' }}
            name="phase"
            color={NodeColors['phase'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'pipeline' }}
            name="pipeline"
            color={NodeColors['pipeline'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'program' }}
            name="program"
            color={NodeColors['program'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'random', stage: 'random' }}
            name="random"
            color={NodeColors['random'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'reverse' }}
            name="reverse"
            color={NodeColors['reverse'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'sink' }}
            name="sink"
            color={NodeColors['sink'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'sort' }}
            name="sort"
            color={NodeColors['sort'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'workout' }}
            name="workout"
            color={NodeColors['workout'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'switch' }}
            name="switch"
            color={NodeColors['switch'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:all' }}
            name="all"
            color={NodeColors['logical:all'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:and' }}
            name="and"
            color={NodeColors['logical:and'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:eq' }}
            name="eq"
            color={NodeColors['logical:eq'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:gte' }}
            name="gte"
            color={NodeColors['logical:gte'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:gt' }}
            name="gt"
            color={NodeColors['logical:gt'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:in' }}
            name="in"
            color={NodeColors['logical:in'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:lte' }}
            name="lte"
            color={NodeColors['logical:lte'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:lt' }}
            name="lt"
            color={NodeColors['logical:lt'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:neq' }}
            name="neq"
            color={NodeColors['logical:neq'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:nin' }}
            name="nin"
            color={NodeColors['logical:nin'] ?? NodeColors.default}
          />
          <TrayItemWidget
            model={{ type: 'stage', stage: 'logical:or' }}
            name="or"
            color={NodeColors['logical:or'] ?? NodeColors.default}
          />
        </TrayWidget>
        <StyledLayer
          onDrop={handleDrop}
          onDragOver={(event) => {
            event.preventDefault();
          }}
        >
          <div style={{ height: 1800 }}>
            <CanvasContainer>{engine ? <CanvasWidget engine={engine}></CanvasWidget> : undefined}</CanvasContainer>
          </div>
        </StyledLayer>
      </StyledContent>
    </StyledBody>
  );
});
