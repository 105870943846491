import * as React from 'react';
import { observer } from 'mobx-react';
import { CoachExerciseBlock } from '../../../../../Model/Coach/Program/Workout/CoachExerciseBlock';
import { CoachSetsTableRow } from './CoachSetsTableRow';

export type CoachSetsTableProps = {
  exerciseBlock: CoachExerciseBlock;
};

export const CoachSetsTable: React.FC<CoachSetsTableProps> = observer(({ exerciseBlock }: CoachSetsTableProps) => {
  return (
    <React.Fragment>
      {exerciseBlock.sets.map((set, index) => (
        <CoachSetsTableRow key={`${exerciseBlock.exerciseBlockId}_${index}`} set={set} index={index} />
      ))}
    </React.Fragment>
  );
});
