import { observable } from 'mobx';
import { ValueType } from './Stages/ValueType';

export class PipelineParam {
  @observable
  name: string = '';
  @observable
  type: ValueType = 'string';
  @observable
  defaultValue?: any = undefined;
  @observable
  repeats = false;

  constructor(json?: any) {
    if (json) {
      this.name = json.name ?? '';
      this.type = json.type || 'string';
      this.defaultValue = json.defaultValue;
      this.repeats = !!json.repeats;
    }
  }

  toJS(): any {
    return {
      name: this.name,
      type: this.type,
      defaultValue: this.defaultValue,
      repeats: this.repeats,
    };
  }

  copy() {
    return new PipelineParam(this.toJS());
  }
}
