/**
 * Created by neo on 18.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RecurringPattern } from '../../../../../Model/Coach/Schedule/RecurringPattern';
import { Col, Row } from 'reactstrap';
import { Checkbox, DatePicker, Form, InputNumber, Radio, Select, Space } from 'antd';
import { DayOfWeek, DayOfWeekList } from '../../../../../Model/DayOfWeek';
import { runInAction } from 'mobx';
import { ValueEntry } from '../../../../../Components/ExerciseVariationFilter/ExerciseVariationFilterSingleSelection';
import dayjs, { Dayjs } from 'dayjs';
import { RecurringPatternDaysOfWeek } from './RecurringPatternDaysOfWeek';
import { SingleColRow } from '../../../../../Components/SingleColRow';

export type RecurringPatternEditorProps = {
  recurringPattern: RecurringPattern;
};

export const RecurringPatternEditor: React.FC<RecurringPatternEditorProps> = observer(({ recurringPattern }) => {
  const maxSeparationCount = recurringPattern.type === 'daily' ? 15 : recurringPattern.type === 'weekly' ? 12 : 4;

  const repeatEveryText =
    recurringPattern.type === 'daily' ? 'day' : recurringPattern.type === 'weekly' ? 'week' : 'month';

  const handleChangeType = React.useCallback((type) => runInAction(() => (recurringPattern.type = type)), [
    recurringPattern,
  ]);

  const handleChangeEndDate = React.useCallback(
    (date) => runInAction(() => (recurringPattern.endDate = date?.toDate())),
    [recurringPattern],
  );

  const handleChangeMaxOccurrences = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        const number = value ? Number(value) : undefined;
        runInAction(() => (recurringPattern.maxOccurrences = number && number > 0 ? number : undefined));
      }
    },
    [recurringPattern],
  );

  const handleChangeRepeatEvery = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        const number = value ? Number(value) : 1;
        runInAction(() => (recurringPattern.separationCount = number && number > 0 ? number : 1));
      }
    },
    [recurringPattern],
  );

  const handleActivateEndDate = React.useCallback(
    () =>
      runInAction(() => {
        recurringPattern.endDate = !recurringPattern.endDate ? dayjs().add(6, 'month').toDate() : undefined;
        recurringPattern.maxOccurrences = recurringPattern.endDate ? undefined : 7;
      }),
    [recurringPattern],
  );

  const handleActivateMaxOccurences = React.useCallback(
    () =>
      runInAction(() => {
        recurringPattern.maxOccurrences = recurringPattern.maxOccurrences ? undefined : 7;
        recurringPattern.endDate = recurringPattern.maxOccurrences ? undefined : dayjs().add(6, 'month').toDate();
      }),
    [recurringPattern],
  );

  const handleActivateDayOfMonth = React.useCallback(
    () =>
      runInAction(() => {
        recurringPattern.dayOfMonth = recurringPattern.dayOfMonth ? undefined : dayjs().date();
        recurringPattern.nthDayOfTheMonth = recurringPattern.dayOfMonth ? undefined : 'first';
        recurringPattern.weekDayOfMonth = recurringPattern.dayOfMonth
          ? undefined
          : DayOfWeekList[dayjs().isoWeekday() - 1];
      }),
    [recurringPattern],
  );

  const handleActivateNthOfMonth = React.useCallback(
    () =>
      runInAction(() => {
        recurringPattern.nthDayOfTheMonth = recurringPattern.nthDayOfTheMonth ? undefined : 'first';
        recurringPattern.dayOfMonth = recurringPattern.nthDayOfTheMonth ? undefined : dayjs().date();
        recurringPattern.weekDayOfMonth = recurringPattern.nthDayOfTheMonth ? DayOfWeekList[dayjs().isoWeekday() - 1] : undefined;
      }),
    [recurringPattern],
  );

  const handleChangeNthDayOfMonth = React.useCallback(
    (type) => runInAction(() => (recurringPattern.nthDayOfTheMonth = type)),
    [recurringPattern],
  );

  const handleChangeDayOfMonth = React.useCallback(
    (value: number | string | undefined | null) => {
      if (value) {
        const number = value ? Number(value) : 1;
        runInAction(() => (recurringPattern.dayOfMonth = number && number > 0 ? number : 1));
      }
    },
    [recurringPattern],
  );

  const handleChangeWeekDayOfMonth = React.useCallback(
    (type) => runInAction(() => (recurringPattern.weekDayOfMonth = type)),
    [recurringPattern],
  );

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Item label="Recurrence">
            <Select value={recurringPattern.type} onChange={handleChangeType}>
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Repeat every" extra={repeatEveryText}>
            <InputNumber
              value={recurringPattern.separationCount}
              min={1}
              max={maxSeparationCount}
              onChange={handleChangeRepeatEvery}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="End Date">
            <Radio checked={!!recurringPattern.endDate} onChange={handleActivateEndDate} />
            <DatePicker
              value={recurringPattern.endDate ? dayjs(recurringPattern.endDate) : undefined}
              onChange={handleChangeEndDate}
              disabled={!recurringPattern.endDate}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Max Occurrences">
            <Radio checked={!!recurringPattern.maxOccurrences} onChange={handleActivateMaxOccurences} />
            <InputNumber
              value={recurringPattern.maxOccurrences}
              onChange={handleChangeMaxOccurrences}
              disabled={!recurringPattern.maxOccurrences}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          {recurringPattern.type === 'weekly' && <RecurringPatternDaysOfWeek recurringPattern={recurringPattern} />}
          {recurringPattern.type === 'monthly' && (
            <React.Fragment>
              <Row>
                <Col>
                  <Form.Item label="Day of Month">
                    <Radio checked={!!recurringPattern.dayOfMonth} onChange={handleActivateDayOfMonth} />
                    <InputNumber
                      value={recurringPattern.dayOfMonth}
                      onChange={handleChangeDayOfMonth}
                      disabled={!recurringPattern.dayOfMonth}
                      min={1}
                      max={31}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="nth day of Month">
                    <Space>
                      <Radio checked={!!recurringPattern.nthDayOfTheMonth} onChange={handleActivateNthOfMonth} />
                      <Select
                        value={recurringPattern.nthDayOfTheMonth}
                        onChange={handleChangeNthDayOfMonth}
                        disabled={!recurringPattern.nthDayOfTheMonth}
                      >
                        <Select.Option value="first">First</Select.Option>
                        <Select.Option value="second">Second</Select.Option>
                        <Select.Option value="third">Third</Select.Option>
                        <Select.Option value="fourth">Fourth</Select.Option>
                        <Select.Option value="last">Last</Select.Option>
                      </Select>
                    </Space>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Day of Week">
                    <Select
                      value={recurringPattern.weekDayOfMonth}
                      onChange={handleChangeWeekDayOfMonth}
                      disabled={!recurringPattern.weekDayOfMonth}
                    >
                      <Select.Option value="MONDAY">Monday</Select.Option>
                      <Select.Option value="TUESDAY">Tuesday</Select.Option>
                      <Select.Option value="WEDNESDAY">Wednesday</Select.Option>
                      <Select.Option value="THURSDAY">Thursday</Select.Option>
                      <Select.Option value="FRIDAY">Friday</Select.Option>
                      <Select.Option value="SATURDAY">Saturday</Select.Option>
                      <Select.Option value="SUNDAY">Sunday</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Col>
      </Row>
      <SingleColRow>
      </SingleColRow>
    </React.Fragment>
  );
});
