/**
 * Created by neo on 08.09.22.
 */
import { observable } from 'mobx';

export type EarnModelTemplateRuleSteppedValueStepJson = {
  minValue?: number;
  maxValue: number;
  points: number;
  extraPoints: number;
};

export class EarnModelTemplateRuleSteppedValueStep {
  @observable
  minValue?: number;
  @observable
  maxValue: number = 0;
  @observable
  points: number = 0;
  @observable
  extraPoints: number = 0;

  constructor(json?: Partial<EarnModelTemplateRuleSteppedValueStepJson>) {
    if (json) {
      this.minValue = json.minValue;
      this.maxValue = json.maxValue ?? 0;
      this.points = json.points ?? 0;
      this.extraPoints = json.extraPoints ?? 0;
    }
  }

  toJS(): EarnModelTemplateRuleSteppedValueStepJson {
    return {
      minValue: this.minValue,
      maxValue: this.maxValue,
      points: this.points,
      extraPoints: this.extraPoints,
    };
  }
}
