/**
 * Created by neo on 24.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkoutModule } from '../../../../Model/Coach/WorkoutTemplate/WorkoutModule';
import { Col, Container, Row } from 'reactstrap';
import { Checkbox, Form, Input, InputNumber, Select } from 'antd';
import { runInAction } from 'mobx';
import { PhaseType } from '../../../../Model/ProgramPortfolio/PhaseType';
import { ExerciseVariationFilter } from '../../../../Components/ExerciseVariationFilter/ExerciseVariationFilter';
import { Exercise } from '../../../../Model/Exercise/Exercise';
import styled from '@emotion/styled';

const ExerciseCardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: space-between;
`;

const ExerciseCard = styled.div<{ image?: string }>`
  background-image: url('${({ image }) => image}');
  background-size: cover;
  flex: 0 0 19%;
  height: 120px;
  border-radius: 8px;
  margin-bottom: 8px;
`;

export type WorkoutModuleViewScreenProps = {};

export const WorkoutModuleViewScreen: React.FC<WorkoutModuleViewScreenProps> = observer((props) => {
  const { moduleId } = useParams<{ moduleId: string }>();

  const [module, setModule] = useState(new WorkoutModule());
  const [exercises, setExercises] = useState<Exercise[]>([]);

  const isNew = moduleId?.toLowerCase() === 'new';

  useEffect(() => {
    if (moduleId && moduleId?.toLowerCase() !== 'new') {
      WorkoutModule.findOne(moduleId).then((res) => setModule(res));
    } else {
      setModule(new WorkoutModule());
    }
  }, [moduleId]);

  const searchExercises = React.useCallback(() => {
    setExercises([]);
    Exercise.find(
      Object.assign({}, module.exerciseQuery.toJS(), {
        size: Math.min(50, module.limitExercises ?? 50),
      }),
    ).then((result) => setExercises(result));
  }, [module]);

  const handleChangeName = React.useCallback(({ target: { value } }) => runInAction(() => (module.name = value)), [
    module,
  ]);

  const handleChangeLimitExercises = React.useCallback(
    (value) => runInAction(() => (module.limitExercises = Number(value))),
    [module],
  );

  const handleChangePhaseType = React.useCallback(
    (value) => runInAction(() => (module.phaseType = value as PhaseType)),
    [module],
  );

  const handleChangeRandomize = React.useCallback(
    ({ target: { checked } }) => runInAction(() => (module.randomizeExercises = checked)),
    [module],
  );

  const handleApplyFilter = React.useCallback(() => {
    searchExercises();
  }, [searchExercises]);

  return (
    <Container>
      <Form layout="vertical">
        <Row>
          <Col xs={12}>
            <h1>{isNew ? 'New Module' : module.name}</h1>
          </Col>
          <Col md={3}>
            <Form.Item label="Module Name">
              <Input value={module.name} onChange={handleChangeName} />
            </Form.Item>
          </Col>
          <Col md={3}>
            <Form.Item label="Phase Type">
              <Select value={module.phaseType} onChange={handleChangePhaseType}>
                <Select.Option value="strength">Strength</Select.Option>
                <Select.Option value="cardio">Cardio</Select.Option>
                <Select.Option value="warmup">Warmup</Select.Option>
                <Select.Option value="mobilization">Mobilization</Select.Option>
                <Select.Option value="balance">Balance</Select.Option>
                <Select.Option value="interval">Interval</Select.Option>
                <Select.Option value="stretching">Stretching</Select.Option>
                <Select.Option value="relax">Relax</Select.Option>
                <Select.Option value="cooldown">Cooldown</Select.Option>
                <Select.Option value="fascia">Fascia</Select.Option>
                <Select.Option value="yoga">Yoga</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={3}>
            <Form.Item label="Max Exercises" extra="(optional) default value can be overriden">
              <InputNumber value={module.limitExercises} onChange={handleChangeLimitExercises} />
            </Form.Item>
          </Col>
          <Col md={3}>
            <Form.Item label="Randomize Exercises">
              <Checkbox checked={module.randomizeExercises} onChange={handleChangeRandomize}>
                Randomize
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Exercise Filter">
              <ExerciseVariationFilter filter={module.exerciseQuery} onApply={handleApplyFilter} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <ExerciseCardContainer>
            {exercises.map((exercise) => (
              <ExerciseCard key={exercise.id} image={exercise.firstSmallestMediaUrl}></ExerciseCard>
            ))}
          </ExerciseCardContainer>
        </Row>
      </Form>
    </Container>
  );
});
