/**
 * Created by neo on 16.01.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { RouteChallengeTeamMember } from '../../../../Model/Engagement/RouteChallenge/RouteChallengeTeamMember';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { AthleteRouteChallengeTableRowTransactions } from '../../../Engagement/RouteChallenge/View/RouteChallengeTeamsTab/AthleteRouteChallengeTableRowTransactions';

export type AthleteRouteChallengeTableRowProps = {
  athlete: Athlete;
  member: RouteChallengeTeamMember;
};

export const AthleteRouteChallengeTableRow: React.FC<AthleteRouteChallengeTableRowProps> = observer(
  ({ athlete, member }) => {
    const [open, setOpen] = useState(false);

    const team = member.team;
    const challenge = member.challenge;
    const currentLocationReached = team ? team?.reachedLocations[team.reachedLocations.length - 1] : undefined;
    const currentLocation =
      currentLocationReached && challenge
        ? challenge.locations.find((l) => l.id === currentLocationReached.locationId)
        : undefined;

    const toggleOpen = () => setOpen((p) => !p);

    return (
      <React.Fragment>
        <tr>
          <td>
            <Button type="link" onClick={toggleOpen}>
              {member.teamId}
            </Button>
          </td>
          <td>
            <Link to={`/engagement/route-challenges/${member.challengeId}`}>{member.challenge?.defaultName}</Link>
          </td>
          <td>{member.totalCollectedPoints}</td>
          <td>{team?.totalCollectedPoints}</td>
          <td>{team?.reachedLocations.length}</td>
          <td>{currentLocation?.defaultName}</td>
        </tr>
        {open && (
          <tr>
            <td colSpan={6}>
              <AthleteRouteChallengeTableRowTransactions athlete={athlete} member={member} />
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  },
);
