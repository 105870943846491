import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { LessThanNodeWidget } from './LessThanNodeWidget';
import { LessThanNodeModel } from './LessThanNodeModel';
import { LessThanStage } from '../../../../../../../../Model/Coach/Stages/Logical/LessThanStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class LessThanNodeModelFactory extends AbstractReactFactory<LessThanNodeModel, DiagramEngine> {
  constructor() {
    super('logical:lt');
  }

  generateReactWidget(event): JSX.Element {
    return <LessThanNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new LessThanNodeModel(new LessThanStage(new Pipeline()));
  }
}
