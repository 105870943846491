/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Line,
  BarProps,
} from 'recharts';
import { ChartColors } from '../ChartColors';

export type PercentageCompletedChartProps = Pick<BarProps, 'stackId'> & {
  data: AnalyticsRow[];
};

export const PercentageCompletedChart: React.FC<PercentageCompletedChartProps> = observer(({ data, stackId }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart height={250} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis yAxisId="left" label={{ value: '% Completed', position: 'insideTopLeft', dy: -25 }} domain={[0, 100]} />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickLine={false}
          axisLine={false}
          label={{ value: 'Count', position: 'insideTopRight', dy: -25 }}
        />
        <Tooltip />
        <Legend />

        <Bar yAxisId="right" dataKey="user_count" stackId={stackId} fill={ChartColors[0]} name="# Unique Users" />
        <Bar yAxisId="right" dataKey="count" stackId={stackId} fill={ChartColors[1]} name="# Logged" />
        <Bar
          yAxisId="left"
          dataKey="avg_percentage_completed"
          stackId={stackId}
          fill={ChartColors[2]}
          name="Avgerage % Completed"
          label={({ value }) => <span>{`${Math.round(value)} %`}</span>}
        />
      </BarChart>
    </ResponsiveContainer>
  );
});
