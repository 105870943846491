/**
 * Created by neo on 10.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import styled from '@emotion/styled';
import { DefaultStageNodeModel } from './Default/DefaultStageNodeModel';
import { ButtonHTMLAttributes } from 'react';

export type StyledInputProps = {
  node: DefaultStageNodeModel;
  children?: string;
} & Pick<ButtonHTMLAttributes<any>, 'onClick'>;

const Button = styled.button`
  flex: 1;
  background: transparent;
  border: 0 solid grey;
  color: blue;
`;

export const StyledButton: React.FC<StyledInputProps> = observer(({ node, onClick, children }: StyledInputProps) => {
  const handleFocus = React.useCallback(() => {
    node.setLocked(true);
  }, [node]);

  const handleBlur = React.useCallback(() => {
    node.setLocked(false);
  }, [node]);

  return <Button onClick={onClick}>{children}</Button>;
});
