/**
 * Created by neo on 01.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { GymChallenge } from '../../../../../Model/GymChallenge/GymChallenge';
import { Col, Row, Table } from 'reactstrap';
import { Button } from 'antd';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { GymChallengeTableRow } from './GymChallengeTableRow';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { GymChallengeModal } from './GymChallengeModal';

export type GymChallengesTabProps = {
  gym: Gym;
};

export const GymChallengesTab: React.FC<GymChallengesTabProps> = observer(({ gym }) => {
  const history = useNavigate();

  const [challenges, setChallenges] = useState<GymChallenge[]>([]);

  useEffect(() => {
    GymChallenge.find({ gymId: gym.id }).then((result) => setChallenges(result));
  }, [gym]);

  const handleCreateChallenge = React.useCallback(() => {
    history(`new`);
  }, [history]);

  const handleChallengeSaved = React.useCallback((challenge: GymChallenge) => {
    setChallenges((prev) => {
      if (!prev.some((c) => c.id === challenge.id)) {
        return [challenge].concat(prev);
      }
      return prev;
    });
  }, []);

  const handleDeleteChallenge = React.useCallback((challenge: GymChallenge) => {
    setChallenges((prev) => prev.filter((c) => c.id !== challenge.id));
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col />
        <Col xs="auto">
          <Button type="primary" onClick={handleCreateChallenge}>
            Create new Challenge
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>Timezone</th>
              <th>Points</th>
              <th>Participants</th>
            </tr>
          </thead>
          <tbody>
            {challenges.map((challenge) => (
              <GymChallengeTableRow key={challenge.id} challenge={challenge} />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
      <Routes>
        <Route
          path=":challengeId"
          element={<GymChallengeModal onSaved={handleChallengeSaved} onDeleted={handleDeleteChallenge} />}
        />
      </Routes>
    </React.Fragment>
  );
});
