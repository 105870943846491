import { observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export class AxonTrackingToken {
  @observable
  id: string = '';
  @observable
  processorName: string = '';
  @observable
  segment: number = 0;
  @observable
  owner?: string = undefined;
  @observable
  timestamp: number = 0;
  @observable
  token: string = '';
  @observable
  tokenType: string = '';

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.processorName = json.processorName;
      this.segment = json.segment;
      this.owner = json.owner;
      this.timestamp = json.timestamp;
      this.token = json.token;
      this.tokenType = json.tokenType;
    }
  }

  delete() {
    return HttpBackend.delete(`/infrastructure/trackingtoken/${this.id}`);
  }

  static async find(params?: any): Promise<Array<AxonTrackingToken>> {
    const res = await HttpBackend.get(`/infrastructure/trackingtoken`, params);
    return (res || []).map((s) => new AxonTrackingToken(s));
  }
}
