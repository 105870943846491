/**
 * Created by neo on 08.07.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { DefaultStageNodeWidget, StageNodeWidgetProps, StyledRow } from '../Default/DefaultStageNodeWidget';
import { ConfigAttributeSelect } from '../ConfigAttributeSelect';
import { DistinctStage } from '../../../../../../../Model/Coach/Stages/DistinctStage';
import { StyledInput } from '../StyledInput';

export type DistinctNodeWidgetProps = {} & StageNodeWidgetProps;

export const DistinctNodeWidget: React.FC<DistinctNodeWidgetProps> = observer(
  ({ node, engine, size }: DistinctNodeWidgetProps) => {
    const stage = node.stage as DistinctStage;

    const handleChangeTagCategory = React.useCallback(
      ({ target: { value } }: any) => (stage.config.tagCategory = value),
      [stage],
    );

    return (
      <DefaultStageNodeWidget
        engine={engine}
        node={node}
        size={size}
        disableEdit={true}
        content={
          <React.Fragment>
            <StyledRow>
              <ConfigAttributeSelect node={node} />
            </StyledRow>
            <StyledRow>
              {stage.config.attribute === 'tagCategory' ? (
                <StyledRow>
                  <StyledInput node={node} value={stage.config.tagCategory} onChange={handleChangeTagCategory} />
                </StyledRow>
              ) : null}
            </StyledRow>
          </React.Fragment>
        }
      />
    );
  },
);
