import * as React from 'react';

const SIZER_STYLES = {
  position: 'absolute',
  width: 0,
  height: 0,
  visibility: 'hidden',
  overflow: 'scroll',
  whiteSpace: 'pre',
};

const STYLE_PROPS = ['fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'letterSpacing'];

export type Props = {
  autoresize?: boolean;
  autofocus?: boolean;
  classNames?: any;
  inputAttributes?: any;
  query?: string;
  placeholder?: any;
  expandable?: any;
  listboxId?: any;
  selectedIndex?: any;
  disabled?: boolean;
};

export class Input extends React.Component<Props> {
  input?: any;
  sizer?: any;
  state: {
    inputWidth?: number;
  };

  constructor(props) {
    super(props);
    this.state = { inputWidth: undefined };
  }

  componentDidMount() {
    if (this.props.autoresize) {
      this.copyInputStyles();
      this.updateInputWidth();
    }

    if (this.props.autofocus) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.input.value !== prevProps.query) {
      this.input.value = this.props.query;
    }
    this.updateInputWidth();
  }
  copyInputStyles() {
    const inputStyle = window.getComputedStyle(this.input);

    STYLE_PROPS.forEach((prop) => {
      this.sizer.style[prop] = inputStyle[prop];
    });
  }

  updateInputWidth() {
    let inputWidth;

    if (this.props.autoresize) {
      // scrollWidth is designed to be fast not accurate.
      // +2 is completely arbitrary but does the job.
      inputWidth = Math.ceil(this.sizer.scrollWidth) + 2;
    }

    if (inputWidth !== this.state.inputWidth) {
      this.setState({ inputWidth });
    }
  }

  render() {
    const { inputAttributes, query, placeholder, expandable, listboxId, disabled, selectedIndex } = this.props;

    return (
      <div className={this.props.classNames.searchInput}>
        <input
          {...inputAttributes}
          ref={(c) => {
            this.input = c;
          }}
          value={query}
          placeholder={placeholder}
          role="combobox"
          aria-autocomplete="list"
          aria-label={placeholder}
          aria-owns={listboxId}
          aria-activedescendant={selectedIndex > -1 ? `${listboxId}-${selectedIndex}` : null}
          aria-expanded={expandable}
          disabled={disabled}
          style={{ width: this.state.inputWidth }}
        />
        <div
          ref={(c) => {
            this.sizer = c;
          }}
          style={SIZER_STYLES as any}
        >
          {query || placeholder}
        </div>
      </div>
    );
  }
}
