/**
 * Created by neo on 05.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { runInAction } from 'mobx';
import { TagCondition } from '../../../../Model/Coach/SuperMacro/TagCondition';
import { Button, Form } from 'antd';
import { notUndefined } from '../../../../Utils/notUndefined';

export const allowedTags = [
  'app:coach',
  'app:coach-web',
  'app:studio-web',
  'app:club',
  'duration:short',
  'duration:medium',
  'duration:long',
  'age:junior',
  'age:adult',
  'age:senior',
  'age:elder',
  'gender:male',
  'gender:female',
  'activity:sitting',
  'activity:standing',
  'activity:physical',
  'equipment:yes',
  'equipment:no',
  'equipment:bottle',
  'level:beginner',
  'level:intermediate',
  'level:advanced',
  'goal:muscle_growth',
  'goal:stay_fit',
  'goal:weight_loss',
  'featured:yes',
  'admin:true',
].sort((a, b) => a.localeCompare(b));

const defaultOptions = allowedTags.map((value) => ({ value, label: value }));

export type TagConditionInputProps = {
  tagCondition: TagCondition;
  index: number;
  onRemove?: (index: number) => any;
  onChange?: (tagCondition: TagCondition) => void;
  disabled?: boolean;
};

export const TagConditionInput: React.FC<TagConditionInputProps> = observer(
  ({ tagCondition, index, onRemove, onChange, disabled }) => {
    const includedTags = tagCondition.includedTags.map((value) => ({ label: value, value }));
    const excludedTags = tagCondition.excludedTags.map((value) => ({ label: value, value }));
    const includedTagCategories = tagCondition.includedTags.map((t) => t.split(':')[0]).filter(notUndefined);
    const excludedTagCategories = tagCondition.excludedTags.map((t) => t.split(':')[0]).filter(notUndefined);

    const allowedIncludedTags = defaultOptions
      .filter(({ value }) => !includedTagCategories.some((cat) => value.startsWith(cat)))
      .filter(({ value }) => !tagCondition.excludedTags.includes(value));

    const allowedExcludedTags = defaultOptions
      // .filter(({ value }) => !excludedTagCategories.some((cat) => value.startsWith(cat)))
      .filter(({ value }) => !tagCondition.includedTags.includes(value));

    const handleIncludedChange = React.useCallback(
      (tags?: any[]) => {
        runInAction(() => {
          tagCondition.includedTags.splice(0, tagCondition.includedTags.length);
          (tags ?? []).map((t) => t.value).forEach((t) => tagCondition.includedTags.push(t));
        });
        onChange && onChange(tagCondition);
      },
      [tagCondition, onChange],
    );

    const handleExcludedChange = React.useCallback(
      (tags?: any[]) => {
        runInAction(() => {
          tagCondition.excludedTags.splice(0, tagCondition.excludedTags.length);
          (tags ?? []).map((t) => t.value).forEach((t) => tagCondition.excludedTags.push(t));
        });
        onChange && onChange(tagCondition);
      },
      [tagCondition, onChange],
    );

    const handleRemove = React.useCallback(() => {
      onRemove && onRemove(index);
    }, [index, onRemove]);

    return (
      <Row>
        <Col>
          <Form.Item extra="These tags must be present in the client">
            <CreatableSelect
              isDisabled={disabled}
              isClearable
              isMulti
              options={allowedIncludedTags}
              onChange={handleIncludedChange as any}
              value={includedTags}
              placeholder="Included Tags"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item extra="These tags CANNOT be present in the client">
            <CreatableSelect
              isClearable
              isMulti
              isDisabled={disabled}
              onChange={handleExcludedChange as any}
              options={allowedExcludedTags}
              value={excludedTags}
              placeholder="Excluded Tags"
            />
          </Form.Item>
        </Col>
        {onRemove ? (
          <Col md="auto">
            <Button type="link" danger onClick={handleRemove} disabled={disabled}>
              Remove
            </Button>
          </Col>
        ) : null}
      </Row>
    );
  },
);
