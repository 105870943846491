/**
 * Created by neo on 10.06.21.
 */
import { TagCondition, TagConditionJson } from '../Coach/SuperMacro/TagCondition';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { computed, observable } from 'mobx';
import { languagePriority } from '../LocalizedNamedEntity';

export type NotificationMessageJson = {
  tagCondition: TagConditionJson;
  title: LocalizedValueJson[];
  message: LocalizedValueJson[];
  durationBefore?: string;
};

export class NotificationMessage {
  @observable
  tagCondition = new TagCondition();
  @observable
  title: LocalizedValue[] = [];
  @observable
  message: LocalizedValue[] = [];
  @observable
  durationBefore?: string;

  constructor(json?: Partial<NotificationMessageJson>) {
    if (json) {
      this.tagCondition = new TagCondition(json.tagCondition);
      this.title = (json.title ?? []).map((m) => new LocalizedValue(m));
      this.message = (json.message ?? []).map((m) => new LocalizedValue(m));
      this.durationBefore = json.durationBefore;
    }
  }

  toJS(): NotificationMessageJson {
    return {
      tagCondition: this.tagCondition.toJS(),
      title: this.title.map((t) => t.toJS()),
      message: this.message.map((t) => t.toJS()),
      durationBefore: this.durationBefore,
    };
  }

  getTitle(lang: string) {
    return this.title.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  getMessage(lang: string) {
    return this.message.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  @computed
  get defaultTitle(): string {
    for (const lang of languagePriority) {
      const entry = this.title.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.title[0];
    return first?.value ?? '';
  }

  @computed
  get defaultMessage(): string {
    for (const lang of languagePriority) {
      const entry = this.message.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.message[0];
    return first?.value ?? '';
  }
}
