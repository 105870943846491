import * as React from 'react';
import { observer } from 'mobx-react';
import { Media } from '../Model/Media/Media';

export type ProfilePictureProps = {
  image?: Media;
  size?: number;
};

export const ProfilePicture: React.FC<ProfilePictureProps> = observer(({ image, size = 64 }) => {
  if (image && image.smallest) {
    return <img src={image.smallest} style={Object.assign({}, styles.image, { width: size, height: size }) as any} />;
  }
  return null;
});

const styles = {
  image: {
    borderRadius: '50%',
    objectFit: 'cover',
  },
};
