import * as React from 'react';
import { observer } from 'mobx-react';
import { SnapshotEvent } from '../../../../../Model/Axon/SnapshotEvent';
import ReactJson from 'react-json-view';
import dayjs, { Dayjs } from 'dayjs';

export interface AxonSnapshotTableRowProps {
  snapshot: SnapshotEvent;
}

@observer
export class AxonSnapshotTableRow extends React.Component<AxonSnapshotTableRowProps> {
  render() {
    const {
      props: { snapshot: result },
    } = this;
    return (
      <tr>
        <td>{result.aggregateSequenceNumber}</td>
        <td>{result.aggregateType}</td>
        {/*<td>{result.aggregateType}</td>*/}
        {/*<td>{result.payload.type}</td>*/}
        <td>{result.payload.revision}</td>
        <td>
          {result.payload.data ? <ReactJson src={result.payload.data} displayDataTypes={false} collapsed={3} /> : null}
        </td>
        <td>{dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss')}</td>
        <td>
          <ReactJson src={result.metaData} displayDataTypes={false} collapsed={2} />
        </td>
      </tr>
    );
  }
}
