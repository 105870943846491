/**
 * Created by Azat Fazlyev on 20.09.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { DailyTipsTableRow } from './DailyTipsTableRow';
import { DailyTip } from "../../../Model/DailyTip/DailyTip";

export type DailyTipsTableProps = {
  dailyTips: DailyTip[];
};

export const DailyTipsTable: React.FC<DailyTipsTableProps> = observer(({ dailyTips }) => {
  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {dailyTips.map((entry, index) => (
          <DailyTipsTableRow key={entry.id} index={index} dailyTip={entry}  />
        ))}
      </tbody>
    </Table>
  );
});
