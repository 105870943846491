/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { SingleColRow } from '../SingleColRow';
import { FormGroup, Input, Label } from 'reactstrap';
import { ExerciseVariationFilterMultipleSelection, ValueEntry } from './ExerciseVariationFilterMultipleSelection';
import { Select } from 'antd';

export type Props = {
  filter: ExerciseFilter;
};

const Values = [
  { value: 'standing', label: 'Standing' },
  { value: 'sitting', label: 'Sitting' },
  { value: 'lying', label: 'Lying' },
  { value: 'hanging', label: 'Hanging' },
  { value: 'kneeling', label: 'Kneeling' },
  { value: 'cross_legged', label: 'Cross Legged' },
];

@observer
export class ExerciseVariationFilterPositionPrimary extends React.Component<Props> {
  handleChange = (values: ValueEntry[]) => {
    this.props.filter.primaryPositions = (values ?? []).map((e) => e.value);
  };

  @computed
  get hasValue(): boolean {
    return !!this.props.filter.primaryPositions;
  }

  render() {
    const {
      props: { filter },
    } = this;
    return (
      <ExerciseVariationFilterMultipleSelection
        label="Primary Position"
        container={filter.primaryPositions || []}
        values={Values}
        onChange={this.handleChange}
      />
    );
  }
}

// const ExerciseVariationsTab = withRouter(ExerciseVariationsTabComponent);
// export default ExerciseVariationsTab;
