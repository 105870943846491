/**
 * Created by neo on 01.09.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Modal, Tabs } from 'antd';
import { useState } from 'react';
import { MindfulnessProgramTemplate } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { MindfulnessProgramTemplateDayUnit } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnit';
import { CoachWorkout } from '../../../../../../Model/Coach/Program/Workout/CoachWorkout';
import { useNavigate } from 'react-router';
import { CoachWorkoutView } from '../../../../AthleteSuperMacro/View/CoachWorkoutView/CoachWorkoutView';
import { MindfulnessProgramTemplateDayUnitWorkoutTemplate } from '../../../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplateDayUnitWorkoutTemplate';
import { TabBasicInfo, TabBasicInfoProps } from './TabBasicInfo';
import { UnitEntryModalContentSwitch } from './UnitEntryModalContentSwitch';

export type WorkoutEntryModalProps = Pick<TabBasicInfoProps, 'onEntryChanged'> & {
  template: MindfulnessProgramTemplate;
  entry: MindfulnessProgramTemplateDayUnit;
  onSaved?: (entry: MindfulnessProgramTemplateDayUnit) => void;
  onDeleted?: (entry: MindfulnessProgramTemplateDayUnit) => void;
  onClose?: () => void;
};

export const UnitEntryModal: React.FC<WorkoutEntryModalProps> = observer(
  ({ template, entry, onSaved, onDeleted, onEntryChanged, onClose }) => {
    const history = useNavigate();

    const [generatedWorkout, setGeneratedWorkout] = useState<CoachWorkout | undefined>();
    const [processing, setProcessing] = useState(false);
    const [activeTab, setActiveTab] = useState('basic');

    const handleChangeTab = React.useCallback((tab: string) => setActiveTab(tab), []);

    const handleSave = React.useCallback(() => {
      setProcessing(true);
      onSaved && onSaved(entry);
      onClose && onClose();
    }, [onSaved, entry, onClose]);

    const handleDelete = React.useCallback(() => {
      setProcessing(true);
      onDeleted && onDeleted(entry);
      onClose && onClose();
    }, [entry, onClose, onDeleted]);

    const handleGenerate = React.useCallback(() => {
      if (entry instanceof MindfulnessProgramTemplateDayUnitWorkoutTemplate) {
        setProcessing(true);
        entry
          .generate()
          .then((res) => setGeneratedWorkout(res))
          .finally(() => setProcessing(false));
      }
    }, [entry]);

    const handleCloseGeneratedWorkoutModal = React.useCallback(() => setGeneratedWorkout(undefined), []);

    return (
      <React.Fragment>
        <Modal
          open={true}
          title={entry.defaultName}
          onCancel={onClose}
          footer={[
            <Button key="1" onClick={onClose} disabled={processing}>
              Close
            </Button>,
            entry.type === 'workoutTemplate' ? (
              <Button key="1" onClick={handleGenerate} disabled={processing}>
                Generate
              </Button>
            ) : null,
            <Button key="2" danger onClick={handleDelete} disabled={processing}>
              Delete
            </Button>,
            <Button key="3" type="primary" onClick={handleSave} disabled={processing || entry.type === 'default'}>
              Save
            </Button>,
          ]}
          confirmLoading={processing}
          width={window.innerWidth * 0.75}
          style={{ paddingBottom: 64 }}
        >
          <Form layout="vertical">
            <Tabs activeKey={activeTab} onChange={handleChangeTab}>
              <Tabs.TabPane tab="Basic Info" key="basic">
                <TabBasicInfo entry={entry} onEntryChanged={onEntryChanged} />
              </Tabs.TabPane>
              {entry.type !== 'default' && (
                <Tabs.TabPane tab="Content" key="content" forceRender={true}>
                  <UnitEntryModalContentSwitch entry={entry} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Form>
        </Modal>
        {generatedWorkout && (
          <Modal
            open={true}
            onCancel={handleCloseGeneratedWorkoutModal}
            title={`Generated Workout - ${generatedWorkout.durationMinutes}min`}
          >
            <CoachWorkoutView workout={generatedWorkout} />
          </Modal>
        )}
      </React.Fragment>
    );
  },
);
