import { withHour } from '../../Queries/withHour';

export const allSignupHourDistribution = `
    ${withHour('time')} LEFT JOIN (
             SELECT FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(DISTINCT user_pseudo_id) as sign_up_total
             FROM
                 \`flinq-6796d.analytics_201736462.events_*\`
             WHERE
                 _TABLE_SUFFIX BETWEEN @start_date AND @end_date
               AND event_name = 'sign_up'
             GROUP BY time
             ORDER BY time
         ) s ON root.time = s.time
             LEFT OUTER JOIN
         (
             SELECT FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(DISTINCT user_pseudo_id) as onboarding_completed_total
             FROM
                 \`flinq-6796d.analytics_201736462.events_*\`
             WHERE
                 _TABLE_SUFFIX BETWEEN @start_date AND @end_date
               AND event_name = 'onboarding_completed'
             GROUP BY time
             ORDER BY time
         ) t ON root.time = t.time
`;

export const gymSignupHourDistribution = `
    ${withHour('time')} LEFT JOIN (
             SELECT FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(DISTINCT user_pseudo_id) as sign_up_total
             FROM
                 \`flinq-6796d.analytics_201736462.events_*\`,
                 UNNEST(user_properties) p
             WHERE
                 _TABLE_SUFFIX BETWEEN @start_date AND @end_date
               AND event_name = 'sign_up'
               AND p.key = 'gymId'
               AND p.value.string_value = @gym_id
             GROUP BY time
             ORDER BY time
         ) s ON root.time = s.time
         LEFT JOIN
         (
             SELECT FORMAT_TIME('%H', TIME(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(DISTINCT user_pseudo_id) as onboarding_completed_total
             FROM
                 \`flinq-6796d.analytics_201736462.events_*\`,
                 UNNEST(user_properties) p
             WHERE
                 _TABLE_SUFFIX BETWEEN @start_date AND @end_date
               AND event_name = 'onboarding_completed'
               AND p.key = 'gymId'
               AND p.value.string_value = @gym_id
             GROUP BY time
             ORDER BY time
         ) t ON root.time = t.time
`;
