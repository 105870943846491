/**
 *
 * Created by neo on 18.01.17.
 */

import { observable, ObservableMap, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';

export class LocalizedEntity {
  @observable
  id: string = UUID();
  @observable
  defaultName: string = '';
  @observable
  name: ObservableMap<string, string> = observable.map({});
  @observable
  defaultDescription: string = '';
  @observable
  description: ObservableMap<string, string> = observable.map({});

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.defaultName = json.defaultName || '';
      this.name = observable.map(json.name || {});
      this.defaultDescription = json.defaultDescription || '';
      this.description = observable.map(json.description || {});
    }
  }

  toJS(): any {
    return {
      id: this.id,
      defaultName: this.defaultName,
      name: toJS(this.name),
      defaultDescription: this.defaultDescription,
      description: toJS(this.description),
    };
  }

  toJSArray(): any {
    return {
      id: this.id,
      name: Array.from(this.name.keys()).map((key) => ({ lang: key, value: this.name.get(key) ?? '' })),
      description: Array.from(this.description.keys()).map((key) => ({ lang: key, value: this.name.get(key) ?? '' })),
    };
  }
}
