/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import { Employee } from '../../../../../Model/Gym/Employee';
import { UserRoleInput } from '../../../../../Components/UserRoleInput';

export type Props = {
  employee: Employee;
  isOpen: boolean;
  onClose: () => void;
};

@observer
export class EmployeeEditModal extends React.Component<Props> {
  toggle = () => {
    if (this.props.isOpen) {
      this.props.onClose();
    }
  };

  handleSave = async () => {
    try {
      await this.props.employee.save();
      this.dismiss();
    } catch (err) {}
  };

  dismiss = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const {
      props: {
        isOpen,
        employee,
        employee: {
          athlete: { user },
          athlete,
        },
      },
    } = this;

    return (
      <Modal isOpen={isOpen} toggle={this.toggle} size="lg">
        <ModalHeader toggle={this.toggle}>Edit {athlete.fullName} </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Email</Label>
                <Input type="text" onChange={(e) => (user.email = e.target.value)} value={user.email} disabled />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  type="password"
                  onChange={(e) => (employee.password = e.target.value)}
                  value={employee.password}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Firstname</Label>
                <Input
                  type="text"
                  onChange={(e) => (athlete.firstname = e.target.value)}
                  value={athlete.firstname}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Lastname</Label>
                <Input
                  type="text"
                  onChange={(e) => (athlete.lastname = e.target.value)}
                  value={athlete.lastname}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <UserRoleInput container={employee.roles} allowIncludes />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSave} disabled={!user}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={this.dismiss}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
