import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { ExerciseBlockStage } from '../../../../../../../Model/Coach/Stages/ExerciseBlockStage';

export class ExerciseBlockNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: ExerciseBlockStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'exerciseBlock' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
