import { action, computed, observable } from 'mobx';
import { Media } from '../../Media/Media';
import { PipelineContext } from '../PipelineContext';
import { Retry } from '../../../Utils/Retry';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { v4 as UUID } from 'uuid';
import { LocalizedArrayEntity } from '../../LocalizedArrayEntity';
import { LocalizedValue } from '../../LocalizedValue';
import { WorkoutResponsePhase } from './WorkoutResponsePhase';
import { WorkoutResponseExerciseBlock } from './WorkoutResponseExerciseBlock';
import { WorkoutTemplate } from '../SuperMacro/WorkoutTemplate/WorkoutTemplate';

export class WorkoutResponse extends LocalizedArrayEntity {
  @observable medias: Media[] = [];
  @observable
  scriptId: string = '';
  @observable type: string = 'strength';
  @observable
  tags: string[] = [];
  @observable.shallow phases: WorkoutResponsePhase[] = [];
  @observable image?: Media = undefined;
  @observable createTimestamp: number = Date.now();
  @observable updateTimestamp: number = Date.now();

  constructor(json?: any) {
    super(json);
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): any {
    return Object.assign(super.toJS(), {
      id: newId ? UUID() : this.id,
      tags: this.tags,
      scriptId: this.scriptId,
      type: this.type,
      phases: this.phases.map((p) => p.toJS()),
      image: this.image ? this.image.toJS() : undefined,
      medias: this.medias.map((m) => m.toJS()),
      createTimestamp: this.createTimestamp,
      updateTimestamp: this.updateTimestamp,
    });
  }

  retest(template: WorkoutTemplate, equipmentTypes: string[]) {
    const context = new PipelineContext({ tags: this.tags, equipmentTypes, debug: true });
    return Retry.tryTimes(() =>
      HttpBackend.post(`/coach/program/template/workout/execute`, {
        template,
        context: context.toJS(),
      }).then((result) => {
        this.setData(result);
        return this;
      }),
    );
  }

  @action
  setBasicData(json: any): void {
    this.id = json.id || UUID();
    this.tags = json.tags ?? [];
    this.scriptId = json.scriptId ?? '';
    this.name = (json.name ?? []).map((l) => new LocalizedValue(l));
    this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
    this.type = json.type;
    this.image = json.image ? new Media(json.image) : undefined;
    this.createTimestamp = json.createTimestamp;
    this.updateTimestamp = json.updateTimestamp;
    this.medias = (json.medias ?? []).map((m) => new Media(m));
  }

  @action
  setData(json: any): void {
    this.setBasicData(json);
    this.phases = (json.phases || []).map((p: any) => new WorkoutResponsePhase(this, p));
    this.createTimestamp = this.createTimestamp || Date.now();
    this.updateTimestamp = this.updateTimestamp || Date.now();
  }

  hasTags(tags: string[]) {
    if (tags.length > 0) {
      return tags.every((tag) => this.tags.indexOf(tag) !== -1);
    }
    return true;
  }

  @computed
  get validExercises(): boolean {
    return (
      this.phases
        .reduce(
          (blocks, phase) => blocks.concat(phase.exerciseBlocks.slice()),
          new Array<WorkoutResponseExerciseBlock>(),
        )
        .findIndex((block) => !block.exercise.accept(this.tags)) === -1
    );
  }

  @computed
  get invalidBlocks(): WorkoutResponseExerciseBlock[] {
    return this.phases
      .reduce((blocks, phase) => blocks.concat(phase.exerciseBlocks.slice()), new Array<WorkoutResponseExerciseBlock>())
      .filter((block) => !block.exercise.accept(this.tags));
  }

  @computed
  get emptyPhases(): WorkoutResponsePhase[] {
    return this.phases.filter((p) => p.exerciseBlocks.length === 0);
  }

  @computed
  get hasEmptyPhases(): boolean {
    return this.emptyPhases.length > 0;
  }

  @computed
  get maxDuration(): number {
    return this.phases.reduce((total, phase) => total + phase.maxDuration, 0);
  }

  @computed
  get minDuration(): number {
    return this.phases.reduce((total, phase) => total + phase.minDuration, 0);
  }

  @computed
  get minDurationMinutes(): number {
    return Math.round(this.minDuration / 60000);
  }

  @computed
  get maxDurationMinutes(): number {
    return Math.round(this.maxDuration / 60000);
  }

  @computed
  get durationValid(): boolean {
    const { expectedMinDuration, expectedMaxDuration } = this;
    return this.maxDuration >= expectedMinDuration && this.maxDuration <= expectedMaxDuration;
  }

  @computed
  get expectedMaxDuration(): number {
    const shortMax = 3 * 600_000 + 60_000;
    const mediumMin = shortMax;
    const mediumMax = 2 * mediumMin;
    const longMax = 3 * 2_700_000;
    if (this.tags.includes('duration:short')) {
      return shortMax;
    } else if (this.tags.includes('duration:medium')) {
      return mediumMax;
    }
    return longMax;
  }

  @computed
  get expectedMinDuration(): number {
    const shortMin = 600_000;
    const shortMax = 3 * shortMin + 60_000;
    const mediumMin = shortMax;
    const longMin = 2_700_000;
    if (this.tags.includes('duration:short')) {
      return shortMin;
    } else if (this.tags.includes('duration:medium')) {
      return mediumMin;
    }
    return longMin;
  }

  @computed
  get sortedTags(): string[] {
    const sort = ['level:', 'age:', 'gender:', 'duration:', 'activity:'];
    return this.tags.sort((a, b) => {
      const indexA = sort.findIndex((t) => a.startsWith(t));
      const indexB = sort.findIndex((t) => b.startsWith(t));
      const indexOne = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
      const indexTwo = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;
      return indexOne - indexTwo;
    });
  }
}
