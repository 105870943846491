/**
 *
 * Created by neo on 06.02.17.
 */

import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Athlete } from '../../../../../../Model/Athlete/Athlete';
import { AthleteLink } from '../../../../../../Components/AthleteLink';
import { Colors } from '../../../../../../Config/Theme/Colors';

export type AthleteTableRowProps = {
  athlete: Athlete;
  onSelect?: (athlete: Athlete) => void;
  selected?: boolean;
};

export const AthleteTableRow: React.FC<AthleteTableRowProps> = observer(({ athlete, onSelect, selected }) => {
  const handleSelect = React.useCallback(() => {
    onSelect && onSelect(athlete);
  }, [onSelect, athlete]);

  return (
    <tr
      onClick={handleSelect}
      style={selected ? { backgroundColor: Colors.primaryDarkest, color: 'white' } : undefined}
    >
      <td style={selected ? { color: 'white' } : undefined}>
        {athlete?.fullName || athlete?.id || '<does not exists>'}
      </td>
      <td style={selected ? { color: 'white' } : undefined}>{athlete.user.email}</td>
    </tr>
  );
});
