/**
 * Created by neo on 02.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { PageResult } from '../../../../Model/PageResult';
import { useLocation } from 'react-router-dom';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Pager } from '../../../../Components/Pager';
import { WorkoutLogTable } from '../../../Tracking/WorkoutLog/WorkoutLogTable/WorkoutLogTable';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { ActivityLog } from '../../../../Model/Activity/ActivityLog';
import { ActivityLogTable } from './ActivityLogTable';

export type AthleteActivityLogTabProps = {
  athlete: Athlete;
};

export const AthleteActivityLogTab: React.FC<AthleteActivityLogTabProps> = observer(({ athlete }) => {
  const [result, setResult] = useState(new PageResult<ActivityLog>());

  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') ?? '0';

  const load = React.useRef((athleteId?: string, page: number = 0) => {
    setResult(new PageResult());
    if (athleteId) {
      PageResult.execute(
        ActivityLog.find({ athleteId, page, sort: 'startDate,DESC', size: 30 }),
        ActivityLog.count({ athleteId }),
        page,
        30,
      ).then((res) => setResult(res));
    }
  }).current;

  useEffect(() => {
    load(athlete.id, Number(page));
  }, [athlete, load, page]);

  const handleRemoved = React.useCallback(() => {
    load(athlete.id, Number(page));
  }, [athlete, load, page]);

  return (
    <React.Fragment>
      <SingleColRow>
        <Pager page={result} />
      </SingleColRow>
      <SingleColRow>
        <ActivityLogTable activityLogs={result.content} />
      </SingleColRow>
    </React.Fragment>
  );
});
