/**
 * Created by neo on 26.02.19
 */

import React, { useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { ThemeConfig } from '../../Config/Theme';
import { Media } from '../../Model/Media/Media';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';

export type GridListMediaItemProps = {
  media: Media;
  onRemove?: (media: Media) => any;
  onClick?: (media: Media) => any;
};

export const GridListMediaItem: React.FC<GridListMediaItemProps> = observer((props) => {
  const { media, onRemove } = props;
  const { classes } = useStyles();

  const videoRef = useRef<any>();

  const handleRemove = useCallback(() => {
    onRemove && onRemove(media);
  }, [media, onRemove]);

  const handleVideoMouseEnter = useCallback(() => {
    videoRef.current && videoRef.current.play();
  }, []);

  const handleVideoMouseLeave = useCallback(() => {
    videoRef.current && videoRef.current.pause();
  }, []);

  return (
    <ImageList>
      <ImageListItem>
        {media.isVideo ? (
          <video
            ref={videoRef}
            className={classes.image}
            playsInline={true}
            muted
            loop
            onMouseEnter={handleVideoMouseEnter}
            onMouseLeave={handleVideoMouseLeave}
          >
            <source src={media.url} type={media.mediaType} />
          </video>
        ) : (
          <img className={classes.image} src={media.smallest} alt={media.name} />
        )}
        <ImageListItemBar
          title={media.name}
          classes={{
            root: classes.titleBar,
            title: classes.title,
          }}
          actionIcon={
            onRemove ? (
              <IconButton onClick={handleRemove}>
                <Delete className={classes.title} style={{ color: ThemeConfig.Colors.errorColor }} />
              </IconButton>
            ) : null
          }
        />
      </ImageListItem>
    </ImageList>
  );
});

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    height: '160px',
    width: 'auto',
  },
  title: {
    color: 'white',
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));
