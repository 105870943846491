/**
 * Created by neo on 02.06.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Button, Card, Form, Input, Space } from 'antd';
import { Col, Row } from 'reactstrap';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import { runInAction } from 'mobx';

export type VttCueEntryProps = {
  parsedVtt: any;
  cue: any;
  onChange?: (vtt: any) => void;
};

export const VttCueEntry: React.FC<VttCueEntryProps> = observer(({ parsedVtt, cue, onChange }) => {
  const handleKeyDownStart = React.useCallback(
    (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowRight') {
        runInAction(() => (cue.start += 0.05));
      } else if (event.key === 'ArrowDown' || event.key === 'ArrowLeft') {
        runInAction(() => (cue.start -= 0.05));
      }
    },
    [cue],
  );

  const handleKeyDownEnd = React.useCallback(
    (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowRight') {
        runInAction(() => (cue.end += 0.05));
      } else if (event.key === 'ArrowDown' || event.key === 'ArrowLeft') {
        runInAction(() => (cue.end -= 0.05));
      }
    },
    [cue],
  );

  const handleSetCurrentSeek = React.useCallback(() => {
    // cue.start = store.currentSeek;
  }, [cue]);

  const handleResetEndTime = React.useCallback(() => {
    // cue.start = store.currentSeek;
  }, [cue]);

  const handleRemove = React.useCallback(() => {
    const index = parsedVtt.cues.findIndex((c) => c.identifier === cue.identifier);
    runInAction(() => parsedVtt.cues.splice(index, 1));
  }, [parsedVtt, cue]);

  const handleChangeText = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (cue.text = value));
    },
    [cue],
  );

  const handleChangeStyle = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (cue.styles = value));
    },
    [cue],
  );

  const handleChanged = React.useCallback(() => {
    onChange && onChange(parsedVtt);
  }, [onChange, parsedVtt]);

  return (
    <Card>
      <Row>
        <Col>
          <Form.Item label="Start Time">
            <Input
              value={cue.start}
              onKeyDown={handleKeyDownStart}
              // addonAfter={
              //   <Button type="link" onClick={handleSetCurrentSeek}>
              //     Set
              //   </Button>
              // }
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="End Time">
            <Input
              value={cue.end}
              onKeyDown={handleKeyDownEnd}
              // addonAfter={
              //   <Button type="link" onClick={handleResetEndTime}>
              //     X
              //   </Button>
              // }
            />
          </Form.Item>
        </Col>
      </Row>
      <SingleColRow>
        <Input.TextArea value={cue.text} onChange={handleChangeText} onBlur={handleChanged} />
        <Input
          value={cue.styles}
          onChange={handleChangeStyle}
          placeholder="Style definition here..."
          onBlur={handleChanged}
        />
        {/*<Button type="link" size="small" danger onClick={handleRemove}>*/}
        {/*  Remove*/}
        {/*</Button>*/}
      </SingleColRow>
    </Card>
  );
});
