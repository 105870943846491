/**
 * Created by neo on 08.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { fromMarkdown } from 'mdast-util-from-markdown';
import { MarkdownNodeRenderer, MarkdownNodeRendererProps } from './MarkdownNodeRenderer';

export type MarkdownRendererProps = Omit<MarkdownNodeRendererProps, 'child'> & {
  containerStyle?: React.CSSProperties;
  text?: string | null;
};

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = observer(
  ({ containerStyle, text, textStyle, ...moreProps }) => {
    const mdast = React.useMemo(() => (text ? fromMarkdown(text) : undefined), [text]);

    if (mdast) {
      return (
        <React.Fragment>
          {mdast.children.map((child, index) => (
            <MarkdownNodeRenderer key={index.toString()} child={child} textStyle={textStyle} {...moreProps} />
          ))}
        </React.Fragment>
      );
    }

    return null;
  },
);
