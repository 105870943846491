/**
 * Created by neo on 01.06.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { EventEntry } from '../../../Model/Coach/Schedule/EventEntry';
import { Table } from 'reactstrap';
import { EventEntryTableRow } from './EventEntryTableRow';

export type EventEntryTableProps = {
  entries: EventEntry[];
};

export const EventEntryTable: React.FC<EventEntryTableProps> = observer(({ entries }) => {
  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>Athlete</th>
          <th>Type</th>
          <th>Object</th>
          <th>AI Planned</th>
          <th>Parent ID</th>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody>
        {entries.map((entry) => (
          <EventEntryTableRow key={entry.id} entry={entry} />
        ))}
      </tbody>
    </Table>
  );
});
