/**
 * Created by neo on 10.09.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Form, Input } from 'antd';
import { PipelineParam } from '../../../../../Model/Coach/PipelineParam';

export type PipelineParamNameInputProps = {
  param: PipelineParam;
};

export const PipelineParamNameInput: React.FC<PipelineParamNameInputProps> = observer(
  ({ param }: PipelineParamNameInputProps) => {
    const handleChange = React.useCallback(
      ({ target: { value } }: any) => {
        param.name = value;
      },
      [param],
    );
    return (
      <Form.Item label="Param Name">
        <Input value={param.name} onChange={handleChange} />
      </Form.Item>
    );
  },
);
