/**
 * Created by neo on 18.08.23
 */
import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';

export type OfflineMeetingLocationJson = {
  id: string;
  name: string;
  address: string;
};

export class OfflineMeetingLocation {
  @observable
  id = UUID();
  @observable
  name = '';
  @observable
  address = '';

  constructor(json?: Partial<OfflineMeetingLocationJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.name = json.name ?? '';
      this.address = json.address ?? '';
    }
  }

  toJS(): OfflineMeetingLocationJson {
    return {
      id: this.id,
      name: this.name,
      address: this.address,
    };
  }
}
