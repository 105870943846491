/**
 *
 * Created by neo on 13.06.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import Select from 'react-select';
import { MultiRow } from './MultiRow';
import { MultiRowMap } from './MultiRowMap';
import { OAuthClient } from '../../../../Model/OAuthClient';
import { UserRoleInput } from '../../../../Components/UserRoleInput';
import { OAuthScopeInput } from '../../../../Components/OAuthScopeInput';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Input, Select as AntSelect, Form } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const OAUTH_GRANT_TYPES = [
  { value: 'client_credentials', label: 'Client Credentials' },
  { value: 'authorization_code', label: 'Authorization Code' },
  { value: 'implicit', label: 'Implicit' },
  { value: 'refresh_token', label: 'Refresh Token' },
  { value: 'password', label: 'Password' },
];

export type OAuthClientViewScreenProps = {};

export const OAuthClientViewScreen: React.FC<OAuthClientViewScreenProps> = observer(() => {
  const [client, setClient] = useState(new OAuthClient());

  const history = useNavigate();

  const { clientId } = useParams();

  const isNew = clientId === 'new';
  const grantTypesSelect = client.authorizedGrantTypes.map((g: string) => ({
    value: g,
    label: g,
  }));

  useEffect(() => {
    if (clientId) {
      OAuthClient.get(clientId).then((res) => setClient(res ?? new OAuthClient()));
    }
  }, [clientId]);

  const handleSave = React.useCallback(() => {
    const wasNew = isNew;
    client.save().then((res) =>
      wasNew
        ? Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (
              <React.Fragment>
                <Row>
                  <Col>
                    <Alert color="warning">
                      Store the following key somewhere. It won&lsquo;t be showed again and cannot be retrieved!
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item label="New Client Secret">
                      <Input.TextArea value={res.plainSecret} disabled rows={6} />
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            ),
            onOk() {
              history(`/security/oauth/${res.id}`, { replace: true });
            },
          })
        : history(`/security/oauth/${res.id}`, { replace: true }),
    );
  }, [client, history, isNew]);

  const handleChangeGrantTypes = (e: any, action: any) => {
    client.authorizedGrantTypes = e.map((v) => v.value);
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>{isNew ? 'New OAuth Client' : `Client - ${client.clientId}`}</h1>
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form layout="vertical">
            <Row>
              <Col md={6}>
                <Form.Item label="Name">
                  <Input type="text" value={client.name || ''} onChange={(e) => (client.name = e.target.value)} />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Description">
                  <Input
                    type="text"
                    value={client.description || ''}
                    onChange={(e) => (client.description = e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Client ID">
                  <Input type="text" value={client.clientId} disabled />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Secret Required?">
                  <AntSelect value={`${client.secretRequired}`} onChange={(e) => (client.secretRequired = Boolean(e))}>
                    <AntSelect.Option value="true">Yes</AntSelect.Option>
                    <AntSelect.Option value="false">No</AntSelect.Option>
                  </AntSelect>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Is Scoped?">
                  <AntSelect value={`${client.isScoped}`} onChange={(e) => (client.isScoped = Boolean(e))}>
                    <AntSelect.Option value="true">Yes</AntSelect.Option>
                    <AntSelect.Option value="false">No</AntSelect.Option>
                  </AntSelect>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Is Auto-Approve?">
                  <AntSelect value={`${client.isAutoApprove}`} onChange={(e) => (client.isAutoApprove = Boolean(e))}>
                    <AntSelect.Option value="true">Yes</AntSelect.Option>
                    <AntSelect.Option value="false">No</AntSelect.Option>
                  </AntSelect>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Access Token Validity">
                  <Input
                    type="number"
                    value={client.accessTokenValiditySeconds}
                    onChange={(e) => (client.accessTokenValiditySeconds = Number(e.target.value))}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Refresh Token Validity">
                  <Input
                    type="number"
                    value={client.refreshTokenValiditySeconds}
                    onChange={(e) => (client.refreshTokenValiditySeconds = Number(e.target.value))}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <OAuthScopeInput container={client.scopes} allowIncludes label="Scopes" />
              </Col>
              <Col md={6}>
                <Form.Item label="Resource IDs">
                  <MultiRow container={client.resourceIds} />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Authorized Grant Types">
                  <Select
                    isMulti={true}
                    options={OAUTH_GRANT_TYPES}
                    value={grantTypesSelect}
                    onChange={handleChangeGrantTypes}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label="Redirect URIs">
                  <MultiRow container={client.redirectUris} />
                </Form.Item>
              </Col>
              <Col md={6}>
                <UserRoleInput container={client.authorities} label="Authorities" allowIncludes />
              </Col>
              <Col xs="12">
                <Form.Item label="Additional Information">
                  <MultiRowMap container={client.additionalInformation} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
});
