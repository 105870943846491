/**
 * Created by katarinababic on 9.2.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import MDEditor, { ICommand, TextState, TextAreaTextApi, commands } from '@uiw/react-md-editor';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LinkIcon from '@mui/icons-material/Link';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TitleIcon from '@mui/icons-material/Title';
import ImageIcon from '@mui/icons-material/Image';
import MediaService from "../Services/MediaService";
import {useState} from "react";
import { Modal, Progress, message } from 'antd';
import Dropzone, { Accept } from 'react-dropzone';


export type CustomMarkdownEditorProps = {
  initialValue?: string | null;
  value?: string;
  onChangeValue?: (val?: string) => void;
};

const acceptedFileTypes: Accept = {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg']
};

export const CustomMarkdownEditor: React.FC<CustomMarkdownEditorProps> = observer(
  ({ initialValue, value, onChangeValue }) => {
    console.log('CustomMarkdownEditor', value, initialValue, onChangeValue);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const textApiRef = React.useRef<TextAreaTextApi | null>(null);

    const handleChangeEditorValue = React.useCallback(
      (val?: string) => {
        onChangeValue && onChangeValue(val);
      },
      [onChangeValue],
    );

    const handleImageUpload = React.useCallback((file: File) => {
      setUploading(true);
      setUploadProgress(0);

      MediaService.uploadMedia(file, (progress) => {
        setUploadProgress((progress.loaded / progress.total) * 100);
      })
          .then((media) => {
            if (textApiRef.current) {
              const imageMarkdown = `![${file.name}](${media.url})`;
              textApiRef.current?.replaceSelection(imageMarkdown);
            }
            setIsModalVisible(false);
            message.success('Image uploaded successfully');
          })
          .catch((error) => {
            console.error('Upload failed:', error);
            message.error('Failed to upload image');
          })
          .finally(() => {
            setUploading(false);
            setUploadProgress(0);
          });
    }, []);

    const handleDrop = React.useCallback(
        (acceptedFiles: File[]) => {
          if (acceptedFiles.length > 0) {
            handleImageUpload(acceptedFiles[0]);
          }
        },
        [handleImageUpload]
    );

    const imageCommand: ICommand = {
      name: 'image',
      keyCommand: 'image',
      buttonProps: { 'aria-label': 'Insert image' },
      icon: <ImageIcon />,
      execute: (state: TextState, api: TextAreaTextApi) => {
        textApiRef.current = api;
        setIsModalVisible(true);
      },
    };


    const bold: ICommand = {
      name: 'bold',
      keyCommand: 'bold',
      buttonProps: { style: styles.button },
      icon: <FormatBoldIcon />,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `**${state.selectedText}**`;
        const regexBold = /\*\*(.*)\*\*/gim;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexBold.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.slice(2, state.selectedText.length - 2));
        } else if (regexBold.test(state.text.slice(start - 2, end + 2))) {
          api.setSelectionRange({ start: start - 2, end: end + 2 });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const italic: ICommand = {
      name: 'italic',
      keyCommand: 'italic',
      buttonProps: { style: styles.button },
      icon: <FormatItalicIcon />,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `*${state.selectedText}*`;
        const regexItalic = /\*(.*)\*/gim;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexItalic.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.slice(1, state.selectedText.length - 1));
        } else if (regexItalic.test(state.text.slice(start - 1, end + 1))) {
          api.setSelectionRange({ start: start - 1, end: end + 1 });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const title1: ICommand = {
      name: 'title1',
      keyCommand: 'title1',
      buttonProps: { style: styles.button },
      icon: <b style={styles.title}>H1</b>,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `# ${state.selectedText}\n`;
        const regexHeader1 = /(#{1}\s)(.*)/;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexHeader1.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.slice(2));
        } else if (regexHeader1.test(state.text.slice(start - 2, end))) {
          api.setSelectionRange({ start: start - 2, end });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const title2: ICommand = {
      name: 'title2',
      keyCommand: 'title2',
      buttonProps: { style: styles.button },
      icon: <b style={styles.title}>H2</b>,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `## ${state.selectedText}\n`;
        const regexHeader2 = /(#{2}\s)(.*)/;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexHeader2.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.slice(3));
        } else if (regexHeader2.test(state.text.slice(start - 3, end))) {
          api.setSelectionRange({ start: start - 3, end });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const title3: ICommand = {
      name: 'title3',
      keyCommand: 'title3',
      buttonProps: { style: styles.button },
      icon: <b style={styles.title}>H3</b>,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `### ${state.selectedText}\n`;
        const regexHeader3 = /(#{3}\s)(.*)/;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexHeader3.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.slice(4));
        } else if (regexHeader3.test(state.text.slice(start - 4, end))) {
          api.setSelectionRange({ start: start - 4, end });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const title4: ICommand = {
      name: 'title4',
      keyCommand: 'title4',
      buttonProps: { style: styles.button },
      icon: <b style={styles.title}>H4</b>,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `#### ${state.selectedText}\n`;
        const regexHeader4 = /(#{4}\s)(.*)/;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexHeader4.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.slice(5));
        } else if (regexHeader4.test(state.text.slice(start - 5, end))) {
          api.setSelectionRange({ start: start - 5, end });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const quote: ICommand = {
      name: 'quote',
      keyCommand: 'quote',
      buttonProps: { style: styles.button },
      icon: <FormatQuoteIcon />,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `> ${state.selectedText}\n`;
        const regexQuote = /^> (.*$)/gim;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexQuote.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.slice(2));
        } else if (regexQuote.test(state.text.slice(start - 2, end))) {
          api.setSelectionRange({ start: start - 2, end });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const link: ICommand = {
      name: 'link',
      keyCommand: 'link',
      buttonProps: { style: styles.button },
      icon: <LinkIcon />,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `[${state.selectedText}](url)`;
        const regexLink = /(\[.*\])(\((http)(?:s)?(:\/\/).*\))/;
        if (regexLink.test(state.selectedText)) {
          api.replaceSelection(state.selectedText.split('[')[1].split(']')[0]);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const orderedListCommand: ICommand = {
      name: 'orderedListCommand',
      keyCommand: 'orderedListCommand',
      buttonProps: { style: styles.button },
      icon: <FormatListNumberedIcon />,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `1. ${state.selectedText}`;
        const regexOrderedList = /(^(\d+\.)(\s)(.*)(?:$)?)+/;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexOrderedList.test(state.selectedText)) {
          api.replaceSelection(`${state.selectedText.slice(3).replace(/\d\.\s/g, '')}`);
        } else if (regexOrderedList.test(state.text.slice(start - 3, end))) {
          api.setSelectionRange({ start: start - 3, end });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    const unorderedListCommand: ICommand = {
      name: 'unorderedListCommand',
      keyCommand: 'unorderedListCommand',
      buttonProps: { style: styles.button },
      icon: <FormatListBulletedIcon />,
      execute: (state: TextState, api: TextAreaTextApi) => {
        const modifyText = `- ${state.selectedText}`;
        const regexUnorderedList = /(^(-{1})(\s)(.*)(?:$)?)+/;
        const start = state.selection.start;
        const end = state.selection.end;

        if (regexUnorderedList.test(state.selectedText)) {
          api.replaceSelection(`${state.selectedText.slice(2).replace(/-\s/g, '')}`);
        } else if (regexUnorderedList.test(state.text.slice(start - 2, end))) {
          api.setSelectionRange({ start: start - 2, end });
          api.replaceSelection(state.selectedText);
        } else {
          api.replaceSelection(modifyText);
        }
      },
    };

    return (
      <div data-color-mode="light">
        <MDEditor
          value={value}
          onChange={handleChangeEditorValue}
          commandsFilter={(cmd) => (cmd.name && /(fullscreen)/.test(cmd.name) ? false : cmd)}
          commands={[
            bold,
            italic,
            commands.group([title1, title2, title3, title4], {
              name: 'title',
              groupName: 'title',
              icon: <TitleIcon />,
              buttonProps: { style: { height: 'auto' } },
            }),
            quote,
            link,
            orderedListCommand,
            unorderedListCommand,
            imageCommand
          ]}
          overflow={false}
        />
          <Modal
              title="Upload Image"
              open={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
          >
              <Dropzone onDrop={handleDrop} accept={acceptedFileTypes} multiple={false}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                          {...getRootProps()}
                          style={{
                              border: '2px dashed #cccccc',
                              borderRadius: '4px',
                              padding: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                          }}
                      >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                              <p>Drop the image here ...</p>
                          ) : (
                              <p>Drag 'n' drop an image here, or click to select one</p>
                          )}
                      </div>
                  )}
              </Dropzone>
              {uploading && (
                  <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Progress
                          type="circle"
                          percent={uploadProgress}
                          status={uploadProgress >= 100 ? 'success' : 'active'}
                          format={(percent) => (percent === 100 ? 'Processing...' : `${percent?.toFixed(0)}%`)}
                      />
                  </div>
              )}
          </Modal>

      </div>
    );
  },
);

const styles = {
  button: { height: 'auto' },
  title: {
    fontSize: 20,
    padding: 8,
    margin: 8,
  },
};
