import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { ObservableMap } from 'mobx';
import { TrackingKeys, BaseTrackingKey, TrackingKeyEntry } from '../../../../../Model/ProgramPortfolio/TrackingKeys';
import styled from '@emotion/styled';
import dayjs from '../../../../../Utils/dayjs';

const KEYS_NAME = {
  REPETITIONS: 'Repetitions',
  WEIGHT: 'Weight',
  DURATION: 'Duration',
  BREAK: 'Break',
  DISTANCE: 'Distance',
  SPEED: 'Speed',
  LEVEL: 'Level',
  CALORIES: 'Calories',
  HEART_RATE: 'HEART_RATE',
  WATTS: 'WATTS',
  STEPS_PER_MINUTE: 'STEPS_PER_MINUTE',
};

export type CoachSetsTableRowTrackingKeyProps = {
  values: ObservableMap<string, number>;
  setKey: BaseTrackingKey;
};

const Container = styled.div`
  border: 1px solid lightgray;
  background: #ececec;
  padding: 2px 16px;
  margin: 2px;
`;

const ValueContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ValueBox = styled.div`
  padding: 2px 4px;
  color: red;
  font-size: 16px;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
`;

const Seperator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CoachSetsTableRowTrackingKey: React.FC<CoachSetsTableRowTrackingKeyProps> = observer(
  (props: CoachSetsTableRowTrackingKeyProps) => {
    const { setKey, values } = props;
    const store = useLocalStore(
      (source) => ({
        get definition(): TrackingKeyEntry | undefined {
          return TrackingKeys[source.setKey];
        },
        get timeBased() {
          return source.setKey === 'DURATION' || source.setKey === 'BREAK';
        },
        get hasValue() {
          return (store.minValue ?? store.maxValue ?? store.value) !== undefined;
        },
        get minValue() {
          return source.values.get(`MIN_${source.setKey}`) ?? store.value;
        },
        get maxValue() {
          return source.values.get(`MAX_${source.setKey}`);
        },
        get value() {
          return source.values.get(source.setKey);
        },
        get minValueString() {
          if (store.minValue !== undefined) {
            if (store.timeBased) {
              return dayjs.utc(store.minValue * 1000).format('HH:mm:ss');
            }
            return `${store.minValue}`;
          }
          return '';
        },
        get maxValueString() {
          if (store.maxValue !== undefined) {
            if (store.timeBased) {
              return dayjs.utc(store.maxValue * 1000).format('HH:mm:ss');
            }
            return `${store.maxValue}`;
          }
          return '';
        },
      }),
      props,
    );

    return store.hasValue ? (
      <Container>
        <Title>{`${KEYS_NAME[setKey]} ${store.definition?.defaultUnit ?? ''}`}</Title>
        <ValueContainer>
          <ValueBox>{store.minValueString}</ValueBox>
          {store.maxValue !== undefined ? <Seperator>-</Seperator> : null}
          {store.maxValue !== undefined ? <ValueBox>{store.maxValueString}</ValueBox> : null}
        </ValueContainer>
      </Container>
    ) : null;
  },
);
