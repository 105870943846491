/**
 * Created by katarinababic on 17.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';

export type InnerBoldTextElementProps = {
  component: NativeFeedComponent;
  lang?: string;
};

export const InnerBoldTextElement: React.FC<InnerBoldTextElementProps> = observer(({ component, lang }) => {
  const { classes } = useStyles();

  return (
    <p className={classes.text}>
      {component.getText(lang ?? 'de') ?? 'text before bold '}
      <b>{component.children[0].getText(lang ?? 'de') ?? 'bold '}</b>
      {component.children[1].getText(lang ?? 'de') ?? 'text after bold'}
    </p>
  );
});

const useStyles = makeStyles()(() => ({
  text: {
    color: '#3c3c3c',
    fontSize: 16,
    paddingLeft: 16,
    paddingRight: 16,
    fontFamily: 'Inter',
    textAlign: 'left',
  },
}));
