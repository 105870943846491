import { observable, runInAction } from 'mobx';

export class SnapshotEventPayload {
  @observable
  type: string = '';
  @observable
  data?: any = undefined;
  @observable
  revision: string = '';

  constructor(json?: any) {
    if (json) {
      this.type = json.type;
      this.revision = json.revision;
    }
    this.initData(json?.data);
  }

  async initData(data: any) {
    const result = await this.parse(data);
    runInAction(() => {
      this.data = result;
    });
  }

  parse(data: string | undefined): any {
    // if (data) {
    //   return new Promise((resolve, reject) =>
    //     xml2js.parseString(data, (err, result) => {
    //       if (err) {
    //         reject(err);
    //       } else {
    //         resolve(result);
    //       }
    //     }),
    //   );
    // }
    return undefined;
  }
}
