/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { WorkoutLogTable } from '../../../../Tracking/WorkoutLog/WorkoutLogTable/WorkoutLogTable';
import { Gym } from '../../../../../Model/Gym/Gym';
import { CustomerWorkoutLog } from '../../../../../Model/Statistics/CustomerWorkoutLog';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { PageResult } from '../../../../../Model/PageResult';
import { useEffect, useState } from 'react';
import { Pager } from '../../../../../Components/Pager';
import { CustomerActivityLog } from '../../../../../Model/Statistics/CustomerActivityLog';
import { ActivityLogTable } from '../../../../Tracking/WorkoutLog/ActivityLogTable/ActivityLogTable';

const SIZE = 50;

export type ActivityLogTabProps = {
  gym: Gym;
};

export const ActivityLogTab: React.FC<ActivityLogTabProps> = observer(({ gym }) => {
  const [result, setResult] = useState(new PageResult<CustomerActivityLog>());
  const [page, setPage] = useState(0);

  useEffect(() => {
    setResult(new PageResult());
    PageResult.execute(
      CustomerActivityLog.findAll({
        gymId: gym.id,
        page,
        size: SIZE,
        resolveExercises: false,
        sort: 'startTimestamp,DESC',
      }),
      CustomerActivityLog.count({
        gymId: gym.id,
      }),
      page,
      SIZE,
    ).then((res) => setResult(res));
  }, [gym, page]);

  const handleNextPage = React.useCallback((newPage) => {
    setPage(newPage);
  }, []);

  return (
    <React.Fragment>
      <SingleColRow>
        <Pager page={result} onPage={handleNextPage} />
      </SingleColRow>
      <SingleColRow>
        <ActivityLogTable activityLogs={result.content.slice()} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={result} onPage={handleNextPage} />
      </SingleColRow>
    </React.Fragment>
  );
});
