export const availableLanguages = [
  {
    label: 'Deutsch',
    value: 'de',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Portugese',
    value: 'pt',
  },
  // {
  //   label: 'Dutch',
  //   value: 'nl',
  // },
].sort((a, b) => a.label.localeCompare(b.label));
