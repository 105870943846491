/**
 * Created by neo on 16.07.2024
 */
import { observable, onBecomeObserved } from 'mobx';
import { Gym } from '../../../Model/Gym/Gym';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type RegisteredUserPerGymAggregationEntryJson = {
  gymId: string;
  date: Date;
  usersJoined: number;
  usersLeft: number;
  deltaUsers: number;
  cumulative: number;
  percentageChange: number;
};

export class RegisteredUserPerGymAggregationEntry {
  @observable
  gymId = '';
  date: Date = new Date();
  usersJoined = 0;
  usersLeft = 0;
  deltaUsers = 0;
  cumulative = 0;
  percentageChange = 0;

  @observable
  gym?: Gym;

  constructor(json?: Partial<RegisteredUserPerGymAggregationEntryJson>) {
    if (json) {
      this.gymId = json.gymId ?? '';
      this.date = json.date ? new Date(json.date) : new Date();
      this.usersJoined = json.usersJoined ?? 0;
      this.usersLeft = json.usersLeft ?? 0;
      this.deltaUsers = json.deltaUsers ?? 0;
      this.cumulative = json.cumulative ?? 0;
      this.percentageChange = json.percentageChange ?? 0;

    }

    onBecomeObserved(this, 'gym', () => {
      if (!this.gym) {
        Gym.get(this.gymId).then((result) => (this.gym = result));
      }
    });
  }
}
