/**
 * Created by neo on 03.08.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { PageResult } from '../../../Model/PageResult';
import { EarnModelTemplate } from '../../../Model/Engagement/EarnModelTemplate';
import { Col, Container, Row } from 'reactstrap';
import { Button } from 'antd';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Pager } from '../../../Components/Pager';
import { EarnModelTemplateTable } from './EarnModelTemplateTable';

export type EarnModelTemplateListScreenProps = {};

export const EarnModelTemplateListScreen: React.FC<EarnModelTemplateListScreenProps> = observer((props) => {
  const [result, setResult] = useState<PageResult<EarnModelTemplate>>(new PageResult());
  const [gymId, setGymId] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(EarnModelTemplate.find({ size: 20, gymId }), EarnModelTemplate.count(), page, 20).then((res) =>
      setResult(res),
    );
  }, [page, gymId]);

  const handlePage = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Earn Models</h1>
        </Col>
        <Col xs="auto">
          <Button href="/engagement/earn-model-templates/create" type="primary">
            Create new Template
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <EarnModelTemplateTable templates={result.content} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
