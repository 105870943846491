import { Stage } from './Stage';
import { BodyPartRegion } from '../../BodyPart/BodyPartRegion';
import { Exercise } from '../../Exercise/Exercise';
import { DataSignal } from '../Signal/DataSignal';

type Matrix = [BodyPartRegion, number[]][];

export class BodyPartClusterSortStage extends Stage<any> {
  process() {
    const exercises = this.dataSignals.map((s) => s.data).filter((e) => e instanceof Exercise);
    exercises
      .reduce((matrix, exercise: Exercise, index) => {
        matrix.forEach((entry) => {
          entry[1].push(0);
        });
        exercise.bodyParts.forEach((bodyPart) => {
          let entry = matrix.find((e) => e[0].id === bodyPart.id);
          if (entry) {
            entry[1][index] = 1;
          } else {
            entry = [bodyPart, new Array(index + 1).fill(0)];
            entry[1][index] = 1;
            matrix.push(entry);
          }
        });
        return matrix;
      }, [] as Matrix)
      .sort((a, b) => (a.biggestBodyPart?.sortIndexDesc ?? 2) - (b.biggestBodyPart?.sortIndexDesc ?? 2))
      .map((data, index) => new DataSignal(data, index))
      .forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'bodyPartClusterSort';
  }
}
