/**
 * Created by neo on 19.12.20.
 */
import { v4 as UUID } from 'uuid';
import { observable } from 'mobx';
import { Media, MediaJson } from '../../Media/Media';
import { LocalizedNamedEntity, LocalizedNamedEntityJson } from '../../LocalizedNamedEntity';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { Pageable } from '../../Pageable';

export type EquipmentSearchParams = Pageable & {
  query?: string;
};

export type DietEquipmentJson = LocalizedNamedEntityJson & {
  image?: MediaJson;
};

export class DietEquipment extends LocalizedNamedEntity {
  @observable
  image?: Media;

  constructor(json?: Partial<DietEquipmentJson>) {
    super(json);
    if (json) {
      this.id = json.id ?? UUID();
      this.image = json.image ? new Media(json.image) : undefined;
    }
  }

  toJS(): DietEquipmentJson {
    return Object.assign(super.toJS(), {
      id: this.id,
      image: this.image?.toJS(),
    });
  }

  static search(params?: EquipmentSearchParams): Promise<DietEquipment[]> {
    return HttpBackend.get(`/diet/equipment`, params).then((result) => result.map((r) => new DietEquipment(r)));
  }

  static count(params?: EquipmentSearchParams): Promise<number> {
    return HttpBackend.get(`/diet/equipment/count`, params);
  }

  static getAll(ids: string[]): Promise<DietEquipment[]> {
    return HttpBackend.get(`/diet/equipment/findAll/${ids.join(',')}`).then((result) =>
      (result ?? []).map((res) => new DietEquipment(res)),
    );
  }
}
