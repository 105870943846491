/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import { ReactTags } from './ReactTags';
import { OAuthScope } from '../Model/OAuthScope';
import { useEffect, useState } from 'react';

export type OAuthScopeInputProps = {
  container: string[];
  label?: string;
  allowIncludes?: boolean;
  onRemove?: (index: number) => void;
};

export type TagEntry = {
  id: string;
  scope: OAuthScope;
  name: string;
};

export const OAuthScopeInput: React.FC<OAuthScopeInputProps> = observer(
  ({ label = 'Scopes', container, allowIncludes, onRemove }) => {
    const [scopes, setScopes] = useState<TagEntry[]>([]);
    const debounce = React.useRef<ReturnType<typeof setTimeout>>();

    const tags = container.map((e) => ({ id: e, name: e }));
    const suggestions = scopes.filter((e) => tags.findIndex((t) => t.id === e.scope.id) === -1);

    useEffect(() => {
      return () => debounce.current && clearTimeout(debounce.current);
    }, []);

    const loadEquipmentTypes = (query: string) =>
      OAuthScope.find({ query, sort: 'name,ASC', size: 6 }).then((result) =>
        setScopes(
          result.map((e) => ({
            id: e.fullName,
            scope: e,
            name: e.fullName,
          })),
        ),
      );

    const handleInputChange = (input: string) => {
      // this.equipmentTypes = [];
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        loadEquipmentTypes(input);
      }, 300);
    };

    const handleDelete = (index: number) => runInAction(() => container.splice(index, 1));

    const handleAddition = async (tag: TagEntry) => {
      if (allowIncludes) {
        const roles = (await Promise.all(tag.scope.includes.map((r) => resolveScope(r))))
          .flatMap((r) => r)
          .filter((r: string, pos: number, self) => self.indexOf(r) === pos);
        roles.filter((r) => !container.find((r1) => r1 === r)).forEach((e: string) => container.push(e));
      }
      runInAction(() => container.push(tag.scope.id));
    };

    const resolveScope = async (role: string): Promise<Array<string>> => {
      const oauthScope = await OAuthScope.get(role);
      if (oauthScope) {
        const roles = [oauthScope.id];
        const includes =
          oauthScope.includes.length > 0 ? await Promise.all(oauthScope.includes.map((r) => resolveScope(r))) : [];
        return roles.concat(includes.flatMap((r) => r));
      }
      return [];
    };

    return (
      <FormGroup>
        <Label>{label}</Label>
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          onRemove={handleDelete}
          onAddition={handleAddition}
          onInputChange={handleInputChange}
          allowNew={false}
          minQueryLength={0}
        />
      </FormGroup>
    );
  },
);
