/**
 * Created by neo on 04.11.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { DatePicker, Form, InputNumber } from 'antd';
import { Col, Row } from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';
import { reaction, runInAction } from 'mobx';
import { GymChallenge } from '../../../Model/GymChallenge/GymChallenge';
import { TranslationInputArray } from '../../../Components/Translation/TranslationInputArray';
import { GymChallengeNotificationMessageEditor } from '../../Infrastructure/Gym/GymViewScreen/GymChallengesTab/GymChallengeNotificationMessageEditor';
import { ViewMedia } from '../../../Components/ViewMedia/ViewMedia';
import { Gym } from '../../../Model/Gym/Gym';
import AsyncSelect from 'react-select/async';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GymChallengeLinks } from './GymChallengeLinks';

export type GymChallengeModalBasicInfoTabProps = {
  challenge: GymChallenge;
};

export const GymChallengeModalBasicInfoTab: React.FC<GymChallengeModalBasicInfoTabProps> = observer(({ challenge }) => {
  const { challengeId, gymId } = useParams<{ challengeId: string; gymId: string }>();

  const [gym, setGym] = useState<Gym | undefined>();

  const isNew = challengeId === 'new';

  useEffect(
    () =>
      reaction(
        () => challenge.gymId,
        (gymId) => {
          setGym(undefined);
          if (gymId) {
            Gym.get(gymId).then((res) => setGym(res));
          }
        },
        { fireImmediately: true },
      ),
    [challenge],
  );

  const handleChangePublishDate = React.useCallback(
    (date) =>
      runInAction(() => (challenge.startDateTime = date?.startOf('day').toDate() ?? dayjs().startOf('day').toDate())),
    [challenge],
  );

  const handleChangeUnpublishDate = React.useCallback(
    (date) => runInAction(() => (challenge.endDateTime = date?.endOf('day').toDate() ?? dayjs().endOf('day').toDate())),
    [challenge],
  );

  const handleChangePointsGoal = React.useCallback(
    (value: number | string | undefined | null) => {
      runInAction(() => (challenge.pointsGoal = value !== undefined && value !== null ? Number(value) : 0));
    },
    [challenge],
  );

  const fetchGym = React.useCallback((query: string) => Gym.find({ query }), []);

  const optionLabel = React.useCallback((obj) => obj.name, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeObject = React.useCallback(
    (value?: Gym) => {
      if (value) {
        runInAction(() => (challenge.gymId = value?.id));
      }
    },
    [challenge],
  );

  return (
    <Form layout="vertical">
      {!isNew && (
        <Row>
          <Col>
            <GymChallengeLinks challenge={challenge} />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={4}>
          <Form.Item label="Start Date">
            <DatePicker
              value={challenge.startDateTime ? dayjs(challenge.startDateTime) : undefined}
              onChange={handleChangePublishDate}
              allowClear={true}
            />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label="End Date">
            <DatePicker
              value={challenge.endDateTime ? dayjs(challenge.endDateTime) : undefined}
              onChange={handleChangeUnpublishDate}
              allowClear={true}
            />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label="Total Points Goal" extra="How many points until we reached the big goal">
            <InputNumber value={challenge.pointsGoal} onChange={handleChangePointsGoal} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Gym / Company">
            <AsyncSelect
              cacheOptions
              value={gym}
              loadOptions={fetchGym as any}
              getOptionLabel={optionLabel}
              getOptionValue={optionValue as any}
              onChange={handleChangeObject as any}
              isDisabled={!isNew}
            />
          </Form.Item>
        </Col>
        <Col md={6}>
          <TranslationInputArray entity={challenge} field="name" label="Name" />
        </Col>
        <Col md={6}>
          <TranslationInputArray entity={challenge} type="textarea" field="description" label="Description" rows={8} />
        </Col>
        <Col md={6}>
          <Form.Item
            label="Notification Message (when goal achieved)"
            extra="It's optional. The backend sends default message if not set"
          >
            <GymChallengeNotificationMessageEditor
              entry={challenge}
              message={challenge.goalAchievedNotificationMessage}
            />
          </Form.Item>
        </Col>
        <Col md={6}>
          <h6>Images</h6>
          <ViewMedia medias={challenge.images} />
        </Col>
      </Row>
    </Form>
  );
});
