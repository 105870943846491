/**
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { ExerciseFilterBodyPartCard } from './ExerciseFilterBodyPartCard';
import { observer } from 'mobx-react';

export type ExerciseVariationFilterSynergistsProps = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterSynergists: React.FC<ExerciseVariationFilterSynergistsProps> = observer(
  ({ filter }: ExerciseVariationFilterSynergistsProps) => {
    return <ExerciseFilterBodyPartCard header="Synergists" bodyPartIds={filter.synergistIds ?? []} />;
  },
);
