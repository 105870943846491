export const dailySummary = (gym: boolean = false) =>
  events.reduce(
    (query, event, index) =>
      query + `LEFT JOIN (${dailyEventSummary(event, gym)}) q${index}\n ON root.event_date = q${index}.event_date\n`,
    `SELECT *, 
       possible_total_users - total_unique_users as delta_users,
      FROM (${dailyActivitiesTotalSummary(gym)}) root\n`,
  ) +
  `LEFT JOIN (${dailyWorkoutSummary('Workout_finished', gym, 'gym_strength')}) gs ON root.event_date = gs.event_date\n
  LEFT JOIN (${dailyWorkoutSummary('Workout_finished', gym, 'workplace')}) gw ON root.event_date = gw.event_date\n
  LEFT JOIN (${dailyWorkoutSummary('Workout_finished', gym, 'stretching')}) gst ON root.event_date = gst.event_date\n
  LEFT JOIN (${dailyRecipeSummary(gym)}) recipe ON root.event_date = recipe.event_date\n
  LEFT JOIN (${dailyPossibleUsers(gym)}) possible ON root.event_date = possible.event_date\n
  ORDER BY root.event_date`;

export const dailyActivitiesTotalSummary = (gym: boolean = false) => `
  SELECT
    event_date,
    COUNT(DISTINCT user_id) as total_unique_users,
    COUNT (*) as total_activities_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    ${gym ? 'UNNEST(user_properties) p,' : ''}
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND 
        (
            (event_name = 'yogaSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'breathingSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'meditationSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'Workout_finished' AND e.key = 'athleteId')
            OR (event_name = 'activity_logged' AND e.key = 'athleteId')
            OR (event_name = 'FeedEntry_read' AND e.key = 'athleteId')
            OR (e.key = 'content_type' AND e.value.string_value = 'recipe')
        )
  GROUP BY event_date
`;

export const dailyWorkoutSummary = (eventName: string, gym: boolean = false, workoutType: string) => `
  SELECT
    event_date,
    COUNT(DISTINCT user_id) as ${eventName}_${workoutType}_unique_users,
    COUNT (*) as ${eventName}_${workoutType}_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    UNNEST(event_params) e
    ${gym ? ',UNNEST(user_properties) p' : ''}
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
      ${`AND e.key = 'workoutType' AND e.value.string_value = '${workoutType}'`}
    AND event_name = '${eventName}'
  GROUP BY event_date 
`;

export const dailyEventSummary = (eventName: string, gym: boolean = false) => `
  SELECT
    event_date,
    COUNT(DISTINCT user_id) as ${eventName}_unique_users,
    COUNT (*) as ${eventName}_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`
    ${gym ? ',UNNEST(user_properties) p' : ''}
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND event_name = '${eventName}'
  GROUP BY event_date 
`;

export const dailyRecipeSummary = (gym: boolean = false) => `
  SELECT
    event_date,
    COUNT(DISTINCT user_id) as recipe_unique_users,
    COUNT (*) as recipe_count
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    ${gym ? `UNNEST(user_properties) p,` : ''}
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND e.key = 'content_type'
    AND e.value.string_value = 'recipe'
  GROUP BY event_date 
`;

export const dailyPossibleUsers = (gym: boolean = false) => `
SELECT
  event_date,
  ANY_VALUE(total) AS possible_total_users
FROM (
  SELECT
    *,
    COUNT(user_id) OVER (ORDER BY event_date) AS total
  FROM (
    SELECT
      user_id,
      MIN(event_date) as event_date,
    FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      ${gym ? 'UNNEST(user_properties) p,' : ''}
      UNNEST(event_params) e
    WHERE
      _TABLE_SUFFIX BETWEEN ${gym ? '@gym_start_date' : `'20210327'`} AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND e.key = 'athleteId'
    GROUP BY
      user_id )
  ORDER BY
    event_date )
GROUP BY
  event_date
`;

const events = [
  'yogaSession_finished',
  'breathingSession_finished',
  'meditationSession_finished',
  'Workout_finished',
  'activity_logged',
  'FeedEntry_read',
];
