/**
 * @flow
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { AthleteEquipmentTableRow } from './AthleteEquipmentTableRow';
import { AthleteEquipment } from '../../../../Model/AthleteEquipment/AthleteEquipment';

export type Props = {
  athleteEquipments: AthleteEquipment[];
  onRemove?: (equipment: AthleteEquipment) => any;
};

@observer
export class AthleteEquipmentTable extends React.Component<Props> {
  @observable
  selected?: AthleteEquipment = undefined;

  handleSelect = (item: AthleteEquipment) => {
    if (this.selected === item) {
      this.selected = undefined;
    } else {
      this.selected = item;
    }
  };

  render() {
    const {
      props: { athleteEquipments, onRemove },
    } = this;
    return (
      <Table striped size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Equipment</th>
            <th></th>
          </tr>
        </thead>
        {athleteEquipments.map((d: AthleteEquipment) => (
          <AthleteEquipmentTableRow
            key={d.id}
            athleteEquipment={d}
            onSelect={this.handleSelect}
            selected={this.selected === d}
            onRemove={onRemove}
          />
        ))}
      </Table>
    );
  }
}
