/**
 * Created by neo on 26.04.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Ingredient } from '../../../../Model/Diet/Ingredient/Ingredient';
import { Col, Row } from 'reactstrap';
import { Input, InputNumber } from 'antd';
import { runInAction } from 'mobx';

export type PossibleUnitEntryProps = {
  unit: string;
  ingredient: Ingredient;
};

export const PossibleUnitEntry: React.FC<PossibleUnitEntryProps> = observer(({ unit, ingredient }) => {
  const amount = ingredient.possibleUnits[unit];

  const handleChangeNumber = React.useCallback(
    (value: number | string | undefined | null) => {
      runInAction(() => (ingredient.possibleUnits[unit] = value !== undefined && value !== null ? Number(value) : 0));
    },
    [unit, ingredient],
  );

  return (
    <Row>
      <Col>
        <Input value={unit} disabled={true} />
      </Col>
      <Col>
        <InputNumber value={amount} onChange={handleChangeNumber} />
      </Col>
    </Row>
  );
});
