import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import {
  action,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { v4 as UUID } from 'uuid';
import { Media, MediaJson } from '../../Media/Media';
import { LocalizedValue } from '../../LocalizedValue';
import { SuperMacroMesoCycleTemplate, SuperMacroMesoCycleTemplateJson } from './SuperMacroMesoCycleTemplate';
import { TagCondition, TagConditionJson } from './TagCondition';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type MacroCycleLaneTemplateJson = LocalizedArrayEntityJson & {
  templateName?: string;
  image?: MediaJson;
  tagConditions: TagConditionJson[];
  tags: string[];
  initCycles: SuperMacroMesoCycleTemplateJson[];
  cycles: SuperMacroMesoCycleTemplateJson[];
};

export class MacroCycleLaneTemplate extends LocalizedArrayEntity {
  @observable
  templateName?: string = undefined;
  @observable
  image?: Media = undefined;
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  tags: string[] = [];
  @observable
  initCycles: SuperMacroMesoCycleTemplate[] = [];
  @observable
  cycles: SuperMacroMesoCycleTemplate[] = [];

  constructor(json?: Partial<MacroCycleLaneTemplateJson>) {
    super(json);
    if (json) {
      this.id = json.id || UUID();
      this.templateName = json.templateName;
      this.image = json.image ? new Media(json.image) : undefined;
      this.name = (json.name || []).map((v) => new LocalizedValue(v));
      this.tags = json.tags || [];
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.description = (json.description || []).map((v) => new LocalizedValue(v));
      this.initCycles = (json.initCycles ?? []).map((c) => new SuperMacroMesoCycleTemplate(c));
      this.cycles = (json.cycles ?? []).map((c) => new SuperMacroMesoCycleTemplate(c));
    }
  }

  toJS(replaceId: boolean = false): any {
    return Object.assign(super.toJS(), {
      id: replaceId ? UUID() : this.id,
      templateName: this.templateName,
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      initCycles: this.initCycles.map((c) => c.id),
      cycles: this.cycles.map((c) => c.id),
      tags: toJS(this.tags),
    });
  }

  @action
  addInitCycle(cycle: SuperMacroMesoCycleTemplate) {
    // this.initMesoCycles = this.initMesoCycles ?? [];
    this.initCycles.push(cycle);
    // this.initCycles.push(cycle.id);
  }

  @action
  addCycle(cycle: SuperMacroMesoCycleTemplate) {
    // this.mesoCycles = this.mesoCycles ?? [];
    // this.mesoCycles.push(cycle);
    this.cycles.push(cycle);
  }

  @action
  removeInitCycle(cycle: SuperMacroMesoCycleTemplate) {
    const index = this.initCycles.findIndex((c) => c.id === cycle.id);
    if (index !== -1) {
      this.initCycles.splice(index, 1);
    }
  }

  @action
  removeCycle(cycle: SuperMacroMesoCycleTemplate) {
    const index = this.cycles.findIndex((c) => c.id === cycle.id);
    if (index !== -1) {
      this.cycles.splice(index, 1);
    }
  }

  static async findOne(superMacroTemplateId: string, macroCycleLaneId: string): Promise<MacroCycleLaneTemplate> {
    return HttpBackend.get(
      `/coach/super-macro/template/${superMacroTemplateId}/lane?macroCycleLaneId=${macroCycleLaneId}`,
    ).then((result) => new MacroCycleLaneTemplate(result));
  }
}
