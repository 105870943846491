/**
 * @flow
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { observer, useLocalStore } from 'mobx-react';
import { AthleteEquipmentTable } from './AthleteEquipmentTable';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Athlete } from '../../../../Model/Athlete/Athlete';
import { AthleteEquipment } from '../../../../Model/AthleteEquipment/AthleteEquipment';
import { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { runInAction } from 'mobx';

export type AthleteEquipmentTabProps = {
  athlete: Athlete;
};

export const AthleteEquipmentTab: React.FC<AthleteEquipmentTabProps> = observer(
  ({ athlete }: AthleteEquipmentTabProps) => {
    const store = useLocalStore(() => ({
      result: new Array<AthleteEquipment>(),
      load(athleteId?: string) {
        AthleteEquipment.list({
          athleteId,
        }).then((result) => runInAction(() => (store.result = result)));
      },
      create(athleteId: string) {
        store.result.unshift(new AthleteEquipment({ athleteId }));
      },
    }));

    useEffect(() => {
      store.load(athlete.id);
    }, [store, athlete]);

    const handleCreate = () => {
      store.create(athlete.id);
    };

    const handleRemove = (equipment: AthleteEquipment) => {
      Modal.confirm({
        title: `Delete ${equipment.name}?`,
        onOk() {
          equipment.delete();
          const index = store.result.findIndex((e) => e.id === equipment.id);
          if (-1 !== index) {
            store.result.splice(index, 1);
          }
        },
      });
    };

    return (
      <React.Fragment>
        <Row>
          <Col />
          <Col xs="auto">
            <Button type="primary" onClick={handleCreate}>
              Create New
            </Button>
          </Col>
        </Row>
        <SingleColRow>
          <AthleteEquipmentTable athleteEquipments={store.result} onRemove={handleRemove} />
        </SingleColRow>
      </React.Fragment>
    );
  },
);
