/**
 * Created by neo on 08.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MarkdownParagraphRenderer, MarkdownParagraphRendererProps } from './MarkdownParagraphRenderer';
import { MarkdownTextRenderer } from './MarkdownTextRenderer';
import { MarkdownHeadingRenderer } from './MarkdownHeadingRenderer';
import { MarkdownEmphasisRenderer } from './MarkdownEmphasisRenderer';
import { MarkdownStrongRenderer } from './MarkdownStrongRenderer';
import { MarkdownLinkRenderer } from './MarkdownLinkRenderer';
import { MarkdownBlockquoteRenderer } from './MarkdownBlockquoteRenderer';
import { MarkdownBreakRenderer } from './MarkdownBreakRenderer';
import { MarkdownImageRenderer } from './MarkdownImageRenderer';
import { MarkdownInlineCodeRenderer } from './MarkdownInlineCodeRenderer';
import { MarkdownThematicBreakRenderer } from './MarkdownThematicBreakRenderer';
import { MarkdownListRenderer } from './MarkdownListRenderer';
import { MarkdownListItemRenderer, MarkdownListItemRendererProps } from './MarkdownListItemRenderer';

export type MarkdownNodeRendererProps = {
  child: any;
  textStyle?: React.CSSProperties;
};

export const MarkdownNodeRenderer: React.FC<MarkdownNodeRendererProps> = observer(({ child, textStyle }) => {
  switch (child.type) {
    case 'blockquote':
      return <MarkdownBlockquoteRenderer content={child} textStyle={textStyle} />;
    case 'break':
      return <MarkdownBreakRenderer content={child} />;
    case 'emphasis':
      return <MarkdownEmphasisRenderer content={child} textStyle={textStyle} />;
    case 'heading':
      /* not using the heading anymore as it's anyway not rendered as such in the app
       * using a strong renderer instead
       * return <MarkdownHeadingRenderer content={child} />;
       * */
      return <MarkdownStrongRenderer content={child} textStyle={textStyle} />;
    case 'image':
      return <MarkdownImageRenderer content={child} />;
    case 'inlineCode':
      return <MarkdownInlineCodeRenderer content={child} />;
    case 'link':
      return <MarkdownLinkRenderer content={child} textStyle={textStyle} />;
    case 'list':
      return <MarkdownListRenderer content={child} ordered={child.ordered ?? false} textStyle={textStyle} />;
    case 'listItem':
      return <MarkdownListItemRenderer content={child} textStyle={textStyle} />;
    case 'paragraph':
      return <MarkdownParagraphRenderer content={child} textStyle={textStyle} />;
    case 'strong':
      return <MarkdownStrongRenderer content={child} textStyle={textStyle} />;
    case 'text':
      return <MarkdownTextRenderer content={child} />;
    case 'thematicBreak':
      return <MarkdownThematicBreakRenderer content={child} />;
    default:
      return null;
  }
});
