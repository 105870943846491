/**
 * Created by neo on 12.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { DomainEvent } from '../../../../../../Model/Axon/DomainEvent';

export type AxonQueryTableRowColumnProps = {
  event: DomainEvent;
  property: string;
  domainProperty?: string;
  comparator?: '=' | '>' | '>=' | '<' | '<=';
  quoteValue?: boolean;
  onFilter?: (filter: string) => any;
};

export const AxonQueryTableRowColumn: React.FC<AxonQueryTableRowColumnProps> = observer(
  ({ event, domainProperty, property, onFilter, quoteValue, comparator }: AxonQueryTableRowColumnProps) => {
    const changeQuery = React.useCallback(
      (e) => {
        e.preventDefault();
        const prop = domainProperty || property;
        const value = quoteValue ? `"${event[prop]}"` : event[prop];
        onFilter && onFilter(`${prop} ${comparator || '='} ${value}`);
      },
      [event, domainProperty, property, comparator, onFilter, quoteValue],
    );

    return (
      <a href="" onClick={changeQuery}>
        {event[property]}
      </a>
    );
  },
);
