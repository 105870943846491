/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { observer } from 'mobx-react';
import { EquipmentType } from '../../../../../Model/Equipment/EquipmentType';
import { ViewMedia } from '../../../../../Components/ViewMedia/ViewMedia';
import { TranslationInput } from '../../../../../Components/Translation/TranslationInput';
import { EquipmentTypeInput } from '../../../../../Components/EquipmentTypeInput';

export type Props = {
  equipmentType: EquipmentType;
};

@observer
export class EquipmentTypeViewBasicInfoTab extends React.Component<Props> {
  handleChangeType = ({ target: { value } }: any) => {
    this.props.equipmentType.newId = value.toUpperCase();
  };

  render() {
    const {
      props: { equipmentType },
    } = this;
    return (
      <Row>
        {equipmentType.isNew ? (
          <Col sm={6}>
            <FormGroup>
              <Label>ID / Type</Label>
              <Input type="text" onChange={this.handleChangeType} value={equipmentType.newId} />
            </FormGroup>
          </Col>
        ) : (
          <Col sm={12}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>ID / Type</Label>
                  <Input type="text" value={equipmentType.id} disabled />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>New ID / Type</Label>
                  <Input type="text" onChange={this.handleChangeType} value={equipmentType.newId} />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        )}
        <Col sm={6}>
          <TranslationInput entity={equipmentType} field="name" label="Name" />
        </Col>
        <Col sm={6}>
          <EquipmentTypeInput container={equipmentType.includes} label="Includes EquipmentTypes" />
        </Col>
        <Col sm={12}>
          <ViewMedia medias={equipmentType.medias} containerName={'equipmentType'} />
        </Col>
      </Row>
    );
  }
}
