/**
 * Created by neo on 19.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Form, Input } from 'antd';
import { User } from '../../../../Model/User';
import { UserRoleInput } from '../../../../Components/UserRoleInput';
import { useParams } from 'react-router';

export type UserViewScreenProps = {};

export const UserViewScreen: React.FC<UserViewScreenProps> = observer((props) => {
  const [user, setUser] = useState(new User());
  const [saving, setSaving] = useState(false);

  const { userId } = useParams<{ userId: string }>();

  useEffect(() => {
    if (userId) {
      User.get(userId).then((res) => setUser(res ?? new User()));
    }
  }, [userId]);

  const handleSave = React.useCallback(() => {
    setSaving(true);
    user.save().finally(() => setSaving(false));
  }, [user]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="float-right">
            <Button type="primary" onClick={handleSave} disabled={saving}>
              Save
            </Button>
          </div>
          <h1>{user.email}</h1>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Item label="Email">
            <Input type="text" value={user.email} onChange={({ target: { value } }) => (user.email = value)} />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="New Password">
            <Input type="text" value={user.password} onChange={({ target: { value } }) => (user.password = value)} />
          </Form.Item>
        </Col>
        <Col md={6}>
          <UserRoleInput allowIncludes container={user.roles} />
        </Col>
      </Row>
    </Container>
  );
});
