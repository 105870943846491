export const ChartColors = [
  '#5DADE2',
  '#48C9B0',
  '#EC7063',
  '#F4D03F',
  '#AF7AC5',
  '#CACFD2',
  '#1F618D',
  '#117A65',
  '#B03A2E',
  '#B7950B',
  '#6C3483',
  '#616A6B',
];
