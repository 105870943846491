/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry } from './ActivityWorkoutEntry';
import { BreathingTechniqueStep, BreathingTechniqueStepJson } from './BreathingTechniqueStep';
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';

export type BreathingEntryJson = ExploreEntryJson & {
  activityIdentifier: string;
  data: Record<string, any>;
  technique: BreathingTechniqueStepJson[];
};

export class BreathingEntry extends ExploreEntry {
  @observable
  activityIdentifier = 'breathing';
  @observable
  data: Record<string, any> = observable.map({});
  @observable
  technique: BreathingTechniqueStep[] = [];

  constructor(json?: Partial<BreathingEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'breathing' }));
    if (json) {
      this.type = 'breathing';
      this.activityIdentifier = 'breathing';
      this.data = observable.map(json.data ?? {});
      this.technique = (json.technique ?? []).map((s) => new BreathingTechniqueStep(s));
    }
  }

  toJS(): BreathingEntryJson {
    return Object.assign(super.toJS(), {
      type: 'breathing',
      activityIdentifier: this.activityIdentifier,
      data: toJS(this.data),
      technique: this.technique.map((s) => s.toJS()),
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<BreathingEntry[]> {
    return HttpBackend.get(`/coach/explore/admin`, Object.assign(toJS(request), { type: 'breathing' })).then((res) =>
      (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static async findOne(id: string): Promise<BreathingEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new BreathingEntry(res));
  }
}
