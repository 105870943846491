/**
 *
 * Created by neo on 30.11.16.
 */

import { observable, action, computed, runInAction } from 'mobx';
import AppAuthToken from '../Services/Security/AppAuthToken';
import AuthenticationService from '../Services/Security/AuthenticationService';
import { User } from '../Model/User';
import { Athlete } from '../Model/Athlete/Athlete';
import { EMPTY_ARRAY } from '../Utils/Constants';
import AppBackend from '../Services/Http/AppBackend';
import __DEV__ from '../Utils/Dev';

export class AuthenticationStore {
  @observable
  authenticated = false;
  @observable
  authTokenCheckInProgress = true;
  @observable
  isAuthenticating = false;
  @observable
  athlete?: Athlete;
  @observable
  wrongPasswordCount = 0;

  @action
  async checkAuthData() {
    this.authTokenCheckInProgress = true;
    AppAuthToken.loadAuthData();
    if (!this.athlete) {
      try {
        const user = await AuthenticationService.checkToken();
        return this.setUserData(user);
      } catch (err) {
        this.authTokenCheckInProgress = false;
        throw err;
      }
    }
    await AppAuthToken.getAuthData();
    return this.athlete;
  }

  @action
  logout() {
    this.authenticated = false;
    this.athlete = undefined;

    AuthenticationService.logout();
  }

  @action
  setUserData(athlete: Athlete): Athlete {
    this.athlete = athlete;
    this.authenticated = true;
    this.isAuthenticating = false;
    this.authTokenCheckInProgress = false;
    return this.athlete;
  }

  otpLogin(authenticationCode: string): Promise<Athlete> {
    this.isAuthenticating = true;
    return AuthenticationService.otpLogin(authenticationCode)
      .then((athleteData) => this.setUserData(athleteData))
      .finally(() => runInAction(() => (this.isAuthenticating = false)));
  }

  requestMagicLink(email: string): Promise<void> {
    return AppBackend.post(`/coach/authentication/magic-link`, {
      email: email,
      callbackUrl: this.callBackUrl(),
      numeric: true,
    });
  }

  private callBackUrl(): string {
    if (__DEV__) {
      return 'http://localhost:3000/auth';
    }

    return 'https://admin.kinastic.com/auth';
  }

  hasAnyRole(roles: string[]): boolean {
    return !!this.athlete?.user.roles.some((r) =>
      roles.map((r1) => (r1.startsWith('ROLE_') ? r1 : `ROLE_${r1}`)).some((r1) => r1 === r),
    );
  }

  hasRoles(roles: string[]): boolean {
    return roles.every((r1) => (this.athlete?.user.roles ?? EMPTY_ARRAY).some((r) => r1 === r));
  }

  @computed
  get isAdmin(): boolean {
    return !!this.athlete?.user.isAdmin;
  }

  @computed
  get isGodAdmin(): boolean {
    return !!this.athlete?.user.isGodAdmin;
  }

  @computed
  get isOneAboveAll(): boolean {
    return this.athlete?.user?.email === 'jose@kinastic.com';
  }
}
