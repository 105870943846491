/**
 * Created by katarinababic on 14.11.23.
 */
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { FileStorageContent, FileStorageContentJson } from './FileStorageContent';

export type FolderContentJson = FileStorageContentJson & {};

export class FolderContent extends FileStorageContent {
  @observable
  isDirectory: boolean = true;

  constructor(json?: Partial<FolderContentJson>) {
    super(json);
    if (json) {
      this.name = json.name ?? '';
      this.isDirectory = json.isDirectory ?? true;
    }
  }

  static async createFolder(path: string = '/') {
    return HttpBackend.post(`/media/file-storage/admin/folder?path=${this.transformPath(path)}`);
  }

  static async renameFolder(currentName: string = '/', newName: string = '/') {
    const currentFolderName = this.transformPath(currentName);
    const newFolderName = this.transformPath(newName);
    return HttpBackend.put(
      `/media/file-storage/admin/folder?currentFolderName=${currentFolderName}&newFolderName=${newFolderName}`,
    );
  }

  static async deleteFolder(paths: string[]) {
    const pathsWithSlash = paths.map((path) => this.transformPath(path));
    return HttpBackend.delete(`/media/file-storage/admin/folder?paths=${pathsWithSlash}`);
  }
}
