/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, Bar, YAxis, Tooltip, Legend } from 'recharts';

export type UserPropertiesDataEntry = { [key: string]: string | number }[];

export type UserPropertiesData = { [key: string]: UserPropertiesDataEntry };

export type UserPropertiesStatsChartProps = {
  property: string;
  categories: UserPropertiesDataEntry;
  color?: string;
};

export const UserPropertiesStatsChart: React.FC<UserPropertiesStatsChartProps> = observer(
  ({ property, categories, color = '#913CA4' }) => {
    return (
      <Form.Item label={property}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart height={250} data={categories} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar type="monotone" dataKey="user_count" fill={color} />
          </BarChart>
        </ResponsiveContainer>
      </Form.Item>
    );
  },
);
