import { Stage } from './Stage';
import objectPath from 'object-path';
import { DataSignal, dataSignal } from '../Signal/DataSignal';

export interface MapStageConfig {
  attribute: string;
}

export class MapStage extends Stage<MapStageConfig> {
  process() {
    this.dataSignals
      .map((signal) => [objectPath.get(signal.data, this.config.attribute), signal])
      .map(([data, signal], index) => new DataSignal(data, index))
      .forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'map';
  }
}
