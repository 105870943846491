/**
 * Created by neo on 23.08.21.
 */
import { WorkoutDayEntry, WorkoutDayEntryJson } from './WorkoutDayEntry';
import { computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';

export type WorkoutDayConfigurationJson = {
  id: string;
  workouts: WorkoutDayEntryJson[];
};

export class WorkoutDayConfiguration {
  @observable
  id = UUID();
  @observable
  workouts: WorkoutDayEntry[] = [];

  constructor(json?: Partial<WorkoutDayConfigurationJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.workouts = (json.workouts ?? []).map((w) => new WorkoutDayEntry(w));
    }
  }

  toJS(newId: boolean = false): WorkoutDayConfigurationJson {
    return {
      id: newId ? UUID() : this.id,
      workouts: this.workouts.map((w) => w.toJS(newId)),
    };
  }

  @computed
  get minWorkoutDurationInMinutes(): number | undefined {
    return this.workouts.reduce(
      (agg, cycle) =>
        cycle.minWorkoutDurationInMinutes
          ? Math.min(cycle.minWorkoutDurationInMinutes, agg ?? Number.MAX_SAFE_INTEGER)
          : agg,
      undefined as number | undefined,
    );
  }

  @computed
  get maxWorkoutDurationInMinutes(): number | undefined {
    return this.workouts.reduce(
      (agg, cycle) =>
        cycle.maxWorkoutDurationInMinutes
          ? Math.max(cycle.maxWorkoutDurationInMinutes, agg ?? Number.MIN_SAFE_INTEGER)
          : agg,
      undefined as number | undefined,
    );
  }
}
