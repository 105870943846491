import { StagePort } from './StagePort';
import { Stage } from '../Stages/Stage';
import { StageOutPort } from './StageOutPort';

export class StageInPort extends StagePort {
  constructor(stage: Stage<any>, type: string = 'in', public outPort?: StageOutPort) {
    super(stage, type);
    this.otherPort = outPort;
  }
}
