import { observable } from 'mobx';

export type ExercisePositionPrimary = 'standing' | 'sitting' | 'lying' | 'kneeling' | 'cross_legged';
export type ExercisePositionSecondary =
  | 'straight'
  | 'inclined_front'
  | 'inclined_back'
  | 'inclined_side'
  | 'back'
  | 'belly'
  | 'side';

export class ExercisePosition {
  @observable
  primary: ExercisePositionPrimary = 'standing';
  @observable
  secondary?: ExercisePositionSecondary = undefined;

  constructor(json?: any) {
    if (json) {
      this.primary = json.primary;
      this.secondary = json.secondary;
    }
  }

  toJS(): any {
    return {
      primary: this.primary,
      secondary: this.secondary,
    };
  }
}
