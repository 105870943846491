/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { ExerciseFilter } from '../../Model/Exercise/ExerciseFilter';
import { EquipmentTypeInput } from '../EquipmentTypeInput';
import { SingleColRow } from '../SingleColRow';

type Props = {
  filter: ExerciseFilter;
};

export const ExerciseVariationFilterEquipmentTypes: React.FC<Props> = observer(({ filter }: Props) => {
  return <EquipmentTypeInput label="Equipment Types" container={filter.equipmentTypes} />;
});
