/**
 * Created by neo on 29.04.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Table } from 'reactstrap';
import { BodyPartJoint } from '../../../Model/BodyPart/BodyPartJoint';
import { BodyPartJointTableRow } from './BodyPartJointTableRow';

export type BodyPartTableProps = {
  joints: BodyPartJoint[];
  onRemove?: (joint: BodyPartJoint) => any;
};

export const BodyPartJointTable: React.FC<BodyPartTableProps> = observer(({ joints, onRemove }: BodyPartTableProps) => {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>{'Image'}</th>
          <th>{'Identifier'}</th>
          <th>{'Name'}</th>
          <th>{'Exercises'}</th>
          <th>{'Delete'}</th>
        </tr>
      </thead>
      <tbody>
        {joints.map((b: BodyPartJoint) => (
          <BodyPartJointTableRow key={b.id} joint={b} onRemove={onRemove} />
        ))}
      </tbody>
    </Table>
  );
});
