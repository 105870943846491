/**
 * Created by neo on 05.08.22.
 */
import {
  EarnModelRuleActivationLogicOrBlock,
  EarnModelRuleActivationLogicOrBlockJson,
} from './EarnModelRuleActivationLogicOrBlock';
import { observable } from 'mobx';

export type EarnModelRuleActivationLogicJson = {
  logicBlocks: EarnModelRuleActivationLogicOrBlockJson[];
};

export class EarnModelRuleActivationLogic {
  @observable
  logicBlocks: EarnModelRuleActivationLogicOrBlock[] = [];

  constructor(json?: Partial<EarnModelRuleActivationLogicJson>) {
    if (json) {
      this.logicBlocks = json.logicBlocks?.map((l) => new EarnModelRuleActivationLogicOrBlock(l)) ?? [];
    }
  }

  toJS(): EarnModelRuleActivationLogicJson {
    return {
      logicBlocks: this.logicBlocks.map((l) => l.toJS()),
    };
  }
}
