/**
 * Created by neo on 11.01.21.
 */
import {
  computed,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { VersionInformation } from '../VersionInformation';
import { ExploreCategoryLink, ExploreCategoryLinkJson } from './ExploreCategoryLink';
import { ExploreContent, ExploreContentJson, ExploreContentQueryRequest } from './ExploreContent';
import { v4 as UUID } from 'uuid';

export type ExploreCategoryType =
  | 'preview'
  | 'big_preview'
  | 'single_exercises'
  | 'card_link'
  | 'category_group'
  | 'link_list'
  | 'recipe_recommendations'
  | 'recipe_favorites'
  | 'recipe_all'
  | 'tools'
  | 'blank';

export type ExploreCategoryQueryRequest = ExploreContentQueryRequest & {};

export type ExploreCategoryJson = ExploreContentJson & {
  identifier: string;
  link?: string;
  links: ExploreCategoryLinkJson[];
  sortingRule?: string;
};

export class ExploreCategory extends ExploreContent {
  @observable
  identifier: string = '';
  @observable
  link?: string;
  @observable
  links: ExploreCategoryLink[] = [];
  @observable
  parent?: ExploreCategory;
  @observable
  sortingRule?: string;

  fetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<ExploreCategoryJson>) {
    super(Object.assign({}, json ?? {}, { contentType: 'category' }));
    if (json) {
      this.identifier = json.identifier || this.id || UUID();
      this.link = json.link;
      this.links = (json.links ?? []).map((l) => new ExploreCategoryLink(l));
      this.version = new VersionInformation(json.version);
      this.sortingRule = json.sortingRule;
    }

    onBecomeObserved(this, 'parent', this.startFetchParent);
    onBecomeUnobserved(this, 'parent', this.stopFetchParent);
  }

  fetchParent(parentId?: string): Promise<ExploreCategory | undefined> {
    if (parentId) {
      return ExploreCategory.findOne(parentId, { gymId: this.gymId });
    }
    return Promise.resolve(undefined);
  }

  startFetchParent = () => {
    this.fetchDisposer = reaction(
      () => this.categories[0],
      (parentId) => {
        if (parentId) {
          this.fetchParent(parentId).then((result) => runInAction(() => (this.parent = result)));
        } else {
          runInAction(() => (this.parent = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchParent = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = undefined;
  };

  toJS(newId: boolean = false): ExploreCategoryJson {
    return Object.assign(super.toJS(newId), {
      identifier: this.identifier || this.id,
      link: this.link,
      links: this.links.map((l) => l.toJS()),
      sortingRule: this.sortingRule,
    });
  }

  save(): Promise<ExploreCategory> {
    return HttpBackend.post(`/coach/explore/category/admin`, this.toJS()).then(() => this);
  }

  delete(): Promise<ExploreCategory> {
    return HttpBackend.delete(`/coach/explore/category/admin/${this.id}`).then(() => this);
  }

  @computed
  get parentId(): string | undefined {
    return this.categories[0];
  }

  static find(params: ExploreCategoryQueryRequest): Promise<ExploreCategory[]> {
    return HttpBackend.get(`/coach/explore/category/admin`, toJS(Object.assign({}, params ?? {}))).then((res) =>
      (res ?? []).map((r) => new ExploreCategory(r)),
    );
  }

  static count(params: ExploreCategoryQueryRequest): Promise<number> {
    return HttpBackend.get(`/coach/explore/category/admin/count`, toJS(params));
  }

  static findOne(id: string, params?: ExploreCategoryQueryRequest): Promise<ExploreCategory> {
    return HttpBackend.get(`/coach/explore/category/admin/${id}`, params).then((res) => new ExploreCategory(res));
  }

  static findAll(ids: string[], params?: ExploreCategoryQueryRequest): Promise<ExploreCategory[]> {
    return HttpBackend.get(`/coach/explore/category/admin/findAll/${ids.join(',')}`, params).then((result) =>
      (result ?? []).map((res) => new ExploreCategory(res)),
    );
  }
}
