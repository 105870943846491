/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { MacroCycleTemplate } from '../../../../Model/Coach/SuperMacro/MacroCycleTemplate';
import { MacroCycleLaneList } from './MacroCycleLaneList';
import { TagConditionsInput } from './TagConditionsInput';
import { SingleColRow } from '../../../../Components/SingleColRow';

export type MacroCycleCardProps = {
  macroCycle: MacroCycleTemplate;
  onRemove?: (macro: MacroCycleTemplate) => any;
};

export const MacroCycleCard: React.FC<MacroCycleCardProps> = observer(
  ({ macroCycle, onRemove }: MacroCycleCardProps) => {
    const handleRemoveLane = React.useCallback(
      (lane) => {
        macroCycle.lanes = macroCycle.lanes.filter((l) => l.id !== lane.id);
      },
      [macroCycle],
    );

    return (
      <React.Fragment>
        <SingleColRow>
          <TagConditionsInput tagConditions={macroCycle.tagConditions} />
        </SingleColRow>
        <MacroCycleLaneList macroCycle={macroCycle} onRemove={handleRemoveLane} />
      </React.Fragment>
    );
  },
);
