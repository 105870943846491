/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { GymChallenge } from '../../../../../Model/GymChallenge/GymChallenge';
import { ChallengeMilestone } from '../../../../../Model/GymChallenge/ChallengeMilestone';
import { Col, Row } from 'reactstrap';
import { runInAction } from 'mobx';
import { Button, Form, InputNumber } from 'antd';
import { EarnModelTemplateRuleStepped } from '../../../../../Model/Engagement/EarnModelTemplateRuleStepped';
import {
  EarnModelTemplateRuleSteppedValueStep
} from '../../../../../Model/Engagement/EarnModelTemplateRuleSteppedValueStep';

export type PointsStepEditorProps = {
  rule: EarnModelTemplateRuleStepped;
  pointStep: EarnModelTemplateRuleSteppedValueStep;
};

export const PointsStepEditor: React.FC<PointsStepEditorProps> = observer(
  ({ rule, pointStep }) => {
    const handleChangeMinValue = React.useCallback(
      (value: number | string | undefined | null) => {
        runInAction(() => (pointStep.minValue = value !== undefined && value !== null ? Number(value) : 0));
      },
      [pointStep],
    );

    const handleChangeMaxValue = React.useCallback(
      (value: number | string | undefined | null) => {
        runInAction(() => (pointStep.maxValue = value !== undefined && value !== null ? Number(value) : 0));
      },
      [pointStep],
    );

    const handleChangePoints = React.useCallback(
      (value: number | string | undefined | null) => {
        runInAction(() => (pointStep.points = value !== undefined && value !== null ? Number(value) : 0));
      },
      [pointStep],
    );

    const handleChangeExtraPoints = React.useCallback(
      (value: number | string | undefined | null) => {
        runInAction(() => (pointStep.extraPoints = value !== undefined && value !== null ? Number(value) : 0));
      },
      [pointStep],
    );

    const handleRemove = React.useCallback(() => {
      runInAction(() => (rule.pointsSteps = rule.pointsSteps.filter((m) => m !== pointStep)));
    }, [rule, pointStep]);

    return (
      <Row>
        <Col>
          <Form.Item label="Min Value" extra="optional">
            <InputNumber value={pointStep.minValue} onChange={handleChangeMinValue} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Max Value">
            <InputNumber value={pointStep.maxValue} onChange={handleChangeMaxValue} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Points">
            <InputNumber value={pointStep.points} onChange={handleChangePoints} />
          </Form.Item>
        </Col>
        {/*<Col>*/}
        {/*  <Form.Item label="Extra Points" extra="Extra Points when user exercised >= sector minutes">*/}
        {/*    <InputNumber value={pointStep.extraPoints} onChange={handleChangeExtraPoints} />*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        <Col xs="auto">
          <Button danger size="small" onClick={handleRemove}>
            Remove
          </Button>
        </Col>
      </Row>
    );
  },
);
