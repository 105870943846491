import { Signal } from './Signal';

export function dataSignal(x: Signal | undefined): x is DataSignal {
  return x instanceof DataSignal;
}

export class DataSignal extends Signal {
  constructor(public readonly data: any, public readonly index: number = 0) {
    super();
  }
}
