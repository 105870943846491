/**
 * Created by neo on 09.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { InlineCode } from 'mdast-util-from-markdown/dev/lib';

export type MarkdownInlineCodeRendererProps = {
  content: InlineCode;
};

export const MarkdownInlineCodeRenderer: React.FC<MarkdownInlineCodeRendererProps> = observer((props) => {
  return null;
});
