/**
 * Created by yaseminturgay on 23.06.23.
 * */
import { observable } from 'mobx';
import { Audited, AuditedJson } from '../../Audited';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type RewardsWalletJson = AuditedJson & {
  lastTransaction?: string | null;
  rewardCoinsCollectionEnabled: boolean;
  experiencePointsConversionFactor: number;
  rewardsWalletId: string;
  shopBaseUrl?: string;
  totalCollectedRewardCoins: number;
  totalSpentRewardCoins: number;
  availableRewardCoins: number;
  totalCollectableRewardCoins: number;
  spendableRewardCoins: number;
};

export class RewardsWallet extends Audited {
  @observable
  rewardCoinsCollectionEnabled = false;
  @observable
  experiencePointsConversionFactor = 1.0;
  @observable
  rewardsWalletId = '';
  @observable
  shopBaseUrl?: string;
  @observable
  totalCollectedRewardCoins = 0;
  @observable
  totalSpentRewardCoins = 0;
  @observable
  availableRewardCoins = 0;
  @observable
  totalCollectableRewardCoins = 0;
  @observable
  spendableRewardCoins = 0;

  constructor(json?: Partial<RewardsWalletJson>) {
    super(json);
    if (json) {
      this.rewardCoinsCollectionEnabled = json.rewardCoinsCollectionEnabled ?? false;
      this.experiencePointsConversionFactor = json.experiencePointsConversionFactor ?? 1.0;
      this.rewardsWalletId = json.rewardsWalletId ?? '';
      this.shopBaseUrl = json.shopBaseUrl;
      this.totalCollectedRewardCoins = json.totalCollectedRewardCoins ?? 0;
      this.totalSpentRewardCoins = json.totalSpentRewardCoins ?? 0;
      this.availableRewardCoins = json.availableRewardCoins ?? 0;
      this.totalCollectableRewardCoins = json.totalCollectableRewardCoins ?? 0;
      this.spendableRewardCoins = json.spendableRewardCoins ?? 0;
    }
  }

  static findByCustomerId(customerId: string): Promise<RewardsWallet> {
    return HttpBackend.get('/engagement/rewards/admin/wallet', { customerId }).then((res) => {
      return new RewardsWallet(res);
    });
  }
}
