/**
 * Created by neo on 07.05.21.
 */
import { observable } from 'mobx';

export type SsmlVoiceGender = 'MALE' | 'FEMALE' | 'NEUTRAL';

export type GoogleTtsVoiceJson = {
  languageCode: string;
  gender?: SsmlVoiceGender;
  name?: string;
};

export class GoogleTtsVoice {
  @observable
  languageCode: string = 'de-DE';
  @observable
  gender?: SsmlVoiceGender;
  @observable
  name?: string;

  constructor(json?: Partial<GoogleTtsVoiceJson>) {
    if (json) {
      this.languageCode = json.languageCode ?? 'de-DE';
      this.gender = json.gender;
      this.name = json.name;
    }
  }

  toJS(): GoogleTtsVoiceJson {
    return {
      languageCode: this.languageCode,
      gender: this.gender,
      name: this.name,
    };
  }
}
