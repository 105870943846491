import { Stage } from './Stage';

export class ConcatStage extends Stage<any> {
  process() {
    this.dataSignals.forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'concat';
  }
}
