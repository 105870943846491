import { NotificationInterval, NotificationIntervalJson } from '../Schedule/NotificationInterval';
import { computed, observable } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';

/**
 * Created by neo on 17.10.21.
 */

export type MicroCycleDayScheduleJson = {
  startDate: string;
  startTime: string;
  timezone: string;
  notificationIntervals: NotificationIntervalJson[];
};

export class MicroCycleDaySchedule {
  @observable
  startDate: string = '';
  @observable
  startTime: string = '';
  @observable
  timezone: string = 'Europe/Zurich';
  @observable
  notificationIntervals: NotificationInterval[] = [];

  constructor(json?: Partial<MicroCycleDayScheduleJson>) {
    if (json) {
      this.startDate = json.startDate ?? '';
      this.startTime = json.startTime ?? '';
      this.timezone = json.timezone ?? 'Europe/Zurich';
      this.notificationIntervals = (json.notificationIntervals ?? []).map((n) => new NotificationInterval(n));
    }
  }

  toJS(): MicroCycleDayScheduleJson {
    return {
      startDate: this.startDate,
      startTime: this.startTime,
      timezone: this.timezone,
      notificationIntervals: this.notificationIntervals.map((n) => n.toJS()),
    };
  }

  @computed
  get startTimeFixed(): string | undefined {
    if (this.startTime) {
      if (this.startTime?.length === 5) {
        return `${this.startTime}:00`;
      } else if ((this.startTime?.length ?? 0) >= 8) {
        return this.startTime.substr(0, 8);
      }
    }
    return undefined;
  }

  @computed
  get startDateTime(): Dayjs | undefined {
    if (this.startDate) {
      const time = this.startTimeFixed ?? '00:00';
      return dayjs(`${this.startDate} ${time}`, 'YYYY-MM-DD HH:mm');
    }
    return undefined;
  }

  @computed
  get notificationsEnabled(): boolean {
    return this.notificationIntervals.length > 0;
  }
}
