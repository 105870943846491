/**
 * @flow
 * Created by neo on 18.01.17.
 */

import { action, observable, computed, toJS, ObservableMap } from 'mobx';
import { TrackingKey } from './TrackingKeys';

export class AbstractSet {
  @observable
  values: ObservableMap<TrackingKey, number> = observable.map({});
  @observable
  updateTimestamp: number = Date.now();

  constructor(source?: any) {
    if (source) {
      this.setData(source);
    }
  }

  toJS(): any {
    return {
      values: toJS(this.values),
      updateTimestamp: this.updateTimestamp,
    };
  }

  @action
  setData(json: any) {
    this.values = observable.map(json.values || {});
    this.updateTimestamp = json.updateTimestamp || Date.now();
  }

  @action
  updateData(json: any) {
    this.setData(json);
  }

  @action
  setKey(key: TrackingKey, value?: number) {
    this.setUpdateTimestamp();
    if (value) {
      this.values.set(key, value);
    } else {
      this.values.delete(key);
    }
  }

  @action
  setUpdateTimestamp() {
    this.updateTimestamp = Date.now();
  }

  @computed
  get calories(): number {
    return this.plannedDurationMs;
  }

  @computed
  get duration(): number {
    return this.plannedDurationMs;
  }

  @computed
  get durationSeconds(): number {
    return this.plannedDuration;
  }

  @computed
  get breakTime(): number {
    return this.plannedBreakTime;
  }

  @computed
  get breakTimeMs(): number {
    return this.plannedBreakTime * 1000;
  }

  @computed
  get plannedBreakTime(): number {
    return this.values.get('MIN_BREAK') || this.values.get('MAX_BREAK') || this.values.get('BREAK') || 0;
  }

  @computed
  get plannedDuration(): number {
    return this.values.get('MAX_DURATION') || this.values.get('MIN_DURATION') || this.values.get('DURATION') || 0;
  }

  @computed
  get plannedDurationMs(): number {
    return this.plannedDuration * 1000;
  }

  @computed
  get tons(): number {
    const weight = this.values.get('MIN_WEIGHT') || this.values.get('MAX_WEIGHT') || this.values.get('WEIGHT') || 0;
    const reps =
      this.values.get('MIN_REPETITIONS') || this.values.get('MAX_REPETITIONS') || this.values.get('REPETITIONS') || 0;
    return (reps * weight) / 1000;
  }

  @computed
  get minBreak(): number {
    return this.values.get('MIN_BREAK') || this.values.get('MAX_BREAK') || 0;
  }

  @computed
  get maxBreak(): number {
    return this.values.get('MAX_BREAK') || this.values.get('MIN_BREAK') || 0;
  }

  @computed
  get minDuration(): number {
    const { values, minRepetitionDuration } = this;
    let time = values.get('MIN_DURATION') || values.get('MAX_DURATION') || minRepetitionDuration;
    time += this.minBreak;
    return time * 1000;
  }

  @computed
  get maxDuration(): number {
    const { values, maxRepetitionDuration } = this;
    let time = values.get('MAX_DURATION') || values.get('MIN_DURATION') || maxRepetitionDuration;
    time += this.maxBreak;
    return time * 1000;
  }

  @computed
  get minRepetitionDuration(): number {
    const reps = this.values.get('MIN_REPETITIONS') || this.values.get('MAX_REPETITIONS') || 0;
    return reps * 3;
  }

  @computed
  get maxRepetitionDuration(): number {
    const reps = this.values.get('MAX_REPETITIONS') || this.values.get('MIN_REPETITIONS') || 0;
    return reps * 3;
  }

  @computed
  get assignedTrackingKeys(): Array<string> {
    const result: Array<string> = [];
    const temp = Array.from(this.values.keys());
    temp.forEach((item: string) => {
      if (item && (item.startsWith('MIN_') || item.startsWith('MAX_'))) {
        const index = result.findIndex((k: string) => k === item.substr(4));
        if (index === -1) {
          result.push(item.substr(4));
        }
      } else {
        const index = result.findIndex((k: string) => k === item);
        if (index === -1) {
          result.push(item);
        }
      }
    });
    return result;
  }
}
