/**
 * Created by neo on 14.11.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { PhaseStage, Substitute } from '../../../../../../../Model/Coach/Stages/PhaseStage';
import { ActionMeta } from 'react-select';
import { PhaseNodeModel } from './PhaseNodeModel';
import { StyledCol, StyledRow } from '../Default/DefaultStageNodeWidget';

export type PhaseSubstituteInputProps = {
  node: PhaseNodeModel;
  substitute: Substitute;
  index: number;
};

export const PhaseSubstituteInput: React.FC<PhaseSubstituteInputProps> = observer(
  ({ node, substitute, index }: PhaseSubstituteInputProps) => {
    const fetch = React.useCallback(
      (name?: string): Promise<Array<Pipeline>> =>
        Pipeline.find({
          name,
          page: 0,
          sort: 'name,ASC',
        }).then((result) => result.filter((b) => b.id !== substitute.pipelineId)),
      [substitute],
    );

    const handleChange = React.useCallback(
      (pipeline?: Pipeline, action?: ActionMeta<any>) => {
        substitute.pipelineId = pipeline ? pipeline.id : undefined;
        substitute.pipeline = pipeline;
      },
      [substitute],
    );

    const getOptionLabel = (option: Pipeline) => option.name;

    const getOptionValue = (option: Pipeline) => option.id;

    const handleFocus = React.useCallback(() => {
      node.setLocked(true);
    }, [node]);

    const handleBlur = React.useCallback(() => {
      node.setLocked(false);
    }, [node]);

    const handleRemove = React.useCallback((e) => {
      e.preventDefault();
      node.stage.config.substitutes.splice(index, 1);
    }, [node, index]);

    return (
      <React.Fragment>
        <StyledRow>
          <StyledCol>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                value={substitute.pipeline}
                cacheOptions
                defaultOptions
                isClearable={true}
                loadOptions={fetch}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue as any}
                onChange={handleChange as any}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
          </StyledCol>
          <StyledCol auto={true}>
            <div style={{ padding: 2 }}>
              <a href="" onClick={handleRemove}>
                X
              </a>
            </div>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <Link style={{ color: 'black' }} to={`/coach/workout-script/${substitute.pipelineId}`}>
            {substitute.pipeline?.name}
          </Link>
        </StyledRow>
      </React.Fragment>
    );
  },
);
