import { runInAction } from 'mobx';
import { LocalizedValue } from '../Model/LocalizedValue';
import { HttpBackend } from './Http/HttpBackend';

export type DeeplTranslateParams = {
  sourceLanguage?: string;
  targetLanguage: string;
  text: string;
};

export class DeeplService {
  static translateName(
    entity: LocalizedValue[],
    targetLanguages: string[] = ['de', 'en', 'fr', 'it', 'es'],
  ): Promise<any> {
    const sourceEntry =
      entity.find((e) => e.lang === 'de' && !!e.value.trim()) ??
      entity.find((e) => e.lang === 'en' && !!e.value.trim()) ??
      entity.find((e) => !!e.value.trim());

    const missingLanguages = targetLanguages.filter((l) => !entity.some((e) => e.lang === l && !!e.value.trim()));

    if (sourceEntry && missingLanguages.length > 0) {
      return Promise.all(
        missingLanguages.map((language) =>
          DeeplService.translate({
            sourceLanguage: sourceEntry.lang,
            text: sourceEntry.value,
            targetLanguage: language,
          })
            .catch((err) => {
              console.error(err);
            })
            .then((response) =>
              runInAction(() => entity.push(new LocalizedValue({ lang: language, value: response ?? '' }))),
            ),
        ),
      );
    }

    return Promise.resolve();
  }

  static translate(params: DeeplTranslateParams): Promise<string | undefined> {
    return HttpBackend.post('/translation/deepl', params).then((response) =>
      response?.translations?.[0]?.text,
    )
      .catch(() => undefined);
  }
}
