export const allUserPropertiesStatsByAge = `
    SELECT key, value, age, COUNT(user_id) as user_count FROM (
        SELECT
        user_id,
        p.key as key,
        p.value.string_value as value,
        age.value.string_value as age,
        ROW_NUMBER() OVER (PARTITION BY user_id, p.key ORDER BY event_timestamp DESC) as rn,
        FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST (user_properties) AS p,
        UNNEST (user_properties) AS age
        WHERE
        p.key <> 'birthdate'
        AND age.key = 'athlete_age'
        AND p.key <> 'ga_session_id'
        AND p.key <> 'ga_session_number'
        AND p.key <> 'athleteId'
        AND p.key <> 'user_id'
        AND p.key <> 'firebase_last_notification'
        AND p.key <> 'last_workout_timestamp'
        AND p.key <> 'last_exercise_timestamp'
        AND p.key <> 'last_exercise_started'
        AND p.key <> 'last_exercise_duration'
        AND p.key <> 'first_open_time'
        AND p.key <> 'body_weight'
        AND p.key <> 'body_height'
        AND p.key <> 'athlete_age'
        AND p.key <> 'gymId'
        AND user_id IS NOT NULL
        ORDER BY user_id, key, rn
        ) u
    WHERE u.rn = 1
    GROUP BY key, value, age
    ORDER BY key, value, age
`;

export const gymUserPropertiesStatsByAge = `
    SELECT key, value, age, COUNT (user_id) as user_count
    FROM (
        SELECT
        user_id,
        p.key as key,
        p.value.string_value as value,
        age.value.string_value as age,
        ROW_NUMBER() OVER (PARTITION BY user_id, p.key ORDER BY event_timestamp DESC) as rn,
        FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        UNNEST (user_properties) AS p,
        UNNEST (user_properties) AS gym,
        UNNEST (user_properties) AS age
        WHERE
        p.key <> 'birthdate'
        AND age.key = 'athlete_age'
        AND p.key <> 'ga_session_id'
        AND p.key <> 'ga_session_number'
        AND p.key <> 'athleteId'
        AND p.key <> 'user_id'
        AND p.key <> 'firebase_last_notification'
        AND p.key <> 'last_workout_timestamp'
        AND p.key <> 'last_exercise_timestamp'
        AND p.key <> 'last_exercise_started'
        AND p.key <> 'last_exercise_duration'
        AND p.key <> 'first_open_time'
        AND p.key <> 'body_weight'
        AND p.key <> 'body_height'
        AND p.key <> 'athlete_age'
        AND p.key <> 'gymId'
        AND gym.key = 'gymId'
        AND gym.value.string_value = @gym_id
        AND user_id IS NOT NULL
        ORDER BY user_id, key, rn
        ) u
    WHERE u.rn = 1
    GROUP BY key, value, age
    ORDER BY key, value, age
`;
