/**
 * Created by katarinababic on 15.12.21..
 */

export const allTopFiveClickedEntriesWeekly = `
SELECT
  *,
  week as event_date
FROM (
  SELECT
    *,
    ROW_NUMBER() OVER (PARTITION BY week ORDER BY select_count DESC) AS rank
  FROM (
    SELECT
      DATE_TRUNC(PARSE_DATE('%Y%m%d',
          event_date), WEEK) AS week,
      params_type.value.string_value AS entry_type,
      params_id.value.string_value AS entry_id,
      COUNT(DISTINCT user_id) AS user_count,
      COUNT(*) AS select_count
    FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      UNNEST (event_params) AS params_type,
      UNNEST (event_params) AS params_id
    WHERE
      _TABLE_SUFFIX BETWEEN @start_date
      AND @end_date
      AND event_name = 'select_content'
      AND params_type.key = 'content_type'
      AND params_type.value.string_value = @entry_type
      AND params_id.key = 'item_id'
    GROUP BY
      week,
      entry_type,
      entry_id
    ORDER BY
      week,
      select_count DESC ))
WHERE
  rank <= 5
ORDER BY
  week ASC
`;

export const gymTopFiveClickedEntriesWeekly = `
SELECT
  *,
  week as event_date
FROM (
  SELECT
    *,
    ROW_NUMBER() OVER (PARTITION BY week ORDER BY select_count DESC) AS rank
  FROM (
    SELECT
      DATE_TRUNC(PARSE_DATE('%Y%m%d',
          event_date), WEEK) AS week,
      params_type.value.string_value AS entry_type,
      params_id.value.string_value AS entry_id,
      COUNT(DISTINCT user_id) AS user_count,
      COUNT(*) AS select_count
    FROM
      \`flinq-6796d.analytics_201736462.events_*\`,
      UNNEST (event_params) AS params_type,
      UNNEST (event_params) AS params_id,
      UNNEST (user_properties) AS p
    WHERE
      _TABLE_SUFFIX BETWEEN @start_date
      AND @end_date
      AND event_name = 'select_content'
      AND params_type.key = 'content_type'
      AND params_type.value.string_value = @entry_type
      AND params_id.key = 'item_id'
      AND p.key = 'gymId'
      AND p.value.string_value = @gym_id
    GROUP BY
      week,
      entry_type,
      entry_id
    ORDER BY
      week,
      select_count DESC ))
WHERE
  rank <= 5
ORDER BY
  week ASC
`;
