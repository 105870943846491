/**
 * Created by neo on 14.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ExerciseStatisticsUsage } from '../../../../../Model/Exercise/Statistics/ExerciseStatisticsUsage';
import dayjs, { Dayjs } from 'dayjs';

export type GymExerciseStatisticsTableRowProps = {
  entry: ExerciseStatisticsUsage;
};

export const GymExerciseStatisticsTableRow: React.FC<GymExerciseStatisticsTableRowProps> = observer(({ entry }) => {
  return (
    <tr>
      <td>
        {entry.exercise?.imageMedia?.smallest && (
          <img style={{ height: 120 }} src={entry.exercise?.imageMedia?.smallest} />
        )}
      </td>
      <td>
        <Link
          to={`metadata/exercise/${entry.exercise?.id}?sourceType=${entry.exercise?.sourceType ?? ''}&sourceId=${
            entry.exercise?.sourceId ?? ''
          }`}
        >
          {entry.exercise?.name}
        </Link>
      </td>
      <td>{entry.exercise?.type}</td>
      <td>{entry.exercise?.equipmentTypes.join(', ')}</td>
      <td>{entry.count}</td>
      <td>{entry.athletes}</td>
      <td>{Math.round(dayjs.duration(entry.duration * 1000).asHours() * 100) / 100}</td>
    </tr>
  );
});
