import { observable, onBecomeObserved, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Customer } from '../Customer/Customer';
import { ActivityLog } from '../Activity/ActivityLog';

export class CustomerActivityLog extends ActivityLog {
  @observable
  customerId: string = '';
  @observable
  customer?: Customer = undefined;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.customerId = json.customerId || '';
    }

    onBecomeObserved(this, 'customer', this.fetchCustomer);
  }

  fetchCustomer = () => {
    if (!this.customer && this.customerId) {
      Customer.get(this.customerId).then((customer) => runInAction(() => (this.customer = customer)));
    }
  };

  static findAll(params?: any): Promise<CustomerActivityLog[]> {
    return HttpBackend.get('/gym/activitylog', params).then((result) =>
      result.map((res) => new CustomerActivityLog(res)),
    );
  }

  static count(params?: any): Promise<number> {
    return HttpBackend.get('/gym/activitylog/count', params).then((result) => result ?? 0);
  }
}
