/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CampaignCalendarEntry } from '../../../Model/CampaignCalendar/CampaignCalendarEntry';
import { Link } from 'react-router-dom';
import { Button, message, Popconfirm, Space, Tag } from 'antd';
import dayjs from 'dayjs';

export type CampaignCalendarTableRowProps = {
  entry: CampaignCalendarEntry;
  onRefresh?: () => void;
};

export const CampaignCalendarTableRow: React.FC<CampaignCalendarTableRowProps> = observer(({ entry, onRefresh }) => {
  const [processing, setProcessing] = useState(false);

  const handleDelete = () =>
    entry
      .delete()
      .then(() => onRefresh?.())
      .then(() => message.success('Campaign deleted'))
      .catch(() => message.error('Failed to delete campaign'));

  const handleCopy = () => {
    setProcessing(true);
    entry
      .copy()
      .save()
      .then(() => onRefresh?.())
      .finally(() => setProcessing(false));
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <Link to={`/customer-success/campaign-calendar/${entry.id}`}>{entry.id}</Link>
        </td>
        <td>{`${entry.defaultName}`}</td>
        <td>{entry.gymId ? <Link to={`/infrastructure/gym/${entry.gymId}}`}>{entry.gym?.name}</Link> : 'Global'}</td>
        <td>{`${dayjs(entry.entryDate, 'YYYY-MM-DD').format('DD. MMMM YYYY')}\n${entry.entryTime}`}</td>
        <td>{entry.publishDate ? dayjs(entry.publishDate).format('DD.MM.YYYY') : '-'}</td>
        <td>{entry.unpublishDate ? dayjs(entry.unpublishDate).format('DD.MM.YYYY') : '-'}</td>
        <td>{entry.published ? <Tag color="success">Published</Tag> : <Tag color="error">Unpublished</Tag>}</td>
        <td>{entry.attachments.length}</td>

        <td>
          <Space>
            <Popconfirm title={'Are you sure?'} onConfirm={handleDelete}>
              <Button danger size="small">
                Delete
              </Button>
            </Popconfirm>
            <Button size="small" onClick={handleCopy}>
              Copy
            </Button>
          </Space>
        </td>
      </tr>
    </React.Fragment>
  );
});
