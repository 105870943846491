import { withDay } from '../../Queries/withDay';

export const weekDayDistribution = (gym: boolean = false) =>
  events.reduce(
    (query, event, index) =>
      query + `LEFT JOIN (${hourEventTotal(event, gym)}) q${index}\n ON root.time = q${index}.time\n`,
    `${withDay('time')} LEFT JOIN (${hourTotal(gym)}) first ON root.time = first.time \n`,
  ) +
  `LEFT JOIN (${hourRecipe(gym)}) recipe ON root.time = recipe.time\n
  LEFT JOIN (${hourWorkout(gym, 'gym_strength')}) gs ON root.time = gs.time\n
  LEFT JOIN (${hourWorkout(gym, 'workplace')}) gw ON root.time = gw.time\n
  LEFT JOIN (${hourUsers(gym)}) users ON root.time = users.time`;

export const hourTotal = (gym: boolean = false) => `
  SELECT
      FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(*) as total
  FROM
    \`flinq-6796d.analytics_201736462.events_*\`,
    ${gym ? `UNNEST(user_properties) p,` : ''}
    UNNEST(event_params) e
  WHERE
    _TABLE_SUFFIX BETWEEN @start_date AND @end_date
    ${
      gym
        ? `AND p.key = 'gymId'
    AND p.value.string_value = @gym_id`
        : ''
    }
    AND 
        (
            (event_name = 'yogaSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'breathingSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'meditationSession_finished' AND e.key = 'athleteId')
            OR (event_name = 'Workout_finished' AND e.key = 'athleteId')
            OR (event_name = 'activity_logged' AND e.key = 'athleteId')
            OR (event_name = 'FeedEntry_read' AND e.key = 'athleteId')
            OR (e.key = 'content_type' AND e.value.string_value = 'recipe')
        )
  GROUP BY time
`;

const hourEventTotal = (eventName: string, gym: boolean = false) => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(*) as ${eventName}_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`
        ${gym ? ',UNNEST(user_properties) p' : ''}
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND event_name = '${eventName}'
    GROUP BY time
`;

const hourRecipe = (gym: boolean = false) => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(*) as recipe_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        ${gym ? `UNNEST(user_properties) p,` : ''}
        UNNEST(event_params) e
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
        ${
          gym
            ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
            : ''
        }
      AND e.key = 'content_type'
      AND e.value.string_value = 'recipe'
    GROUP BY time
`;

const hourWorkout = (gym: boolean = false, workoutType: string = 'gym_strength') => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(*) as ${workoutType}_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        ${gym ? `UNNEST(user_properties) p,` : ''}
        UNNEST(event_params) e
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND e.key = 'workoutType'
      AND e.value.string_value = '${workoutType}'
    GROUP BY time
`;

const hourUsers = (gym: boolean = false) => `
    SELECT FORMAT_DATE('%A', DATE(TIMESTAMP_MICROS(event_timestamp))) time,
    COUNT(DISTINCT user_id) as user_count
    FROM
        \`flinq-6796d.analytics_201736462.events_*\`,
        ${gym ? `UNNEST(user_properties) p,` : ''}
        UNNEST(event_params) e
    WHERE
        _TABLE_SUFFIX BETWEEN @start_date AND @end_date
      ${
        gym
          ? `AND p.key = 'gymId'
      AND p.value.string_value = @gym_id`
          : ''
      }
      AND e.key = 'athleteId'
    GROUP BY time
`;

const events = [
  'yogaSession_finished',
  'breathingSession_finished',
  'meditationSession_finished',
  'Workout_finished',
  'activity_logged',
  'FeedEntry_read',
];
