import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';
import { ActivityLogTableRow } from './ActivityLogTableRow';
import { WorkoutLog } from '../../../../Model/WorkoutLog';
import { ActivityLog } from '../../../../Model/Activity/ActivityLog';

export type ActivityLogTableProps = {
  activityLogs: ActivityLog[];
};

export const ActivityLogTable: React.FC<ActivityLogTableProps> = observer(({ activityLogs }) => {
  return (
    <Table size="sm" hover={true} striped={true}>
      <thead>
        <tr>
          <th>{'Type'}</th>
          <th>{'Athlete'}</th>
          <th>{'Source'}</th>
          <th>{'Date'}</th>
          <th>{'Start'}</th>
          <th>{'End'}</th>
          <th>{'Duration'}</th>
        </tr>
      </thead>
      <tbody>
        {activityLogs.map((w) => (
          <ActivityLogTableRow key={w.id} activityLog={w} />
        ))}
      </tbody>
    </Table>
  );
});
