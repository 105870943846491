/**
 * Created by neo on 04.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Input } from 'antd';
import { Pipeline } from '../../../../../Model/Coach/Pipeline';
import { PipelineTrayItem } from './PipelineTrayItem';
import styled from '@emotion/styled';

export type ModuleSelectionContainerProps = {};

const InputContainer = styled.div`
  padding-bottom: 8px;
`;

export const ModuleSelectionContainer: React.FC<ModuleSelectionContainerProps> = observer((props) => {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(new Array<Pipeline>());

  useEffect(() => {
    Pipeline.list({ tags: 'phase' }).then((pipelines) => setResult(pipelines));
  }, []);

  const handleChange = React.useCallback(({ target: { value } }) => {
    setQuery(value);
    Pipeline.list({ tags: 'phase', name: value }).then((pipelines) => setResult(pipelines));
  }, []);

  return (
    <React.Fragment>
      <InputContainer>
        <Input value={query} onChange={handleChange} placeholder="Search..." />
      </InputContainer>
      <SingleColRow style={{ overflow: 'scroll-y' }}>
        {result.map((pipeline) => (
          <PipelineTrayItem key={pipeline.id} pipeline={pipeline} />
        ))}
      </SingleColRow>
    </React.Fragment>
  );
});
