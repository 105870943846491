/**
 *
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Input } from 'antd';
import { HealthDataPointQuantity } from '../../../../Model/HealthData/HealthDataPointQuantity';
import { HealthDataPointCorrelation } from '../../../../Model/HealthData/HealthDataPointCorrelation';
import { HealthDataPointQuantityEntry } from './HealthDataPointQuantityEntry';
import { SingleColRow } from '../../../../Components/SingleColRow';

export type HealthDataPointCorrelationEntryProps = {
  dataPoint: HealthDataPointCorrelation;
};

export const HealthDataPointCorrelationEntry: React.FC<HealthDataPointCorrelationEntryProps> = observer(
  ({ dataPoint }) => {
    const handleSave = React.useCallback(
      (newPoint: HealthDataPointQuantity) => {
        const index = dataPoint.values.findIndex((d) => d.type === newPoint.type);
        if (index !== -1) {
          dataPoint.values[index] = newPoint;
        } else {
          dataPoint.values.push(newPoint);
        }
        dataPoint.save();
      },
      [dataPoint],
    );

    return (
      <Row style={{ marginTop: 4, marginBottom: 4 }}>
        <Col>
          <Input type="text" value={dataPoint.type} disabled={true} />
        </Col>
        <Col>
          {dataPoint.values.map((quantity) => (
            <SingleColRow key={dataPoint.id}>
              <HealthDataPointQuantityEntry dataPoint={quantity} onSave={handleSave} />
            </SingleColRow>
          ))}
        </Col>
      </Row>
    );
  },
);
