/**
 * Created by neo on 02.08.22.
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { observable } from 'mobx';
import { EarnModelTemplateRuleAbstract, EarnModelTemplateRuleAbstractJson } from './EarnModelTemplateRuleAbstract';
import { EarnModelTemplateRuleBuilder } from './EarnModelTemplateRuleBuilder';

export type EarnModelTemplateCategoryJson = LocalizedArrayEntityJson & {
  templateName: string;
  rules: EarnModelTemplateRuleAbstractJson[];
  hidden: boolean;
};

export class EarnModelTemplateCategory extends LocalizedArrayEntity {
  @observable
  templateName: string = '';
  @observable
  rules: EarnModelTemplateRuleAbstract[] = [];
  @observable
  hidden = false;

  constructor(json?: Partial<EarnModelTemplateCategoryJson>) {
    super(json);
    if (json) {
      this.templateName = json.templateName ?? '';
      this.rules = json.rules?.map((r) => EarnModelTemplateRuleBuilder.build(r)) ?? [];
      this.hidden = json.hidden ?? false;
    }
  }

  toJS(newId: boolean = false): EarnModelTemplateCategoryJson {
    return Object.assign(super.toJS(newId), {
      templateName: this.templateName,
      rules: this.rules.map((r) => r.toJS(newId)),
      hidden: this.hidden,
    });
  }
}
