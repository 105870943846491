/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AnalyticsRow } from '../../../../Model/Analytics/AnalyticsData';
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Line } from 'recharts';
import { ChartColors } from '../../ChartColors';

export type ExploreWorkoutAnalyticsChartProps = {
  data: AnalyticsRow[];
};

export const ExploreWorkoutAnalyticsChart: React.FC<ExploreWorkoutAnalyticsChartProps> = observer(({ data }) => {
  console.log('workout', data);
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="event_date" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" tickLine={false} axisLine={false} />
        <Tooltip />
        <Legend />

        <Bar yAxisId="left" type="monotone" dataKey="user_count" fill="#CE7537" />
        <Bar yAxisId="left" type="monotone" dataKey="select_user_count" fill="#D2A45F" />

        <Line yAxisId="left" dataKey="count" stroke={ChartColors[2]} />
        <Line yAxisId="right" dataKey="avg_percentage_completed" stroke={ChartColors[3]} connectNulls />
        <Line yAxisId="right" dataKey="converted" stroke={ChartColors[4]} connectNulls />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
