/**
 * Created by neo on 08.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Strong } from 'mdast-util-from-markdown/lib';
import { MarkdownNodeRenderer } from './MarkdownNodeRenderer';

export type MarkdownStrongRendererProps = {
  content: Strong;
  textStyle?: React.CSSProperties;
};

export const MarkdownStrongRenderer: React.FC<MarkdownStrongRendererProps> = observer(({ content, textStyle }) => {
  return (
    <b style={textStyle}>
      {content.children.map((child, index) => (
        <MarkdownNodeRenderer key={index.toString()} child={child} />
      ))}
    </b>
  );
});
