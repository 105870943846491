/**
 * Created by neo on 18.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../Model/Gym/Gym';
import dayjs, { Dayjs } from 'dayjs';
import { AnalyticsData, AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../Components/SingleColRow';
import { DatePicker, Form } from 'antd';
import { SignupOnboardingChart } from './SignupOnboardingChart';
import { allSignupOnboardingDaily, signupOnboardingDaily } from './Queries/querySignupOnboardingDaily';

export type SignupOnboardingDailyProps = {
  gym?: Gym;
};

export const SignupOnboardingDaily: React.FC<SignupOnboardingDailyProps> = observer(({ gym }) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [data, setData] = useState<AnalyticsRow[] | undefined>();

  useEffect(() => {
    setStartDate(dayjs(gym?.createdAt ?? dayjs('2020-05-01', 'YYYY-MM-DD').toDate()));
  }, [gym]);

  useEffect(() => {
    setData(undefined);
    AnalyticsData.query({
      query: gym ? signupOnboardingDaily : allSignupOnboardingDaily,
      parameters: Object.assign(
        {
          start_date: {
            value: startDate.format('YYYYMMDD'),
          },
          end_date: {
            value: endDate.format('YYYYMMDD'),
          },
        },
        gym
          ? {
              gym_id: {
                value: gym?.id,
              },
            }
          : {},
      ) as any,
    }).then((data) =>
      setData(
        data
          .map((row, index, arr) => {
            const prev = arr[index - 1];
            if (prev) {
              if (row['onboarding_completed_cumulative'] === '0' || !row['onboarding_completed_cumulative']) {
                row['onboarding_completed_cumulative'] = prev['onboarding_completed_cumulative'];
              }

              if (row['sign_up_cumulative'] === '0' || !row['sign_up_cumulative']) {
                row['sign_up_cumulative'] = prev['sign_up_cumulative'];
              }
            }

            return row;
          })
          .map((d) =>
            Array.from(Object.entries(d))
              .map(([key, value], index, arr) => [key, key === 'event_date' ? value : Number(value)])
              .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}),
          ),
      ),
    );
  }, [gym, startDate, endDate]);

  const handleChangeValidFrom = React.useCallback(
    (date) => setStartDate(date ?? dayjs().subtract(1, 'month')),
    [],
  );

  const handleChangeValidUntil = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return (
    <Container>
      <SingleColRow>
        <h5>Sign Ups & Onboardings Daily</h5>
      </SingleColRow>
      <Row>
        <Col xs={6}>
          <Form.Item label="Start Date">
            <DatePicker value={startDate} onChange={handleChangeValidFrom} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="End Date">
            <DatePicker value={endDate} onChange={handleChangeValidUntil} />
          </Form.Item>
        </Col>
        <Col xs={12}>{data && <SignupOnboardingChart data={data} />}</Col>
      </Row>
    </Container>
  );
});
