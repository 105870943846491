import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { GreaterThanNodeWidget } from './GreaterThanNodeWidget';
import { GreaterThanNodeModel } from './GreaterThanNodeModel';
import { GreaterThanStage } from '../../../../../../../../Model/Coach/Stages/Logical/GreaterThanStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class GreaterThanNodeModelFactory extends AbstractReactFactory<GreaterThanNodeModel, DiagramEngine> {
  constructor() {
    super('logical:gt');
  }

  generateReactWidget(event): JSX.Element {
    return <GreaterThanNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new GreaterThanNodeModel(new GreaterThanStage(new Pipeline()));
  }
}
