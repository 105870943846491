/**
 * Created by neo on 09.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Blockquote } from 'mdast-util-from-markdown/dev/lib';
import { MarkdownNodeRenderer } from './MarkdownNodeRenderer';
import { styled } from '@mui/material/styles';
import { ThemeConfig } from '../../Config/Theme';

const Container = styled('div')(({ theme }) => ({
  marginLeft: 8,
  marginRight: 8,
  backgroundColor: ThemeConfig.Colors.lightGrey,
  borderLeft: `8px solid ${ThemeConfig.Colors.warmestGrey}`,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 1,
  paddingBottom: 1,
  [theme.breakpoints.up('xl')]: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

export type MarkdownBlockquoteRendererProps = {
  content: Blockquote;
  textStyle?: React.CSSProperties;
};

export const MarkdownBlockquoteRenderer: React.FC<MarkdownBlockquoteRendererProps> = observer(
  ({ content, textStyle }) => {
    return (
      <Container>
        {content.children.map((child, index) => (
          <MarkdownNodeRenderer key={index.toString()} child={child} textStyle={textStyle} />
        ))}
      </Container>
    );
  },
);
