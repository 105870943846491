/**
 *
 * Created by neo on 16.03.17.
 */
import * as React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { OAuthScopeInput } from '../../../../Components/OAuthScopeInput';
import { OAuthScope } from '../../../../Model/OAuthScope';

export type Props = {
  scope: OAuthScope;
  isNew?: boolean;
};

@observer
export class OAuthScopeViewBasicInfoTab extends React.Component<Props> {
  handleChangeType = ({ target: { value } }: any) => {
    value = value.toLowerCase();
    if (this.props.isNew) {
      this.props.scope.id = value;
    } else {
      this.props.scope.newId = value;
    }
  };

  handleChangeName = ({ target: { value } }: any) => {
    this.props.scope.name = value;
  };

  handleChangeDescription = ({ target: { value } }: any) => {
    this.props.scope.description = value;
  };

  render() {
    const {
      props: { scope, isNew },
    } = this;
    return (
      <Row>
        {isNew ? (
          <Col sm={6}>
            <FormGroup>
              <Label>ID / Type</Label>
              <Input type="text" onChange={this.handleChangeType} value={scope.id} />
            </FormGroup>
          </Col>
        ) : (
          <Col sm={12}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>ID / Type</Label>
                  <Input type="text" value={scope.id} disabled />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>New ID / Type</Label>
                  <Input type="text" onChange={this.handleChangeType} value={scope.newId} />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        )}
        <Col sm={6}>
          <FormGroup>
            <Label>Name</Label>
            <Input type="text" onChange={this.handleChangeName} value={scope.name} />
          </FormGroup>
        </Col>
        <Col sm={6}>
          <OAuthScopeInput container={scope.includes} label="Included Scopes" />
        </Col>
        <Col sm={12}>
          <FormGroup>
            <Label>Description</Label>
            <Input type="textarea" onChange={this.handleChangeDescription} value={scope.description} />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}
