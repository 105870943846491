export class TagCombination {
  static readonly goals = ['goal:weight_loss', 'goal:muscle_growth', 'goal:stay_fit'];
  static readonly levels = ['level:beginner', 'level:intermediate', 'level:advanced'];
  static readonly ages = ['age:junior', 'age:adult', 'age:senior', 'age:elder'];
  static readonly frequencies = ['frequency:2', 'frequency:3', 'frequency:4', 'frequency:5'];
  static readonly equipment = ['equipment:no', 'equipment:yes'];
  static readonly activities = ['activity:sitting', 'activity:standing', 'activity:physical'];
  static readonly durations = ['duration:short', 'duration:medium', 'duration:long'];
  static readonly genders = ['gender:male', 'gender:female'];

  static get permutationsWorkout(): string[][] {
    return this.levels.flatMap((level) =>
      this.ages.flatMap((age) =>
        this.activities.flatMap((activity) =>
          this.genders.flatMap((gender) => this.durations.map((duration) => [level, age, activity, duration, gender])),
        ),
      ),
    );
  }
}
