import { Stage } from './Stage';
import { DataSignal, dataSignal } from '../Signal/DataSignal';

export class RandomStage extends Stage<any> {
  collection: DataSignal[] = [];

  process() {
    const { dataSignals: input } = this;
    for (let i = input.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [input[i], input[j]] = [input[j], input[i]];
    }

    input.forEach((signal) => this.processNext(signal));
  }

  get type(): string {
    return 'random';
  }
}
