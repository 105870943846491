/**
 *
 * Created by neo on 04.03.17.
 */
import { observable, computed } from 'mobx';
import { GeoJsonPoint } from './GeoJsonPoint';

export class Address {
  @observable
  addressLine1: string;
  @observable
  addressLine2: string;
  @observable
  city: string = '';
  @observable
  zipCode: string = '';
  @observable
  state: string = '';
  @observable
  country: string = '';
  @observable
  location?: GeoJsonPoint = undefined;

  constructor(json?: any) {
    if (json) {
      this.addressLine1 = json.addressLine1;
      this.addressLine2 = json.addressLine2;
      this.city = json.city;
      this.zipCode = json.zipCode;
      this.state = json.state;
      this.country = json.country;
      this.location = json.location ? new GeoJsonPoint(json.location) : undefined;
    }
  }

  toJS() {
    return {
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      zipCode: this.zipCode,
      state: this.state,
      country: this.country,
      location: this.location?.toJS(),
    };
  }

  @computed
  get friendlyString(): string {
    let address = '';
    if (this.addressLine1) {
      address = `${this.addressLine1}`;
    }
    if (this.zipCode) {
      address = `${address ? `${address}, ` : ''}${this.zipCode}`;
    }
    if (this.city) {
      address = `${address ? `${address} ` : ''}${this.city}`;
    }
    if (this.state) {
      address = `${address ? `${address} ` : ''}${this.state}`;
    }
    if (this.country) {
      address = `${address ? `${address} ` : ''}${this.country}`;
    }
    return address;
  }
}
