/**
 * Created by neo on 20.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, Bar, YAxis, Tooltip, Legend } from 'recharts';
import { ChartColors } from '../ChartColors';

export type UserPropertiesDataEntry = { name: string; user_count: number }[];

export type UserPropertiesAgeGenderData = { [key: string]: UserPropertiesDataEntry };

export type UserPropertiesStatsAgeGenderChartProps = {
  property: string;
  categories: UserPropertiesDataEntry;
  subCategories: string[];
  dataKeys: string[];
  stackId?: string;
};

export const UserPropertiesStatsAgeGenderChart: React.FC<UserPropertiesStatsAgeGenderChartProps> = observer(
  ({ property, categories, subCategories, dataKeys, stackId }) => {
    console.log('UserPropertiesStatsAgeGenderChart', categories, subCategories, dataKeys);

    const allKeys = subCategories.flatMap((category) => dataKeys.map((dataKey) => ({ category, dataKey })));

    return (
      <Form.Item label={property}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart height={250} data={categories} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {allKeys.map(({ category, dataKey }, index) => (
              <Bar
                key={`${category}_${dataKey}`}
                type="monotone"
                dataKey={`${category}_${dataKey}`}
                fill={ChartColors[index % ChartColors.length]}
                stackId={category}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Form.Item>
    );
  },
);
