import { computed, observable, ObservableMap } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Pageable } from '../Pageable';
import { Audited } from '../Audited';

export type AthleteDeviceInfoQueryRequest = Record<string, string | number | undefined> &
  Pageable & {
    athleteId?: string;
    app?: string;
  };

export class AthleteDeviceInfo extends Audited {
  @observable
  app = 'club';
  @observable
  athleteId: string = '';
  @observable
  data: ObservableMap<string> = observable.map({});

  constructor(json?: any) {
    super(json);
    if (json) {
      this.app = json.app;
      this.athleteId = json.athleteId;
      this.data = observable.map(json.data || {});
    }
  }

  @computed
  get appName(): string {
    return this.data.get('bundleId') === 'app.flinq.athlete' ? 'coach' : this.app;
  }

  static find(params?: AthleteDeviceInfoQueryRequest): Promise<AthleteDeviceInfo[]> {
    return HttpBackend.get('/athlete/deviceinfo', params).then((data) => data.map((d) => new AthleteDeviceInfo(d)));
  }

  static last(params?: AthleteDeviceInfoQueryRequest): Promise<AthleteDeviceInfo | undefined> {
    return HttpBackend.get('/athlete/deviceinfo/last', params).then((data) =>
      data ? new AthleteDeviceInfo(data) : undefined,
    );
  }
}
