import {
  computed,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { Pipeline } from './Pipeline';
import { ValueType } from './Stages/ValueType';
import { EquipmentType } from '../Equipment/EquipmentType';

export type ParamValues = {
  [key: string]: any;
};

export type PipelineContextJson = {
  tags: string[];
  equipmentTypes: string[];
  excludeExerciseIds: string[];
  paramValues: ParamValues;
  executeUntilStageId?: string;
  debug?: boolean;
};

export class PipelineContext {
  @observable
  tags: Array<string> = [];
  @observable
  equipmentTypes: Array<string> = [];
  @observable
  excludeExerciseIds: string[] = [];
  @observable
  paramValues: ParamValues = {};
  @observable
  debug = false;
  executeUntilStageId?: string;
  @observable
  equipmentTypeData?: EquipmentType[];
  equipmentTypeFetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<PipelineContextJson>) {
    if (json) {
      this.tags = json.tags || [];
      this.equipmentTypes = json.equipmentTypes || [];
      this.excludeExerciseIds = json.excludeExerciseIds ?? [];
      this.executeUntilStageId = json.executeUntilStageId;
      this.paramValues = json.paramValues || {};
      this.debug = json.debug ?? false;
    }

    onBecomeObserved(this, 'equipmentTypeData', this.startFetchEquipmentTypes);
    onBecomeUnobserved(this, 'equipmentTypeData', this.stopFetchEquipmentTypes);
  }

  startFetchEquipmentTypes = () => {
    this.equipmentTypeFetchDisposer && this.equipmentTypeFetchDisposer();
    this.equipmentTypeFetchDisposer = reaction(
      () => this.equipmentTypes.map((e) => e),
      (equipmenTypes) => {
        if (equipmenTypes.length > 0) {
          EquipmentType.list()
            .then((result) => result.filter((e) => equipmenTypes.some((e1) => e1 === e.id)))
            .then((result) => runInAction(() => (this.equipmentTypeData = result)));
        } else {
          runInAction(() => {
            this.equipmentTypeData = [];
          });
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchEquipmentTypes = () => {
    this.equipmentTypeFetchDisposer && this.equipmentTypeFetchDisposer();
    this.equipmentTypeFetchDisposer = undefined;
  };

  copy(params?: any): PipelineContext {
    return new PipelineContext(Object.assign(this.toJS(), params ?? {}));
  }

  getValue<T>(pipeline: Pipeline, param: string, defaultValue?: T, raw: boolean = false): T | undefined {
    const paramDefinition = pipeline.params.find((p) => p.name === param);
    const value = this.paramValues[param] ?? this[param] ?? paramDefinition?.defaultValue ?? defaultValue;
    console.log('PipelineContext::getValue', { param, value, defaultValue, paramDefinition });
    return paramDefinition && !raw ? this.transformValue(value, paramDefinition.type) : value;
  }

  private transformValue(value: any, type: ValueType): any {
    switch (type) {
      case 'number':
        return Number(value);
      case 'boolean':
        return !!value;
      case 'stringArray':
        return value.split(',').map((s) => s.trim());
      case 'numberArray':
        return value
          .split(',')
          .map((s) => s.trim())
          .map((s) => Number(s));
      case 'booleanArray':
        return value
          .split(',')
          .map((s) => s.trim())
          .map((s) => !!s);
      case 'string':
      default:
        return value;
    }
  }

  toJS(): PipelineContextJson {
    return {
      tags: toJS(this.tags),
      equipmentTypes: toJS(this.equipmentTypes),
      paramValues: this.paramValues,
      excludeExerciseIds: toJS(this.excludeExerciseIds),
      executeUntilStageId: this.executeUntilStageId,
      debug: this.debug,
    };
  }

  @computed
  get isWithoutEquipment(): boolean {
    return (
      (this.equipmentTypes.length === 1 &&
        (this.equipmentTypes.includes('FREE') || this.equipmentTypes.includes('FREE_OUTDOOR'))) ||
      (this.equipmentTypes.length === 2 &&
        this.equipmentTypes.includes('FREE') &&
        this.equipmentTypes.includes('FREE_OUTDOOR'))
    );
  }
}
