/**
 * @fow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Badge } from 'reactstrap';
import { AxonEventProcessor } from '../../../../../Model/Axon/AxonEventProcessor';
import { fromPromise, IPromiseBasedObservable, PENDING } from 'mobx-utils';
import { AxonEventProcessorTracker } from '../../../../../Model/Axon/AxonEventProcessorTracker';
import dayjs, { Dayjs } from 'dayjs';

export type Props = {
  model: AxonEventProcessor;
  globalIndexMax: number;
  globalIndexMin: number;
};

@observer
export class AxonTrackingTableRow extends React.Component<Props> {
  @observable
  isOpen: boolean = false;
  @observable
  dropdownOpen: boolean = false;
  @observable
  resetPromise?: IPromiseBasedObservable<any>;

  reset = () => {
    if (window.confirm(`Do you REALLY want to reset ${this.props.model.name}???`)) {
      this.resetPod();
    }
  };

  resetHourAgo = (hours: number) => () => {
    const timestamp = dayjs().subtract(hours, 'hours').valueOf();
    this.resetPod(timestamp);
  };

  resetNow = () => {
    this.resetPod(0);
  };

  resetPod(startTimestamp?: number) {
    this.resetPromise = fromPromise(this.props.model.reset(startTimestamp));
  }

  toggle = (e) => {
    e.preventDefault();
    this.isOpen = !this.isOpen;
  };

  toggleButtonDropdown = () => (this.dropdownOpen = !this.dropdownOpen);

  @computed
  get badgeColorMin(): string {
    return this.props.model.globalIndexMin < this.props.globalIndexMax - 1 ? 'danger' : 'success';
  }

  @computed
  get badgeColorMax(): string {
    return this.props.model.globalIndexMax < this.props.globalIndexMax - 1 ? 'danger' : 'success';
  }

  @computed
  get restarting(): boolean {
    return !!this.resetPromise && this.resetPromise.state === PENDING;
  }

  render() {
    const {
      isOpen,
      props: { model },
    } = this;
    return (
      <React.Fragment>
        <tr style={{ cursor: 'pointer' }}>
          <td>{model.componentName}</td>
          <td>
            <a href="" onClick={this.toggle}>
              {model.name}
            </a>
          </td>
          <td>
            <Badge color={this.badgeColorMin}>{model.globalIndexMin}</Badge>
          </td>
          <td>
            <Badge color={this.badgeColorMax}>{model.globalIndexMax}</Badge>
          </td>
          <td>{model.trackers.length}</td>
          <td>
            {model.supportsReset ? (
              <ButtonDropdown
                size="sm"
                isOpen={this.dropdownOpen}
                toggle={this.toggleButtonDropdown}
                disabled={this.restarting}
              >
                <DropdownToggle caret>Reset</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.resetHourAgo(1)}>An hour ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(6)}>6 hours ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(12)}>12 hours ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(24)}>24 hours ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(48)}>48 hours ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(72)}>72 hours ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(168)}>a week ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(336)}>two weeks ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(744)}>a month ago</DropdownItem>
                  <DropdownItem onClick={this.resetHourAgo(4464)}>half a year ago</DropdownItem>
                  <DropdownItem onClick={this.resetNow}>Set to Now</DropdownItem>
                  <DropdownItem onClick={this.reset}>From Beginning</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            ) : null}
          </td>
        </tr>
        {isOpen ? (
          <tr>
            <td colSpan={8}>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Pod</th>
                    {/*<th>Type</th>*/}
                    <th>Segment</th>
                    <th>Token Position</th>
                    {/*<th>Segments</th>*/}
                    {/*<th>Active Threads</th>*/}
                    <th>Caught Up</th>
                    <th>Replaying</th>
                    {/*<th>State</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {model.trackers.flatMap((p: AxonEventProcessorTracker) => (
                    <React.Fragment>
                      <tr key={`${p.clientId}`}>
                        <td>{p.clientId}</td>
                        {/*<td>{p.type}</td>*/}
                        <td>{p.segmentId}</td>
                        <td>{p.tokenPosition}</td>
                        {/*<td>{p.segments.length}</td>*/}
                        {/*<td>{p.activeProcessorThreads}</td>*/}
                        <td>{p.caughtUp ? 'true' : 'false'}</td>
                        <td>{p.replaying ? 'true' : 'false'}</td>
                        {/*<td>{p.state}</td>*/}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </td>
          </tr>
        ) : null}
      </React.Fragment>
    );
  }
}
