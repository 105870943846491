/**
 *
 * Created by neo on 25.02.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Gym } from '../../../../../../Model/Gym/Gym';
import { AthleteTableRow } from './AthleteTableRow';
import { HttpBackend } from '../../../../../../Services/Http/HttpBackend';
import { Athlete } from '../../../../../../Model/Athlete/Athlete';

import { v4 as UUID } from 'uuid';
import { SingleColRow } from '../../../../../../Components/SingleColRow';
import { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { Table } from 'reactstrap';
import { useDebouncedEffect } from '../../../../../../Utils/useDebouncedEffect';

export type CustomerCreateModalProps = {
  gym: Gym;
  isOpen?: boolean;
  onClose?: () => any;
};

export const CustomerCreateModal: React.FC<CustomerCreateModalProps> = observer(({ gym, isOpen, onClose }) => {
  const debounce = React.useRef<any>();
  const [selectedAthlete, setSelectedAthlete] = useState<Athlete | undefined>();
  const [athleteResult, setAthleteResult] = useState<Athlete[]>([]);
  const [query, setQuery] = useState('');

  useDebouncedEffect(() => {
    Athlete.find({
      query,
      size: 20,
    }).then((res) => setAthleteResult(res));
  }, [query]);

  const toggle = () => {
    onClose && onClose();
  };

  const dismiss = () => {
    onClose && onClose();
  };

  const handleChange = ({ target: { value } }: any) => {
    setQuery(value);
  };

  const createCustomer = async () => {
    try {
      if (selectedAthlete) {
        await HttpBackend.post('/gym/customer/admin/create', {
          customerId: UUID(),
          athleteId: selectedAthlete.id,
          gymId: gym.id,
        });
        dismiss();
      }
    } catch (err) {}
  };

  const handleSelectAthlete = (newAthlete: Athlete) => {
    if (selectedAthlete?.id === newAthlete.id) {
      setSelectedAthlete(undefined);
    } else {
      setSelectedAthlete(newAthlete);
    }
  };

  return (
    <Modal
      open={true}
      onCancel={toggle}
      title="Create Customer"
      footer={[
        <Button key="1" type="primary" onClick={createCustomer} disabled={!selectedAthlete}>
          Create Customer
        </Button>,
        <Button key="2" color="secondary" onClick={dismiss}>
          Cancel
        </Button>,
      ]}
    >
      <SingleColRow>
        <Input type="text" onChange={handleChange} />
      </SingleColRow>
      <SingleColRow>
        <Table striped={true} size="sm" hover={true} className="pt-2">
          <thead className="thead-inverse">
            <tr>
              <th>{'Name'}</th>
              <th>{'User'}</th>
            </tr>
          </thead>
          <tbody>
            {athleteResult.map((rowAthlete) => (
              <AthleteTableRow
                key={rowAthlete.id}
                athlete={rowAthlete}
                onSelect={handleSelectAthlete}
                selected={rowAthlete.id === selectedAthlete?.id}
              />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
    </Modal>
  );
});
