import * as React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { useNavigate, Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import { SingleColRow } from '../../Components/SingleColRow';
import { observer } from 'mobx-react';

export type MainLayoutComponentProps = {};

export const MainLayout: React.FC<MainLayoutComponentProps> = observer(() => {
  const history = useNavigate();

  const handleLogout = React.useCallback(() => {
    setTimeout(() => {
      history('/auth', { replace: true });
    }, 250);
  }, [history]);

  return (
    <React.Fragment>
      <Header onLogout={handleLogout} />
      <Container id="main-container" fluid style={{ paddingBottom: 60 }}>
        <SingleColRow>
          <Outlet />
        </SingleColRow>
      </Container>
      <Footer />
    </React.Fragment>
  );
});
