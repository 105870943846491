/**
 * Created by neo on 28.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import styled from '@emotion/styled';
import { PipelineNodeModel } from './PipelineNodeModel';

export type ExecutionModeSelectProps = {
  node: PipelineNodeModel;
};

const StyledSelect = styled.select`
  flex: 1;
  color: black;
  margin-right: 4px;
`;

export const ExecutionModeSelect: React.FC<ExecutionModeSelectProps> = observer(
  ({ node }: ExecutionModeSelectProps) => {
    const handleChange = React.useCallback(
      ({ target: { value } }: any) => {
        node.stage.config.executionMode = value;
      },
      [node],
    );

    return (
      <StyledSelect value={node.stage.config.executionMode ?? 'STREAM'} onChange={handleChange}>
        <option value="ONCE">ONCE</option>
        <option value="STREAM">STREAM</option>
      </StyledSelect>
    );
  },
);
