/**
 * Created by neo on 01.05.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { BodyPartTableTab } from './BodyPartTableTab';
import { BodyPartJointTab } from './BodyPartJointTab';

export type BodyPartTabsProps = {
  onTabChange?: (tab: string) => any;
};

export const BodyPartTabs: React.FC<BodyPartTabsProps> = observer(({ onTabChange }) => {
  const { tab = 'table' } = useParams<any>();

  return (
    <Tabs activeKey={tab} animated={false} onChange={onTabChange}>
      <Tabs.TabPane tab="Body Parts" key="table">
        <BodyPartTableTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Joints" key="joints">
        <BodyPartJointTab />
      </Tabs.TabPane>
    </Tabs>
  );
});
