/**
 * @flow
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';

export interface ValueEntry {
  value: string;
  label: string;
}

export type Props = {
  container: string[];
  label?: string;
  values: ValueEntry[];
  onChange?: (values: ValueEntry[]) => any;
  disabled?: boolean;
};

@observer
export class ExerciseVariationFilterMultipleSelection extends React.Component<Props> {
  handleChange = (selectedOptions: ValueEntry[]) => {
    this.props.onChange && this.props.onChange(selectedOptions);
  };

  @computed
  get values(): any[] {
    return this.props.container.map((c) => ({ value: c, label: c }));
  }

  render() {
    const {
      props: { label, values, disabled, container },
    } = this;
    return (
      <React.Fragment>
        <Row>
          <Col>
            <FormGroup>
              {label ? <Label>{label}</Label> : null}
              <Select
                value={this.values}
                isMulti
                name="colors"
                options={values}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option}
                onChange={this.handleChange as any}
                isDisabled={disabled}
              />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

// const ExerciseVariationsTab = withRouter(ExerciseVariationsTabComponent);
// export default ExerciseVariationsTab;
