/**
 * Created by neo on 25.02.17.
 */
import { observer } from 'mobx-react';
import * as React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
    padding: 4,
  },
};

export type Props = {
  size?: number;
  onClick?: () => void;
};

@observer
export class DeleteIcon extends React.Component<Props> {
  render() {
    const size = this.props.size || 26;
    const borderRadius = size || 26 / 2;
    const style = {
      ...styles.container,
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: `${borderRadius}px`,
      backgroundColor: 'red',
    };
    return (
      <div style={style as any} onClick={this.props.onClick}>
        <DeleteOutlined />
      </div>
    );
  }
}
