/**
 * Created by neo on 20.01.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { Media } from '../../../../../../Model/Media/Media';
import { MediaAudioTrack } from '../../../../../../Model/Media/MediaAudioTrack';
import { Col, Row } from 'reactstrap';
import { Button, Input, Select } from 'antd';
import { runInAction } from 'mobx';
import { SingleColRow } from '../../../../../../Components/SingleColRow';

export type MediaAudioTrackEntryProps = {
  media: Media;
  track: MediaAudioTrack;
};

const languages = [
  { lang: 'de', title: 'Deutsch' },
  { lang: 'en', title: 'English' },
  { lang: 'fr', title: 'Français' },
  { lang: 'it', title: 'Italiano' },
  { lang: 'fi', title: 'Suomi' },
];

export const MediaAudioTrackEntry: React.FC<MediaAudioTrackEntryProps> = observer(({ media, track }) => {
  const handleChangeLanguage = React.useCallback(
    (newLang) =>
      runInAction(() => {
        track.language = newLang;
        track.title = languages.find((l) => l.lang === newLang)?.title ?? newLang;
      }),
    [track],
  );

  const handleRemove = React.useCallback(() => {
    runInAction(() => (media.audioTracks = media.audioTracks.filter((m) => m.url !== track.url)));
  }, [media, track]);

  return (
    <Row>
      <Col>
        <Select className="select-before" value={track.language} onChange={handleChangeLanguage}>
          {languages.map(({ lang, title }) => (
            <Select.Option key={lang} value={lang}>
              {`${title} (${lang})`}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        <SingleColRow>
          <audio controls>
            <source src={track.url} />
          </audio>
        </SingleColRow>
        {track.filename}
      </Col>
      <Col xs="auto">
        <Button danger size="small" onClick={handleRemove}>
          Remove
        </Button>
      </Col>
    </Row>
  );
});
