import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { SinkNodeModel } from './SinkNodeModel';
import { SinkNodeWidget } from './SinkNodeWidget';
import { Pipeline } from '../../../../../../../Model/Coach/Pipeline';
import { SinkStage } from '../../../../../../../Model/Coach/Stages/SinkStage';

export class SinkNodeModelFactory extends AbstractReactFactory<DefaultStageNodeModel, DiagramEngine> {
  constructor() {
    super('sink');
  }

  generateReactWidget(event): JSX.Element {
    return <SinkNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new SinkNodeModel(new SinkStage(new Pipeline()));
  }
}
