/**
 * Created by neo on 12.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { RouteChallengeTeamMember } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeamMember';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeTeam } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';
import { RouteChallengeTeamsTableRowMembersTableMemberTransactionsTable } from './RouteChallengeTeamsTableRowMembersTableMemberTransactionsTable';

export type RouteChallengeTeamsTableRowMembersTableMemberRowProps = {
  challenge: RouteChallenge;
  team: RouteChallengeTeam;
  member: RouteChallengeTeamMember;
  onRemove?: (member: RouteChallengeTeamMember) => void;
  disabled?: boolean;
};

export const RouteChallengeTeamsTableRowMembersTableMemberRow: React.FC<RouteChallengeTeamsTableRowMembersTableMemberRowProps> =
  observer(({ challenge, team, member, onRemove, disabled }) => {
    const [open, setOpen] = React.useState(false);

    const toggleOpen = () => setOpen((p) => !p);

    return (
      <React.Fragment>
        <tr>
          <td>
            <Button type="link" onClick={toggleOpen}>
              {member.id}
            </Button>
          </td>
          <td>
            <Link to={`/athlete/${member.athleteId}`}>{member.username}</Link>
          </td>
          <td>{member.athlete?.fullName}</td>
          <td>{member.athlete?.user.email}</td>
          <td>{member.totalCollectedPoints}</td>
          <td>
            <Popconfirm
              title={`Remove ${member.athlete?.fullName}?`}
              onConfirm={() => onRemove?.(member)}
              disabled={disabled}
            >
              <Button type="link" danger disabled={disabled}>
                Remove
              </Button>
            </Popconfirm>
          </td>
        </tr>
        {open && (
          <tr>
            <td colSpan={6}>
              <RouteChallengeTeamsTableRowMembersTableMemberTransactionsTable
                challenge={challenge}
                team={team}
                member={member}
              />
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  });
