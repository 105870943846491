/**
 * Created by neo on 12.04.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageResult } from '../../../../Model/PageResult';
import { FitnessProgramTemplate } from '../../../../Model/Coach/Program/Template/FitnessProgramTemplate';
import { toJS } from 'mobx';
import { Button, Input, message, Space } from 'antd';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { Pager } from '../../../../Components/Pager';
import { CoachProgramTemplateTable } from '../../CoachProgramTemplate/CoachProgramTemplateTable';
import { MindfulnessProgramTemplate } from '../../../../Model/Coach/Mindfulness/Template/MindfulnessProgramTemplate';
import { MindfulnessProgramTemplateTable } from './MindfulnessProgramTemplateTable';

export type MindfulnessProgramTemplateListScreenProps = {};

export const MindfulnessProgramTemplateListScreen: React.FC<MindfulnessProgramTemplateListScreenProps> = observer(
  (props) => {
    const history = useNavigate();
    const [templates, setTemplates] = useState<PageResult<MindfulnessProgramTemplate>>(() => new PageResult());
    const [query, setQuery] = useState('');
    const page = React.useRef(0);
    const debounce = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    useEffect(() => {
      PageResult.execute(MindfulnessProgramTemplate.find(), MindfulnessProgramTemplate.count()).then((result) =>
        setTemplates(result),
      );
    }, []);

    useEffect(() => {
      return () => {
        debounce.current && clearTimeout(debounce.current);
      };
    }, []);

    const handleCreate = React.useCallback(() => {
      history('create');
    }, [history]);

    const handleRemove = React.useCallback((template: MindfulnessProgramTemplate) => {
      template.delete().then(() => {
        setTemplates((p) => {
          p.content = p.content.filter((p0) => p0.id !== template.id);
          return new PageResult(toJS(p));
        });
        message.success('Template deleted');
      });
    }, []);

    const handleCopy = React.useCallback((pipeline: MindfulnessProgramTemplate) => {
      pipeline
        .copy()
        .save()
        .then((result) =>
          setTemplates((p) => new PageResult(Object.assign(toJS(p), { content: [result].concat(p.content) }))),
        );
    }, []);

    const handlePage = React.useCallback(
      (newPage: number) => {
        PageResult.execute(
          MindfulnessProgramTemplate.find({ page: newPage, query }),
          MindfulnessProgramTemplate.count(),
          newPage,
          10,
        ).then((result) => setTemplates(result));
      },
      [query],
    );

    const handleChangeQuery = React.useCallback(({ target: { value } }) => {
      setQuery(value);
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(
        () =>
          PageResult.execute(
            MindfulnessProgramTemplate.find({ page: 0, query: value }),
            MindfulnessProgramTemplate.count(),
            0,
            10,
          ).then((result) => setTemplates(result)),
        300,
      );
    }, []);

    return (
      <Container>
        <Row>
          <Col>
            <h1>Mindfulness Program Templates</h1>
          </Col>
          <Col xs="auto">
            <Space>
              <Button type="primary" onClick={handleCreate}>
                Create Template
              </Button>
            </Space>
          </Col>
        </Row>
        <SingleColRow>
          <Input value={query} onChange={handleChangeQuery} />
        </SingleColRow>
        <SingleColRow>
          <Pager page={templates} onPage={handlePage} />
        </SingleColRow>
        <SingleColRow>
          <MindfulnessProgramTemplateTable templates={templates.content} onRemove={handleRemove} onCopy={handleCopy} />
        </SingleColRow>
        <SingleColRow>
          <Pager page={templates} onPage={handlePage} />
        </SingleColRow>
      </Container>
    );
  },
);
