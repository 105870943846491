/**
 * Created by neo on 07.08.20.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { PhaseParamEditor } from './PhaseParamEditor';
import { ParamCondition } from '../../../../../Model/Coach/SuperMacro/ParamCondition';
import { WorkoutPhaseTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutPhaseTemplate';
import styled from '@emotion/styled';
import { TagConditionInput } from '../../../SuperMacro/View/TagConditionInput';
import { Button, Form } from 'antd';
import { PhaseConfiguration } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/PhaseConfiguration';
import { WorkoutTemplate } from '../../../../../Model/Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { SingleColRow } from '../../../../../Components/SingleColRow';

export type PhaseTemplateParamConditionProps = {
  phaseTemplate: WorkoutPhaseTemplate;
  condition: ParamCondition;
  index: number;
  phaseConfiguration?: PhaseConfiguration;
  workoutTemplate?: WorkoutTemplate;
};

const Container = styled.div`
  padding: 4px;
  margin: 4px 0;
`;

const TagContainer = styled.div`
  margin-top: -16px;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid grey;
`;

export const PhaseTemplateParamCondition: React.FC<PhaseTemplateParamConditionProps> = observer(
  ({ phaseTemplate, condition, index, workoutTemplate, phaseConfiguration }) => {
    return (
      <Container>
        <TagContainer>
          <TagConditionInput tagCondition={condition.tagCondition} index={index} />
        </TagContainer>
        <SingleColRow>
          <Row>
            {phaseTemplate.pipeline?.paramsSorted
              .filter((p) => p.name !== 'weight')
              .map((param) => (
                <Col key={param.name} xs={6} md={3}>
                  <Form layout="vertical">
                    <PhaseParamEditor
                      phaseTemplate={condition}
                      workoutTemplate={workoutTemplate}
                      phaseConfiguration={phaseConfiguration}
                      param={param}
                    />
                  </Form>
                </Col>
              ))}
          </Row>
        </SingleColRow>
      </Container>
    );
  },
);
