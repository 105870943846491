/**
 * Created by neo on 18.08.23
 */
import { computed, observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { languagePriority } from '../LocalizedArrayEntity';

export type FormFieldType = 'numeric' | 'string' | 'email' | 'date' | 'datetime' | 'time' | 'boolean';

export type FormFieldJson = {
  identifier: string;
  label: LocalizedValueJson[];
  value: string;
  minSize?: number;
  maxSize?: number;
  valueType: FormFieldType;
};

export class FormField {
  @observable
  identifier = '';
  @observable
  label: LocalizedValue[] = [];
  @observable
  value = '';
  @observable
  minSize?: number;
  @observable
  maxSize?: number;
  @observable
  valueType: FormFieldType = 'string';

  constructor(json?: Partial<FormFieldJson>) {
    if (json) {
      this.identifier = json.identifier ?? '';
      this.label = (json.label ?? []).map((l) => new LocalizedValue(l));
      this.value = json.value ?? '';
      this.minSize = json.minSize;
      this.maxSize = json.maxSize;
      this.valueType = json.valueType ?? 'string';
    }
  }

  toJS(): FormFieldJson {
    return {
      identifier: this.identifier,
      label: this.label.map((l) => l.toJS()),
      value: this.value,
      minSize: this.minSize,
      maxSize: this.maxSize,
      valueType: this.valueType,
    };
  }

  getLabel(lang: string): string {
    return this.label.find((n) => n.lang === lang.toLowerCase())?.value || this.defaultLabel;
  }

  @computed
  get defaultLabel(): string {
    for (const lang of languagePriority) {
      const entry = this.label.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.label[0];
    return first?.value ?? '';
  }
}
