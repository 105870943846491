import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { GreaterThanEqualsNodeWidget } from './GreaterThanEqualsNodeWidget';
import { GreaterThanEqualsNodeModel } from './GreaterThanEqualsNodeModel';
import { GreaterThanEqualsStage } from '../../../../../../../../Model/Coach/Stages/Logical/GreaterThanEqualsStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class GreaterThanEqualsNodeModelFactory extends AbstractReactFactory<GreaterThanEqualsNodeModel, DiagramEngine> {
  constructor() {
    super('logical:gte');
  }

  generateReactWidget(event): JSX.Element {
    return <GreaterThanEqualsNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new GreaterThanEqualsNodeModel(new GreaterThanEqualsStage(new Pipeline()));
  }
}
