/**
 *
 * Created by neo on 06.02.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'reactstrap';
import { Gym } from '../../../Model/Gym/Gym';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { SingleColRow } from '../../../Components/SingleColRow';
import { useEffect, useRef, useState } from 'react';
import { GymTable } from './GymTable';
import { Button, Form, Input, Select } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

export type GymListScreenProps = {};

export const GymListScreen: React.FC<GymListScreenProps> = observer((_: GymListScreenProps) => {
  const location = useLocation();
  const history = useNavigate();
  const params = new URLSearchParams(location.search);
  const urlQuery = params.get('query') ?? '';
  const urlPage = Number(params.get('page') ?? '0');
  const debounce = React.useRef<any>();
  const updateDebounce = React.useRef<any>();
  const count = useRef(0);
  const [result, setResult] = useState(new PageResult<Gym>());
  const [query, setQuery] = useState('');

  const updateSearchParams = React.useCallback(
    (query: string, page: number = 0) => {
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        const params = new URLSearchParams(location.search);
        params.set('query', query);
        params.set('page', page.toString());
        history(
          {
            pathname: location.pathname,
            search: params.toString(),
          },
          { replace: true },
        );
      }, 300);
    },
    [history, location],
  );

  const load = React.useCallback((query: string, page: number = 0) => {
    const size = 10;
    PageResult.execute(
      Gym.find({ query, page, sort: 'name,ASC', size }),
      page === 0 ? Gym.count({ query }).then((res) => (count.current = res)) : Promise.resolve(count.current),
      page,
      size,
    ).then((result) => setResult(result));
  }, []);

  useEffect(() => {
    return () => {
      debounce.current && clearTimeout(debounce.current);
      updateDebounce.current && clearTimeout(updateDebounce.current);
    };
  }, []);

  useEffect(() => {
    setQuery(urlQuery);
    load(urlQuery, urlPage);
  }, [urlQuery, urlPage, load]);

  const handlePage = React.useCallback(
    (newPage: number) => {
      if (urlPage !== newPage) {
        updateSearchParams(query, newPage);
      }
    },
    [urlPage, query, updateSearchParams],
  );

  const handleChangeQuery = React.useCallback(
    ({ target: { value } }) => {
      setQuery(value);
      debounce.current && clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        updateSearchParams(value, 0);
      }, 300);
    },
    [updateSearchParams],
  );

  return (
    <Container>
      <SingleColRow>
        <h1>{'Companies'}</h1>
      </SingleColRow>
      <Row>
        <Col>
          <Form.Item>
            <Input type="text" onChange={handleChangeQuery} value={query} />
          </Form.Item>
        </Col>
        <Col md="auto">
          <Button type="primary" href={'/infrastructure/gym/new'}>
            {'Create New Company'}
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
      <SingleColRow>
        <GymTable gyms={result.content.slice()} />
      </SingleColRow>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
    </Container>
  );
});
