import { Stage } from './Stage';
import { DataSignal } from '../Signal/DataSignal';
import { toJS } from 'mobx';
import { EmptySignal } from '../Signal/EmptySignal';
import { TruthySignal } from '../Signal/TruthySignal';

export class SwitchStage extends Stage<any> {
  process() {
    let signals = this.take();
    while (signals.length > 0) {
      const truthy = signals.filter((s) => s instanceof DataSignal).length === signals.length;
      console.log('switch::process', toJS(signals), truthy);
      if (truthy) {
        signals.forEach((s) => this.processNext(s, 'if'));
      } else {
        signals.forEach((s) => this.processNext(s instanceof EmptySignal ? new TruthySignal() : s, 'else'));
      }
      signals = this.take();
    }
  }

  get type(): string {
    return 'switch';
  }
}
