import * as React from 'react';
import { observer } from 'mobx-react';
import ReactJson from 'react-json-view';
import { observable } from 'mobx';
import { AxonTrackingToken } from '../../../../../Model/Axon/AxonTrackingToken';
import { Button } from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';

export interface AxonSnapshotTableRowProps {
  trackingToken: AxonTrackingToken;
  onRemove?: (token: AxonTrackingToken) => any;
}

@observer
export class AxonTrackingTokenTableRow extends React.Component<AxonSnapshotTableRowProps> {
  @observable
  json?: any = undefined;

  componentDidMount(): void {
    this.parse();
  }

  handleRemove = () => {
    this.props.onRemove && this.props.onRemove(this.props.trackingToken);
  };

  parse() {
    // xml2js.parseString(this.props.trackingToken.token, (err, result) => {
    //   if (err) {
    //     console.error(err);
    //   } else {
    //     this.json = result;
    //   }
    // });
  }

  render() {
    const {
      props: { trackingToken: result },
    } = this;
    return (
      <tr>
        <td>{result.processorName}</td>
        <td>{result.owner}</td>
        <td>{result.segment}</td>
        <td>
          {this.json ? <ReactJson src={this.json} displayDataTypes={false} sortKeys={true} collapsed={3} /> : null}
        </td>
        <td>{dayjs(result.timestamp).format('DD.MM.YYYY HH:mm:ss')}</td>
        <td>
          {!result.owner ? (
            <Button color="link" onClick={this.handleRemove}>
              Remove
            </Button>
          ) : null}
        </td>
      </tr>
    );
  }
}
