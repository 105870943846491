/**
 * Created by andreaskarantzas on 06.05.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Button, Form, Space } from 'antd';
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';

export type ExploreEntryCoachUrlProps = {
  entry: ExploreEntry;
};

export const ExploreEntryCoachUrl: React.FC<ExploreEntryCoachUrlProps> = observer(({ entry }) => {
  const [copiedDynamicLink, setCopiedDynamicLink] = useState<boolean>(false);
  const [copiedNormalLink, setCopiedNormalLink] = useState<boolean>(false);
  const [copiedImageUrl, setCopiedImageUrl] = useState<boolean>(false);

  const normalLink = `https://coach.kinastic.com/explore/${entry.type}/${entry.id}`
  const dynamicLink = `https://open.kinastic.com?link=${encodeURIComponent(normalLink)}&apn=app.flinq.athlete&isi=1465156373&ibi=app.flinq.athlete`

  const handleCopyDynamicLink = React.useCallback(() => {
    copy(`${dynamicLink}`);
    setCopiedDynamicLink(true);
    setTimeout(() => {
      setCopiedDynamicLink(false);
    }, 3000);
  }, [dynamicLink]);

  const handleCopyNormalLink = React.useCallback(() => {
    copy(`${normalLink}`);
    setCopiedNormalLink(true);
    setTimeout(() => {
      setCopiedNormalLink(false);
    }, 3000);
  }, [normalLink]);

  const handleCopyImageUrl = React.useCallback(() => {
    copy(`${entry.image?.smallest}`);
    setCopiedImageUrl(true);
    setTimeout(() => {
      setCopiedImageUrl(false);
    }, 3000);
  }, [entry]);

  return (
    <Row>
      <Col xs={6} style={styles.container}>
        <Form.Item label="Notification Link" extra="Use this link in notifications">
          <Space>
            <span>{normalLink || 'No link available'}</span>
            <Button type="primary" shape="round" size="small" disabled={!normalLink} onClick={handleCopyNormalLink}>
              {copiedNormalLink ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </Space>
        </Form.Item>
        <Form.Item label="Dynamic Link" extra="Use this links in any other communication like emails NOT notifications">
          <Space>
            <span>{dynamicLink || 'No link available'}</span>
            <Button type="primary" shape="round" size="small" disabled={!dynamicLink} onClick={handleCopyDynamicLink}>
              {copiedDynamicLink ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </Space>
        </Form.Item>
      </Col>
      <Col xs={6} style={styles.container}>
        <Form.Item
          label="Notification Image link"
          extra="to be used in the notification image as link. Make sure it is below 300KB"
        >
          {entry.image ? (
            <Space>
              <a href={entry.image.smallest} rel="noreferrer" target="_blank">
                {entry.image.smallest}
              </a>
              <Button
                type="primary"
                shape="round"
                size="small"
                disabled={!entry.image?.smallest}
                onClick={handleCopyImageUrl}
              >
                {copiedImageUrl ? 'Copied!' : 'Copy to clipboard'}
              </Button>
            </Space>
          ) : (
            <p>Missing image. Add an image first</p>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
});

const styles = {
  container: {},
};
