import { HealthDataPointQuantityType, HealthDataPointQuantityTypes } from './HealthDataPointQuantityType';
import { HealthDataPointCorrelationType, HealthDataPointCorrelationTypes } from './HealthDataPointCorrelationType';
import { HealthDataPointCategoryType, HealthDataPointCategoryTypes } from './HealthDataPointCategoryType';

export type HealthDataPointObjectType =
  | HealthDataPointQuantityType
  | HealthDataPointCorrelationType
  | HealthDataPointCategoryType;

export const HealthDataPointObjectTypes: Array<HealthDataPointObjectType> = HealthDataPointQuantityTypes.concat(
  HealthDataPointCorrelationTypes,
).concat(HealthDataPointCategoryTypes) as Array<HealthDataPointObjectType>;
