import { action, computed, observable, onBecomeObserved, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Media } from '../Media/Media';
import { Phase } from './Phase';

export class AbstractWorkout {
  @observable
  id: string = UUID();
  @observable
  type: string = 'strength';
  @observable
  image?: Media = undefined;
  @observable
  phases: Phase[] = [];
  @observable
  tags: Array<string> = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id || UUID();
      this.type = json.type || 'gym_strength';
      this.image = json.image ? new Media(json.image) : undefined;
      this.phases = (json.phases || []).map((p) => this.transformPhase(p));
      this.tags = json.tags || [];
    }
  }

  toJS(newId?: boolean): any {
    return {
      id: newId ? UUID() : this.id,
      type: this.type,
      phases: this.phases.map((p) => p.toJS(newId)),
      image: this.image ? this.image.toJS() : undefined,
      tags: toJS(this.tags),
    };
  }

  protected transformPhase(phase: any): Phase {
    return new Phase(this, phase);
  }

  @action
  createPhase(data?: any): Phase {
    const phase = new Phase({
      workoutId: this.id,
      ...data,
    });
    this.addPhase(phase);
    return phase;
  }

  @action
  addPhase(phase: Phase) {
    this.phases.push(phase);
  }

  @action
  changePhaseOrder(newOrder: Array<Phase>) {
    // const index = this.phases.findIndex(p => p.phaseId === phase.phaseId);
    // if (-1 !== index) {
    //     this.phases.splice(index, 1);
    //     this.phases.splice(newIndex, 0, phase);
    // }
    this.phases = newOrder;
  }

  @action
  removePhase(index: number) {
    this.phases.splice(index, 1);
  }

  @computed
  get hasExercises(): boolean {
    return this.totalExercises > 0;
  }

  @computed
  get hasPhases(): boolean {
    return this.phases.length > 0;
  }

  @computed
  get totalExercises(): number {
    return this.phases.reduce((acc, phase) => acc + phase.totalExercises, 0);
  }

  @computed
  get maxDuration(): number {
    let totalTime = 0;
    const phases = (this.phases || []).slice();
    for (const phase of phases) {
      totalTime += phase.maxDuration;
    }
    return totalTime;
  }

  @computed
  get minDuration(): number {
    let totalTime = 0;
    const phases = (this.phases || []).slice();
    for (const phase of phases) {
      totalTime += phase.minDuration;
    }
    return totalTime;
  }
}
