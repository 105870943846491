/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NativeFeedSideDrawerComponentStylesSwitch } from './NativeFeedSideDrawerComponentStylesSwitch';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { makeStyles } from 'tss-react/mui';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';

interface NativeFeedSideDrawerComponentStylesProps {
  component: NativeFeedComponent;
}

export const NativeFeedSideDrawerComponentStyles: React.FC<NativeFeedSideDrawerComponentStylesProps> = observer(
  ({ component }) => {
    const { classes } = useStyles();

    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography className={classes.heading}>{component.type}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.secondaryHeading}>Edit the component below</Typography>
            </Grid>
            <NativeFeedSideDrawerComponentStylesSwitch component={component} />
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
);

const useStyles = makeStyles()((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
    flexBasis: '100%',
  },
}));
