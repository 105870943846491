/**
 * Created by neo on 10.06.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { NotificationMessage } from '../../../../Model/Explore/NotificationMessage';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { TagConditionInput } from '../../SuperMacro/View/TagConditionInput';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import { Col, Row } from 'reactstrap';
import { Button, Form, Select } from 'antd';
import { runInAction } from 'mobx';
import styled from '@emotion/styled';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';

const Container = styled.div`
  padding: 8px;
  border: 1px solid grey;
`;

export type ExploreEntryNotificationMessageEditorProps = {
  entry: ExploreEntry;
  index: number;
  message: NotificationMessage;
};

export const ExploreEntryNotificationMessageEditor: React.FC<ExploreEntryNotificationMessageEditorProps> = observer(
  ({ entry, message, index }) => {
    const handleRemove = React.useCallback(() => {
      runInAction(() => entry.notificationMessages.splice(index, 1));
    }, [entry, index]);

    const handleChangeDurationBefore = React.useCallback(
      (value?: string) => runInAction(() => (message.durationBefore = value === 'default' ? undefined : value)),
      [message],
    );

    return (
      <Container>
        <SingleColRow>
          <TagConditionInput tagCondition={message.tagCondition} index={0} />
        </SingleColRow>
        <Row>
          <Col>
            <TranslationInputArray entity={message} field="title" label="Title" />
          </Col>
          <Col>
            <TranslationInputArray entity={message} field="message" label="Message" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Duration Before">
              <Select value={message.durationBefore ?? 'default'} onChange={handleChangeDurationBefore}>
                <Select.Option value="default">Default</Select.Option>
                <Select.Option value="PT0S">At time of event</Select.Option>
                <Select.Option value="PT15M">15min before</Select.Option>
                <Select.Option value="PT30M">30min before</Select.Option>
                <Select.Option value="PT1H">1 hour before</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md="auto">
            <Button type="primary" danger onClick={handleRemove}>
              Remove
            </Button>
          </Col>
        </Row>
      </Container>
    );
  },
);
