/**
 * Created by neo on 03.04.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Gym } from '../../../../../Model/Gym/Gym';
import { PageResult } from '../../../../../Model/PageResult';
import { CustomerGroup } from '../../../../../Model/Gym/CustomerGroup/CustomerGroup';
import { Col, Row, Table } from 'reactstrap';
import { Button } from 'antd';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { Route, Routes, useNavigate } from 'react-router';
import { CustomerGroupModal } from './CustomerGroupModal';
import { CustomerGroupTableRow } from './CustomerGroupTableRow';
import { Pager } from '../../../../../Components/Pager';

export type CustomerGroupTabProps = {
  gym: Gym;
};

export const CustomerGroupTab: React.FC<CustomerGroupTabProps> = observer(({ gym }) => {
  const [result, setResult] = useState(new PageResult<CustomerGroup>());
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const load = React.useRef((gym: Gym, page: number) => {
    setResult(new PageResult());
    PageResult.execute(
      CustomerGroup.find({ gymId: gym.id, page, size: 20 }),
      CustomerGroup.count({ gymId: gym.id }),
      page,
      20,
    ).then((result) => setResult(result));
  }).current;

  useEffect(() => {
    load(gym, page);
  }, [gym, load, page]);

  const handleCreate = React.useCallback(() => {
    navigate(`new`);
  }, [navigate]);

  const handleSaved = React.useCallback(
    (customerGroup: CustomerGroup) => {
      load(gym, page);
    },
    [gym, load, page],
  );

  const handleDeleteChallenge = React.useCallback(
    (challenge: CustomerGroup) => {
      load(gym, page);
    },
    [gym, load, page],
  );

  const handlePage = (newPage) => setPage(newPage);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Pager page={result} onPage={handlePage} />
        </Col>
        <Col xs="auto">
          <Button type="primary" onClick={handleCreate}>
            Create new group
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Members</th>
            </tr>
          </thead>
          <tbody>
            {result.content.slice().map((customerGroup) => (
              <CustomerGroupTableRow key={customerGroup.id} customerGroup={customerGroup} />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
      <SingleColRow>
        <Pager page={result} onPage={handlePage} />
      </SingleColRow>
      <Routes>
        <Route
          path=":groupId"
          element={<CustomerGroupModal onSaved={handleSaved} onDeleted={handleDeleteChallenge} />}
        />
      </Routes>
    </React.Fragment>
  );
});
