import * as React from 'react';
import { observer } from 'mobx-react';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { observable, runInAction } from 'mobx';
import { Alert, Button, Col, Input, Row, Table } from 'reactstrap';
import { EventSourcePolyfill } from 'event-source-polyfill';
import AppAuthToken from '../../../../../Services/Security/AppAuthToken';
import { AxonSnapshotTableRow } from './AxonSnapshotTableRow';
import { SnapshotEvent } from '../../../../../Model/Axon/SnapshotEvent';

const EventSource = EventSourcePolyfill;

export interface AxonSnapshotTabProps {
  aggregateIdentifier?: string;
}

@observer
export class AxonSnapshotTab extends React.Component<AxonSnapshotTabProps> {
  @observable
  query: string = '';
  @observable.shallow
  results: SnapshotEvent[] = [];
  @observable
  headers: string[] = [];
  @observable
  done: boolean = false;
  debounce?: any;
  context = 'default';
  eventSource?: EventSource;

  componentDidMount(): void {
    this.setup();
  }

  componentWillUnmount(): void {
    this.debounce && clearTimeout(this.debounce);
  }

  async setup() {
    this.submit();
  }

  handleQueryChange = ({ target: { value } }) => {
    this.query = value;
    // this.debounce = setTimeout(() => {
    //   this.submit();
    // }, 300);
  };

  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.submit();
    }
  };

  async submit() {
    const { query, context } = this;
    if (this.eventSource) {
      this.eventSource.close();
    }

    const token = await AppAuthToken.getAuthData();
    if (token && this.query) {
      this.eventSource = new EventSource(
        `https://api.kinastic.com/v1/infrastructure/gateway/v1/snapshots?aggregateId=${query}&context=default&initialSequence=0&maxSequence=-1`,
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        },
      );

      const source: any = this.eventSource;

      this.headers = [];
      this.done = false;
      this.results = [];

      source.addEventListener('metadata', (event) => {
        // target.columns = JSON.parse(event.data);
        console.log('metadata', JSON.parse(event.data));
        this.headers = JSON.parse(event.data);
      });

      source.addEventListener('done', (event) => {
        // target.working = false;
        console.log('done', event);
        runInAction(() => {
          this.done = true;
        });
        // this.results = this.results.sort((a: any, b: any) => b.token - a.token);
      });

      source.addEventListener('message', (event) => {
        const d = JSON.parse(event.data);

        console.log('message', d);

        if (d.deleted) {
          console.info('Deleted');
          return;
        } else {
          let rowIdx = 0;
          const event = new SnapshotEvent(d);
          while (
            rowIdx < this.results.length &&
            event.aggregateSequenceNumber < this.results[rowIdx].aggregateSequenceNumber
          ) {
            rowIdx++;
          }
          runInAction(() => {
            this.results.splice(rowIdx, 0, event);
          });
        }
      });

      source.addEventListener(
        'error',
        (e) => {
          console.log(e);
          this.done = true;
          if (e.readyState === EventSource.CLOSED) {
            // Connection was closed.
          } else {
            if (e.data) {
              alert(e.data);
            }
          }
          source.close();
        },
        false,
      );
    }
  }

  changeQuery = (query: string) => {
    this.query = query;
    this.submit();
  };

  handleClearQuery = () => {
    this.query = '';
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Input type="text" value={this.query} onChange={this.handleQueryChange} onKeyDown={this.handleKeyDown} />
          </Col>
          <Col xs="auto">
            <Button color="primary" onClick={this.handleClearQuery}>
              Clear
            </Button>
          </Col>
        </Row>
        {this.done && this.results.length === 0 ? (
          <SingleColRow>
            <Alert color="warning">No Snapshots found for {this.query}</Alert>
          </SingleColRow>
        ) : null}
        <SingleColRow>
          <Table>
            <thead>
              <tr>
                <th>Sequence</th>
                <th>Type</th>
                {/*<th>Payload Type</th>*/}
                <th>Revision</th>
                <th>Payload</th>
                <th>Time</th>
                <th>Meta Data</th>
              </tr>
            </thead>
            <tbody>
              {this.results.map((result: SnapshotEvent) => (
                <AxonSnapshotTableRow key={result.messageIdentifier} snapshot={result} />
              ))}
            </tbody>
          </Table>
        </SingleColRow>
      </React.Fragment>
    );
  }
}
