/**
 * Created by katarinababic on 5.10.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { AnalyticsRow } from '../../../Model/Analytics/AnalyticsData';
import { renderActiveShape } from '../../../Utils/pieChartActiveShape';
import { Cell, Pie, PieChart } from 'recharts';
import { ChartColors } from '../ChartColors';
import styled from '@emotion/styled';

export type TotalCompletedActivitiesChartProps = {
  data: AnalyticsRow[];
};

const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TotalCompletedActivitiesChart: React.FC<TotalCompletedActivitiesChartProps> = observer(({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePieEnter = React.useCallback((_, index) => {
    setActiveIndex(index);
  }, []);

  console.log('******', data);

  return (
    <ChartContainer>
      <PieChart width={700} height={600}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          innerRadius={140}
          outerRadius={200}
          fill={ChartColors[activeIndex]}
          dataKey="completed_count"
          onMouseEnter={handlePieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={ChartColors[index % ChartColors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ChartContainer>
  );
});
