/**
 *
 * Created by neo on 11.02.17.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../Store/useRootStore';
import { useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { Button, Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

export type AuthScreenProps = {};

export const AuthScreen: React.FC<AuthScreenProps> = observer(() => {
  const { authentication } = useRootStore();

  const location = useLocation();
  const navigate = useNavigate();

  const [processing, setProcessing] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [authCode, setAuthCode] = useState('');
  const [requested, setRequested] = useState(false);

  const valid = email.trim() && (!requested || authCode.trim());
  const disableEmail = requested || processing;

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCancel = React.useCallback(() => {
    setRequested(false);
    setAuthCode('');
    setEmail('');
  }, []);

  const handleSubmit = React.useCallback(() => {
    setProcessing(true);
    if (requested && authCode.trim()) {
      authentication
        .otpLogin(authCode)
        .catch((e) => {
          console.error(e);
          window.alert('Authentication failed');
        })
        .finally(() => setProcessing(false))
        .then(() => {
          const redirect = new URLSearchParams(location.search).get('redirect') ?? undefined;
          if (redirect) {
            navigate(redirect, { replace: true });
          } else {
            navigate('/', { replace: true });
          }
        });
    } else {
      authentication
        .requestMagicLink(email)
        .then(() => setRequested(true))
        .catch((e) => window.alert(e.response.data.errorMessage))
        .finally(() => setProcessing(false));
    }
  }, [authCode, authentication, email, location.search, navigate, requested]);

  return (
    <Row>
      <Col sm={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 4 }}>
        <div style={{ marginTop: '100px' }}>
          <Card>
            <CardBody>
              <CardTitle>{'KINASTIC Admin'}</CardTitle>
              <Form onFinish={handleSubmit} layout="vertical">
                <Form.Item label="Email">
                  <Input
                    type="email"
                    id="loginEmail"
                    placeholder={'Deine Email'}
                    onChange={handleChangeEmail}
                    value={email}
                    disabled={disableEmail}
                  />
                </Form.Item>
                {requested && (
                  <Form.Item label="Authentication Code">
                    <Input
                      type="email"
                      id="loginEmail"
                      placeholder={'Der Auth Code per Mail'}
                      onChange={({ target: { value } }) => setAuthCode(value)}
                      value={authCode}
                      disabled={processing}
                    />
                  </Form.Item>
                )}
                <Button type="primary" disabled={!valid || processing} onClick={handleSubmit}>
                  {requested ? 'Authenticate' : 'Request Login Mail'}
                </Button>
                {requested && (
                  <Button type="link" onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
              </Form>
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
});
