import { DefaultStageNodeModel } from '../Default/DefaultStageNodeModel';
import { DistinctStage } from '../../../../../../../Model/Coach/Stages/DistinctStage';

export class DuplicateNodeModel extends DefaultStageNodeModel {
  constructor(public readonly stage: DistinctStage, options: any = {}) {
    super(stage, Object.assign(options || {}, { type: 'duplicate' }));
    this.addInPort('in');
    this.addOutPort('default');
  }
}
