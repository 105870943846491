/**
 * Created by neo on 18.03.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { LinkEntry } from '../../../../../Model/Explore/LinkEntry';
import { Form, Input } from 'antd';
import { runInAction } from 'mobx';

export type LinkEntryContentProps = {
  entry: LinkEntry;
};

export const LinkEntryContent: React.FC<LinkEntryContentProps> = observer(({ entry }) => {
  const handleChange = React.useCallback(
    ({ target: { value } }) => {
      runInAction(() => (entry.link = value));
    },
    [entry],
  );

  return (
    <Form.Item label="Link" extra="Can be an internal app link, external or something like mailto:// or tel://">
      <Input value={entry.link} onChange={handleChange} />
    </Form.Item>
  );
});
