import { action, observable } from 'mobx';
import { MicroCycleDay } from './MicroCycleDay';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { ProgramParams } from './ProgramParams';
import { CoachProgramType } from './CoachProgramType';

export class MicroCycle {
  @observable
  id: string = '';
  @observable
  athleteSuperMacroId: string = '';
  @observable
  mesoCycleId: string = '';
  @observable
  athleteId: string = '';
  @observable
  microCycleTemplateId: string = '';
  @observable
  startDateTime: Date = new Date();
  @observable
  endDateTime?: Date = undefined;
  @observable
  deadline?: Date = undefined;
  @observable
  entries: MicroCycleDay[] = [];
  @observable
  type: CoachProgramType = 'superMacro';
  @observable
  weekIndex = 0;

  constructor(json?: any) {
    if (json) {
      this.setBasicData(json);
      this.entries = (json.entries ?? []).map((e) => new MicroCycleDay(this, e));
    }
  }

  @action
  setBasicData(json: any) {
    this.id = json.id || '';
    this.athleteSuperMacroId = json.athleteSuperMacroId || '';
    this.mesoCycleId = json.mesoCycleId || '';
    this.athleteId = json.athleteId || '';
    this.microCycleTemplateId = json.microCycleTemplateId || '';
    this.startDateTime = new Date(json.startDateTime);
    this.endDateTime = json.endDateTime ? new Date(json.endDateTime) : undefined;
    this.deadline = json.deadline ? new Date(json.deadline) : undefined;
    this.type = json.type ?? 'superMacro';
    this.weekIndex = json.weekIndex ?? 0;
  }

  @action
  updateData(json: any) {
    this.setBasicData(json);
    json.entries.map((e: any, newIndex: number) => {
      const entry = this.entries[newIndex];
      if (entry && entry.id === e.id) {
        entry.updateData(e);
      } else {
        this.entries[newIndex] = new MicroCycleDay(this, e);
      }
      const index = this.entries.findIndex((e1) => e1.id === e.id);
      if (index !== -1) {
        const entry = this.entries[index];
        entry.updateData(e);
      }
    });
  }

  async refresh(params?: ProgramParams) {
    const result =
      this.type === 'program'
        ? await HttpBackend.post(`/coach/program/${this.athleteSuperMacroId}/cycle/${this.id}/refresh`, params?.toJS())
        : await HttpBackend.post(`/coach/${this.athleteSuperMacroId}/cycle/${this.id}/refresh`, params?.toJS());
    if (result) {
      this.updateData(result);
    }
  }

  async reload() {
    const res = await HttpBackend.get(`/coach/micro-cycle/${this.id}`);
    if (res) {
      this.updateData(res);
    }
  }

  static async find(params?: any): Promise<Array<MicroCycle>> {
    const res = await HttpBackend.get('/coach/micro-cycle', params);
    return (res || []).map((w) => new MicroCycle(w));
  }

  static async get(cycleId: string): Promise<MicroCycle | undefined> {
    const res = await HttpBackend.get(`/coach/micro-cycle/${cycleId}`);
    return res ? new MicroCycle(res) : undefined;
  }

  async current(userId?: string): Promise<MicroCycle | undefined> {
    const result = await HttpBackend.get('/coach/micro-cycle/current', { userId });
    return result ? new MicroCycle(result) : undefined;
  }
}
