import * as React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable, runInAction } from 'mobx';
import { AxonEventProcessor } from '../../../../../Model/Axon/AxonEventProcessor';
import { fromPromise, IPromiseBasedObservable, PENDING } from 'mobx-utils';
import {
  Alert,
  ButtonDropdown,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap';
import { AxonTrackingTableRow } from './AxonTrackingTableRow';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import dayjs, { Dayjs } from 'dayjs';

export type EventProcessorTabProps = {};

@observer
export class EventProcessorTab extends React.Component<EventProcessorTabProps> {
  @observable
  processors: AxonEventProcessor[] = [];
  @observable
  dropdownOpen: boolean = false;
  @observable
  resetPromise?: IPromiseBasedObservable<any>;
  refreshTimeout?: number;

  componentDidMount() {
    this.init();
  }

  componentWillUnmount(): void {
    this.refreshTimeout && clearTimeout(this.refreshTimeout);
  }

  async init() {
    await this.load();
    this.refreshTimeout = setTimeout(() => {
      this.refresh();
    }, 2000) as any;
  }

  toggleButtonDropdown = () => (this.dropdownOpen = !this.dropdownOpen);

  reset = () => {
    if (window.confirm(`Do you REALLY want to reset ALL PROCESSORS???`)) {
      this.resetPod();
    }
  };

  resetHourAgo = (hours: number) => () => {
    const timestamp = dayjs().subtract(hours, 'hours').valueOf();
    this.resetPod(timestamp);
  };

  resetNow = () => {
    this.resetPod(0);
  };

  resetPod(startTimestamp?: number) {
    this.resetPromise = fromPromise(
      Promise.all(
        Array.from(this.processors.values())
          .filter((m) => m.supportsReset)
          .map((m) => m.reset(startTimestamp)),
      ),
    );
  }

  @computed
  get restarting(): boolean {
    return !!this.resetPromise && this.resetPromise.state === PENDING;
  }

  async refresh() {
    const response = await AxonEventProcessor.find();
    runInAction(() => {
      response.forEach((processor: AxonEventProcessor) => {
        const existing = this.processors.find((p) => p.name === processor.name);
        if (existing) {
          existing.refresh(processor);
        } else {
          this.processors.push(processor);
        }
      });
    });

    this.refreshTimeout = setTimeout(() => {
      this.refresh();
    }, 2000) as any;
  }

  @action
  async load() {
    this.processors = await AxonEventProcessor.find();
  }

  @computed
  get globalIndexMax(): number {
    return this.processors.reduce((max: number, m: AxonEventProcessor) => Math.max(max, m.globalIndexMax), 0);
  }

  @computed
  get globalIndexMin(): number {
    return this.processors.reduce(
      (max: number, m: AxonEventProcessor) => Math.max(max, m.globalIndexMin),
      this.globalIndexMax,
    );
  }

  render() {
    const { processors } = this;
    return (
      <Container>
        <Row>
          <Col>
            <h1>{'Event Processors'}</h1>
          </Col>
          <Col xs="auto">
            <ButtonDropdown
              size="sm"
              isOpen={this.dropdownOpen}
              toggle={this.toggleButtonDropdown}
              disabled={this.restarting}
            >
              <DropdownToggle caret>Reset</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.resetHourAgo(1)}>An hour ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(6)}>6 hours ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(12)}>12 hours ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(24)}>24 hours ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(48)}>48 hours ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(72)}>72 hours ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(168)}>a week ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(336)}>two weeks ago</DropdownItem>
                <DropdownItem onClick={this.resetHourAgo(744)}>a month ago</DropdownItem>
                <DropdownItem onClick={this.resetNow}>Set to Now</DropdownItem>
                <DropdownItem onClick={this.reset}>From Beginning</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>
        <SingleColRow>
          <Alert color="danger">
            <b>WARNING ONLY FOR EXPERIENCED USERS</b>
          </Alert>
        </SingleColRow>
        <Row>
          <Col>
            <Table size="sm" hover striped>
              <thead>
                <tr>
                  <th>Service Name</th>
                  <th>Name</th>
                  <th>Global Index Min</th>
                  <th>Global Index Max</th>
                  <th>Segments</th>
                  <th>{''}</th>
                </tr>
              </thead>
              <tbody>
                {processors.map((processor) => (
                  <AxonTrackingTableRow
                    key={processor.name}
                    model={processor}
                    globalIndexMax={this.globalIndexMax}
                    globalIndexMin={this.globalIndexMin}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}
