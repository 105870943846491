/**
 *
 * Created by neo on 14.06.17.
 */

import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import { useState } from 'react';
import { MultiRowMapEntry } from './MultiRowMapEntry';
import { Button, Form, Input } from 'antd';
import { ObservableMap, runInAction } from 'mobx';

export type MultiRowMapProps = {
  container: Map<string, any> | Record<string, any>;
  label?: string;
};

export const MultiRowMap: React.FC<MultiRowMapProps> = observer(({ container, label = 'Additional Information' }) => {
  const [newValue, setNewValue] = useState('');
  const [newKey, setNewKey] = useState('');
  const keys =
    container instanceof Map || container instanceof ObservableMap
      ? Array.from(container.keys())
      : Object.keys(container);

  const addRow = React.useCallback(() => {
    runInAction(() => {
      if (container instanceof Map || container instanceof ObservableMap) {
        container.set(newKey, newValue);
      } else {
        container[newKey] = newValue;
      }
    });
    setNewValue('');
    setNewKey('');
  }, [newKey, newValue, container]);

  if (!container) {
    return null;
  }

  return (
    <Form layout="vertical">
      {keys.map((key, index) => (
        <MultiRowMapEntry key={index} container={container} valueKey={key} />
      ))}
      <Form.Item label={`Add ${label}`}>
        <Row>
          <Col>
            <Input type="text" value={newKey} onChange={(e) => setNewKey(e.target.value)} />
          </Col>
          <Col>
            <Input type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
          </Col>
          <Col xs="auto">
            <Button type="dashed" onClick={addRow}>
              Add
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
});
