import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { AndNodeWidget } from './AndNodeWidget';
import { AndNodeModel } from './AndNodeModel';
import { AndStage } from '../../../../../../../../Model/Coach/Stages/Logical/AndStage';
import { Pipeline } from '../../../../../../../../Model/Coach/Pipeline';

export class AndNodeModelFactory extends AbstractReactFactory<AndNodeModel, DiagramEngine> {
  constructor() {
    super('logical:and');
  }

  generateReactWidget(event): JSX.Element {
    return <AndNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event) {
    return new AndNodeModel(new AndStage(new Pipeline()));
  }
}
